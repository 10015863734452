export const itemIdMap: Record<number, string> = {
    0: "Dwarf remains",
    1: "Toolkit",
    2: "Cannonball",
    3: "Nulodion's notes",
    4: "Ammo mould",
    5: "Instruction manual",
    6: "Cannon base",
    7: "null",
    8: "Cannon stand",
    9: "null",
    10: "Cannon barrels",
    11: "null",
    12: "Cannon furnace",
    13: "null",
    14: "Railing",
    15: "Holy table napkin",
    16: "Magic whistle",
    17: "Grail bell",
    18: "Magic gold feather",
    19: "Holy grail",
    20: "White cog",
    21: "Black cog",
    22: "Blue cog",
    23: "Red cog",
    24: "Rat poison",
    25: "Red vine worm",
    26: "Fishing trophy",
    27: "Fishing pass",
    28: "Insect repellent",
    29: "null",
    30: "Bucket of wax",
    31: "null",
    32: "Lit black candle",
    33: "Lit candle",
    34: "Null",
    35: "Excalibur",
    36: "Candle",
    37: "null",
    38: "Black candle",
    39: "Bronze arrowtips",
    40: "Iron arrowtips",
    41: "Steel arrowtips",
    42: "Mithril arrowtips",
    43: "Adamant arrowtips",
    44: "Rune arrowtips",
    45: "Opal bolt tips",
    46: "Pearl bolt tips",
    47: "Barb bolttips",
    48: "Longbow (u)",
    49: "null",
    50: "Shortbow (u)",
    51: "null",
    52: "Arrow shaft",
    53: "Headless arrow",
    54: "Oak shortbow (u)",
    55: "null",
    56: "Oak longbow (u)",
    57: "null",
    58: "Willow longbow (u)",
    59: "null",
    60: "Willow shortbow (u)",
    61: "null",
    62: "Maple longbow (u)",
    63: "null",
    64: "Maple shortbow (u)",
    65: "null",
    66: "Yew longbow (u)",
    67: "null",
    68: "Yew shortbow (u)",
    69: "null",
    70: "Magic longbow (u)",
    71: "null",
    72: "Magic shortbow (u)",
    73: "null",
    74: "Khazard helmet",
    75: "Khazard armour",
    76: "Khazard cell keys",
    77: "Khali brew",
    78: "Ice arrows",
    79: "null",
    80: "null",
    81: "null",
    82: "null",
    83: "Lever",
    84: "Staff of armadyl",
    85: "Shiny key",
    86: "Pendant of lucien",
    87: "Armadyl pendant",
    88: "Boots of lightness",
    89: "Boots of lightness",
    90: "Child's blanket",
    91: "Guam potion (unf)",
    92: "null",
    93: "Marrentill potion (unf)",
    94: "null",
    95: "Tarromin potion (unf)",
    96: "null",
    97: "Harralander potion (unf)",
    98: "null",
    99: "Ranarr potion (unf)",
    100: "null",
    101: "Irit potion (unf)",
    102: "null",
    103: "Avantoe potion (unf)",
    104: "null",
    105: "Kwuarm potion (unf)",
    106: "null",
    107: "Cadantine potion (unf)",
    108: "null",
    109: "Dwarf weed potion (unf)",
    110: "null",
    111: "Torstol potion (unf)",
    112: "null",
    113: "Strength potion(4)",
    114: "null",
    115: "Strength potion(3)",
    116: "null",
    117: "Strength potion(2)",
    118: "null",
    119: "Strength potion(1)",
    120: "null",
    121: "Attack potion(3)",
    122: "null",
    123: "Attack potion(2)",
    124: "null",
    125: "Attack potion(1)",
    126: "null",
    127: "Restore potion(3)",
    128: "null",
    129: "Restore potion(2)",
    130: "null",
    131: "Restore potion(1)",
    132: "null",
    133: "Defence potion(3)",
    134: "null",
    135: "Defence potion(2)",
    136: "null",
    137: "Defence potion(1)",
    138: "null",
    139: "Prayer potion(3)",
    140: "null",
    141: "Prayer potion(2)",
    142: "null",
    143: "Prayer potion(1)",
    144: "null",
    145: "Super attack(3)",
    146: "null",
    147: "Super attack(2)",
    148: "null",
    149: "Super attack(1)",
    150: "null",
    151: "Fishing potion(3)",
    152: "null",
    153: "Fishing potion(2)",
    154: "null",
    155: "Fishing potion(1)",
    156: "null",
    157: "Super strength(3)",
    158: "null",
    159: "Super strength(2)",
    160: "null",
    161: "Super strength(1)",
    162: "null",
    163: "Super defence(3)",
    164: "null",
    165: "Super defence(2)",
    166: "null",
    167: "Super defence(1)",
    168: "null",
    169: "Ranging potion(3)",
    170: "null",
    171: "Ranging potion(2)",
    172: "null",
    173: "Ranging potion(1)",
    174: "null",
    175: "Antipoison(3)",
    176: "null",
    177: "Antipoison(2)",
    178: "null",
    179: "Antipoison(1)",
    180: "null",
    181: "Superantipoison(3)",
    182: "null",
    183: "Superantipoison(2)",
    184: "null",
    185: "Superantipoison(1)",
    186: "null",
    187: "Weapon poison",
    188: "null",
    189: "Zamorak brew(3)",
    190: "null",
    191: "Zamorak brew(2)",
    192: "null",
    193: "Zamorak brew(1)",
    194: "null",
    195: "Potion",
    196: "null",
    197: "Poison chalice",
    198: "null",
    199: "Grimy guam leaf",
    200: "null",
    201: "Grimy marrentill",
    202: "null",
    203: "Grimy tarromin",
    204: "null",
    205: "Grimy harralander",
    206: "null",
    207: "Grimy ranarr weed",
    208: "null",
    209: "Grimy irit leaf",
    210: "null",
    211: "Grimy avantoe",
    212: "null",
    213: "Grimy kwuarm",
    214: "null",
    215: "Grimy cadantine",
    216: "null",
    217: "Grimy dwarf weed",
    218: "null",
    219: "Grimy torstol",
    220: "null",
    221: "Eye of newt",
    222: "null",
    223: "Red spiders' eggs",
    224: "null",
    225: "Limpwurt root",
    226: "null",
    227: "Vial of water",
    228: "null",
    229: "Vial",
    230: "null",
    231: "Snape grass",
    232: "null",
    233: "Pestle and mortar",
    234: "null",
    235: "Unicorn horn dust",
    236: "null",
    237: "Unicorn horn",
    238: "null",
    239: "White berries",
    240: "null",
    241: "Dragon scale dust",
    242: "null",
    243: "Blue dragon scale",
    244: "null",
    245: "Wine of zamorak",
    246: "null",
    247: "Jangerberries",
    248: "null",
    249: "Guam leaf",
    250: "null",
    251: "Marrentill",
    252: "null",
    253: "Tarromin",
    254: "null",
    255: "Harralander",
    256: "null",
    257: "Ranarr weed",
    258: "null",
    259: "Irit leaf",
    260: "null",
    261: "Avantoe",
    262: "null",
    263: "Kwuarm",
    264: "null",
    265: "Cadantine",
    266: "null",
    267: "Dwarf weed",
    268: "null",
    269: "Torstol",
    270: "null",
    271: "Pressure gauge",
    272: "Fish food",
    273: "Poison",
    274: "Poisoned fish food",
    275: "Key",
    276: "Rubber tube",
    277: "Oil can",
    278: "Cattleprod",
    279: "Sheep feed",
    280: "Sheep bones (1)",
    281: "Sheep bones (2)",
    282: "Sheep bones (3)",
    283: "Sheep bones (4)",
    284: "Plague jacket",
    285: "Plague trousers",
    286: "Orange goblin mail",
    287: "Blue goblin mail",
    288: "Goblin mail",
    289: "null",
    290: "Research package",
    291: "Research notes",
    292: "Book on baxtorian",
    293: "Key",
    294: "Glarial's pebble",
    295: "Glarial's amulet",
    296: "Glarial's urn",
    297: "Glarial's urn (empty)",
    298: "Key",
    299: "Mithril seeds",
    300: "Rat's tail",
    301: "Lobster pot",
    302: "null",
    303: "Small fishing net",
    304: "null",
    305: "Big fishing net",
    306: "null",
    307: "Fishing rod",
    308: "null",
    309: "Fly fishing rod",
    310: "null",
    311: "Harpoon",
    312: "null",
    313: "Fishing bait",
    314: "Feather",
    315: "Shrimps",
    316: "null",
    317: "Raw shrimps",
    318: "null",
    319: "Anchovies",
    320: "null",
    321: "Raw anchovies",
    322: "null",
    323: "Burnt fish",
    324: "null",
    325: "Sardine",
    326: "null",
    327: "Raw sardine",
    328: "null",
    329: "Salmon",
    330: "null",
    331: "Raw salmon",
    332: "null",
    333: "Trout",
    334: "null",
    335: "Raw trout",
    336: "null",
    337: "Giant carp",
    338: "Raw giant carp",
    339: "Cod",
    340: "null",
    341: "Raw cod",
    342: "null",
    343: "Burnt fish",
    344: "null",
    345: "Raw herring",
    346: "null",
    347: "Herring",
    348: "null",
    349: "Raw pike",
    350: "null",
    351: "Pike",
    352: "null",
    353: "Raw mackerel",
    354: "null",
    355: "Mackerel",
    356: "null",
    357: "Burnt fish",
    358: "null",
    359: "Raw tuna",
    360: "null",
    361: "Tuna",
    362: "null",
    363: "Raw bass",
    364: "null",
    365: "Bass",
    366: "null",
    367: "Burnt fish",
    368: "null",
    369: "Burnt fish",
    370: "null",
    371: "Raw swordfish",
    372: "null",
    373: "Swordfish",
    374: "null",
    375: "Burnt swordfish",
    376: "null",
    377: "Raw lobster",
    378: "null",
    379: "Lobster",
    380: "null",
    381: "Burnt lobster",
    382: "null",
    383: "Raw shark",
    384: "null",
    385: "Shark",
    386: "null",
    387: "Burnt shark",
    388: "null",
    389: "Raw manta ray",
    390: "null",
    391: "Manta ray",
    392: "null",
    393: "Burnt manta ray",
    394: "null",
    395: "Raw sea turtle",
    396: "null",
    397: "Sea turtle",
    398: "null",
    399: "Burnt sea turtle",
    400: "null",
    401: "Seaweed",
    402: "null",
    403: "Edible seaweed",
    404: "null",
    405: "Casket",
    406: "null",
    407: "Oyster",
    408: "null",
    409: "Empty oyster",
    410: "null",
    411: "Oyster pearl",
    412: "null",
    413: "Oyster pearls",
    414: "null",
    415: "Ethenea",
    416: "Liquid honey",
    417: "Sulphuric broline",
    418: "Plague sample",
    419: "Touch paper",
    420: "Distillator",
    421: "Lathas' amulet",
    422: "Bird feed",
    423: "Key",
    424: "Pigeon cage",
    425: "Pigeon cage",
    426: "Priest gown",
    427: "null",
    428: "Priest gown",
    429: "null",
    430: "Medical gown",
    431: "Karamjan rum",
    432: "Chest key",
    433: "Pirate message",
    434: "Clay",
    435: "null",
    436: "Copper ore",
    437: "null",
    438: "Tin ore",
    439: "null",
    440: "Iron ore",
    441: "null",
    442: "Silver ore",
    443: "null",
    444: "Gold ore",
    445: "null",
    446: "'perfect' gold ore",
    447: "Mithril ore",
    448: "null",
    449: "Adamantite ore",
    450: "null",
    451: "Runite ore",
    452: "null",
    453: "Coal",
    454: "null",
    455: "Barcrawl card",
    456: "Scorpion cage",
    457: "Scorpion cage",
    458: "Scorpion cage",
    459: "Scorpion cage",
    460: "Scorpion cage",
    461: "Scorpion cage",
    462: "Scorpion cage",
    463: "Scorpion cage",
    464: "Strange fruit",
    465: "null",
    466: "Pickaxe handle",
    467: "null",
    468: "Broken pickaxe",
    469: "null",
    470: "Broken pickaxe",
    471: "null",
    472: "Broken pickaxe",
    473: "null",
    474: "Broken pickaxe",
    475: "null",
    476: "Broken pickaxe",
    477: "null",
    478: "Broken pickaxe",
    479: "null",
    480: "Bronze pick head",
    481: "null",
    482: "Iron pick head",
    483: "null",
    484: "Steel pick head",
    485: "null",
    486: "Mithril pick head",
    487: "null",
    488: "Adamant pick head",
    489: "null",
    490: "Rune pick head",
    491: "null",
    492: "Axe handle",
    493: "null",
    494: "Broken axe",
    495: "null",
    496: "Broken axe",
    497: "null",
    498: "Broken axe",
    499: "null",
    500: "Broken axe",
    501: "null",
    502: "Broken axe",
    503: "null",
    504: "Broken axe",
    505: "null",
    506: "Broken axe",
    507: "null",
    508: "Bronze axe head",
    509: "null",
    510: "Iron axe head",
    511: "null",
    512: "Steel axe head",
    513: "null",
    514: "Black axe head",
    515: "null",
    516: "Mithril axe head",
    517: "null",
    518: "Adamant axe head",
    519: "null",
    520: "Rune axe head",
    521: "null",
    522: "Enchanted beef",
    523: "Enchanted rat",
    524: "Enchanted bear",
    525: "Enchanted chicken",
    526: "Bones",
    527: "null",
    528: "Burnt bones",
    529: "null",
    530: "Bat bones",
    531: "null",
    532: "Big bones",
    533: "null",
    534: "Babydragon bones",
    535: "null",
    536: "Dragon bones",
    537: "null",
    538: "Druid's robe",
    539: "null",
    540: "Druid's robe top",
    541: "null",
    542: "Monk's robe",
    543: "null",
    544: "Monk's robe top",
    545: "null",
    546: "Shade robe top",
    547: "null",
    548: "Shade robe",
    549: "null",
    550: "Newcomer map",
    551: "null",
    552: "Ghostspeak amulet",
    553: "Ghost's skull",
    554: "Fire rune",
    555: "Water rune",
    556: "Air rune",
    557: "Earth rune",
    558: "Mind rune",
    559: "Body rune",
    560: "Death rune",
    561: "Nature rune",
    562: "Chaos rune",
    563: "Law rune",
    564: "Cosmic rune",
    565: "Blood rune",
    566: "Soul rune",
    567: "Unpowered orb",
    568: "null",
    569: "Fire orb",
    570: "null",
    571: "Water orb",
    572: "null",
    573: "Air orb",
    574: "null",
    575: "Earth orb",
    576: "null",
    577: "Blue wizard robe",
    578: "null",
    579: "Blue wizard hat",
    580: "null",
    581: "Black robe",
    582: "null",
    583: "Bailing bucket",
    584: "null",
    585: "Bailing bucket",
    586: "null",
    587: "Orb of protection",
    588: "Orbs of protection",
    589: "Gnome amulet",
    590: "Tinderbox",
    591: "null",
    592: "Ashes",
    593: "null",
    594: "Lit torch",
    595: "Torch",
    596: "Unlit torch",
    597: "null",
    598: "Bronze fire arrow",
    599: "null",
    600: "Astronomy book",
    601: "Goblin kitchen key",
    602: "Lens mould",
    603: "Observatory lens",
    604: "Bone shard",
    605: "Bone key",
    606: "Stone-plaque",
    607: "Tattered scroll",
    608: "Crumpled scroll",
    609: "Rashiliyia corpse",
    610: "Zadimus corpse",
    611: "Locating crystal",
    612: "Locating crystal",
    613: "Locating crystal",
    614: "Locating crystal",
    615: "Locating crystal",
    616: "Beads of the dead",
    617: "Coins",
    618: "Bone beads",
    619: "Paramaya ticket",
    620: "Paramaya ticket",
    621: "Ship ticket",
    622: "null",
    623: "Sword pommel",
    624: "Bervirius notes",
    625: "Wampum belt",
    626: "Pink boots",
    627: "null",
    628: "Green boots",
    629: "null",
    630: "Blue boots",
    631: "null",
    632: "Cream boots",
    633: "null",
    634: "Turquoise boots",
    635: "null",
    636: "Pink robe top",
    637: "null",
    638: "Green robe top",
    639: "null",
    640: "Blue robe top",
    641: "null",
    642: "Cream robe top",
    643: "null",
    644: "Turquoise robe top",
    645: "null",
    646: "Pink robe bottoms",
    647: "null",
    648: "Green robe bottoms",
    649: "null",
    650: "Blue robe bottoms",
    651: "null",
    652: "Cream robe bottoms",
    653: "null",
    654: "Turquoise robe bottoms",
    655: "null",
    656: "Pink hat",
    657: "null",
    658: "Green hat",
    659: "null",
    660: "Blue hat",
    661: "null",
    662: "Cream hat",
    663: "null",
    664: "Turquoise hat",
    665: "null",
    666: "Portrait",
    667: "Blurite sword",
    668: "Blurite ore",
    669: "Specimen jar",
    670: "Specimen brush",
    671: "Animal skull",
    672: "Special cup",
    673: "Teddy",
    674: "Cracked sample",
    675: "Rock pick",
    676: "Trowel",
    677: "Panning tray",
    678: "Panning tray",
    679: "Panning tray",
    680: "Nuggets",
    681: "Ancient talisman",
    682: "Unstamped letter",
    683: "Sealed letter",
    684: "Belt buckle",
    685: "Old boot",
    686: "Rusty sword",
    687: "Broken arrow",
    688: "Buttons",
    689: "Broken staff",
    690: "Broken glass",
    691: "Level 1 certificate",
    692: "Level 2 certificate",
    693: "Level 3 certificate",
    694: "Ceramic remains",
    695: "Old tooth",
    696: "Invitation letter",
    697: "Damaged armour",
    698: "Broken armour",
    699: "Stone tablet",
    700: "Chemical powder",
    701: "Ammonium nitrate",
    702: "Unidentified liquid",
    703: "Nitroglycerin",
    704: "Ground charcoal",
    705: "Mixed chemicals",
    706: "Mixed chemicals",
    707: "Chemical compound",
    708: "Arcenia root",
    709: "Chest key",
    710: "Vase",
    711: "Book on chemicals",
    712: "Cup of tea",
    713: "Clue scroll",
    714: "Radimus notes",
    715: "Radimus notes",
    716: "Bullroarer",
    717: "Scrawled note",
    718: "A scribbled note",
    719: "Scrumpled note",
    720: "Sketch",
    721: "Gold bowl",
    722: "Blessed gold bowl",
    723: "Golden bowl",
    724: "Golden bowl",
    725: "Golden bowl",
    726: "Golden bowl",
    727: "Hollow reed",
    728: "Hollow reed",
    729: "Shaman's tome",
    730: "Binding book",
    731: "Enchanted vial",
    732: "Holy water",
    733: "Smashed glass",
    734: "null",
    735: "Yommi tree seeds",
    736: "Yommi tree seeds",
    737: "Snakeweed mixture",
    738: "Ardrigal mixture",
    739: "Bravery potion",
    740: "Blue hat",
    741: "Chunk of crystal",
    742: "Hunk of crystal",
    743: "Lump of crystal",
    744: "Heart crystal",
    745: "Heart crystal",
    746: "Dark dagger",
    747: "Glowing dagger",
    748: "Holy force",
    749: "Yommi totem",
    750: "Gilded totem",
    751: "Gnomeball",
    752: "null",
    753: "Cadava berries",
    754: "null",
    755: "Message",
    756: "Cadava potion",
    757: "Book",
    758: "Phoenix hq key",
    759: "Weapon store key",
    760: "null",
    761: "Intel report",
    762: "Falador shield",
    763: "Broken shield",
    764: "Coal bag",
    765: "Broken shield",
    766: "Gem bag",
    767: "Phoenix crossbow",
    768: "null",
    769: "Certificate",
    770: "Ardougne cloak",
    771: "Dramen branch",
    772: "Dramen staff",
    773: "'perfect' ring",
    774: "'perfect' necklace",
    775: "Cooking gauntlets",
    776: "Goldsmith gauntlets",
    777: "Chaos gauntlets",
    778: "Steel gauntlets",
    779: "Crest part",
    780: "Crest part",
    781: "Crest part",
    782: "Family crest",
    783: "Bark sample",
    784: "Translation book",
    785: "Glough's journal",
    786: "Hazelmere's scroll",
    787: "Lumber order",
    788: "Glough's key",
    789: "Twigs",
    790: "Twigs",
    791: "Twigs",
    792: "Twigs",
    793: "Daconia rock",
    794: "Invasion plans",
    795: "War ship",
    796: "null",
    797: "null",
    798: "null",
    799: "null",
    800: "Bronze thrownaxe",
    801: "Iron thrownaxe",
    802: "Steel thrownaxe",
    803: "Mithril thrownaxe",
    804: "Adamant thrownaxe",
    805: "Rune thrownaxe",
    806: "Bronze dart",
    807: "Iron dart",
    808: "Steel dart",
    809: "Mithril dart",
    810: "Adamant dart",
    811: "Rune dart",
    812: "Bronze dart(p)",
    813: "Iron dart (p)",
    814: "Steel dart(p)",
    815: "Mithril dart(p)",
    816: "Adamant dart(p)",
    817: "Rune dart(p)",
    818: "Poisoned dart(p)",
    819: "Bronze dart tip",
    820: "Iron dart tip",
    821: "Steel dart tip",
    822: "Mithril dart tip",
    823: "Adamant dart tip",
    824: "Rune dart tip",
    825: "Bronze javelin",
    826: "Iron javelin",
    827: "Steel javelin",
    828: "Mithril javelin",
    829: "Adamant javelin",
    830: "Rune javelin",
    831: "Bronze javelin(p)",
    832: "Iron javelin(p)",
    833: "Steel javelin(p)",
    834: "Mithril javelin(p)",
    835: "Adamant javelin(p)",
    836: "Rune javelin(p)",
    837: "Crossbow",
    838: "null",
    839: "Longbow",
    840: "null",
    841: "Shortbow",
    842: "null",
    843: "Oak shortbow",
    844: "null",
    845: "Oak longbow",
    846: "null",
    847: "Willow longbow",
    848: "null",
    849: "Willow shortbow",
    850: "null",
    851: "Maple longbow",
    852: "null",
    853: "Maple shortbow",
    854: "null",
    855: "Yew longbow",
    856: "null",
    857: "Yew shortbow",
    858: "null",
    859: "Magic longbow",
    860: "null",
    861: "Magic shortbow",
    862: "null",
    863: "Iron knife",
    864: "Bronze knife",
    865: "Steel knife",
    866: "Mithril knife",
    867: "Adamant knife",
    868: "Rune knife",
    869: "Black knife",
    870: "Bronze knife(p)",
    871: "Iron knife(p)",
    872: "Steel knife(p)",
    873: "Mithril knife(p)",
    874: "Black knife(p)",
    875: "Adamant knife(p)",
    876: "Rune knife(p)",
    877: "Bronze bolts",
    878: "Bronze bolts (p)",
    879: "Opal bolts",
    880: "Pearl bolts",
    881: "Barbed bolts",
    882: "Bronze arrow",
    883: "Bronze arrow(p)",
    884: "Iron arrow",
    885: "Iron arrow(p)",
    886: "Steel arrow",
    887: "Steel arrow(p)",
    888: "Mithril arrow",
    889: "Mithril arrow(p)",
    890: "Adamant arrow",
    891: "Adamant arrow(p)",
    892: "Rune arrow",
    893: "Rune arrow(p)",
    894: "null",
    895: "null",
    896: "null",
    897: "null",
    898: "null",
    899: "null",
    900: "null",
    901: "null",
    902: "null",
    903: "null",
    904: "null",
    905: "null",
    906: "null",
    907: "null",
    908: "null",
    909: "null",
    910: "null",
    911: "null",
    912: "null",
    913: "null",
    914: "null",
    915: "null",
    916: "null",
    917: "null",
    918: "null",
    919: "null",
    920: "null",
    921: "null",
    922: "null",
    923: "null",
    924: "null",
    925: "null",
    926: "null",
    927: "null",
    928: "null",
    929: "null",
    930: "null",
    931: "null",
    932: "null",
    933: "null",
    934: "null",
    935: "null",
    936: "null",
    937: "null",
    938: "null",
    939: "null",
    940: "null",
    941: "null",
    942: "Bronze fire arrow (lit)",
    943: "Worm",
    944: "null",
    945: "Throwing rope",
    946: "Knife",
    947: "null",
    948: "Bear fur",
    949: "null",
    950: "Silk",
    951: "null",
    952: "Spade",
    953: "null",
    954: "Rope",
    955: "null",
    956: "Flyer",
    957: "null",
    958: "Grey wolf fur",
    959: "null",
    960: "Plank",
    961: "null",
    962: "Christmas cracker",
    963: "null",
    964: "Skull",
    965: "Skull",
    966: "Tile",
    967: "null",
    968: "Rock",
    969: "null",
    970: "Papyrus",
    971: "null",
    972: "Papyrus",
    973: "Charcoal",
    974: "null",
    975: "Machete",
    976: "null",
    977: "Cooking pot",
    978: "null",
    979: "null",
    980: "null",
    981: "Disk of returning",
    982: "null",
    983: "Brass key",
    984: "null",
    985: "Tooth half of key",
    986: "null",
    987: "Loop half of key",
    988: "null",
    989: "Crystal key",
    990: "null",
    991: "Muddy key",
    992: "null",
    993: "Sinister key",
    994: "null",
    995: "Coins",
    996: "null",
    997: "null",
    998: "null",
    999: "null",
    1000: "null",
    1001: "null",
    1002: "null",
    1003: "null",
    1004: "null",
    1005: "White apron",
    1006: "null",
    1007: "Red cape",
    1008: "null",
    1009: "Brass necklace",
    1010: "null",
    1011: "Blue skirt",
    1012: "null",
    1013: "Pink skirt",
    1014: "null",
    1015: "Black skirt",
    1016: "null",
    1017: "Wizard hat",
    1018: "null",
    1019: "Black cape",
    1020: "null",
    1021: "Blue cape",
    1022: "null",
    1023: "Yellow cape",
    1024: "null",
    1025: "Right eye patch",
    1026: "null",
    1027: "Green cape",
    1028: "null",
    1029: "Purple cape",
    1030: "null",
    1031: "Orange cape",
    1032: "null",
    1033: "Zamorak monk bottom",
    1034: "null",
    1035: "Zamorak monk top",
    1036: "null",
    1037: "Bunny ears",
    1038: "Red partyhat",
    1039: "null",
    1040: "Yellow partyhat",
    1041: "null",
    1042: "Blue partyhat",
    1043: "null",
    1044: "Green partyhat",
    1045: "null",
    1046: "Purple partyhat",
    1047: "null",
    1048: "White partyhat",
    1049: "null",
    1050: "Santa hat",
    1051: "null",
    1052: "Cape of legends",
    1053: "Green halloween mask",
    1054: "null",
    1055: "Blue halloween mask",
    1056: "null",
    1057: "Red halloween mask",
    1058: "null",
    1059: "Leather gloves",
    1060: "null",
    1061: "Leather boots",
    1062: "null",
    1063: "Leather vambraces",
    1064: "null",
    1065: "Green d'hide vambraces",
    1066: "null",
    1067: "Iron platelegs",
    1068: "null",
    1069: "Steel platelegs",
    1070: "null",
    1071: "Mithril platelegs",
    1072: "null",
    1073: "Adamant platelegs",
    1074: "null",
    1075: "Bronze platelegs",
    1076: "null",
    1077: "Black platelegs",
    1078: "null",
    1079: "Rune platelegs",
    1080: "null",
    1081: "Iron plateskirt",
    1082: "null",
    1083: "Steel plateskirt",
    1084: "null",
    1085: "Mithril plateskirt",
    1086: "null",
    1087: "Bronze plateskirt",
    1088: "null",
    1089: "Black plateskirt",
    1090: "null",
    1091: "Adamant plateskirt",
    1092: "null",
    1093: "Rune plateskirt",
    1094: "null",
    1095: "Leather chaps",
    1096: "null",
    1097: "Studded chaps",
    1098: "null",
    1099: "Green d'hide chaps",
    1100: "null",
    1101: "Iron chainbody",
    1102: "null",
    1103: "Bronze chainbody",
    1104: "null",
    1105: "Steel chainbody",
    1106: "null",
    1107: "Black chainbody",
    1108: "null",
    1109: "Mithril chainbody",
    1110: "null",
    1111: "Adamant chainbody",
    1112: "null",
    1113: "Rune chainbody",
    1114: "null",
    1115: "Iron platebody",
    1116: "null",
    1117: "Bronze platebody",
    1118: "null",
    1119: "Steel platebody",
    1120: "null",
    1121: "Mithril platebody",
    1122: "null",
    1123: "Adamant platebody",
    1124: "null",
    1125: "Black platebody",
    1126: "null",
    1127: "Rune platebody",
    1128: "null",
    1129: "Leather body",
    1130: "null",
    1131: "Hardleather body",
    1132: "null",
    1133: "Studded body",
    1134: "null",
    1135: "Green d'hide body",
    1136: "null",
    1137: "Iron med helm",
    1138: "null",
    1139: "Bronze med helm",
    1140: "null",
    1141: "Steel med helm",
    1142: "null",
    1143: "Mithril med helm",
    1144: "null",
    1145: "Adamant med helm",
    1146: "null",
    1147: "Rune med helm",
    1148: "null",
    1149: "Dragon med helm",
    1150: "null",
    1151: "Black med helm",
    1152: "null",
    1153: "Iron full helm",
    1154: "null",
    1155: "Bronze full helm",
    1156: "null",
    1157: "Steel full helm",
    1158: "null",
    1159: "Mithril full helm",
    1160: "null",
    1161: "Adamant full helm",
    1162: "null",
    1163: "Rune full helm",
    1164: "null",
    1165: "Black full helm",
    1166: "null",
    1167: "Leather cowl",
    1168: "null",
    1169: "Coif",
    1170: "null",
    1171: "Wooden shield",
    1172: "null",
    1173: "Bronze sq shield",
    1174: "null",
    1175: "Iron sq shield",
    1176: "null",
    1177: "Steel sq shield",
    1178: "null",
    1179: "Black sq shield",
    1180: "null",
    1181: "Mithril sq shield",
    1182: "null",
    1183: "Adamant sq shield",
    1184: "null",
    1185: "Rune sq shield",
    1186: "null",
    1187: "Dragon sq shield",
    1188: "null",
    1189: "Bronze kiteshield",
    1190: "null",
    1191: "Iron kiteshield",
    1192: "null",
    1193: "Steel kiteshield",
    1194: "null",
    1195: "Black kiteshield",
    1196: "null",
    1197: "Mithril kiteshield",
    1198: "null",
    1199: "Adamant kiteshield",
    1200: "null",
    1201: "Rune kiteshield",
    1202: "null",
    1203: "Iron dagger",
    1204: "null",
    1205: "Bronze dagger",
    1206: "null",
    1207: "Steel dagger",
    1208: "null",
    1209: "Mithril dagger",
    1210: "null",
    1211: "Adamant dagger",
    1212: "null",
    1213: "Rune dagger",
    1214: "null",
    1215: "Dragon dagger",
    1216: "null",
    1217: "Black dagger",
    1218: "null",
    1219: "Iron dagger(p)",
    1220: "null",
    1221: "Bronze dagger(p)",
    1222: "null",
    1223: "Steel dagger(p)",
    1224: "null",
    1225: "Mithril dagger(p)",
    1226: "null",
    1227: "Adamant dagger(p)",
    1228: "null",
    1229: "Rune dagger(p)",
    1230: "null",
    1231: "Dragon dagger(p)",
    1232: "null",
    1233: "Black dagger(p)",
    1234: "null",
    1235: "Poisoned dagger(p)",
    1236: "null",
    1237: "Bronze spear",
    1238: "null",
    1239: "Iron spear",
    1240: "null",
    1241: "Steel spear",
    1242: "null",
    1243: "Mithril spear",
    1244: "null",
    1245: "Adamant spear",
    1246: "null",
    1247: "Rune spear",
    1248: "null",
    1249: "Dragon spear",
    1250: "null",
    1251: "Bronze spear(p)",
    1252: "null",
    1253: "Iron spear(p)",
    1254: "null",
    1255: "Steel spear(p)",
    1256: "null",
    1257: "Mithril spear(p)",
    1258: "null",
    1259: "Adamant spear(p)",
    1260: "null",
    1261: "Rune spear(p)",
    1262: "null",
    1263: "Dragon spear(p)",
    1264: "null",
    1265: "Bronze pickaxe",
    1266: "null",
    1267: "Iron pickaxe",
    1268: "null",
    1269: "Steel pickaxe",
    1270: "null",
    1271: "Adamant pickaxe",
    1272: "null",
    1273: "Mithril pickaxe",
    1274: "null",
    1275: "Rune pickaxe",
    1276: "null",
    1277: "Bronze sword",
    1278: "null",
    1279: "Iron sword",
    1280: "null",
    1281: "Steel sword",
    1282: "null",
    1283: "Black sword",
    1284: "null",
    1285: "Mithril sword",
    1286: "null",
    1287: "Adamant sword",
    1288: "null",
    1289: "Rune sword",
    1290: "null",
    1291: "Bronze longsword",
    1292: "null",
    1293: "Iron longsword",
    1294: "null",
    1295: "Steel longsword",
    1296: "null",
    1297: "Black longsword",
    1298: "null",
    1299: "Mithril longsword",
    1300: "null",
    1301: "Adamant longsword",
    1302: "null",
    1303: "Rune longsword",
    1304: "null",
    1305: "Dragon longsword",
    1306: "null",
    1307: "Bronze 2h sword",
    1308: "null",
    1309: "Iron 2h sword",
    1310: "null",
    1311: "Steel 2h sword",
    1312: "null",
    1313: "Black 2h sword",
    1314: "null",
    1315: "Mithril 2h sword",
    1316: "null",
    1317: "Adamant 2h sword",
    1318: "null",
    1319: "Rune 2h sword",
    1320: "null",
    1321: "Bronze scimitar",
    1322: "null",
    1323: "Iron scimitar",
    1324: "null",
    1325: "Steel scimitar",
    1326: "null",
    1327: "Black scimitar",
    1328: "null",
    1329: "Mithril scimitar",
    1330: "null",
    1331: "Adamant scimitar",
    1332: "null",
    1333: "Rune scimitar",
    1334: "null",
    1335: "Iron warhammer",
    1336: "null",
    1337: "Bronze warhammer",
    1338: "null",
    1339: "Steel warhammer",
    1340: "null",
    1341: "Black warhammer",
    1342: "null",
    1343: "Mithril warhammer",
    1344: "null",
    1345: "Adamant warhammer",
    1346: "null",
    1347: "Rune warhammer",
    1348: "null",
    1349: "Iron axe",
    1350: "null",
    1351: "Bronze axe",
    1352: "null",
    1353: "Steel axe",
    1354: "null",
    1355: "Mithril axe",
    1356: "null",
    1357: "Adamant axe",
    1358: "null",
    1359: "Rune axe",
    1360: "null",
    1361: "Black axe",
    1362: "null",
    1363: "Iron battleaxe",
    1364: "null",
    1365: "Steel battleaxe",
    1366: "null",
    1367: "Black battleaxe",
    1368: "null",
    1369: "Mithril battleaxe",
    1370: "null",
    1371: "Adamant battleaxe",
    1372: "null",
    1373: "Rune battleaxe",
    1374: "null",
    1375: "Bronze battleaxe",
    1376: "null",
    1377: "Dragon battleaxe",
    1378: "null",
    1379: "Staff",
    1380: "null",
    1381: "Staff of air",
    1382: "null",
    1383: "Staff of water",
    1384: "null",
    1385: "Staff of earth",
    1386: "null",
    1387: "Staff of fire",
    1388: "null",
    1389: "Magic staff",
    1390: "null",
    1391: "Battlestaff",
    1392: "null",
    1393: "Fire battlestaff",
    1394: "null",
    1395: "Water battlestaff",
    1396: "null",
    1397: "Air battlestaff",
    1398: "null",
    1399: "Earth battlestaff",
    1400: "null",
    1401: "Mystic fire staff",
    1402: "null",
    1403: "Mystic water staff",
    1404: "null",
    1405: "Mystic air staff",
    1406: "null",
    1407: "Mystic earth staff",
    1408: "null",
    1409: "Iban's staff",
    1410: "Iban's staff",
    1411: "Farmer's fork",
    1412: "null",
    1413: "Halberd",
    1414: "null",
    1415: "Warhammer",
    1416: "null",
    1417: "Javelin",
    1418: "null",
    1419: "Scythe",
    1420: "Iron mace",
    1421: "null",
    1422: "Bronze mace",
    1423: "null",
    1424: "Steel mace",
    1425: "null",
    1426: "Black mace",
    1427: "null",
    1428: "Mithril mace",
    1429: "null",
    1430: "Adamant mace",
    1431: "null",
    1432: "Rune mace",
    1433: "null",
    1434: "Dragon mace",
    1435: "null",
    1436: "Rune essence",
    1437: "null",
    1438: "Air talisman",
    1439: "null",
    1440: "Earth talisman",
    1441: "null",
    1442: "Fire talisman",
    1443: "null",
    1444: "Water talisman",
    1445: "null",
    1446: "Body talisman",
    1447: "null",
    1448: "Mind talisman",
    1449: "null",
    1450: "Blood talisman",
    1451: "null",
    1452: "Chaos talisman",
    1453: "null",
    1454: "Cosmic talisman",
    1455: "null",
    1456: "Death talisman",
    1457: "null",
    1458: "Law talisman",
    1459: "Null",
    1460: "Null",
    1461: "null",
    1462: "Nature talisman",
    1463: "null",
    1464: "Archery ticket",
    1465: "Weapon poison",
    1466: "Sea slug",
    1467: "Damp sticks",
    1468: "Dry sticks",
    1469: "Broken glass",
    1470: "Red bead",
    1471: "null",
    1472: "Yellow bead",
    1473: "null",
    1474: "Black bead",
    1475: "null",
    1476: "White bead",
    1477: "null",
    1478: "Amulet of accuracy",
    1479: "null",
    1480: "Rock",
    1481: "Orb of light",
    1482: "Orb of light",
    1483: "Orb of light",
    1484: "Orb of light",
    1485: "Oily cloth",
    1486: "Piece of railing",
    1487: "Unicorn horn",
    1488: "Paladin's badge",
    1489: "Paladin's badge",
    1490: "Paladin's badge",
    1491: "Witch's cat",
    1492: "Doll of iban",
    1493: "Old journal",
    1494: "History of iban",
    1495: "Klank's gauntlets",
    1496: "Iban's dove",
    1497: "Amulet of othanian",
    1498: "Amulet of doomion",
    1499: "Amulet of holthion",
    1500: "Iban's shadow",
    1501: "Dwarf brew",
    1502: "Iban's ashes",
    1503: "Warrant",
    1504: "Hangover cure",
    1505: "Ardougne teleport scroll",
    1506: "Gas mask",
    1507: "A small key",
    1508: "A scruffy note",
    1509: "Book",
    1510: "Picture",
    1511: "Logs",
    1512: "null",
    1513: "Magic logs",
    1514: "null",
    1515: "Yew logs",
    1516: "null",
    1517: "Maple logs",
    1518: "null",
    1519: "Willow logs",
    1520: "null",
    1521: "Oak logs",
    1522: "null",
    1523: "Lockpick",
    1524: "null",
    1525: "Grimy snake weed",
    1526: "Snake weed",
    1527: "Grimy ardrigal",
    1528: "Ardrigal",
    1529: "Grimy sito foil",
    1530: "Sito foil",
    1531: "Grimy volencia moss",
    1532: "Volencia moss",
    1533: "Grimy rogue's purse",
    1534: "Rogue's purse",
    1535: "Map part",
    1536: "Map part",
    1537: "Map part",
    1538: "Crandor map",
    1539: "Steel nails",
    1540: "Anti-dragon shield",
    1541: "null",
    1542: "Maze key",
    1543: "Key",
    1544: "Key",
    1545: "Key",
    1546: "Key",
    1547: "Key",
    1548: "Key",
    1549: "Stake",
    1550: "Garlic",
    1551: "null",
    1552: "Seasoned sardine",
    1553: "null",
    1554: "Fluffs' kitten",
    1555: "Pet kitten",
    1556: "Pet kitten",
    1557: "Pet kitten",
    1558: "Pet kitten",
    1559: "Pet kitten",
    1560: "Pet kitten",
    1561: "Pet cat",
    1562: "Pet cat",
    1563: "Pet cat",
    1564: "Pet cat",
    1565: "Pet cat",
    1566: "Pet cat",
    1567: "Pet cat",
    1568: "Pet cat",
    1569: "Pet cat",
    1570: "Pet cat",
    1571: "Pet cat",
    1572: "Pet cat",
    1573: "Doogle leaves",
    1574: "null",
    1575: "Cat training medal",
    1576: "null",
    1577: "Pete's candlestick",
    1578: "null",
    1579: "Thieves' armband",
    1580: "Ice gloves",
    1581: "Blamish snail slime",
    1582: "Blamish oil",
    1583: "Fire feather",
    1584: "Id papers",
    1585: "Oily fishing rod",
    1586: "Miscellaneous key",
    1587: "null",
    1588: "Grip's keyring",
    1589: "null",
    1590: "Dusty key",
    1591: "Jail key",
    1592: "Ring mould",
    1593: "null",
    1594: "Unholy mould",
    1595: "Amulet mould",
    1596: "null",
    1597: "Necklace mould",
    1598: "null",
    1599: "Holy mould",
    1600: "null",
    1601: "Diamond",
    1602: "null",
    1603: "Ruby",
    1604: "null",
    1605: "Emerald",
    1606: "null",
    1607: "Sapphire",
    1608: "null",
    1609: "Opal",
    1610: "null",
    1611: "Jade",
    1612: "null",
    1613: "Red topaz",
    1614: "null",
    1615: "Dragonstone",
    1616: "null",
    1617: "Uncut diamond",
    1618: "null",
    1619: "Uncut ruby",
    1620: "null",
    1621: "Uncut emerald",
    1622: "null",
    1623: "Uncut sapphire",
    1624: "null",
    1625: "Uncut opal",
    1626: "null",
    1627: "Uncut jade",
    1628: "null",
    1629: "Uncut red topaz",
    1630: "null",
    1631: "Uncut dragonstone",
    1632: "null",
    1633: "Crushed gem",
    1634: "null",
    1635: "Gold ring",
    1636: "null",
    1637: "Sapphire ring",
    1638: "null",
    1639: "Emerald ring",
    1640: "null",
    1641: "Ruby ring",
    1642: "null",
    1643: "Diamond ring",
    1644: "null",
    1645: "Dragonstone ring",
    1646: "null",
    1647: "null",
    1648: "null",
    1649: "null",
    1650: "null",
    1651: "null",
    1652: "null",
    1653: "null",
    1654: "Gold necklace",
    1655: "null",
    1656: "Sapphire necklace",
    1657: "null",
    1658: "Emerald necklace",
    1659: "null",
    1660: "Ruby necklace",
    1661: "null",
    1662: "Diamond necklace",
    1663: "null",
    1664: "Dragon necklace",
    1665: "null",
    1666: "null",
    1667: "null",
    1668: "null",
    1669: "null",
    1670: "null",
    1671: "null",
    1672: "null",
    1673: "Gold amulet (u)",
    1674: "null",
    1675: "Sapphire amulet (u)",
    1676: "null",
    1677: "Emerald amulet (u)",
    1678: "null",
    1679: "Ruby amulet (u)",
    1680: "null",
    1681: "Diamond amulet (u)",
    1682: "null",
    1683: "Dragonstone amulet (u)",
    1684: "null",
    1685: "null",
    1686: "Karamja gloves",
    1687: "null",
    1688: "null",
    1689: "null",
    1690: "null",
    1691: "null",
    1692: "Gold amulet",
    1693: "null",
    1694: "Sapphire amulet",
    1695: "null",
    1696: "Emerald amulet",
    1697: "null",
    1698: "Ruby amulet",
    1699: "null",
    1700: "Diamond amulet",
    1701: "null",
    1702: "Dragonstone amulet",
    1703: "null",
    1704: "Amulet of glory",
    1705: "null",
    1706: "Amulet of glory(1)",
    1707: "null",
    1708: "Amulet of glory(2)",
    1709: "null",
    1710: "Amulet of glory(3)",
    1711: "null",
    1712: "Amulet of glory(4)",
    1713: "null",
    1714: "Unstrung symbol",
    1715: "null",
    1716: "Unblessed symbol",
    1717: "null",
    1718: "Holy symbol",
    1719: "null",
    1720: "Unstrung emblem",
    1721: "null",
    1722: "Unpowered symbol",
    1723: "null",
    1724: "Unholy symbol",
    1725: "Amulet of strength",
    1726: "null",
    1727: "Amulet of magic",
    1728: "null",
    1729: "Amulet of defence",
    1730: "null",
    1731: "Amulet of power",
    1732: "null",
    1733: "Needle",
    1734: "Thread",
    1735: "Shears",
    1736: "null",
    1737: "Wool",
    1738: "null",
    1739: "Cowhide",
    1740: "null",
    1741: "Leather",
    1742: "null",
    1743: "Hard leather",
    1744: "null",
    1745: "Green dragon leather",
    1746: "null",
    1747: "Black dragonhide",
    1748: "null",
    1749: "Red dragonhide",
    1750: "null",
    1751: "Blue dragonhide",
    1752: "null",
    1753: "Green dragonhide",
    1754: "null",
    1755: "Chisel",
    1756: "null",
    1757: "Brown apron",
    1758: "null",
    1759: "Ball of wool",
    1760: "null",
    1761: "Soft clay",
    1762: "null",
    1763: "Red dye",
    1764: "null",
    1765: "Yellow dye",
    1766: "null",
    1767: "Blue dye",
    1768: "null",
    1769: "Orange dye",
    1770: "null",
    1771: "Green dye",
    1772: "null",
    1773: "Purple dye",
    1774: "null",
    1775: "Molten glass",
    1776: "null",
    1777: "Bow string",
    1778: "null",
    1779: "Flax",
    1780: "null",
    1781: "Soda ash",
    1782: "null",
    1783: "Bucket of sand",
    1784: "null",
    1785: "Glassblowing pipe",
    1786: "null",
    1787: "Unfired pot",
    1788: "null",
    1789: "Unfired pie dish",
    1790: "null",
    1791: "Unfired bowl",
    1792: "null",
    1793: "Woad leaf",
    1794: "Bronze wire",
    1795: "null",
    1796: "Silver necklace",
    1797: "Silver necklace",
    1798: "Silver cup",
    1799: "Silver cup",
    1800: "Silver bottle",
    1801: "Silver bottle",
    1802: "Silver book",
    1803: "Silver book",
    1804: "Silver needle",
    1805: "Silver needle",
    1806: "Silver pot",
    1807: "Silver pot",
    1808: "Criminal's thread",
    1809: "Criminal's thread",
    1810: "Criminal's thread",
    1811: "Flypaper",
    1812: "Pungent pot",
    1813: "Criminal's dagger",
    1814: "Criminal's dagger",
    1815: "Killer's print",
    1816: "Anna's print",
    1817: "Bob's print",
    1818: "Carol's print",
    1819: "David's print",
    1820: "Elizabeth's print",
    1821: "Frank's print",
    1822: "Unknown print",
    1823: "Waterskin(4)",
    1824: "null",
    1825: "Waterskin(3)",
    1826: "null",
    1827: "Waterskin(2)",
    1828: "null",
    1829: "Waterskin(1)",
    1830: "null",
    1831: "Waterskin(0)",
    1832: "null",
    1833: "Desert shirt",
    1834: "null",
    1835: "Desert robe",
    1836: "null",
    1837: "Desert boots",
    1838: "null",
    1839: "Metal key",
    1840: "Cell door key",
    1841: "Barrel",
    1842: "Ana in a barrel",
    1843: "Wrought iron key",
    1844: "Slave shirt",
    1845: "Slave robe",
    1846: "Slave boots",
    1847: "Scrumpled paper",
    1848: "Shantay disclaimer",
    1849: "Prototype dart",
    1850: "Technical plans",
    1851: "Tenti pineapple",
    1852: "Bedabin key",
    1853: "Prototype dart tip",
    1854: "Shantay pass",
    1855: "Rock",
    1856: "Guide book",
    1857: "Totem",
    1858: "Address label",
    1859: "Raw ugthanki meat",
    1860: "null",
    1861: "Ugthanki meat",
    1862: "null",
    1863: "Pitta dough",
    1864: "null",
    1865: "Pitta bread",
    1866: "null",
    1867: "Burnt pitta bread",
    1868: "null",
    1869: "Chopped tomato",
    1870: "null",
    1871: "Chopped onion",
    1872: "null",
    1873: "Chopped ugthanki",
    1874: "null",
    1875: "Onion & tomato",
    1876: "null",
    1877: "Ugthanki & onion",
    1878: "null",
    1879: "Ugthanki & tomato",
    1880: "null",
    1881: "Kebab mix",
    1882: "null",
    1883: "Ugthanki kebab",
    1884: "null",
    1885: "Ugthanki kebab",
    1886: "null",
    1887: "Cake tin",
    1888: "null",
    1889: "Uncooked cake",
    1890: "null",
    1891: "Cake",
    1892: "null",
    1893: "2/3 cake",
    1894: "null",
    1895: "Slice of cake",
    1896: "null",
    1897: "Chocolate cake",
    1898: "null",
    1899: "2/3 chocolate cake",
    1900: "null",
    1901: "Chocolate slice",
    1902: "null",
    1903: "Burnt cake",
    1904: "null",
    1905: "Asgarnian ale",
    1906: "null",
    1907: "Wizard's mind bomb",
    1908: "null",
    1909: "Greenman's ale",
    1910: "null",
    1911: "Dragon bitter",
    1912: "null",
    1913: "Dwarven stout",
    1914: "null",
    1915: "Grog",
    1916: "null",
    1917: "Beer",
    1918: "null",
    1919: "Beer glass",
    1920: "null",
    1921: "Bowl of water",
    1922: "null",
    1923: "Bowl",
    1924: "null",
    1925: "Bucket",
    1926: "null",
    1927: "Bucket of milk",
    1928: "null",
    1929: "Bucket of water",
    1930: "null",
    1931: "Pot",
    1932: "null",
    1933: "Pot of flour",
    1934: "null",
    1935: "Jug",
    1936: "null",
    1937: "Jug of water",
    1938: "null",
    1939: "Swamp tar",
    1940: "Raw swamp paste",
    1941: "Swamp paste",
    1942: "Potato",
    1943: "null",
    1944: "Egg",
    1945: "null",
    1946: "Flour",
    1947: "Grain",
    1948: "null",
    1949: "Chef's hat",
    1950: "null",
    1951: "Redberries",
    1952: "null",
    1953: "Pastry dough",
    1954: "null",
    1955: "Cooking apple",
    1956: "null",
    1957: "Onion",
    1958: "null",
    1959: "Pumpkin",
    1960: "null",
    1961: "Easter egg",
    1962: "null",
    1963: "Banana",
    1964: "null",
    1965: "Cabbage",
    1966: "null",
    1967: "Cabbage",
    1968: "null",
    1969: "Spinach roll",
    1970: "null",
    1971: "Kebab",
    1972: "null",
    1973: "Chocolate bar",
    1974: "null",
    1975: "Chocolate dust",
    1976: "null",
    1977: "Chocolatey milk",
    1978: "Cup of tea",
    1979: "null",
    1980: "Empty cup",
    1981: "null",
    1982: "Tomato",
    1983: "null",
    1984: "Rotten apple",
    1985: "Cheese",
    1986: "null",
    1987: "Grapes",
    1988: "null",
    1989: "Half full wine jug",
    1990: "null",
    1991: "Jug of bad wine",
    1992: "Jug of bad wine",
    1993: "Jug of wine",
    1994: "null",
    1995: "Unfermented wine",
    1996: "Unfermented wine",
    1997: "Incomplete stew",
    1998: "null",
    1999: "Incomplete stew",
    2000: "null",
    2001: "Uncooked stew",
    2002: "null",
    2003: "Stew",
    2004: "null",
    2005: "Burnt stew",
    2006: "null",
    2007: "Spice",
    2008: "null",
    2009: "Uncooked curry",
    2010: "null",
    2011: "Curry",
    2012: "null",
    2013: "Burnt curry",
    2014: "null",
    2015: "Vodka",
    2016: "null",
    2017: "Whisky",
    2018: "null",
    2019: "Gin",
    2020: "null",
    2021: "Brandy",
    2022: "null",
    2023: "Cocktail guide",
    2024: "null",
    2025: "Cocktail shaker",
    2026: "Cocktail glass",
    2027: "null",
    2028: "Premade blurb' sp.",
    2029: "null",
    2030: "Premade choc s'dy",
    2031: "null",
    2032: "Premade dr' dragon",
    2033: "null",
    2034: "Premade fr' blast",
    2035: "null",
    2036: "Premade p' punch",
    2037: "null",
    2038: "Premade sgg",
    2039: "null",
    2040: "Premade wiz blz'd",
    2041: "null",
    2042: "Unfinished cocktail",
    2043: "null",
    2044: "Unfinished cocktail",
    2045: "null",
    2046: "Unfinished cocktail",
    2047: "null",
    2048: "Pineapple punch",
    2049: "null",
    2050: "Unfinished cocktail",
    2051: "null",
    2052: "Unfinished cocktail",
    2053: "null",
    2054: "Wizard blizzard",
    2055: "null",
    2056: "Unfinished cocktail",
    2057: "null",
    2058: "Unfinished cocktail",
    2059: "null",
    2060: "Unfinished cocktail",
    2061: "null",
    2062: "Unfinished cocktail",
    2063: "null",
    2064: "Blurberry special",
    2065: "null",
    2066: "Unfinished cocktail",
    2067: "null",
    2068: "Unfinished cocktail",
    2069: "null",
    2070: "Unfinished cocktail",
    2071: "null",
    2072: "Unfinished cocktail",
    2073: "null",
    2074: "Choc saturday",
    2075: "null",
    2076: "Unfinished cocktail",
    2077: "null",
    2078: "Unfinished cocktail",
    2079: "null",
    2080: "Short green guy",
    2081: "null",
    2082: "Unfinished cocktail",
    2083: "null",
    2084: "Fruit blast",
    2085: "null",
    2086: "Unfinished cocktail",
    2087: "null",
    2088: "Unfinished cocktail",
    2089: "null",
    2090: "Unfinished cocktail",
    2091: "null",
    2092: "Drunk dragon",
    2093: "null",
    2094: "Odd cocktail",
    2095: "null",
    2096: "Odd cocktail",
    2097: "null",
    2098: "Odd cocktail",
    2099: "null",
    2100: "Odd cocktail",
    2101: "null",
    2102: "Lemon",
    2103: "null",
    2104: "Lemon chunks",
    2105: "null",
    2106: "Lemon slices",
    2107: "null",
    2108: "Orange",
    2109: "null",
    2110: "Orange chunks",
    2111: "null",
    2112: "Orange slices",
    2113: "null",
    2114: "Pineapple",
    2115: "null",
    2116: "Pineapple chunks",
    2117: "null",
    2118: "Pineapple ring",
    2119: "null",
    2120: "Lime",
    2121: "null",
    2122: "Lime chunks",
    2123: "null",
    2124: "Lime slices",
    2125: "null",
    2126: "Dwellberries",
    2127: "null",
    2128: "Equa leaves",
    2129: "null",
    2130: "Pot of cream",
    2131: "null",
    2132: "Raw beef",
    2133: "null",
    2134: "Raw rat meat",
    2135: "null",
    2136: "Raw bear meat",
    2137: "null",
    2138: "Raw chicken",
    2139: "null",
    2140: "Cooked chicken",
    2141: "null",
    2142: "Cooked meat",
    2143: "null",
    2144: "Burnt chicken",
    2145: "null",
    2146: "Burnt meat",
    2147: "null",
    2148: "Raw lava eel",
    2149: "Lava eel",
    2150: "Swamp toad",
    2151: "null",
    2152: "Toad's legs",
    2153: "null",
    2154: "Equa toad's legs",
    2155: "null",
    2156: "Spicy toad's legs",
    2157: "null",
    2158: "Seasoned legs",
    2159: "null",
    2160: "Spicy worm",
    2161: "null",
    2162: "King worm",
    2163: "null",
    2164: "Batta tin",
    2165: "Crunchy tray",
    2166: "Gnomebowl mould",
    2167: "Gianne's cook book",
    2168: "null",
    2169: "Gnome spice",
    2170: "null",
    2171: "Gianne dough",
    2172: "null",
    2173: "Odd gnomebowl",
    2174: "null",
    2175: "Burnt gnomebowl",
    2176: "null",
    2177: "Half baked bowl",
    2178: "Raw gnomebowl",
    2179: "Unfinished bowl",
    2180: "null",
    2181: "Unfinished bowl",
    2182: "null",
    2183: "Unfinished bowl",
    2184: "null",
    2185: "Chocolate bomb",
    2186: "null",
    2187: "Tangled toad's legs",
    2188: "null",
    2189: "Unfinished bowl",
    2190: "null",
    2191: "Worm hole",
    2192: "null",
    2193: "Unfinished bowl",
    2194: "null",
    2195: "Veg ball",
    2196: "null",
    2197: "Odd crunchies",
    2198: "null",
    2199: "Burnt crunchies",
    2200: "null",
    2201: "Half baked crunchy",
    2202: "Raw crunchies",
    2203: "Rock-climbing boots",
    2204: "null",
    2205: "Worm crunchies",
    2206: "null",
    2207: "Unfinished crunchy",
    2208: "null",
    2209: "Chocchip crunchies",
    2210: "null",
    2211: "Unfinished crunchy",
    2212: "null",
    2213: "Spicy crunchies",
    2214: "null",
    2215: "Unfinished crunchy",
    2216: "null",
    2217: "Toad crunchies",
    2218: "null",
    2219: "Premade w'm batta",
    2220: "null",
    2221: "Premade t'd batta",
    2222: "null",
    2223: "Premade c+t batta",
    2224: "null",
    2225: "Premade fr't batta",
    2226: "null",
    2227: "Premade veg batta",
    2228: "null",
    2229: "Premade choc bomb",
    2230: "null",
    2231: "Premade ttl",
    2232: "null",
    2233: "Premade worm hole",
    2234: "null",
    2235: "Premade veg ball",
    2236: "null",
    2237: "Premade w'm crun'",
    2238: "null",
    2239: "Premade ch' crunch",
    2240: "null",
    2241: "Premade s'y crunch",
    2242: "null",
    2243: "Premade t'd crunch",
    2244: "null",
    2245: "Odd batta",
    2246: "null",
    2247: "Burnt batta",
    2248: "null",
    2249: "Half baked batta",
    2250: "Raw batta",
    2251: "Unfinished batta",
    2252: "null",
    2253: "Worm batta",
    2254: "null",
    2255: "Toad batta",
    2256: "null",
    2257: "Unfinished batta",
    2258: "null",
    2259: "Cheese+tom batta",
    2260: "null",
    2261: "Unfinished batta",
    2262: "null",
    2263: "Unfinished batta",
    2264: "null",
    2265: "Unfinished batta",
    2266: "null",
    2267: "Unfinished batta",
    2268: "null",
    2269: "Unfinished batta",
    2270: "null",
    2271: "Unfinished batta",
    2272: "null",
    2273: "Unfinished batta",
    2274: "null",
    2275: "Unfinished batta",
    2276: "null",
    2277: "Fruit batta",
    2278: "null",
    2279: "Unfinished batta",
    2280: "null",
    2281: "Vegetable batta",
    2282: "null",
    2283: "Pizza base",
    2284: "null",
    2285: "Incomplete pizza",
    2286: "null",
    2287: "Uncooked pizza",
    2288: "null",
    2289: "Plain pizza",
    2290: "null",
    2291: "1/2 plain pizza",
    2292: "null",
    2293: "Meat pizza",
    2294: "null",
    2295: "1/2 meat pizza",
    2296: "null",
    2297: "Anchovy pizza",
    2298: "null",
    2299: "1/2 anchovy pizza",
    2300: "null",
    2301: "Pineapple pizza",
    2302: "null",
    2303: "1/2 pineapple pizza",
    2304: "null",
    2305: "Burnt pizza",
    2306: "null",
    2307: "Bread dough",
    2308: "null",
    2309: "Bread",
    2310: "null",
    2311: "Burnt bread",
    2312: "null",
    2313: "Pie dish",
    2314: "null",
    2315: "Pie shell",
    2316: "null",
    2317: "Uncooked apple pie",
    2318: "null",
    2319: "Uncooked meat pie",
    2320: "null",
    2321: "Uncooked berry pie",
    2322: "null",
    2323: "Apple pie",
    2324: "null",
    2325: "Redberry pie",
    2326: "null",
    2327: "Meat pie",
    2328: "null",
    2329: "Burnt pie",
    2330: "null",
    2331: "Half a meat pie",
    2332: "null",
    2333: "Half a redberry pie",
    2334: "null",
    2335: "Half an apple pie",
    2336: "null",
    2337: "Raw oomlie",
    2338: "null",
    2339: "Palm leaf",
    2340: "Palm leaf",
    2341: "Wrapped oomlie",
    2342: "null",
    2343: "Cooked oomlie wrap",
    2344: "null",
    2345: "Burnt oomlie wrap",
    2346: "null",
    2347: "Hammer",
    2348: "null",
    2349: "Bronze bar",
    2350: "null",
    2351: "Iron bar",
    2352: "null",
    2353: "Steel bar",
    2354: "null",
    2355: "Silver bar",
    2356: "null",
    2357: "Gold bar",
    2358: "null",
    2359: "Mithril bar",
    2360: "null",
    2361: "Adamantite bar",
    2362: "null",
    2363: "Runite bar",
    2364: "null",
    2365: "'perfect' gold bar",
    2366: "Shield left half",
    2367: "null",
    2368: "Shield right half",
    2369: "null",
    2370: "Steel studs",
    2371: "null",
    2372: "Ogre relic",
    2373: "Relic part 1",
    2374: "Relic part 2",
    2375: "Relic part 3",
    2376: "Skavid map",
    2377: "Ogre tooth",
    2378: "Toban's key",
    2379: "Rock cake",
    2380: "Crystal",
    2381: "Crystal",
    2382: "Crystal",
    2383: "Crystal",
    2384: "Fingernails",
    2385: "Old robe",
    2386: "Unusual armour",
    2387: "Damaged dagger",
    2388: "Tattered eye patch",
    2389: "Vial",
    2390: "Vial",
    2391: "Ground bat bones",
    2392: "null",
    2393: "Toban's gold",
    2394: "Potion",
    2395: "Magic ogre potion",
    2396: "Spell scroll",
    2397: "Shaman robe",
    2398: "Cave nightshade",
    2399: "Silverlight key",
    2400: "Silverlight key",
    2401: "Silverlight key",
    2402: "Silverlight",
    2403: "Hazeel scroll",
    2404: "Chest key",
    2405: "Carnillean armour",
    2406: "Hazeel's mark",
    2407: "Ball",
    2408: "Diary",
    2409: "Door key",
    2410: "Magnet",
    2411: "Key",
    2412: "Saradomin cape",
    2413: "Guthix cape",
    2414: "Zamorak cape",
    2415: "Saradomin staff",
    2416: "Guthix staff",
    2417: "Zamorak staff",
    2418: "Bronze key",
    2419: "Wig",
    2420: "null",
    2421: "Wig",
    2422: "Blue partyhat",
    2423: "Key print",
    2424: "Paste",
    2425: "Vorkath's head",
    2426: "Burnt oomlie",
    2427: "null",
    2428: "Attack potion(4)",
    2429: "null",
    2430: "Restore potion(4)",
    2431: "null",
    2432: "Defence potion(4)",
    2433: "null",
    2434: "Prayer potion(4)",
    2435: "null",
    2436: "Super attack(4)",
    2437: "null",
    2438: "Fishing potion(4)",
    2439: "null",
    2440: "Super strength(4)",
    2441: "null",
    2442: "Super defence(4)",
    2443: "null",
    2444: "Ranging potion(4)",
    2445: "null",
    2446: "Antipoison(4)",
    2447: "null",
    2448: "Superantipoison(4)",
    2449: "null",
    2450: "Zamorak brew(4)",
    2451: "null",
    2452: "Antifire potion(4)",
    2453: "null",
    2454: "Antifire potion(3)",
    2455: "null",
    2456: "Antifire potion(2)",
    2457: "null",
    2458: "Antifire potion(1)",
    2459: "null",
    2460: "Assorted flowers",
    2461: "null",
    2462: "Red flowers",
    2463: "null",
    2464: "Blue flowers",
    2465: "null",
    2466: "Yellow flowers",
    2467: "null",
    2468: "Purple flowers",
    2469: "null",
    2470: "Orange flowers",
    2471: "null",
    2472: "Mixed flowers",
    2473: "null",
    2474: "White flowers",
    2475: "null",
    2476: "Black flowers",
    2477: "null",
    2478: "null",
    2479: "null",
    2480: "null",
    2481: "Lantadyme",
    2482: "null",
    2483: "Lantadyme potion (unf)",
    2484: "null",
    2485: "Grimy lantadyme",
    2486: "null",
    2487: "Blue d'hide vambraces",
    2488: "null",
    2489: "Red d'hide vambraces",
    2490: "null",
    2491: "Black d'hide vambraces",
    2492: "null",
    2493: "Blue d'hide chaps",
    2494: "null",
    2495: "Red d'hide chaps",
    2496: "null",
    2497: "Black d'hide chaps",
    2498: "null",
    2499: "Blue d'hide body",
    2500: "null",
    2501: "Red d'hide body",
    2502: "null",
    2503: "Black d'hide body",
    2504: "null",
    2505: "Blue dragon leather",
    2506: "null",
    2507: "Red dragon leather",
    2508: "null",
    2509: "Black dragon leather",
    2510: "null",
    2511: "Logs",
    2512: "null",
    2513: "Dragon chainbody",
    2514: "Raw shrimps",
    2515: "null",
    2516: "Pot of flour",
    2517: "null",
    2518: "Rotten tomato",
    2519: "null",
    2520: "Brown toy horsey",
    2521: "null",
    2522: "White toy horsey",
    2523: "null",
    2524: "Black toy horsey",
    2525: "null",
    2526: "Grey toy horsey",
    2527: "null",
    2528: "Lamp",
    2529: "Dead orb",
    2530: "Bones",
    2531: "null",
    2532: "Iron fire arrow",
    2533: "Iron fire arrow (lit)",
    2534: "Steel fire arrow",
    2535: "Steel fire arrow (lit)",
    2536: "Mithril fire arrow",
    2537: "Mithril fire arrow (lit)",
    2538: "Adamant fire arrow",
    2539: "Adamant fire arrow (lit)",
    2540: "Rune fire arrow",
    2541: "Rune fire arrow (lit)",
    2542: "null",
    2543: "null",
    2544: "null",
    2545: "null",
    2546: "null",
    2547: "null",
    2548: "null",
    2549: "null",
    2550: "Ring of recoil",
    2551: "null",
    2552: "Ring of dueling(8)",
    2553: "null",
    2554: "Ring of dueling(7)",
    2555: "null",
    2556: "Ring of dueling(6)",
    2557: "null",
    2558: "Ring of dueling(5)",
    2559: "null",
    2560: "Ring of dueling(4)",
    2561: "null",
    2562: "Ring of dueling(3)",
    2563: "null",
    2564: "Ring of dueling(2)",
    2565: "null",
    2566: "Ring of dueling(1)",
    2567: "null",
    2568: "Ring of forging",
    2569: "null",
    2570: "Ring of life",
    2571: "null",
    2572: "Ring of wealth",
    2573: "null",
    2574: "Sextant",
    2575: "Watch",
    2576: "Chart",
    2577: "Ranger boots",
    2578: "null",
    2579: "Wizard boots",
    2580: "null",
    2581: "Robin hood hat",
    2582: "null",
    2583: "Black platebody (t)",
    2584: "null",
    2585: "Black platelegs (t)",
    2586: "null",
    2587: "Black full helm (t)",
    2588: "null",
    2589: "Black kiteshield (t)",
    2590: "null",
    2591: "Black platebody (g)",
    2592: "null",
    2593: "Black platelegs (g)",
    2594: "null",
    2595: "Black full helm (g)",
    2596: "null",
    2597: "Black kiteshield (g)",
    2598: "null",
    2599: "Adamant platebody (t)",
    2600: "null",
    2601: "Adamant platelegs (t)",
    2602: "null",
    2603: "Adamant kiteshield (t)",
    2604: "null",
    2605: "Adamant full helm (t)",
    2606: "null",
    2607: "Adamant platebody (g)",
    2608: "null",
    2609: "Adamant platelegs (g)",
    2610: "null",
    2611: "Adamant kiteshield (g)",
    2612: "null",
    2613: "Adamant full helm (g)",
    2614: "null",
    2615: "Rune platebody (g)",
    2616: "null",
    2617: "Rune platelegs (g)",
    2618: "null",
    2619: "Rune full helm (g)",
    2620: "null",
    2621: "Rune kiteshield (g)",
    2622: "null",
    2623: "Rune platebody (t)",
    2624: "null",
    2625: "Rune platelegs (t)",
    2626: "null",
    2627: "Rune full helm (t)",
    2628: "null",
    2629: "Rune kiteshield (t)",
    2630: "null",
    2631: "Highwayman mask",
    2632: "null",
    2633: "Blue beret",
    2634: "null",
    2635: "Black beret",
    2636: "null",
    2637: "White beret",
    2638: "null",
    2639: "Tan cavalier",
    2640: "null",
    2641: "Dark cavalier",
    2642: "null",
    2643: "Black cavalier",
    2644: "null",
    2645: "Red headband",
    2646: "null",
    2647: "Black headband",
    2648: "null",
    2649: "Brown headband",
    2650: "null",
    2651: "Pirate's hat",
    2652: "null",
    2653: "Zamorak platebody",
    2654: "null",
    2655: "Zamorak platelegs",
    2656: "null",
    2657: "Zamorak full helm",
    2658: "null",
    2659: "Zamorak kiteshield",
    2660: "null",
    2661: "Saradomin platebody",
    2662: "null",
    2663: "Saradomin platelegs",
    2664: "null",
    2665: "Saradomin full helm",
    2666: "null",
    2667: "Saradomin kiteshield",
    2668: "null",
    2669: "Guthix platebody",
    2670: "null",
    2671: "Guthix platelegs",
    2672: "null",
    2673: "Guthix full helm",
    2674: "null",
    2675: "Guthix kiteshield",
    2676: "null",
    2677: "Clue scroll (easy)",
    2678: "Clue scroll (easy)",
    2679: "Clue scroll (easy)",
    2680: "Clue scroll (easy)",
    2681: "Clue scroll (easy)",
    2682: "Clue scroll (easy)",
    2683: "Clue scroll (easy)",
    2684: "Clue scroll (easy)",
    2685: "Clue scroll (easy)",
    2686: "Clue scroll (easy)",
    2687: "Clue scroll (easy)",
    2688: "Clue scroll (easy)",
    2689: "Clue scroll (easy)",
    2690: "Clue scroll (easy)",
    2691: "Clue scroll (easy)",
    2692: "Clue scroll (easy)",
    2693: "Clue scroll (easy)",
    2694: "Clue scroll (easy)",
    2695: "Clue scroll (easy)",
    2696: "Clue scroll (easy)",
    2697: "Clue scroll (easy)",
    2698: "Clue scroll (easy)",
    2699: "Clue scroll (easy)",
    2700: "Clue scroll (easy)",
    2701: "Clue scroll (easy)",
    2702: "Clue scroll (easy)",
    2703: "Clue scroll (easy)",
    2704: "Clue scroll (easy)",
    2705: "Clue scroll (easy)",
    2706: "Clue scroll (easy)",
    2707: "Clue scroll (easy)",
    2708: "Clue scroll (easy)",
    2709: "Clue scroll (easy)",
    2710: "Clue scroll (easy)",
    2711: "Clue scroll (easy)",
    2712: "Clue scroll (easy)",
    2713: "Clue scroll (easy)",
    2714: "Casket (easy)",
    2715: "Casket (easy)",
    2716: "Clue scroll (easy)",
    2717: "Casket (easy)",
    2718: "Casket (easy)",
    2719: "Clue scroll (easy)",
    2720: "Casket (easy)",
    2721: "Casket (easy)",
    2722: "Clue scroll (hard)",
    2723: "Clue scroll (hard)",
    2724: "Casket (hard)",
    2725: "Clue scroll (hard)",
    2726: "Casket (hard)",
    2727: "Clue scroll (hard)",
    2728: "Casket (hard)",
    2729: "Clue scroll (hard)",
    2730: "Casket (hard)",
    2731: "Clue scroll (hard)",
    2732: "Casket (hard)",
    2733: "Clue scroll (hard)",
    2734: "Casket (hard)",
    2735: "Clue scroll (hard)",
    2736: "Casket (hard)",
    2737: "Clue scroll (hard)",
    2738: "Casket (hard)",
    2739: "Clue scroll (hard)",
    2740: "Casket (hard)",
    2741: "Clue scroll (hard)",
    2742: "Casket (hard)",
    2743: "Clue scroll (hard)",
    2744: "Casket (hard)",
    2745: "Clue scroll (hard)",
    2746: "Casket (hard)",
    2747: "Clue scroll (hard)",
    2748: "Casket (hard)",
    2749: "",
    2750: "",
    2751: "",
    2752: "",
    2753: "",
    2754: "",
    2755: "",
    2756: "",
    2757: "",
    2758: "",
    2759: "",
    2760: "",
    2761: "",
    2762: "",
    2763: "",
    2764: "",
    2765: "",
    2766: "",
    2767: "",
    2768: "",
    2769: "",
    2770: "",
    2771: "",
    2772: "",
    2773: "Clue scroll (hard)",
    2774: "Clue scroll (hard)",
    2775: "Casket (hard)",
    2776: "Clue scroll (hard)",
    2777: "Casket (hard)",
    2778: "Clue scroll (hard)",
    2779: "Casket (hard)",
    2780: "Clue scroll (hard)",
    2781: "Casket (hard)",
    2782: "Clue scroll (hard)",
    2783: "Clue scroll (hard)",
    2784: "Casket (hard)",
    2785: "Clue scroll (hard)",
    2786: "Clue scroll (hard)",
    2787: "Casket (hard)",
    2788: "Clue scroll (hard)",
    2789: "Casket (hard)",
    2790: "Clue scroll (hard)",
    2791: "Casket (hard)",
    2792: "Clue scroll (hard)",
    2793: "Clue scroll (hard)",
    2794: "Clue scroll (hard)",
    2795: "Puzzle box (hard)",
    2796: "Clue scroll (hard)",
    2797: "Clue scroll (hard)",
    2798: "Puzzle box (hard)",
    2799: "Clue scroll (hard)",
    2800: "Puzzle box (hard)",
    2801: "Clue scroll (medium)",
    2802: "Casket (medium)",
    2803: "Clue scroll (medium)",
    2804: "Casket (medium)",
    2805: "Clue scroll (medium)",
    2806: "Casket (medium)",
    2807: "Clue scroll (medium)",
    2808: "Casket (medium)",
    2809: "Clue scroll (medium)",
    2810: "Casket (medium)",
    2811: "Clue scroll (medium)",
    2812: "Casket (medium)",
    2813: "Clue scroll (medium)",
    2814: "Casket (medium)",
    2815: "Clue scroll (medium)",
    2816: "Casket (medium)",
    2817: "Clue scroll (medium)",
    2818: "Casket (medium)",
    2819: "Clue scroll (medium)",
    2820: "Casket (medium)",
    2821: "Clue scroll (medium)",
    2822: "Casket (medium)",
    2823: "Clue scroll (medium)",
    2824: "Casket (medium)",
    2825: "Clue scroll (medium)",
    2826: "Casket (medium)",
    2827: "Clue scroll (medium)",
    2828: "Casket (medium)",
    2829: "Clue scroll (medium)",
    2830: "Casket (medium)",
    2831: "Clue scroll (medium)",
    2832: "Key (medium)",
    2833: "Clue scroll (medium)",
    2834: "Key (medium)",
    2835: "Clue scroll (medium)",
    2836: "Key (medium)",
    2837: "Clue scroll (medium)",
    2838: "Key (medium)",
    2839: "Clue scroll (medium)",
    2840: "Key (medium)",
    2841: "Clue scroll (medium)",
    2842: "Challenge scroll (medium)",
    2843: "Clue scroll (medium)",
    2844: "Challenge scroll (medium)",
    2845: "Clue scroll (medium)",
    2846: "Challenge scroll (medium)",
    2847: "Clue scroll (medium)",
    2848: "Clue scroll (medium)",
    2849: "Clue scroll (medium)",
    2850: "Challenge scroll (medium)",
    2851: "Clue scroll (medium)",
    2852: "Challenge scroll (medium)",
    2853: "Clue scroll (medium)",
    2854: "Challenge scroll (medium)",
    2855: "Clue scroll (medium)",
    2856: "Clue scroll (medium)",
    2857: "Clue scroll (medium)",
    2858: "Clue scroll (medium)",
    2859: "Wolf bones",
    2860: "null",
    2861: "Wolfbone arrowtips",
    2862: "Achey tree logs",
    2863: "null",
    2864: "Ogre arrow shaft",
    2865: "Flighted ogre arrow",
    2866: "Ogre arrow",
    2867: "null",
    2868: "null",
    2869: "null",
    2870: "null",
    2871: "Ogre bellows",
    2872: "Ogre bellows (3)",
    2873: "Ogre bellows (2)",
    2874: "Ogre bellows (1)",
    2875: "Bloated toad",
    2876: "Raw chompy",
    2877: "null",
    2878: "Cooked chompy",
    2879: "null",
    2880: "Ruined chompy",
    2881: "null",
    2882: "Seasoned chompy",
    2883: "Ogre bow",
    2884: "null",
    2885: "Null",
    2886: "Battered book",
    2887: "Battered key",
    2888: "A stone bowl",
    2889: "A stone bowl",
    2890: "Elemental shield",
    2891: "null",
    2892: "Elemental ore",
    2893: "Elemental metal",
    2894: "Grey boots",
    2895: "null",
    2896: "Grey robe top",
    2897: "null",
    2898: "Grey robe bottoms",
    2899: "null",
    2900: "Grey hat",
    2901: "null",
    2902: "Grey gloves",
    2903: "null",
    2904: "Red boots",
    2905: "null",
    2906: "Red robe top",
    2907: "null",
    2908: "Red robe bottoms",
    2909: "null",
    2910: "Red hat",
    2911: "null",
    2912: "Red gloves",
    2913: "null",
    2914: "Yellow boots",
    2915: "null",
    2916: "Yellow robe top",
    2917: "null",
    2918: "Yellow robe bottoms",
    2919: "null",
    2920: "Yellow hat",
    2921: "null",
    2922: "Yellow gloves",
    2923: "null",
    2924: "Teal boots",
    2925: "null",
    2926: "Teal robe top",
    2927: "null",
    2928: "Teal robe bottoms",
    2929: "null",
    2930: "Teal hat",
    2931: "null",
    2932: "Teal gloves",
    2933: "null",
    2934: "Purple boots",
    2935: "null",
    2936: "Purple robe top",
    2937: "null",
    2938: "Purple robe bottoms",
    2939: "null",
    2940: "Purple hat",
    2941: "null",
    2942: "Purple gloves",
    2943: "null",
    2944: "Golden key",
    2945: "Iron key",
    2946: "Golden tinderbox",
    2947: "Golden candle",
    2948: "Golden pot",
    2949: "Golden hammer",
    2950: "Golden feather",
    2951: "Golden needle",
    2952: "Wolfbane",
    2953: "Murky water",
    2954: "Blessed water",
    2955: "Moonlight mead",
    2956: "null",
    2957: "Druid pouch",
    2958: "Druid pouch",
    2959: "Rotten food",
    2960: "null",
    2961: "Silver sickle",
    2962: "null",
    2963: "Silver sickle (b)",
    2964: "Washing bowl",
    2965: "null",
    2966: "Mirror",
    2967: "Journal",
    2968: "Druidic spell",
    2969: "A used spell",
    2970: "Mort myre fungus",
    2971: "null",
    2972: "Mort myre stem",
    2973: "null",
    2974: "Mort myre pear",
    2975: "null",
    2976: "Sickle mould",
    2977: "null",
    2978: "Chompy bird hat",
    2979: "Chompy bird hat",
    2980: "Chompy bird hat",
    2981: "Chompy bird hat",
    2982: "Chompy bird hat",
    2983: "Chompy bird hat",
    2984: "Chompy bird hat",
    2985: "Chompy bird hat",
    2986: "Chompy bird hat",
    2987: "Chompy bird hat",
    2988: "Chompy bird hat",
    2989: "Chompy bird hat",
    2990: "Chompy bird hat",
    2991: "Chompy bird hat",
    2992: "Chompy bird hat",
    2993: "Chompy bird hat",
    2994: "Chompy bird hat",
    2995: "Chompy bird hat",
    2996: "Agility arena ticket",
    2997: "Pirate's hook",
    2998: "Toadflax",
    2999: "null",
    3000: "Snapdragon",
    3001: "null",
    3002: "Toadflax potion (unf)",
    3003: "null",
    3004: "Snapdragon potion (unf)",
    3005: "null",
    3006: "Firework",
    3007: "null",
    3008: "Energy potion(4)",
    3009: "null",
    3010: "Energy potion(3)",
    3011: "null",
    3012: "Energy potion(2)",
    3013: "null",
    3014: "Energy potion(1)",
    3015: "null",
    3016: "Super energy(4)",
    3017: "null",
    3018: "Super energy(3)",
    3019: "null",
    3020: "Super energy(2)",
    3021: "null",
    3022: "Super energy(1)",
    3023: "null",
    3024: "Super restore(4)",
    3025: "null",
    3026: "Super restore(3)",
    3027: "null",
    3028: "Super restore(2)",
    3029: "null",
    3030: "Super restore(1)",
    3031: "null",
    3032: "Agility potion(4)",
    3033: "null",
    3034: "Agility potion(3)",
    3035: "null",
    3036: "Agility potion(2)",
    3037: "null",
    3038: "Agility potion(1)",
    3039: "null",
    3040: "Magic potion(4)",
    3041: "null",
    3042: "Magic potion(3)",
    3043: "null",
    3044: "Magic potion(2)",
    3045: "null",
    3046: "Magic potion(1)",
    3047: "null",
    3048: "null",
    3049: "Grimy toadflax",
    3050: "null",
    3051: "Grimy snapdragon",
    3052: "null",
    3053: "Lava battlestaff",
    3054: "Mystic lava staff",
    3055: "null",
    3056: "null",
    3057: "Mime mask",
    3058: "Mime top",
    3059: "Mime legs",
    3060: "Mime gloves",
    3061: "Mime boots",
    3062: "Strange box",
    3063: "null",
    3064: "null",
    3065: "null",
    3066: "null",
    3067: "null",
    3068: "null",
    3069: "null",
    3070: "null",
    3071: "null",
    3072: "null",
    3073: "null",
    3074: "null",
    3075: "null",
    3076: "null",
    3077: "null",
    3078: "null",
    3079: "null",
    3080: "null",
    3081: "null",
    3082: "null",
    3083: "null",
    3084: "null",
    3085: "null",
    3086: "null",
    3087: "null",
    3088: "null",
    3089: "null",
    3090: "null",
    3091: "null",
    3092: "null",
    3093: "Black dart",
    3094: "Black dart(p)",
    3095: "Bronze claws",
    3096: "Iron claws",
    3097: "Steel claws",
    3098: "Black claws",
    3099: "Mithril claws",
    3100: "Adamant claws",
    3101: "Rune claws",
    3102: "Combination",
    3103: "Iou",
    3104: "Secret way map",
    3105: "Climbing boots",
    3106: "null",
    3107: "Spiked boots",
    3108: "null",
    3109: "Stone ball",
    3110: "Stone ball",
    3111: "Stone ball",
    3112: "Stone ball",
    3113: "Stone ball",
    3114: "Certificate",
    3115: "null",
    3116: "null",
    3117: "null",
    3118: "null",
    3119: "null",
    3120: "null",
    3121: "null",
    3122: "Granite shield",
    3123: "Shaikahan bones",
    3124: "null",
    3125: "Jogre bones",
    3126: "null",
    3127: "Burnt jogre bones",
    3128: "Pasty jogre bones",
    3129: "Pasty jogre bones",
    3130: "Marinated j' bones",
    3131: "Pasty jogre bones",
    3132: "Pasty jogre bones",
    3133: "Marinated j' bones",
    3134: "null",
    3135: "Prison key",
    3136: "Cell key 1",
    3137: "Cell key 2",
    3138: "Potato cactus",
    3139: "null",
    3140: "Dragon chainbody",
    3141: "null",
    3142: "Raw karambwan",
    3143: "null",
    3144: "Cooked karambwan",
    3145: "null",
    3146: "Poison karambwan",
    3147: "Cooked karambwan",
    3148: "Burnt karambwan",
    3149: "null",
    3150: "Raw karambwanji",
    3151: "Null",
    3152: "Karambwan paste",
    3153: "Karambwan paste",
    3154: "Karambwan paste",
    3155: "Karambwanji paste",
    3156: "Karambwanji paste",
    3157: "Karambwan vessel",
    3158: "null",
    3159: "Karambwan vessel",
    3160: "null",
    3161: "Crafting manual",
    3162: "Sliced banana",
    3163: "null",
    3164: "Karamjan rum",
    3165: "Karamjan rum",
    3166: "Monkey corpse",
    3167: "Monkey skin",
    3168: "Seaweed sandwich",
    3169: "Stuffed monkey",
    3170: "Bronze spear(kp)",
    3171: "Iron spear(kp)",
    3172: "Steel spear(kp)",
    3173: "Mithril spear(kp)",
    3174: "Adamant spear(kp)",
    3175: "Rune spear(kp)",
    3176: "Dragon spear(kp)",
    3177: "Left-handed banana",
    3178: "null",
    3179: "Small ninja monkey bones",
    3180: "Medium ninja monkey bones",
    3181: "Gorilla bones",
    3182: "Bearded gorilla bones",
    3183: "Monkey bones",
    3184: "null",
    3185: "Small zombie monkey bones",
    3186: "Large zombie monkey bones",
    3187: "Bones",
    3188: "Cleaning cloth",
    3189: "null",
    3190: "Bronze halberd",
    3191: "null",
    3192: "Iron halberd",
    3193: "null",
    3194: "Steel halberd",
    3195: "null",
    3196: "Black halberd",
    3197: "null",
    3198: "Mithril halberd",
    3199: "null",
    3200: "Adamant halberd",
    3201: "null",
    3202: "Rune halberd",
    3203: "null",
    3204: "Dragon halberd",
    3205: "null",
    3206: "King's message",
    3207: "Iorwerth's message",
    3208: "Crystal pendant",
    3209: "Sulphur",
    3210: "null",
    3211: "Limestone",
    3212: "null",
    3213: "Quicklime",
    3214: "Pot of quicklime",
    3215: "Ground sulphur",
    3216: "Barrel",
    3217: "null",
    3218: "Barrel bomb",
    3219: "Barrel bomb",
    3220: "Barrel of coal tar",
    3221: "Barrel of naphtha",
    3222: "Naphtha mix",
    3223: "Naphtha mix",
    3224: "Strip of cloth",
    3225: "null",
    3226: "Raw rabbit",
    3227: "null",
    3228: "Cooked rabbit",
    3229: "null",
    3230: "Big book of bangs",
    3231: "Symbol",
    3232: "null",
    3233: "Symbol",
    3234: "null",
    3235: "Symbol",
    3236: "null",
    3237: "Symbol",
    3238: "null",
    3239: "Bark",
    3240: "null",
    3241: "Man",
    3242: "null",
    3243: "Farmer",
    3244: "null",
    3245: "Warrior woman",
    3246: "null",
    3247: "Rogue",
    3248: "null",
    3249: "Guard",
    3250: "null",
    3251: "Knight",
    3252: "null",
    3253: "Watchman",
    3254: "null",
    3255: "Paladin",
    3256: "null",
    3257: "Gnome",
    3258: "null",
    3259: "Hero",
    3260: "null",
    3261: "Goutweed",
    3262: "Troll thistle",
    3263: "Dried thistle",
    3264: "Ground thistle",
    3265: "Troll potion",
    3266: "Drunk parrot",
    3267: "Dirty robe",
    3268: "Fake man",
    3269: "Storeroom key",
    3270: "Alco-chunks",
    3271: "Compost bin",
    3272: "Cave kraken",
    3273: "null",
    3274: "null",
    3275: "null",
    3276: "null",
    3277: "null",
    3278: "null",
    3279: "null",
    3280: "null",
    3281: "null",
    3282: "null",
    3283: "null",
    3284: "null",
    3285: "null",
    3286: "null",
    3287: "null",
    3288: "null",
    3289: "null",
    3290: "null",
    3291: "null",
    3292: "null",
    3293: "null",
    3294: "null",
    3295: "null",
    3296: "null",
    3297: "null",
    3298: "null",
    3299: "null",
    3300: "null",
    3301: "null",
    3302: "null",
    3303: "null",
    3304: "null",
    3305: "null",
    3306: "null",
    3307: "null",
    3308: "null",
    3309: "null",
    3310: "null",
    3311: "null",
    3312: "null",
    3313: "null",
    3314: "null",
    3315: "null",
    3316: "null",
    3317: "null",
    3318: "null",
    3319: "null",
    3320: "null",
    3321: "null",
    3322: "null",
    3323: "null",
    3324: "null",
    3325: "Vampyre dust",
    3326: "null",
    3327: "Myre snelm",
    3328: "null",
    3329: "Blood'n'tar snelm",
    3330: "null",
    3331: "Ochre snelm",
    3332: "null",
    3333: "Bruise blue snelm",
    3334: "null",
    3335: "Broken bark snelm",
    3336: "null",
    3337: "Pointed myre snelm",
    3338: "null",
    3339: "Pointed blood'n'tar snelm",
    3340: "null",
    3341: "Pointed ochre snelm",
    3342: "null",
    3343: "Pointed bruise blue snelm",
    3344: "null",
    3345: "Blamish myre shell",
    3346: "null",
    3347: "Blamish red shell",
    3348: "null",
    3349: "Blamish ochre shell",
    3350: "null",
    3351: "Blamish blue shell",
    3352: "null",
    3353: "Blamish bark shell",
    3354: "null",
    3355: "Blamish myre shell",
    3356: "null",
    3357: "Blamish red shell",
    3358: "null",
    3359: "Blamish ochre shell",
    3360: "null",
    3361: "Blamish blue shell",
    3362: "null",
    3363: "Thin snail",
    3364: "null",
    3365: "Lean snail",
    3366: "null",
    3367: "Fat snail",
    3368: "null",
    3369: "Thin snail meat",
    3370: "null",
    3371: "Lean snail meat",
    3372: "null",
    3373: "Fat snail meat",
    3374: "null",
    3375: "Burnt snail",
    3376: "null",
    3377: "Sample bottle",
    3378: "null",
    3379: "Raw slimy eel",
    3380: "null",
    3381: "Cooked slimy eel",
    3382: "null",
    3383: "Burnt eel",
    3384: "null",
    3385: "Splitbark helm",
    3386: "null",
    3387: "Splitbark body",
    3388: "null",
    3389: "Splitbark legs",
    3390: "null",
    3391: "Splitbark gauntlets",
    3392: "null",
    3393: "Splitbark boots",
    3394: "null",
    3395: "Diary",
    3396: "Loar remains",
    3397: "null",
    3398: "Phrin remains",
    3399: "null",
    3400: "Riyl remains",
    3401: "null",
    3402: "Asyn remains",
    3403: "null",
    3404: "Fiyr remains",
    3405: "null",
    3406: "Unfinished potion",
    3407: "null",
    3408: "Serum 207 (4)",
    3409: "null",
    3410: "Serum 207 (3)",
    3411: "null",
    3412: "Serum 207 (2)",
    3413: "null",
    3414: "Serum 207 (1)",
    3415: "null",
    3416: "Serum 208 (4)",
    3417: "Serum 208 (3)",
    3418: "Serum 208 (2)",
    3419: "Serum 208 (1)",
    3420: "Limestone brick",
    3421: "null",
    3422: "Olive oil(4)",
    3423: "null",
    3424: "Olive oil(3)",
    3425: "null",
    3426: "Olive oil(2)",
    3427: "null",
    3428: "Olive oil(1)",
    3429: "null",
    3430: "Sacred oil(4)",
    3431: "null",
    3432: "Sacred oil(3)",
    3433: "null",
    3434: "Sacred oil(2)",
    3435: "null",
    3436: "Sacred oil(1)",
    3437: "null",
    3438: "Pyre logs",
    3439: "null",
    3440: "Oak pyre logs",
    3441: "null",
    3442: "Willow pyre logs",
    3443: "null",
    3444: "Maple pyre logs",
    3445: "null",
    3446: "Yew pyre logs",
    3447: "null",
    3448: "Magic pyre logs",
    3449: "null",
    3450: "Bronze key red",
    3451: "Bronze key brown",
    3452: "Bronze key crimson",
    3453: "Bronze key black",
    3454: "Bronze key purple",
    3455: "Steel key red",
    3456: "Steel key brown",
    3457: "Steel key crimson",
    3458: "Steel key black",
    3459: "Steel key purple",
    3460: "Black key red",
    3461: "Black key brown",
    3462: "Black key crimson",
    3463: "Black key black",
    3464: "Black key purple",
    3465: "Silver key red",
    3466: "Silver key brown",
    3467: "Silver key crimson",
    3468: "Silver key black",
    3469: "Silver key purple",
    3470: "Fine cloth",
    3471: "null",
    3472: "Black plateskirt (t)",
    3473: "Black plateskirt (g)",
    3474: "Adamant plateskirt (t)",
    3475: "Adamant plateskirt (g)",
    3476: "Rune plateskirt (g)",
    3477: "Rune plateskirt (t)",
    3478: "Zamorak plateskirt",
    3479: "Saradomin plateskirt",
    3480: "Guthix plateskirt",
    3481: "Gilded platebody",
    3482: "null",
    3483: "Gilded platelegs",
    3484: "null",
    3485: "Gilded plateskirt",
    3486: "Gilded full helm",
    3487: "null",
    3488: "Gilded kiteshield",
    3489: "null",
    3490: "Clue scroll (easy)",
    3491: "Clue scroll (easy)",
    3492: "Clue scroll (easy)",
    3493: "Clue scroll (easy)",
    3494: "Clue scroll (easy)",
    3495: "Clue scroll (easy)",
    3496: "Clue scroll (easy)",
    3497: "Clue scroll (easy)",
    3498: "Clue scroll (easy)",
    3499: "Clue scroll (easy)",
    3500: "Clue scroll (easy)",
    3501: "Clue scroll (easy)",
    3502: "Clue scroll (easy)",
    3503: "Clue scroll (easy)",
    3504: "Clue scroll (easy)",
    3505: "Clue scroll (easy)",
    3506: "Clue scroll (easy)",
    3507: "Clue scroll (easy)",
    3508: "Clue scroll (easy)",
    3509: "Clue scroll (easy)",
    3510: "Clue scroll (easy)",
    3511: "Casket (easy)",
    3512: "Clue scroll (easy)",
    3513: "Clue scroll (easy)",
    3514: "Clue scroll (easy)",
    3515: "Clue scroll (easy)",
    3516: "Clue scroll (easy)",
    3517: "Casket (easy)",
    3518: "Clue scroll (easy)",
    3519: "Casket (easy)",
    3520: "Clue scroll (hard)",
    3521: "Casket (hard)",
    3522: "Clue scroll (hard)",
    3523: "Casket (hard)",
    3524: "Clue scroll (hard)",
    3525: "Clue scroll (hard)",
    3526: "Clue scroll (hard)",
    3527: "Casket (hard)",
    3528: "Clue scroll (hard)",
    3529: "Casket (hard)",
    3530: "Clue scroll (hard)",
    3531: "Casket (hard)",
    3532: "Clue scroll (hard)",
    3533: "Casket (hard)",
    3534: "Clue scroll (hard)",
    3535: "Casket (hard)",
    3536: "Clue scroll (hard)",
    3537: "Casket (hard)",
    3538: "Clue scroll (hard)",
    3539: "Casket (hard)",
    3540: "Clue scroll (hard)",
    3541: "Casket (hard)",
    3542: "Clue scroll (hard)",
    3543: "Casket (hard)",
    3544: "Clue scroll (hard)",
    3545: "Casket (hard)",
    3546: "Clue scroll (hard)",
    3547: "Casket (hard)",
    3548: "Clue scroll (hard)",
    3549: "Casket (hard)",
    3550: "Clue scroll (hard)",
    3551: "Casket (hard)",
    3552: "Clue scroll (hard)",
    3553: "Casket (hard)",
    3554: "Clue scroll (hard)",
    3555: "Casket (hard)",
    3556: "Clue scroll (hard)",
    3557: "Casket (hard)",
    3558: "Clue scroll (hard)",
    3559: "Casket (hard)",
    3560: "Clue scroll (hard)",
    3561: "Casket (hard)",
    3562: "Clue scroll (hard)",
    3563: "Casket (hard)",
    3564: "Clue scroll (hard)",
    3565: "Puzzle box (hard)",
    3566: "Clue scroll (hard)",
    3567: "Puzzle box (hard)",
    3568: "Clue scroll (hard)",
    3569: "Puzzle box (hard)",
    3570: "Clue scroll (hard)",
    3571: "Puzzle box (hard)",
    3572: "Clue scroll (hard)",
    3573: "Clue scroll (hard)",
    3574: "Clue scroll (hard)",
    3575: "Clue scroll (hard)",
    3576: "Puzzle box (hard)",
    3577: "Clue scroll (hard)",
    3578: "Puzzle box (hard)",
    3579: "Clue scroll (hard)",
    3580: "Clue scroll (hard)",
    3581: "Casket (hard)",
    3582: "Clue scroll (medium)",
    3583: "Casket (medium)",
    3584: "Clue scroll (medium)",
    3585: "Casket (medium)",
    3586: "Clue scroll (medium)",
    3587: "Casket (medium)",
    3588: "Clue scroll (medium)",
    3589: "Casket (medium)",
    3590: "Clue scroll (medium)",
    3591: "Casket (medium)",
    3592: "Clue scroll (medium)",
    3593: "Casket (medium)",
    3594: "Clue scroll (medium)",
    3595: "Casket (medium)",
    3596: "Clue scroll (medium)",
    3597: "Casket (medium)",
    3598: "Clue scroll (medium)",
    3599: "Clue scroll (medium)",
    3600: "Casket (medium)",
    3601: "Clue scroll (medium)",
    3602: "Clue scroll (medium)",
    3603: "Casket (medium)",
    3604: "Clue scroll (medium)",
    3605: "Clue scroll (medium)",
    3606: "Key (medium)",
    3607: "Clue scroll (medium)",
    3608: "Key (medium)",
    3609: "Clue scroll (medium)",
    3610: "Clue scroll (medium)",
    3611: "Clue scroll (medium)",
    3612: "Clue scroll (medium)",
    3613: "Clue scroll (medium)",
    3614: "Clue scroll (medium)",
    3615: "Clue scroll (medium)",
    3616: "Clue scroll (medium)",
    3617: "Clue scroll (medium)",
    3618: "Clue scroll (medium)",
    3619: "",
    3620: "",
    3621: "",
    3622: "",
    3623: "",
    3624: "",
    3625: "",
    3626: "",
    3627: "",
    3628: "",
    3629: "",
    3630: "",
    3631: "",
    3632: "",
    3633: "",
    3634: "",
    3635: "",
    3636: "",
    3637: "",
    3638: "",
    3639: "",
    3640: "",
    3641: "",
    3642: "",
    3643: "",
    3644: "",
    3645: "",
    3646: "",
    3647: "",
    3648: "",
    3649: "",
    3650: "",
    3651: "",
    3652: "",
    3653: "",
    3654: "",
    3655: "",
    3656: "",
    3657: "",
    3658: "",
    3659: "",
    3660: "",
    3661: "",
    3662: "",
    3663: "",
    3664: "",
    3665: "",
    3666: "",
    3667: "Boss helper tool",
    3668: "null",
    3669: "null",
    3670: "null",
    3671: "null",
    3672: "null",
    3673: "null",
    3674: "null",
    3675: "null",
    3676: "null",
    3677: "null",
    3678: "Flamtaer hammer",
    3679: "null",
    3680: "Shoe",
    3681: "Shoe",
    3682: "Shoe",
    3683: "Shoe",
    3684: "Shoe",
    3685: "Shoe",
    3686: "Fremennik",
    3687: "null",
    3688: "Unstrung lyre",
    3689: "Lyre",
    3690: "Enchanted lyre",
    3691: "Enchanted lyre(1)",
    3692: "Branch",
    3693: "Golden fleece",
    3694: "Golden wool",
    3695: "Pet rock",
    3696: "Hunters' talisman",
    3697: "Hunters' talisman",
    3698: "Exotic flower",
    3699: "Fremennik ballad",
    3700: "Sturdy boots",
    3701: "Tracking map",
    3702: "Custom bow string",
    3703: "Unusual fish",
    3704: "Sea fishing map",
    3705: "Weather forecast",
    3706: "Champions token",
    3707: "Legendary cocktail",
    3708: "Fiscal statement",
    3709: "Promissory note",
    3710: "Warriors' contract",
    3711: "Keg of beer",
    3712: "Low alcohol keg",
    3713: "Strange object",
    3714: "Lit strange object",
    3715: "Red disk",
    3716: "Red disk",
    3717: "null",
    3718: "Magnet",
    3719: "Blue thread",
    3720: "Small pick",
    3721: "Toy ship",
    3722: "Full bucket",
    3723: "4/5ths full bucket",
    3724: "3/5ths full bucket",
    3725: "2/5ths full bucket",
    3726: "1/5ths full bucket",
    3727: "Empty bucket",
    3728: "Frozen bucket",
    3729: "Full jug",
    3730: "2/3rds full jug",
    3731: "1/3rds full jug",
    3732: "Empty jug",
    3733: "Frozen jug",
    3734: "Vase",
    3735: "Vase of water",
    3736: "Frozen vase",
    3737: "Vase lid",
    3738: "Sealed vase",
    3739: "Sealed vase",
    3740: "Sealed vase",
    3741: "Frozen key",
    3742: "Red herring",
    3743: "Red disk",
    3744: "Wooden disk",
    3745: "Seer's key",
    3746: "Sticky red goop",
    3747: "null",
    3748: "Fremennik helm",
    3749: "Archer helm",
    3750: "null",
    3751: "Berserker helm",
    3752: "null",
    3753: "Warrior helm",
    3754: "null",
    3755: "Farseer helm",
    3756: "null",
    3757: "Fremennik blade",
    3758: "Fremennik shield",
    3759: "Fremennik cyan cloak",
    3760: "null",
    3761: "Fremennik brown cloak",
    3762: "null",
    3763: "Fremennik blue cloak",
    3764: "null",
    3765: "Fremennik green cloak",
    3766: "null",
    3767: "Fremennik brown shirt",
    3768: "null",
    3769: "Fremennik grey shirt",
    3770: "null",
    3771: "Fremennik beige shirt",
    3772: "null",
    3773: "Fremennik red shirt",
    3774: "null",
    3775: "Fremennik blue shirt",
    3776: "null",
    3777: "Fremennik red cloak",
    3778: "null",
    3779: "Fremennik grey cloak",
    3780: "null",
    3781: "Fremennik yellow cloak",
    3782: "null",
    3783: "Fremennik teal cloak",
    3784: "null",
    3785: "Fremennik purple cloak",
    3786: "null",
    3787: "Fremennik pink cloak",
    3788: "null",
    3789: "Fremennik black cloak",
    3790: "null",
    3791: "Fremennik boots",
    3792: "null",
    3793: "Fremennik robe",
    3794: "null",
    3795: "Fremennik skirt",
    3796: "null",
    3797: "Fremennik hat",
    3798: "null",
    3799: "Fremennik gloves",
    3800: "null",
    3801: "Keg of beer",
    3802: "null",
    3803: "Beer tankard",
    3804: "null",
    3805: "Tankard",
    3806: "null",
    3807: "null",
    3808: "null",
    3809: "null",
    3810: "null",
    3811: "null",
    3812: "null",
    3813: "null",
    3814: "null",
    3815: "null",
    3816: "null",
    3817: "null",
    3818: "null",
    3819: "null",
    3820: "null",
    3821: "null",
    3822: "null",
    3823: "null",
    3824: "null",
    3825: "null",
    3826: "null",
    3827: "Saradomin page 1",
    3828: "Saradomin page 2",
    3829: "Saradomin page 3",
    3830: "Saradomin page 4",
    3831: "Zamorak page 1",
    3832: "Zamorak page 2",
    3833: "Zamorak page 3",
    3834: "Zamorak page 4",
    3835: "Guthix page 1",
    3836: "Guthix page 2",
    3837: "Guthix page 3",
    3838: "Guthix page 4",
    3839: "Damaged book",
    3840: "Holy book",
    3841: "Damaged book",
    3842: "Unholy book",
    3843: "Damaged book",
    3844: "Book of balance",
    3845: "Journal",
    3846: "Diary",
    3847: "Manual",
    3848: "Lighthouse key",
    3849: "Rusty casket",
    3850: "null",
    3851: "null",
    3852: "null",
    3853: "Games necklace(8)",
    3854: "null",
    3855: "Games necklace(7)",
    3856: "null",
    3857: "Games necklace(6)",
    3858: "null",
    3859: "Games necklace(5)",
    3860: "null",
    3861: "Games necklace(4)",
    3862: "null",
    3863: "Games necklace(3)",
    3864: "null",
    3865: "Games necklace(2)",
    3866: "null",
    3867: "Games necklace(1)",
    3868: "null",
    3869: "Board game piece",
    3870: "Board game piece",
    3871: "Board game piece",
    3872: "Board game piece",
    3873: "Board game piece",
    3874: "Board game piece",
    3875: "Board game piece",
    3876: "Board game piece",
    3877: "Board game piece",
    3878: "Board game piece",
    3879: "Board game piece",
    3880: "Board game piece",
    3881: "Board game piece",
    3882: "Board game piece",
    3883: "Board game piece",
    3884: "Board game piece",
    3885: "Board game piece",
    3886: "Board game piece",
    3887: "Board game piece",
    3888: "Board game piece",
    3889: "Board game piece",
    3890: "Board game piece",
    3891: "Board game piece",
    3892: "Board game piece",
    3893: "Stool",
    3894: "Awful anthem",
    3895: "Good anthem",
    3896: "Treaty",
    3897: "Giant nib",
    3898: "Giant pen",
    3899: "Iron sickle",
    3900: "null",
    3901: "Ghrim's book",
    3902: "null",
    3903: "null",
    3904: "",
    3905: "null",
    3906: "",
    3907: "null",
    3908: "",
    3909: "null",
    3910: "",
    3911: "null",
    3912: "",
    3913: "null",
    3914: "",
    3915: "null",
    3916: "",
    3917: "null",
    3918: "",
    3919: "null",
    3920: "",
    3921: "null",
    3922: "",
    3923: "null",
    3924: "",
    3925: "null",
    3926: "",
    3927: "null",
    3928: "",
    3929: "null",
    3930: "",
    3931: "null",
    3932: "",
    3933: "null",
    3934: "",
    3935: "null",
    3936: "",
    3937: "null",
    3938: "",
    3939: "null",
    3940: "",
    3941: "null",
    3942: "",
    3943: "null",
    3944: "",
    3945: "null",
    3946: "",
    3947: "null",
    3948: "",
    3949: "null",
    3950: "",
    3951: "null",
    3952: "",
    3953: "null",
    3954: "",
    3955: "null",
    3956: "",
    3957: "null",
    3958: "",
    3959: "null",
    3960: "",
    3961: "null",
    3962: "",
    3963: "null",
    3964: "",
    3965: "null",
    3966: "",
    3967: "null",
    3968: "",
    3969: "null",
    3970: "",
    3971: "null",
    3972: "",
    3973: "null",
    3974: "",
    3975: "null",
    3976: "",
    3977: "null",
    3978: "",
    3979: "null",
    3980: "",
    3981: "Wilderness sword",
    3982: "",
    3983: "Western banner",
    3984: "",
    3985: "null",
    3986: "",
    3987: "null",
    3988: "",
    3989: "null",
    3990: "",
    3991: "null",
    3992: "",
    3993: "null",
    3994: "",
    3995: "null",
    3996: "",
    3997: "null",
    3998: "",
    3999: "null",
    4000: "null",
    4001: "Hardy gout tuber",
    4002: "Spare controls",
    4003: "null",
    4004: "Gnome royal seal",
    4005: "Narnode's orders",
    4006: "Monkey dentures",
    4007: "Enchanted bar",
    4008: "Eye of gnome",
    4009: "Eye of gnome",
    4010: "Monkey magic",
    4011: "null",
    4012: "Monkey nuts",
    4013: "null",
    4014: "Monkey bar",
    4015: "null",
    4016: "Banana stew",
    4017: "null",
    4018: "Monkey wrench",
    4019: "null",
    4020: "M'amulet mould",
    4021: "M'speak amulet",
    4022: "M'speak amulet",
    4023: "Monkey talisman",
    4024: "Ninja monkey greegree",
    4025: "Ninja monkey greegree",
    4026: "Gorilla greegree",
    4027: "Bearded gorilla greegree",
    4028: "Ancient gorilla greegree",
    4029: "Zombie monkey greegree",
    4030: "Zombie monkey greegree",
    4031: "Karamjan monkey greegree",
    4032: "null",
    4033: "Monkey",
    4034: "Monkey skull",
    4035: "10th squad sigil",
    4036: "null",
    4037: "Saradomin banner",
    4038: "null",
    4039: "Zamorak banner",
    4040: "null",
    4041: "Hooded cloak",
    4042: "Hooded cloak",
    4043: "Rock",
    4044: "null",
    4045: "Explosive potion",
    4046: "null",
    4047: "Climbing rope",
    4048: "null",
    4049: "Bandages",
    4050: "null",
    4051: "Toolkit",
    4052: "null",
    4053: "Barricade",
    4054: "null",
    4055: "Castlewars manual",
    4056: "null",
    4057: "null",
    4058: "null",
    4059: "null",
    4060: "null",
    4061: "null",
    4062: "null",
    4063: "null",
    4064: "null",
    4065: "null",
    4066: "null",
    4067: "Castle wars ticket",
    4068: "Decorative sword",
    4069: "Decorative armour",
    4070: "Decorative armour",
    4071: "Decorative helm",
    4072: "Decorative shield",
    4073: "Damp tinderbox",
    4074: "null",
    4075: "Glowing fungus",
    4076: "null",
    4077: "Crystal-mine key",
    4078: "Zealot's key",
    4079: "Yo-yo",
    4080: "null",
    4081: "Salve amulet",
    4082: "Salve shard",
    4083: "Sled",
    4084: "Sled",
    4085: "Wax",
    4086: "Trollweiss",
    4087: "Dragon platelegs",
    4088: "null",
    4089: "Mystic hat",
    4090: "null",
    4091: "Mystic robe top",
    4092: "null",
    4093: "Mystic robe bottom",
    4094: "null",
    4095: "Mystic gloves",
    4096: "null",
    4097: "Mystic boots",
    4098: "null",
    4099: "Mystic hat (dark)",
    4100: "null",
    4101: "Mystic robe top (dark)",
    4102: "null",
    4103: "Mystic robe bottom (dark)",
    4104: "null",
    4105: "Mystic gloves (dark)",
    4106: "null",
    4107: "Mystic boots (dark)",
    4108: "null",
    4109: "Mystic hat (light)",
    4110: "null",
    4111: "Mystic robe top (light)",
    4112: "null",
    4113: "Mystic robe bottom (light)",
    4114: "null",
    4115: "Mystic gloves (light)",
    4116: "null",
    4117: "Mystic boots (light)",
    4118: "null",
    4119: "Bronze boots",
    4120: "null",
    4121: "Iron boots",
    4122: "null",
    4123: "Steel boots",
    4124: "null",
    4125: "Black boots",
    4126: "null",
    4127: "Mithril boots",
    4128: "null",
    4129: "Adamant boots",
    4130: "null",
    4131: "Rune boots",
    4132: "null",
    4133: "Crawling hand",
    4134: "Cave crawler",
    4135: "Banshee",
    4136: "Rockslug",
    4137: "Cockatrice",
    4138: "Pyrefiend",
    4139: "Basilisk",
    4140: "Infernal mage",
    4141: "Bloodveld",
    4142: "Jelly",
    4143: "Turoth",
    4144: "Aberrant spectre",
    4145: "Dust devil",
    4146: "Kurask",
    4147: "Gargoyle",
    4148: "Nechryael",
    4149: "Abyssal demon",
    4150: "Broad arrows",
    4151: "Abyssal whip",
    4152: "null",
    4153: "Granite maul",
    4154: "null",
    4155: "Enchanted gem",
    4156: "Mirror shield",
    4157: "null",
    4158: "Leaf-bladed spear",
    4159: "Leaf-bladed spear",
    4160: "Broad arrows",
    4161: "Bag of salt",
    4162: "Rock hammer",
    4163: "null",
    4164: "Facemask",
    4165: "null",
    4166: "Earmuffs",
    4167: "null",
    4168: "Nose peg",
    4169: "null",
    4170: "Slayer's staff",
    4171: "null",
    4172: "null",
    4173: "null",
    4174: "null",
    4175: "null",
    4176: "null",
    4177: "null",
    4178: "Abyssal whip",
    4179: "Stick",
    4180: "Dragon platelegs",
    4181: "Mouth grip",
    4182: "Goutweed",
    4183: "Star amulet",
    4184: "Cavern key",
    4185: "Tower key",
    4186: "Shed key",
    4187: "Marble amulet",
    4188: "Obsidian amulet",
    4189: "Garden cane",
    4190: "Garden brush",
    4191: "Extended brush",
    4192: "Extended brush",
    4193: "Extended brush",
    4194: "Torso",
    4195: "Arms",
    4196: "Legs",
    4197: "Decapitated head",
    4198: "Decapitated head",
    4199: "Pickled brain",
    4200: "Conductor mould",
    4201: "Conductor",
    4202: "Ring of charos",
    4203: "Journal",
    4204: "Letter",
    4205: "Consecration seed",
    4206: "Consecration seed",
    4207: "Crystal weapon seed",
    4208: "null",
    4209: "Cadarn lineage",
    4210: "null",
    4211: "Elf crystal",
    4212: "New crystal bow",
    4213: "New crystal bow",
    4214: "Crystal bow full",
    4215: "Crystal bow 9/10",
    4216: "Crystal bow 8/10",
    4217: "Crystal bow 7/10",
    4218: "Crystal bow 6/10",
    4219: "Crystal bow 5/10",
    4220: "Crystal bow 4/10",
    4221: "Crystal bow 3/10",
    4222: "Crystal bow 2/10",
    4223: "Crystal bow 1/10",
    4224: "New crystal shield",
    4225: "Crystal shield full",
    4226: "Crystal shield 9/10",
    4227: "Crystal shield 8/10",
    4228: "Crystal shield 7/10",
    4229: "Crystal shield 6/10",
    4230: "Crystal shield 5/10",
    4231: "Crystal shield 4/10",
    4232: "Crystal shield 3/10",
    4233: "Crystal shield 2/10",
    4234: "Crystal shield 1/10",
    4235: "New crystal shield",
    4236: "Signed oak bow",
    4237: "Nettle-water",
    4238: "Puddle of slime",
    4239: "Nettle tea",
    4240: "Nettle tea",
    4241: "Nettles",
    4242: "Cup of tea",
    4243: "Cup of tea",
    4244: "Porcelain cup",
    4245: "Cup of tea",
    4246: "Cup of tea",
    4247: "Mystical robes",
    4248: "Book of haricanto",
    4249: "Translation manual",
    4250: "Ghostspeak amulet",
    4251: "Ectophial",
    4252: "Ectophial",
    4253: "Model ship",
    4254: "Model ship",
    4255: "Bonemeal",
    4256: "Bat bonemeal",
    4257: "Big bonemeal",
    4258: "Burnt bonemeal",
    4259: "Burnt jogre bonemeal",
    4260: "Baby dragon bonemeal",
    4261: "Dragon bonemeal",
    4262: "Wolf bonemeal",
    4263: "Small ninja bonemeal",
    4264: "Medium ninja bonemeal",
    4265: "Gorilla bonemeal",
    4266: "Bearded gorilla bonemeal",
    4267: "Monkey bonemeal",
    4268: "Small zombie monkey bonemeal",
    4269: "Large zombie monkey bonemeal",
    4270: "Skeleton bonemeal",
    4271: "Jogre bonemeal",
    4272: "Bone key",
    4273: "Chest key",
    4274: "Map scrap",
    4275: "Map scrap",
    4276: "Map scrap",
    4277: "Treasure map",
    4278: "Ecto-token",
    4279: "null",
    4280: "null",
    4281: "null",
    4282: "null",
    4283: "Petition form",
    4284: "Bedsheet",
    4285: "Bedsheet",
    4286: "Bucket of slime",
    4287: "Raw beef",
    4288: "null",
    4289: "Raw chicken",
    4290: "null",
    4291: "Cooked chicken",
    4292: "null",
    4293: "Cooked meat",
    4294: "null",
    4295: "Female h.a.m.",
    4296: "null",
    4297: "Male h.a.m.",
    4298: "Ham shirt",
    4299: "null",
    4300: "Ham robe",
    4301: "null",
    4302: "Ham hood",
    4303: "null",
    4304: "Ham cloak",
    4305: "null",
    4306: "Ham logo",
    4307: "null",
    4308: "Ham gloves",
    4309: "null",
    4310: "Ham boots",
    4311: "null",
    4312: "null",
    4313: "Crystal singing for beginners",
    4314: "null",
    4315: "Team-1 cape",
    4316: "null",
    4317: "Team-2 cape",
    4318: "null",
    4319: "Team-3 cape",
    4320: "null",
    4321: "Team-4 cape",
    4322: "null",
    4323: "Team-5 cape",
    4324: "null",
    4325: "Team-6 cape",
    4326: "null",
    4327: "Team-7 cape",
    4328: "null",
    4329: "Team-8 cape",
    4330: "null",
    4331: "Team-9 cape",
    4332: "null",
    4333: "Team-10 cape",
    4334: "null",
    4335: "Team-11 cape",
    4336: "null",
    4337: "Team-12 cape",
    4338: "null",
    4339: "Team-13 cape",
    4340: "null",
    4341: "Team-14 cape",
    4342: "null",
    4343: "Team-15 cape",
    4344: "null",
    4345: "Team-16 cape",
    4346: "null",
    4347: "Team-17 cape",
    4348: "null",
    4349: "Team-18 cape",
    4350: "null",
    4351: "Team-19 cape",
    4352: "null",
    4353: "Team-20 cape",
    4354: "null",
    4355: "Team-21 cape",
    4356: "null",
    4357: "Team-22 cape",
    4358: "null",
    4359: "Team-23 cape",
    4360: "null",
    4361: "Team-24 cape",
    4362: "null",
    4363: "Team-25 cape",
    4364: "null",
    4365: "Team-26 cape",
    4366: "null",
    4367: "Team-27 cape",
    4368: "null",
    4369: "Team-28 cape",
    4370: "null",
    4371: "Team-29 cape",
    4372: "null",
    4373: "Team-30 cape",
    4374: "null",
    4375: "Team-31 cape",
    4376: "null",
    4377: "Team-32 cape",
    4378: "null",
    4379: "Team-33 cape",
    4380: "null",
    4381: "Team-34 cape",
    4382: "null",
    4383: "Team-35 cape",
    4384: "null",
    4385: "Team-36 cape",
    4386: "null",
    4387: "Team-37 cape",
    4388: "null",
    4389: "Team-38 cape",
    4390: "null",
    4391: "Team-39 cape",
    4392: "null",
    4393: "Team-40 cape",
    4394: "null",
    4395: "Team-41 cape",
    4396: "null",
    4397: "Team-42 cape",
    4398: "null",
    4399: "Team-43 cape",
    4400: "null",
    4401: "Team-44 cape",
    4402: "null",
    4403: "Team-45 cape",
    4404: "null",
    4405: "Team-46 cape",
    4406: "null",
    4407: "Team-47 cape",
    4408: "null",
    4409: "Team-48 cape",
    4410: "null",
    4411: "Team-49 cape",
    4412: "null",
    4413: "Team-50 cape",
    4414: "null",
    4415: "Blunt axe",
    4416: "Herbal tincture",
    4417: "Guthix rest(4)",
    4418: "null",
    4419: "Guthix rest(3)",
    4420: "null",
    4421: "Guthix rest(2)",
    4422: "null",
    4423: "Guthix rest(1)",
    4424: "null",
    4425: "Stodgy mattress",
    4426: "Comfy mattress",
    4427: "Iron oxide",
    4428: "Animate rock scroll",
    4429: "Broken vane part",
    4430: "Directionals",
    4431: "Broken vane part",
    4432: "Ornament",
    4433: "Broken vane part",
    4434: "Weathervane pillar",
    4435: "Weather report",
    4436: "Airtight pot",
    4437: "null",
    4438: "Unfired pot lid",
    4439: "null",
    4440: "Pot lid",
    4441: "null",
    4442: "Breathing salts",
    4443: "Chicken cage",
    4444: "Sharpened axe",
    4445: "Red mahogany log",
    4446: "Steel key ring",
    4447: "Antique lamp",
    4448: "null",
    4449: "null",
    4450: "null",
    4451: "null",
    4452: "null",
    4453: "null",
    4454: "null",
    4455: "null",
    4456: "Bowl of hot water",
    4457: "null",
    4458: "Cup of water",
    4459: "null",
    4460: "Cup of hot water",
    4461: "null",
    4462: "Ruined herb tea",
    4463: "null",
    4464: "Herb tea mix",
    4465: "null",
    4466: "Herb tea mix",
    4467: "null",
    4468: "Herb tea mix",
    4469: "null",
    4470: "Herb tea mix",
    4471: "null",
    4472: "Herb tea mix",
    4473: "null",
    4474: "Herb tea mix",
    4475: "null",
    4476: "Herb tea mix",
    4477: "null",
    4478: "Herb tea mix",
    4479: "null",
    4480: "Herb tea mix",
    4481: "null",
    4482: "Herb tea mix",
    4483: "null",
    4484: "Safety guarantee",
    4485: "White pearl",
    4486: "White pearl seed",
    4487: "Half a rock",
    4488: "Corpse of woman",
    4489: "Asleif's necklace",
    4490: "Mud",
    4491: "null",
    4492: "Muddy rock",
    4493: "null",
    4494: "Pole",
    4495: "Null",
    4496: "Broken pole",
    4497: "null",
    4498: "Rope",
    4499: "null",
    4500: "Pole",
    4501: "Null",
    4502: "Bearhead",
    4503: "Decorative sword",
    4504: "Decorative armour",
    4505: "Decorative armour",
    4506: "Decorative helm",
    4507: "Decorative shield",
    4508: "Decorative sword",
    4509: "Decorative armour",
    4510: "Decorative armour",
    4511: "Decorative helm",
    4512: "Decorative shield",
    4513: "Castlewars hood",
    4514: "Castlewars cloak",
    4515: "Castlewars hood",
    4516: "Castlewars cloak",
    4517: "Giant frog legs",
    4518: "null",
    4519: "Swamp wallbeast",
    4520: "Swamp cave slime",
    4521: "Swamp cave bug",
    4522: "Oil lamp",
    4523: "null",
    4524: "Oil lamp",
    4525: "Empty oil lamp",
    4526: "null",
    4527: "Empty candle lantern",
    4528: "null",
    4529: "Candle lantern",
    4530: "null",
    4531: "Candle lantern",
    4532: "Candle lantern",
    4533: "null",
    4534: "Candle lantern",
    4535: "Empty oil lantern",
    4536: "null",
    4537: "Oil lantern",
    4538: "null",
    4539: "Oil lantern",
    4540: "Oil lantern frame",
    4541: "null",
    4542: "Lantern lens",
    4543: "null",
    4544: "Bullseye lantern (unf)",
    4545: "null",
    4546: "Bullseye lantern (empty)",
    4547: "null",
    4548: "Bullseye lantern",
    4549: "null",
    4550: "Bullseye lantern",
    4551: "Spiny helmet",
    4552: "null",
    4553: "null",
    4554: "null",
    4555: "null",
    4556: "null",
    4557: "null",
    4558: "Blue sweets",
    4559: "Deep blue sweets",
    4560: "White sweets",
    4561: "Purple sweets",
    4562: "Red sweets",
    4563: "Green sweets",
    4564: "Pink sweets",
    4565: "Easter basket",
    4566: "Rubber chicken",
    4567: "Gold helmet",
    4568: "Dwarven lore",
    4569: "Book page 1",
    4570: "Book page 2",
    4571: "Book page 3",
    4572: "Pages",
    4573: "Pages",
    4574: "Base schematics",
    4575: "Schematic",
    4576: "Schematics",
    4577: "Schematics",
    4578: "Schematic",
    4579: "Cannon ball",
    4580: "Black spear",
    4581: "null",
    4582: "Black spear(p)",
    4583: "null",
    4584: "Black spear(kp)",
    4585: "Dragon plateskirt",
    4586: "null",
    4587: "Dragon scimitar",
    4588: "null",
    4589: "Keys",
    4590: "Jewels",
    4591: "Kharidian headpiece",
    4592: "null",
    4593: "Fake beard",
    4594: "null",
    4595: "Karidian disguise",
    4596: "null",
    4597: "Note",
    4598: "Note",
    4599: "Oak blackjack",
    4600: "Willow blackjack",
    4601: "Ugthanki dung",
    4602: "Ugthanki dung",
    4603: "Receipt",
    4604: "Hag's poison",
    4605: "Snake charm",
    4606: "Snake basket",
    4607: "Snake basket full",
    4608: "Super kebab",
    4609: "null",
    4610: "Red hot sauce",
    4611: "Desert disguise",
    4612: "null",
    4613: "Spinning plate",
    4614: "Broken plate",
    4615: "Letter",
    4616: "Varmen's notes",
    4617: "Display cabinet key",
    4618: "Statuette",
    4619: "Strange implement",
    4620: "Black mushroom",
    4621: "Phoenix feather",
    4622: "Black dye",
    4623: "Phoenix quill pen",
    4624: "Golem program",
    4625: "Bandit",
    4626: "null",
    4627: "Bandit's brew",
    4628: "null",
    4629: "null",
    4630: "null",
    4631: "null",
    4632: "null",
    4633: "null",
    4634: "null",
    4635: "null",
    4636: "null",
    4637: "null",
    4638: "null",
    4639: "null",
    4640: "null",
    4641: "null",
    4642: "null",
    4643: "null",
    4644: "null",
    4645: "null",
    4646: "null",
    4647: "null",
    4648: "null",
    4649: "null",
    4650: "null",
    4651: "null",
    4652: "null",
    4653: "Fire",
    4654: "Etchings",
    4655: "Translation",
    4656: "Warm key",
    4657: "Ring of visibility",
    4658: "Silver pot",
    4659: "Blessed pot",
    4660: "Silver pot",
    4661: "Blessed pot",
    4662: "Silver pot",
    4663: "Blessed pot",
    4664: "Silver pot",
    4665: "Blessed pot",
    4666: "Silver pot",
    4667: "Blessed pot",
    4668: "Garlic powder",
    4669: "null",
    4670: "Blood diamond",
    4671: "Ice diamond",
    4672: "Smoke diamond",
    4673: "Shadow diamond",
    4674: "Gilded cross",
    4675: "Ancient staff",
    4676: "null",
    4677: "Catspeak amulet",
    4678: "Canopic jar",
    4679: "Canopic jar",
    4680: "Canopic jar",
    4681: "Canopic jar",
    4682: "Holy symbol",
    4683: "Unholy symbol",
    4684: "Linen",
    4685: "null",
    4686: "Embalming manual",
    4687: "Bucket of sap",
    4688: "null",
    4689: "Pile of salt",
    4690: "null",
    4691: "Sphinx's token",
    4692: "Gold leaf",
    4693: "Bucket of saltwater",
    4694: "Steam rune",
    4695: "Mist rune",
    4696: "Dust rune",
    4697: "Smoke rune",
    4698: "Mud rune",
    4699: "Lava rune",
    4700: "Sapphire lantern",
    4701: "Sapphire lantern",
    4702: "Sapphire lantern",
    4703: "Magic stone",
    4704: "Stone bowl",
    4705: "null",
    4706: "null",
    4707: "Crumbling tome",
    4708: "Ahrim's hood",
    4709: "null",
    4710: "Ahrim's staff",
    4711: "null",
    4712: "Ahrim's robetop",
    4713: "null",
    4714: "Ahrim's robeskirt",
    4715: "null",
    4716: "Dharok's helm",
    4717: "null",
    4718: "Dharok's greataxe",
    4719: "null",
    4720: "Dharok's platebody",
    4721: "null",
    4722: "Dharok's platelegs",
    4723: "null",
    4724: "Guthan's helm",
    4725: "null",
    4726: "Guthan's warspear",
    4727: "null",
    4728: "Guthan's platebody",
    4729: "null",
    4730: "Guthan's chainskirt",
    4731: "null",
    4732: "Karil's coif",
    4733: "null",
    4734: "Karil's crossbow",
    4735: "null",
    4736: "Karil's leathertop",
    4737: "null",
    4738: "Karil's leatherskirt",
    4739: "null",
    4740: "Bolt rack",
    4741: "null",
    4742: "null",
    4743: "null",
    4744: "null",
    4745: "Torag's helm",
    4746: "null",
    4747: "Torag's hammers",
    4748: "null",
    4749: "Torag's platebody",
    4750: "null",
    4751: "Torag's platelegs",
    4752: "null",
    4753: "Verac's helm",
    4754: "null",
    4755: "Verac's flail",
    4756: "null",
    4757: "Verac's brassard",
    4758: "null",
    4759: "Verac's plateskirt",
    4760: "null",
    4761: "null",
    4762: "null",
    4763: "null",
    4764: "null",
    4765: "null",
    4766: "null",
    4767: "null",
    4768: "null",
    4769: "null",
    4770: "null",
    4771: "null",
    4772: "null",
    4773: "Bronze brutal",
    4774: "null",
    4775: "null",
    4776: "null",
    4777: "null",
    4778: "Iron brutal",
    4779: "null",
    4780: "null",
    4781: "null",
    4782: "null",
    4783: "Steel brutal",
    4784: "null",
    4785: "null",
    4786: "null",
    4787: "null",
    4788: "Black brutal",
    4789: "null",
    4790: "null",
    4791: "null",
    4792: "null",
    4793: "Mithril brutal",
    4794: "null",
    4795: "null",
    4796: "null",
    4797: "null",
    4798: "Adamant brutal",
    4799: "null",
    4800: "null",
    4801: "null",
    4802: "null",
    4803: "Rune brutal",
    4804: "null",
    4805: "null",
    4806: "null",
    4807: "null",
    4808: "Black prism",
    4809: "Torn page",
    4810: "Ruined backpack",
    4811: "Dragon inn tankard",
    4812: "Zogre bones",
    4813: "null",
    4814: "Sithik portrait",
    4815: "Sithik portrait",
    4816: "Signed portrait",
    4817: "Book of portraiture",
    4818: "Ogre artefact",
    4819: "Bronze nails",
    4820: "Iron nails",
    4821: "Black nails",
    4822: "Mithril nails",
    4823: "Adamantite nails",
    4824: "Rune nails",
    4825: "Unstrung comp bow",
    4826: "null",
    4827: "Comp ogre bow",
    4828: "null",
    4829: "Book of 'h.a.m'",
    4830: "Fayrg bones",
    4831: "null",
    4832: "Raurg bones",
    4833: "null",
    4834: "Ourg bones",
    4835: "null",
    4836: "Strange potion",
    4837: "Necromancy book",
    4838: "Cup of tea",
    4839: "Ogre gate key",
    4840: "Unfinished potion",
    4841: "null",
    4842: "Relicym's balm(4)",
    4843: "null",
    4844: "Relicym's balm(3)",
    4845: "null",
    4846: "Relicym's balm(2)",
    4847: "null",
    4848: "Relicym's balm(1)",
    4849: "null",
    4850: "Ogre coffin key",
    4851: "null",
    4852: "Zogre bonemeal",
    4853: "Fayrg bonemeal",
    4854: "Raurg bonemeal",
    4855: "Ourg bonemeal",
    4856: "Ahrim's hood 100",
    4857: "Ahrim's hood 75",
    4858: "Ahrim's hood 50",
    4859: "Ahrim's hood 25",
    4860: "Ahrim's hood 0",
    4861: "null",
    4862: "Ahrim's staff 100",
    4863: "Ahrim's staff 75",
    4864: "Ahrim's staff 50",
    4865: "Ahrim's staff 25",
    4866: "Ahrim's staff 0",
    4867: "null",
    4868: "Ahrim's robetop 100",
    4869: "Ahrim's robetop 75",
    4870: "Ahrim's robetop 50",
    4871: "Ahrim's robetop 25",
    4872: "Ahrim's robetop 0",
    4873: "null",
    4874: "Ahrim's robeskirt 100",
    4875: "Ahrim's robeskirt 75",
    4876: "Ahrim's robeskirt 50",
    4877: "Ahrim's robeskirt 25",
    4878: "Ahrim's robeskirt 0",
    4879: "null",
    4880: "Dharok's helm 100",
    4881: "Dharok's helm 75",
    4882: "Dharok's helm 50",
    4883: "Dharok's helm 25",
    4884: "Dharok's helm 0",
    4885: "null",
    4886: "Dharok's greataxe 100",
    4887: "Dharok's greataxe 75",
    4888: "Dharok's greataxe 50",
    4889: "Dharok's greataxe 25",
    4890: "Dharok's greataxe 0",
    4891: "null",
    4892: "Dharok's platebody 100",
    4893: "Dharok's platebody 75",
    4894: "Dharok's platebody 50",
    4895: "Dharok's platebody 25",
    4896: "Dharok's platebody 0",
    4897: "null",
    4898: "Dharok's platelegs 100",
    4899: "Dharok's platelegs 75",
    4900: "Dharok's platelegs 50",
    4901: "Dharok's platelegs 25",
    4902: "Dharok's platelegs 0",
    4903: "null",
    4904: "Guthan's helm 100",
    4905: "Guthan's helm 75",
    4906: "Guthan's helm 50",
    4907: "Guthan's helm 25",
    4908: "Guthan's helm 0",
    4909: "null",
    4910: "Guthan's warspear 100",
    4911: "Guthan's warspear 75",
    4912: "Guthan's warspear 50",
    4913: "Guthan's warspear 25",
    4914: "Guthan's warspear 0",
    4915: "null",
    4916: "Guthan's platebody 100",
    4917: "Guthan's platebody 75",
    4918: "Guthan's platebody 50",
    4919: "Guthan's platebody 25",
    4920: "Guthan's platebody 0",
    4921: "null",
    4922: "Guthan's chainskirt 100",
    4923: "Guthan's chainskirt 75",
    4924: "Guthan's chainskirt 50",
    4925: "Guthan's chainskirt 25",
    4926: "Guthan's chainskirt 0",
    4927: "null",
    4928: "Karil's coif 100",
    4929: "Karil's coif 75",
    4930: "Karil's coif 50",
    4931: "Karil's coif 25",
    4932: "Karil's coif 0",
    4933: "null",
    4934: "Karil's crossbow 100",
    4935: "Karil's crossbow 75",
    4936: "Karil's crossbow 50",
    4937: "Karil's crossbow 25",
    4938: "Karil's crossbow 0",
    4939: "null",
    4940: "Karil's leathertop 100",
    4941: "Karil's leathertop 75",
    4942: "Karil's leathertop 50",
    4943: "Karil's leathertop 25",
    4944: "Karil's leathertop 0",
    4945: "null",
    4946: "Karil's leatherskirt 100",
    4947: "Karil's leatherskirt 75",
    4948: "Karil's leatherskirt 50",
    4949: "Karil's leatherskirt 25",
    4950: "Karil's leatherskirt 0",
    4951: "null",
    4952: "Torag's helm 100",
    4953: "Torag's helm 75",
    4954: "Torag's helm 50",
    4955: "Torag's helm 25",
    4956: "Torag's helm 0",
    4957: "null",
    4958: "Torag's hammers 100",
    4959: "Torag's hammers 75",
    4960: "Torag's hammers 50",
    4961: "Torag's hammers 25",
    4962: "Torag's hammers 0",
    4963: "null",
    4964: "Torag's platebody 100",
    4965: "Torag's platebody 75",
    4966: "Torag's platebody 50",
    4967: "Torag's platebody 25",
    4968: "Torag's platebody 0",
    4969: "null",
    4970: "Torag's platelegs 100",
    4971: "Torag's platelegs 75",
    4972: "Torag's platelegs 50",
    4973: "Torag's platelegs 25",
    4974: "Torag's platelegs 0",
    4975: "null",
    4976: "Verac's helm 100",
    4977: "Verac's helm 75",
    4978: "Verac's helm 50",
    4979: "Verac's helm 25",
    4980: "Verac's helm 0",
    4981: "null",
    4982: "Verac's flail 100",
    4983: "Verac's flail 75",
    4984: "Verac's flail 50",
    4985: "Verac's flail 25",
    4986: "Verac's flail 0",
    4987: "null",
    4988: "Verac's brassard 100",
    4989: "Verac's brassard 75",
    4990: "Verac's brassard 50",
    4991: "Verac's brassard 25",
    4992: "Verac's brassard 0",
    4993: "null",
    4994: "Verac's plateskirt 100",
    4995: "Verac's plateskirt 75",
    4996: "Verac's plateskirt 50",
    4997: "Verac's plateskirt 25",
    4998: "Verac's plateskirt 0",
    4999: "null",
    5000: "null",
    5001: "Raw cave eel",
    5002: "Burnt cave eel",
    5003: "Cave eel",
    5004: "Frog spawn",
    5005: "null",
    5006: "null",
    5007: "null",
    5008: "Brooch",
    5009: "Goblin symbol book",
    5010: "Key",
    5011: "Silverware",
    5012: "Peace treaty",
    5013: "Mining helmet",
    5014: "Mining helmet",
    5015: "null",
    5016: "Bone spear",
    5017: "null",
    5018: "Bone club",
    5019: "null",
    5020: "Minecart ticket",
    5021: "Minecart ticket",
    5022: "Minecart ticket",
    5023: "Minecart ticket",
    5024: "Woven top",
    5025: "null",
    5026: "Woven top",
    5027: "null",
    5028: "Woven top",
    5029: "null",
    5030: "Shirt",
    5031: "null",
    5032: "Shirt",
    5033: "null",
    5034: "Shirt",
    5035: "null",
    5036: "Trousers",
    5037: "null",
    5038: "Trousers",
    5039: "null",
    5040: "Trousers",
    5041: "null",
    5042: "Shorts",
    5043: "null",
    5044: "Shorts",
    5045: "null",
    5046: "Shorts",
    5047: "null",
    5048: "Skirt",
    5049: "null",
    5050: "Skirt",
    5051: "null",
    5052: "Skirt",
    5053: "null",
    5054: "Dwarf",
    5055: "null",
    5056: "Dwarven battleaxe",
    5057: "Dwarven battleaxe",
    5058: "Dwarven battleaxe",
    5059: "Dwarven battleaxe",
    5060: "Dwarven battleaxe",
    5061: "Dwarven battleaxe",
    5062: "Left boot",
    5063: "Right boot",
    5064: "Exquisite boots",
    5065: "Book on costumes",
    5066: "Meeting notes",
    5067: "Exquisite clothes",
    5068: "Master farmer",
    5069: "null",
    5070: "Bird nest",
    5071: "Bird nest",
    5072: "Bird nest",
    5073: "Bird nest",
    5074: "Bird nest",
    5075: "Bird nest",
    5076: "Bird's egg",
    5077: "Bird's egg",
    5078: "Bird's egg",
    5079: "null",
    5080: "null",
    5081: "null",
    5082: "null",
    5083: "null",
    5084: "null",
    5085: "null",
    5086: "null",
    5087: "Varrock armour",
    5088: "null",
    5089: "Sea snake",
    5090: "null",
    5091: "null",
    5092: "null",
    5093: "Morytania legs",
    5094: "null",
    5095: "Explorer's ring",
    5096: "Marigold seed",
    5097: "Rosemary seed",
    5098: "Nasturtium seed",
    5099: "Woad seed",
    5100: "Limpwurt seed",
    5101: "Redberry seed",
    5102: "Cadavaberry seed",
    5103: "Dwellberry seed",
    5104: "Jangerberry seed",
    5105: "Whiteberry seed",
    5106: "Poison ivy seed",
    5107: "null",
    5108: "null",
    5109: "null",
    5110: "null",
    5111: "null",
    5112: "null",
    5113: "null",
    5114: "null",
    5115: "null",
    5116: "null",
    5117: "null",
    5118: "null",
    5119: "null",
    5120: "null",
    5121: "null",
    5122: "null",
    5123: "null",
    5124: "null",
    5125: "null",
    5126: "null",
    5127: "null",
    5128: "null",
    5129: "null",
    5130: "null",
    5131: "null",
    5132: "null",
    5133: "null",
    5134: "null",
    5135: "null",
    5136: "null",
    5137: "null",
    5138: "null",
    5139: "null",
    5140: "null",
    5141: "null",
    5142: "null",
    5143: "null",
    5144: "null",
    5145: "null",
    5146: "null",
    5147: "null",
    5148: "null",
    5149: "null",
    5150: "null",
    5151: "null",
    5152: "null",
    5153: "null",
    5154: "null",
    5155: "null",
    5156: "null",
    5157: "null",
    5158: "null",
    5159: "null",
    5160: "null",
    5161: "null",
    5162: "null",
    5163: "null",
    5164: "null",
    5165: "null",
    5166: "null",
    5167: "null",
    5168: "null",
    5169: "null",
    5170: "null",
    5171: "Seeds",
    5172: "null",
    5173: "null",
    5174: "null",
    5175: "null",
    5176: "null",
    5177: "null",
    5178: "null",
    5179: "null",
    5180: "null",
    5181: "null",
    5182: "null",
    5183: "null",
    5184: "null",
    5185: "null",
    5186: "null",
    5187: "null",
    5188: "null",
    5189: "null",
    5190: "null",
    5191: "null",
    5192: "null",
    5193: "null",
    5194: "null",
    5195: "null",
    5196: "null",
    5197: "null",
    5198: "null",
    5199: "null",
    5200: "null",
    5201: "null",
    5202: "null",
    5203: "null",
    5204: "null",
    5205: "null",
    5206: "null",
    5207: "null",
    5208: "null",
    5209: "null",
    5210: "null",
    5211: "null",
    5212: "null",
    5213: "null",
    5214: "null",
    5215: "null",
    5216: "null",
    5217: "null",
    5218: "null",
    5219: "null",
    5220: "null",
    5221: "null",
    5222: "null",
    5223: "null",
    5224: "null",
    5225: "null",
    5226: "null",
    5227: "null",
    5228: "null",
    5229: "null",
    5230: "null",
    5231: "null",
    5232: "null",
    5233: "null",
    5234: "null",
    5235: "null",
    5236: "null",
    5237: "null",
    5238: "null",
    5239: "null",
    5240: "null",
    5241: "null",
    5242: "null",
    5243: "null",
    5244: "null",
    5245: "null",
    5246: "null",
    5247: "null",
    5248: "null",
    5249: "null",
    5250: "null",
    5251: "null",
    5252: "null",
    5253: "null",
    5254: "null",
    5255: "null",
    5256: "null",
    5257: "null",
    5258: "null",
    5259: "null",
    5260: "null",
    5261: "null",
    5262: "null",
    5263: "null",
    5264: "null",
    5265: "null",
    5266: "null",
    5267: "null",
    5268: "null",
    5269: "null",
    5270: "null",
    5271: "null",
    5272: "null",
    5273: "null",
    5274: "null",
    5275: "null",
    5276: "null",
    5277: "null",
    5278: "null",
    5279: "null",
    5280: "Cactus seed",
    5281: "Belladonna seed",
    5282: "Mushroom spore",
    5283: "Apple tree seed",
    5284: "Banana tree seed",
    5285: "Orange tree seed",
    5286: "Curry tree seed",
    5287: "Pineapple seed",
    5288: "Papaya tree seed",
    5289: "Palm tree seed",
    5290: "Calquat tree seed",
    5291: "Guam seed",
    5292: "Marrentill seed",
    5293: "Tarromin seed",
    5294: "Harralander seed",
    5295: "Ranarr seed",
    5296: "Toadflax seed",
    5297: "Irit seed",
    5298: "Avantoe seed",
    5299: "Kwuarm seed",
    5300: "Snapdragon seed",
    5301: "Cadantine seed",
    5302: "Lantadyme seed",
    5303: "Dwarf weed seed",
    5304: "Torstol seed",
    5305: "Barley seed",
    5306: "Jute seed",
    5307: "Hammerstone seed",
    5308: "Asgarnian seed",
    5309: "Yanillian seed",
    5310: "Krandorian seed",
    5311: "Wildblood seed",
    5312: "Acorn",
    5313: "Willow seed",
    5314: "Maple seed",
    5315: "Yew seed",
    5316: "Magic seed",
    5317: "Spirit seed",
    5318: "Potato seed",
    5319: "Onion seed",
    5320: "Sweetcorn seed",
    5321: "Watermelon seed",
    5322: "Tomato seed",
    5323: "Strawberry seed",
    5324: "Cabbage seed",
    5325: "Gardening trowel",
    5326: "null",
    5327: "Spade handle",
    5328: "Spade head",
    5329: "Secateurs",
    5330: "null",
    5331: "Watering can",
    5332: "null",
    5333: "Watering can(1)",
    5334: "Watering can(2)",
    5335: "Watering can(3)",
    5336: "Watering can(4)",
    5337: "Watering can(5)",
    5338: "Watering can(6)",
    5339: "Watering can(7)",
    5340: "Watering can(8)",
    5341: "Rake",
    5342: "null",
    5343: "Seed dibber",
    5344: "null",
    5345: "Gardening boots",
    5346: "null",
    5347: "Rake handle",
    5348: "Rake head",
    5349: "Smoke devil",
    5350: "Empty plant pot",
    5351: "null",
    5352: "Unfired plant pot",
    5353: "null",
    5354: "Filled plant pot",
    5355: "null",
    5356: "Plant pot",
    5357: "null",
    5358: "Oak seedling",
    5359: "Willow seedling",
    5360: "Maple seedling",
    5361: "Yew seedling",
    5362: "Magic seedling",
    5363: "Spirit seedling",
    5364: "Oak seedling (w)",
    5365: "Willow seedling (w)",
    5366: "Maple seedling (w)",
    5367: "Yew seedling (w)",
    5368: "Magic seedling (w)",
    5369: "Spirit seedling (w)",
    5370: "Oak sapling",
    5371: "Willow sapling",
    5372: "Maple sapling",
    5373: "Yew sapling",
    5374: "Magic sapling",
    5375: "Spirit sapling",
    5376: "Basket",
    5377: "null",
    5378: "Apples(1)",
    5379: "null",
    5380: "Apples(2)",
    5381: "null",
    5382: "Apples(3)",
    5383: "null",
    5384: "Apples(4)",
    5385: "null",
    5386: "Apples(5)",
    5387: "null",
    5388: "Oranges(1)",
    5389: "null",
    5390: "Oranges(2)",
    5391: "null",
    5392: "Oranges(3)",
    5393: "null",
    5394: "Oranges(4)",
    5395: "null",
    5396: "Oranges(5)",
    5397: "null",
    5398: "Strawberries(1)",
    5399: "null",
    5400: "Strawberries(2)",
    5401: "null",
    5402: "Strawberries(3)",
    5403: "null",
    5404: "Strawberries(4)",
    5405: "null",
    5406: "Strawberries(5)",
    5407: "null",
    5408: "Bananas(1)",
    5409: "null",
    5410: "Bananas(2)",
    5411: "null",
    5412: "Bananas(3)",
    5413: "null",
    5414: "Bananas(4)",
    5415: "null",
    5416: "Bananas(5)",
    5417: "null",
    5418: "Empty sack",
    5419: "null",
    5420: "Potatoes(1)",
    5421: "null",
    5422: "Potatoes(2)",
    5423: "null",
    5424: "Potatoes(3)",
    5425: "null",
    5426: "Potatoes(4)",
    5427: "null",
    5428: "Potatoes(5)",
    5429: "null",
    5430: "Potatoes(6)",
    5431: "null",
    5432: "Potatoes(7)",
    5433: "null",
    5434: "Potatoes(8)",
    5435: "null",
    5436: "Potatoes(9)",
    5437: "null",
    5438: "Potatoes(10)",
    5439: "null",
    5440: "Onions(1)",
    5441: "null",
    5442: "Onions(2)",
    5443: "null",
    5444: "Onions(3)",
    5445: "null",
    5446: "Onions(4)",
    5447: "null",
    5448: "Onions(5)",
    5449: "null",
    5450: "Onions(6)",
    5451: "null",
    5452: "Onions(7)",
    5453: "null",
    5454: "Onions(8)",
    5455: "null",
    5456: "Onions(9)",
    5457: "null",
    5458: "Onions(10)",
    5459: "null",
    5460: "Cabbages(1)",
    5461: "null",
    5462: "Cabbages(2)",
    5463: "null",
    5464: "Cabbages(3)",
    5465: "null",
    5466: "Cabbages(4)",
    5467: "null",
    5468: "Cabbages(5)",
    5469: "null",
    5470: "Cabbages(6)",
    5471: "null",
    5472: "Cabbages(7)",
    5473: "null",
    5474: "Cabbages(8)",
    5475: "null",
    5476: "Cabbages(9)",
    5477: "null",
    5478: "Cabbages(10)",
    5479: "null",
    5480: "Apple seedling",
    5481: "Banana seedling",
    5482: "Orange seedling",
    5483: "Curry seedling",
    5484: "Pineapple seedling",
    5485: "Papaya seedling",
    5486: "Palm seedling",
    5487: "Calquat seedling",
    5488: "Apple seedling (w)",
    5489: "Banana seedling (w)",
    5490: "Orange seedling (w)",
    5491: "Curry seedling (w)",
    5492: "Pineapple seedling (w)",
    5493: "Papaya seedling (w)",
    5494: "Palm seedling (w)",
    5495: "Calquat seedling (w)",
    5496: "Apple sapling",
    5497: "Banana sapling",
    5498: "Orange sapling",
    5499: "Curry sapling",
    5500: "Pineapple sapling",
    5501: "Papaya sapling",
    5502: "Palm sapling",
    5503: "Calquat sapling",
    5504: "Strawberry",
    5505: "null",
    5506: "Old man's message",
    5507: "Strange book",
    5508: "Book of folklore",
    5509: "Small pouch",
    5510: "Medium pouch",
    5511: "Medium pouch",
    5512: "Large pouch",
    5513: "Large pouch",
    5514: "Giant pouch",
    5515: "Giant pouch",
    5516: "Elemental talisman",
    5517: "null",
    5518: "Scrying orb",
    5519: "Scrying orb",
    5520: "Abyssal book",
    5521: "Binding necklace",
    5522: "null",
    5523: "Tiara mould",
    5524: "null",
    5525: "Tiara",
    5526: "null",
    5527: "Air tiara",
    5528: "null",
    5529: "Mind tiara",
    5530: "null",
    5531: "Water tiara",
    5532: "null",
    5533: "Body tiara",
    5534: "null",
    5535: "Earth tiara",
    5536: "null",
    5537: "Fire tiara",
    5538: "null",
    5539: "Cosmic tiara",
    5540: "null",
    5541: "Nature tiara",
    5542: "null",
    5543: "Chaos tiara",
    5544: "null",
    5545: "Law tiara",
    5546: "Null",
    5547: "Death tiara",
    5548: "null",
    5549: "Blood tiara",
    5550: "null",
    5551: "Null",
    5552: "Null",
    5553: "Rogue top",
    5554: "Rogue mask",
    5555: "Rogue trousers",
    5556: "Rogue gloves",
    5557: "Rogue boots",
    5558: "Rogue kit",
    5559: "Flash powder",
    5560: "Stethoscope",
    5561: "Mystic jewel",
    5562: "Gear",
    5563: "Gear",
    5564: "Gear",
    5565: "Gear",
    5566: "Gear",
    5567: "Gear",
    5568: "Tile",
    5569: "Tiles",
    5570: "Tiles",
    5571: "Tiles",
    5572: "Null",
    5573: "Desert amulet",
    5574: "Initiate sallet",
    5575: "Initiate hauberk",
    5576: "Initiate cuisse",
    5577: "Cupric sulfate",
    5578: "Acetic acid",
    5579: "Gypsum",
    5580: "Sodium chloride",
    5581: "Nitrous oxide",
    5582: "Vial of liquid",
    5583: "Tin ore powder",
    5584: "Cupric ore powder",
    5585: "Bronze key",
    5586: "Metal spade",
    5587: "Metal spade",
    5588: "Alchemical notes",
    5589: "??? mixture",
    5590: "??? mixture",
    5591: "??? mixture",
    5592: "Tin",
    5593: "Tin",
    5594: "Tin",
    5595: "Tin",
    5596: "Tin",
    5597: "Tin",
    5598: "Tin",
    5599: "Tin",
    5600: "Tin",
    5601: "Chisel",
    5602: "Bronze wire",
    5603: "Shears",
    5604: "Magnet",
    5605: "Knife",
    5606: "Null",
    5607: "Grain",
    5608: "Fox",
    5609: "Chicken",
    5610: "Hourglass",
    5611: "null",
    5612: "null",
    5613: "null",
    5614: "Magic carpet",
    5615: "Shaikahan bonemeal",
    5616: "Bronze arrow(p+)",
    5617: "Iron arrow(p+)",
    5618: "Steel arrow(p+)",
    5619: "Mithril arrow(p+)",
    5620: "Adamant arrow(p+)",
    5621: "Rune arrow(p+)",
    5622: "Bronze arrow(p++)",
    5623: "Iron arrow(p++)",
    5624: "Steel arrow(p++)",
    5625: "Mithril arrow(p++)",
    5626: "Adamant arrow(p++)",
    5627: "Rune arrow(p++)",
    5628: "Bronze dart(p+)",
    5629: "Iron dart(p+)",
    5630: "Steel dart(p+)",
    5631: "Black dart(p+)",
    5632: "Mithril dart(p+)",
    5633: "Adamant dart(p+)",
    5634: "Rune dart(p+)",
    5635: "Bronze dart(p++)",
    5636: "Iron dart(p++)",
    5637: "Steel dart(p++)",
    5638: "Black dart(p++)",
    5639: "Mithril dart(p++)",
    5640: "Adamant dart(p++)",
    5641: "Rune dart(p++)",
    5642: "Bronze javelin(p+)",
    5643: "Iron javelin(p+)",
    5644: "Steel javelin(p+)",
    5645: "Mithril javelin(p+)",
    5646: "Adamant javelin(p+)",
    5647: "Rune javelin(p+)",
    5648: "Bronze javelin(p++)",
    5649: "Iron javelin(p++)",
    5650: "Steel javelin(p++)",
    5651: "Mithril javelin(p++)",
    5652: "Adamant javelin(p++)",
    5653: "Rune javelin(p++)",
    5654: "Bronze knife(p+)",
    5655: "Iron knife(p+)",
    5656: "Steel knife(p+)",
    5657: "Mithril knife(p+)",
    5658: "Black knife(p+)",
    5659: "Adamant knife(p+)",
    5660: "Rune knife(p+)",
    5661: "Bronze knife(p++)",
    5662: "Iron knife(p++)",
    5663: "Steel knife(p++)",
    5664: "Mithril knife(p++)",
    5665: "Black knife(p++)",
    5666: "Adamant knife(p++)",
    5667: "Rune knife(p++)",
    5668: "Iron dagger(p+)",
    5669: "null",
    5670: "Bronze dagger(p+)",
    5671: "null",
    5672: "Steel dagger(p+)",
    5673: "null",
    5674: "Mithril dagger(p+)",
    5675: "null",
    5676: "Adamant dagger(p+)",
    5677: "null",
    5678: "Rune dagger(p+)",
    5679: "null",
    5680: "Dragon dagger(p+)",
    5681: "null",
    5682: "Black dagger(p+)",
    5683: "null",
    5684: "Poison dagger(p+)",
    5685: "null",
    5686: "Iron dagger(p++)",
    5687: "null",
    5688: "Bronze dagger(p++)",
    5689: "null",
    5690: "Steel dagger(p++)",
    5691: "null",
    5692: "Mithril dagger(p++)",
    5693: "null",
    5694: "Adamant dagger(p++)",
    5695: "null",
    5696: "Rune dagger(p++)",
    5697: "null",
    5698: "Dragon dagger(p++)",
    5699: "null",
    5700: "Black dagger(p++)",
    5701: "null",
    5702: "Poison dagger(p++)",
    5703: "null",
    5704: "Bronze spear(p+)",
    5705: "null",
    5706: "Iron spear(p+)",
    5707: "null",
    5708: "Steel spear(p+)",
    5709: "null",
    5710: "Mithril spear(p+)",
    5711: "null",
    5712: "Adamant spear(p+)",
    5713: "null",
    5714: "Rune spear(p+)",
    5715: "null",
    5716: "Dragon spear(p+)",
    5717: "null",
    5718: "Bronze spear(p++)",
    5719: "null",
    5720: "Iron spear(p++)",
    5721: "null",
    5722: "Steel spear(p++)",
    5723: "null",
    5724: "Mithril spear(p++)",
    5725: "null",
    5726: "Adamant spear(p++)",
    5727: "null",
    5728: "Rune spear(p++)",
    5729: "null",
    5730: "Dragon spear(p++)",
    5731: "null",
    5732: "Stool",
    5733: "Rotten potato",
    5734: "Black spear(p+)",
    5735: "null",
    5736: "Black spear(p++)",
    5737: "null",
    5738: "Woad leaf",
    5739: "Asgarnian ale(m)",
    5740: "null",
    5741: "Mature wmb",
    5742: "null",
    5743: "Greenman's ale(m)",
    5744: "null",
    5745: "Dragon bitter(m)",
    5746: "null",
    5747: "Dwarven stout(m)",
    5748: "null",
    5749: "Moonlight mead(m)",
    5750: "null",
    5751: "Axeman's folly",
    5752: "null",
    5753: "Axeman's folly(m)",
    5754: "null",
    5755: "Chef's delight",
    5756: "null",
    5757: "Chef's delight(m)",
    5758: "null",
    5759: "Slayer's respite",
    5760: "null",
    5761: "Slayer's respite(m)",
    5762: "null",
    5763: "Cider",
    5764: "null",
    5765: "Mature cider",
    5766: "null",
    5767: "Ale yeast",
    5768: "null",
    5769: "Calquat keg",
    5770: "null",
    5771: "Dwarven stout(1)",
    5772: "null",
    5773: "Dwarven stout(2)",
    5774: "null",
    5775: "Dwarven stout(3)",
    5776: "null",
    5777: "Dwarven stout(4)",
    5778: "null",
    5779: "Asgarnian ale(1)",
    5780: "null",
    5781: "Asgarnian ale(2)",
    5782: "null",
    5783: "Asgarnian ale(3)",
    5784: "null",
    5785: "Asgarnian ale(4)",
    5786: "null",
    5787: "Greenmans ale(1)",
    5788: "null",
    5789: "Greenmans ale(2)",
    5790: "null",
    5791: "Greenmans ale(3)",
    5792: "null",
    5793: "Greenmans ale(4)",
    5794: "null",
    5795: "Mind bomb(1)",
    5796: "null",
    5797: "Mind bomb(2)",
    5798: "null",
    5799: "Mind bomb(3)",
    5800: "null",
    5801: "Mind bomb(4)",
    5802: "null",
    5803: "Dragon bitter(1)",
    5804: "null",
    5805: "Dragon bitter(2)",
    5806: "null",
    5807: "Dragon bitter(3)",
    5808: "null",
    5809: "Dragon bitter(4)",
    5810: "null",
    5811: "Moonlight mead(1)",
    5812: "null",
    5813: "Moonlight mead(2)",
    5814: "null",
    5815: "Moonlight mead(3)",
    5816: "null",
    5817: "Moonlight mead(4)",
    5818: "null",
    5819: "Axeman's folly(1)",
    5820: "null",
    5821: "Axeman's folly(2)",
    5822: "null",
    5823: "Axeman's folly(3)",
    5824: "null",
    5825: "Axeman's folly(4)",
    5826: "null",
    5827: "Chef's delight(1)",
    5828: "null",
    5829: "Chef's delight(2)",
    5830: "null",
    5831: "Chef's delight(3)",
    5832: "null",
    5833: "Chef's delight(4)",
    5834: "null",
    5835: "Slayer's respite(1)",
    5836: "null",
    5837: "Slayer's respite(2)",
    5838: "null",
    5839: "Slayer's respite(3)",
    5840: "null",
    5841: "Slayer's respite(4)",
    5842: "null",
    5843: "Cider(1)",
    5844: "null",
    5845: "Cider(2)",
    5846: "null",
    5847: "Cider(3)",
    5848: "null",
    5849: "Cider(4)",
    5850: "null",
    5851: "Dwarven stout(m1)",
    5852: "null",
    5853: "Dwarven stout(m2)",
    5854: "null",
    5855: "Dwarven stout(m3)",
    5856: "null",
    5857: "Dwarven stout(m4)",
    5858: "null",
    5859: "Asgarnian ale(m1)",
    5860: "null",
    5861: "Asgarnian ale(m2)",
    5862: "null",
    5863: "Asgarnian ale(m3)",
    5864: "null",
    5865: "Asgarnian ale(m4)",
    5866: "null",
    5867: "Greenmans ale(m1)",
    5868: "null",
    5869: "Greenmans ale(m2)",
    5870: "null",
    5871: "Greenmans ale(m3)",
    5872: "null",
    5873: "Greenmans ale(m4)",
    5874: "null",
    5875: "Mind bomb(m1)",
    5876: "null",
    5877: "Mind bomb(m2)",
    5878: "null",
    5879: "Mind bomb(m3)",
    5880: "null",
    5881: "Mind bomb(m4)",
    5882: "null",
    5883: "Dragon bitter(m1)",
    5884: "null",
    5885: "Dragon bitter(m2)",
    5886: "null",
    5887: "Dragon bitter(m3)",
    5888: "null",
    5889: "Dragon bitter(m4)",
    5890: "null",
    5891: "Moonlight mead(m1)",
    5892: "null",
    5893: "Moonlight mead(m2)",
    5894: "null",
    5895: "Moonlight mead(m3)",
    5896: "null",
    5897: "Moonlight mead(m4)",
    5898: "null",
    5899: "Axeman's folly(m1)",
    5900: "null",
    5901: "Axeman's folly(m2)",
    5902: "null",
    5903: "Axeman's folly(m3)",
    5904: "null",
    5905: "Axeman's folly(m4)",
    5906: "null",
    5907: "Chef's delight(m1)",
    5908: "null",
    5909: "Chef's delight(m2)",
    5910: "null",
    5911: "Chef's delight(m3)",
    5912: "null",
    5913: "Chef's delight(m4)",
    5914: "null",
    5915: "Slayer's respite(m1)",
    5916: "null",
    5917: "Slayer's respite(m2)",
    5918: "null",
    5919: "Slayer's respite(m3)",
    5920: "null",
    5921: "Slayer's respite(m4)",
    5922: "null",
    5923: "Cider(m1)",
    5924: "null",
    5925: "Cider(m2)",
    5926: "null",
    5927: "Cider(m3)",
    5928: "null",
    5929: "Cider(m4)",
    5930: "null",
    5931: "Jute fibre",
    5932: "null",
    5933: "Willow branch",
    5934: "null",
    5935: "Coconut milk",
    5936: "Weapon poison+ (unf)",
    5937: "Weapon poison(+)",
    5938: "null",
    5939: "Weapon poison++ (unf)",
    5940: "Weapon poison(++)",
    5941: "null",
    5942: "Antidote+ (unf)",
    5943: "Antidote+(4)",
    5944: "null",
    5945: "Antidote+(3)",
    5946: "null",
    5947: "Antidote+(2)",
    5948: "null",
    5949: "Antidote+(1)",
    5950: "null",
    5951: "Antidote++ (unf)",
    5952: "Antidote++(4)",
    5953: "null",
    5954: "Antidote++(3)",
    5955: "null",
    5956: "Antidote++(2)",
    5957: "null",
    5958: "Antidote++(1)",
    5959: "null",
    5960: "Tomatoes(1)",
    5961: "null",
    5962: "Tomatoes(2)",
    5963: "null",
    5964: "Tomatoes(3)",
    5965: "null",
    5966: "Tomatoes(4)",
    5967: "null",
    5968: "Tomatoes(5)",
    5969: "null",
    5970: "Curry leaf",
    5971: "null",
    5972: "Papaya fruit",
    5973: "null",
    5974: "Coconut",
    5975: "null",
    5976: "Half coconut",
    5977: "null",
    5978: "Coconut shell",
    5979: "null",
    5980: "Calquat fruit",
    5981: "null",
    5982: "Watermelon",
    5983: "null",
    5984: "Watermelon slice",
    5985: "null",
    5986: "Sweetcorn",
    5987: "null",
    5988: "Cooked sweetcorn",
    5989: "null",
    5990: "Burnt sweetcorn",
    5991: "null",
    5992: "Apple mush",
    5993: "null",
    5994: "Hammerstone hops",
    5995: "null",
    5996: "Asgarnian hops",
    5997: "null",
    5998: "Yanillian hops",
    5999: "null",
    6000: "Krandorian hops",
    6001: "null",
    6002: "Wildblood hops",
    6003: "null",
    6004: "Mushroom",
    6005: "null",
    6006: "Barley",
    6007: "null",
    6008: "Barley malt",
    6009: "null",
    6010: "Marigolds",
    6011: "null",
    6012: "Nasturtiums",
    6013: "null",
    6014: "Rosemary",
    6015: "null",
    6016: "Cactus spine",
    6017: "null",
    6018: "Poison ivy berries",
    6019: "null",
    6020: "Leaves",
    6021: "Null",
    6022: "Oak leaves",
    6023: "Null",
    6024: "Willow leaves",
    6025: "Null",
    6026: "Yew leaves",
    6027: "Null",
    6028: "Maple leaves",
    6029: "Null",
    6030: "Magic leaves",
    6031: "Null",
    6032: "Compost",
    6033: "null",
    6034: "Supercompost",
    6035: "null",
    6036: "Plant cure",
    6037: "null",
    6038: "Magic string",
    6039: "null",
    6040: "Amulet of nature",
    6041: "Pre-nature amulet",
    6042: "null",
    6043: "Oak roots",
    6044: "null",
    6045: "Willow roots",
    6046: "null",
    6047: "Maple roots",
    6048: "null",
    6049: "Yew roots",
    6050: "null",
    6051: "Magic roots",
    6052: "null",
    6053: "Spirit roots",
    6054: "null",
    6055: "Weeds",
    6056: "null",
    6057: "Hay sack",
    6058: "Hay sack",
    6059: "Scarecrow",
    6060: "null",
    6061: "Bronze bolts (p+)",
    6062: "Bronze bolts (p++)",
    6063: "Spirit tree",
    6064: "Bloody mourner top",
    6065: "Mourner top",
    6066: "Ripped mourner trousers",
    6067: "Mourner trousers",
    6068: "Mourner gloves",
    6069: "Mourner boots",
    6070: "Mourner cloak",
    6071: "Mourner letter",
    6072: "Tegid's soap",
    6073: "Prifddinas' history",
    6074: "null",
    6075: "Eastern discovery",
    6076: "null",
    6077: "Eastern settlement",
    6078: "null",
    6079: "The great divide",
    6080: "null",
    6081: "Broken device",
    6082: "Fixed device",
    6083: "Tarnished key",
    6084: "Worn key",
    6085: "Red dye bellows",
    6086: "Blue dye bellows",
    6087: "Yellow dye bellows",
    6088: "Green dye bellows",
    6089: "Blue toad",
    6090: "Red toad",
    6091: "Yellow toad",
    6092: "Green toad",
    6093: "Rotten apples",
    6094: "Apple barrel",
    6095: "Naphtha apple mix",
    6096: "Toxic naphtha",
    6097: "Sieve",
    6098: "Toxic powder",
    6099: "Teleport crystal (4)",
    6100: "Teleport crystal (3)",
    6101: "Teleport crystal (2)",
    6102: "Teleport crystal (1)",
    6103: "Crystal teleport seed",
    6104: "New key",
    6105: "Elf",
    6106: "Ghostly boots",
    6107: "Ghostly robe",
    6108: "Ghostly robe",
    6109: "Ghostly hood",
    6110: "Ghostly gloves",
    6111: "Ghostly cloak",
    6112: "Kelda seed",
    6113: "Kelda hops",
    6114: "null",
    6115: "null",
    6116: "null",
    6117: "null",
    6118: "Kelda stout",
    6119: "Square stone",
    6120: "Square stone",
    6121: "Letter",
    6122: "A chair",
    6123: "Beer glass",
    6124: "null",
    6125: "Enchanted lyre(2)",
    6126: "Enchanted lyre(3)",
    6127: "Enchanted lyre(4)",
    6128: "Rock-shell helm",
    6129: "Rock-shell plate",
    6130: "Rock-shell legs",
    6131: "Spined helm",
    6132: "null",
    6133: "Spined body",
    6134: "null",
    6135: "Spined chaps",
    6136: "null",
    6137: "Skeletal helm",
    6138: "null",
    6139: "Skeletal top",
    6140: "null",
    6141: "Skeletal bottoms",
    6142: "null",
    6143: "Spined boots",
    6144: "null",
    6145: "Rock-shell boots",
    6146: "null",
    6147: "Skeletal boots",
    6148: "null",
    6149: "Spined gloves",
    6150: "null",
    6151: "Rock-shell gloves",
    6152: "null",
    6153: "Skeletal gloves",
    6154: "null",
    6155: "Dagannoth hide",
    6156: "null",
    6157: "Rock-shell chunk",
    6158: "null",
    6159: "Rock-shell shard",
    6160: "null",
    6161: "Rock-shell splinter",
    6162: "null",
    6163: "Skull piece",
    6164: "null",
    6165: "Ribcage piece",
    6166: "null",
    6167: "Fibula piece",
    6168: "null",
    6169: "Circular hide",
    6170: "null",
    6171: "Flattened hide",
    6172: "null",
    6173: "Stretched hide",
    6174: "null",
    6175: "null",
    6176: "null",
    6177: "null",
    6178: "Raw pheasant",
    6179: "Raw pheasant",
    6180: "Lederhosen top",
    6181: "Lederhosen shorts",
    6182: "Lederhosen hat",
    6183: "Frog token",
    6184: "Royal frog tunic",
    6185: "Royal frog leggings",
    6186: "Royal frog blouse",
    6187: "Royal frog skirt",
    6188: "Frog mask",
    6189: "null",
    6190: "null",
    6191: "null",
    6192: "null",
    6193: "null",
    6194: "null",
    6195: "null",
    6196: "null",
    6197: "null",
    6198: "null",
    6199: "Mystery box",
    6200: "Raw fishlike thing",
    6201: "null",
    6202: "Fishlike thing",
    6203: "null",
    6204: "Raw fishlike thing",
    6205: "null",
    6206: "Fishlike thing",
    6207: "null",
    6208: "Null",
    6209: "Small fishing net",
    6210: "null",
    6211: "Teak pyre logs",
    6212: "null",
    6213: "Mahogany pyre logs",
    6214: "null",
    6215: "Broodoo shield (10)",
    6216: "null",
    6217: "Broodoo shield (9)",
    6218: "null",
    6219: "Broodoo shield (8)",
    6220: "null",
    6221: "Broodoo shield (7)",
    6222: "null",
    6223: "Broodoo shield (6)",
    6224: "null",
    6225: "Broodoo shield (5)",
    6226: "null",
    6227: "Broodoo shield (4)",
    6228: "null",
    6229: "Broodoo shield (3)",
    6230: "null",
    6231: "Broodoo shield (2)",
    6232: "null",
    6233: "Broodoo shield (1)",
    6234: "null",
    6235: "Broodoo shield",
    6236: "null",
    6237: "Broodoo shield (10)",
    6238: "null",
    6239: "Broodoo shield (9)",
    6240: "null",
    6241: "Broodoo shield (8)",
    6242: "null",
    6243: "Broodoo shield (7)",
    6244: "null",
    6245: "Broodoo shield (6)",
    6246: "null",
    6247: "Broodoo shield (5)",
    6248: "null",
    6249: "Broodoo shield (4)",
    6250: "null",
    6251: "Broodoo shield (3)",
    6252: "null",
    6253: "Broodoo shield (2)",
    6254: "null",
    6255: "Broodoo shield (1)",
    6256: "null",
    6257: "Broodoo shield",
    6258: "null",
    6259: "Broodoo shield (10)",
    6260: "null",
    6261: "Broodoo shield (9)",
    6262: "null",
    6263: "Broodoo shield (8)",
    6264: "null",
    6265: "Broodoo shield (7)",
    6266: "null",
    6267: "Broodoo shield (6)",
    6268: "null",
    6269: "Broodoo shield (5)",
    6270: "null",
    6271: "Broodoo shield (4)",
    6272: "null",
    6273: "Broodoo shield (3)",
    6274: "null",
    6275: "Broodoo shield (2)",
    6276: "null",
    6277: "Broodoo shield (1)",
    6278: "null",
    6279: "Broodoo shield",
    6280: "null",
    6281: "Thatch spar light",
    6282: "null",
    6283: "Thatch spar med",
    6284: "null",
    6285: "Thatch spar dense",
    6286: "null",
    6287: "Snake hide",
    6288: "null",
    6289: "Snakeskin",
    6290: "null",
    6291: "Spider carcass",
    6292: "null",
    6293: "Spider on stick",
    6294: "null",
    6295: "Spider on shaft",
    6296: "null",
    6297: "Spider on stick",
    6298: "null",
    6299: "Spider on shaft",
    6300: "null",
    6301: "Burnt spider",
    6302: "null",
    6303: "Spider on shaft",
    6304: "null",
    6305: "Skewer stick",
    6306: "Trading sticks",
    6307: "null",
    6308: "null",
    6309: "null",
    6310: "null",
    6311: "Gout tuber",
    6312: "null",
    6313: "Opal machete",
    6314: "null",
    6315: "Jade machete",
    6316: "null",
    6317: "Red topaz machete",
    6318: "null",
    6319: "Proboscis",
    6320: "null",
    6321: "null",
    6322: "Snakeskin body",
    6323: "null",
    6324: "Snakeskin chaps",
    6325: "null",
    6326: "Snakeskin bandana",
    6327: "null",
    6328: "Snakeskin boots",
    6329: "null",
    6330: "Snakeskin vambraces",
    6331: "null",
    6332: "Mahogany logs",
    6333: "Teak logs",
    6334: "null",
    6335: "Tribal mask",
    6336: "null",
    6337: "Tribal mask",
    6338: "null",
    6339: "Tribal mask",
    6340: "null",
    6341: "Tribal top",
    6342: "null",
    6343: "Villager robe",
    6344: "null",
    6345: "Villager hat",
    6346: "null",
    6347: "Villager armband",
    6348: "null",
    6349: "Villager sandals",
    6350: "null",
    6351: "Tribal top",
    6352: "null",
    6353: "Villager robe",
    6354: "null",
    6355: "Villager hat",
    6356: "null",
    6357: "Villager sandals",
    6358: "null",
    6359: "Villager armband",
    6360: "null",
    6361: "Tribal top",
    6362: "null",
    6363: "Villager robe",
    6364: "null",
    6365: "Villager hat",
    6366: "null",
    6367: "Villager sandals",
    6368: "null",
    6369: "Villager armband",
    6370: "null",
    6371: "Tribal top",
    6372: "null",
    6373: "Villager robe",
    6374: "null",
    6375: "Villager hat",
    6376: "null",
    6377: "Villager sandals",
    6378: "null",
    6379: "Villager armband",
    6380: "null",
    6381: "null",
    6382: "Fez",
    6383: "null",
    6384: "Desert top",
    6385: "null",
    6386: "Desert robes",
    6387: "null",
    6388: "Desert top",
    6389: "null",
    6390: "Desert legs",
    6391: "null",
    6392: "Menaphite purple hat",
    6393: "null",
    6394: "Menaphite purple top",
    6395: "null",
    6396: "Menaphite purple robe",
    6397: "null",
    6398: "Menaphite purple kilt",
    6399: "null",
    6400: "Menaphite red hat",
    6401: "null",
    6402: "Menaphite red top",
    6403: "null",
    6404: "Menaphite red robe",
    6405: "null",
    6406: "Menaphite red kilt",
    6407: "null",
    6408: "Oak blackjack(o)",
    6409: "null",
    6410: "Oak blackjack(d)",
    6411: "null",
    6412: "Willow blackjack(o)",
    6413: "null",
    6414: "Willow blackjack(d)",
    6415: "null",
    6416: "Maple blackjack",
    6417: "null",
    6418: "Maple blackjack(o)",
    6419: "null",
    6420: "Maple blackjack(d)",
    6421: "null",
    6422: "Air rune",
    6423: "null",
    6424: "Water rune",
    6425: "null",
    6426: "Earth rune",
    6427: "null",
    6428: "Fire rune",
    6429: "null",
    6430: "Chaos rune",
    6431: "null",
    6432: "Death rune",
    6433: "null",
    6434: "Law rune",
    6435: "null",
    6436: "Mind rune",
    6437: "null",
    6438: "Body rune",
    6439: "null",
    6440: "null",
    6441: "null",
    6442: "null",
    6443: "null",
    6444: "null",
    6445: "null",
    6446: "null",
    6447: "null",
    6448: "Spadeful of coke",
    6449: "null",
    6450: "Kandarin headgear",
    6451: "null",
    6452: "Mage arena cape",
    6453: "White rose seed",
    6454: "Red rose seed",
    6455: "Pink rose seed",
    6456: "Vine seed",
    6457: "Delphinium seed",
    6458: "Orchid seed",
    6459: "Orchid seed",
    6460: "Snowdrop seed",
    6461: "White tree shoot",
    6462: "White tree shoot",
    6463: "White tree shoot (w)",
    6464: "White tree sapling",
    6465: "Ring of charos(a)",
    6466: "Rune shards",
    6467: "Rune dust",
    6468: "Plant cure",
    6469: "White tree fruit",
    6470: "Compost potion(4)",
    6471: "null",
    6472: "Compost potion(3)",
    6473: "null",
    6474: "Compost potion(2)",
    6475: "null",
    6476: "Compost potion(1)",
    6477: "null",
    6478: "Trolley",
    6479: "List",
    6480: "null",
    6481: "null",
    6482: "null",
    6483: "null",
    6484: "null",
    6485: "null",
    6486: "null",
    6487: "null",
    6488: "null",
    6489: "null",
    6490: "null",
    6491: "null",
    6492: "null",
    6493: "null",
    6494: "null",
    6495: "null",
    6496: "null",
    6497: "null",
    6498: "null",
    6499: "null",
    6500: "null",
    6501: "null",
    6502: "null",
    6503: "null",
    6504: "null",
    6505: "null",
    6506: "null",
    6507: "null",
    6508: "null",
    6509: "null",
    6510: "null",
    6511: "null",
    6512: "null",
    6513: "null",
    6514: "Agility jump",
    6515: "Agility balance",
    6516: "Agility contortion",
    6517: "Agility climb",
    6518: "Agility jump",
    6519: "Agility balance",
    6520: "Agility contortion",
    6521: "Agility climb",
    6522: "Toktz-xil-ul",
    6523: "Toktz-xil-ak",
    6524: "Toktz-ket-xil",
    6525: "Toktz-xil-ek",
    6526: "Toktz-mej-tal",
    6527: "Tzhaar-ket-em",
    6528: "Tzhaar-ket-om",
    6529: "Tokkul",
    6530: "null",
    6531: "null",
    6532: "null",
    6533: "null",
    6534: "null",
    6535: "null",
    6536: "null",
    6537: "null",
    6538: "null",
    6539: "null",
    6540: "null",
    6541: "Mouse toy",
    6542: "Present",
    6543: "Antique lamp",
    6544: "Catspeak amulet(e)",
    6545: "Chores",
    6546: "Recipe",
    6547: "Doctor's hat",
    6548: "Nurse hat",
    6549: "Lazy cat",
    6550: "Lazy cat",
    6551: "Lazy cat",
    6552: "Lazy cat",
    6553: "Lazy cat",
    6554: "Lazy cat",
    6555: "Wily cat",
    6556: "Wily cat",
    6557: "Wily cat",
    6558: "Wily cat",
    6559: "Wily cat",
    6560: "Wily cat",
    6561: "Ahab's beer",
    6562: "Mud battlestaff",
    6563: "Mystic mud staff",
    6564: "null",
    6565: "null",
    6566: "null",
    6567: "null",
    6568: "Obsidian cape",
    6569: "null",
    6570: "Fire cape",
    6571: "Uncut onyx",
    6572: "null",
    6573: "Onyx",
    6574: "null",
    6575: "Onyx ring",
    6576: "null",
    6577: "Onyx necklace",
    6578: "null",
    6579: "Onyx amulet (u)",
    6580: "null",
    6581: "Onyx amulet",
    6582: "null",
    6583: "Ring of stone",
    6584: "null",
    6585: "Amulet of fury",
    6586: "null",
    6587: "White claws",
    6588: "null",
    6589: "White battleaxe",
    6590: "null",
    6591: "White dagger",
    6592: "null",
    6593: "White dagger(p)",
    6594: "null",
    6595: "White dagger(p+)",
    6596: "null",
    6597: "White dagger(p++)",
    6598: "null",
    6599: "White halberd",
    6600: "null",
    6601: "White mace",
    6602: "null",
    6603: "White magic staff",
    6604: "null",
    6605: "White sword",
    6606: "null",
    6607: "White longsword",
    6608: "null",
    6609: "White 2h sword",
    6610: "null",
    6611: "White scimitar",
    6612: "null",
    6613: "White warhammer",
    6614: "null",
    6615: "White chainbody",
    6616: "null",
    6617: "White platebody",
    6618: "null",
    6619: "White boots",
    6620: "null",
    6621: "White med helm",
    6622: "null",
    6623: "White full helm",
    6624: "null",
    6625: "White platelegs",
    6626: "null",
    6627: "White plateskirt",
    6628: "null",
    6629: "White gloves",
    6630: "null",
    6631: "White sq shield",
    6632: "null",
    6633: "White kiteshield",
    6634: "null",
    6635: "Commorb",
    6636: "Solus's hat",
    6637: "Dark beast",
    6638: "Colour wheel",
    6639: "Hand mirror",
    6640: "Red crystal",
    6641: "Yellow crystal",
    6642: "Green crystal",
    6643: "Cyan crystal",
    6644: "Blue crystal",
    6645: "Magenta crystal",
    6646: "Fractured crystal",
    6647: "Fractured crystal",
    6648: "Item list",
    6649: "Edern's journal",
    6650: "Blackened crystal",
    6651: "Newly made crystal",
    6652: "Newly made crystal",
    6653: "Crystal trinket",
    6654: "Camo top",
    6655: "Camo bottoms",
    6656: "Camo helmet",
    6657: "Camo top",
    6658: "Camo bottoms",
    6659: "Camo helmet",
    6660: "Fishing explosive",
    6661: "Mogre",
    6662: "Broken fishing rod",
    6663: "Forlorn boot",
    6664: "Fishing explosive",
    6665: "Mudskipper hat",
    6666: "Flippers",
    6667: "Empty fishbowl",
    6668: "Fishbowl",
    6669: "Fishbowl",
    6670: "Fishbowl",
    6671: "Fishbowl",
    6672: "Fishbowl",
    6673: "Fishbowl and net",
    6674: "Tiny net",
    6675: "An empty box",
    6676: "null",
    6677: "Guam in a box",
    6678: "Guam in a box?",
    6679: "Seaweed in a box",
    6680: "Seaweed in a box?",
    6681: "Ground guam",
    6682: "null",
    6683: "Ground seaweed",
    6684: "null",
    6685: "Saradomin brew(4)",
    6686: "null",
    6687: "Saradomin brew(3)",
    6688: "null",
    6689: "Saradomin brew(2)",
    6690: "null",
    6691: "Saradomin brew(1)",
    6692: "null",
    6693: "Crushed nest",
    6694: "null",
    6695: "Desert lizard",
    6696: "Ice cooler",
    6697: "Pat of butter",
    6698: "null",
    6699: "Burnt potato",
    6700: "null",
    6701: "Baked potato",
    6702: "null",
    6703: "Potato with butter",
    6704: "null",
    6705: "Potato with cheese",
    6706: "null",
    6707: "Camulet",
    6708: "Slayer gloves",
    6709: "Fever spider",
    6710: "Blindweed seed",
    6711: "Blindweed",
    6712: "Bucket of water",
    6713: "Wrench",
    6714: "Holy wrench",
    6715: "Sluglings",
    6716: "Karamthulhu",
    6717: "Karamthulhu",
    6718: "Fever spider body",
    6719: "Unsanitary swill",
    6720: "Slayer gloves",
    6721: "Rusty scimitar",
    6722: "Zombie head",
    6723: "null",
    6724: "Seercull",
    6725: "null",
    6726: "null",
    6727: "null",
    6728: "Dagannoth-king bonemeal",
    6729: "Dagannoth bones",
    6730: "null",
    6731: "Seers ring",
    6732: "null",
    6733: "Archers ring",
    6734: "null",
    6735: "Warrior ring",
    6736: "null",
    6737: "Berserker ring",
    6738: "null",
    6739: "Dragon axe",
    6740: "null",
    6741: "Broken axe",
    6742: "null",
    6743: "Dragon axe head",
    6744: "null",
    6745: "Silverlight",
    6746: "Darklight",
    6747: "Demonic sigil mould",
    6748: "Demonic sigil",
    6749: "Demonic tome",
    6750: "Black desert shirt",
    6751: "null",
    6752: "Black desert robe",
    6753: "null",
    6754: "Enchanted key",
    6755: "Journal",
    6756: "Letter",
    6757: "Letter",
    6758: "Scroll",
    6759: "Chest",
    6760: "Guthix mjolnir",
    6761: "null",
    6762: "Saradomin mjolnir",
    6763: "null",
    6764: "Zamorak mjolnir",
    6765: "null",
    6766: "Cat antipoison",
    6767: "Book",
    6768: "Poisoned cheese",
    6769: "Music scroll",
    6770: "Directions",
    6771: "Pot of weeds",
    6772: "Smouldering pot",
    6773: "Rat pole",
    6774: "Rat pole",
    6775: "Rat pole",
    6776: "Rat pole",
    6777: "Rat pole",
    6778: "Rat pole",
    6779: "Rat pole",
    6780: "Menaphite thug",
    6781: "Bandit",
    6782: "Bandit",
    6783: "null",
    6784: "null",
    6785: "Statuette",
    6786: "Robe of elidinis",
    6787: "Robe of elidinis",
    6788: "Torn robe",
    6789: "Torn robe",
    6790: "Shoes",
    6791: "Sole",
    6792: "Ancestral key",
    6793: "Ballad",
    6794: "Choc-ice",
    6795: "null",
    6796: "Lamp",
    6797: "Watering can",
    6798: "Earth warrior champion scroll",
    6799: "Ghoul champion scroll",
    6800: "Giant champion scroll",
    6801: "Goblin champion scroll",
    6802: "Hobgoblin champion scroll",
    6803: "Imp champion scroll",
    6804: "Jogre champion scroll",
    6805: "Lesser demon champion scroll",
    6806: "Skeleton champion scroll",
    6807: "Zombie champion scroll",
    6808: "Leon's champion scroll",
    6809: "Granite legs",
    6810: "Wyvern bonemeal",
    6811: "Skeletal wyvern",
    6812: "Wyvern bones",
    6813: "null",
    6814: "Fur",
    6815: "null",
    6816: "null",
    6817: "Slender blade",
    6818: "Bow-sword",
    6819: "Large pouch",
    6820: "Relic",
    6821: "Orb",
    6822: "Star bauble",
    6823: "Star bauble",
    6824: "Star bauble",
    6825: "Star bauble",
    6826: "Star bauble",
    6827: "Star bauble",
    6828: "Box bauble",
    6829: "Box bauble",
    6830: "Box bauble",
    6831: "Box bauble",
    6832: "Box bauble",
    6833: "Box bauble",
    6834: "Diamond bauble",
    6835: "Diamond bauble",
    6836: "Diamond bauble",
    6837: "Diamond bauble",
    6838: "Diamond bauble",
    6839: "Diamond bauble",
    6840: "Tree bauble",
    6841: "Tree bauble",
    6842: "Tree bauble",
    6843: "Tree bauble",
    6844: "Tree bauble",
    6845: "Tree bauble",
    6846: "Bell bauble",
    6847: "Bell bauble",
    6848: "Bell bauble",
    6849: "Bell bauble",
    6850: "Bell bauble",
    6851: "Bell bauble",
    6852: "Puppet box",
    6853: "Bauble box",
    6854: "Puppet box",
    6855: "Bauble box",
    6856: "Bobble hat",
    6857: "Bobble scarf",
    6858: "Jester hat",
    6859: "Jester scarf",
    6860: "Tri-jester hat",
    6861: "Tri-jester scarf",
    6862: "Woolly hat",
    6863: "Woolly scarf",
    6864: "Marionette handle",
    6865: "Blue marionette",
    6866: "Green marionette",
    6867: "Red marionette",
    6868: "Blue marionette",
    6869: "Green marionette",
    6870: "Red marionette",
    6871: "Red marionette",
    6872: "Red marionette",
    6873: "Red marionette",
    6874: "Red marionette",
    6875: "Blue marionette",
    6876: "Blue marionette",
    6877: "Blue marionette",
    6878: "Blue marionette",
    6879: "Green marionette",
    6880: "Green marionette",
    6881: "Green marionette",
    6882: "Green marionette",
    6883: "Peach",
    6884: "null",
    6885: "Progress hat",
    6886: "Progress hat",
    6887: "Progress hat",
    6888: "Null",
    6889: "Mage's book",
    6890: "null",
    6891: "Arena book",
    6892: "null",
    6893: "Leather boots",
    6894: "Adamant kiteshield",
    6895: "Adamant med helm",
    6896: "Emerald",
    6897: "Rune longsword",
    6898: "Cylinder",
    6899: "Cube",
    6900: "Icosahedron",
    6901: "Pentamid",
    6902: "Orb",
    6903: "Dragonstone",
    6904: "Animals' bones",
    6905: "Animals' bones",
    6906: "Animals' bones",
    6907: "Animals' bones",
    6908: "Beginner wand",
    6909: "null",
    6910: "Apprentice wand",
    6911: "null",
    6912: "Teacher wand",
    6913: "null",
    6914: "Master wand",
    6915: "null",
    6916: "Infinity top",
    6917: "null",
    6918: "Infinity hat",
    6919: "null",
    6920: "Infinity boots",
    6921: "null",
    6922: "Infinity gloves",
    6923: "null",
    6924: "Infinity bottoms",
    6925: "null",
    6926: "Bones to peaches",
    6927: "null",
    6928: "null",
    6929: "null",
    6930: "null",
    6931: "null",
    6932: "null",
    6933: "null",
    6934: "null",
    6935: "null",
    6936: "null",
    6937: "null",
    6938: "null",
    6939: "null",
    6940: "null",
    6941: "null",
    6942: "null",
    6943: "null",
    6944: "null",
    6945: "Sandy hand",
    6946: "Beer soaked hand",
    6947: "Bert's rota",
    6948: "Sandy's rota",
    6949: "A magic scroll",
    6950: "Magical orb",
    6951: "Magical orb (a)",
    6952: "Truth serum",
    6953: "Bottled water",
    6954: "Redberry juice",
    6955: "Pink dye",
    6956: "Rose-tinted lens",
    6957: "Wizard's head",
    6958: "Sand",
    6959: "Pink cape",
    6960: "null",
    6961: "Baguette",
    6962: "Triangle sandwich",
    6963: "Roll",
    6964: "Coins",
    6965: "Square sandwich",
    6966: "Prison key",
    6967: "Dragon med helm",
    6968: "null",
    6969: "Shark",
    6970: "Pyramid top",
    6971: "Sandstone (1kg)",
    6972: "null",
    6973: "Sandstone (2kg)",
    6974: "null",
    6975: "Sandstone (5kg)",
    6976: "null",
    6977: "Sandstone (10kg)",
    6978: "null",
    6979: "Granite (500g)",
    6980: "null",
    6981: "Granite (2kg)",
    6982: "null",
    6983: "Granite (5kg)",
    6984: "null",
    6985: "Sandstone (20kg)",
    6986: "Sandstone (32kg)",
    6987: "Sandstone body",
    6988: "Sandstone base",
    6989: "Stone head",
    6990: "Stone head",
    6991: "Stone head",
    6992: "Stone head",
    6993: "Z sigil",
    6994: "M sigil",
    6995: "R sigil",
    6996: "K sigil",
    6997: "Stone left arm",
    6998: "Stone right arm",
    6999: "Stone left leg",
    7000: "Stone right leg",
    7001: "Camel mould (p)",
    7002: "Stone head",
    7003: "Camel mask",
    7004: "null",
    7005: "null",
    7006: "null",
    7007: "null",
    7008: "null",
    7009: "null",
    7010: "null",
    7011: "null",
    7012: "null",
    7013: "null",
    7014: "null",
    7015: "null",
    7016: "null",
    7017: "null",
    7018: "null",
    7019: "null",
    7020: "null",
    7021: "null",
    7022: "null",
    7023: "null",
    7024: "null",
    7025: "null",
    7026: "null",
    7027: "null",
    7028: "null",
    7029: "null",
    7030: "null",
    7031: "null",
    7032: "null",
    7033: "null",
    7034: "null",
    7035: "null",
    7036: "null",
    7037: "null",
    7038: "null",
    7039: "null",
    7040: "null",
    7041: "null",
    7042: "null",
    7043: "null",
    7044: "null",
    7045: "null",
    7046: "null",
    7047: "null",
    7048: "null",
    7049: "null",
    7050: "Swarm",
    7051: "Unlit bug lantern",
    7052: "null",
    7053: "Lit bug lantern",
    7054: "Chilli potato",
    7055: "null",
    7056: "Egg potato",
    7057: "null",
    7058: "Mushroom potato",
    7059: "null",
    7060: "Tuna potato",
    7061: "null",
    7062: "Chilli con carne",
    7063: "null",
    7064: "Egg and tomato",
    7065: "null",
    7066: "Mushroom & onion",
    7067: "null",
    7068: "Tuna and corn",
    7069: "null",
    7070: "Minced meat",
    7071: "null",
    7072: "Spicy sauce",
    7073: "null",
    7074: "Chopped garlic",
    7075: "null",
    7076: "Uncooked egg",
    7077: "null",
    7078: "Scrambled egg",
    7079: "null",
    7080: "Sliced mushrooms",
    7081: "null",
    7082: "Fried mushrooms",
    7083: "null",
    7084: "Fried onions",
    7085: "null",
    7086: "Chopped tuna",
    7087: "null",
    7088: "Sweetcorn",
    7089: "null",
    7090: "Burnt egg",
    7091: "null",
    7092: "Burnt onion",
    7093: "null",
    7094: "Burnt mushroom",
    7095: "null",
    7096: "Board game piece",
    7097: "Board game piece",
    7098: "Board game piece",
    7099: "Board game piece",
    7100: "Board game piece",
    7101: "Board game piece",
    7102: "Board game piece",
    7103: "Board game piece",
    7104: "Board game piece",
    7105: "Board game piece",
    7106: "Board game piece",
    7107: "Board game piece",
    7108: "Gunpowder",
    7109: "Fuse",
    7110: "Stripy pirate shirt",
    7111: "null",
    7112: "Pirate bandana",
    7113: "null",
    7114: "Pirate boots",
    7115: "null",
    7116: "Pirate leggings",
    7117: "null",
    7118: "Canister",
    7119: "Cannon ball",
    7120: "Ramrod",
    7121: "Repair plank",
    7122: "Stripy pirate shirt",
    7123: "null",
    7124: "Pirate bandana",
    7125: "null",
    7126: "Pirate leggings",
    7127: "null",
    7128: "Stripy pirate shirt",
    7129: "null",
    7130: "Pirate bandana",
    7131: "null",
    7132: "Pirate leggings",
    7133: "null",
    7134: "Stripy pirate shirt",
    7135: "null",
    7136: "Pirate bandana",
    7137: "null",
    7138: "Pirate leggings",
    7139: "null",
    7140: "Lucky cutlass",
    7141: "Harry's cutlass",
    7142: "Rapier",
    7143: "Plunder",
    7144: "Book o' piracy",
    7145: "Cannon barrel",
    7146: "Broken cannon",
    7147: "Cannon balls",
    7148: "Repair plank",
    7149: "Canister",
    7150: "Tacks",
    7151: "null",
    7152: "null",
    7153: "null",
    7154: "null",
    7155: "Rope",
    7156: "Tinderbox",
    7157: "Braindeath 'rum'",
    7158: "Dragon 2h sword",
    7159: "Insulated boots",
    7160: "Killerwatt",
    7161: "null",
    7162: "Pie recipe book",
    7163: "null",
    7164: "Part mud pie",
    7165: "null",
    7166: "Part mud pie",
    7167: "null",
    7168: "Raw mud pie",
    7169: "null",
    7170: "Mud pie",
    7171: "null",
    7172: "Part garden pie",
    7173: "null",
    7174: "Part garden pie",
    7175: "null",
    7176: "Raw garden pie",
    7177: "null",
    7178: "Garden pie",
    7179: "null",
    7180: "Half a garden pie",
    7181: "null",
    7182: "Part fish pie",
    7183: "null",
    7184: "Part fish pie",
    7185: "null",
    7186: "Raw fish pie",
    7187: "null",
    7188: "Fish pie",
    7189: "null",
    7190: "Half a fish pie",
    7191: "null",
    7192: "Part admiral pie",
    7193: "null",
    7194: "Part admiral pie",
    7195: "null",
    7196: "Raw admiral pie",
    7197: "null",
    7198: "Admiral pie",
    7199: "null",
    7200: "Half an admiral pie",
    7201: "null",
    7202: "Part wild pie",
    7203: "null",
    7204: "Part wild pie",
    7205: "null",
    7206: "Raw wild pie",
    7207: "null",
    7208: "Wild pie",
    7209: "null",
    7210: "Half a wild pie",
    7211: "null",
    7212: "Part summer pie",
    7213: "null",
    7214: "Part summer pie",
    7215: "null",
    7216: "Raw summer pie",
    7217: "null",
    7218: "Summer pie",
    7219: "null",
    7220: "Half a summer pie",
    7221: "null",
    7222: "Burnt rabbit",
    7223: "Roast rabbit",
    7224: "Skewered rabbit",
    7225: "Iron spit",
    7226: "Burnt chompy",
    7227: "null",
    7228: "Cooked chompy",
    7229: "null",
    7230: "Skewered chompy",
    7231: "null",
    7232: "null",
    7233: "null",
    7234: "null",
    7235: "null",
    7236: "Clue scroll (easy)",
    7237: "Casket (easy)",
    7238: "Clue scroll (easy)",
    7239: "Clue scroll (hard)",
    7240: "Casket (hard)",
    7241: "Clue scroll (hard)",
    7242: "Casket (hard)",
    7243: "Clue scroll (hard)",
    7244: "Casket (hard)",
    7245: "Clue scroll (hard)",
    7246: "Casket (hard)",
    7247: "Clue scroll (hard)",
    7248: "Clue scroll (hard)",
    7249: "Clue scroll (hard)",
    7250: "Clue scroll (hard)",
    7251: "Clue scroll (hard)",
    7252: "Clue scroll (hard)",
    7253: "Clue scroll (hard)",
    7254: "Clue scroll (hard)",
    7255: "Clue scroll (hard)",
    7256: "Clue scroll (hard)",
    7257: "Casket (hard)",
    7258: "Clue scroll (hard)",
    7259: "Casket (hard)",
    7260: "Clue scroll (hard)",
    7261: "Casket (hard)",
    7262: "Clue scroll (hard)",
    7263: "Casket (hard)",
    7264: "Clue scroll (hard)",
    7265: "Casket (hard)",
    7266: "Clue scroll (hard)",
    7267: "Casket (hard)",
    7268: "Clue scroll (hard)",
    7269: "Challenge scroll (hard)",
    7270: "Clue scroll (hard)",
    7271: "Challenge scroll (hard)",
    7272: "Clue scroll (hard)",
    7273: "Challenge scroll (hard)",
    7274: "Clue scroll (medium)",
    7275: "Challenge scroll (medium)",
    7276: "Clue scroll (medium)",
    7277: "Challenge scroll (medium)",
    7278: "Clue scroll (medium)",
    7279: "Challenge scroll (medium)",
    7280: "Clue scroll (medium)",
    7281: "Challenge scroll (medium)",
    7282: "Clue scroll (medium)",
    7283: "Challenge scroll (medium)",
    7284: "Clue scroll (medium)",
    7285: "Challenge scroll (medium)",
    7286: "Clue scroll (medium)",
    7287: "Casket (medium)",
    7288: "Clue scroll (medium)",
    7289: "Casket (medium)",
    7290: "Clue scroll (medium)",
    7291: "Casket (medium)",
    7292: "Clue scroll (medium)",
    7293: "Casket (medium)",
    7294: "Clue scroll (medium)",
    7295: "Casket (medium)",
    7296: "Clue scroll (medium)",
    7297: "Key (medium)",
    7298: "Clue scroll (medium)",
    7299: "Key (medium)",
    7300: "Clue scroll (medium)",
    7301: "Clue scroll (medium)",
    7302: "Key (medium)",
    7303: "Clue scroll (medium)",
    7304: "Clue scroll (medium)",
    7305: "Clue scroll (medium)",
    7306: "Casket (medium)",
    7307: "Clue scroll (medium)",
    7308: "Casket (medium)",
    7309: "Clue scroll (medium)",
    7310: "Casket (medium)",
    7311: "Clue scroll (medium)",
    7312: "Casket (medium)",
    7313: "Clue scroll (medium)",
    7314: "Casket (medium)",
    7315: "Clue scroll (medium)",
    7316: "Casket (medium)",
    7317: "Clue scroll (medium)",
    7318: "Casket (medium)",
    7319: "Red boater",
    7320: "null",
    7321: "Orange boater",
    7322: "null",
    7323: "Green boater",
    7324: "null",
    7325: "Blue boater",
    7326: "null",
    7327: "Black boater",
    7328: "null",
    7329: "Red firelighter",
    7330: "Green firelighter",
    7331: "Blue firelighter",
    7332: "Black shield (h1)",
    7333: "null",
    7334: "Adamant shield (h1)",
    7335: "null",
    7336: "Rune shield (h1)",
    7337: "null",
    7338: "Black shield (h2)",
    7339: "null",
    7340: "Adamant shield (h2)",
    7341: "null",
    7342: "Rune shield (h2)",
    7343: "null",
    7344: "Black shield (h3)",
    7345: "null",
    7346: "Adamant shield (h3)",
    7347: "null",
    7348: "Rune shield (h3)",
    7349: "null",
    7350: "Black shield (h4)",
    7351: "null",
    7352: "Adamant shield (h4)",
    7353: "null",
    7354: "Rune shield (h4)",
    7355: "null",
    7356: "Black shield (h5)",
    7357: "null",
    7358: "Adamant shield (h5)",
    7359: "null",
    7360: "Rune shield (h5)",
    7361: "null",
    7362: "Studded body (g)",
    7363: "null",
    7364: "Studded body (t)",
    7365: "null",
    7366: "Studded chaps (g)",
    7367: "null",
    7368: "Studded chaps (t)",
    7369: "null",
    7370: "Green d'hide body (g)",
    7371: "null",
    7372: "Green d'hide body (t)",
    7373: "null",
    7374: "Blue d'hide body (g)",
    7375: "null",
    7376: "Blue d'hide body (t)",
    7377: "null",
    7378: "Green d'hide chaps (g)",
    7379: "null",
    7380: "Green d'hide chaps (t)",
    7381: "null",
    7382: "Blue d'hide chaps (g)",
    7383: "null",
    7384: "Blue d'hide chaps (t)",
    7385: "null",
    7386: "Blue skirt (g)",
    7387: "null",
    7388: "Blue skirt (t)",
    7389: "null",
    7390: "Blue wizard robe (g)",
    7391: "null",
    7392: "Blue wizard robe (t)",
    7393: "null",
    7394: "Blue wizard hat (g)",
    7395: "null",
    7396: "Blue wizard hat (t)",
    7397: "null",
    7398: "Enchanted robe",
    7399: "Enchanted top",
    7400: "Enchanted hat",
    7401: "null",
    7402: "null",
    7403: "null",
    7404: "Red logs",
    7405: "Green logs",
    7406: "Blue logs",
    7407: "null",
    7408: "Draynor skull",
    7409: "Magic secateurs",
    7410: "Queen's secateurs",
    7411: "Symptoms list",
    7412: "null",
    7413: "Bird nest",
    7414: "Paddle",
    7415: "null",
    7416: "Mole claw",
    7417: "null",
    7418: "Mole skin",
    7419: "null",
    7420: "Mutated zygomite",
    7421: "Fungicide spray 10",
    7422: "Fungicide spray 9",
    7423: "Fungicide spray 8",
    7424: "Fungicide spray 7",
    7425: "Fungicide spray 6",
    7426: "Fungicide spray 5",
    7427: "Fungicide spray 4",
    7428: "Fungicide spray 3",
    7429: "Fungicide spray 2",
    7430: "Fungicide spray 1",
    7431: "Fungicide spray 0",
    7432: "Fungicide",
    7433: "Wooden spoon",
    7434: "null",
    7435: "Egg whisk",
    7436: "null",
    7437: "Spork",
    7438: "null",
    7439: "Spatula",
    7440: "null",
    7441: "Frying pan",
    7442: "null",
    7443: "Skewer",
    7444: "null",
    7445: "Rolling pin",
    7446: "null",
    7447: "Kitchen knife",
    7448: "null",
    7449: "Meat tenderiser",
    7450: "null",
    7451: "Cleaver",
    7452: "null",
    7453: "Hardleather gloves",
    7454: "Bronze gloves",
    7455: "Iron gloves",
    7456: "Steel gloves",
    7457: "Black gloves",
    7458: "Mithril gloves",
    7459: "Adamant gloves",
    7460: "Rune gloves",
    7461: "Dragon gloves",
    7462: "Barrows gloves",
    7463: "Cornflour",
    7464: "Book on chickens",
    7465: "Vanilla pod",
    7466: "Cornflour",
    7467: "null",
    7468: "Pot of cornflour",
    7469: "null",
    7470: "Cornflour mixture",
    7471: "Milky mixture",
    7472: "Cinnamon",
    7473: "Brulee",
    7474: "Brulee",
    7475: "Brulee",
    7476: "Brulee supreme",
    7477: "Evil chicken's egg",
    7478: "Dragon token",
    7479: "Spicy stew",
    7480: "Red spice (4)",
    7481: "Red spice (3)",
    7482: "Red spice (2)",
    7483: "Red spice (1)",
    7484: "Orange spice (4)",
    7485: "Orange spice (3)",
    7486: "Orange spice (2)",
    7487: "Orange spice (1)",
    7488: "Brown spice (4)",
    7489: "Brown spice (3)",
    7490: "Brown spice (2)",
    7491: "Brown spice (1)",
    7492: "Yellow spice (4)",
    7493: "Yellow spice (3)",
    7494: "Yellow spice (2)",
    7495: "Yellow spice (1)",
    7496: "Empty spice shaker",
    7497: "Dirty blast",
    7498: "Antique lamp",
    7499: "null",
    7500: "null",
    7501: "null",
    7502: "null",
    7503: "null",
    7504: "null",
    7505: "null",
    7506: "null",
    7507: "null",
    7508: "Asgoldian ale",
    7509: "Dwarven rock cake",
    7510: "Dwarven rock cake",
    7511: "Slop of compromise",
    7512: "Soggy bread",
    7513: "Spicy maggots",
    7514: "Dyed orange",
    7515: "Breadcrumbs",
    7516: "Kelp",
    7517: "Ground kelp",
    7518: "Crab meat",
    7519: "Crab meat",
    7520: "Burnt crab meat",
    7521: "Cooked crab meat",
    7522: "null",
    7523: "Cooked crab meat",
    7524: "Cooked crab meat",
    7525: "Cooked crab meat",
    7526: "Cooked crab meat",
    7527: "Ground crab meat",
    7528: "Ground cod",
    7529: "Raw fishcake",
    7530: "Cooked fishcake",
    7531: "Burnt fishcake",
    7532: "Mudskipper hide",
    7533: "Rock",
    7534: "Fishbowl helmet",
    7535: "Diving apparatus",
    7536: "Fresh crab claw",
    7537: "Crab claw",
    7538: "Fresh crab shell",
    7539: "Crab helmet",
    7540: "Broken crab claw",
    7541: "Broken crab shell",
    7542: "Cake of guidance",
    7543: "Raw guide cake",
    7544: "Enchanted egg",
    7545: "Enchanted milk",
    7546: "Enchanted flour",
    7547: "Druid pouch",
    7548: "Potato seed",
    7549: "null",
    7550: "Onion seed",
    7551: "null",
    7552: "Mithril arrow",
    7553: "null",
    7554: "Fire rune",
    7555: "null",
    7556: "Water rune",
    7557: "null",
    7558: "Air rune",
    7559: "null",
    7560: "Chaos rune",
    7561: "null",
    7562: "Tomato seed",
    7563: "null",
    7564: "Balloon toad",
    7565: "Balloon toad",
    7566: "Raw jubbly",
    7567: "null",
    7568: "Cooked jubbly",
    7569: "null",
    7570: "Burnt jubbly",
    7571: "null",
    7572: "Red banana",
    7573: "Tchiki monkey nuts",
    7574: "Sliced red banana",
    7575: "Tchiki nut paste",
    7576: "Snake corpse",
    7577: "Raw stuffed snake",
    7578: "Odd stuffed snake",
    7579: "Stuffed snake",
    7580: "Snake over-cooked",
    7581: "Overgrown hellcat",
    7582: "Hell cat",
    7583: "Hell-kitten",
    7584: "Lazy hell cat",
    7585: "Wily hellcat",
    7586: "Dummy",
    7587: "Coffin",
    7588: "Coffin",
    7589: "Coffin",
    7590: "Coffin",
    7591: "Coffin",
    7592: "Zombie shirt",
    7593: "Zombie trousers",
    7594: "Zombie mask",
    7595: "Zombie gloves",
    7596: "Zombie boots",
    7597: "Item",
    7598: "Item",
    7599: "Item",
    7600: "Item",
    7601: "Item",
    7602: "Item",
    7603: "Item",
    7604: "Item",
    7605: "Item",
    7606: "Item",
    7607: "Item",
    7608: "Item",
    7609: "Item",
    7610: "Item",
    7611: "Item",
    7612: "Item",
    7613: "Item",
    7614: "Item",
    7615: "Item",
    7616: "Item",
    7617: "Item",
    7618: "Item",
    7619: "null",
    7620: "",
    7621: "null",
    7622: "Bucket of rubble",
    7623: "null",
    7624: "Bucket of rubble",
    7625: "null",
    7626: "Bucket of rubble",
    7627: "null",
    7628: "Plaster fragment",
    7629: "Dusty scroll",
    7630: "Crate",
    7631: "null",
    7632: "Temple library key",
    7633: "The sleeping seven",
    7634: "Histories of the hallowland",
    7635: "Modern day morytania",
    7636: "Rod dust",
    7637: "Silvthrill rod",
    7638: "Silvthrill rod",
    7639: "Rod of ivandis (10)",
    7640: "Rod of ivandis (9)",
    7641: "Rod of ivandis (8)",
    7642: "Rod of ivandis (7)",
    7643: "Rod of ivandis (6)",
    7644: "Rod of ivandis (5)",
    7645: "Rod of ivandis (4)",
    7646: "Rod of ivandis (3)",
    7647: "Rod of ivandis (2)",
    7648: "Rod of ivandis (1)",
    7649: "Rod mould",
    7650: "Silver dust",
    7651: "null",
    7652: "Guthix balance (unf)",
    7653: "null",
    7654: "Guthix balance (unf)",
    7655: "null",
    7656: "Guthix balance (unf)",
    7657: "null",
    7658: "Guthix balance (unf)",
    7659: "null",
    7660: "Guthix balance(4)",
    7661: "null",
    7662: "Guthix balance(3)",
    7663: "null",
    7664: "Guthix balance(2)",
    7665: "null",
    7666: "Guthix balance(1)",
    7667: "null",
    7668: "Gadderhammer",
    7669: "null",
    7670: "null",
    7671: "Boxing gloves",
    7672: "null",
    7673: "Boxing gloves",
    7674: "null",
    7675: "Wooden sword",
    7676: "Wooden shield",
    7677: "Treasure stone",
    7678: "Prize key",
    7679: "Pugel",
    7680: "null",
    7681: "Game book",
    7682: "Hoop",
    7683: "null",
    7684: "Dart",
    7685: "null",
    7686: "Bow and arrow",
    7687: "null",
    7688: "Kettle",
    7689: "null",
    7690: "Full kettle",
    7691: "Hot kettle",
    7692: "Pot of tea (4)",
    7693: "null",
    7694: "Pot of tea (3)",
    7695: "null",
    7696: "Pot of tea (2)",
    7697: "null",
    7698: "Pot of tea (1)",
    7699: "null",
    7700: "Teapot with leaves",
    7701: "null",
    7702: "Teapot",
    7703: "null",
    7704: "Pot of tea (4)",
    7705: "null",
    7706: "Pot of tea (3)",
    7707: "null",
    7708: "Pot of tea (2)",
    7709: "null",
    7710: "Pot of tea (1)",
    7711: "null",
    7712: "Teapot with leaves",
    7713: "null",
    7714: "Teapot",
    7715: "null",
    7716: "Pot of tea (4)",
    7717: "null",
    7718: "Pot of tea (3)",
    7719: "null",
    7720: "Pot of tea (2)",
    7721: "null",
    7722: "Pot of tea (1)",
    7723: "null",
    7724: "Teapot with leaves",
    7725: "null",
    7726: "Teapot",
    7727: "null",
    7728: "Empty cup",
    7729: "null",
    7730: "Cup of tea",
    7731: "Cup of tea",
    7732: "Porcelain cup",
    7733: "Cup of tea",
    7734: "Cup of tea",
    7735: "Porcelain cup",
    7736: "Cup of tea",
    7737: "Cup of tea",
    7738: "Tea leaves",
    7739: "null",
    7740: "Beer",
    7741: "null",
    7742: "Beer glass",
    7743: "null",
    7744: "Asgarnian ale",
    7745: "null",
    7746: "Greenman's ale",
    7747: "null",
    7748: "Dragon bitter",
    7749: "null",
    7750: "Moonlight mead",
    7751: "null",
    7752: "Cider",
    7753: "null",
    7754: "Chef's delight",
    7755: "null",
    7756: "Paintbrush",
    7757: "null",
    7758: "null",
    7759: "Toy soldier",
    7760: "null",
    7761: "Toy soldier (wound)",
    7762: "null",
    7763: "Toy doll",
    7764: "null",
    7765: "Toy doll (wound)",
    7766: "null",
    7767: "Toy mouse",
    7768: "null",
    7769: "Toy mouse (wound)",
    7770: "null",
    7771: "Toy cat",
    7772: "null",
    7773: "Branch",
    7774: "Reward token",
    7775: "Reward token",
    7776: "Reward token",
    7777: "Long vine",
    7778: "Short vine",
    7779: "Fishing tome",
    7780: "Fishing tome",
    7781: "Fishing tome",
    7782: "Agility tome",
    7783: "Agility tome",
    7784: "Agility tome",
    7785: "Thieving tome",
    7786: "Thieving tome",
    7787: "Thieving tome",
    7788: "Slayer tome",
    7789: "Slayer tome",
    7790: "Slayer tome",
    7791: "Mining tome",
    7792: "Mining tome",
    7793: "Mining tome",
    7794: "Firemaking tome",
    7795: "Firemaking tome",
    7796: "Firemaking tome",
    7797: "Woodcutting tome",
    7798: "Woodcutting tome",
    7799: "Woodcutting tome",
    7800: "Snail shell",
    7801: "Snake hide",
    7802: "null",
    7803: "Yin yang amulet",
    7804: "Ancient mjolnir",
    7805: "null",
    7806: "Anger sword",
    7807: "Anger battleaxe",
    7808: "Anger mace",
    7809: "Anger spear",
    7810: "Jug of vinegar",
    7811: "Pot of vinegar",
    7812: "Goblin skull",
    7813: "Bone in vinegar",
    7814: "Goblin skull",
    7815: "Bear ribs",
    7816: "Bone in vinegar",
    7817: "Bear ribs",
    7818: "Ram skull",
    7819: "Bone in vinegar",
    7820: "Ram skull",
    7821: "Unicorn bone",
    7822: "Bone in vinegar",
    7823: "Unicorn bone",
    7824: "Giant rat bone",
    7825: "Bone in vinegar",
    7826: "Giant rat bone",
    7827: "Giant bat wing",
    7828: "Bone in vinegar",
    7829: "Giant bat wing",
    7830: "Wolf bone",
    7831: "Bone in vinegar",
    7832: "Wolf bone",
    7833: "Bat wing",
    7834: "Bone in vinegar",
    7835: "Bat wing",
    7836: "Rat bone",
    7837: "Bone in vinegar",
    7838: "Rat bone",
    7839: "Baby dragon bone",
    7840: "Bone in vinegar",
    7841: "Baby dragon bone",
    7842: "Ogre ribs",
    7843: "Bone in vinegar",
    7844: "Ogre ribs",
    7845: "Jogre bone",
    7846: "Bone in vinegar",
    7847: "Jogre bone",
    7848: "Zogre bone",
    7849: "Bone in vinegar",
    7850: "Zogre bone",
    7851: "Mogre bone",
    7852: "Bone in vinegar",
    7853: "Mogre bone",
    7854: "Monkey paw",
    7855: "Bone in vinegar",
    7856: "Monkey paw",
    7857: "Dagannoth ribs",
    7858: "Bone in vinegar",
    7859: "Dagannoth ribs",
    7860: "Snake spine",
    7861: "Bone in vinegar",
    7862: "Snake spine",
    7863: "Zombie bone",
    7864: "Bone in vinegar",
    7865: "Zombie bone",
    7866: "Werewolf bone",
    7867: "Bone in vinegar",
    7868: "Werewolf bone",
    7869: "Moss giant bone",
    7870: "Bone in vinegar",
    7871: "Moss giant bone",
    7872: "Fire giant bone",
    7873: "Bone in vinegar",
    7874: "Fire giant bone",
    7875: "Ice giant ribs",
    7876: "Bone in vinegar",
    7877: "Ice giant ribs",
    7878: "Terrorbird wing",
    7879: "Bone in vinegar",
    7880: "Terrorbird wing",
    7881: "Ghoul bone",
    7882: "Bone in vinegar",
    7883: "Ghoul bone",
    7884: "Troll bone",
    7885: "Bone in vinegar",
    7886: "Troll bone",
    7887: "Seagull wing",
    7888: "Bone in vinegar",
    7889: "Seagull wing",
    7890: "Undead cow ribs",
    7891: "Bone in vinegar",
    7892: "Undead cow ribs",
    7893: "Experiment bone",
    7894: "Bone in vinegar",
    7895: "Experiment bone",
    7896: "Rabbit bone",
    7897: "Bone in vinegar",
    7898: "Rabbit bone",
    7899: "Basilisk bone",
    7900: "Bone in vinegar",
    7901: "Basilisk bone",
    7902: "Desert lizard bone",
    7903: "Bone in vinegar",
    7904: "Desert lizard bone",
    7905: "Cave goblin skull",
    7906: "Bone in vinegar",
    7907: "Cave goblin skull",
    7908: "Big frog leg",
    7909: "Bone in vinegar",
    7910: "Big frog leg",
    7911: "Vulture wing",
    7912: "Bone in vinegar",
    7913: "Vulture wing",
    7914: "Jackal bone",
    7915: "Bone in vinegar",
    7916: "Jackal bone",
    7917: "Ram skull helm",
    7918: "Bonesack",
    7919: "Bottle of wine",
    7920: "null",
    7921: "Empty wine bottle",
    7922: "Al kharid flyer",
    7923: "null",
    7924: "null",
    7925: "null",
    7926: "null",
    7927: "Easter ring",
    7928: "Easter egg",
    7929: "Easter egg",
    7930: "Easter egg",
    7931: "Easter egg",
    7932: "Easter egg",
    7933: "Easter egg",
    7934: "Field ration",
    7935: "null",
    7936: "Pure essence",
    7937: "null",
    7938: "Dark essence fragments",
    7939: "Tortoise shell",
    7940: "null",
    7941: "Iron sheet",
    7942: "Fresh monkfish",
    7943: "Fresh monkfish",
    7944: "Raw monkfish",
    7945: "null",
    7946: "Monkfish",
    7947: "null",
    7948: "Burnt monkfish",
    7949: "null",
    7950: "Bone seeds",
    7951: "Herman's book",
    7952: "Axe handle",
    7953: "null",
    7954: "Burnt shrimp",
    7955: "null",
    7956: "Casket",
    7957: "White apron",
    7958: "Mining prop",
    7959: "Heavy box",
    7960: "Empty box",
    7961: "Burnt diary",
    7962: "Burnt diary",
    7963: "Burnt diary",
    7964: "Burnt diary",
    7965: "Burnt diary",
    7966: "Letter",
    7967: "Engine",
    7968: "Scroll",
    7969: "Pulley beam",
    7970: "Long pulley beam",
    7971: "Longer pulley beam",
    7972: "Lift manual",
    7973: "Beam",
    7974: "Servant bell",
    7975: "Crawling hand",
    7976: "Cockatrice head",
    7977: "Basilisk head",
    7978: "Kurask head",
    7979: "Abyssal head",
    7980: "Kbd heads",
    7981: "Kq head",
    7982: "Stuffed crawling hand",
    7983: "Stuffed cockatrice head",
    7984: "Stuffed basilisk head",
    7985: "Stuffed kurask head",
    7986: "Stuffed abyssal head",
    7987: "Stuffed kbd heads",
    7988: "Stuffed kq head",
    7989: "Big bass",
    7990: "Stuffed big bass",
    7991: "Big swordfish",
    7992: "Stuffed big swordfish",
    7993: "Big shark",
    7994: "Stuffed big shark",
    7995: "Arthur portrait",
    7996: "Elena portrait",
    7997: "Keldagrim portrait",
    7998: "Misc. portrait",
    7999: "Desert painting",
    8000: "Isafdar painting",
    8001: "Karamja painting",
    8002: "Lumbridge painting",
    8003: "Morytania painting",
    8004: "Small map",
    8005: "Medium map",
    8006: "Large map",
    8007: "Varrock teleport",
    8008: "Lumbridge teleport",
    8009: "Falador teleport",
    8010: "Camelot teleport",
    8011: "Ardougne teleport",
    8012: "Watchtower teleport",
    8013: "Teleport to house",
    8014: "Bones to bananas",
    8015: "Bones to peaches",
    8016: "Enchant sapphire or opal",
    8017: "Enchant emerald or jade",
    8018: "Enchant ruby or topaz",
    8019: "Enchant diamond",
    8020: "Enchant dragonstone",
    8021: "Enchant onyx",
    8022: "Telekinetic grab",
    8023: "Boxing ring",
    8024: "Fencing ring",
    8025: "Combat ring",
    8026: "Ranging pedestals",
    8027: "Balance beam",
    8028: "Glove rack",
    8029: "Weapons rack",
    8030: "Extra weapons rack",
    8031: "Wooden bed",
    8032: "Oak bed",
    8033: "Large oak bed",
    8034: "Teak bed",
    8035: "Large teak bed",
    8036: "4-poster",
    8037: "Gilded 4-poster",
    8038: "Shoe box",
    8039: "Oak drawers",
    8040: "Oak wardrobe",
    8041: "Teak drawers",
    8042: "Teak wardrobe",
    8043: "Mahogany wardrobe",
    8044: "Gilded wardrobe",
    8045: "Shaving stand",
    8046: "Oak shaving stand",
    8047: "Oak dresser",
    8048: "Teak dresser",
    8049: "Fancy teak dresser",
    8050: "Mahogany dresser",
    8051: "Gilded dresser",
    8052: "Oak clock",
    8053: "Teak clock",
    8054: "Gilded clock",
    8055: "Saradomin symbol",
    8056: "Zamorak symbol",
    8057: "Guthix symbol",
    8058: "Saradomin icon",
    8059: "Zamorak icon",
    8060: "Guthix icon",
    8061: "Bob icon",
    8062: "Oak altar",
    8063: "Teak altar",
    8064: "Cloth-covered altar",
    8065: "Mahogany altar",
    8066: "Limestone altar",
    8067: "Marble altar",
    8068: "Gilded altar",
    8069: "Wooden torches",
    8070: "Steel torches",
    8071: "Steel candlesticks",
    8072: "Gold candlesticks",
    8073: "Incense burners",
    8074: "Mahogany burners",
    8075: "Marble burners",
    8076: "Shuttered window",
    8077: "Decorative window",
    8078: "Stained glass",
    8079: "Windchimes",
    8080: "Bells",
    8081: "Organ",
    8082: "Small statues",
    8083: "Medium statues",
    8084: "Large statues",
    8085: "Suit of armour",
    8086: "Small portrait",
    8087: "Minor head",
    8088: "Medium head",
    8089: "Major head",
    8090: "Mounted sword",
    8091: "Small landscape",
    8092: "null",
    8093: "Large portrait",
    8094: "Large landscape",
    8095: "Rune display case",
    8096: "Low-level plants",
    8097: "Mid-level plants",
    8098: "High-level plants",
    8099: "Rope bell-pull",
    8100: "Bell-pull",
    8101: "Posh bell-pull",
    8102: "Oak decoration",
    8103: "Teak decoration",
    8104: "Gilded decoration",
    8105: "Round shield",
    8106: "Square shield",
    8107: "Kite shield",
    8108: "Wooden bench",
    8109: "Oak bench",
    8110: "Carved oak bench",
    8111: "Teak dining bench",
    8112: "Carved teak bench",
    8113: "Mahogany bench",
    8114: "Gilded bench",
    8115: "Wood dining table",
    8116: "Oak dining table",
    8117: "Carved oak table",
    8118: "Teak table",
    8119: "Carved teak table",
    8120: "Mahogany table",
    8121: "Opulent table",
    8122: "Oak door",
    8123: "Steel-plated door",
    8124: "Marble door",
    8125: "Decorative blood",
    8126: "Decorative pipe",
    8127: "Hanging skeleton",
    8128: "Candles",
    8129: "Torches",
    8130: "Skull torches",
    8131: "Skeleton guard",
    8132: "Guard dog",
    8133: "Hobgoblin guard",
    8134: "Baby red dragon",
    8135: "Huge spider",
    8136: "Troll guard",
    8137: "Hellhound",
    8138: "Demon",
    8139: "Kalphite soldier",
    8140: "Tok-xil",
    8141: "Dagannoth",
    8142: "Steel dragon",
    8143: "Spike trap",
    8144: "Man trap",
    8145: "Tangle vine",
    8146: "Marble trap",
    8147: "Teleport trap",
    8148: "Wooden crate",
    8149: "Oak chest",
    8150: "Teak chest",
    8151: "Mahogany chest",
    8152: "Magic chest",
    8153: "Clay attack stone",
    8154: "Attack stone",
    8155: "Marble att. stone",
    8156: "Magical balance 1",
    8157: "Magical balance 2",
    8158: "Magical balance 3",
    8159: "Jester",
    8160: "Treasure hunt",
    8161: "Hangman game",
    8162: "Hoop and stick",
    8163: "Dartboard",
    8164: "Archery target",
    8165: "Oak prize chest",
    8166: "Teak prize chest",
    8167: "Mahogany chest",
    8168: "Exit portal",
    8169: "Decorative rock",
    8170: "Pond",
    8171: "Imp statue",
    8172: "Dungeon entrance",
    8173: "Tree",
    8174: "Nice tree",
    8175: "Oak tree",
    8176: "Willow tree",
    8177: "Maple tree",
    8178: "Yew tree",
    8179: "Magic tree",
    8180: "Plant",
    8181: "Small fern",
    8182: "Fern",
    8183: "Dock leaf",
    8184: "Thistle",
    8185: "Reeds",
    8186: "Fern",
    8187: "Bush",
    8188: "Tall plant",
    8189: "Short plant",
    8190: "Large-leaf plant",
    8191: "Huge plant",
    8192: "Gazebo",
    8193: "Small fountain",
    8194: "Large fountain",
    8195: "Posh fountain",
    8196: "Boundary stones",
    8197: "Wooden fence",
    8198: "Stone wall",
    8199: "Iron railings",
    8200: "Picket fence",
    8201: "Garden fence",
    8202: "Marble wall",
    8203: "Thorny hedge",
    8204: "Nice hedge",
    8205: "Small box hedge",
    8206: "Topiary hedge",
    8207: "Fancy hedge",
    8208: "Tall fancy hedge",
    8209: "Tall box hedge",
    8210: "Rosemary",
    8211: "Daffodils",
    8212: "Bluebells",
    8213: "Sunflower",
    8214: "Marigolds",
    8215: "Roses",
    8216: "Firepit",
    8217: "Firepit with hook",
    8218: "Firepit with pot",
    8219: "Small oven",
    8220: "Large oven",
    8221: "Steel range",
    8222: "Fancy range",
    8223: "Wooden shelves 1",
    8224: "Wooden shelves 2",
    8225: "Wooden shelves 3",
    8226: "Oak shelves 1",
    8227: "Oak shelves 2",
    8228: "Teak shelves 1",
    8229: "Teak shelves 2",
    8230: "Pump and drain",
    8231: "Pump and tub",
    8232: "Sink",
    8233: "Wooden larder",
    8234: "Oak larder",
    8235: "Teak larder",
    8236: "Cat blanket",
    8237: "Cat basket",
    8238: "Cushioned basket",
    8239: "Beer barrel",
    8240: "Cider barrel",
    8241: "Asgarnian ale",
    8242: "Greenman's ale",
    8243: "Dragon bitter",
    8244: "Chef's delight",
    8245: "Null",
    8246: "Wood kitchen table",
    8247: "Oak kitchen table",
    8248: "Teak kitchen table",
    8249: "Oak staircase",
    8250: "Oak staircase",
    8251: "Oak staircase",
    8252: "Teak staircase",
    8253: "Teak staircase",
    8254: "Teak staircase",
    8255: "Marble staircase",
    8256: "Marble staircase",
    8257: "Marble staircase",
    8258: "Spiral staircase",
    8259: "Marble spiral",
    8260: "Crawling hand",
    8261: "Cockatrice head",
    8262: "Basilisk head",
    8263: "Kurask head",
    8264: "Abyssal head",
    8265: "Kbd heads",
    8266: "Kq head",
    8267: "Mounted bass",
    8268: "Mounted swordfish",
    8269: "Mounted shark",
    8270: "Mithril armour",
    8271: "Adamantite armour",
    8272: "Runite armour",
    8273: "Cw armour 1",
    8274: "Cw armour 2",
    8275: "Cw armour 3",
    8276: "Rune case 1",
    8277: "Rune case 2",
    8278: "Rune case 3",
    8279: "Silverlight",
    8280: "Excalibur",
    8281: "Darklight",
    8282: "Anti-dragon shield",
    8283: "Amulet of glory",
    8284: "Cape of legends",
    8285: "King arthur",
    8286: "Elena",
    8287: "Giant dwarf",
    8288: "Miscellanians",
    8289: "Lumbridge",
    8290: "The desert",
    8291: "Morytania",
    8292: "Karamja",
    8293: "Isafdar",
    8294: "Small map",
    8295: "Medium map",
    8296: "Large map",
    8297: "Oak cage",
    8298: "Oak and steel cage",
    8299: "Steel cage",
    8300: "Spiked cage",
    8301: "Bone cage",
    8302: "Spikes",
    8303: "Tentacle pool",
    8304: "Flame pit",
    8305: "Rocnar",
    8306: "Oak ladder",
    8307: "Teak ladder",
    8308: "Mahogany ladder",
    8309: "Crude wooden chair",
    8310: "Wooden chair",
    8311: "Rocking chair",
    8312: "Oak chair",
    8313: "Oak armchair",
    8314: "Teak armchair",
    8315: "Mahogany armchair",
    8316: "Brown rug",
    8317: "Rug",
    8318: "Opulent rug",
    8319: "Wooden bookcase",
    8320: "Oak bookcase",
    8321: "Mahogany bookcase",
    8322: "Torn curtains",
    8323: "Curtains",
    8324: "Opulent curtains",
    8325: "Clay fireplace",
    8326: "Stone fireplace",
    8327: "Marble fireplace",
    8328: "Teak portal",
    8329: "Mahogany portal",
    8330: "Marble portal",
    8331: "Teleport focus",
    8332: "Greater focus",
    8333: "Scrying pool",
    8334: "Oak lectern",
    8335: "Eagle lectern",
    8336: "Demon lectern",
    8337: "Teak eagle lectern",
    8338: "Teak demon lectern",
    8339: "Mahogany eagle",
    8340: "Mahogany demon",
    8341: "Globe",
    8342: "Ornamental globe",
    8343: "Lunar globe",
    8344: "Celestial globe",
    8345: "Armillary sphere",
    8346: "Small orrery",
    8347: "Large orrery",
    8348: "Oak telescope",
    8349: "Teak telescope",
    8350: "Mahogany telescope",
    8351: "Crystal ball",
    8352: "Elemental sphere",
    8353: "Crystal of power",
    8354: "Alchemical chart",
    8355: "Astronomical chart",
    8356: "Infernal chart",
    8357: "Oak throne",
    8358: "Teak throne",
    8359: "Mahogany throne",
    8360: "Gilded throne",
    8361: "Skeleton throne",
    8362: "Crystal throne",
    8363: "Demonic throne",
    8364: "Oak lever",
    8365: "Teak lever",
    8366: "Mahogany lever",
    8367: "Trapdoor",
    8368: "Trapdoor",
    8369: "Trapdoor",
    8370: "Floor decoration",
    8371: "Steel cage",
    8372: "Trapdoor",
    8373: "Lesser magic cage",
    8374: "Greater magic cage",
    8375: "Wooden workbench",
    8376: "Oak workbench",
    8377: "Steel framed bench",
    8378: "Bench with vice",
    8379: "Bench with lathe",
    8380: "Crafting table 1",
    8381: "Crafting table 2",
    8382: "Crafting table 3",
    8383: "Crafting table 4",
    8384: "Tool store 1",
    8385: "Tool store 2",
    8386: "Tool store 3",
    8387: "Tool store 4",
    8388: "Tool store 5",
    8389: "Repair bench",
    8390: "Whetstone",
    8391: "Armour stand",
    8392: "Pluming stand",
    8393: "Shield easel",
    8394: "Banner easel",
    8395: "Parlour",
    8396: "Kitchen",
    8397: "Dining room",
    8398: "Bedroom",
    8399: "Games room",
    8400: "Combat room",
    8401: "Hall",
    8402: "Hall",
    8403: "Hall",
    8404: "Hall",
    8405: "Chapel",
    8406: "Workshop",
    8407: "Study",
    8408: "Portal chamber",
    8409: "Throne room",
    8410: "Oubliette",
    8411: "Dungeon corridor",
    8412: "Dungeon cross",
    8413: "Dungeon stairs",
    8414: "Treasure room",
    8415: "Garden",
    8416: "Formal garden",
    8417: "Bagged dead tree",
    8418: "null",
    8419: "Bagged nice tree",
    8420: "null",
    8421: "Bagged oak tree",
    8422: "null",
    8423: "Bagged willow tree",
    8424: "null",
    8425: "Bagged maple tree",
    8426: "null",
    8427: "Bagged yew tree",
    8428: "null",
    8429: "Bagged magic tree",
    8430: "null",
    8431: "Bagged plant 1",
    8432: "null",
    8433: "Bagged plant 2",
    8434: "null",
    8435: "Bagged plant 3",
    8436: "null",
    8437: "Thorny hedge",
    8438: "null",
    8439: "Nice hedge",
    8440: "null",
    8441: "Small box hedge",
    8442: "null",
    8443: "Topiary hedge",
    8444: "null",
    8445: "Fancy hedge",
    8446: "null",
    8447: "Tall fancy hedge",
    8448: "null",
    8449: "Tall box hedge",
    8450: "null",
    8451: "Bagged flower",
    8452: "null",
    8453: "Bagged daffodils",
    8454: "null",
    8455: "Bagged bluebells",
    8456: "null",
    8457: "Bagged sunflower",
    8458: "null",
    8459: "Bagged marigolds",
    8460: "null",
    8461: "Bagged roses",
    8462: "null",
    8463: "Construction guide",
    8464: "Rune heraldic helm",
    8465: "null",
    8466: "Rune heraldic helm",
    8467: "null",
    8468: "Rune heraldic helm",
    8469: "null",
    8470: "Rune heraldic helm",
    8471: "null",
    8472: "Rune heraldic helm",
    8473: "null",
    8474: "Rune heraldic helm",
    8475: "null",
    8476: "Rune heraldic helm",
    8477: "null",
    8478: "Rune heraldic helm",
    8479: "null",
    8480: "Rune heraldic helm",
    8481: "null",
    8482: "Rune heraldic helm",
    8483: "null",
    8484: "Rune heraldic helm",
    8485: "null",
    8486: "Rune heraldic helm",
    8487: "null",
    8488: "Rune heraldic helm",
    8489: "null",
    8490: "Rune heraldic helm",
    8491: "null",
    8492: "Rune heraldic helm",
    8493: "null",
    8494: "Rune heraldic helm",
    8495: "null",
    8496: "Crude chair",
    8497: "null",
    8498: "Wooden chair",
    8499: "null",
    8500: "Rocking chair",
    8501: "null",
    8502: "Oak chair",
    8503: "null",
    8504: "Oak armchair",
    8505: "null",
    8506: "Teak armchair",
    8507: "null",
    8508: "Mahogany armchair",
    8509: "null",
    8510: "Bookcase",
    8511: "null",
    8512: "Oak bookcase",
    8513: "null",
    8514: "Mahogany bookcase",
    8515: "null",
    8516: "Beer barrel",
    8517: "null",
    8518: "Cider barrel",
    8519: "null",
    8520: "Asgarnian ale",
    8521: "null",
    8522: "Greenman's ale",
    8523: "null",
    8524: "Dragon bitter",
    8525: "null",
    8526: "Chef's delight",
    8527: "null",
    8528: "Kitchen table",
    8529: "null",
    8530: "Oak kitchen table",
    8531: "null",
    8532: "Teak kitchen table",
    8533: "null",
    8534: "Oak lectern",
    8535: "null",
    8536: "Eagle lectern",
    8537: "null",
    8538: "Demon lectern",
    8539: "null",
    8540: "Teak eagle lectern",
    8541: "null",
    8542: "Teak demon lectern",
    8543: "null",
    8544: "Mahogany eagle",
    8545: "null",
    8546: "Mahogany demon",
    8547: "null",
    8548: "Wood dining table",
    8549: "null",
    8550: "Oak dining table",
    8551: "null",
    8552: "Carved oak table",
    8553: "null",
    8554: "Teak table",
    8555: "null",
    8556: "Carved teak table",
    8557: "null",
    8558: "Mahogany table",
    8559: "null",
    8560: "Opulent table",
    8561: "null",
    8562: "Wooden bench",
    8563: "null",
    8564: "Oak bench",
    8565: "null",
    8566: "Carved oak bench",
    8567: "null",
    8568: "Teak dining bench",
    8569: "null",
    8570: "Carved teak bench",
    8571: "null",
    8572: "Mahogany bench",
    8573: "null",
    8574: "Gilded bench",
    8575: "null",
    8576: "Wooden bed",
    8577: "null",
    8578: "Oak bed",
    8579: "null",
    8580: "Large oak bed",
    8581: "null",
    8582: "Teak bed",
    8583: "null",
    8584: "Large teak bed",
    8585: "null",
    8586: "Four-poster bed",
    8587: "null",
    8588: "Gilded four-poster",
    8589: "null",
    8590: "Oak clock",
    8591: "null",
    8592: "Teak clock",
    8593: "null",
    8594: "Gilded clock",
    8595: "null",
    8596: "Shaving stand",
    8597: "null",
    8598: "Oak shaving stand",
    8599: "null",
    8600: "Oak dresser",
    8601: "null",
    8602: "Teak dresser",
    8603: "null",
    8604: "Fancy teak dresser",
    8605: "null",
    8606: "Mahogany dresser",
    8607: "null",
    8608: "Gilded dresser",
    8609: "null",
    8610: "Shoe box",
    8611: "null",
    8612: "Oak drawers",
    8613: "null",
    8614: "Oak wardrobe",
    8615: "null",
    8616: "Teak drawers",
    8617: "null",
    8618: "Teak wardrobe",
    8619: "null",
    8620: "Mahogany wardrobe",
    8621: "null",
    8622: "Gilded wardrobe",
    8623: "null",
    8624: "Crystal ball",
    8625: "null",
    8626: "Elemental sphere",
    8627: "null",
    8628: "Crystal of power",
    8629: "null",
    8630: "Globe",
    8631: "null",
    8632: "Ornamental globe",
    8633: "null",
    8634: "Lunar globe",
    8635: "null",
    8636: "Celestial globe",
    8637: "null",
    8638: "Armillary sphere",
    8639: "null",
    8640: "Small orrery",
    8641: "null",
    8642: "Large orrery",
    8643: "null",
    8644: "Oak telescope",
    8645: "null",
    8646: "Teak telescope",
    8647: "null",
    8648: "Mahogany telescope",
    8649: "null",
    8650: "Banner",
    8651: "null",
    8652: "Banner",
    8653: "null",
    8654: "Banner",
    8655: "null",
    8656: "Banner",
    8657: "null",
    8658: "Banner",
    8659: "null",
    8660: "Banner",
    8661: "null",
    8662: "Banner",
    8663: "null",
    8664: "Banner",
    8665: "null",
    8666: "Banner",
    8667: "null",
    8668: "Banner",
    8669: "null",
    8670: "Banner",
    8671: "null",
    8672: "Banner",
    8673: "null",
    8674: "Banner",
    8675: "null",
    8676: "Banner",
    8677: "null",
    8678: "Banner",
    8679: "null",
    8680: "Banner",
    8681: "null",
    8682: "Steel heraldic helm",
    8683: "null",
    8684: "Steel heraldic helm",
    8685: "null",
    8686: "Steel heraldic helm",
    8687: "null",
    8688: "Steel heraldic helm",
    8689: "null",
    8690: "Steel heraldic helm",
    8691: "null",
    8692: "Steel heraldic helm",
    8693: "null",
    8694: "Steel heraldic helm",
    8695: "null",
    8696: "Steel heraldic helm",
    8697: "null",
    8698: "Steel heraldic helm",
    8699: "null",
    8700: "Steel heraldic helm",
    8701: "null",
    8702: "Steel heraldic helm",
    8703: "null",
    8704: "Steel heraldic helm",
    8705: "null",
    8706: "Steel heraldic helm",
    8707: "null",
    8708: "Steel heraldic helm",
    8709: "null",
    8710: "Steel heraldic helm",
    8711: "null",
    8712: "Steel heraldic helm",
    8713: "null",
    8714: "Rune kiteshield",
    8715: "null",
    8716: "Rune kiteshield",
    8717: "null",
    8718: "Rune kiteshield",
    8719: "null",
    8720: "Rune kiteshield",
    8721: "null",
    8722: "Rune kiteshield",
    8723: "null",
    8724: "Rune kiteshield",
    8725: "null",
    8726: "Rune kiteshield",
    8727: "null",
    8728: "Rune kiteshield",
    8729: "null",
    8730: "Rune kiteshield",
    8731: "null",
    8732: "Rune kiteshield",
    8733: "null",
    8734: "Rune kiteshield",
    8735: "null",
    8736: "Rune kiteshield",
    8737: "null",
    8738: "Rune kiteshield",
    8739: "null",
    8740: "Rune kiteshield",
    8741: "null",
    8742: "Rune kiteshield",
    8743: "null",
    8744: "Rune kiteshield",
    8745: "null",
    8746: "Steel kiteshield",
    8747: "null",
    8748: "Steel kiteshield",
    8749: "null",
    8750: "Steel kiteshield",
    8751: "null",
    8752: "Steel kiteshield",
    8753: "null",
    8754: "Steel kiteshield",
    8755: "null",
    8756: "Steel kiteshield",
    8757: "null",
    8758: "Steel kiteshield",
    8759: "null",
    8760: "Steel kiteshield",
    8761: "null",
    8762: "Steel kiteshield",
    8763: "null",
    8764: "Steel kiteshield",
    8765: "null",
    8766: "Steel kiteshield",
    8767: "null",
    8768: "Steel kiteshield",
    8769: "null",
    8770: "Steel kiteshield",
    8771: "null",
    8772: "Steel kiteshield",
    8773: "null",
    8774: "Steel kiteshield",
    8775: "null",
    8776: "Steel kiteshield",
    8777: "null",
    8778: "Oak plank",
    8779: "null",
    8780: "Teak plank",
    8781: "null",
    8782: "Mahogany plank",
    8783: "null",
    8784: "Gold leaf",
    8785: "null",
    8786: "Marble block",
    8787: "null",
    8788: "Magic stone",
    8789: "null",
    8790: "Bolt of cloth",
    8791: "null",
    8792: "Clockwork",
    8793: "null",
    8794: "Saw",
    8795: "null",
    8796: "Null",
    8797: "null",
    8798: "null",
    8799: "null",
    8800: "null",
    8801: "null",
    8802: "null",
    8803: "null",
    8804: "null",
    8805: "null",
    8806: "null",
    8807: "null",
    8808: "null",
    8809: "null",
    8810: "null",
    8811: "null",
    8812: "null",
    8813: "null",
    8814: "null",
    8815: "null",
    8816: "null",
    8817: "null",
    8818: "null",
    8819: "null",
    8820: "null",
    8821: "null",
    8822: "null",
    8823: "null",
    8824: "null",
    8825: "null",
    8826: "null",
    8827: "null",
    8828: "null",
    8829: "null",
    8830: "null",
    8831: "null",
    8832: "null",
    8833: "null",
    8834: "null",
    8835: "null",
    8836: "null",
    8837: "Timber beam",
    8838: "null",
    8839: "Void knight top",
    8840: "Void knight robe",
    8841: "Void knight mace",
    8842: "Void knight gloves",
    8843: "null",
    8844: "Bronze defender",
    8845: "Iron defender",
    8846: "Steel defender",
    8847: "Black defender",
    8848: "Mithril defender",
    8849: "Adamant defender",
    8850: "Rune defender",
    8851: "Warrior guild token",
    8852: "null",
    8853: "null",
    8854: "null",
    8855: "null",
    8856: "Defensive shield",
    8857: "Shot",
    8858: "18lb shot",
    8859: "22lb shot",
    8860: "One barrel",
    8861: "Two barrels",
    8862: "Three barrels",
    8863: "Four barrels",
    8864: "Five barrels",
    8865: "Ground ashes",
    8866: "Steel key",
    8867: "Bronze key",
    8868: "Silver key",
    8869: "Iron key",
    8870: "Zanik",
    8871: "Crate with zanik",
    8872: "Bone dagger",
    8873: "null",
    8874: "Bone dagger (p)",
    8875: "null",
    8876: "Bone dagger (p+)",
    8877: "null",
    8878: "Bone dagger (p++)",
    8879: "null",
    8880: "Dorgeshuun crossbow",
    8881: "null",
    8882: "Bone bolts",
    8883: "null",
    8884: "null",
    8885: "null",
    8886: "null",
    8887: "Zanik",
    8888: "Zanik (ham)",
    8889: "Zanik (showdown)",
    8890: "Coins",
    8891: "null",
    8892: "null",
    8893: "null",
    8894: "null",
    8895: "null",
    8896: "null",
    8897: "null",
    8898: "null",
    8899: "null",
    8900: "Cave horror",
    8901: "Black mask (10)",
    8902: "null",
    8903: "Black mask (9)",
    8904: "null",
    8905: "Black mask (8)",
    8906: "null",
    8907: "Black mask (7)",
    8908: "null",
    8909: "Black mask (6)",
    8910: "null",
    8911: "Black mask (5)",
    8912: "null",
    8913: "Black mask (4)",
    8914: "null",
    8915: "Black mask (3)",
    8916: "null",
    8917: "Black mask (2)",
    8918: "null",
    8919: "Black mask (1)",
    8920: "null",
    8921: "Black mask",
    8922: "null",
    8923: "Witchwood icon",
    8924: "Bandana eyepatch",
    8925: "Bandana eyepatch",
    8926: "Bandana eyepatch",
    8927: "Bandana eyepatch",
    8928: "Hat eyepatch",
    8929: "Crabclaw hook",
    8930: "Pipe section",
    8931: "null",
    8932: "Lumber patch",
    8933: "null",
    8934: "Scrapey tree logs",
    8935: "null",
    8936: "Blue flowers",
    8937: "null",
    8938: "Red flowers",
    8939: "null",
    8940: "Rum",
    8941: "Rum",
    8942: "Monkey",
    8943: "Blue monkey",
    8944: "Blue monkey",
    8945: "Blue monkey",
    8946: "Red monkey",
    8947: "Red monkey",
    8948: "Red monkey",
    8949: "Pirate bandana",
    8950: "Pirate hat",
    8951: "Pieces of eight",
    8952: "Blue naval shirt",
    8953: "Green naval shirt",
    8954: "Red naval shirt",
    8955: "Brown naval shirt",
    8956: "Black naval shirt",
    8957: "Purple naval shirt",
    8958: "Grey naval shirt",
    8959: "Blue tricorn hat",
    8960: "Green tricorn hat",
    8961: "Red tricorn hat",
    8962: "Brown tricorn hat",
    8963: "Black tricorn hat",
    8964: "Purple tricorn hat",
    8965: "Grey tricorn hat",
    8966: "Cutthroat flag",
    8967: "Gilded smile flag",
    8968: "Bronze fist flag",
    8969: "Lucky shot flag",
    8970: "Treasure flag",
    8971: "Phasmatys flag",
    8972: "Bowl of red water",
    8973: "null",
    8974: "Bowl of blue water",
    8975: "null",
    8976: "Bitternut",
    8977: "Scrapey bark",
    8978: "null",
    8979: "Bridge section",
    8980: "null",
    8981: "Sweetgrubs",
    8982: "null",
    8983: "null",
    8984: "null",
    8985: "null",
    8986: "Bucket",
    8987: "Torch",
    8988: "The stuff",
    8989: "Brewin' guide",
    8990: "Brewin' guide",
    8991: "Blue navy slacks",
    8992: "Green navy slacks",
    8993: "Red navy slacks",
    8994: "Brown navy slacks",
    8995: "Black navy slacks",
    8996: "Purple navy slacks",
    8997: "Grey navy slacks",
    8998: "null",
    8999: "null",
    9000: "null",
    9001: "null",
    9002: "null",
    9003: "Security book",
    9004: "Stronghold notes",
    9005: "Fancy boots",
    9006: "Fighting boots",
    9007: "Right skull half",
    9008: "Left skull half",
    9009: "Strange skull",
    9010: "Top of sceptre",
    9011: "Bottom of sceptre",
    9012: "Runed sceptre",
    9013: "Skull sceptre",
    9014: "null",
    9015: "null",
    9016: "Gorak claws",
    9017: "Star flower",
    9018: "Gorak claw powder",
    9019: "Magic essence (unf)",
    9020: "Queen's secateurs",
    9021: "Magic essence(4)",
    9022: "Magic essence(3)",
    9023: "Magic essence(2)",
    9024: "Magic essence(1)",
    9025: "Nuff's certificate",
    9026: "Ivory comb",
    9027: "null",
    9028: "Golden scarab",
    9029: "null",
    9030: "Stone scarab",
    9031: "null",
    9032: "Pottery scarab",
    9033: "null",
    9034: "Golden statuette",
    9035: "null",
    9036: "Pottery statuette",
    9037: "null",
    9038: "Stone statuette",
    9039: "null",
    9040: "Gold seal",
    9041: "null",
    9042: "Stone seal",
    9043: "null",
    9044: "Pharaoh's sceptre",
    9045: "Pharaoh's sceptre",
    9046: "Pharaoh's sceptre",
    9047: "Pharaoh's sceptre",
    9048: "Pharaoh's sceptre",
    9049: "Pharaoh's sceptre",
    9050: "Pharaoh's sceptre",
    9051: "Pharaoh's sceptre",
    9052: "Locust meat",
    9053: "null",
    9054: "Red goblin mail",
    9055: "Black goblin mail",
    9056: "Yellow goblin mail",
    9057: "Green goblin mail",
    9058: "Purple goblin mail",
    9059: "Pink goblin mail",
    9060: "null",
    9061: "null",
    9062: "null",
    9063: "null",
    9064: "Emerald lantern",
    9065: "Emerald lantern",
    9066: "Emerald lens",
    9067: "Dream log",
    9068: "Moonclan helm",
    9069: "Moonclan hat",
    9070: "Moonclan armour",
    9071: "Moonclan skirt",
    9072: "Moonclan gloves",
    9073: "Moonclan boots",
    9074: "Moonclan cape",
    9075: "Astral rune",
    9076: "Lunar ore",
    9077: "Lunar bar",
    9078: "Moonclan manual",
    9079: "Suqah tooth",
    9080: "Suqah hide",
    9081: "Suqah leather",
    9082: "Ground tooth",
    9083: "Seal of passage",
    9084: "Lunar staff",
    9085: "Empty vial",
    9086: "Vial of water",
    9087: "Waking sleep vial",
    9088: "Guam vial",
    9089: "Marr vial",
    9090: "Guam-marr vial",
    9091: "Lunar staff - pt1",
    9092: "Lunar staff - pt2",
    9093: "Lunar staff - pt3",
    9094: "Kindling",
    9095: "Soaked kindling",
    9096: "Lunar helm",
    9097: "Lunar torso",
    9098: "Lunar legs",
    9099: "Lunar gloves",
    9100: "Lunar boots",
    9101: "Lunar cape",
    9102: "Lunar amulet",
    9103: "A special tiara",
    9104: "Lunar ring",
    9105: "Suqah monster",
    9106: "Astral tiara",
    9107: "null",
    9108: "null",
    9109: "null",
    9110: "null",
    9111: "null",
    9112: "null",
    9113: "null",
    9114: "null",
    9115: "null",
    9116: "null",
    9117: "null",
    9118: "null",
    9119: "null",
    9120: "null",
    9121: "null",
    9122: "null",
    9123: "null",
    9124: "null",
    9125: "null",
    9126: "null",
    9127: "null",
    9128: "null",
    9129: "null",
    9130: "null",
    9131: "null",
    9132: "null",
    9133: "null",
    9134: "null",
    9135: "null",
    9136: "null",
    9137: "null",
    9138: "null",
    9139: "Blurite bolts",
    9140: "Iron bolts",
    9141: "Steel bolts",
    9142: "Mithril bolts",
    9143: "Adamant bolts",
    9144: "Runite bolts",
    9145: "Silver bolts",
    9146: "null",
    9147: "null",
    9148: "null",
    9149: "null",
    9150: "null",
    9151: "null",
    9152: "null",
    9153: "null",
    9154: "null",
    9155: "null",
    9156: "null",
    9157: "null",
    9158: "null",
    9159: "null",
    9160: "null",
    9161: "null",
    9162: "null",
    9163: "null",
    9164: "null",
    9165: "null",
    9166: "null",
    9167: "null",
    9168: "null",
    9169: "null",
    9170: "null",
    9171: "null",
    9172: "null",
    9173: "null",
    9174: "Bronze crossbow",
    9175: "null",
    9176: "Blurite crossbow",
    9177: "Iron crossbow",
    9178: "null",
    9179: "Steel crossbow",
    9180: "null",
    9181: "Mithril crossbow",
    9182: "null",
    9183: "Adamant crossbow",
    9184: "null",
    9185: "Rune crossbow",
    9186: "null",
    9187: "Jade bolt tips",
    9188: "Topaz bolt tips",
    9189: "Sapphire bolt tips",
    9190: "Emerald bolt tips",
    9191: "Ruby bolt tips",
    9192: "Diamond bolt tips",
    9193: "Dragonstone bolt tips",
    9194: "Onyx bolt tips",
    9195: "null",
    9196: "null",
    9197: "null",
    9198: "null",
    9199: "null",
    9200: "null",
    9201: "null",
    9202: "null",
    9203: "null",
    9204: "null",
    9205: "null",
    9206: "null",
    9207: "null",
    9208: "null",
    9209: "null",
    9210: "null",
    9211: "null",
    9212: "null",
    9213: "null",
    9214: "null",
    9215: "null",
    9216: "null",
    9217: "null",
    9218: "null",
    9219: "null",
    9220: "null",
    9221: "null",
    9222: "null",
    9223: "null",
    9224: "null",
    9225: "null",
    9226: "null",
    9227: "null",
    9228: "null",
    9229: "null",
    9230: "null",
    9231: "null",
    9232: "null",
    9233: "null",
    9234: "null",
    9235: "null",
    9236: "Opal bolts (e)",
    9237: "Jade bolts (e)",
    9238: "Pearl bolts (e)",
    9239: "Topaz bolts (e)",
    9240: "Sapphire bolts (e)",
    9241: "Emerald bolts (e)",
    9242: "Ruby bolts (e)",
    9243: "Diamond bolts (e)",
    9244: "Dragonstone bolts (e)",
    9245: "Onyx bolts (e)",
    9246: "null",
    9247: "null",
    9248: "null",
    9249: "null",
    9250: "null",
    9251: "null",
    9252: "null",
    9253: "null",
    9254: "null",
    9255: "null",
    9256: "null",
    9257: "null",
    9258: "null",
    9259: "null",
    9260: "null",
    9261: "null",
    9262: "null",
    9263: "null",
    9264: "null",
    9265: "null",
    9266: "null",
    9267: "null",
    9268: "null",
    9269: "null",
    9270: "null",
    9271: "null",
    9272: "null",
    9273: "null",
    9274: "null",
    9275: "null",
    9276: "null",
    9277: "null",
    9278: "null",
    9279: "null",
    9280: "null",
    9281: "null",
    9282: "null",
    9283: "null",
    9284: "null",
    9285: "null",
    9286: "Blurite bolts (p)",
    9287: "Iron bolts (p)",
    9288: "Steel bolts (p)",
    9289: "Mithril bolts (p)",
    9290: "Adamant bolts (p)",
    9291: "Runite bolts (p)",
    9292: "Silver bolts (p)",
    9293: "Blurite bolts (p+)",
    9294: "Iron bolts (p+)",
    9295: "Steel bolts (p+)",
    9296: "Mithril bolts (p+)",
    9297: "Adamant bolts (p+)",
    9298: "Runite bolts (p+)",
    9299: "Silver bolts (p+)",
    9300: "Blurite bolts (p++)",
    9301: "Iron bolts (p++)",
    9302: "Steel bolts (p++)",
    9303: "Mithril bolts (p++)",
    9304: "Adamant bolts (p++)",
    9305: "Runite bolts (p++)",
    9306: "Silver bolts (p++)",
    9307: "null",
    9308: "null",
    9309: "null",
    9310: "null",
    9311: "null",
    9312: "null",
    9313: "null",
    9314: "null",
    9315: "null",
    9316: "null",
    9317: "null",
    9318: "null",
    9319: "null",
    9320: "null",
    9321: "null",
    9322: "null",
    9323: "null",
    9324: "null",
    9325: "null",
    9326: "null",
    9327: "null",
    9328: "null",
    9329: "null",
    9330: "null",
    9331: "null",
    9332: "null",
    9333: "null",
    9334: "null",
    9335: "Jade bolts",
    9336: "Topaz bolts",
    9337: "Sapphire bolts",
    9338: "Emerald bolts",
    9339: "Ruby bolts",
    9340: "Diamond bolts",
    9341: "Dragonstone bolts",
    9342: "Onyx bolts",
    9343: "null",
    9344: "null",
    9345: "null",
    9346: "null",
    9347: "null",
    9348: "null",
    9349: "null",
    9350: "null",
    9351: "null",
    9352: "null",
    9353: "null",
    9354: "null",
    9355: "null",
    9356: "null",
    9357: "null",
    9358: "null",
    9359: "null",
    9360: "null",
    9361: "null",
    9362: "null",
    9363: "null",
    9364: "null",
    9365: "null",
    9366: "null",
    9367: "null",
    9368: "null",
    9369: "null",
    9370: "null",
    9371: "null",
    9372: "null",
    9373: "null",
    9374: "null",
    9375: "Bronze bolts (unf)",
    9376: "Blurite bolts (unf)",
    9377: "Iron bolts (unf)",
    9378: "Steel bolts (unf)",
    9379: "Mithril bolts (unf)",
    9380: "Adamant bolts(unf)",
    9381: "Runite bolts (unf)",
    9382: "Silver bolts (unf)",
    9383: "null",
    9384: "null",
    9385: "null",
    9386: "null",
    9387: "null",
    9388: "null",
    9389: "null",
    9390: "null",
    9391: "null",
    9392: "null",
    9393: "null",
    9394: "null",
    9395: "null",
    9396: "null",
    9397: "null",
    9398: "null",
    9399: "null",
    9400: "null",
    9401: "null",
    9402: "null",
    9403: "null",
    9404: "null",
    9405: "null",
    9406: "null",
    9407: "null",
    9408: "null",
    9409: "null",
    9410: "null",
    9411: "null",
    9412: "null",
    9413: "null",
    9414: "null",
    9415: "Grapple",
    9416: "Mith grapple tip",
    9417: "null",
    9418: "Mith grapple",
    9419: "Mith grapple",
    9420: "Bronze limbs",
    9421: "null",
    9422: "Blurite limbs",
    9423: "Iron limbs",
    9424: "null",
    9425: "Steel limbs",
    9426: "null",
    9427: "Mithril limbs",
    9428: "null",
    9429: "Adamantite limbs",
    9430: "null",
    9431: "Runite limbs",
    9432: "null",
    9433: "Bolt pouch",
    9434: "Bolt mould",
    9435: "null",
    9436: "Sinew",
    9437: "null",
    9438: "Crossbow string",
    9439: "null",
    9440: "Wooden stock",
    9441: "null",
    9442: "Oak stock",
    9443: "null",
    9444: "Willow stock",
    9445: "null",
    9446: "Teak stock",
    9447: "null",
    9448: "Maple stock",
    9449: "null",
    9450: "Mahogany stock",
    9451: "null",
    9452: "Yew stock",
    9453: "null",
    9454: "Bronze crossbow (u)",
    9455: "null",
    9456: "Blurite crossbow (u)",
    9457: "Iron crossbow (u)",
    9458: "null",
    9459: "Steel crossbow (u)",
    9460: "null",
    9461: "Mithril crossbow (u)",
    9462: "null",
    9463: "Adamant crossbow (u)",
    9464: "null",
    9465: "Runite crossbow (u)",
    9466: "null",
    9467: "Blurite bar",
    9468: "Sawdust",
    9469: "Grand seed pod",
    9470: "Gnome scarf",
    9471: "null",
    9472: "Gnome goggles",
    9473: "null",
    9474: "Reward token",
    9475: "Mint cake",
    9476: "null",
    9477: "Aluft aloft box",
    9478: "Half made batta",
    9479: "Unfinished batta",
    9480: "Half made batta",
    9481: "Unfinished batta",
    9482: "Half made batta",
    9483: "Half made batta",
    9484: "Unfinished batta",
    9485: "Half made batta",
    9486: "Unfinished batta",
    9487: "Wizard blizzard",
    9488: "null",
    9489: "Wizard blizzard",
    9490: "null",
    9491: "null",
    9492: "null",
    9493: "null",
    9494: "null",
    9495: "null",
    9496: "null",
    9497: "null",
    9498: "null",
    9499: "null",
    9500: "null",
    9501: "null",
    9502: "null",
    9503: "null",
    9504: "null",
    9505: "null",
    9506: "null",
    9507: "null",
    9508: "Wizard blizzard",
    9509: "null",
    9510: "Short green guy",
    9511: "null",
    9512: "Pineapple punch",
    9513: "null",
    9514: "Fruit blast",
    9515: "null",
    9516: "Drunk dragon",
    9517: "null",
    9518: "Choc saturday",
    9519: "null",
    9520: "Blurberry special",
    9521: "null",
    9522: "Batta tin",
    9523: "null",
    9524: "Batta tin",
    9525: "null",
    9526: "null",
    9527: "Fruit batta",
    9528: "null",
    9529: "Toad batta",
    9530: "null",
    9531: "Worm batta",
    9532: "null",
    9533: "Vegetable batta",
    9534: "null",
    9535: "Cheese+tom batta",
    9536: "null",
    9537: "null",
    9538: "Toad crunchies",
    9539: "null",
    9540: "Spicy crunchies",
    9541: "null",
    9542: "Worm crunchies",
    9543: "null",
    9544: "Chocchip crunchies",
    9545: "null",
    9546: "null",
    9547: "Worm hole",
    9548: "null",
    9549: "Veg ball",
    9550: "null",
    9551: "Tangled toads' legs",
    9552: "null",
    9553: "Chocolate bomb",
    9554: "null",
    9555: "null",
    9556: "null",
    9557: "null",
    9558: "Half made bowl",
    9559: "Half made bowl",
    9560: "Unfinished bowl",
    9561: "Half made bowl",
    9562: "Unfinished bowl",
    9563: "Half made bowl",
    9564: "Unfinished bowl",
    9565: "null",
    9566: "Mixed blizzard",
    9567: "Mixed sgg",
    9568: "Mixed blast",
    9569: "Mixed punch",
    9570: "Mixed special",
    9571: "Mixed saturday",
    9572: "Mixed saturday",
    9573: "Mixed saturday",
    9574: "Mixed dragon",
    9575: "Mixed dragon",
    9576: "Mixed dragon",
    9577: "Half made crunchy",
    9578: "Unfinished crunchy",
    9579: "Half made crunchy",
    9580: "Unfinished crunchy",
    9581: "Half made crunchy",
    9582: "Unfinished crunchy",
    9583: "Half made crunchy",
    9584: "Unfinished crunchy",
    9585: "null",
    9586: "null",
    9587: "null",
    9588: "null",
    9589: "Dossier",
    9590: "Dossier",
    9591: "Broken cauldron",
    9592: "Magic glue",
    9593: "Weird gloop",
    9594: "Ground mud runes",
    9595: "Hazelmere's book",
    9596: "Null",
    9597: "Red circle",
    9598: "Red triangle",
    9599: "Red square",
    9600: "Red pentagon",
    9601: "Orange circle",
    9602: "Orange triangle",
    9603: "Orange square",
    9604: "Orange pentagon",
    9605: "Yellow circle",
    9606: "Yellow triangle",
    9607: "Yellow square",
    9608: "Yellow pentagon",
    9609: "Green circle",
    9610: "Green triangle",
    9611: "Green square",
    9612: "Green pentagon",
    9613: "Blue circle",
    9614: "Blue triangle",
    9615: "Blue square",
    9616: "Blue pentagon",
    9617: "Indigo circle",
    9618: "Indigo triangle",
    9619: "Indigo square",
    9620: "Indigo pentagon",
    9621: "Violet circle",
    9622: "Violet triangle",
    9623: "Violet square",
    9624: "Violet pentagon",
    9625: "Crystal saw",
    9626: "Crystal saw seed",
    9627: "A handwritten book",
    9628: "null",
    9629: "Tyras helm",
    9630: "null",
    9631: "null",
    9632: "Daeyalt ore",
    9633: "Message",
    9634: "Vyrewatch top",
    9635: "null",
    9636: "Vyrewatch legs",
    9637: "null",
    9638: "Vyrewatch shoes",
    9639: "null",
    9640: "Citizen top",
    9641: "null",
    9642: "Citizen trousers",
    9643: "null",
    9644: "Citizen shoes",
    9645: "null",
    9646: "Castle sketch 1",
    9647: "Castle sketch 2",
    9648: "Castle sketch 3",
    9649: "Message",
    9650: "Blood tithe pouch",
    9651: "Large ornate key",
    9652: "Haemalchemy volume 1",
    9653: "Sealed message",
    9654: "Door key",
    9655: "Ladder top",
    9656: "Tome of experience (3)",
    9657: "Tome of experience (2)",
    9658: "Tome of experience (1)",
    9659: "Bucket of water",
    9660: "Bucket",
    9661: "null",
    9662: "Useless key",
    9663: "null",
    9664: "null",
    9665: "Torch",
    9666: "Proselyte harness m",
    9667: "null",
    9668: "Initiate harness m",
    9669: "null",
    9670: "Proselyte harness f",
    9671: "null",
    9672: "Proselyte sallet",
    9673: "null",
    9674: "Proselyte hauberk",
    9675: "null",
    9676: "Proselyte cuisse",
    9677: "null",
    9678: "Proselyte tasset",
    9679: "null",
    9680: "Sea slug glue",
    9681: "Commorb v2",
    9682: "Door transcription",
    9683: "Dead sea slug",
    9684: "Page 1",
    9685: "Page 2",
    9686: "Page 3",
    9687: "Fragment 1",
    9688: "Fragment 2",
    9689: "Fragment 3",
    9690: "Blank water rune",
    9691: "Water rune",
    9692: "Blank air rune",
    9693: "Air rune",
    9694: "Blank earth rune",
    9695: "Earth rune",
    9696: "Blank mind rune",
    9697: "Mind rune",
    9698: "Blank fire rune",
    9699: "Fire rune",
    9700: "null",
    9701: "null",
    9702: "Stick",
    9703: "Training sword",
    9704: "Training shield",
    9705: "Training bow",
    9706: "Training arrows",
    9707: "null",
    9708: "null",
    9709: "null",
    9710: "null",
    9711: "null",
    9712: "null",
    9713: "null",
    9714: "null",
    9715: "Slashed book",
    9716: "Rock",
    9717: "Beaten book",
    9718: "Crane schematic",
    9719: "Lever schematic",
    9720: "Crane claw",
    9721: "Scroll",
    9722: "Key",
    9723: "Pipe",
    9724: "Large cog",
    9725: "Medium cog",
    9726: "Small cog",
    9727: "Primed bar",
    9728: "Primed mind bar",
    9729: "Elemental helmet",
    9730: "null",
    9731: "Mind shield",
    9732: "null",
    9733: "Mind helmet",
    9734: "null",
    9735: "Desert goat horn",
    9736: "Goat horn dust",
    9737: "null",
    9738: "null",
    9739: "Combat potion(4)",
    9740: "null",
    9741: "Combat potion(3)",
    9742: "null",
    9743: "Combat potion(2)",
    9744: "null",
    9745: "Combat potion(1)",
    9746: "null",
    9747: "Attack cape",
    9748: "Attack cape(t)",
    9749: "Attack hood",
    9750: "Strength cape",
    9751: "Strength cape(t)",
    9752: "Strength hood",
    9753: "Defence cape",
    9754: "Defence cape(t)",
    9755: "Defence hood",
    9756: "Ranging cape",
    9757: "Ranging cape(t)",
    9758: "Ranging hood",
    9759: "Prayer cape",
    9760: "Prayer cape(t)",
    9761: "Prayer hood",
    9762: "Magic cape",
    9763: "Magic cape(t)",
    9764: "Magic hood",
    9765: "Runecraft cape",
    9766: "Runecraft cape(t)",
    9767: "Runecraft hood",
    9768: "Hitpoints cape",
    9769: "Hitpoints cape(t)",
    9770: "Hitpoints hood",
    9771: "Agility cape",
    9772: "Agility cape(t)",
    9773: "Agility hood",
    9774: "Herblore cape",
    9775: "Herblore cape(t)",
    9776: "Herblore hood",
    9777: "Thieving cape",
    9778: "Thieving cape(t)",
    9779: "Thieving hood",
    9780: "Crafting cape",
    9781: "Crafting cape(t)",
    9782: "Crafting hood",
    9783: "Fletching cape",
    9784: "Fletching cape(t)",
    9785: "Fletching hood",
    9786: "Slayer cape",
    9787: "Slayer cape(t)",
    9788: "Slayer hood",
    9789: "Construct. cape",
    9790: "Construct. cape(t)",
    9791: "Construct. hood",
    9792: "Mining cape",
    9793: "Mining cape(t)",
    9794: "Mining hood",
    9795: "Smithing cape",
    9796: "Smithing cape(t)",
    9797: "Smithing hood",
    9798: "Fishing cape",
    9799: "Fishing cape(t)",
    9800: "Fishing hood",
    9801: "Cooking cape",
    9802: "Cooking cape(t)",
    9803: "Cooking hood",
    9804: "Firemaking cape",
    9805: "Firemaking cape(t)",
    9806: "Firemaking hood",
    9807: "Woodcutting cape",
    9808: "Woodcut. cape(t)",
    9809: "Woodcutting hood",
    9810: "Farming cape",
    9811: "Farming cape(t)",
    9812: "Farming hood",
    9813: "Quest point cape",
    9814: "Quest point hood",
    9815: "null",
    9816: "null",
    9817: "Oak cape rack",
    9818: "Teak cape rack",
    9819: "Mahogany cape rack",
    9820: "Gilded cape rack",
    9821: "Marble cape rack",
    9822: "Magical cape rack",
    9823: "Oak costume box",
    9824: "Teak costume box",
    9825: "Mahogany costume box",
    9826: "Oak armour case",
    9827: "Teak armour case",
    9828: "Mahogany armour case",
    9829: "Oak wardrobe",
    9830: "Carved oak wardrobe",
    9831: "Teak wardrobe",
    9832: "Carved teak wardrobe",
    9833: "Mahogany wardrobe",
    9834: "Gilded wardrobe",
    9835: "Marble wardrobe",
    9836: "Oak toy box",
    9837: "Teak toy box",
    9838: "Mahogany toy box",
    9839: "Oak treasure chest",
    9840: "Teak treasure chest",
    9841: "Mahogany treasure chest",
    9842: "Costume room",
    9843: "Oak cape rack",
    9844: "Teak cape rack",
    9845: "Mahogany cape rack",
    9846: "Gilded cape rack",
    9847: "Marble cape rack",
    9848: "Magic cape rack",
    9849: "Oak toy box",
    9850: "Teak toy box",
    9851: "Mahogany toy box",
    9852: "Oak magic wardrobe",
    9853: "Carved oak magic wardrobe",
    9854: "Teak magic wardrobe",
    9855: "Carved teak magic wardrobe",
    9856: "Mahogany magic wardrobe",
    9857: "Gilded magic wardrobe",
    9858: "Marble magic wardrobe",
    9859: "Oak armour case",
    9860: "Teak armour case",
    9861: "Mahogany armour case",
    9862: "Oak treasure chest",
    9863: "Teak treasure chest",
    9864: "M. treasure chest",
    9865: "Oak fancy dress box",
    9866: "Teak fancy dress box",
    9867: "Mahogany fancy dress box",
    9868: "null",
    9869: "null",
    9870: "null",
    9871: "null",
    9872: "null",
    9873: "null",
    9874: "null",
    9875: "null",
    9876: "null",
    9877: "null",
    9878: "null",
    9879: "null",
    9880: "null",
    9881: "null",
    9882: "null",
    9883: "null",
    9884: "null",
    9885: "null",
    9886: "null",
    9887: "null",
    9888: "null",
    9889: "null",
    9890: "null",
    9891: "null",
    9892: "null",
    9893: "null",
    9894: "null",
    9895: "null",
    9896: "null",
    9897: "null",
    9898: "null",
    9899: "null",
    9900: "null",
    9901: "Goutweedy lump",
    9902: "Hardy gout tubers",
    9903: "Farming manual",
    9904: "Sailing book",
    9905: "null",
    9906: "Ghost buster 500",
    9907: "Ghost buster 500",
    9908: "Ghost buster 500",
    9909: "Ghost buster 500",
    9910: "Ghost buster 500",
    9911: "Ghost buster 500",
    9912: "Ghost buster 500",
    9913: "White destabiliser",
    9914: "Red destabiliser",
    9915: "Blue destabiliser",
    9916: "Green destabiliser",
    9917: "Yellow destabiliser",
    9918: "Black destabiliser",
    9919: "Evil root",
    9920: "Jack lantern mask",
    9921: "Skeleton boots",
    9922: "Skeleton gloves",
    9923: "Skeleton leggings",
    9924: "Skeleton shirt",
    9925: "Skeleton mask",
    9926: "null",
    9927: "null",
    9928: "null",
    9929: "null",
    9930: "null",
    9931: "null",
    9932: "Auguste's sapling",
    9933: "Balloon structure",
    9934: "Origami balloon",
    9935: "Yellow balloon",
    9936: "Blue balloon",
    9937: "Red balloon",
    9938: "Orange balloon",
    9939: "Green balloon",
    9940: "Purple balloon",
    9941: "Pink balloon",
    9942: "Black balloon",
    9943: "Sandbag",
    9944: "Bomber jacket",
    9945: "Bomber cap",
    9946: "Cap and goggles",
    9947: "Old red disk",
    9948: "Hunter cape",
    9949: "Hunter cape(t)",
    9950: "Hunter hood",
    9951: "Footprint",
    9952: "Imp",
    9953: "Kebbit",
    9954: "Kebbit",
    9955: "Kebbit",
    9956: "Kebbit",
    9957: "Kebbit",
    9958: "Kebbit",
    9959: "Kebbit",
    9960: "Kebbit",
    9961: "Kebbit",
    9962: "Kebbit",
    9963: "Kebbit",
    9964: "Kebbit",
    9965: "Crimson swift",
    9966: "Copper longtail",
    9967: "Cerulean twitch",
    9968: "Golden warbler",
    9969: "Tropical wagtail",
    9970: "Butterfly",
    9971: "Butterfly",
    9972: "Butterfly",
    9973: "Butterfly",
    9974: "Giant eagle",
    9975: "Rabbit",
    9976: "Chinchompa",
    9977: "Red chinchompa",
    9978: "Raw bird meat",
    9979: "null",
    9980: "Roast bird meat",
    9981: "null",
    9982: "Burnt bird meat",
    9983: "null",
    9984: "Skewered bird meat",
    9985: "null",
    9986: "Raw beast meat",
    9987: "null",
    9988: "Roast beast meat",
    9989: "null",
    9990: "Burnt beast meat",
    9991: "null",
    9992: "Skewered beast",
    9993: "null",
    9994: "Spicy tomato",
    9995: "null",
    9996: "Spicy minced meat",
    9997: "null",
    9998: "Hunter potion(4)",
    9999: "null",
    10000: "Hunter potion(3)",
    10001: "null",
    10002: "Hunter potion(2)",
    10003: "null",
    10004: "Hunter potion(1)",
    10005: "null",
    10006: "Bird snare",
    10007: "null",
    10008: "Box trap",
    10009: "null",
    10010: "Butterfly net",
    10011: "null",
    10012: "Butterfly jar",
    10013: "null",
    10014: "Black warlock",
    10015: "null",
    10016: "Snowy knight",
    10017: "null",
    10018: "Sapphire glacialis",
    10019: "null",
    10020: "Ruby harvest",
    10021: "null",
    10022: "null",
    10023: "Falconer's glove",
    10024: "Falconer's glove",
    10025: "Magic box",
    10026: "null",
    10027: "Imp-in-a-box(2)",
    10028: "Imp-in-a-box(1)",
    10029: "Teasing stick",
    10030: "null",
    10031: "Rabbit snare",
    10032: "null",
    10033: "Chinchompa",
    10034: "Red chinchompa",
    10035: "Kyatt legs",
    10036: "null",
    10037: "Kyatt top",
    10038: "null",
    10039: "Kyatt hat",
    10040: "null",
    10041: "Larupia legs",
    10042: "null",
    10043: "Larupia top",
    10044: "null",
    10045: "Larupia hat",
    10046: "null",
    10047: "Graahk legs",
    10048: "null",
    10049: "Graahk top",
    10050: "null",
    10051: "Graahk headdress",
    10052: "null",
    10053: "Wood camo top",
    10054: "null",
    10055: "Wood camo legs",
    10056: "null",
    10057: "Jungle camo top",
    10058: "null",
    10059: "Jungle camo legs",
    10060: "null",
    10061: "Desert camo top",
    10062: "null",
    10063: "Desert camo legs",
    10064: "null",
    10065: "Polar camo top",
    10066: "null",
    10067: "Polar camo legs",
    10068: "null",
    10069: "Spotted cape",
    10070: "null",
    10071: "Spottier cape",
    10072: "null",
    10073: "Spotted cape",
    10074: "Spottier cape",
    10075: "Gloves of silence",
    10076: "null",
    10077: "Spiky vambraces",
    10078: "null",
    10079: "Green spiky vambraces",
    10080: "null",
    10081: "Blue spiky vambraces",
    10082: "null",
    10083: "Red spiky vambraces",
    10084: "null",
    10085: "Black spiky vambraces",
    10086: "null",
    10087: "Stripy feather",
    10088: "Red feather",
    10089: "Blue feather",
    10090: "Yellow feather",
    10091: "Orange feather",
    10092: "Ferret",
    10093: "Tatty larupia fur",
    10094: "null",
    10095: "Larupia fur",
    10096: "null",
    10097: "Tatty graahk fur",
    10098: "null",
    10099: "Graahk fur",
    10100: "null",
    10101: "Tatty kyatt fur",
    10102: "null",
    10103: "Kyatt fur",
    10104: "null",
    10105: "Kebbit spike",
    10106: "null",
    10107: "Long kebbit spike",
    10108: "null",
    10109: "Kebbit teeth",
    10110: "null",
    10111: "Kebbit teeth dust",
    10112: "null",
    10113: "Kebbit claws",
    10114: "null",
    10115: "Dark kebbit fur",
    10116: "null",
    10117: "Polar kebbit fur",
    10118: "null",
    10119: "Feldip weasel fur",
    10120: "null",
    10121: "Common kebbit fur",
    10122: "null",
    10123: "Desert devil fur",
    10124: "null",
    10125: "Spotted kebbit fur",
    10126: "null",
    10127: "Dashing kebbit fur",
    10128: "null",
    10129: "Barb-tail harpoon",
    10130: "null",
    10131: "null",
    10132: "Strung rabbit foot",
    10133: "null",
    10134: "Rabbit foot",
    10135: "null",
    10136: "Rainbow fish",
    10137: "null",
    10138: "Raw rainbow fish",
    10139: "null",
    10140: "Burnt rainbow fish",
    10141: "null",
    10142: "Guam tar",
    10143: "Marrentill tar",
    10144: "Tarromin tar",
    10145: "Harralander tar",
    10146: "Orange salamander",
    10147: "Red salamander",
    10148: "Black salamander",
    10149: "Swamp lizard",
    10150: "Noose wand",
    10151: "null",
    10152: "null",
    10153: "null",
    10154: "null",
    10155: "null",
    10156: "Hunters' crossbow",
    10157: "null",
    10158: "Kebbit bolts",
    10159: "Long kebbit bolts",
    10160: "null",
    10161: "null",
    10162: "null",
    10163: "null",
    10164: "null",
    10165: "null",
    10166: "null",
    10167: "Eagle feather",
    10168: "null",
    10169: "null",
    10170: "null",
    10171: "Eagle cape",
    10172: "Fake beak",
    10173: "Bird book",
    10174: "Metal feather",
    10175: "Golden feather",
    10176: "Silver feather",
    10177: "Bronze feather",
    10178: "Odd bird seed",
    10179: "Feathered journal",
    10180: "Clue scroll (easy)",
    10181: "Casket (easy)",
    10182: "Clue scroll (easy)",
    10183: "Casket (easy)",
    10184: "Clue scroll (easy)",
    10185: "Casket (easy)",
    10186: "Clue scroll (easy)",
    10187: "Casket (easy)",
    10188: "Clue scroll (easy)",
    10189: "Casket (easy)",
    10190: "Clue scroll (easy)",
    10191: "Casket (easy)",
    10192: "Clue scroll (easy)",
    10193: "Casket (easy)",
    10194: "Clue scroll (easy)",
    10195: "Casket (easy)",
    10196: "Clue scroll (easy)",
    10197: "Casket (easy)",
    10198: "Clue scroll (easy)",
    10199: "Casket (easy)",
    10200: "Clue scroll (easy)",
    10201: "Casket (easy)",
    10202: "Clue scroll (easy)",
    10203: "Casket (easy)",
    10204: "Clue scroll (easy)",
    10205: "Casket (easy)",
    10206: "Clue scroll (easy)",
    10207: "Casket (easy)",
    10208: "Clue scroll (easy)",
    10209: "Casket (easy)",
    10210: "Clue scroll (easy)",
    10211: "Casket (easy)",
    10212: "Clue scroll (easy)",
    10213: "Casket (easy)",
    10214: "Clue scroll (easy)",
    10215: "Casket (easy)",
    10216: "Clue scroll (easy)",
    10217: "Casket (easy)",
    10218: "Clue scroll (easy)",
    10219: "Casket (easy)",
    10220: "Clue scroll (easy)",
    10221: "Casket (easy)",
    10222: "Clue scroll (easy)",
    10223: "Casket (easy)",
    10224: "Clue scroll (easy)",
    10225: "Casket (easy)",
    10226: "Clue scroll (easy)",
    10227: "Casket (easy)",
    10228: "Clue scroll (easy)",
    10229: "Casket (easy)",
    10230: "Clue scroll (easy)",
    10231: "Casket (easy)",
    10232: "Clue scroll (easy)",
    10233: "Casket (easy)",
    10234: "Clue scroll (hard)",
    10235: "Casket (hard)",
    10236: "Clue scroll (hard)",
    10237: "Casket (hard)",
    10238: "Clue scroll (hard)",
    10239: "Casket (hard)",
    10240: "Clue scroll (hard)",
    10241: "Casket (hard)",
    10242: "Clue scroll (hard)",
    10243: "Casket (hard)",
    10244: "Clue scroll (hard)",
    10245: "Casket (hard)",
    10246: "Clue scroll (hard)",
    10247: "Casket (hard)",
    10248: "Clue scroll (hard)",
    10249: "Casket (hard)",
    10250: "Clue scroll (hard)",
    10251: "Casket (hard)",
    10252: "Clue scroll (hard)",
    10253: "Casket (hard)",
    10254: "Clue scroll (medium)",
    10255: "Casket (medium)",
    10256: "Clue scroll (medium)",
    10257: "Casket (medium)",
    10258: "Clue scroll (medium)",
    10259: "Casket (medium)",
    10260: "Clue scroll (medium)",
    10261: "Casket (medium)",
    10262: "Clue scroll (medium)",
    10263: "Casket (medium)",
    10264: "Clue scroll (medium)",
    10265: "Casket (medium)",
    10266: "Clue scroll (medium)",
    10267: "Casket (medium)",
    10268: "Clue scroll (medium)",
    10269: "Casket (medium)",
    10270: "Clue scroll (medium)",
    10271: "Casket (medium)",
    10272: "Clue scroll (medium)",
    10273: "Casket (medium)",
    10274: "Clue scroll (medium)",
    10275: "Casket (medium)",
    10276: "Clue scroll (medium)",
    10277: "Casket (medium)",
    10278: "Clue scroll (medium)",
    10279: "Casket (medium)",
    10280: "Willow comp bow",
    10281: "null",
    10282: "Yew comp bow",
    10283: "null",
    10284: "Magic comp bow",
    10285: "null",
    10286: "Rune helm (h1)",
    10287: "null",
    10288: "Rune helm (h2)",
    10289: "null",
    10290: "Rune helm (h3)",
    10291: "null",
    10292: "Rune helm (h4)",
    10293: "null",
    10294: "Rune helm (h5)",
    10295: "null",
    10296: "Adamant helm (h1)",
    10297: "null",
    10298: "Adamant helm (h2)",
    10299: "null",
    10300: "Adamant helm (h3)",
    10301: "null",
    10302: "Adamant helm (h4)",
    10303: "null",
    10304: "Adamant helm (h5)",
    10305: "null",
    10306: "Black helm (h1)",
    10307: "null",
    10308: "Black helm (h2)",
    10309: "null",
    10310: "Black helm (h3)",
    10311: "null",
    10312: "Black helm (h4)",
    10313: "null",
    10314: "Black helm (h5)",
    10315: "null",
    10316: "Bob's red shirt",
    10317: "null",
    10318: "Bob's blue shirt",
    10319: "null",
    10320: "Bob's green shirt",
    10321: "null",
    10322: "Bob's black shirt",
    10323: "null",
    10324: "Bob's purple shirt",
    10325: "null",
    10326: "Purple firelighter",
    10327: "White firelighter",
    10328: "White logs",
    10329: "Purple logs",
    10330: "3rd age range top",
    10331: "null",
    10332: "3rd age range legs",
    10333: "null",
    10334: "3rd age range coif",
    10335: "null",
    10336: "3rd age vambraces",
    10337: "null",
    10338: "3rd age robe top",
    10339: "null",
    10340: "3rd age robe",
    10341: "null",
    10342: "3rd age mage hat",
    10343: "null",
    10344: "3rd age amulet",
    10345: "null",
    10346: "3rd age platelegs",
    10347: "null",
    10348: "3rd age platebody",
    10349: "null",
    10350: "3rd age full helmet",
    10351: "null",
    10352: "3rd age kiteshield",
    10353: "null",
    10354: "Amulet of glory (t4)",
    10355: "null",
    10356: "Amulet of glory (t3)",
    10357: "null",
    10358: "Amulet of glory (t2)",
    10359: "null",
    10360: "Amulet of glory (t1)",
    10361: "null",
    10362: "Amulet of glory (t)",
    10363: "null",
    10364: "Strength amulet (t)",
    10365: "null",
    10366: "Amulet of magic (t)",
    10367: "null",
    10368: "Zamorak bracers",
    10369: "null",
    10370: "Zamorak d'hide body",
    10371: "null",
    10372: "Zamorak chaps",
    10373: "null",
    10374: "Zamorak coif",
    10375: "null",
    10376: "Guthix bracers",
    10377: "null",
    10378: "Guthix d'hide body",
    10379: "null",
    10380: "Guthix chaps",
    10381: "null",
    10382: "Guthix coif",
    10383: "null",
    10384: "Saradomin bracers",
    10385: "null",
    10386: "Saradomin d'hide body",
    10387: "null",
    10388: "Saradomin chaps",
    10389: "null",
    10390: "Saradomin coif",
    10391: "null",
    10392: "A powdered wig",
    10393: "null",
    10394: "Flared trousers",
    10395: "null",
    10396: "Pantaloons",
    10397: "null",
    10398: "Sleeping cap",
    10399: "null",
    10400: "Black elegant shirt",
    10401: "null",
    10402: "Black elegant legs",
    10403: "null",
    10404: "Red elegant shirt",
    10405: "null",
    10406: "Red elegant legs",
    10407: "null",
    10408: "Blue elegant shirt",
    10409: "null",
    10410: "Blue elegant legs",
    10411: "null",
    10412: "Green elegant shirt",
    10413: "null",
    10414: "Green elegant legs",
    10415: "null",
    10416: "Purple elegant shirt",
    10417: "null",
    10418: "Purple elegant legs",
    10419: "null",
    10420: "White elegant blouse",
    10421: "null",
    10422: "White elegant skirt",
    10423: "null",
    10424: "Red elegant blouse",
    10425: "null",
    10426: "Red elegant skirt",
    10427: "null",
    10428: "Blue elegant blouse",
    10429: "null",
    10430: "Blue elegant skirt",
    10431: "null",
    10432: "Green elegant blouse",
    10433: "null",
    10434: "Green elegant skirt",
    10435: "null",
    10436: "Purple elegant blouse",
    10437: "null",
    10438: "Purple elegant skirt",
    10439: "null",
    10440: "Saradomin crozier",
    10441: "null",
    10442: "Guthix crozier",
    10443: "null",
    10444: "Zamorak crozier",
    10445: "null",
    10446: "Saradomin cloak",
    10447: "null",
    10448: "Guthix cloak",
    10449: "null",
    10450: "Zamorak cloak",
    10451: "null",
    10452: "Saradomin mitre",
    10453: "null",
    10454: "Guthix mitre",
    10455: "null",
    10456: "Zamorak mitre",
    10457: "null",
    10458: "Saradomin robe top",
    10459: "null",
    10460: "Zamorak robe top",
    10461: "null",
    10462: "Guthix robe top",
    10463: "null",
    10464: "Saradomin robe legs",
    10465: "null",
    10466: "Guthix robe legs",
    10467: "null",
    10468: "Zamorak robe legs",
    10469: "null",
    10470: "Saradomin stole",
    10471: "null",
    10472: "Guthix stole",
    10473: "null",
    10474: "Zamorak stole",
    10475: "null",
    10476: "Purple sweets",
    10477: "null",
    10478: "null",
    10479: "null",
    10480: "null",
    10481: "null",
    10482: "null",
    10483: "null",
    10484: "null",
    10485: "Scroll",
    10486: "Empty sack",
    10487: "Undead chicken",
    10488: "Selected iron",
    10489: "Bar magnet",
    10490: "Undead twigs",
    10491: "Blessed axe",
    10492: "Research notes",
    10493: "Translated notes",
    10494: "A pattern",
    10495: "A container",
    10496: "Polished buttons",
    10497: "null",
    10498: "Ava's attractor",
    10499: "Ava's accumulator",
    10500: "Crone-made amulet",
    10501: "Snowball",
    10502: "null",
    10503: "null",
    10504: "null",
    10505: "null",
    10506: "Gublinch shards",
    10507: "Reindeer hat",
    10508: "Wintumber tree",
    10509: "null",
    10510: "Fremennik sea boots",
    10511: "Null",
    10512: "Scroll",
    10513: "Crackers",
    10514: "Tofu",
    10515: "Worms",
    10516: "Attacker horn",
    10517: "Attacker horn",
    10518: "Attacker horn",
    10519: "Attacker horn",
    10520: "Attacker horn",
    10521: "Collection bag",
    10522: "Collection bag",
    10523: "Collection bag",
    10524: "Collection bag",
    10525: "Collection bag",
    10526: "Healer horn",
    10527: "Healer horn",
    10528: "Healer horn",
    10529: "Healer horn",
    10530: "Healer horn",
    10531: "Green egg",
    10532: "Red egg",
    10533: "Blue egg",
    10534: "Yellow egg",
    10535: "Poisoned egg",
    10536: "Spiked/pois. egg",
    10537: "Omega egg",
    10538: "Defender horn",
    10539: "Poisoned tofu",
    10540: "Poisoned worms",
    10541: "Poisoned meat",
    10542: "Healing vial(4)",
    10543: "Healing vial(3)",
    10544: "Healing vial(2)",
    10545: "Healing vial(1)",
    10546: "Healing vial",
    10547: "Healer hat",
    10548: "Fighter hat",
    10549: "Runner hat",
    10550: "Ranger hat",
    10551: "Fighter torso",
    10552: "Runner boots",
    10553: "Penance gloves",
    10554: "Penance gloves",
    10555: "Penance skirt",
    10556: "Attacker icon",
    10557: "Collector icon",
    10558: "Defender icon",
    10559: "Healer icon",
    10560: "Collector horn",
    10561: "Spikes",
    10562: "Queen help book",
    10563: "No eggs",
    10564: "Granite body",
    10565: "null",
    10566: "Fire cape",
    10567: "Healer icon",
    10568: "null",
    10569: "null",
    10570: "null",
    10571: "null",
    10572: "null",
    10573: "null",
    10574: "null",
    10575: "null",
    10576: "null",
    10577: "null",
    10578: "null",
    10579: "null",
    10580: "null",
    10581: "Keris",
    10582: "Keris(p)",
    10583: "Keris(p+)",
    10584: "Keris(p++)",
    10585: "Parchment",
    10586: "Combat lamp",
    10587: "Tarn's diary",
    10588: "Salve amulet (e)",
    10589: "Granite helm",
    10590: "null",
    10591: "Terror dog",
    10592: "Penguin bongos",
    10593: "Cowbells",
    10594: "Clockwork book",
    10595: "Clockwork suit",
    10596: "Clockwork suit",
    10597: "Mission report",
    10598: "Mission report",
    10599: "Mission report",
    10600: "Kgp id card",
    10601: "null",
    10602: "null",
    10603: "Null",
    10604: "Null",
    10605: "Null",
    10606: "Null",
    10607: "Null",
    10608: "Null",
    10609: "Null",
    10610: "Null",
    10611: "Null",
    10612: "Null",
    10613: "Null",
    10614: "Null",
    10615: "Null",
    10616: "Null",
    10617: "Null",
    10618: "Null",
    10619: "Null",
    10620: "Null",
    10621: "Null",
    10622: "Null",
    10623: "Null",
    10624: "Null",
    10625: "Null",
    10626: "Null",
    10627: "Null",
    10628: "Null",
    10629: "Null",
    10630: "Null",
    10631: "Null",
    10632: "Null",
    10633: "Null",
    10634: "Null",
    10635: "Null",
    10636: "Null",
    10637: "Null",
    10638: "Null",
    10639: "Null",
    10640: "Null",
    10641: "Null",
    10642: "Null",
    10643: "Null",
    10644: "Null",
    10645: "Null",
    10646: "Null",
    10647: "Null",
    10648: "Null",
    10649: "Null",
    10650: "Null",
    10651: "Null",
    10652: "Null",
    10653: "Null",
    10654: "Null",
    10655: "Null",
    10656: "Null",
    10657: "Null",
    10658: "Null",
    10659: "Null",
    10660: "Null",
    10661: "Null",
    10662: "Null",
    10663: "Null",
    10664: "Null",
    10665: "Null",
    10666: "null",
    10667: "Null",
    10668: "null",
    10669: "null",
    10670: "Null",
    10671: "Null",
    10672: "Null",
    10673: "Null",
    10674: "Null",
    10675: "Null",
    10676: "Null",
    10677: "Null",
    10678: "Null",
    10679: "Null",
    10680: "Null",
    10681: "Null",
    10682: "Null",
    10683: "Null",
    10684: "Null",
    10685: "Null",
    10686: "Null",
    10687: "Null",
    10688: "Null",
    10689: "Null",
    10690: "Null",
    10691: "Null",
    10692: "Null",
    10693: "Null",
    10694: "Null",
    10695: "Null",
    10696: "Null",
    10697: "Null",
    10698: "Null",
    10699: "Null",
    10700: "Null",
    10701: "Null",
    10702: "Null",
    10703: "Null",
    10704: "Null",
    10705: "Null",
    10706: "Null",
    10707: "Null",
    10708: "Null",
    10709: "Null",
    10710: "Null",
    10711: "Null",
    10712: "Null",
    10713: "Null",
    10714: "Null",
    10715: "Null",
    10716: "Null",
    10717: "Null",
    10718: "Null",
    10719: "Null",
    10720: "Null",
    10721: "Null",
    10722: "Null",
    10723: "Null",
    10724: "Null",
    10725: "Null",
    10726: "Null",
    10727: "Null",
    10728: "Null",
    10729: "Null",
    10730: "Null",
    10731: "Null",
    10732: "Null",
    10733: "Null",
    10734: "Null",
    10735: "Null",
    10736: "Null",
    10737: "Null",
    10738: "Null",
    10739: "Null",
    10740: "Null",
    10741: "Null",
    10742: "Null",
    10743: "Null",
    10744: "Null",
    10745: "Null",
    10746: "Null",
    10747: "Null",
    10748: "Null",
    10749: "Null",
    10750: "Null",
    10751: "Null",
    10752: "Null",
    10753: "Null",
    10754: "Null",
    10755: "Null",
    10756: "Null",
    10757: "Null",
    10758: "Null",
    10759: "Null",
    10760: "Null",
    10761: "Null",
    10762: "Null",
    10763: "Null",
    10764: "Null",
    10765: "Null",
    10766: "Null",
    10767: "Null",
    10768: "Null",
    10769: "Null",
    10770: "Null",
    10771: "Null",
    10772: "Null",
    10773: "Null",
    10774: "Null",
    10775: "Null",
    10776: "Null",
    10777: "Null",
    10778: "Null",
    10779: "Null",
    10780: "Null",
    10781: "Null",
    10782: "Null",
    10783: "Null",
    10784: "Null",
    10785: "Null",
    10786: "Null",
    10787: "Null",
    10788: "Null",
    10789: "Null",
    10790: "Null",
    10791: "Null",
    10792: "Null",
    10793: "Null",
    10794: "Null",
    10795: "Null",
    10796: "Null",
    10797: "Null",
    10798: "Null",
    10799: "Null",
    10800: "Null",
    10801: "Null",
    10802: "Null",
    10803: "Null",
    10804: "Null",
    10805: "Null",
    10806: "Null",
    10807: "Null",
    10808: "Arctic pyre logs",
    10809: "null",
    10810: "Arctic pine logs",
    10811: "null",
    10812: "Split log",
    10813: "null",
    10814: "Hair",
    10815: "null",
    10816: "Raw yak meat",
    10817: "null",
    10818: "Yak-hide",
    10819: "null",
    10820: "Cured yak-hide",
    10821: "null",
    10822: "Yak-hide armour",
    10823: "null",
    10824: "Yak-hide armour",
    10825: "null",
    10826: "Neitiznot shield",
    10827: "null",
    10828: "Helm of neitiznot",
    10829: "Documents",
    10830: "Royal decree",
    10831: "Empty tax bag",
    10832: "Light tax bag",
    10833: "Normal tax bag",
    10834: "Hefty tax bag",
    10835: "Bulging taxbag",
    10836: "Silly jester hat",
    10837: "Silly jester top",
    10838: "Silly jester tights",
    10839: "Silly jester boots",
    10840: "A jester stick",
    10841: "Apricot cream pie",
    10842: "Decapitated head",
    10843: "null",
    10844: "Spring sq'irk",
    10845: "Summer sq'irk",
    10846: "Autumn sq'irk",
    10847: "Winter sq'irk",
    10848: "Spring sq'irkjuice",
    10849: "Summer sq'irkjuice",
    10850: "Autumn sq'irkjuice",
    10851: "Winter sq'irkjuice",
    10852: "Summer garden",
    10853: "Spring garden",
    10854: "Autumn garden",
    10855: "Winter garden",
    10856: "Sin seer's note",
    10857: "Severed leg",
    10858: "Shadow sword",
    10859: "Tea flask",
    10860: "null",
    10861: "null",
    10862: "Hard hat",
    10863: "Builder's shirt",
    10864: "Builder's trousers",
    10865: "Builder's boots",
    10866: "Rivets",
    10867: "null",
    10868: "null",
    10869: "null",
    10870: "Binding fluid",
    10871: "Pipe",
    10872: "Pipe ring",
    10873: "Metal sheet",
    10874: "Coloured ball",
    10875: "Valve wheel",
    10876: "Metal bar",
    10877: "Plain satchel",
    10878: "Green satchel",
    10879: "Red satchel",
    10880: "Black satchel",
    10881: "Gold satchel",
    10882: "Rune satchel",
    10883: "Null",
    10884: "Fuse",
    10885: "Keg",
    10886: "Prayer book",
    10887: "Barrelchest anchor",
    10888: "Barrelchest anchor",
    10889: "Blessed lamp",
    10890: "Prayer book",
    10891: "Wooden cat",
    10892: "null",
    10893: "Cranial clamp",
    10894: "Brain tongs",
    10895: "Bell jar",
    10896: "Wolf whistle",
    10897: "Shipping order",
    10898: "Keg",
    10899: "Crate part",
    10900: "null",
    10901: "null",
    10902: "null",
    10903: "null",
    10904: "Skull staple",
    10905: "null",
    10906: "null",
    10907: "null",
    10908: "null",
    10909: "Mixture - step 1(4)",
    10910: "null",
    10911: "Mixture - step 1(3)",
    10912: "null",
    10913: "Mixture - step 1(2)",
    10914: "null",
    10915: "Mixture - step 1(1)",
    10916: "null",
    10917: "Mixture - step 2(4)",
    10918: "null",
    10919: "Mixture - step 2(3)",
    10920: "null",
    10921: "Mixture - step 2(2)",
    10922: "null",
    10923: "Mixture - step 2(1)",
    10924: "null",
    10925: "Sanfew serum(4)",
    10926: "null",
    10927: "Sanfew serum(3)",
    10928: "null",
    10929: "Sanfew serum(2)",
    10930: "null",
    10931: "Sanfew serum(1)",
    10932: "null",
    10933: "Lumberjack boots",
    10934: "Reward token",
    10935: "Reward token",
    10936: "Reward token",
    10937: "Nail beast nails",
    10938: "null",
    10939: "Lumberjack top",
    10940: "Lumberjack legs",
    10941: "Lumberjack hat",
    10942: "Reward token",
    10943: "Reward token",
    10944: "Reward token",
    10945: "Null",
    10946: "Pushup",
    10947: "Run",
    10948: "Situp",
    10949: "Starjump",
    10950: "Skull staples",
    10951: "Skull staples",
    10952: "Slayer bell",
    10953: "null",
    10954: "Frog-leather body",
    10955: "null",
    10956: "Frog-leather chaps",
    10957: "null",
    10958: "Frog-leather boots",
    10959: "null",
    10960: "Green gloop soup",
    10961: "Frogspawn gumbo",
    10962: "Frogburger",
    10963: "Coated frogs' legs",
    10964: "Bat shish",
    10965: "Fingers",
    10966: "Grubs � la mode",
    10967: "Roast frog",
    10968: "Mushrooms",
    10969: "Fillets",
    10970: "Loach",
    10971: "Eel sushi",
    10972: "Dorgesh-kaan sphere",
    10973: "Light orb",
    10974: "null",
    10975: "Spanner",
    10976: "Long bone",
    10977: "Curved bone",
    10978: "Swamp weed",
    10979: "null",
    10980: "Empty light orb",
    10981: "Cave goblin wire",
    10982: "null",
    10983: "Cog",
    10984: "Cog",
    10985: "Fuse",
    10986: "Fuse",
    10987: "Meter",
    10988: "Meter",
    10989: "Capacitor",
    10990: "Capacitor",
    10991: "Lever",
    10992: "Lever",
    10993: "Powerbox",
    10994: "Powerbox",
    10995: "Perfect shell",
    10996: "Perfect snail shell",
    10997: "Molanisk",
    10998: "Cave goblin",
    10999: "Goblin book",
    11000: "null",
    11001: "Dagon'hai history",
    11002: "Sin'keth's diary",
    11003: "An empty folder",
    11004: "null",
    11005: "null",
    11006: "Used folder",
    11007: "Full folder",
    11008: "Rat's paper",
    11009: "Letter to surok",
    11010: "Surok's letter",
    11011: "Zaff's instructions",
    11012: "Wand",
    11013: "Infused wand",
    11014: "Beacon ring",
    11015: "Null",
    11016: "Null",
    11017: "Null",
    11018: "Null",
    11019: "Chicken feet",
    11020: "Chicken wings",
    11021: "Chicken head",
    11022: "Chicken legs",
    11023: "Magic egg",
    11024: "Rabbit mould",
    11025: "Chocolate chunks",
    11026: "Chocolate kebbit",
    11027: "Easter egg",
    11028: "Easter egg",
    11029: "Easter egg",
    11030: "Easter egg",
    11031: "Damp planks",
    11032: "Crude carving",
    11033: "Cruder carving",
    11034: "Sven's last map",
    11035: "Windswept logs",
    11036: "Parchment",
    11037: "Brine sabre",
    11038: "null",
    11039: "Key",
    11040: "Key",
    11041: "Key",
    11042: "Key",
    11043: "Key",
    11044: "Rotten barrel",
    11045: "Rotten barrel",
    11046: "Rope",
    11047: "Brine rat",
    11048: "Armour shard",
    11049: "Artefact",
    11050: "Axe head",
    11051: "Artefact",
    11052: "Helmet fragment",
    11053: "Artefact",
    11054: "Shield fragment",
    11055: "Artefact",
    11056: "Sword fragment",
    11057: "Artefact",
    11058: "Mace",
    11059: "Artefact",
    11060: "Goblin village sphere",
    11061: "Ancient mace",
    11062: "Zanik (slice)",
    11063: "null",
    11064: "null",
    11065: "Bracelet mould",
    11066: "null",
    11067: "null",
    11068: "Gold bracelet",
    11069: "Gold bracelet",
    11070: "null",
    11071: "Sapphire bracelet",
    11072: "Sapphire bracelet",
    11073: "null",
    11074: "Bracelet of clay",
    11075: "null",
    11076: "Emerald bracelet",
    11077: "null",
    11078: "Emerald bracelet",
    11079: "Castle wars bracelet(3)",
    11080: "null",
    11081: "Castle wars bracelet(2)",
    11082: "null",
    11083: "Castle wars bracelet(1)",
    11084: "null",
    11085: "Ruby bracelet",
    11086: "null",
    11087: "Ruby bracelet",
    11088: "Inoculation bracelet",
    11089: "null",
    11090: "Phoenix necklace",
    11091: "null",
    11092: "Diamond bracelet",
    11093: "null",
    11094: "Diamond bracelet",
    11095: "Abyssal bracelet(5)",
    11096: "null",
    11097: "Abyssal bracelet(4)",
    11098: "null",
    11099: "Abyssal bracelet(3)",
    11100: "null",
    11101: "Abyssal bracelet(2)",
    11102: "null",
    11103: "Abyssal bracelet(1)",
    11104: "null",
    11105: "Skills necklace(4)",
    11106: "null",
    11107: "Skills necklace(3)",
    11108: "null",
    11109: "Skills necklace(2)",
    11110: "null",
    11111: "Skills necklace(1)",
    11112: "null",
    11113: "Skills necklace",
    11114: "null",
    11115: "Dragonstone bracelet",
    11116: "null",
    11117: "Dragon bracelet",
    11118: "Combat bracelet(4)",
    11119: "null",
    11120: "Combat bracelet(3)",
    11121: "null",
    11122: "Combat bracelet(2)",
    11123: "null",
    11124: "Combat bracelet(1)",
    11125: "null",
    11126: "Combat bracelet",
    11127: "null",
    11128: "Berserker necklace",
    11129: "null",
    11130: "Onyx bracelet",
    11131: "null",
    11132: "Onyx bracelet",
    11133: "Regen bracelet",
    11134: "null",
    11135: "Null",
    11136: "Karamja gloves 1",
    11137: "Antique lamp",
    11138: "Karamja gloves 2",
    11139: "Antique lamp",
    11140: "Karamja gloves 3",
    11141: "Antique lamp",
    11142: "null",
    11143: "null",
    11144: "null",
    11145: "null",
    11146: "null",
    11147: "null",
    11148: "null",
    11149: "null",
    11150: "null",
    11151: "Dream vial (empty)",
    11152: "Dream vial (water)",
    11153: "Dream vial (herb)",
    11154: "Dream potion",
    11155: "Ground astral rune",
    11156: "Astral rune shards",
    11157: "Dreamy lamp",
    11158: "Cyrisus's chest",
    11159: "Hunter kit",
    11160: "null",
    11161: "null",
    11162: "null",
    11163: "null",
    11164: "Null",
    11165: "Phoenix crossbow",
    11166: "null",
    11167: "Phoenix crossbow",
    11168: "null",
    11169: "Newspaper",
    11170: "null",
    11171: "Newspaper",
    11172: "null",
    11173: "Half certificate",
    11174: "Half certificate",
    11175: "Uncleaned find",
    11176: "Arrowheads",
    11177: "Jewellery",
    11178: "Pottery",
    11179: "Old coin",
    11180: "Ancient coin",
    11181: "Ancient symbol",
    11182: "Old symbol",
    11183: "Old chipped vase",
    11184: "Museum map",
    11185: "Antique lamp",
    11186: "Antique lamp",
    11187: "Antique lamp",
    11188: "Antique lamp",
    11189: "Antique lamp",
    11190: "Digsite pendant (1)",
    11191: "Digsite pendant (2)",
    11192: "Digsite pendant (3)",
    11193: "Digsite pendant (4)",
    11194: "Digsite pendant (5)",
    11195: "Clean necklace",
    11196: "Griffin feather",
    11197: "Miazrqa's pendant",
    11198: "Music sheet",
    11199: "Rupert's helmet",
    11200: "Dwarven helmet",
    11201: "null",
    11202: "Shrinking recipe",
    11203: "To-do list",
    11204: "Shrink-me-quick",
    11205: "Shrunk ogleroot",
    11206: "null",
    11207: "null",
    11208: "null",
    11209: "null",
    11210: "Golden goblin",
    11211: "Magic beans",
    11212: "Dragon arrow",
    11213: "null",
    11214: "null",
    11215: "null",
    11216: "null",
    11217: "Dragon fire arrow",
    11218: "null",
    11219: "null",
    11220: "null",
    11221: "null",
    11222: "Dragon fire arrow (lit)",
    11223: "null",
    11224: "null",
    11225: "null",
    11226: "null",
    11227: "Dragon arrow(p)",
    11228: "Dragon arrow(p+)",
    11229: "Dragon arrow(p++)",
    11230: "Dragon dart",
    11231: "Dragon dart(p)",
    11232: "Dragon dart tip",
    11233: "Dragon dart(p+)",
    11234: "Dragon dart(p++)",
    11235: "Dark bow",
    11236: "null",
    11237: "Dragon arrowtips",
    11238: "Baby impling jar",
    11239: "null",
    11240: "Young impling jar",
    11241: "null",
    11242: "Gourmet impling jar",
    11243: "null",
    11244: "Earth impling jar",
    11245: "null",
    11246: "Essence impling jar",
    11247: "null",
    11248: "Eclectic impling jar",
    11249: "null",
    11250: "Nature impling jar",
    11251: "null",
    11252: "Magpie impling jar",
    11253: "null",
    11254: "Ninja impling jar",
    11255: "null",
    11256: "Dragon impling jar",
    11257: "null",
    11258: "Jar generator",
    11259: "Magic butterfly net",
    11260: "Impling jar",
    11261: "null",
    11262: "Imp repellent",
    11263: "null",
    11264: "Anchovy oil",
    11265: "null",
    11266: "Anchovy paste",
    11267: "Dummy",
    11268: "Dummy",
    11269: "Dummy",
    11270: "null",
    11271: "Dummy",
    11272: "null",
    11273: "Impling scroll",
    11274: "Null",
    11275: "null",
    11276: "null",
    11277: "Null",
    11278: "Null",
    11279: "Elvarg's head",
    11280: "Cavalier mask",
    11281: "null",
    11282: "Beret mask",
    11283: "Dragonfire shield",
    11284: "Dragonfire shield",
    11285: "null",
    11286: "Draconic visage",
    11287: "null",
    11288: "null",
    11289: "null",
    11290: "null",
    11291: "null",
    11292: "null",
    11293: "null",
    11294: "null",
    11295: "null",
    11296: "null",
    11297: "null",
    11298: "null",
    11299: "null",
    11300: "null",
    11301: "null",
    11302: "null",
    11303: "null",
    11304: "null",
    11305: "null",
    11306: "null",
    11307: "null",
    11308: "null",
    11309: "null",
    11310: "null",
    11311: "null",
    11312: "null",
    11313: "null",
    11314: "null",
    11315: "null",
    11316: "null",
    11317: "null",
    11318: "null",
    11319: "null",
    11320: "null",
    11321: "null",
    11322: "null",
    11323: "Barbarian rod",
    11324: "Roe",
    11325: "null",
    11326: "Caviar",
    11327: "null",
    11328: "Leaping trout",
    11329: "null",
    11330: "Leaping salmon",
    11331: "null",
    11332: "Leaping sturgeon",
    11333: "null",
    11334: "Fish offcuts",
    11335: "Dragon full helm",
    11336: "null",
    11337: "Mangled bones",
    11338: "Chewed bones",
    11339: "My notes",
    11340: "Barbarian skills",
    11341: "Ancient page",
    11342: "Ancient page",
    11343: "Ancient page",
    11344: "Ancient page",
    11345: "Ancient page",
    11346: "Ancient page",
    11347: "Ancient page",
    11348: "Ancient page",
    11349: "Ancient page",
    11350: "Ancient page",
    11351: "Ancient page",
    11352: "Ancient page",
    11353: "Ancient page",
    11354: "Ancient page",
    11355: "Ancient page",
    11356: "Ancient page",
    11357: "Ancient page",
    11358: "Ancient page",
    11359: "Ancient page",
    11360: "Ancient page",
    11361: "Ancient page",
    11362: "Ancient page",
    11363: "Ancient page",
    11364: "Ancient page",
    11365: "Ancient page",
    11366: "Ancient page",
    11367: "Bronze hasta",
    11368: "null",
    11369: "Iron hasta",
    11370: "null",
    11371: "Steel hasta",
    11372: "null",
    11373: "Mithril hasta",
    11374: "null",
    11375: "Adamant hasta",
    11376: "null",
    11377: "Rune hasta",
    11378: "null",
    11379: "Bronze hasta(p)",
    11380: "null",
    11381: "Bronze hasta(kp)",
    11382: "Bronze hasta(p+)",
    11383: "null",
    11384: "Bronze hasta(p++)",
    11385: "null",
    11386: "Iron hasta(p)",
    11387: "null",
    11388: "Iron hasta(kp)",
    11389: "Iron hasta(p+)",
    11390: "null",
    11391: "Iron hasta(p++)",
    11392: "null",
    11393: "Steel hasta(p)",
    11394: "null",
    11395: "Steel hasta(kp)",
    11396: "Steel hasta(p+)",
    11397: "null",
    11398: "Steel hasta(p++)",
    11399: "null",
    11400: "Mithril hasta(p)",
    11401: "null",
    11402: "Mithril hasta(kp)",
    11403: "Mithril hasta(p+)",
    11404: "null",
    11405: "Mithril hasta(p++)",
    11406: "null",
    11407: "Adamant hasta(p)",
    11408: "null",
    11409: "Adamant hasta(kp)",
    11410: "Adamant hasta(p+)",
    11411: "null",
    11412: "Adamant hasta(p++)",
    11413: "null",
    11414: "Rune hasta(p)",
    11415: "null",
    11416: "Rune hasta(kp)",
    11417: "Rune hasta(p+)",
    11418: "null",
    11419: "Rune hasta(p++)",
    11420: "null",
    11421: "null",
    11422: "null",
    11423: "null",
    11424: "null",
    11425: "null",
    11426: "null",
    11427: "Fish vial",
    11428: "Fish vial",
    11429: "Attack mix(2)",
    11430: "null",
    11431: "Attack mix(1)",
    11432: "null",
    11433: "Antipoison mix(2)",
    11434: "null",
    11435: "Antipoison mix(1)",
    11436: "null",
    11437: "Relicym's mix(2)",
    11438: "null",
    11439: "Relicym's mix(1)",
    11440: "null",
    11441: "Strength mix(1)",
    11442: "null",
    11443: "Strength mix(2)",
    11444: "null",
    11445: "Combat mix(2)",
    11446: "null",
    11447: "Combat mix(1)",
    11448: "null",
    11449: "Restore mix(2)",
    11450: "null",
    11451: "Restore mix(1)",
    11452: "null",
    11453: "Energy mix(2)",
    11454: "null",
    11455: "Energy mix(1)",
    11456: "null",
    11457: "Defence mix(2)",
    11458: "null",
    11459: "Defence mix(1)",
    11460: "null",
    11461: "Agility mix(2)",
    11462: "null",
    11463: "Agility mix(1)",
    11464: "null",
    11465: "Prayer mix(2)",
    11466: "null",
    11467: "Prayer mix(1)",
    11468: "null",
    11469: "Superattack mix(2)",
    11470: "null",
    11471: "Superattack mix(1)",
    11472: "null",
    11473: "Anti-poison supermix(2)",
    11474: "null",
    11475: "Anti-poison supermix(1)",
    11476: "null",
    11477: "Fishing mix(2)",
    11478: "null",
    11479: "Fishing mix(1)",
    11480: "null",
    11481: "Super energy mix(2)",
    11482: "null",
    11483: "Super energy mix(1)",
    11484: "null",
    11485: "Super str. mix(2)",
    11486: "null",
    11487: "Super str. mix(1)",
    11488: "null",
    11489: "Magic essence mix(2)",
    11490: "null",
    11491: "Magic essence mix(1)",
    11492: "null",
    11493: "Super restore mix(2)",
    11494: "null",
    11495: "Super restore mix(1)",
    11496: "null",
    11497: "Super def. mix(2)",
    11498: "null",
    11499: "Super def. mix(1)",
    11500: "null",
    11501: "Antidote+ mix(2)",
    11502: "null",
    11503: "Antidote+ mix(1)",
    11504: "null",
    11505: "Antifire mix(2)",
    11506: "null",
    11507: "Antifire mix(1)",
    11508: "null",
    11509: "Ranging mix(2)",
    11510: "null",
    11511: "Ranging mix(1)",
    11512: "null",
    11513: "Magic mix(2)",
    11514: "null",
    11515: "Magic mix(1)",
    11516: "null",
    11517: "Hunting mix(2)",
    11518: "null",
    11519: "Hunting mix(1)",
    11520: "null",
    11521: "Zamorak mix(2)",
    11522: "null",
    11523: "Zamorak mix(1)",
    11524: "null",
    11525: "Feather",
    11526: "null",
    11527: "null",
    11528: "null",
    11529: "null",
    11530: "null",
    11531: "null",
    11532: "null",
    11533: "null",
    11534: "null",
    11535: "null",
    11536: "null",
    11537: "null",
    11538: "null",
    11539: "null",
    11540: "null",
    11541: "null",
    11542: "null",
    11543: "null",
    11544: "null",
    11545: "null",
    11546: "null",
    11547: "null",
    11548: "null",
    11549: "null",
    11550: "null",
    11551: "null",
    11552: "null",
    11553: "null",
    11554: "null",
    11555: "null",
    11556: "null",
    11557: "null",
    11558: "null",
    11559: "null",
    11560: "null",
    11561: "null",
    11562: "null",
    11563: "null",
    11564: "null",
    11565: "null",
    11566: "null",
    11567: "null",
    11568: "null",
    11569: "null",
    11570: "null",
    11571: "null",
    11572: "null",
    11573: "null",
    11574: "null",
    11575: "null",
    11576: "null",
    11577: "null",
    11578: "null",
    11579: "null",
    11580: "null",
    11581: "null",
    11582: "null",
    11583: "null",
    11584: "null",
    11585: "null",
    11586: "null",
    11587: "null",
    11588: "null",
    11589: "null",
    11590: "null",
    11591: "null",
    11592: "null",
    11593: "null",
    11594: "null",
    11595: "null",
    11596: "null",
    11597: "null",
    11598: "null",
    11599: "null",
    11600: "null",
    11601: "null",
    11602: "null",
    11603: "null",
    11604: "null",
    11605: "null",
    11606: "null",
    11607: "null",
    11608: "null",
    11609: "null",
    11610: "null",
    11611: "null",
    11612: "null",
    11613: "null",
    11614: "null",
    11615: "null",
    11616: "null",
    11617: "null",
    11618: "null",
    11619: "null",
    11620: "null",
    11621: "null",
    11622: "null",
    11623: "null",
    11624: "null",
    11625: "null",
    11626: "null",
    11627: "null",
    11628: "Ava's device",
    11629: "null",
    11630: "null",
    11631: "null",
    11632: "null",
    11633: "null",
    11634: "null",
    11635: "null",
    11636: "null",
    11637: "null",
    11638: "null",
    11639: "null",
    11640: "Book of knowledge",
    11641: "null",
    11642: "null",
    11643: "null",
    11644: "null",
    11645: "null",
    11646: "null",
    11647: "null",
    11648: "null",
    11649: "null",
    11650: "null",
    11651: "null",
    11652: "null",
    11653: "null",
    11654: "null",
    11655: "null",
    11656: "Glassblowing book",
    11657: "null",
    11658: "null",
    11659: "null",
    11660: "null",
    11661: "null",
    11662: "null",
    11663: "Void mage helm",
    11664: "Void ranger helm",
    11665: "Void melee helm",
    11666: "Void seal(8)",
    11667: "Void seal(7)",
    11668: "Void seal(6)",
    11669: "Void seal(5)",
    11670: "Void seal(4)",
    11671: "Void seal(3)",
    11672: "Void seal(2)",
    11673: "Void seal(1)",
    11674: "Null",
    11675: "Null",
    11676: "Null",
    11677: "Explorer's notes",
    11678: "Black knight helm",
    11679: "Antique lamp",
    11680: "Address form",
    11681: "Scrap paper",
    11682: "Hair clip",
    11683: "null",
    11684: "null",
    11685: "null",
    11686: "Fire rune",
    11687: "Water rune",
    11688: "Air rune",
    11689: "Earth rune",
    11690: "Mind rune",
    11691: "Body rune",
    11692: "Death rune",
    11693: "Nature rune",
    11694: "Chaos rune",
    11695: "Law rune",
    11696: "Cosmic rune",
    11697: "Blood rune",
    11698: "Soul rune",
    11699: "Astral rune",
    11700: "Bronze arrow",
    11701: "Iron arrow",
    11702: "Steel arrow",
    11703: "Mithril arrow",
    11704: "Raw pheasant",
    11705: "Beach boxing gloves",
    11706: "Beach boxing gloves",
    11707: "Cursed goblin hammer",
    11708: "Cursed goblin bow",
    11709: "Cursed goblin staff",
    11710: "Anti-dragon shield (nz)",
    11711: "Magic secateurs (nz)",
    11712: "Chaos rune (nz)",
    11713: "Death rune (nz)",
    11714: "Blood rune (nz)",
    11715: "Air rune (nz)",
    11716: "Water rune (nz)",
    11717: "Earth rune (nz)",
    11718: "Fire rune (nz)",
    11719: "Rune pickaxe (nz)",
    11720: "Mithril pickaxe (nz)",
    11721: "Iron pickaxe (nz)",
    11722: "Super ranging (4)",
    11723: "Super ranging (3)",
    11724: "Super ranging (2)",
    11725: "Super ranging (1)",
    11726: "Super magic potion (4)",
    11727: "Super magic potion (3)",
    11728: "Super magic potion (2)",
    11729: "Super magic potion (1)",
    11730: "Overload (4)",
    11731: "Overload (3)",
    11732: "Overload (2)",
    11733: "Overload (1)",
    11734: "Absorption (4)",
    11735: "Absorption (3)",
    11736: "Absorption (2)",
    11737: "Absorption (1)",
    11738: "Herb box",
    11739: "Open herb box",
    11740: "Scroll of redirection",
    11741: "Rimmington teleport",
    11742: "Taverley teleport",
    11743: "Pollnivneach teleport",
    11744: "Rellekka teleport",
    11745: "Brimhaven teleport",
    11746: "Yanille teleport",
    11747: "Trollheim teleport",
    11748: "New crystal bow (i)",
    11749: "Crystal bow full (i)",
    11750: "Crystal bow 9/10 (i)",
    11751: "Crystal bow 8/10 (i)",
    11752: "Crystal bow 7/10 (i)",
    11753: "Crystal bow 6/10 (i)",
    11754: "Crystal bow 5/10 (i)",
    11755: "Crystal bow 4/10 (i)",
    11756: "Crystal bow 3/10 (i)",
    11757: "Crystal bow 2/10 (i)",
    11758: "Crystal bow 1/10 (i)",
    11759: "New crystal shield (i)",
    11760: "Crystal shield full (i)",
    11761: "Crystal shield 9/10 (i)",
    11762: "Crystal shield 8/10 (i)",
    11763: "Crystal shield 7/10 (i)",
    11764: "Crystal shield 6/10 (i)",
    11765: "Crystal shield 5/10 (i)",
    11766: "Crystal shield 4/10 (i)",
    11767: "Crystal shield 3/10 (i)",
    11768: "Crystal shield 2/10 (i)",
    11769: "Crystal shield 1/10 (i)",
    11770: "Seers ring (i)",
    11771: "Archers ring (i)",
    11772: "Warrior ring (i)",
    11773: "Berserker ring (i)",
    11774: "Black mask (10) (i)",
    11775: "Black mask (9) (i)",
    11776: "Black mask (8) (i)",
    11777: "Black mask (7) (i)",
    11778: "Black mask (6) (i)",
    11779: "Black mask (5) (i)",
    11780: "Black mask (4) (i)",
    11781: "Black mask (3) (i)",
    11782: "Black mask (2) (i)",
    11783: "Black mask (1) (i)",
    11784: "Black mask (i)",
    11785: "Armadyl crossbow",
    11786: "null",
    11787: "Steam battlestaff",
    11788: "null",
    11789: "Mystic steam staff",
    11790: "null",
    11791: "Staff of the dead",
    11792: "null",
    11793: "Agility jump",
    11794: "Godsword shards 1 & 2",
    11795: "null",
    11796: "Godsword shards 1 & 3",
    11797: "null",
    11798: "Godsword blade",
    11799: "null",
    11800: "Godsword shards 2 & 3",
    11801: "null",
    11802: "Armadyl godsword",
    11803: "null",
    11804: "Bandos godsword",
    11805: "null",
    11806: "Saradomin godsword",
    11807: "null",
    11808: "Zamorak godsword",
    11809: "null",
    11810: "Armadyl hilt",
    11811: "null",
    11812: "Bandos hilt",
    11813: "null",
    11814: "Saradomin hilt",
    11815: "null",
    11816: "Zamorak hilt",
    11817: "null",
    11818: "Godsword shard 1",
    11819: "null",
    11820: "Godsword shard 2",
    11821: "null",
    11822: "Godsword shard 3",
    11823: "null",
    11824: "Zamorakian spear",
    11825: "null",
    11826: "Armadyl helmet",
    11827: "null",
    11828: "Armadyl chestplate",
    11829: "null",
    11830: "Armadyl chainskirt",
    11831: "null",
    11832: "Bandos chestplate",
    11833: "null",
    11834: "Bandos tassets",
    11835: "null",
    11836: "Bandos boots",
    11837: "null",
    11838: "Saradomin sword",
    11839: "null",
    11840: "Dragon boots",
    11841: "null",
    11842: "Knight's notes",
    11843: "Knight's notes",
    11844: "null",
    11845: "null",
    11846: "null",
    11847: "Black h'ween mask",
    11848: "Rancid turkey",
    11849: "Mark of grace",
    11850: "Graceful hood",
    11851: "Graceful hood",
    11852: "Graceful cape",
    11853: "Graceful cape",
    11854: "Graceful top",
    11855: "Graceful top",
    11856: "Graceful legs",
    11857: "Graceful legs",
    11858: "Graceful gloves",
    11859: "Graceful gloves",
    11860: "Graceful boots",
    11861: "Graceful boots",
    11862: "Black partyhat",
    11863: "Rainbow partyhat",
    11864: "Slayer helmet",
    11865: "Slayer helmet (i)",
    11866: "Slayer ring (8)",
    11867: "Slayer ring (7)",
    11868: "Slayer ring (6)",
    11869: "Slayer ring (5)",
    11870: "Slayer ring (4)",
    11871: "Slayer ring (3)",
    11872: "Slayer ring (2)",
    11873: "Slayer ring (1)",
    11874: "Broad arrowheads",
    11875: "Broad bolts",
    11876: "Unfinished broad bolts",
    11877: "Empty vial pack",
    11878: "null",
    11879: "Water-filled vial pack",
    11880: "null",
    11881: "Feather pack",
    11882: "null",
    11883: "Bait pack",
    11884: "null",
    11885: "Broad arrowhead pack",
    11886: "null",
    11887: "Unfinished broad bolt pack",
    11888: "null",
    11889: "Zamorakian hasta",
    11890: "null",
    11891: "Saradomin banner",
    11892: "Zamorak banner",
    11893: "Decorative armour",
    11894: "Decorative armour",
    11895: "Decorative armour",
    11896: "Decorative armour",
    11897: "Decorative armour",
    11898: "Decorative armour",
    11899: "Decorative armour",
    11900: "Decorative armour",
    11901: "Decorative armour",
    11902: "Leaf-bladed sword",
    11903: "null",
    11904: "Entomologist's diary",
    11905: "Trident of the seas (full)",
    11906: "null",
    11907: "Trident of the seas",
    11908: "Uncharged trident",
    11909: "null",
    11910: "Chocolate strawberry",
    11911: "null",
    11912: "Box of chocolate strawberries",
    11913: "null",
    11914: "Box of chocolate strawberries",
    11915: "null",
    11916: "Slice of birthday cake",
    11917: "Slice of birthday cake",
    11918: "Birthday present",
    11919: "Cow mask",
    11920: "Dragon pickaxe",
    11921: "null",
    11922: "Lava dragon bonemeal",
    11923: "Broken pickaxe",
    11924: "Malediction ward",
    11925: "null",
    11926: "Odium ward",
    11927: "null",
    11928: "Odium shard 1",
    11929: "Odium shard 2",
    11930: "Odium shard 3",
    11931: "Malediction shard 1",
    11932: "Malediction shard 2",
    11933: "Malediction shard 3",
    11934: "Raw dark crab",
    11935: "null",
    11936: "Dark crab",
    11937: "null",
    11938: "Burnt dark crab",
    11939: "null",
    11940: "Dark fishing bait",
    11941: "Looting bag",
    11942: "Ecumenical key",
    11943: "Lava dragon bones",
    11944: "null",
    11945: "null",
    11946: "null",
    11947: "null",
    11948: "null",
    11949: "null",
    11950: "null",
    11951: "Extended antifire(4)",
    11952: "null",
    11953: "Extended antifire(3)",
    11954: "null",
    11955: "Extended antifire(2)",
    11956: "null",
    11957: "Extended antifire(1)",
    11958: "null",
    11959: "Black chinchompa",
    11960: "Extended antifire mix(2)",
    11961: "null",
    11962: "Extended antifire mix(1)",
    11963: "null",
    11964: "Amulet of glory (t6)",
    11965: "null",
    11966: "Amulet of glory (t5)",
    11967: "null",
    11968: "Skills necklace(6)",
    11969: "null",
    11970: "Skills necklace(5)",
    11971: "null",
    11972: "Combat bracelet(6)",
    11973: "null",
    11974: "Combat bracelet(5)",
    11975: "null",
    11976: "Amulet of glory(5)",
    11977: "null",
    11978: "Amulet of glory(6)",
    11979: "null",
    11980: "Ring of wealth (5)",
    11981: "null",
    11982: "Ring of wealth (4)",
    11983: "null",
    11984: "Ring of wealth (3)",
    11985: "null",
    11986: "Ring of wealth (2)",
    11987: "null",
    11988: "Ring of wealth (1)",
    11989: "null",
    11990: "Fedora",
    11991: "null",
    11992: "Lava scale",
    11993: "null",
    11994: "Lava scale shard",
    11995: "Pet chaos elemental",
    11996: "Holiday tool",
    11997: "Easter",
    11998: "Smoke battlestaff",
    11999: "null",
    12000: "Mystic smoke staff",
    12001: "null",
    12002: "Occult necklace",
    12003: "null",
    12004: "Kraken tentacle",
    12005: "null",
    12006: "Abyssal tentacle",
    12007: "Jar of dirt",
    12008: "null",
    12009: "Soft clay pack",
    12010: "Soft clay pack",
    12011: "Pay-dirt",
    12012: "Golden nugget",
    12013: "Prospector helmet",
    12014: "Prospector jacket",
    12015: "Prospector legs",
    12016: "Prospector boots",
    12017: "Salve amulet(i)",
    12018: "Salve amulet(ei)",
    12019: "Coal bag",
    12020: "Gem bag",
    12021: "Clue scroll (medium)",
    12022: "Casket (medium)",
    12023: "Clue scroll (medium)",
    12024: "Casket (medium)",
    12025: "Clue scroll (medium)",
    12026: "Casket (medium)",
    12027: "Clue scroll (medium)",
    12028: "Casket (medium)",
    12029: "Clue scroll (medium)",
    12030: "Casket (medium)",
    12031: "Clue scroll (medium)",
    12032: "Casket (medium)",
    12033: "Clue scroll (medium)",
    12034: "Casket (medium)",
    12035: "Clue scroll (medium)",
    12036: "Casket (medium)",
    12037: "Clue scroll (medium)",
    12038: "Casket (medium)",
    12039: "Clue scroll (medium)",
    12040: "Casket (medium)",
    12041: "Clue scroll (medium)",
    12042: "Casket (medium)",
    12043: "Clue scroll (medium)",
    12044: "Casket (medium)",
    12045: "Clue scroll (medium)",
    12046: "Casket (medium)",
    12047: "Clue scroll (medium)",
    12048: "Casket (medium)",
    12049: "Clue scroll (medium)",
    12050: "Casket (medium)",
    12051: "Clue scroll (medium)",
    12052: "Casket (medium)",
    12053: "Clue scroll (medium)",
    12054: "Casket (medium)",
    12055: "Clue scroll (medium)",
    12056: "Challenge scroll (medium)",
    12057: "Clue scroll (medium)",
    12058: "Challenge scroll (medium)",
    12059: "Clue scroll (medium)",
    12060: "Challenge scroll (medium)",
    12061: "Clue scroll (medium)",
    12062: "Challenge scroll (medium)",
    12063: "Clue scroll (medium)",
    12064: "Challenge scroll (medium)",
    12065: "Clue scroll (medium)",
    12066: "Challenge scroll (medium)",
    12067: "Clue scroll (medium)",
    12068: "Challenge scroll (medium)",
    12069: "Clue scroll (medium)",
    12070: "Challenge scroll (medium)",
    12071: "Clue scroll (medium)",
    12072: "Challenge scroll (medium)",
    12073: "Clue scroll (elite)",
    12074: "Clue scroll (elite)",
    12075: "Clue scroll (elite)",
    12076: "Clue scroll (elite)",
    12077: "Clue scroll (elite)",
    12078: "Clue scroll (elite)",
    12079: "Clue scroll (elite)",
    12080: "Clue scroll (elite)",
    12081: "Clue scroll (elite)",
    12082: "Clue scroll (elite)",
    12083: "Clue scroll (elite)",
    12084: "Casket (elite)",
    12085: "Clue scroll (elite)",
    12086: "Clue scroll (elite)",
    12087: "Clue scroll (elite)",
    12088: "Clue scroll (elite)",
    12089: "Clue scroll (elite)",
    12090: "Clue scroll (elite)",
    12091: "Clue scroll (elite)",
    12092: "Clue scroll (elite)",
    12093: "Clue scroll (elite)",
    12094: "Clue scroll (elite)",
    12095: "Clue scroll (elite)",
    12096: "Clue scroll (elite)",
    12097: "Clue scroll (elite)",
    12098: "Clue scroll (elite)",
    12099: "Clue scroll (elite)",
    12100: "Clue scroll (elite)",
    12101: "Clue scroll (elite)",
    12102: "Clue scroll (elite)",
    12103: "Clue scroll (elite)",
    12104: "Clue scroll (elite)",
    12105: "Clue scroll (elite)",
    12106: "Clue scroll (elite)",
    12107: "Clue scroll (elite)",
    12108: "Clue scroll (elite)",
    12109: "Clue scroll (elite)",
    12110: "Clue scroll (elite)",
    12111: "Clue scroll (elite)",
    12112: "Casket (elite)",
    12113: "Clue scroll (elite)",
    12114: "Clue scroll (elite)",
    12115: "Clue scroll (elite)",
    12116: "Clue scroll (elite)",
    12117: "Clue scroll (elite)",
    12118: "Clue scroll (elite)",
    12119: "Clue scroll (elite)",
    12120: "Clue scroll (elite)",
    12121: "Clue scroll (elite)",
    12122: "Clue scroll (elite)",
    12123: "Clue scroll (elite)",
    12124: "Clue scroll (elite)",
    12125: "Clue scroll (elite)",
    12126: "Clue scroll (elite)",
    12127: "Clue scroll (elite)",
    12128: "Challenge scroll (elite)",
    12129: "Casket (elite)",
    12130: "Clue scroll (elite)",
    12131: "Casket (elite)",
    12132: "Clue scroll (elite)",
    12133: "Clue scroll (elite)",
    12134: "Clue scroll (elite)",
    12135: "Clue scroll (elite)",
    12136: "Clue scroll (elite)",
    12137: "Clue scroll (elite)",
    12138: "Clue scroll (elite)",
    12139: "Challenge scroll (elite)",
    12140: "Clue scroll (elite)",
    12141: "Clue scroll (elite)",
    12142: "Clue scroll (elite)",
    12143: "Clue scroll (elite)",
    12144: "Clue scroll (elite)",
    12145: "Clue scroll (elite)",
    12146: "Clue scroll (elite)",
    12147: "Clue scroll (elite)",
    12148: "Clue scroll (elite)",
    12149: "Clue scroll (elite)",
    12150: "Clue scroll (elite)",
    12151: "Clue scroll (elite)",
    12152: "Clue scroll (elite)",
    12153: "Clue scroll (elite)",
    12154: "Clue scroll (elite)",
    12155: "Clue scroll (elite)",
    12156: "Clue scroll (elite)",
    12157: "Clue scroll (elite)",
    12158: "Clue scroll (elite)",
    12159: "Clue scroll (elite)",
    12160: "Casket (elite)",
    12161: "Puzzle box (elite)",
    12162: "Clue scroll (easy)",
    12163: "Casket (easy)",
    12164: "Clue scroll (easy)",
    12165: "Casket (easy)",
    12166: "Clue scroll (easy)",
    12167: "Clue scroll (easy)",
    12168: "Clue scroll (easy)",
    12169: "Clue scroll (easy)",
    12170: "Clue scroll (easy)",
    12171: "Casket (easy)",
    12172: "Clue scroll (easy)",
    12173: "Clue scroll (easy)",
    12174: "Clue scroll (easy)",
    12175: "Clue scroll (easy)",
    12176: "Clue scroll (easy)",
    12177: "Clue scroll (easy)",
    12178: "Clue scroll (easy)",
    12179: "Clue scroll (easy)",
    12180: "Casket (easy)",
    12181: "Clue scroll (easy)",
    12182: "Clue scroll (easy)",
    12183: "Clue scroll (easy)",
    12184: "Clue scroll (easy)",
    12185: "Clue scroll (easy)",
    12186: "Clue scroll (easy)",
    12187: "Clue scroll (easy)",
    12188: "Clue scroll (easy)",
    12189: "Clue scroll (easy)",
    12190: "Clue scroll (easy)",
    12191: "Clue scroll (easy)",
    12192: "Clue scroll (easy)",
    12193: "Ancient robe top",
    12194: "null",
    12195: "Ancient robe legs",
    12196: "null",
    12197: "Ancient cloak",
    12198: "null",
    12199: "Ancient crozier",
    12200: "null",
    12201: "Ancient stole",
    12202: "null",
    12203: "Ancient mitre",
    12204: "null",
    12205: "Bronze platebody (g)",
    12206: "null",
    12207: "Bronze platelegs (g)",
    12208: "null",
    12209: "Bronze plateskirt (g)",
    12210: "null",
    12211: "Bronze full helm (g)",
    12212: "null",
    12213: "Bronze kiteshield (g)",
    12214: "null",
    12215: "Bronze platebody (t)",
    12216: "null",
    12217: "Bronze platelegs (t)",
    12218: "null",
    12219: "Bronze plateskirt (t)",
    12220: "null",
    12221: "Bronze full helm (t)",
    12222: "null",
    12223: "Bronze kiteshield (t)",
    12224: "null",
    12225: "Iron platebody (t)",
    12226: "null",
    12227: "Iron platelegs (t)",
    12228: "null",
    12229: "Iron plateskirt (t)",
    12230: "null",
    12231: "Iron full helm (t)",
    12232: "null",
    12233: "Iron kiteshield (t)",
    12234: "null",
    12235: "Iron platebody (g)",
    12236: "null",
    12237: "Iron platelegs (g)",
    12238: "null",
    12239: "Iron plateskirt (g)",
    12240: "null",
    12241: "Iron full helm (g)",
    12242: "null",
    12243: "Iron kiteshield (g)",
    12244: "null",
    12245: "Beanie",
    12246: "null",
    12247: "Red beret",
    12248: "null",
    12249: "Imp mask",
    12250: "null",
    12251: "Goblin mask",
    12252: "null",
    12253: "Armadyl robe top",
    12254: "null",
    12255: "Armadyl robe legs",
    12256: "null",
    12257: "Armadyl stole",
    12258: "null",
    12259: "Armadyl mitre",
    12260: "null",
    12261: "Armadyl cloak",
    12262: "null",
    12263: "Armadyl crozier",
    12264: "null",
    12265: "Bandos robe top",
    12266: "null",
    12267: "Bandos robe legs",
    12268: "null",
    12269: "Bandos stole",
    12270: "null",
    12271: "Bandos mitre",
    12272: "null",
    12273: "Bandos cloak",
    12274: "null",
    12275: "Bandos crozier",
    12276: "null",
    12277: "Mithril platebody (g)",
    12278: "null",
    12279: "Mithril platelegs (g)",
    12280: "null",
    12281: "Mithril kiteshield (g)",
    12282: "null",
    12283: "Mithril full helm (g)",
    12284: "null",
    12285: "Mithril plateskirt (g)",
    12286: "null",
    12287: "Mithril platebody (t)",
    12288: "null",
    12289: "Mithril platelegs (t)",
    12290: "null",
    12291: "Mithril kiteshield (t)",
    12292: "null",
    12293: "Mithril full helm (t)",
    12294: "null",
    12295: "Mithril plateskirt (t)",
    12296: "null",
    12297: "Black pickaxe",
    12298: "null",
    12299: "White headband",
    12300: "null",
    12301: "Blue headband",
    12302: "null",
    12303: "Gold headband",
    12304: "null",
    12305: "Pink headband",
    12306: "null",
    12307: "Green headband",
    12308: "null",
    12309: "Pink boater",
    12310: "null",
    12311: "Purple boater",
    12312: "null",
    12313: "White boater",
    12314: "null",
    12315: "Pink elegant shirt",
    12316: "null",
    12317: "Pink elegant legs",
    12318: "null",
    12319: "Crier hat",
    12320: "null",
    12321: "White cavalier",
    12322: "null",
    12323: "Red cavalier",
    12324: "null",
    12325: "Navy cavalier",
    12326: "null",
    12327: "Red d'hide body (g)",
    12328: "null",
    12329: "Red d'hide chaps (g)",
    12330: "null",
    12331: "Red d'hide body (t)",
    12332: "null",
    12333: "Red d'hide chaps (t)",
    12334: "null",
    12335: "Briefcase",
    12336: "null",
    12337: "Sagacious spectacles",
    12338: "null",
    12339: "Pink elegant blouse",
    12340: "null",
    12341: "Pink elegant skirt",
    12342: "null",
    12343: "Gold elegant blouse",
    12344: "null",
    12345: "Gold elegant skirt",
    12346: "null",
    12347: "Gold elegant shirt",
    12348: "null",
    12349: "Gold elegant legs",
    12350: "null",
    12351: "Musketeer hat",
    12352: "null",
    12353: "Monocle",
    12354: "null",
    12355: "Big pirate hat",
    12356: "null",
    12357: "Katana",
    12358: "null",
    12359: "Leprechaun hat",
    12360: "null",
    12361: "Cat mask",
    12362: "null",
    12363: "Bronze dragon mask",
    12364: "null",
    12365: "Iron dragon mask",
    12366: "null",
    12367: "Steel dragon mask",
    12368: "null",
    12369: "Mithril dragon mask",
    12370: "null",
    12371: "Lava dragon mask",
    12372: "null",
    12373: "Dragon cane",
    12374: "null",
    12375: "Black cane",
    12376: "null",
    12377: "Adamant cane",
    12378: "null",
    12379: "Rune cane",
    12380: "null",
    12381: "Black d'hide body (g)",
    12382: "null",
    12383: "Black d'hide chaps (g)",
    12384: "null",
    12385: "Black d'hide body (t)",
    12386: "null",
    12387: "Black d'hide chaps (t)",
    12388: "null",
    12389: "Gilded scimitar",
    12390: "null",
    12391: "Gilded boots",
    12392: "null",
    12393: "Royal gown top",
    12394: "null",
    12395: "Royal gown bottom",
    12396: "null",
    12397: "Royal crown",
    12398: "null",
    12399: "Partyhat & specs",
    12400: "null",
    12401: "null",
    12402: "Nardah teleport",
    12403: "Digsite teleport",
    12404: "Feldip hills teleport",
    12405: "Lunar isle teleport",
    12406: "Mort'ton teleport",
    12407: "Pest control teleport",
    12408: "Piscatoris teleport",
    12409: "Tai bwo wannai teleport",
    12410: "Iorwerth camp teleport",
    12411: "Mos le'harmless teleport",
    12412: "Pirate hat & patch",
    12413: "null",
    12414: "Dragon chainbody (g)",
    12415: "Dragon platelegs (g)",
    12416: "Dragon plateskirt (g)",
    12417: "Dragon full helm (g)",
    12418: "Dragon sq shield (g)",
    12419: "Light infinity hat",
    12420: "Light infinity top",
    12421: "Light infinity bottoms",
    12422: "3rd age wand",
    12423: "null",
    12424: "3rd age bow",
    12425: "null",
    12426: "3rd age longsword",
    12427: "null",
    12428: "Penguin mask",
    12429: "null",
    12430: "Afro",
    12431: "null",
    12432: "Top hat",
    12433: "null",
    12434: "Top hat & monocle",
    12435: "null",
    12436: "Amulet of fury (or)",
    12437: "3rd age cloak",
    12438: "null",
    12439: "Royal sceptre",
    12440: "null",
    12441: "Musketeer tabard",
    12442: "null",
    12443: "Musketeer pants",
    12444: "null",
    12445: "Black skirt (g)",
    12446: "null",
    12447: "Black skirt (t)",
    12448: "null",
    12449: "Black wizard robe (g)",
    12450: "null",
    12451: "Black wizard robe (t)",
    12452: "null",
    12453: "Black wizard hat (g)",
    12454: "null",
    12455: "Black wizard hat (t)",
    12456: "null",
    12457: "Dark infinity hat",
    12458: "Dark infinity top",
    12459: "Dark infinity bottoms",
    12460: "Ancient platebody",
    12461: "null",
    12462: "Ancient platelegs",
    12463: "null",
    12464: "Ancient plateskirt",
    12465: "null",
    12466: "Ancient full helm",
    12467: "null",
    12468: "Ancient kiteshield",
    12469: "null",
    12470: "Armadyl platebody",
    12471: "null",
    12472: "Armadyl platelegs",
    12473: "null",
    12474: "Armadyl plateskirt",
    12475: "null",
    12476: "Armadyl full helm",
    12477: "null",
    12478: "Armadyl kiteshield",
    12479: "null",
    12480: "Bandos platebody",
    12481: "null",
    12482: "Bandos platelegs",
    12483: "null",
    12484: "Bandos plateskirt",
    12485: "null",
    12486: "Bandos full helm",
    12487: "null",
    12488: "Bandos kiteshield",
    12489: "null",
    12490: "Ancient bracers",
    12491: "null",
    12492: "Ancient d'hide body",
    12493: "null",
    12494: "Ancient chaps",
    12495: "null",
    12496: "Ancient coif",
    12497: "null",
    12498: "Bandos bracers",
    12499: "null",
    12500: "Bandos d'hide body",
    12501: "null",
    12502: "Bandos chaps",
    12503: "null",
    12504: "Bandos coif",
    12505: "null",
    12506: "Armadyl bracers",
    12507: "null",
    12508: "Armadyl d'hide body",
    12509: "null",
    12510: "Armadyl chaps",
    12511: "null",
    12512: "Armadyl coif",
    12513: "null",
    12514: "Explorer backpack",
    12515: "null",
    12516: "Pith helmet",
    12517: "null",
    12518: "Green dragon mask",
    12519: "null",
    12520: "Blue dragon mask",
    12521: "null",
    12522: "Red dragon mask",
    12523: "null",
    12524: "Black dragon mask",
    12525: "null",
    12526: "Fury ornament kit",
    12527: "null",
    12528: "Dark infinity colour kit",
    12529: "null",
    12530: "Light infinity colour kit",
    12531: "null",
    12532: "Dragon sq shield ornament kit",
    12533: "null",
    12534: "Dragon chainbody ornament kit",
    12535: "null",
    12536: "Dragon legs/skirt ornament kit",
    12537: "null",
    12538: "Dragon full helm ornament kit",
    12539: "null",
    12540: "Deerstalker",
    12541: "null",
    12542: "Clue scroll (hard)",
    12543: "Casket (hard)",
    12544: "Clue scroll (hard)",
    12545: "Casket (hard)",
    12546: "Clue scroll (hard)",
    12547: "Casket (hard)",
    12548: "Clue scroll (hard)",
    12549: "Casket (hard)",
    12550: "Clue scroll (hard)",
    12551: "Casket (hard)",
    12552: "Clue scroll (hard)",
    12553: "Casket (hard)",
    12554: "Clue scroll (hard)",
    12555: "Casket (hard)",
    12556: "Clue scroll (hard)",
    12557: "Casket (hard)",
    12558: "Clue scroll (hard)",
    12559: "Casket (hard)",
    12560: "Clue scroll (hard)",
    12561: "Casket (hard)",
    12562: "Clue scroll (hard)",
    12563: "Casket (hard)",
    12564: "Clue scroll (hard)",
    12565: "Casket (hard)",
    12566: "Clue scroll (hard)",
    12567: "Challenge scroll (hard)",
    12568: "Clue scroll (hard)",
    12569: "Challenge scroll (hard)",
    12570: "Clue scroll (hard)",
    12571: "Challenge scroll (hard)",
    12572: "Clue scroll (hard)",
    12573: "Challenge scroll (hard)",
    12574: "Clue scroll (hard)",
    12575: "Challenge scroll (hard)",
    12576: "Clue scroll (hard)",
    12577: "Challenge scroll (hard)",
    12578: "Clue scroll (hard)",
    12579: "Puzzle box (hard)",
    12580: "Casket (hard)",
    12581: "Clue scroll (hard)",
    12582: "Puzzle box (hard)",
    12583: "Casket (hard)",
    12584: "Clue scroll (hard)",
    12585: "Puzzle box (hard)",
    12586: "Casket (hard)",
    12587: "Clue scroll (hard)",
    12588: "Puzzle box (hard)",
    12589: "Casket (hard)",
    12590: "Clue scroll (hard)",
    12591: "Casket (hard)",
    12592: "Black pick head",
    12593: "null",
    12594: "Broken pickaxe",
    12595: "null",
    12596: "Rangers' tunic",
    12597: "null",
    12598: "Holy sandals",
    12599: "null",
    12600: "Druidic wreath",
    12601: "Ring of the gods",
    12602: "null",
    12603: "Tyrannical ring",
    12604: "null",
    12605: "Treasonous ring",
    12606: "null",
    12607: "Damaged book",
    12608: "Book of war",
    12609: "Damaged book",
    12610: "Book of law",
    12611: "Damaged book",
    12612: "Book of darkness",
    12613: "Bandos page 1",
    12614: "Bandos page 2",
    12615: "Bandos page 3",
    12616: "Bandos page 4",
    12617: "Armadyl page 1",
    12618: "Armadyl page 2",
    12619: "Armadyl page 3",
    12620: "Armadyl page 4",
    12621: "Ancient page 1",
    12622: "Ancient page 2",
    12623: "Ancient page 3",
    12624: "Ancient page 4",
    12625: "Stamina potion(4)",
    12626: "null",
    12627: "Stamina potion(3)",
    12628: "null",
    12629: "Stamina potion(2)",
    12630: "null",
    12631: "Stamina potion(1)",
    12632: "null",
    12633: "Stamina mix(2)",
    12634: "null",
    12635: "Stamina mix(1)",
    12636: "null",
    12637: "Saradomin halo",
    12638: "Zamorak halo",
    12639: "Guthix halo",
    12640: "Amylase crystal",
    12641: "Amylase pack",
    12642: "Lumberyard teleport",
    12643: "Pet dagannoth supreme",
    12644: "Pet dagannoth prime",
    12645: "Pet dagannoth rex",
    12646: "Baby mole",
    12647: "Kalphite princess",
    12648: "Pet smoke devil",
    12649: "Pet kree'arra",
    12650: "Pet general graardor",
    12651: "Pet zilyana",
    12652: "Pet k'ril tsutsaroth",
    12653: "Prince black dragon",
    12654: "Kalphite princess",
    12655: "Pet kraken",
    12656: "Junk",
    12657: "Junk",
    12658: "Iban's staff (u)",
    12659: "Clan wars cape",
    12660: "Clan wars cape",
    12661: "Clan wars cape",
    12662: "Clan wars cape",
    12663: "Clan wars cape",
    12664: "Clan wars cape",
    12665: "Clan wars cape",
    12666: "Clan wars cape",
    12667: "Clan wars cape",
    12668: "Clan wars cape",
    12669: "Clan wars cape",
    12670: "Clan wars cape",
    12671: "Clan wars cape",
    12672: "Clan wars cape",
    12673: "Clan wars cape",
    12674: "Clan wars cape",
    12675: "Clan wars cape",
    12676: "Clan wars cape",
    12677: "Clan wars cape",
    12678: "Clan wars cape",
    12679: "Clan wars cape",
    12680: "Clan wars cape",
    12681: "Clan wars cape",
    12682: "Clan wars cape",
    12683: "Clan wars cape",
    12684: "Clan wars cape",
    12685: "Clan wars cape",
    12686: "Clan wars cape",
    12687: "Clan wars cape",
    12688: "Clan wars cape",
    12689: "Clan wars cape",
    12690: "Clan wars cape",
    12691: "Tyrannical ring (i)",
    12692: "Treasonous ring (i)",
    12693: "Kree'arra",
    12694: "Chaos elemental",
    12695: "Super combat potion(4)",
    12696: "null",
    12697: "Super combat potion(3)",
    12698: "null",
    12699: "Super combat potion(2)",
    12700: "null",
    12701: "Super combat potion(1)",
    12702: "null",
    12703: "Pet penance queen",
    12704: "Oak house",
    12705: "Teak house",
    12706: "Mahogany house",
    12707: "Consecrated house",
    12708: "Desecrated house",
    12709: "Nature house",
    12710: "Grassland habitat",
    12711: "Forest habitat",
    12712: "Desert habitat",
    12713: "Polar habitat",
    12714: "Volcanic habitat",
    12715: "Oak scratching post",
    12716: "Teak scratching post",
    12717: "Mahogany scratching post",
    12718: "Simple arena",
    12719: "Advanced arena",
    12720: "Glorious arena",
    12721: "Pet list",
    12722: "Oak feeder",
    12723: "Teak feeder",
    12724: "Mahogany feeder",
    12725: "Menagerie",
    12726: "Menagerie",
    12727: "Goblin paint cannon",
    12728: "Air rune pack",
    12729: "null",
    12730: "Water rune pack",
    12731: "null",
    12732: "Earth rune pack",
    12733: "null",
    12734: "Fire rune pack",
    12735: "null",
    12736: "Mind rune pack",
    12737: "null",
    12738: "Chaos rune pack",
    12739: "null",
    12740: "Bird snare pack",
    12741: "null",
    12742: "Box trap pack",
    12743: "null",
    12744: "Magic imp box pack",
    12745: "null",
    12746: "Archaic emblem (tier 1)",
    12747: "Archaic emblem (tier 1)",
    12748: "Archaic emblem (tier 2)",
    12749: "Archaic emblem (tier 3)",
    12750: "Archaic emblem (tier 4)",
    12751: "Archaic emblem (tier 5)",
    12752: "Archaic emblem (tier 6)",
    12753: "Archaic emblem (tier 7)",
    12754: "Archaic emblem (tier 8)",
    12755: "Archaic emblem (tier 9)",
    12756: "Archaic emblem (tier 10)",
    12757: "Blue dark bow paint",
    12758: "null",
    12759: "Green dark bow paint",
    12760: "null",
    12761: "Yellow dark bow paint",
    12762: "null",
    12763: "White dark bow paint",
    12764: "null",
    12765: "Dark bow",
    12766: "Dark bow",
    12767: "Dark bow",
    12768: "Dark bow",
    12769: "Frozen whip mix",
    12770: "null",
    12771: "Volcanic whip mix",
    12772: "null",
    12773: "Volcanic abyssal whip",
    12774: "Frozen abyssal whip",
    12775: "Annakarl teleport",
    12776: "Carrallanger teleport",
    12777: "Dareeyak teleport",
    12778: "Ghorrock teleport",
    12779: "Kharyrll teleport",
    12780: "Lassar teleport",
    12781: "Paddewwa teleport",
    12782: "Senntisten teleport",
    12783: "Ring of wealth scroll",
    12784: "null",
    12785: "Ring of wealth (i)",
    12786: "Magic shortbow scroll",
    12787: "null",
    12788: "Magic shortbow (i)",
    12789: "Clue box",
    12790: "null",
    12791: "Rune pouch",
    12792: "Nest box (empty)",
    12793: "Nest box (seeds)",
    12794: "Nest box (ring)",
    12795: "Steam battlestaff",
    12796: "Mystic steam staff",
    12797: "Dragon pickaxe",
    12798: "Steam staff upgrade kit",
    12799: "null",
    12800: "Dragon pickaxe upgrade kit",
    12801: "null",
    12802: "Ward upgrade kit",
    12803: "null",
    12804: "Saradomin's tear",
    12805: "null",
    12806: "Malediction ward",
    12807: "Odium ward",
    12808: "Sara's blessed sword (full)",
    12809: "Saradomin's blessed sword",
    12810: "Ironman helm",
    12811: "Ironman platebody",
    12812: "Ironman platelegs",
    12813: "Ultimate ironman helm",
    12814: "Ultimate ironman platebody",
    12815: "Ultimate ironman platelegs",
    12816: "Pet dark core",
    12817: "Elysian spirit shield",
    12818: "null",
    12819: "Elysian sigil",
    12820: "null",
    12821: "Spectral spirit shield",
    12822: "null",
    12823: "Spectral sigil",
    12824: "null",
    12825: "Arcane spirit shield",
    12826: "null",
    12827: "Arcane sigil",
    12828: "null",
    12829: "Spirit shield",
    12830: "null",
    12831: "Blessed spirit shield",
    12832: "null",
    12833: "Holy elixir",
    12834: "null",
    12835: "Community pumpkin",
    12836: "Grim reaper's diary",
    12837: "Grim robe",
    12838: "Will and testament",
    12839: "Human bones",
    12840: "Servant's skull",
    12841: "Hourglass",
    12842: "Scythe sharpener",
    12843: "Human eye",
    12844: "Voice potion",
    12845: "Grim reaper hood",
    12846: "Target teleport scroll",
    12847: "null",
    12848: "Granite maul",
    12849: "Granite clamp",
    12850: "null",
    12851: "Amulet of the damned (full)",
    12852: "null",
    12853: "Amulet of the damned",
    12854: "Flamtaer bag",
    12855: "Hunter's honour",
    12856: "Rogue's revenge",
    12857: "Olive oil pack",
    12858: "null",
    12859: "Eye of newt pack",
    12860: "null",
    12861: "Thanksgiving dinner",
    12862: "Thanksgiving dinner",
    12863: "Dwarf cannon set",
    12864: "null",
    12865: "Green dragonhide set",
    12866: "null",
    12867: "Blue dragonhide set",
    12868: "null",
    12869: "Red dragonhide set",
    12870: "null",
    12871: "Black dragonhide set",
    12872: "null",
    12873: "Guthan's armour set",
    12874: "null",
    12875: "Verac's armour set",
    12876: "null",
    12877: "Dharok's armour set",
    12878: "null",
    12879: "Torag's armour set",
    12880: "null",
    12881: "Ahrim's armour set",
    12882: "null",
    12883: "Karil's armour set",
    12884: "null",
    12885: "Jar of sand",
    12886: "null",
    12887: "Santa mask",
    12888: "Santa jacket",
    12889: "Santa pantaloons",
    12890: "Santa gloves",
    12891: "Santa boots",
    12892: "Antisanta mask",
    12893: "Antisanta jacket",
    12894: "Antisanta pantaloons",
    12895: "Antisanta gloves",
    12896: "Antisanta boots",
    12897: "Antisanta's coal box",
    12898: "Antisanta's coal box (full)",
    12899: "Trident of the swamp",
    12900: "Uncharged toxic trident",
    12901: "null",
    12902: "Toxic staff (uncharged)",
    12903: "null",
    12904: "Toxic staff of the dead",
    12905: "Anti-venom(4)",
    12906: "null",
    12907: "Anti-venom(3)",
    12908: "null",
    12909: "Anti-venom(2)",
    12910: "null",
    12911: "Anti-venom(1)",
    12912: "null",
    12913: "Anti-venom+(4)",
    12914: "null",
    12915: "Anti-venom+(3)",
    12916: "null",
    12917: "Anti-venom+(2)",
    12918: "null",
    12919: "Anti-venom+(1)",
    12920: "null",
    12921: "Pet snakeling",
    12922: "Tanzanite fang",
    12923: "null",
    12924: "Toxic blowpipe (empty)",
    12925: "null",
    12926: "Toxic blowpipe",
    12927: "Serpentine visage",
    12928: "null",
    12929: "Serpentine helm (uncharged)",
    12930: "null",
    12931: "Serpentine helm",
    12932: "Magic fang",
    12933: "null",
    12934: "Zulrah's scales",
    12935: "Ohn's diary",
    12936: "Jar of swamp",
    12937: "null",
    12938: "Zul-andra teleport",
    12939: "Pet snakeling",
    12940: "Pet snakeling",
    12941: "null",
    12942: "null",
    12943: "null",
    12944: "null",
    12945: "null",
    12946: "null",
    12947: "null",
    12948: "null",
    12949: "null",
    12950: "null",
    12951: "null",
    12952: "null",
    12953: "null",
    12954: "Dragon defender",
    12955: "Free to play starter pack",
    12956: "Cow top",
    12957: "Cow trousers",
    12958: "Cow gloves",
    12959: "Cow shoes",
    12960: "Bronze set (lg)",
    12961: "null",
    12962: "Bronze set (sk)",
    12963: "null",
    12964: "Bronze trimmed set (lg)",
    12965: "null",
    12966: "Bronze trimmed set (sk)",
    12967: "null",
    12968: "Bronze gold-trimmed set (lg)",
    12969: "null",
    12970: "Bronze gold-trimmed set (sk)",
    12971: "null",
    12972: "Iron set (lg)",
    12973: "null",
    12974: "Iron set (sk)",
    12975: "null",
    12976: "Iron trimmed set (lg)",
    12977: "null",
    12978: "Iron trimmed set (sk)",
    12979: "null",
    12980: "Iron gold-trimmed set (lg)",
    12981: "null",
    12982: "Iron gold-trimmed set (sk)",
    12983: "null",
    12984: "Steel set (lg)",
    12985: "null",
    12986: "Steel set (sk)",
    12987: "null",
    12988: "Black set (lg)",
    12989: "null",
    12990: "Black set (sk)",
    12991: "null",
    12992: "Black trimmed set (lg)",
    12993: "null",
    12994: "Black trimmed set (sk)",
    12995: "null",
    12996: "Black gold-trimmed set (lg)",
    12997: "null",
    12998: "Black gold-trimmed set (sk)",
    12999: "null",
    13000: "Mithril set (lg)",
    13001: "null",
    13002: "Mithril set (sk)",
    13003: "null",
    13004: "Mithril trimmed set (lg)",
    13005: "null",
    13006: "Mithril trimmed set (sk)",
    13007: "null",
    13008: "Mithril gold-trimmed set (lg)",
    13009: "null",
    13010: "Mithril gold-trimmed set (sk)",
    13011: "null",
    13012: "Adamant set (lg)",
    13013: "null",
    13014: "Adamant set (sk)",
    13015: "null",
    13016: "Adamant trimmed set (lg)",
    13017: "null",
    13018: "Adamant trimmed set (sk)",
    13019: "null",
    13020: "Adamant gold-trimmed set (lg)",
    13021: "null",
    13022: "Adamant gold-trimmed set (sk)",
    13023: "null",
    13024: "Rune armour set (lg)",
    13025: "null",
    13026: "Rune armour set (sk)",
    13027: "null",
    13028: "Rune trimmed set (lg)",
    13029: "null",
    13030: "Rune trimmed set (sk)",
    13031: "null",
    13032: "Rune gold-trimmed set (lg)",
    13033: "null",
    13034: "Rune gold-trimmed set (sk)",
    13035: "null",
    13036: "Gilded armour set (lg)",
    13037: "null",
    13038: "Gilded armour set (sk)",
    13039: "null",
    13040: "Saradomin armour set (lg)",
    13041: "null",
    13042: "Saradomin armour set (sk)",
    13043: "null",
    13044: "Zamorak armour set (lg)",
    13045: "null",
    13046: "Zamorak armour set (sk)",
    13047: "null",
    13048: "Guthix armour set (lg)",
    13049: "null",
    13050: "Guthix armour set (sk)",
    13051: "null",
    13052: "Armadyl rune armour set (lg)",
    13053: "null",
    13054: "Armadyl rune armour set (sk)",
    13055: "null",
    13056: "Bandos rune armour set (lg)",
    13057: "null",
    13058: "Bandos rune armour set (sk)",
    13059: "null",
    13060: "Ancient rune armour set (lg)",
    13061: "null",
    13062: "Ancient rune armour set (sk)",
    13063: "null",
    13064: "Combat potion set",
    13065: "null",
    13066: "Super potion set",
    13067: "null",
    13068: "Quest point cape (t)",
    13069: "Achievement diary cape (t)",
    13070: "Achievement diary hood",
    13071: "Chompy chick",
    13072: "Elite void top",
    13073: "Elite void robe",
    13074: "Pharaoh's sceptre",
    13075: "Pharaoh's sceptre",
    13076: "Pharaoh's sceptre",
    13077: "Pharaoh's sceptre",
    13078: "Pharaoh's sceptre",
    13079: "Enchanted lyre(5)",
    13080: "New crystal halberd full (i)",
    13081: "Crystal halberd full (i)",
    13082: "Crystal halberd 9/10 (i)",
    13083: "Crystal halberd 8/10 (i)",
    13084: "Crystal halberd 7/10 (i)",
    13085: "Crystal halberd 6/10 (i)",
    13086: "Crystal halberd 5/10 (i)",
    13087: "Crystal halberd 4/10 (i)",
    13088: "Crystal halberd 3/10 (i)",
    13089: "Crystal halberd 2/10 (i)",
    13090: "Crystal halberd 1/10 (i)",
    13091: "New crystal halberd full",
    13092: "Crystal halberd full",
    13093: "Crystal halberd 9/10",
    13094: "Crystal halberd 8/10",
    13095: "Crystal halberd 7/10",
    13096: "Crystal halberd 6/10",
    13097: "Crystal halberd 5/10",
    13098: "Crystal halberd 4/10",
    13099: "Crystal halberd 3/10",
    13100: "Crystal halberd 2/10",
    13101: "Crystal halberd 1/10",
    13102: "Teleport crystal (5)",
    13103: "Karamja gloves 4",
    13104: "Varrock armour 1",
    13105: "Varrock armour 2",
    13106: "Varrock armour 3",
    13107: "Varrock armour 4",
    13108: "Wilderness sword 1",
    13109: "Wilderness sword 2",
    13110: "Wilderness sword 3",
    13111: "Wilderness sword 4",
    13112: "Morytania legs 1",
    13113: "Morytania legs 2",
    13114: "Morytania legs 3",
    13115: "Morytania legs 4",
    13116: "Bonecrusher",
    13117: "Falador shield 1",
    13118: "Falador shield 2",
    13119: "Falador shield 3",
    13120: "Falador shield 4",
    13121: "Ardougne cloak 1",
    13122: "Ardougne cloak 2",
    13123: "Ardougne cloak 3",
    13124: "Ardougne cloak 4",
    13125: "Explorer's ring 1",
    13126: "Explorer's ring 2",
    13127: "Explorer's ring 3",
    13128: "Explorer's ring 4",
    13129: "Fremennik sea boots 1",
    13130: "Fremennik sea boots 2",
    13131: "Fremennik sea boots 3",
    13132: "Fremennik sea boots 4",
    13133: "Desert amulet 1",
    13134: "Desert amulet 2",
    13135: "Desert amulet 3",
    13136: "Desert amulet 4",
    13137: "Kandarin headgear 1",
    13138: "Kandarin headgear 2",
    13139: "Kandarin headgear 3",
    13140: "Kandarin headgear 4",
    13141: "Western banner 1",
    13142: "Western banner 2",
    13143: "Western banner 3",
    13144: "Western banner 4",
    13145: "Antique lamp",
    13146: "Antique lamp",
    13147: "Antique lamp",
    13148: "Antique lamp",
    13149: "Holy book page set",
    13150: "null",
    13151: "Unholy book page set",
    13152: "null",
    13153: "Book of balance page set",
    13154: "null",
    13155: "Book of war page set",
    13156: "null",
    13157: "Book of law page set",
    13158: "null",
    13159: "Book of darkness page set",
    13160: "null",
    13161: "Zamorak dragonhide set",
    13162: "null",
    13163: "Saradomin dragonhide set",
    13164: "null",
    13165: "Guthix dragonhide set",
    13166: "null",
    13167: "Bandos dragonhide set",
    13168: "null",
    13169: "Armadyl dragonhide set",
    13170: "null",
    13171: "Ancient dragonhide set",
    13172: "null",
    13173: "Partyhat set",
    13174: "null",
    13175: "Halloween mask set",
    13176: "null",
    13177: "Venenatis spiderling",
    13178: "Callisto cub",
    13179: "Vet'ion jr.",
    13180: "Vet'ion jr.",
    13181: "Scorpia's offspring",
    13182: "Bunny feet",
    13183: "Empty blaster",
    13184: "Incomplete blaster",
    13185: "Easter blaster",
    13186: "Volatile mineral",
    13187: "Package",
    13188: "Diango's claws",
    13189: "null",
    13190: "Old school bond",
    13191: "null",
    13192: "Old school bond (untradeable)",
    13193: "Bone bolt pack",
    13194: "null",
    13195: "Oddskull",
    13196: "Tanzanite helm (uncharged)",
    13197: "Tanzanite helm",
    13198: "Magma helm (uncharged)",
    13199: "Magma helm",
    13200: "Tanzanite mutagen",
    13201: "Magma mutagen",
    13202: "Ring of the gods (i)",
    13203: "Mask of balance",
    13204: "Platinum token",
    13205: "Rotten egg",
    13206: "Null",
    13207: "null",
    13208: "null",
    13209: "null",
    13210: "null",
    13211: "Null",
    13212: "null",
    13213: "null",
    13214: "null",
    13215: "Tiger toy",
    13216: "Lion toy",
    13217: "Snow leopard toy",
    13218: "Amur leopard toy",
    13219: "Null",
    13220: "null",
    13221: "Music cape",
    13222: "Music cape(t)",
    13223: "Music hood",
    13224: "Null",
    13225: "Tzrek-jad",
    13226: "Herb sack",
    13227: "Eternal crystal",
    13228: "null",
    13229: "Pegasian crystal",
    13230: "null",
    13231: "Primordial crystal",
    13232: "null",
    13233: "Smouldering stone",
    13234: "null",
    13235: "Eternal boots",
    13236: "null",
    13237: "Pegasian boots",
    13238: "null",
    13239: "Primordial boots",
    13240: "null",
    13241: "Infernal axe",
    13242: "Infernal axe (uncharged)",
    13243: "Infernal pickaxe",
    13244: "Infernal pickaxe (uncharged)",
    13245: "Jar of souls",
    13246: "null",
    13247: "Hellpuppy",
    13248: "Key master's key",
    13249: "Key master teleport",
    13250: "Plant pot pack",
    13251: "null",
    13252: "Sack pack",
    13253: "null",
    13254: "Basket pack",
    13255: "null",
    13256: "Saradomin's light",
    13257: "null",
    13258: "Angler hat",
    13259: "Angler top",
    13260: "Angler waders",
    13261: "Angler boots",
    13262: "Abyssal orphan",
    13263: "Abyssal bludgeon",
    13264: "null",
    13265: "Abyssal dagger",
    13266: "null",
    13267: "Abyssal dagger (p)",
    13268: "null",
    13269: "Abyssal dagger (p+)",
    13270: "null",
    13271: "Abyssal dagger (p++)",
    13272: "null",
    13273: "Unsired",
    13274: "Bludgeon spine",
    13275: "Bludgeon claw",
    13276: "Bludgeon axon",
    13277: "Jar of miasma",
    13278: "null",
    13279: "Overseer's book",
    13280: "Max cape",
    13281: "Max hood",
    13282: "Null",
    13283: "Gravedigger mask",
    13284: "Gravedigger top",
    13285: "Gravedigger leggings",
    13286: "Gravedigger boots",
    13287: "Gravedigger gloves",
    13288: "Anti-panties",
    13289: "Null",
    13290: "Null",
    13291: "Null",
    13292: "Null",
    13293: "Null",
    13294: "Null",
    13295: "Null",
    13296: "Null",
    13297: "Null",
    13298: "Null",
    13299: "Null",
    13300: "Null",
    13301: "Null",
    13302: "Bank key",
    13303: "Bank key",
    13304: "Bank key",
    13305: "Bank key",
    13306: "Bank key",
    13307: "Blood money",
    13308: "null",
    13309: "null",
    13310: "null",
    13311: "null",
    13312: "null",
    13313: "null",
    13314: "null",
    13315: "null",
    13316: "null",
    13317: "Deadman's chest",
    13318: "Deadman's legs",
    13319: "Deadman's cape",
    13320: "Heron",
    13321: "Rock golem",
    13322: "Beaver",
    13323: "Baby chinchompa",
    13324: "Baby chinchompa",
    13325: "Baby chinchompa",
    13326: "Baby chinchompa",
    13327: "Rotten onion",
    13328: "Green banner",
    13329: "Fire max cape",
    13330: "Fire max hood",
    13331: "Saradomin max cape",
    13332: "Saradomin max hood",
    13333: "Zamorak max cape",
    13334: "Zamorak max hood",
    13335: "Guthix max cape",
    13336: "Guthix max hood",
    13337: "Accumulator max cape",
    13338: "Accumulator max hood",
    13339: "Sacred eel",
    13340: "Agility cape",
    13341: "Agility cape(t)",
    13342: "Max cape",
    13343: "Black santa hat",
    13344: "Inverted santa hat",
    13345: "Anti-present",
    13346: "Present",
    13347: "Vial of tears (empty)",
    13348: "Vial of tears (1)",
    13349: "Vial of tears (2)",
    13350: "Vial of tears (3)",
    13351: "Vial of tears (full)",
    13352: "Vial of sorrow",
    13353: "Gricoller's can",
    13354: "Lovakite bar",
    13355: "Juniper logs",
    13356: "Lovakite ore",
    13357: "Shayzien gloves (1)",
    13358: "Shayzien boots (1)",
    13359: "Shayzien helm (1)",
    13360: "Shayzien greaves (1)",
    13361: "Shayzien platebody (1)",
    13362: "Shayzien gloves (2)",
    13363: "Shayzien boots (2)",
    13364: "Shayzien helm (2)",
    13365: "Shayzien greaves (2)",
    13366: "Shayzien platebody (2)",
    13367: "Shayzien gloves (3)",
    13368: "Shayzien boots (3)",
    13369: "Shayzien helm (3)",
    13370: "Shayzien greaves (3)",
    13371: "Shayzien platebody (3)",
    13372: "Shayzien gloves (4)",
    13373: "Shayzien boots (4)",
    13374: "Shayzien helm (4)",
    13375: "Shayzien greaves (4)",
    13376: "Shayzien platebody (4)",
    13377: "Shayzien gloves (5)",
    13378: "Shayzien boots (5)",
    13379: "Shayzien helm (5)",
    13380: "Shayzien greaves (5)",
    13381: "Shayzien body (5)",
    13382: "Shayzien medpack",
    13383: "Xerician fabric",
    13384: "null",
    13385: "Xerician hat",
    13386: "null",
    13387: "Xerician top",
    13388: "null",
    13389: "Xerician robe",
    13390: "null",
    13391: "Lizardman fang",
    13392: "Xeric's talisman (inert)",
    13393: "Xeric's talisman",
    13394: "Gang meeting info",
    13395: "Intelligence",
    13396: "Training manual",
    13397: "Servery flour",
    13398: "Servery pastry dough",
    13399: "Servery raw meat",
    13400: "Servery dish",
    13401: "Servery pie shell",
    13402: "Servery uncooked pie",
    13403: "Servery meat pie",
    13404: "Servery pizza base",
    13405: "Servery tomato",
    13406: "Servery incomplete pizza",
    13407: "Servery cheese",
    13408: "Servery uncooked pizza",
    13409: "Servery plain pizza",
    13410: "Servery pineapple",
    13411: "Servery pineapple chunks",
    13412: "Servery pineapple pizza",
    13413: "Servery cooked meat",
    13414: "Servery potato",
    13415: "Servery incomplete stew",
    13416: "Servery incomplete stew",
    13417: "Servery uncooked stew",
    13418: "Servery stew",
    13419: "Sulphurous fertiliser",
    13420: "Gricoller's fertiliser",
    13421: "Saltpetre",
    13422: "null",
    13423: "Golovanova seed",
    13424: "Bologano seed",
    13425: "Logavano seed",
    13426: "Golovanova fruit",
    13427: "Bologano fruit",
    13428: "Logavano fruit",
    13429: "Fresh fish",
    13430: "Bucket of sandworms",
    13431: "Sandworms",
    13432: "Sandworms pack",
    13433: "null",
    13434: "Stolen pendant",
    13435: "Stolen garnet ring",
    13436: "Stolen circlet",
    13437: "Stolen family heirloom",
    13438: "Stolen jewelry box",
    13439: "Raw anglerfish",
    13440: "null",
    13441: "Anglerfish",
    13442: "null",
    13443: "Burnt anglerfish",
    13444: "null",
    13445: "Dense essence block",
    13446: "Dark essence block",
    13447: "Ensouled goblin head",
    13448: "Ensouled goblin head",
    13449: "null",
    13450: "Ensouled monkey head",
    13451: "Ensouled monkey head",
    13452: "null",
    13453: "Ensouled imp head",
    13454: "Ensouled imp head",
    13455: "null",
    13456: "Ensouled minotaur head",
    13457: "Ensouled minotaur head",
    13458: "null",
    13459: "Ensouled scorpion head",
    13460: "Ensouled scorpion head",
    13461: "null",
    13462: "Ensouled bear head",
    13463: "Ensouled bear head",
    13464: "null",
    13465: "Ensouled unicorn head",
    13466: "Ensouled unicorn head",
    13467: "null",
    13468: "Ensouled dog head",
    13469: "Ensouled dog head",
    13470: "null",
    13471: "Ensouled chaos druid head",
    13472: "Ensouled chaos druid head",
    13473: "null",
    13474: "Ensouled giant head",
    13475: "Ensouled giant head",
    13476: "null",
    13477: "Ensouled ogre head",
    13478: "Ensouled ogre head",
    13479: "null",
    13480: "Ensouled elf head",
    13481: "Ensouled elf head",
    13482: "null",
    13483: "Ensouled troll head",
    13484: "Ensouled troll head",
    13485: "null",
    13486: "Ensouled horror head",
    13487: "Ensouled horror head",
    13488: "null",
    13489: "Ensouled kalphite head",
    13490: "Ensouled kalphite head",
    13491: "null",
    13492: "Ensouled dagannoth head",
    13493: "Ensouled dagannoth head",
    13494: "null",
    13495: "Ensouled bloodveld head",
    13496: "Ensouled bloodveld head",
    13497: "null",
    13498: "Ensouled tzhaar head",
    13499: "Ensouled tzhaar head",
    13500: "null",
    13501: "Ensouled demon head",
    13502: "Ensouled demon head",
    13503: "null",
    13504: "Ensouled aviansie head",
    13505: "Ensouled aviansie head",
    13506: "null",
    13507: "Ensouled abyssal head",
    13508: "Ensouled abyssal head",
    13509: "null",
    13510: "Ensouled dragon head",
    13511: "Ensouled dragon head",
    13512: "null",
    13513: "Book of arcane knowledge",
    13514: "Dark manuscript",
    13515: "Dark manuscript",
    13516: "Dark manuscript",
    13517: "Dark manuscript",
    13518: "Dark manuscript",
    13519: "Dark manuscript",
    13520: "Dark manuscript",
    13521: "Dark manuscript",
    13522: "Dark manuscript",
    13523: "Dark manuscript",
    13524: "Rada's census",
    13525: "Ricktor's diary (7)",
    13526: "Eathram & rada extract",
    13527: "Killing of a king",
    13528: "Hosidius letter",
    13529: "Wintertodt parable",
    13530: "Twill accord",
    13531: "Byrne's coronation speech",
    13532: "Ideology of darkness",
    13533: "Rada's journey",
    13534: "Transvergence theory",
    13535: "Tristessa's tragedy",
    13536: "Treachery of royalty",
    13537: "Transportation incantations",
    13538: "Shayzien supply gloves (1)",
    13539: "Shayzien supply boots (1)",
    13540: "Shayzien supply helm (1)",
    13541: "Shayzien supply greaves (1)",
    13542: "Shayzien supply platebody (1)",
    13543: "Shayzien supply gloves (2)",
    13544: "Shayzien supply boots (2)",
    13545: "Shayzien supply helm (2)",
    13546: "Shayzien supply greaves (2)",
    13547: "Shayzien supply platebody (2)",
    13548: "Shayzien supply gloves (3)",
    13549: "Shayzien supply boots (3)",
    13550: "Shayzien supply helm (3)",
    13551: "Shayzien supply greaves (3)",
    13552: "Shayzien supply platebody (3)",
    13553: "Shayzien supply gloves (4)",
    13554: "Shayzien supply boots (4)",
    13555: "Shayzien supply helm (4)",
    13556: "Shayzien supply greaves (4)",
    13557: "Shayzien supply platebody (4)",
    13558: "Shayzien supply gloves (5)",
    13559: "Shayzien supply boots (5)",
    13560: "Shayzien supply helm (5)",
    13561: "Shayzien supply greaves (5)",
    13562: "Shayzien supply platebody (5)",
    13563: "Shayzien supply crate",
    13564: "Shayzien supply crate",
    13565: "Shayzien supply set (1)",
    13566: "Shayzien supply set (2)",
    13567: "Shayzien supply set (3)",
    13568: "Shayzien supply set (4)",
    13569: "Shayzien supply set (5)",
    13570: "Juniper charcoal",
    13571: "Volcanic sulphur",
    13572: "Dynamite pot",
    13573: "Dynamite",
    13574: "null",
    13575: "Blasted ore",
    13576: "Dragon warhammer",
    13577: "null",
    13578: "null",
    13579: "Graceful hood",
    13580: "Graceful hood",
    13581: "Graceful cape",
    13582: "Graceful cape",
    13583: "Graceful top",
    13584: "Graceful top",
    13585: "Graceful legs",
    13586: "Graceful legs",
    13587: "Graceful gloves",
    13588: "Graceful gloves",
    13589: "Graceful boots",
    13590: "Graceful boots",
    13591: "Graceful hood",
    13592: "Graceful hood",
    13593: "Graceful cape",
    13594: "Graceful cape",
    13595: "Graceful top",
    13596: "Graceful top",
    13597: "Graceful legs",
    13598: "Graceful legs",
    13599: "Graceful gloves",
    13600: "Graceful gloves",
    13601: "Graceful boots",
    13602: "Graceful boots",
    13603: "Graceful hood",
    13604: "Graceful hood",
    13605: "Graceful cape",
    13606: "Graceful cape",
    13607: "Graceful top",
    13608: "Graceful top",
    13609: "Graceful legs",
    13610: "Graceful legs",
    13611: "Graceful gloves",
    13612: "Graceful gloves",
    13613: "Graceful boots",
    13614: "Graceful boots",
    13615: "Graceful hood",
    13616: "Graceful hood",
    13617: "Graceful cape",
    13618: "Graceful cape",
    13619: "Graceful top",
    13620: "Graceful top",
    13621: "Graceful legs",
    13622: "Graceful legs",
    13623: "Graceful gloves",
    13624: "Graceful gloves",
    13625: "Graceful boots",
    13626: "Graceful boots",
    13627: "Graceful hood",
    13628: "Graceful hood",
    13629: "Graceful cape",
    13630: "Graceful cape",
    13631: "Graceful top",
    13632: "Graceful top",
    13633: "Graceful legs",
    13634: "Graceful legs",
    13635: "Graceful gloves",
    13636: "Graceful gloves",
    13637: "Graceful boots",
    13638: "Graceful boots",
    13639: "Seed box",
    13640: "Farmer's boro trousers",
    13641: "Farmer's boro trousers",
    13642: "Farmer's jacket",
    13643: "Farmer's shirt",
    13644: "Farmer's boots",
    13645: "Farmer's boots",
    13646: "Farmer's strawhat",
    13647: "Farmer's strawhat",
    13648: "Clue bottle (easy)",
    13649: "Clue bottle (medium)",
    13650: "Clue bottle (hard)",
    13651: "Clue bottle (elite)",
    13652: "Dragon claws",
    13653: "Bird nest",
    13654: "Nest box (seeds)",
    13655: "Gnome child hat",
    13656: "Present",
    13657: "Grape seed",
    13658: "Teleport card",
    13659: "null",
    13660: "Chronicle",
    13661: "Null",
    13662: "Null",
    13663: "Bunny top",
    13664: "Bunny legs",
    13665: "Bunny paws",
    13666: "Deadman teleport tablet",
    13667: "Graceful hood",
    13668: "Graceful hood",
    13669: "Graceful cape",
    13670: "Graceful cape",
    13671: "Graceful top",
    13672: "Graceful top",
    13673: "Graceful legs",
    13674: "Graceful legs",
    13675: "Graceful gloves",
    13676: "Graceful gloves",
    13677: "Graceful boots",
    13678: "Graceful boots",
    13679: "Cabbage cape",
    13680: "Cabbage rune",
    13681: "Cruciferous codex",
    13682: "null",
    13683: "null",
    13684: "null",
    13685: "null",
    13686: "null",
    13687: "null",
    13688: "null",
    13689: "null",
    13690: "null",
    13691: "null",
    13692: "null",
    13693: "null",
    13694: "null",
    13695: "null",
    13696: "null",
    13697: "null",
    13698: "null",
    13699: "null",
    13700: "null",
    13701: "null",
    13702: "null",
    13703: "null",
    13704: "null",
    13705: "null",
    13706: "null",
    13707: "null",
    13708: "null",
    13709: "null",
    13710: "null",
    13711: "null",
    13712: "null",
    13713: "null",
    13714: "null",
    13715: "null",
    13716: "null",
    13717: "null",
    13718: "null",
    13719: "null",
    13720: "null",
    13721: "null",
    13722: "null",
    13723: "null",
    13724: "null",
    13725: "null",
    13726: "null",
    13727: "null",
    13728: "null",
    13729: "null",
    13730: "null",
    13731: "null",
    13732: "null",
    13733: "null",
    13734: "null",
    13735: "null",
    13736: "null",
    13737: "null",
    13738: "null",
    13739: "null",
    13740: "null",
    13741: "null",
    13742: "null",
    13743: "null",
    13744: "null",
    13745: "null",
    13746: "null",
    13747: "null",
    13748: "null",
    13749: "null",
    13750: "null",
    13751: "null",
    13752: "null",
    13753: "null",
    13754: "null",
    13755: "null",
    13756: "null",
    13757: "null",
    13758: "null",
    13759: "null",
    13760: "null",
    13761: "null",
    13762: "null",
    13763: "null",
    13764: "null",
    13765: "null",
    13766: "null",
    13767: "null",
    13768: "null",
    13769: "null",
    13770: "null",
    13771: "null",
    13772: "null",
    13773: "null",
    13774: "null",
    13775: "null",
    13776: "null",
    13777: "null",
    13778: "null",
    13779: "null",
    13780: "null",
    13781: "null",
    13782: "null",
    13783: "null",
    13784: "null",
    13785: "null",
    13786: "null",
    13787: "null",
    13788: "null",
    13789: "null",
    13790: "null",
    13791: "null",
    13792: "null",
    13793: "null",
    13794: "null",
    13795: "null",
    13796: "null",
    13797: "null",
    13798: "null",
    13799: "null",
    13800: "null",
    13801: "null",
    13802: "null",
    13803: "null",
    13804: "null",
    13805: "null",
    13806: "null",
    13807: "null",
    13808: "null",
    13809: "null",
    13810: "null",
    13811: "null",
    13812: "null",
    13813: "null",
    13814: "null",
    13815: "null",
    13816: "null",
    13817: "null",
    13818: "null",
    13819: "null",
    13820: "null",
    13821: "null",
    13822: "null",
    13823: "null",
    13824: "null",
    13825: "null",
    13826: "null",
    13827: "null",
    13828: "null",
    13829: "null",
    13830: "null",
    13831: "null",
    13832: "null",
    13833: "null",
    13834: "null",
    13835: "null",
    13836: "null",
    13837: "null",
    13838: "null",
    13839: "null",
    13840: "null",
    13841: "null",
    13842: "null",
    13843: "null",
    13844: "null",
    13845: "null",
    13846: "null",
    13847: "null",
    13848: "null",
    13849: "null",
    13850: "null",
    13851: "null",
    13852: "null",
    13853: "null",
    13854: "null",
    13855: "null",
    13856: "null",
    13857: "null",
    13858: "null",
    13859: "null",
    13860: "null",
    13861: "null",
    13862: "null",
    13863: "null",
    13864: "null",
    13865: "null",
    13866: "null",
    13867: "null",
    13868: "null",
    13869: "null",
    13870: "null",
    13871: "null",
    13872: "null",
    13873: "null",
    13874: "null",
    13875: "null",
    13876: "null",
    13877: "null",
    13878: "null",
    13879: "null",
    13880: "null",
    13881: "null",
    13882: "null",
    13883: "null",
    13884: "null",
    13885: "null",
    13886: "null",
    13887: "null",
    13888: "null",
    13889: "null",
    13890: "null",
    13891: "null",
    13892: "null",
    13893: "null",
    13894: "null",
    13895: "null",
    13896: "null",
    13897: "null",
    13898: "null",
    13899: "null",
    13900: "null",
    13901: "null",
    13902: "null",
    13903: "null",
    13904: "null",
    13905: "null",
    13906: "null",
    13907: "null",
    13908: "null",
    13909: "null",
    13910: "null",
    13911: "null",
    13912: "null",
    13913: "null",
    13914: "null",
    13915: "null",
    13916: "null",
    13917: "null",
    13918: "null",
    13919: "null",
    13920: "null",
    13921: "null",
    13922: "null",
    13923: "null",
    13924: "null",
    13925: "null",
    13926: "null",
    13927: "null",
    13928: "null",
    13929: "null",
    13930: "null",
    13931: "null",
    13932: "null",
    13933: "null",
    13934: "null",
    13935: "null",
    13936: "null",
    13937: "null",
    13938: "null",
    13939: "null",
    13940: "null",
    13941: "null",
    13942: "null",
    13943: "null",
    13944: "null",
    13945: "null",
    13946: "null",
    13947: "null",
    13948: "null",
    13949: "null",
    13950: "null",
    13951: "null",
    13952: "null",
    13953: "null",
    13954: "null",
    13955: "null",
    13956: "null",
    13957: "null",
    13958: "null",
    13959: "null",
    13960: "null",
    13961: "null",
    13962: "null",
    13963: "null",
    13964: "null",
    13965: "null",
    13966: "null",
    13967: "null",
    13968: "null",
    13969: "null",
    13970: "null",
    13971: "null",
    13972: "null",
    13973: "null",
    13974: "null",
    13975: "null",
    13976: "null",
    13977: "null",
    13978: "null",
    13979: "null",
    13980: "null",
    13981: "null",
    13982: "null",
    13983: "null",
    13984: "null",
    13985: "null",
    13986: "null",
    13987: "null",
    13988: "null",
    13989: "null",
    13990: "null",
    13991: "null",
    13992: "null",
    13993: "null",
    13994: "null",
    13995: "null",
    13996: "null",
    13997: "null",
    13998: "null",
    13999: "null",
    14000: "null",
    14001: "null",
    14002: "null",
    14003: "null",
    14004: "null",
    14005: "null",
    14006: "null",
    14007: "null",
    14008: "null",
    14009: "null",
    14010: "null",
    14011: "null",
    14012: "null",
    14013: "null",
    14014: "null",
    14015: "null",
    14016: "null",
    14017: "null",
    14018: "null",
    14019: "null",
    14020: "null",
    14021: "null",
    14022: "null",
    14023: "null",
    14024: "null",
    14025: "null",
    14026: "null",
    14027: "null",
    14028: "null",
    14029: "null",
    14030: "null",
    14031: "null",
    14032: "null",
    14033: "null",
    14034: "null",
    14035: "null",
    14036: "null",
    14037: "null",
    14038: "null",
    14039: "null",
    14040: "null",
    14041: "null",
    14042: "null",
    14043: "null",
    14044: "null",
    14045: "null",
    14046: "null",
    14047: "null",
    14048: "null",
    14049: "null",
    14050: "null",
    14051: "null",
    14052: "null",
    14053: "null",
    14054: "null",
    14055: "null",
    14056: "null",
    14057: "null",
    14058: "null",
    14059: "null",
    14060: "null",
    14061: "null",
    14062: "null",
    14063: "null",
    14064: "null",
    14065: "null",
    14066: "null",
    14067: "null",
    14068: "null",
    14069: "null",
    14070: "null",
    14071: "null",
    14072: "null",
    14073: "null",
    14074: "null",
    14075: "null",
    14076: "null",
    14077: "null",
    14078: "null",
    14079: "null",
    14080: "null",
    14081: "null",
    14082: "null",
    14083: "null",
    14084: "null",
    14085: "null",
    14086: "null",
    14087: "null",
    14088: "null",
    14089: "null",
    14090: "null",
    14091: "null",
    14092: "null",
    14093: "null",
    14094: "null",
    14095: "null",
    14096: "null",
    14097: "null",
    14098: "null",
    14099: "null",
    14100: "null",
    14101: "null",
    14102: "null",
    14103: "null",
    14104: "null",
    14105: "null",
    14106: "null",
    14107: "null",
    14108: "null",
    14109: "null",
    14110: "null",
    14111: "null",
    14112: "null",
    14113: "null",
    14114: "null",
    14115: "null",
    14116: "null",
    14117: "null",
    14118: "null",
    14119: "null",
    14120: "null",
    14121: "null",
    14122: "null",
    14123: "null",
    14124: "null",
    14125: "null",
    14126: "null",
    14127: "null",
    14128: "null",
    14129: "null",
    14130: "null",
    14131: "null",
    14132: "null",
    14133: "null",
    14134: "null",
    14135: "null",
    14136: "null",
    14137: "null",
    14138: "null",
    14139: "null",
    14140: "null",
    14141: "null",
    14142: "null",
    14143: "null",
    14144: "null",
    14145: "null",
    14146: "null",
    14147: "null",
    14148: "null",
    14149: "null",
    14150: "null",
    14151: "null",
    14152: "null",
    14153: "null",
    14154: "null",
    14155: "null",
    14156: "null",
    14157: "null",
    14158: "null",
    14159: "null",
    14160: "null",
    14161: "null",
    14162: "null",
    14163: "null",
    14164: "null",
    14165: "null",
    14166: "null",
    14167: "null",
    14168: "null",
    14169: "null",
    14170: "null",
    14171: "null",
    14172: "null",
    14173: "null",
    14174: "null",
    14175: "null",
    14176: "null",
    14177: "null",
    14178: "null",
    14179: "null",
    14180: "null",
    14181: "null",
    14182: "null",
    14183: "null",
    14184: "null",
    14185: "null",
    14186: "null",
    14187: "null",
    14188: "null",
    14189: "null",
    14190: "null",
    14191: "null",
    14192: "null",
    14193: "null",
    14194: "null",
    14195: "null",
    14196: "null",
    14197: "null",
    14198: "null",
    14199: "null",
    14200: "null",
    14201: "null",
    14202: "null",
    14203: "null",
    14204: "null",
    14205: "null",
    14206: "null",
    14207: "null",
    14208: "null",
    14209: "null",
    14210: "null",
    14211: "null",
    14212: "null",
    14213: "null",
    14214: "null",
    14215: "null",
    14216: "null",
    14217: "null",
    14218: "null",
    14219: "null",
    14220: "null",
    14221: "null",
    14222: "null",
    14223: "null",
    14224: "null",
    14225: "null",
    14226: "null",
    14227: "null",
    14228: "null",
    14229: "null",
    14230: "null",
    14231: "null",
    14232: "null",
    14233: "null",
    14234: "null",
    14235: "null",
    14236: "null",
    14237: "null",
    14238: "null",
    14239: "null",
    14240: "null",
    14241: "null",
    14242: "null",
    14243: "null",
    14244: "null",
    14245: "null",
    14246: "null",
    14247: "null",
    14248: "null",
    14249: "null",
    14250: "null",
    14251: "null",
    14252: "null",
    14253: "null",
    14254: "null",
    14255: "null",
    14256: "null",
    14257: "null",
    14258: "null",
    14259: "null",
    14260: "null",
    14261: "null",
    14262: "null",
    14263: "null",
    14264: "null",
    14265: "null",
    14266: "null",
    14267: "null",
    14268: "null",
    14269: "null",
    14270: "null",
    14271: "null",
    14272: "null",
    14273: "null",
    14274: "null",
    14275: "null",
    14276: "null",
    14277: "null",
    14278: "null",
    14279: "null",
    14280: "null",
    14281: "null",
    14282: "null",
    14283: "null",
    14284: "null",
    14285: "null",
    14286: "null",
    14287: "null",
    14288: "null",
    14289: "null",
    14290: "null",
    14291: "null",
    14292: "null",
    14293: "null",
    14294: "null",
    14295: "null",
    14296: "null",
    14297: "null",
    14298: "null",
    14299: "null",
    14300: "null",
    14301: "null",
    14302: "null",
    14303: "null",
    14304: "null",
    14305: "null",
    14306: "null",
    14307: "null",
    14308: "null",
    14309: "null",
    14310: "null",
    14311: "null",
    14312: "null",
    14313: "null",
    14314: "null",
    14315: "null",
    14316: "null",
    14317: "null",
    14318: "null",
    14319: "null",
    14320: "null",
    14321: "null",
    14322: "null",
    14323: "null",
    14324: "null",
    14325: "null",
    14326: "null",
    14327: "null",
    14328: "null",
    14329: "null",
    14330: "null",
    14331: "null",
    14332: "null",
    14333: "null",
    14334: "null",
    14335: "null",
    14336: "null",
    14337: "null",
    14338: "null",
    14339: "null",
    14340: "null",
    14341: "null",
    14342: "null",
    14343: "null",
    14344: "null",
    14345: "null",
    14346: "null",
    14347: "null",
    14348: "null",
    14349: "null",
    14350: "null",
    14351: "null",
    14352: "null",
    14353: "null",
    14354: "null",
    14355: "null",
    14356: "null",
    14357: "null",
    14358: "null",
    14359: "null",
    14360: "null",
    14361: "null",
    14362: "null",
    14363: "null",
    14364: "null",
    14365: "null",
    14366: "null",
    14367: "null",
    14368: "null",
    14369: "null",
    14370: "null",
    14371: "null",
    14372: "null",
    14373: "null",
    14374: "null",
    14375: "null",
    14376: "null",
    14377: "null",
    14378: "null",
    14379: "null",
    14380: "null",
    14381: "null",
    14382: "null",
    14383: "null",
    14384: "null",
    14385: "null",
    14386: "null",
    14387: "null",
    14388: "null",
    14389: "null",
    14390: "null",
    14391: "null",
    14392: "null",
    14393: "null",
    14394: "null",
    14395: "null",
    14396: "null",
    14397: "null",
    14398: "null",
    14399: "null",
    14400: "null",
    14401: "null",
    14402: "null",
    14403: "null",
    14404: "null",
    14405: "null",
    14406: "null",
    14407: "null",
    14408: "null",
    14409: "null",
    14410: "null",
    14411: "null",
    14412: "null",
    14413: "null",
    14414: "null",
    14415: "null",
    14416: "null",
    14417: "null",
    14418: "null",
    14419: "null",
    14420: "null",
    14421: "null",
    14422: "null",
    14423: "null",
    14424: "null",
    14425: "null",
    14426: "null",
    14427: "null",
    14428: "null",
    14429: "null",
    14430: "null",
    14431: "null",
    14432: "null",
    14433: "null",
    14434: "null",
    14435: "null",
    14436: "null",
    14437: "null",
    14438: "null",
    14439: "null",
    14440: "null",
    14441: "null",
    14442: "null",
    14443: "null",
    14444: "null",
    14445: "null",
    14446: "null",
    14447: "null",
    14448: "null",
    14449: "null",
    14450: "null",
    14451: "null",
    14452: "null",
    14453: "null",
    14454: "null",
    14455: "null",
    14456: "null",
    14457: "null",
    14458: "null",
    14459: "null",
    14460: "null",
    14461: "null",
    14462: "null",
    14463: "null",
    14464: "null",
    14465: "null",
    14466: "null",
    14467: "null",
    14468: "null",
    14469: "null",
    14470: "null",
    14471: "null",
    14472: "null",
    14473: "null",
    14474: "null",
    14475: "null",
    14476: "null",
    14477: "null",
    14478: "null",
    14479: "null",
    14480: "null",
    14481: "null",
    14482: "null",
    14483: "null",
    14484: "null",
    14485: "null",
    14486: "null",
    14487: "null",
    14488: "null",
    14489: "null",
    14490: "null",
    14491: "null",
    14492: "null",
    14493: "null",
    14494: "null",
    14495: "null",
    14496: "null",
    14497: "null",
    14498: "null",
    14499: "null",
    14500: "null",
    14501: "null",
    14502: "null",
    14503: "null",
    14504: "null",
    14505: "null",
    14506: "null",
    14507: "null",
    14508: "null",
    14509: "null",
    14510: "null",
    14511: "null",
    14512: "null",
    14513: "null",
    14514: "null",
    14515: "null",
    14516: "null",
    14517: "null",
    14518: "null",
    14519: "null",
    14520: "null",
    14521: "null",
    14522: "null",
    14523: "null",
    14524: "null",
    14525: "null",
    14526: "null",
    14527: "null",
    14528: "null",
    14529: "null",
    14530: "null",
    14531: "null",
    14532: "null",
    14533: "null",
    14534: "null",
    14535: "null",
    14536: "null",
    14537: "null",
    14538: "null",
    14539: "null",
    14540: "null",
    14541: "null",
    14542: "null",
    14543: "null",
    14544: "null",
    14545: "null",
    14546: "null",
    14547: "null",
    14548: "null",
    14549: "null",
    14550: "null",
    14551: "null",
    14552: "null",
    14553: "null",
    14554: "null",
    14555: "null",
    14556: "null",
    14557: "null",
    14558: "null",
    14559: "null",
    14560: "null",
    14561: "null",
    14562: "null",
    14563: "null",
    14564: "null",
    14565: "null",
    14566: "null",
    14567: "null",
    14568: "null",
    14569: "null",
    14570: "null",
    14571: "null",
    14572: "null",
    14573: "null",
    14574: "null",
    14575: "null",
    14576: "null",
    14577: "null",
    14578: "null",
    14579: "null",
    14580: "null",
    14581: "null",
    14582: "null",
    14583: "null",
    14584: "null",
    14585: "null",
    14586: "null",
    14587: "null",
    14588: "null",
    14589: "null",
    14590: "null",
    14591: "null",
    14592: "null",
    14593: "null",
    14594: "null",
    14595: "null",
    14596: "null",
    14597: "null",
    14598: "null",
    14599: "null",
    14600: "null",
    14601: "null",
    14602: "null",
    14603: "null",
    14604: "null",
    14605: "null",
    14606: "null",
    14607: "null",
    14608: "null",
    14609: "null",
    14610: "null",
    14611: "null",
    14612: "null",
    14613: "null",
    14614: "null",
    14615: "null",
    14616: "null",
    14617: "null",
    14618: "null",
    14619: "null",
    14620: "null",
    14621: "null",
    14622: "null",
    14623: "null",
    14624: "null",
    14625: "null",
    14626: "null",
    14627: "null",
    14628: "null",
    14629: "null",
    14630: "null",
    14631: "null",
    14632: "null",
    14633: "null",
    14634: "null",
    14635: "null",
    14636: "null",
    14637: "null",
    14638: "null",
    14639: "null",
    14640: "null",
    14641: "null",
    14642: "null",
    14643: "null",
    14644: "null",
    14645: "null",
    14646: "null",
    14647: "null",
    14648: "null",
    14649: "null",
    14650: "null",
    14651: "null",
    14652: "null",
    14653: "null",
    14654: "null",
    14655: "null",
    14656: "null",
    14657: "null",
    14658: "null",
    14659: "null",
    14660: "null",
    14661: "null",
    14662: "null",
    14663: "null",
    14664: "null",
    14665: "null",
    14666: "null",
    14667: "null",
    14668: "null",
    14669: "null",
    14670: "null",
    14671: "null",
    14672: "null",
    14673: "null",
    14674: "null",
    14675: "null",
    14676: "null",
    14677: "null",
    14678: "null",
    14679: "null",
    14680: "null",
    14681: "null",
    14682: "null",
    14683: "null",
    14684: "null",
    14685: "null",
    14686: "null",
    14687: "null",
    14688: "null",
    14689: "null",
    14690: "null",
    14691: "null",
    14692: "null",
    14693: "null",
    14694: "null",
    14695: "null",
    14696: "null",
    14697: "null",
    14698: "null",
    14699: "null",
    14700: "null",
    14701: "null",
    14702: "null",
    14703: "null",
    14704: "null",
    14705: "null",
    14706: "null",
    14707: "null",
    14708: "null",
    14709: "null",
    14710: "null",
    14711: "null",
    14712: "null",
    14713: "null",
    14714: "null",
    14715: "null",
    14716: "null",
    14717: "null",
    14718: "null",
    14719: "null",
    14720: "null",
    14721: "null",
    14722: "null",
    14723: "null",
    14724: "null",
    14725: "null",
    14726: "null",
    14727: "null",
    14728: "null",
    14729: "null",
    14730: "null",
    14731: "null",
    14732: "null",
    14733: "null",
    14734: "null",
    14735: "null",
    14736: "null",
    14737: "null",
    14738: "null",
    14739: "null",
    14740: "null",
    14741: "null",
    14742: "null",
    14743: "null",
    14744: "null",
    14745: "null",
    14746: "null",
    14747: "null",
    14748: "null",
    14749: "null",
    14750: "null",
    14751: "null",
    14752: "null",
    14753: "null",
    14754: "null",
    14755: "null",
    14756: "null",
    14757: "null",
    14758: "null",
    14759: "null",
    14760: "null",
    14761: "null",
    14762: "null",
    14763: "null",
    14764: "null",
    14765: "null",
    14766: "null",
    14767: "null",
    14768: "null",
    14769: "null",
    14770: "null",
    14771: "null",
    14772: "null",
    14773: "null",
    14774: "null",
    14775: "null",
    14776: "null",
    14777: "null",
    14778: "null",
    14779: "null",
    14780: "null",
    14781: "null",
    14782: "null",
    14783: "null",
    14784: "null",
    14785: "null",
    14786: "null",
    14787: "null",
    14788: "null",
    14789: "null",
    14790: "null",
    14791: "null",
    14792: "null",
    14793: "null",
    14794: "null",
    14795: "null",
    14796: "null",
    14797: "null",
    14798: "null",
    14799: "null",
    14800: "null",
    14801: "null",
    14802: "null",
    14803: "null",
    14804: "null",
    14805: "null",
    14806: "null",
    14807: "null",
    14808: "null",
    14809: "null",
    14810: "null",
    14811: "null",
    14812: "null",
    14813: "null",
    14814: "null",
    14815: "null",
    14816: "null",
    14817: "null",
    14818: "null",
    14819: "null",
    14820: "null",
    14821: "null",
    14822: "null",
    14823: "null",
    14824: "null",
    14825: "null",
    14826: "null",
    14827: "null",
    14828: "null",
    14829: "null",
    14830: "null",
    14831: "null",
    14832: "null",
    14833: "null",
    14834: "null",
    14835: "null",
    14836: "null",
    14837: "null",
    14838: "null",
    14839: "null",
    14840: "null",
    14841: "null",
    14842: "null",
    14843: "null",
    14844: "null",
    14845: "null",
    14846: "null",
    14847: "null",
    14848: "null",
    14849: "null",
    14850: "null",
    14851: "null",
    14852: "null",
    14853: "null",
    14854: "null",
    14855: "null",
    14856: "null",
    14857: "null",
    14858: "null",
    14859: "null",
    14860: "null",
    14861: "null",
    14862: "null",
    14863: "null",
    14864: "null",
    14865: "null",
    14866: "null",
    14867: "null",
    14868: "null",
    14869: "null",
    14870: "null",
    14871: "null",
    14872: "null",
    14873: "null",
    14874: "null",
    14875: "null",
    14876: "null",
    14877: "null",
    14878: "null",
    14879: "null",
    14880: "null",
    14881: "null",
    14882: "null",
    14883: "null",
    14884: "null",
    14885: "null",
    14886: "null",
    14887: "null",
    14888: "null",
    14889: "null",
    14890: "null",
    14891: "null",
    14892: "null",
    14893: "null",
    14894: "null",
    14895: "null",
    14896: "null",
    14897: "null",
    14898: "null",
    14899: "null",
    14900: "null",
    14901: "null",
    14902: "null",
    14903: "null",
    14904: "null",
    14905: "null",
    14906: "null",
    14907: "null",
    14908: "null",
    14909: "null",
    14910: "null",
    14911: "null",
    14912: "null",
    14913: "null",
    14914: "null",
    14915: "null",
    14916: "null",
    14917: "null",
    14918: "null",
    14919: "null",
    14920: "null",
    14921: "null",
    14922: "null",
    14923: "null",
    14924: "null",
    14925: "null",
    14926: "null",
    14927: "null",
    14928: "null",
    14929: "null",
    14930: "null",
    14931: "null",
    14932: "null",
    14933: "null",
    14934: "null",
    14935: "null",
    14936: "null",
    14937: "null",
    14938: "null",
    14939: "null",
    14940: "null",
    14941: "null",
    14942: "null",
    14943: "null",
    14944: "null",
    14945: "null",
    14946: "null",
    14947: "null",
    14948: "null",
    14949: "null",
    14950: "null",
    14951: "null",
    14952: "null",
    14953: "null",
    14954: "null",
    14955: "null",
    14956: "null",
    14957: "null",
    14958: "null",
    14959: "null",
    14960: "null",
    14961: "null",
    14962: "null",
    14963: "null",
    14964: "null",
    14965: "null",
    14966: "null",
    14967: "null",
    14968: "null",
    14969: "null",
    14970: "null",
    14971: "null",
    14972: "null",
    14973: "null",
    14974: "null",
    14975: "null",
    14976: "null",
    14977: "null",
    14978: "null",
    14979: "null",
    14980: "null",
    14981: "null",
    14982: "null",
    14983: "null",
    14984: "null",
    14985: "null",
    14986: "null",
    14987: "null",
    14988: "null",
    14989: "null",
    14990: "null",
    14991: "null",
    14992: "null",
    14993: "null",
    14994: "null",
    14995: "null",
    14996: "null",
    14997: "null",
    14998: "null",
    14999: "null",
    15000: "null",
    15001: "null",
    15002: "null",
    15003: "null",
    15004: "null",
    15005: "null",
    15006: "null",
    15007: "null",
    15008: "null",
    15009: "null",
    15010: "null",
    15011: "null",
    15012: "null",
    15013: "null",
    15014: "null",
    15015: "null",
    15016: "null",
    15017: "null",
    15018: "null",
    15019: "null",
    15020: "null",
    15021: "null",
    15022: "null",
    15023: "null",
    15024: "null",
    15025: "null",
    15026: "null",
    15027: "null",
    15028: "null",
    15029: "null",
    15030: "null",
    15031: "null",
    15032: "null",
    15033: "null",
    15034: "null",
    15035: "null",
    15036: "null",
    15037: "null",
    15038: "null",
    15039: "null",
    15040: "null",
    15041: "null",
    15042: "null",
    15043: "null",
    15044: "null",
    15045: "null",
    15046: "null",
    15047: "null",
    15048: "null",
    15049: "null",
    15050: "null",
    15051: "null",
    15052: "null",
    15053: "null",
    15054: "null",
    15055: "null",
    15056: "null",
    15057: "null",
    15058: "null",
    15059: "null",
    15060: "null",
    15061: "null",
    15062: "null",
    15063: "null",
    15064: "null",
    15065: "null",
    15066: "null",
    15067: "null",
    15068: "null",
    15069: "null",
    15070: "null",
    15071: "null",
    15072: "null",
    15073: "null",
    15074: "null",
    15075: "null",
    15076: "null",
    15077: "null",
    15078: "null",
    15079: "null",
    15080: "null",
    15081: "null",
    15082: "null",
    15083: "null",
    15084: "null",
    15085: "null",
    15086: "null",
    15087: "null",
    15088: "null",
    15089: "null",
    15090: "null",
    15091: "null",
    15092: "null",
    15093: "null",
    15094: "null",
    15095: "null",
    15096: "null",
    15097: "null",
    15098: "null",
    15099: "null",
    15100: "null",
    15101: "null",
    15102: "null",
    15103: "null",
    15104: "null",
    15105: "null",
    15106: "null",
    15107: "null",
    15108: "null",
    15109: "null",
    15110: "null",
    15111: "null",
    15112: "null",
    15113: "null",
    15114: "null",
    15115: "null",
    15116: "null",
    15117: "null",
    15118: "null",
    15119: "null",
    15120: "null",
    15121: "null",
    15122: "null",
    15123: "null",
    15124: "null",
    15125: "null",
    15126: "null",
    15127: "null",
    15128: "null",
    15129: "null",
    15130: "null",
    15131: "null",
    15132: "null",
    15133: "null",
    15134: "null",
    15135: "null",
    15136: "null",
    15137: "null",
    15138: "null",
    15139: "null",
    15140: "null",
    15141: "null",
    15142: "null",
    15143: "null",
    15144: "null",
    15145: "null",
    15146: "null",
    15147: "null",
    15148: "null",
    15149: "null",
    15150: "null",
    15151: "null",
    15152: "null",
    15153: "null",
    15154: "null",
    15155: "null",
    15156: "null",
    15157: "null",
    15158: "null",
    15159: "null",
    15160: "null",
    15161: "null",
    15162: "null",
    15163: "null",
    15164: "null",
    15165: "null",
    15166: "null",
    15167: "null",
    15168: "null",
    15169: "null",
    15170: "null",
    15171: "null",
    15172: "null",
    15173: "null",
    15174: "null",
    15175: "null",
    15176: "null",
    15177: "null",
    15178: "null",
    15179: "null",
    15180: "null",
    15181: "null",
    15182: "null",
    15183: "null",
    15184: "null",
    15185: "null",
    15186: "null",
    15187: "null",
    15188: "null",
    15189: "null",
    15190: "null",
    15191: "null",
    15192: "null",
    15193: "null",
    15194: "null",
    15195: "null",
    15196: "null",
    15197: "null",
    15198: "null",
    15199: "null",
    15200: "null",
    15201: "null",
    15202: "null",
    15203: "null",
    15204: "null",
    15205: "null",
    15206: "null",
    15207: "null",
    15208: "null",
    15209: "null",
    15210: "null",
    15211: "null",
    15212: "null",
    15213: "null",
    15214: "null",
    15215: "null",
    15216: "null",
    15217: "null",
    15218: "null",
    15219: "null",
    15220: "null",
    15221: "null",
    15222: "null",
    15223: "null",
    15224: "null",
    15225: "null",
    15226: "null",
    15227: "null",
    15228: "null",
    15229: "null",
    15230: "null",
    15231: "null",
    15232: "null",
    15233: "null",
    15234: "null",
    15235: "null",
    15236: "null",
    15237: "null",
    15238: "null",
    15239: "null",
    15240: "null",
    15241: "null",
    15242: "null",
    15243: "null",
    15244: "null",
    15245: "null",
    15246: "null",
    15247: "null",
    15248: "null",
    15249: "null",
    15250: "null",
    15251: "null",
    15252: "null",
    15253: "null",
    15254: "null",
    15255: "null",
    15256: "null",
    15257: "null",
    15258: "null",
    15259: "null",
    15260: "null",
    15261: "null",
    15262: "null",
    15263: "null",
    15264: "null",
    15265: "null",
    15266: "null",
    15267: "null",
    15268: "null",
    15269: "null",
    15270: "null",
    15271: "null",
    15272: "null",
    15273: "null",
    15274: "null",
    15275: "null",
    15276: "null",
    15277: "null",
    15278: "null",
    15279: "null",
    15280: "null",
    15281: "null",
    15282: "null",
    15283: "null",
    15284: "null",
    15285: "null",
    15286: "null",
    15287: "null",
    15288: "null",
    15289: "null",
    15290: "null",
    15291: "null",
    15292: "null",
    15293: "null",
    15294: "null",
    15295: "null",
    15296: "null",
    15297: "Null",
    15298: "null",
    15299: "null",
    15300: "null",
    15301: "null",
    15302: "null",
    15303: "Null",
    15304: "Null",
    15305: "null",
    15306: "null",
    15307: "null",
    15308: "null",
    15309: "Null",
    15310: "null",
    15311: "null",
    15312: "null",
    15313: "null",
    15314: "null",
    15315: "null",
    15316: "null",
    15317: "null",
    15318: "null",
    15319: "null",
    15320: "null",
    15321: "null",
    15322: "null",
    15323: "null",
    15324: "null",
    15325: "null",
    15326: "null",
    15327: "null",
    15328: "null",
    15329: "null",
    15330: "null",
    15331: "null",
    15332: "null",
    15333: "null",
    15334: "null",
    15335: "null",
    15336: "null",
    15337: "null",
    15338: "null",
    15339: "null",
    15340: "null",
    15341: "null",
    15342: "null",
    15343: "null",
    15344: "Null",
    15345: "Null",
    15346: "Null",
    15347: "null",
    15348: "null",
    15349: "null",
    15350: "null",
    15351: "null",
    15352: "null",
    15353: "null",
    15354: "null",
    15355: "null",
    15356: "null",
    15357: "null",
    15358: "null",
    15359: "null",
    15360: "null",
    15361: "null",
    15362: "null",
    15363: "null",
    15364: "null",
    15365: "null",
    15366: "null",
    15367: "null",
    15368: "null",
    15369: "null",
    15370: "null",
    15371: "null",
    15372: "null",
    15373: "null",
    15374: "null",
    15375: "null",
    15376: "null",
    15377: "null",
    15378: "null",
    15379: "null",
    15380: "null",
    15381: "null",
    15382: "null",
    15383: "null",
    15384: "null",
    15385: "null",
    15386: "null",
    15387: "null",
    15388: "null",
    15389: "null",
    15390: "null",
    15391: "null",
    15392: "null",
    15393: "null",
    15394: "null",
    15395: "null",
    15396: "null",
    15397: "null",
    15398: "null",
    15399: "null",
    15400: "null",
    15401: "null",
    15402: "null",
    15403: "null",
    15404: "null",
    15405: "null",
    15406: "null",
    15407: "null",
    15408: "null",
    15409: "null",
    15410: "null",
    15411: "null",
    15412: "null",
    15413: "null",
    15414: "null",
    15415: "null",
    15416: "null",
    15417: "null",
    15418: "null",
    15419: "null",
    15420: "null",
    15421: "null",
    15422: "null",
    15423: "null",
    15424: "null",
    15425: "null",
    15426: "null",
    15427: "null",
    15428: "null",
    15429: "null",
    15430: "null",
    15431: "null",
    15432: "null",
    15433: "null",
    15434: "null",
    15435: "null",
    15436: "null",
    15437: "null",
    15438: "null",
    15439: "null",
    15440: "null",
    15441: "null",
    15442: "null",
    15443: "null",
    15444: "null",
    15445: "null",
    15446: "null",
    15447: "null",
    15448: "null",
    15449: "null",
    15450: "null",
    15451: "null",
    15452: "null",
    15453: "null",
    15454: "null",
    15455: "null",
    15456: "null",
    15457: "null",
    15458: "null",
    15459: "null",
    15460: "null",
    15461: "null",
    15462: "null",
    15463: "null",
    15464: "null",
    15465: "null",
    15466: "null",
    15467: "null",
    15468: "null",
    15469: "null",
    15470: "null",
    15471: "null",
    15472: "null",
    15473: "null",
    15474: "null",
    15475: "null",
    15476: "null",
    15477: "null",
    15478: "null",
    15479: "null",
    15480: "null",
    15481: "null",
    15482: "null",
    15483: "null",
    15484: "null",
    15485: "null",
    15486: "null",
    15487: "null",
    15488: "null",
    15489: "null",
    15490: "null",
    15491: "null",
    15492: "null",
    15493: "null",
    15494: "null",
    15495: "null",
    15496: "null",
    15497: "null",
    15498: "null",
    15499: "null",
    15500: "null",
    15501: "null",
    15502: "null",
    15503: "null",
    15504: "null",
    15505: "null",
    15506: "null",
    15507: "null",
    15508: "null",
    15509: "null",
    15510: "null",
    15511: "null",
    15512: "null",
    15513: "null",
    15514: "null",
    15515: "null",
    15516: "null",
    15517: "null",
    15518: "null",
    15519: "null",
    15520: "null",
    15521: "null",
    15522: "null",
    15523: "null",
    15524: "null",
    15525: "null",
    15526: "null",
    15527: "null",
    15528: "null",
    15529: "null",
    15530: "null",
    15531: "null",
    15532: "null",
    15533: "null",
    15534: "null",
    15535: "null",
    15536: "null",
    15537: "null",
    15538: "null",
    15539: "null",
    15540: "null",
    15541: "null",
    15542: "null",
    15543: "null",
    15544: "null",
    15545: "null",
    15546: "null",
    15547: "null",
    15548: "null",
    15549: "null",
    15550: "null",
    15551: "null",
    15552: "null",
    15553: "null",
    15554: "null",
    15555: "null",
    15556: "null",
    15557: "null",
    15558: "null",
    15559: "null",
    15560: "null",
    15561: "null",
    15562: "null",
    15563: "null",
    15564: "null",
    15565: "null",
    15566: "null",
    15567: "null",
    15568: "null",
    15569: "null",
    15570: "null",
    15571: "null",
    15572: "null",
    15573: "null",
    15574: "null",
    15575: "null",
    15576: "null",
    15577: "null",
    15578: "null",
    15579: "null",
    15580: "null",
    15581: "null",
    15582: "null",
    15583: "null",
    15584: "null",
    15585: "null",
    15586: "null",
    15587: "null",
    15588: "null",
    15589: "null",
    15590: "null",
    15591: "null",
    15592: "null",
    15593: "null",
    15594: "null",
    15595: "null",
    15596: "null",
    15597: "null",
    15598: "null",
    15599: "null",
    15600: "null",
    15601: "null",
    15602: "null",
    15603: "null",
    15604: "null",
    15605: "null",
    15606: "null",
    15607: "null",
    15608: "null",
    15609: "null",
    15610: "null",
    15611: "null",
    15612: "null",
    15613: "null",
    15614: "null",
    15615: "null",
    15616: "null",
    15617: "null",
    15618: "null",
    15619: "null",
    15620: "null",
    15621: "null",
    15622: "null",
    15623: "null",
    15624: "null",
    15625: "null",
    15626: "null",
    15627: "null",
    15628: "null",
    15629: "null",
    15630: "null",
    15631: "null",
    15632: "null",
    15633: "null",
    15634: "null",
    15635: "null",
    15636: "null",
    15637: "null",
    15638: "null",
    15639: "null",
    15640: "null",
    15641: "null",
    15642: "null",
    15643: "null",
    15644: "null",
    15645: "null",
    15646: "null",
    15647: "null",
    15648: "null",
    15649: "null",
    15650: "null",
    15651: "null",
    15652: "null",
    15653: "null",
    15654: "null",
    15655: "null",
    15656: "null",
    15657: "null",
    15658: "null",
    15659: "null",
    15660: "null",
    15661: "null",
    15662: "null",
    15663: "null",
    15664: "null",
    15665: "null",
    15666: "null",
    15667: "null",
    15668: "null",
    15669: "null",
    15670: "null",
    15671: "null",
    15672: "null",
    15673: "null",
    15674: "null",
    15675: "null",
    15676: "null",
    15677: "null",
    15678: "null",
    15679: "null",
    15680: "null",
    15681: "null",
    15682: "null",
    15683: "null",
    15684: "null",
    15685: "null",
    15686: "null",
    15687: "null",
    15688: "null",
    15689: "null",
    15690: "null",
    15691: "null",
    15692: "null",
    15693: "null",
    15694: "null",
    15695: "null",
    15696: "null",
    15697: "null",
    15698: "null",
    15699: "null",
    15700: "null",
    15701: "null",
    15702: "null",
    15703: "null",
    15704: "null",
    15705: "null",
    15706: "null",
    15707: "null",
    15708: "null",
    15709: "null",
    15710: "null",
    15711: "null",
    15712: "null",
    15713: "null",
    15714: "null",
    15715: "null",
    15716: "null",
    15717: "null",
    15718: "null",
    15719: "null",
    15720: "null",
    15721: "null",
    15722: "null",
    15723: "null",
    15724: "null",
    15725: "null",
    15726: "null",
    15727: "null",
    15728: "null",
    15729: "null",
    15730: "null",
    15731: "null",
    15732: "null",
    15733: "null",
    15734: "null",
    15735: "null",
    15736: "null",
    15737: "null",
    15738: "null",
    15739: "null",
    15740: "null",
    15741: "null",
    15742: "null",
    15743: "null",
    15744: "null",
    15745: "null",
    15746: "null",
    15747: "null",
    15748: "null",
    15749: "null",
    15750: "null",
    15751: "null",
    15752: "null",
    15753: "null",
    15754: "null",
    15755: "null",
    15756: "null",
    15757: "null",
    15758: "null",
    15759: "null",
    15760: "null",
    15761: "null",
    15762: "null",
    15763: "null",
    15764: "null",
    15765: "null",
    15766: "null",
    15767: "null",
    15768: "null",
    15769: "null",
    15770: "null",
    15771: "null",
    15772: "null",
    15773: "null",
    15774: "null",
    15775: "null",
    15776: "null",
    15777: "null",
    15778: "null",
    15779: "null",
    15780: "null",
    15781: "null",
    15782: "null",
    15783: "null",
    15784: "null",
    15785: "null",
    15786: "null",
    15787: "null",
    15788: "null",
    15789: "null",
    15790: "null",
    15791: "null",
    15792: "null",
    15793: "null",
    15794: "null",
    15795: "null",
    15796: "null",
    15797: "null",
    15798: "null",
    15799: "null",
    15800: "null",
    15801: "null",
    15802: "null",
    15803: "null",
    15804: "null",
    15805: "null",
    15806: "null",
    15807: "null",
    15808: "null",
    15809: "null",
    15810: "null",
    15811: "null",
    15812: "null",
    15813: "null",
    15814: "null",
    15815: "null",
    15816: "null",
    15817: "null",
    15818: "null",
    15819: "null",
    15820: "null",
    15821: "null",
    15822: "null",
    15823: "null",
    15824: "null",
    15825: "null",
    15826: "null",
    15827: "null",
    15828: "null",
    15829: "null",
    15830: "null",
    15831: "null",
    15832: "null",
    15833: "null",
    15834: "null",
    15835: "null",
    15836: "null",
    15837: "null",
    15838: "null",
    15839: "null",
    15840: "null",
    15841: "null",
    15842: "null",
    15843: "null",
    15844: "null",
    15845: "null",
    15846: "null",
    15847: "null",
    15848: "null",
    15849: "null",
    15850: "null",
    15851: "null",
    15852: "null",
    15853: "null",
    15854: "null",
    15855: "null",
    15856: "null",
    15857: "null",
    15858: "null",
    15859: "null",
    15860: "null",
    15861: "null",
    15862: "null",
    15863: "null",
    15864: "null",
    15865: "null",
    15866: "null",
    15867: "null",
    15868: "null",
    15869: "null",
    15870: "null",
    15871: "null",
    15872: "null",
    15873: "null",
    15874: "null",
    15875: "null",
    15876: "null",
    15877: "null",
    15878: "null",
    15879: "null",
    15880: "null",
    15881: "null",
    15882: "null",
    15883: "null",
    15884: "null",
    15885: "null",
    15886: "null",
    15887: "null",
    15888: "null",
    15889: "null",
    15890: "null",
    15891: "null",
    15892: "null",
    15893: "null",
    15894: "null",
    15895: "null",
    15896: "null",
    15897: "null",
    15898: "null",
    15899: "null",
    15900: "null",
    15901: "null",
    15902: "null",
    15903: "null",
    15904: "null",
    15905: "null",
    15906: "null",
    15907: "null",
    15908: "null",
    15909: "null",
    15910: "null",
    15911: "null",
    15912: "null",
    15913: "null",
    15914: "null",
    15915: "null",
    15916: "null",
    15917: "null",
    15918: "null",
    15919: "null",
    15920: "null",
    15921: "null",
    15922: "null",
    15923: "null",
    15924: "null",
    15925: "null",
    15926: "null",
    15927: "null",
    15928: "null",
    15929: "null",
    15930: "null",
    15931: "null",
    15932: "null",
    15933: "null",
    15934: "null",
    15935: "null",
    15936: "null",
    15937: "null",
    15938: "null",
    15939: "null",
    15940: "null",
    15941: "null",
    15942: "null",
    15943: "null",
    15944: "null",
    15945: "null",
    15946: "null",
    15947: "null",
    15948: "null",
    15949: "null",
    15950: "null",
    15951: "null",
    15952: "null",
    15953: "null",
    15954: "null",
    15955: "null",
    15956: "null",
    15957: "null",
    15958: "null",
    15959: "null",
    15960: "null",
    15961: "null",
    15962: "null",
    15963: "null",
    15964: "null",
    15965: "null",
    15966: "null",
    15967: "null",
    15968: "null",
    15969: "null",
    15970: "null",
    15971: "null",
    15972: "null",
    15973: "null",
    15974: "null",
    15975: "null",
    15976: "null",
    15977: "null",
    15978: "null",
    15979: "null",
    15980: "null",
    15981: "null",
    15982: "null",
    15983: "null",
    15984: "null",
    15985: "null",
    15986: "null",
    15987: "null",
    15988: "null",
    15989: "null",
    15990: "null",
    15991: "null",
    15992: "null",
    15993: "null",
    15994: "null",
    15995: "null",
    15996: "null",
    15997: "null",
    15998: "null",
    15999: "null",
    16000: "null",
    16001: "null",
    16002: "null",
    16003: "null",
    16004: "null",
    16005: "null",
    16006: "null",
    16007: "null",
    16008: "null",
    16009: "null",
    16010: "null",
    16011: "null",
    16012: "null",
    16013: "null",
    16014: "null",
    16015: "null",
    16016: "null",
    16017: "null",
    16018: "null",
    16019: "null",
    16020: "null",
    16021: "null",
    16022: "null",
    16023: "null",
    16024: "null",
    16025: "null",
    16026: "null",
    16027: "null",
    16028: "null",
    16029: "null",
    16030: "null",
    16031: "null",
    16032: "null",
    16033: "null",
    16034: "null",
    16035: "null",
    16036: "null",
    16037: "null",
    16038: "null",
    16039: "null",
    16040: "null",
    16041: "null",
    16042: "null",
    16043: "null",
    16044: "null",
    16045: "null",
    16046: "null",
    16047: "null",
    16048: "null",
    16049: "null",
    16050: "null",
    16051: "null",
    16052: "null",
    16053: "null",
    16054: "null",
    16055: "null",
    16056: "null",
    16057: "null",
    16058: "null",
    16059: "null",
    16060: "null",
    16061: "null",
    16062: "null",
    16063: "null",
    16064: "null",
    16065: "null",
    16066: "null",
    16067: "null",
    16068: "null",
    16069: "null",
    16070: "null",
    16071: "null",
    16072: "null",
    16073: "null",
    16074: "null",
    16075: "null",
    16076: "null",
    16077: "null",
    16078: "null",
    16079: "null",
    16080: "null",
    16081: "null",
    16082: "null",
    16083: "null",
    16084: "null",
    16085: "null",
    16086: "null",
    16087: "null",
    16088: "null",
    16089: "null",
    16090: "null",
    16091: "null",
    16092: "null",
    16093: "null",
    16094: "null",
    16095: "null",
    16096: "null",
    16097: "null",
    16098: "null",
    16099: "null",
    16100: "null",
    16101: "null",
    16102: "null",
    16103: "null",
    16104: "null",
    16105: "null",
    16106: "null",
    16107: "null",
    16108: "null",
    16109: "null",
    16110: "null",
    16111: "null",
    16112: "null",
    16113: "null",
    16114: "null",
    16115: "null",
    16116: "null",
    16117: "null",
    16118: "null",
    16119: "null",
    16120: "null",
    16121: "null",
    16122: "null",
    16123: "null",
    16124: "null",
    16125: "null",
    16126: "null",
    16127: "null",
    16128: "null",
    16129: "null",
    16130: "null",
    16131: "null",
    16132: "null",
    16133: "null",
    16134: "null",
    16135: "null",
    16136: "null",
    16137: "null",
    16138: "null",
    16139: "null",
    16140: "null",
    16141: "null",
    16142: "null",
    16143: "null",
    16144: "null",
    16145: "null",
    16146: "null",
    16147: "null",
    16148: "null",
    16149: "null",
    16150: "null",
    16151: "null",
    16152: "null",
    16153: "null",
    16154: "null",
    16155: "null",
    16156: "null",
    16157: "null",
    16158: "null",
    16159: "null",
    16160: "null",
    16161: "null",
    16162: "null",
    16163: "null",
    16164: "null",
    16165: "null",
    16166: "null",
    16167: "null",
    16168: "null",
    16169: "null",
    16170: "null",
    16171: "null",
    16172: "null",
    16173: "null",
    16174: "null",
    16175: "null",
    16176: "Pharaoh's sceptre",
    16177: "null",
    16178: "null",
    16179: "null",
    16180: "null",
    16181: "null",
    16182: "null",
    16183: "null",
    16184: "null",
    16185: "null",
    16186: "null",
    16187: "null",
    16188: "null",
    16189: "null",
    16190: "null",
    16191: "null",
    16192: "null",
    16193: "null",
    16194: "null",
    16195: "null",
    16196: "null",
    16197: "null",
    16198: "null",
    16199: "null",
    16200: "null",
    16201: "null",
    16202: "null",
    16203: "null",
    16204: "null",
    16205: "null",
    16206: "null",
    16207: "null",
    16208: "null",
    16209: "null",
    16210: "null",
    16211: "null",
    16212: "null",
    16213: "null",
    16214: "null",
    16215: "null",
    16216: "null",
    16217: "null",
    16218: "null",
    16219: "null",
    16220: "null",
    16221: "null",
    16222: "null",
    16223: "null",
    16224: "null",
    16225: "null",
    16226: "null",
    16227: "null",
    16228: "null",
    16229: "null",
    16230: "null",
    16231: "null",
    16232: "null",
    16233: "null",
    16234: "null",
    16235: "null",
    16236: "null",
    16237: "null",
    16238: "null",
    16239: "null",
    16240: "null",
    16241: "null",
    16242: "null",
    16243: "null",
    16244: "null",
    16245: "null",
    16246: "null",
    16247: "null",
    16248: "null",
    16249: "null",
    16250: "null",
    16251: "null",
    16252: "null",
    16253: "null",
    16254: "null",
    16255: "null",
    16256: "null",
    16257: "null",
    16258: "null",
    16259: "null",
    16260: "null",
    16261: "null",
    16262: "null",
    16263: "null",
    16264: "null",
    16265: "null",
    16266: "null",
    16267: "null",
    16268: "null",
    16269: "null",
    16270: "null",
    16271: "null",
    16272: "null",
    16273: "null",
    16274: "null",
    16275: "null",
    16276: "null",
    16277: "null",
    16278: "null",
    16279: "null",
    16280: "null",
    16281: "null",
    16282: "null",
    16283: "null",
    16284: "null",
    16285: "null",
    16286: "null",
    16287: "null",
    16288: "null",
    16289: "null",
    16290: "null",
    16291: "null",
    16292: "null",
    16293: "null",
    16294: "null",
    16295: "null",
    16296: "null",
    16297: "null",
    16298: "null",
    16299: "null",
    16300: "null",
    16301: "null",
    16302: "null",
    16303: "null",
    16304: "null",
    16305: "null",
    16306: "null",
    16307: "null",
    16308: "null",
    16309: "null",
    16310: "null",
    16311: "null",
    16312: "null",
    16313: "null",
    16314: "null",
    16315: "null",
    16316: "null",
    16317: "null",
    16318: "null",
    16319: "null",
    16320: "null",
    16321: "null",
    16322: "null",
    16323: "null",
    16324: "null",
    16325: "null",
    16326: "null",
    16327: "null",
    16328: "null",
    16329: "null",
    16330: "null",
    16331: "null",
    16332: "null",
    16333: "null",
    16334: "null",
    16335: "null",
    16336: "null",
    16337: "null",
    16338: "null",
    16339: "null",
    16340: "null",
    16341: "null",
    16342: "null",
    16343: "null",
    16344: "null",
    16345: "null",
    16346: "null",
    16347: "null",
    16348: "null",
    16349: "null",
    16350: "null",
    16351: "null",
    16352: "null",
    16353: "null",
    16354: "null",
    16355: "null",
    16356: "null",
    16357: "null",
    16358: "null",
    16359: "null",
    16360: "null",
    16361: "null",
    16362: "null",
    16363: "null",
    16364: "null",
    16365: "null",
    16366: "null",
    16367: "null",
    16368: "null",
    16369: "null",
    16370: "null",
    16371: "null",
    16372: "null",
    16373: "null",
    16374: "null",
    16375: "null",
    16376: "null",
    16377: "null",
    16378: "null",
    16379: "null",
    16380: "null",
    16381: "null",
    16382: "null",
    16383: "null",
    16384: "null",
    16385: "null",
    16386: "null",
    16387: "null",
    16388: "null",
    16389: "null",
    16390: "null",
    16391: "null",
    16392: "null",
    16393: "null",
    16394: "null",
    16395: "null",
    16396: "null",
    16397: "null",
    16398: "null",
    16399: "null",
    16400: "null",
    16401: "null",
    16402: "null",
    16403: "null",
    16404: "null",
    16405: "null",
    16406: "null",
    16407: "null",
    16408: "null",
    16409: "null",
    16410: "null",
    16411: "null",
    16412: "null",
    16413: "null",
    16414: "null",
    16415: "null",
    16416: "null",
    16417: "null",
    16418: "null",
    16419: "null",
    16420: "null",
    16421: "null",
    16422: "null",
    16423: "null",
    16424: "null",
    16425: "null",
    16426: "null",
    16427: "null",
    16428: "null",
    16429: "null",
    16430: "null",
    16431: "null",
    16432: "null",
    16433: "null",
    16434: "null",
    16435: "null",
    16436: "null",
    16437: "null",
    16438: "null",
    16439: "null",
    16440: "null",
    16441: "null",
    16442: "null",
    16443: "null",
    16444: "null",
    16445: "null",
    16446: "null",
    16447: "null",
    16448: "null",
    16449: "null",
    16450: "null",
    16451: "null",
    16452: "null",
    16453: "null",
    16454: "null",
    16455: "null",
    16456: "null",
    16457: "null",
    16458: "null",
    16459: "null",
    16460: "null",
    16461: "null",
    16462: "null",
    16463: "null",
    16464: "null",
    16465: "null",
    16466: "null",
    16467: "null",
    16468: "null",
    16469: "null",
    16470: "null",
    16471: "null",
    16472: "null",
    16473: "null",
    16474: "null",
    16475: "null",
    16476: "null",
    16477: "null",
    16478: "null",
    16479: "null",
    16480: "null",
    16481: "null",
    16482: "null",
    16483: "null",
    16484: "null",
    16485: "null",
    16486: "null",
    16487: "null",
    16488: "null",
    16489: "null",
    16490: "null",
    16491: "null",
    16492: "null",
    16493: "null",
    16494: "null",
    16495: "null",
    16496: "null",
    16497: "null",
    16498: "null",
    16499: "null",
    16500: "null",
    16501: "null",
    16502: "null",
    16503: "null",
    16504: "null",
    16505: "null",
    16506: "null",
    16507: "null",
    16508: "null",
    16509: "null",
    16510: "null",
    16511: "null",
    16512: "null",
    16513: "null",
    16514: "null",
    16515: "null",
    16516: "null",
    16517: "null",
    16518: "null",
    16519: "null",
    16520: "null",
    16521: "null",
    16522: "null",
    16523: "null",
    16524: "null",
    16525: "null",
    16526: "null",
    16527: "null",
    16528: "null",
    16529: "null",
    16530: "null",
    16531: "null",
    16532: "null",
    16533: "null",
    16534: "null",
    16535: "null",
    16536: "null",
    16537: "null",
    16538: "null",
    16539: "null",
    16540: "null",
    16541: "null",
    16542: "null",
    16543: "null",
    16544: "null",
    16545: "null",
    16546: "null",
    16547: "null",
    16548: "null",
    16549: "null",
    16550: "null",
    16551: "null",
    16552: "null",
    16553: "null",
    16554: "null",
    16555: "null",
    16556: "null",
    16557: "null",
    16558: "null",
    16559: "null",
    16560: "null",
    16561: "null",
    16562: "null",
    16563: "null",
    16564: "null",
    16565: "null",
    16566: "null",
    16567: "null",
    16568: "null",
    16569: "null",
    16570: "null",
    16571: "null",
    16572: "null",
    16573: "null",
    16574: "null",
    16575: "null",
    16576: "null",
    16577: "null",
    16578: "null",
    16579: "null",
    16580: "null",
    16581: "null",
    16582: "null",
    16583: "null",
    16584: "null",
    16585: "null",
    16586: "null",
    16587: "null",
    16588: "null",
    16589: "null",
    16590: "null",
    16591: "null",
    16592: "null",
    16593: "null",
    16594: "null",
    16595: "null",
    16596: "null",
    16597: "null",
    16598: "null",
    16599: "null",
    16600: "null",
    16601: "null",
    16602: "null",
    16603: "null",
    16604: "null",
    16605: "null",
    16606: "null",
    16607: "null",
    16608: "null",
    16609: "null",
    16610: "null",
    16611: "null",
    16612: "null",
    16613: "null",
    16614: "null",
    16615: "null",
    16616: "null",
    16617: "null",
    16618: "null",
    16619: "null",
    16620: "null",
    16621: "null",
    16622: "null",
    16623: "null",
    16624: "null",
    16625: "null",
    16626: "null",
    16627: "null",
    16628: "null",
    16629: "null",
    16630: "null",
    16631: "null",
    16632: "null",
    16633: "null",
    16634: "null",
    16635: "null",
    16636: "null",
    16637: "null",
    16638: "null",
    16639: "null",
    16640: "null",
    16641: "null",
    16642: "null",
    16643: "null",
    16644: "null",
    16645: "null",
    16646: "null",
    16647: "null",
    16648: "null",
    16649: "null",
    16650: "null",
    16651: "null",
    16652: "null",
    16653: "null",
    16654: "null",
    16655: "null",
    16656: "null",
    16657: "null",
    16658: "null",
    16659: "null",
    16660: "null",
    16661: "null",
    16662: "null",
    16663: "null",
    16664: "null",
    16665: "null",
    16666: "null",
    16667: "null",
    16668: "null",
    16669: "null",
    16670: "null",
    16671: "null",
    16672: "null",
    16673: "null",
    16674: "null",
    16675: "null",
    16676: "null",
    16677: "null",
    16678: "null",
    16679: "null",
    16680: "null",
    16681: "null",
    16682: "null",
    16683: "null",
    16684: "Useless key",
    16685: "null",
    16686: "null",
    16687: "null",
    16688: "null",
    16689: "null",
    16690: "null",
    16691: "null",
    16692: "null",
    16693: "null",
    16694: "null",
    16695: "null",
    16696: "null",
    16697: "null",
    16698: "null",
    16699: "null",
    16700: "null",
    16701: "null",
    16702: "null",
    16703: "null",
    16704: "null",
    16705: "null",
    16706: "null",
    16707: "null",
    16708: "null",
    16709: "null",
    16710: "null",
    16711: "null",
    16712: "null",
    16713: "null",
    16714: "null",
    16715: "null",
    16716: "null",
    16717: "null",
    16718: "null",
    16719: "null",
    16720: "null",
    16721: "null",
    16722: "null",
    16723: "null",
    16724: "null",
    16725: "null",
    16726: "null",
    16727: "null",
    16728: "null",
    16729: "null",
    16730: "null",
    16731: "null",
    16732: "null",
    16733: "null",
    16734: "null",
    16735: "null",
    16736: "null",
    16737: "null",
    16738: "null",
    16739: "null",
    16740: "null",
    16741: "null",
    16742: "null",
    16743: "null",
    16744: "null",
    16745: "null",
    16746: "null",
    16747: "null",
    16748: "null",
    16749: "null",
    16750: "null",
    16751: "null",
    16752: "null",
    16753: "null",
    16754: "null",
    16755: "null",
    16756: "null",
    16757: "null",
    16758: "null",
    16759: "null",
    16760: "null",
    16761: "null",
    16762: "null",
    16763: "null",
    16764: "null",
    16765: "null",
    16766: "null",
    16767: "null",
    16768: "null",
    16769: "null",
    16770: "null",
    16771: "null",
    16772: "null",
    16773: "null",
    16774: "null",
    16775: "null",
    16776: "null",
    16777: "null",
    16778: "null",
    16779: "null",
    16780: "null",
    16781: "null",
    16782: "null",
    16783: "null",
    16784: "null",
    16785: "null",
    16786: "null",
    16787: "null",
    16788: "null",
    16789: "null",
    16790: "null",
    16791: "null",
    16792: "null",
    16793: "null",
    16794: "null",
    16795: "null",
    16796: "null",
    16797: "null",
    16798: "null",
    16799: "null",
    16800: "null",
    16801: "null",
    16802: "null",
    16803: "null",
    16804: "null",
    16805: "null",
    16806: "null",
    16807: "null",
    16808: "null",
    16809: "null",
    16810: "null",
    16811: "null",
    16812: "null",
    16813: "null",
    16814: "null",
    16815: "null",
    16816: "null",
    16817: "null",
    16818: "null",
    16819: "null",
    16820: "null",
    16821: "null",
    16822: "null",
    16823: "null",
    16824: "null",
    16825: "null",
    16826: "null",
    16827: "null",
    16828: "null",
    16829: "null",
    16830: "null",
    16831: "null",
    16832: "null",
    16833: "null",
    16834: "null",
    16835: "null",
    16836: "null",
    16837: "null",
    16838: "null",
    16839: "null",
    16840: "null",
    16841: "null",
    16842: "null",
    16843: "null",
    16844: "null",
    16845: "null",
    16846: "null",
    16847: "null",
    16848: "null",
    16849: "null",
    16850: "null",
    16851: "null",
    16852: "null",
    16853: "null",
    16854: "null",
    16855: "null",
    16856: "null",
    16857: "null",
    16858: "null",
    16859: "null",
    16860: "null",
    16861: "null",
    16862: "null",
    16863: "null",
    16864: "null",
    16865: "null",
    16866: "null",
    16867: "null",
    16868: "null",
    16869: "null",
    16870: "null",
    16871: "null",
    16872: "null",
    16873: "null",
    16874: "null",
    16875: "null",
    16876: "null",
    16877: "null",
    16878: "null",
    16879: "null",
    16880: "null",
    16881: "null",
    16882: "null",
    16883: "null",
    16884: "null",
    16885: "null",
    16886: "null",
    16887: "null",
    16888: "New crystal bow",
    16889: "New crystal bow (i)",
    16890: "New crystal shield",
    16891: "New crystal shield (i)",
    16892: "New crystal halberd full (i)",
    16893: "New crystal halberd full",
    16894: "null",
    16895: "null",
    16896: "null",
    16897: "null",
    16898: "null",
    16899: "null",
    16900: "null",
    16901: "null",
    16902: "null",
    16903: "null",
    16904: "null",
    16905: "null",
    16906: "null",
    16907: "null",
    16908: "null",
    16909: "null",
    16910: "null",
    16911: "null",
    16912: "null",
    16913: "null",
    16914: "null",
    16915: "null",
    16916: "null",
    16917: "null",
    16918: "null",
    16919: "null",
    16920: "null",
    16921: "null",
    16922: "null",
    16923: "null",
    16924: "null",
    16925: "null",
    16926: "null",
    16927: "null",
    16928: "null",
    16929: "null",
    16930: "null",
    16931: "null",
    16932: "null",
    16933: "null",
    16934: "null",
    16935: "null",
    16936: "null",
    16937: "null",
    16938: "null",
    16939: "null",
    16940: "null",
    16941: "null",
    16942: "null",
    16943: "null",
    16944: "null",
    16945: "null",
    16946: "null",
    16947: "null",
    16948: "null",
    16949: "null",
    16950: "null",
    16951: "null",
    16952: "null",
    16953: "null",
    16954: "null",
    16955: "null",
    16956: "null",
    16957: "null",
    16958: "null",
    16959: "null",
    16960: "null",
    16961: "null",
    16962: "null",
    16963: "null",
    16964: "null",
    16965: "null",
    16966: "null",
    16967: "null",
    16968: "null",
    16969: "null",
    16970: "null",
    16971: "null",
    16972: "null",
    16973: "null",
    16974: "null",
    16975: "null",
    16976: "null",
    16977: "null",
    16978: "null",
    16979: "null",
    16980: "null",
    16981: "null",
    16982: "null",
    16983: "null",
    16984: "null",
    16985: "null",
    16986: "null",
    16987: "null",
    16988: "null",
    16989: "null",
    16990: "null",
    16991: "null",
    16992: "null",
    16993: "null",
    16994: "null",
    16995: "null",
    16996: "null",
    16997: "null",
    16998: "null",
    16999: "null",
    17000: "null",
    17001: "null",
    17002: "null",
    17003: "null",
    17004: "null",
    17005: "null",
    17006: "null",
    17007: "null",
    17008: "null",
    17009: "null",
    17010: "null",
    17011: "null",
    17012: "null",
    17013: "null",
    17014: "null",
    17015: "null",
    17016: "null",
    17017: "null",
    17018: "null",
    17019: "null",
    17020: "null",
    17021: "null",
    17022: "null",
    17023: "null",
    17024: "null",
    17025: "null",
    17026: "null",
    17027: "null",
    17028: "null",
    17029: "null",
    17030: "null",
    17031: "null",
    17032: "null",
    17033: "null",
    17034: "null",
    17035: "null",
    17036: "null",
    17037: "null",
    17038: "null",
    17039: "null",
    17040: "null",
    17041: "null",
    17042: "null",
    17043: "null",
    17044: "null",
    17045: "null",
    17046: "null",
    17047: "null",
    17048: "null",
    17049: "null",
    17050: "null",
    17051: "null",
    17052: "null",
    17053: "null",
    17054: "null",
    17055: "null",
    17056: "null",
    17057: "null",
    17058: "null",
    17059: "null",
    17060: "null",
    17061: "null",
    17062: "null",
    17063: "null",
    17064: "null",
    17065: "null",
    17066: "null",
    17067: "null",
    17068: "null",
    17069: "null",
    17070: "null",
    17071: "null",
    17072: "null",
    17073: "null",
    17074: "null",
    17075: "null",
    17076: "null",
    17077: "null",
    17078: "null",
    17079: "null",
    17080: "null",
    17081: "null",
    17082: "null",
    17083: "null",
    17084: "null",
    17085: "null",
    17086: "null",
    17087: "null",
    17088: "null",
    17089: "null",
    17090: "null",
    17091: "null",
    17092: "null",
    17093: "null",
    17094: "null",
    17095: "null",
    17096: "null",
    17097: "null",
    17098: "null",
    17099: "null",
    17100: "null",
    17101: "null",
    17102: "null",
    17103: "null",
    17104: "null",
    17105: "null",
    17106: "null",
    17107: "null",
    17108: "null",
    17109: "null",
    17110: "null",
    17111: "null",
    17112: "null",
    17113: "null",
    17114: "null",
    17115: "null",
    17116: "null",
    17117: "null",
    17118: "null",
    17119: "null",
    17120: "null",
    17121: "null",
    17122: "null",
    17123: "null",
    17124: "null",
    17125: "null",
    17126: "null",
    17127: "null",
    17128: "null",
    17129: "null",
    17130: "null",
    17131: "null",
    17132: "null",
    17133: "null",
    17134: "null",
    17135: "null",
    17136: "null",
    17137: "null",
    17138: "null",
    17139: "null",
    17140: "null",
    17141: "null",
    17142: "null",
    17143: "null",
    17144: "null",
    17145: "null",
    17146: "null",
    17147: "null",
    17148: "null",
    17149: "null",
    17150: "null",
    17151: "null",
    17152: "Dead orb",
    17153: "null",
    17154: "null",
    17155: "null",
    17156: "null",
    17157: "null",
    17158: "null",
    17159: "null",
    17160: "null",
    17161: "null",
    17162: "null",
    17163: "null",
    17164: "null",
    17165: "null",
    17166: "null",
    17167: "null",
    17168: "null",
    17169: "null",
    17170: "null",
    17171: "null",
    17172: "null",
    17173: "null",
    17174: "null",
    17175: "null",
    17176: "null",
    17177: "null",
    17178: "null",
    17179: "null",
    17180: "null",
    17181: "null",
    17182: "null",
    17183: "null",
    17184: "null",
    17185: "null",
    17186: "null",
    17187: "null",
    17188: "null",
    17189: "null",
    17190: "null",
    17191: "null",
    17192: "null",
    17193: "null",
    17194: "null",
    17195: "null",
    17196: "null",
    17197: "null",
    17198: "null",
    17199: "null",
    17200: "null",
    17201: "null",
    17202: "null",
    17203: "null",
    17204: "null",
    17205: "null",
    17206: "null",
    17207: "null",
    17208: "null",
    17209: "null",
    17210: "null",
    17211: "null",
    17212: "null",
    17213: "null",
    17214: "null",
    17215: "null",
    17216: "null",
    17217: "null",
    17218: "null",
    17219: "null",
    17220: "null",
    17221: "null",
    17222: "null",
    17223: "null",
    17224: "null",
    17225: "null",
    17226: "null",
    17227: "null",
    17228: "null",
    17229: "null",
    17230: "null",
    17231: "null",
    17232: "null",
    17233: "null",
    17234: "null",
    17235: "null",
    17236: "null",
    17237: "null",
    17238: "null",
    17239: "null",
    17240: "null",
    17241: "null",
    17242: "null",
    17243: "null",
    17244: "null",
    17245: "null",
    17246: "null",
    17247: "null",
    17248: "null",
    17249: "null",
    17250: "null",
    17251: "null",
    17252: "null",
    17253: "null",
    17254: "null",
    17255: "null",
    17256: "null",
    17257: "null",
    17258: "null",
    17259: "null",
    17260: "null",
    17261: "null",
    17262: "null",
    17263: "null",
    17264: "null",
    17265: "null",
    17266: "null",
    17267: "null",
    17268: "null",
    17269: "null",
    17270: "null",
    17271: "null",
    17272: "null",
    17273: "null",
    17274: "null",
    17275: "null",
    17276: "null",
    17277: "null",
    17278: "null",
    17279: "null",
    17280: "null",
    17281: "null",
    17282: "null",
    17283: "null",
    17284: "null",
    17285: "null",
    17286: "null",
    17287: "null",
    17288: "null",
    17289: "null",
    17290: "null",
    17291: "null",
    17292: "null",
    17293: "null",
    17294: "null",
    17295: "null",
    17296: "null",
    17297: "null",
    17298: "null",
    17299: "null",
    17300: "null",
    17301: "null",
    17302: "null",
    17303: "null",
    17304: "null",
    17305: "null",
    17306: "null",
    17307: "null",
    17308: "null",
    17309: "null",
    17310: "null",
    17311: "null",
    17312: "null",
    17313: "null",
    17314: "null",
    17315: "null",
    17316: "null",
    17317: "null",
    17318: "null",
    17319: "null",
    17320: "null",
    17321: "null",
    17322: "null",
    17323: "null",
    17324: "null",
    17325: "null",
    17326: "null",
    17327: "null",
    17328: "null",
    17329: "null",
    17330: "null",
    17331: "null",
    17332: "null",
    17333: "null",
    17334: "null",
    17335: "null",
    17336: "null",
    17337: "null",
    17338: "null",
    17339: "null",
    17340: "null",
    17341: "null",
    17342: "null",
    17343: "null",
    17344: "null",
    17345: "null",
    17346: "null",
    17347: "null",
    17348: "null",
    17349: "null",
    17350: "null",
    17351: "null",
    17352: "null",
    17353: "null",
    17354: "null",
    17355: "null",
    17356: "null",
    17357: "null",
    17358: "null",
    17359: "null",
    17360: "null",
    17361: "null",
    17362: "null",
    17363: "null",
    17364: "null",
    17365: "null",
    17366: "null",
    17367: "null",
    17368: "null",
    17369: "null",
    17370: "null",
    17371: "null",
    17372: "null",
    17373: "null",
    17374: "null",
    17375: "null",
    17376: "null",
    17377: "null",
    17378: "null",
    17379: "null",
    17380: "null",
    17381: "null",
    17382: "null",
    17383: "null",
    17384: "null",
    17385: "null",
    17386: "null",
    17387: "null",
    17388: "null",
    17389: "null",
    17390: "null",
    17391: "null",
    17392: "null",
    17393: "null",
    17394: "null",
    17395: "null",
    17396: "null",
    17397: "null",
    17398: "null",
    17399: "null",
    17400: "null",
    17401: "null",
    17402: "null",
    17403: "null",
    17404: "null",
    17405: "null",
    17406: "null",
    17407: "null",
    17408: "null",
    17409: "null",
    17410: "null",
    17411: "null",
    17412: "null",
    17413: "null",
    17414: "null",
    17415: "null",
    17416: "null",
    17417: "null",
    17418: "null",
    17419: "null",
    17420: "null",
    17421: "null",
    17422: "null",
    17423: "null",
    17424: "null",
    17425: "null",
    17426: "null",
    17427: "null",
    17428: "null",
    17429: "null",
    17430: "null",
    17431: "null",
    17432: "null",
    17433: "null",
    17434: "null",
    17435: "null",
    17436: "null",
    17437: "null",
    17438: "null",
    17439: "null",
    17440: "null",
    17441: "null",
    17442: "null",
    17443: "null",
    17444: "null",
    17445: "null",
    17446: "null",
    17447: "null",
    17448: "null",
    17449: "null",
    17450: "null",
    17451: "null",
    17452: "null",
    17453: "null",
    17454: "null",
    17455: "null",
    17456: "null",
    17457: "null",
    17458: "null",
    17459: "null",
    17460: "null",
    17461: "null",
    17462: "null",
    17463: "null",
    17464: "null",
    17465: "null",
    17466: "null",
    17467: "null",
    17468: "null",
    17469: "null",
    17470: "null",
    17471: "null",
    17472: "null",
    17473: "null",
    17474: "null",
    17475: "null",
    17476: "null",
    17477: "null",
    17478: "null",
    17479: "null",
    17480: "null",
    17481: "null",
    17482: "null",
    17483: "null",
    17484: "null",
    17485: "null",
    17486: "null",
    17487: "null",
    17488: "null",
    17489: "null",
    17490: "null",
    17491: "null",
    17492: "null",
    17493: "null",
    17494: "null",
    17495: "null",
    17496: "null",
    17497: "null",
    17498: "null",
    17499: "null",
    17500: "null",
    17501: "null",
    17502: "null",
    17503: "null",
    17504: "null",
    17505: "null",
    17506: "null",
    17507: "null",
    17508: "null",
    17509: "null",
    17510: "null",
    17511: "null",
    17512: "null",
    17513: "null",
    17514: "null",
    17515: "null",
    17516: "null",
    17517: "null",
    17518: "null",
    17519: "null",
    17520: "null",
    17521: "null",
    17522: "null",
    17523: "null",
    17524: "null",
    17525: "null",
    17526: "null",
    17527: "null",
    17528: "null",
    17529: "null",
    17530: "null",
    17531: "null",
    17532: "null",
    17533: "null",
    17534: "null",
    17535: "null",
    17536: "null",
    17537: "null",
    17538: "null",
    17539: "null",
    17540: "null",
    17541: "null",
    17542: "null",
    17543: "null",
    17544: "null",
    17545: "null",
    17546: "null",
    17547: "null",
    17548: "null",
    17549: "null",
    17550: "null",
    17551: "null",
    17552: "null",
    17553: "null",
    17554: "null",
    17555: "null",
    17556: "null",
    17557: "null",
    17558: "null",
    17559: "null",
    17560: "null",
    17561: "null",
    17562: "null",
    17563: "null",
    17564: "null",
    17565: "null",
    17566: "null",
    17567: "null",
    17568: "null",
    17569: "null",
    17570: "null",
    17571: "null",
    17572: "null",
    17573: "null",
    17574: "null",
    17575: "null",
    17576: "null",
    17577: "null",
    17578: "null",
    17579: "null",
    17580: "null",
    17581: "null",
    17582: "null",
    17583: "null",
    17584: "null",
    17585: "null",
    17586: "null",
    17587: "null",
    17588: "null",
    17589: "null",
    17590: "null",
    17591: "null",
    17592: "null",
    17593: "null",
    17594: "null",
    17595: "null",
    17596: "null",
    17597: "null",
    17598: "null",
    17599: "null",
    17600: "null",
    17601: "null",
    17602: "null",
    17603: "null",
    17604: "null",
    17605: "null",
    17606: "null",
    17607: "null",
    17608: "null",
    17609: "null",
    17610: "null",
    17611: "null",
    17612: "null",
    17613: "null",
    17614: "null",
    17615: "null",
    17616: "null",
    17617: "null",
    17618: "null",
    17619: "null",
    17620: "null",
    17621: "null",
    17622: "null",
    17623: "null",
    17624: "null",
    17625: "null",
    17626: "null",
    17627: "null",
    17628: "null",
    17629: "null",
    17630: "null",
    17631: "null",
    17632: "null",
    17633: "null",
    17634: "null",
    17635: "null",
    17636: "null",
    17637: "null",
    17638: "null",
    17639: "null",
    17640: "null",
    17641: "null",
    17642: "null",
    17643: "null",
    17644: "null",
    17645: "null",
    17646: "null",
    17647: "null",
    17648: "null",
    17649: "null",
    17650: "null",
    17651: "null",
    17652: "null",
    17653: "null",
    17654: "null",
    17655: "null",
    17656: "null",
    17657: "null",
    17658: "null",
    17659: "null",
    17660: "null",
    17661: "null",
    17662: "null",
    17663: "null",
    17664: "null",
    17665: "null",
    17666: "null",
    17667: "null",
    17668: "null",
    17669: "null",
    17670: "null",
    17671: "null",
    17672: "null",
    17673: "null",
    17674: "null",
    17675: "null",
    17676: "null",
    17677: "null",
    17678: "null",
    17679: "null",
    17680: "null",
    17681: "null",
    17682: "null",
    17683: "null",
    17684: "null",
    17685: "null",
    17686: "null",
    17687: "null",
    17688: "null",
    17689: "null",
    17690: "null",
    17691: "null",
    17692: "null",
    17693: "null",
    17694: "null",
    17695: "null",
    17696: "null",
    17697: "null",
    17698: "null",
    17699: "null",
    17700: "null",
    17701: "null",
    17702: "null",
    17703: "null",
    17704: "null",
    17705: "null",
    17706: "null",
    17707: "null",
    17708: "null",
    17709: "null",
    17710: "null",
    17711: "null",
    17712: "null",
    17713: "null",
    17714: "null",
    17715: "null",
    17716: "null",
    17717: "null",
    17718: "null",
    17719: "null",
    17720: "null",
    17721: "null",
    17722: "null",
    17723: "null",
    17724: "null",
    17725: "null",
    17726: "null",
    17727: "null",
    17728: "null",
    17729: "null",
    17730: "null",
    17731: "null",
    17732: "null",
    17733: "null",
    17734: "null",
    17735: "null",
    17736: "null",
    17737: "null",
    17738: "null",
    17739: "null",
    17740: "null",
    17741: "null",
    17742: "null",
    17743: "null",
    17744: "null",
    17745: "null",
    17746: "null",
    17747: "null",
    17748: "null",
    17749: "null",
    17750: "null",
    17751: "null",
    17752: "null",
    17753: "null",
    17754: "null",
    17755: "null",
    17756: "null",
    17757: "null",
    17758: "null",
    17759: "null",
    17760: "null",
    17761: "null",
    17762: "null",
    17763: "null",
    17764: "null",
    17765: "null",
    17766: "null",
    17767: "null",
    17768: "null",
    17769: "null",
    17770: "null",
    17771: "null",
    17772: "null",
    17773: "null",
    17774: "null",
    17775: "null",
    17776: "null",
    17777: "null",
    17778: "null",
    17779: "null",
    17780: "null",
    17781: "null",
    17782: "null",
    17783: "null",
    17784: "null",
    17785: "null",
    17786: "null",
    17787: "null",
    17788: "null",
    17789: "null",
    17790: "null",
    17791: "null",
    17792: "null",
    17793: "null",
    17794: "null",
    17795: "null",
    17796: "null",
    17797: "null",
    17798: "null",
    17799: "null",
    17800: "null",
    17801: "null",
    17802: "null",
    17803: "null",
    17804: "null",
    17805: "null",
    17806: "null",
    17807: "null",
    17808: "null",
    17809: "null",
    17810: "null",
    17811: "null",
    17812: "null",
    17813: "null",
    17814: "null",
    17815: "null",
    17816: "null",
    17817: "null",
    17818: "null",
    17819: "null",
    17820: "null",
    17821: "null",
    17822: "null",
    17823: "null",
    17824: "null",
    17825: "null",
    17826: "null",
    17827: "null",
    17828: "null",
    17829: "null",
    17830: "null",
    17831: "null",
    17832: "null",
    17833: "null",
    17834: "null",
    17835: "null",
    17836: "null",
    17837: "null",
    17838: "null",
    17839: "null",
    17840: "null",
    17841: "null",
    17842: "null",
    17843: "null",
    17844: "null",
    17845: "null",
    17846: "null",
    17847: "null",
    17848: "null",
    17849: "null",
    17850: "null",
    17851: "null",
    17852: "null",
    17853: "null",
    17854: "null",
    17855: "null",
    17856: "null",
    17857: "null",
    17858: "null",
    17859: "null",
    17860: "null",
    17861: "null",
    17862: "null",
    17863: "null",
    17864: "null",
    17865: "null",
    17866: "null",
    17867: "null",
    17868: "Null",
    17869: "null",
    17870: "null",
    17871: "null",
    17872: "null",
    17873: "null",
    17874: "null",
    17875: "null",
    17876: "null",
    17877: "null",
    17878: "null",
    17879: "null",
    17880: "null",
    17881: "null",
    17882: "null",
    17883: "null",
    17884: "null",
    17885: "null",
    17886: "null",
    17887: "null",
    17888: "null",
    17889: "null",
    17890: "null",
    17891: "null",
    17892: "null",
    17893: "null",
    17894: "null",
    17895: "null",
    17896: "null",
    17897: "null",
    17898: "null",
    17899: "null",
    17900: "null",
    17901: "null",
    17902: "null",
    17903: "null",
    17904: "null",
    17905: "null",
    17906: "null",
    17907: "null",
    17908: "null",
    17909: "null",
    17910: "null",
    17911: "null",
    17912: "null",
    17913: "null",
    17914: "null",
    17915: "null",
    17916: "null",
    17917: "null",
    17918: "null",
    17919: "null",
    17920: "null",
    17921: "null",
    17922: "null",
    17923: "null",
    17924: "null",
    17925: "null",
    17926: "null",
    17927: "null",
    17928: "null",
    17929: "null",
    17930: "null",
    17931: "null",
    17932: "null",
    17933: "null",
    17934: "null",
    17935: "null",
    17936: "null",
    17937: "null",
    17938: "null",
    17939: "null",
    17940: "null",
    17941: "null",
    17942: "null",
    17943: "null",
    17944: "null",
    17945: "null",
    17946: "null",
    17947: "null",
    17948: "null",
    17949: "null",
    17950: "null",
    17951: "null",
    17952: "null",
    17953: "null",
    17954: "null",
    17955: "null",
    17956: "null",
    17957: "null",
    17958: "null",
    17959: "null",
    17960: "null",
    17961: "null",
    17962: "null",
    17963: "null",
    17964: "null",
    17965: "null",
    17966: "null",
    17967: "null",
    17968: "null",
    17969: "null",
    17970: "null",
    17971: "null",
    17972: "null",
    17973: "null",
    17974: "null",
    17975: "null",
    17976: "null",
    17977: "null",
    17978: "null",
    17979: "null",
    17980: "null",
    17981: "null",
    17982: "null",
    17983: "null",
    17984: "null",
    17985: "null",
    17986: "null",
    17987: "null",
    17988: "null",
    17989: "null",
    17990: "null",
    17991: "null",
    17992: "null",
    17993: "null",
    17994: "null",
    17995: "null",
    17996: "null",
    17997: "null",
    17998: "null",
    17999: "null",
    18000: "null",
    18001: "null",
    18002: "null",
    18003: "null",
    18004: "null",
    18005: "null",
    18006: "null",
    18007: "null",
    18008: "null",
    18009: "null",
    18010: "null",
    18011: "null",
    18012: "null",
    18013: "null",
    18014: "null",
    18015: "null",
    18016: "null",
    18017: "null",
    18018: "null",
    18019: "null",
    18020: "null",
    18021: "null",
    18022: "null",
    18023: "null",
    18024: "null",
    18025: "null",
    18026: "null",
    18027: "null",
    18028: "null",
    18029: "null",
    18030: "null",
    18031: "null",
    18032: "null",
    18033: "null",
    18034: "null",
    18035: "null",
    18036: "null",
    18037: "null",
    18038: "null",
    18039: "null",
    18040: "null",
    18041: "null",
    18042: "null",
    18043: "null",
    18044: "null",
    18045: "null",
    18046: "null",
    18047: "null",
    18048: "null",
    18049: "null",
    18050: "null",
    18051: "null",
    18052: "null",
    18053: "null",
    18054: "null",
    18055: "null",
    18056: "null",
    18057: "null",
    18058: "null",
    18059: "null",
    18060: "null",
    18061: "null",
    18062: "null",
    18063: "null",
    18064: "null",
    18065: "null",
    18066: "null",
    18067: "null",
    18068: "null",
    18069: "null",
    18070: "null",
    18071: "null",
    18072: "null",
    18073: "null",
    18074: "null",
    18075: "null",
    18076: "null",
    18077: "null",
    18078: "null",
    18079: "null",
    18080: "null",
    18081: "null",
    18082: "null",
    18083: "null",
    18084: "null",
    18085: "null",
    18086: "null",
    18087: "null",
    18088: "null",
    18089: "null",
    18090: "null",
    18091: "null",
    18092: "null",
    18093: "null",
    18094: "null",
    18095: "null",
    18096: "null",
    18097: "null",
    18098: "null",
    18099: "null",
    18100: "null",
    18101: "null",
    18102: "null",
    18103: "null",
    18104: "null",
    18105: "null",
    18106: "null",
    18107: "null",
    18108: "null",
    18109: "null",
    18110: "null",
    18111: "null",
    18112: "null",
    18113: "null",
    18114: "null",
    18115: "null",
    18116: "null",
    18117: "null",
    18118: "null",
    18119: "null",
    18120: "null",
    18121: "null",
    18122: "null",
    18123: "null",
    18124: "null",
    18125: "null",
    18126: "null",
    18127: "null",
    18128: "null",
    18129: "null",
    18130: "null",
    18131: "null",
    18132: "null",
    18133: "null",
    18134: "null",
    18135: "null",
    18136: "null",
    18137: "null",
    18138: "null",
    18139: "null",
    18140: "null",
    18141: "null",
    18142: "null",
    18143: "null",
    18144: "null",
    18145: "null",
    18146: "null",
    18147: "null",
    18148: "null",
    18149: "null",
    18150: "null",
    18151: "null",
    18152: "null",
    18153: "null",
    18154: "null",
    18155: "null",
    18156: "null",
    18157: "null",
    18158: "null",
    18159: "null",
    18160: "null",
    18161: "null",
    18162: "null",
    18163: "null",
    18164: "null",
    18165: "null",
    18166: "null",
    18167: "null",
    18168: "null",
    18169: "null",
    18170: "null",
    18171: "null",
    18172: "null",
    18173: "null",
    18174: "null",
    18175: "null",
    18176: "null",
    18177: "null",
    18178: "null",
    18179: "null",
    18180: "null",
    18181: "null",
    18182: "null",
    18183: "null",
    18184: "null",
    18185: "null",
    18186: "null",
    18187: "null",
    18188: "null",
    18189: "null",
    18190: "null",
    18191: "null",
    18192: "null",
    18193: "null",
    18194: "null",
    18195: "null",
    18196: "null",
    18197: "null",
    18198: "null",
    18199: "null",
    18200: "null",
    18201: "null",
    18202: "null",
    18203: "null",
    18204: "null",
    18205: "null",
    18206: "null",
    18207: "null",
    18208: "null",
    18209: "null",
    18210: "null",
    18211: "null",
    18212: "null",
    18213: "null",
    18214: "null",
    18215: "null",
    18216: "null",
    18217: "null",
    18218: "null",
    18219: "null",
    18220: "null",
    18221: "null",
    18222: "null",
    18223: "null",
    18224: "null",
    18225: "null",
    18226: "null",
    18227: "null",
    18228: "null",
    18229: "null",
    18230: "null",
    18231: "null",
    18232: "null",
    18233: "null",
    18234: "null",
    18235: "null",
    18236: "null",
    18237: "null",
    18238: "null",
    18239: "null",
    18240: "null",
    18241: "null",
    18242: "null",
    18243: "null",
    18244: "null",
    18245: "null",
    18246: "null",
    18247: "null",
    18248: "null",
    18249: "null",
    18250: "null",
    18251: "null",
    18252: "null",
    18253: "null",
    18254: "null",
    18255: "null",
    18256: "null",
    18257: "null",
    18258: "null",
    18259: "null",
    18260: "null",
    18261: "null",
    18262: "null",
    18263: "null",
    18264: "null",
    18265: "null",
    18266: "null",
    18267: "null",
    18268: "null",
    18269: "null",
    18270: "null",
    18271: "null",
    18272: "null",
    18273: "null",
    18274: "null",
    18275: "null",
    18276: "null",
    18277: "null",
    18278: "null",
    18279: "null",
    18280: "null",
    18281: "null",
    18282: "null",
    18283: "null",
    18284: "null",
    18285: "null",
    18286: "null",
    18287: "null",
    18288: "null",
    18289: "null",
    18290: "null",
    18291: "null",
    18292: "null",
    18293: "null",
    18294: "null",
    18295: "null",
    18296: "null",
    18297: "null",
    18298: "null",
    18299: "null",
    18300: "null",
    18301: "null",
    18302: "null",
    18303: "null",
    18304: "null",
    18305: "null",
    18306: "null",
    18307: "null",
    18308: "null",
    18309: "null",
    18310: "null",
    18311: "null",
    18312: "null",
    18313: "null",
    18314: "null",
    18315: "null",
    18316: "null",
    18317: "null",
    18318: "null",
    18319: "null",
    18320: "null",
    18321: "null",
    18322: "null",
    18323: "null",
    18324: "null",
    18325: "null",
    18326: "null",
    18327: "null",
    18328: "null",
    18329: "null",
    18330: "null",
    18331: "null",
    18332: "null",
    18333: "null",
    18334: "null",
    18335: "null",
    18336: "null",
    18337: "null",
    18338: "null",
    18339: "null",
    18340: "null",
    18341: "null",
    18342: "null",
    18343: "null",
    18344: "null",
    18345: "null",
    18346: "null",
    18347: "null",
    18348: "null",
    18349: "null",
    18350: "null",
    18351: "null",
    18352: "null",
    18353: "null",
    18354: "null",
    18355: "null",
    18356: "null",
    18357: "null",
    18358: "null",
    18359: "null",
    18360: "null",
    18361: "null",
    18362: "null",
    18363: "null",
    18364: "null",
    18365: "null",
    18366: "null",
    18367: "null",
    18368: "null",
    18369: "null",
    18370: "null",
    18371: "null",
    18372: "null",
    18373: "null",
    18374: "null",
    18375: "null",
    18376: "null",
    18377: "null",
    18378: "null",
    18379: "null",
    18380: "null",
    18381: "null",
    18382: "null",
    18383: "null",
    18384: "null",
    18385: "null",
    18386: "null",
    18387: "null",
    18388: "null",
    18389: "null",
    18390: "null",
    18391: "null",
    18392: "null",
    18393: "null",
    18394: "null",
    18395: "null",
    18396: "null",
    18397: "null",
    18398: "null",
    18399: "null",
    18400: "null",
    18401: "null",
    18402: "null",
    18403: "null",
    18404: "null",
    18405: "null",
    18406: "null",
    18407: "null",
    18408: "null",
    18409: "null",
    18410: "null",
    18411: "null",
    18412: "null",
    18413: "null",
    18414: "null",
    18415: "null",
    18416: "null",
    18417: "null",
    18418: "null",
    18419: "null",
    18420: "null",
    18421: "null",
    18422: "null",
    18423: "null",
    18424: "null",
    18425: "null",
    18426: "null",
    18427: "null",
    18428: "null",
    18429: "null",
    18430: "null",
    18431: "null",
    18432: "null",
    18433: "null",
    18434: "null",
    18435: "null",
    18436: "null",
    18437: "null",
    18438: "null",
    18439: "null",
    18440: "null",
    18441: "null",
    18442: "null",
    18443: "null",
    18444: "null",
    18445: "null",
    18446: "null",
    18447: "null",
    18448: "null",
    18449: "null",
    18450: "null",
    18451: "null",
    18452: "null",
    18453: "null",
    18454: "null",
    18455: "null",
    18456: "null",
    18457: "null",
    18458: "null",
    18459: "Shayzien medpack",
    18460: "null",
    18461: "null",
    18462: "null",
    18463: "null",
    18464: "null",
    18465: "null",
    18466: "null",
    18467: "Sulphurous fertiliser",
    18468: "null",
    18469: "null",
    18470: "null",
    18471: "null",
    18472: "null",
    18473: "null",
    18474: "null",
    18475: "null",
    18476: "null",
    18477: "null",
    18478: "null",
    18479: "null",
    18480: "null",
    18481: "null",
    18482: "null",
    18483: "null",
    18484: "null",
    18485: "null",
    18486: "null",
    18487: "null",
    18488: "null",
    18489: "null",
    18490: "null",
    18491: "null",
    18492: "null",
    18493: "null",
    18494: "null",
    18495: "null",
    18496: "null",
    18497: "null",
    18498: "null",
    18499: "null",
    18500: "null",
    18501: "null",
    18502: "null",
    18503: "null",
    18504: "null",
    18505: "null",
    18506: "null",
    18507: "null",
    18508: "null",
    18509: "null",
    18510: "null",
    18511: "null",
    18512: "null",
    18513: "null",
    18514: "null",
    18515: "null",
    18516: "null",
    18517: "null",
    18518: "null",
    18519: "null",
    18520: "null",
    18521: "null",
    18522: "null",
    18523: "null",
    18524: "null",
    18525: "null",
    18526: "null",
    18527: "null",
    18528: "null",
    18529: "null",
    18530: "null",
    18531: "null",
    18532: "null",
    18533: "null",
    18534: "null",
    18535: "null",
    18536: "null",
    18537: "null",
    18538: "null",
    18539: "null",
    18540: "null",
    18541: "null",
    18542: "null",
    18543: "null",
    18544: "null",
    18545: "null",
    18546: "null",
    18547: "null",
    18548: "null",
    18549: "null",
    18550: "null",
    18551: "null",
    18552: "null",
    18553: "null",
    18554: "null",
    18555: "Shayzien supply gloves (1)",
    18556: "Shayzien supply boots (1)",
    18557: "Shayzien supply helm (1)",
    18558: "Shayzien supply greaves (1)",
    18559: "Shayzien supply platebody (1)",
    18560: "Shayzien supply gloves (2)",
    18561: "Shayzien supply boots (2)",
    18562: "Shayzien supply helm (2)",
    18563: "Shayzien supply greaves (2)",
    18564: "Shayzien supply platebody (2)",
    18565: "Shayzien supply gloves (3)",
    18566: "Shayzien supply boots (3)",
    18567: "Shayzien supply helm (3)",
    18568: "Shayzien supply greaves (3)",
    18569: "Shayzien supply platebody (3)",
    18570: "Shayzien supply gloves (4)",
    18571: "Shayzien supply boots (4)",
    18572: "Shayzien supply helm (4)",
    18573: "Shayzien supply greaves (4)",
    18574: "Shayzien supply platebody (4)",
    18575: "Shayzien supply gloves (5)",
    18576: "Shayzien supply boots (5)",
    18577: "Shayzien supply helm (5)",
    18578: "Shayzien supply greaves (5)",
    18579: "Shayzien supply platebody (5)",
    18580: "Shayzien supply crate",
    18581: "Shayzien supply set (1)",
    18582: "Shayzien supply set (2)",
    18583: "Shayzien supply set (3)",
    18584: "Shayzien supply set (4)",
    18585: "Shayzien supply set (5)",
    18586: "null",
    18587: "null",
    18588: "null",
    18589: "null",
    18590: "null",
    18591: "null",
    18592: "null",
    18593: "null",
    18594: "null",
    18595: "null",
    18596: "null",
    18597: "null",
    18598: "null",
    18599: "null",
    18600: "null",
    18601: "null",
    18602: "null",
    18603: "null",
    18604: "null",
    18605: "null",
    18606: "null",
    18607: "null",
    18608: "null",
    18609: "null",
    18610: "null",
    18611: "null",
    18612: "null",
    18613: "null",
    18614: "null",
    18615: "null",
    18616: "null",
    18617: "null",
    18618: "null",
    18619: "null",
    18620: "null",
    18621: "null",
    18622: "null",
    18623: "null",
    18624: "null",
    18625: "null",
    18626: "null",
    18627: "null",
    18628: "null",
    18629: "null",
    18630: "null",
    18631: "null",
    18632: "null",
    18633: "null",
    18634: "null",
    18635: "null",
    18636: "null",
    18637: "null",
    18638: "null",
    18639: "null",
    18640: "null",
    18641: "null",
    18642: "null",
    18643: "null",
    18644: "null",
    18645: "null",
    18646: "null",
    18647: "null",
    18648: "null",
    18649: "null",
    18650: "null",
    18651: "null",
    18652: "null",
    18653: "null",
    18654: "null",
    18655: "null",
    18656: "null",
    18657: "null",
    18658: "null",
    18659: "null",
    18660: "null",
    18661: "null",
    18662: "null",
    18663: "null",
    18664: "null",
    18665: "null",
    18666: "null",
    18667: "null",
    18668: "null",
    18669: "null",
    18670: "null",
    18671: "null",
    18672: "null",
    18673: "null",
    18674: "null",
    18675: "null",
    18676: "null",
    18677: "null",
    18678: "null",
    18679: "null",
    18680: "null",
    18681: "null",
    18682: "null",
    18683: "null",
    18684: "null",
    18685: "null",
    18686: "null",
    18687: "null",
    18688: "null",
    18689: "null",
    18690: "null",
    18691: "null",
    18692: "null",
    18693: "null",
    18694: "null",
    18695: "null",
    18696: "null",
    18697: "null",
    18698: "null",
    18699: "null",
    18700: "null",
    18701: "null",
    18702: "null",
    18703: "null",
    18704: "null",
    18705: "null",
    18706: "null",
    18707: "null",
    18708: "null",
    18709: "null",
    18710: "null",
    18711: "null",
    18712: "null",
    18713: "null",
    18714: "null",
    18715: "null",
    18716: "null",
    18717: "null",
    18718: "null",
    18719: "null",
    18720: "null",
    18721: "null",
    18722: "null",
    18723: "null",
    18724: "null",
    18725: "null",
    18726: "null",
    18727: "null",
    18728: "null",
    18729: "null",
    18730: "null",
    18731: "null",
    18732: "null",
    18733: "null",
    18734: "null",
    18735: "null",
    18736: "null",
    18737: "null",
    18738: "null",
    18739: "null",
    18740: "null",
    18741: "null",
    18742: "null",
    18743: "null",
    18744: "null",
    18745: "null",
    18746: "null",
    18747: "null",
    18748: "null",
    18749: "null",
    18750: "null",
    18751: "null",
    18752: "null",
    18753: "null",
    18754: "null",
    18755: "null",
    18756: "null",
    18757: "null",
    18758: "null",
    18759: "null",
    18760: "null",
    18761: "null",
    18762: "null",
    18763: "null",
    18764: "null",
    18765: "null",
    18766: "null",
    18767: "null",
    18768: "null",
    18769: "null",
    18770: "null",
    18771: "null",
    18772: "null",
    18773: "null",
    18774: "null",
    18775: "null",
    18776: "null",
    18777: "null",
    18778: "null",
    18779: "null",
    18780: "null",
    18781: "null",
    18782: "null",
    18783: "null",
    18784: "null",
    18785: "null",
    18786: "null",
    18787: "null",
    18788: "null",
    18789: "null",
    18790: "null",
    18791: "null",
    18792: "null",
    18793: "null",
    18794: "null",
    18795: "null",
    18796: "null",
    18797: "null",
    18798: "null",
    18799: "null",
    18800: "null",
    18801: "null",
    18802: "null",
    18803: "null",
    18804: "null",
    18805: "null",
    18806: "null",
    18807: "null",
    18808: "null",
    18809: "null",
    18810: "null",
    18811: "null",
    18812: "null",
    18813: "null",
    18814: "null",
    18815: "null",
    18816: "null",
    18817: "null",
    18818: "null",
    18819: "null",
    18820: "null",
    18821: "null",
    18822: "null",
    18823: "null",
    18824: "null",
    18825: "null",
    18826: "null",
    18827: "null",
    18828: "null",
    18829: "null",
    18830: "null",
    18831: "null",
    18832: "null",
    18833: "null",
    18834: "null",
    18835: "null",
    18836: "null",
    18837: "null",
    18838: "null",
    18839: "null",
    18840: "null",
    18841: "null",
    18842: "null",
    18843: "null",
    18844: "null",
    18845: "null",
    18846: "null",
    18847: "null",
    18848: "null",
    18849: "null",
    18850: "null",
    18851: "null",
    18852: "null",
    18853: "null",
    18854: "null",
    18855: "null",
    18856: "null",
    18857: "null",
    18858: "null",
    18859: "null",
    18860: "null",
    18861: "null",
    18862: "null",
    18863: "null",
    18864: "null",
    18865: "null",
    18866: "null",
    18867: "null",
    18868: "null",
    18869: "null",
    18870: "null",
    18871: "null",
    18872: "null",
    18873: "null",
    18874: "null",
    18875: "null",
    18876: "null",
    18877: "null",
    18878: "null",
    18879: "null",
    18880: "null",
    18881: "null",
    18882: "null",
    18883: "null",
    18884: "null",
    18885: "null",
    18886: "null",
    18887: "null",
    18888: "null",
    18889: "null",
    18890: "null",
    18891: "null",
    18892: "null",
    18893: "null",
    18894: "null",
    18895: "null",
    18896: "null",
    18897: "null",
    18898: "null",
    18899: "null",
    18900: "null",
    18901: "null",
    18902: "null",
    18903: "null",
    18904: "null",
    18905: "null",
    18906: "null",
    18907: "null",
    18908: "null",
    18909: "null",
    18910: "null",
    18911: "null",
    18912: "null",
    18913: "null",
    18914: "null",
    18915: "null",
    18916: "null",
    18917: "null",
    18918: "null",
    18919: "null",
    18920: "null",
    18921: "null",
    18922: "null",
    18923: "null",
    18924: "null",
    18925: "null",
    18926: "null",
    18927: "null",
    18928: "null",
    18929: "null",
    18930: "null",
    18931: "null",
    18932: "null",
    18933: "null",
    18934: "null",
    18935: "null",
    18936: "null",
    18937: "null",
    18938: "null",
    18939: "null",
    18940: "null",
    18941: "null",
    18942: "null",
    18943: "null",
    18944: "null",
    18945: "null",
    18946: "null",
    18947: "null",
    18948: "null",
    18949: "null",
    18950: "null",
    18951: "null",
    18952: "null",
    18953: "null",
    18954: "null",
    18955: "null",
    18956: "null",
    18957: "null",
    18958: "null",
    18959: "null",
    18960: "null",
    18961: "null",
    18962: "null",
    18963: "null",
    18964: "null",
    18965: "null",
    18966: "null",
    18967: "null",
    18968: "null",
    18969: "null",
    18970: "null",
    18971: "null",
    18972: "null",
    18973: "null",
    18974: "null",
    18975: "null",
    18976: "null",
    18977: "null",
    18978: "null",
    18979: "null",
    18980: "null",
    18981: "null",
    18982: "null",
    18983: "null",
    18984: "null",
    18985: "null",
    18986: "null",
    18987: "null",
    18988: "null",
    18989: "null",
    18990: "null",
    18991: "null",
    18992: "null",
    18993: "null",
    18994: "null",
    18995: "null",
    18996: "null",
    18997: "null",
    18998: "null",
    18999: "null",
    19000: "null",
    19001: "null",
    19002: "null",
    19003: "null",
    19004: "null",
    19005: "null",
    19006: "null",
    19007: "null",
    19008: "null",
    19009: "null",
    19010: "null",
    19011: "null",
    19012: "null",
    19013: "null",
    19014: "null",
    19015: "null",
    19016: "null",
    19017: "null",
    19018: "null",
    19019: "null",
    19020: "null",
    19021: "null",
    19022: "null",
    19023: "null",
    19024: "null",
    19025: "null",
    19026: "null",
    19027: "null",
    19028: "null",
    19029: "null",
    19030: "null",
    19031: "null",
    19032: "null",
    19033: "null",
    19034: "null",
    19035: "null",
    19036: "null",
    19037: "null",
    19038: "null",
    19039: "null",
    19040: "null",
    19041: "null",
    19042: "null",
    19043: "null",
    19044: "null",
    19045: "null",
    19046: "null",
    19047: "null",
    19048: "null",
    19049: "null",
    19050: "null",
    19051: "null",
    19052: "null",
    19053: "null",
    19054: "null",
    19055: "null",
    19056: "null",
    19057: "null",
    19058: "null",
    19059: "null",
    19060: "null",
    19061: "null",
    19062: "null",
    19063: "null",
    19064: "null",
    19065: "null",
    19066: "null",
    19067: "null",
    19068: "null",
    19069: "null",
    19070: "null",
    19071: "null",
    19072: "null",
    19073: "null",
    19074: "null",
    19075: "null",
    19076: "null",
    19077: "null",
    19078: "null",
    19079: "null",
    19080: "null",
    19081: "null",
    19082: "null",
    19083: "null",
    19084: "null",
    19085: "null",
    19086: "null",
    19087: "null",
    19088: "null",
    19089: "null",
    19090: "null",
    19091: "null",
    19092: "null",
    19093: "null",
    19094: "null",
    19095: "null",
    19096: "null",
    19097: "null",
    19098: "null",
    19099: "null",
    19100: "null",
    19101: "null",
    19102: "null",
    19103: "null",
    19104: "null",
    19105: "null",
    19106: "null",
    19107: "null",
    19108: "null",
    19109: "null",
    19110: "null",
    19111: "null",
    19112: "null",
    19113: "null",
    19114: "null",
    19115: "null",
    19116: "null",
    19117: "null",
    19118: "null",
    19119: "null",
    19120: "null",
    19121: "null",
    19122: "null",
    19123: "null",
    19124: "null",
    19125: "null",
    19126: "null",
    19127: "null",
    19128: "null",
    19129: "null",
    19130: "null",
    19131: "null",
    19132: "null",
    19133: "null",
    19134: "null",
    19135: "null",
    19136: "null",
    19137: "null",
    19138: "null",
    19139: "null",
    19140: "null",
    19141: "null",
    19142: "null",
    19143: "null",
    19144: "null",
    19145: "null",
    19146: "null",
    19147: "null",
    19148: "null",
    19149: "null",
    19150: "null",
    19151: "null",
    19152: "null",
    19153: "null",
    19154: "null",
    19155: "null",
    19156: "null",
    19157: "null",
    19158: "null",
    19159: "null",
    19160: "null",
    19161: "null",
    19162: "null",
    19163: "null",
    19164: "null",
    19165: "null",
    19166: "null",
    19167: "null",
    19168: "null",
    19169: "null",
    19170: "null",
    19171: "null",
    19172: "null",
    19173: "null",
    19174: "null",
    19175: "null",
    19176: "null",
    19177: "null",
    19178: "null",
    19179: "null",
    19180: "null",
    19181: "null",
    19182: "null",
    19183: "null",
    19184: "null",
    19185: "null",
    19186: "null",
    19187: "null",
    19188: "null",
    19189: "null",
    19190: "null",
    19191: "null",
    19192: "null",
    19193: "null",
    19194: "null",
    19195: "null",
    19196: "null",
    19197: "null",
    19198: "null",
    19199: "null",
    19200: "null",
    19201: "null",
    19202: "null",
    19203: "null",
    19204: "null",
    19205: "null",
    19206: "null",
    19207: "null",
    19208: "null",
    19209: "null",
    19210: "null",
    19211: "null",
    19212: "null",
    19213: "null",
    19214: "null",
    19215: "null",
    19216: "null",
    19217: "null",
    19218: "null",
    19219: "null",
    19220: "null",
    19221: "null",
    19222: "null",
    19223: "null",
    19224: "null",
    19225: "null",
    19226: "null",
    19227: "null",
    19228: "null",
    19229: "null",
    19230: "null",
    19231: "null",
    19232: "null",
    19233: "null",
    19234: "null",
    19235: "null",
    19236: "null",
    19237: "null",
    19238: "null",
    19239: "null",
    19240: "null",
    19241: "null",
    19242: "null",
    19243: "null",
    19244: "null",
    19245: "null",
    19246: "null",
    19247: "null",
    19248: "null",
    19249: "null",
    19250: "null",
    19251: "null",
    19252: "null",
    19253: "null",
    19254: "null",
    19255: "null",
    19256: "null",
    19257: "null",
    19258: "null",
    19259: "null",
    19260: "null",
    19261: "null",
    19262: "null",
    19263: "null",
    19264: "null",
    19265: "null",
    19266: "null",
    19267: "null",
    19268: "null",
    19269: "null",
    19270: "null",
    19271: "null",
    19272: "null",
    19273: "null",
    19274: "null",
    19275: "null",
    19276: "null",
    19277: "null",
    19278: "null",
    19279: "null",
    19280: "null",
    19281: "null",
    19282: "null",
    19283: "null",
    19284: "null",
    19285: "null",
    19286: "null",
    19287: "null",
    19288: "null",
    19289: "null",
    19290: "null",
    19291: "null",
    19292: "null",
    19293: "null",
    19294: "null",
    19295: "null",
    19296: "null",
    19297: "null",
    19298: "null",
    19299: "null",
    19300: "null",
    19301: "null",
    19302: "null",
    19303: "null",
    19304: "null",
    19305: "null",
    19306: "null",
    19307: "null",
    19308: "null",
    19309: "null",
    19310: "null",
    19311: "null",
    19312: "null",
    19313: "null",
    19314: "null",
    19315: "null",
    19316: "null",
    19317: "null",
    19318: "null",
    19319: "null",
    19320: "null",
    19321: "null",
    19322: "null",
    19323: "null",
    19324: "null",
    19325: "null",
    19326: "null",
    19327: "null",
    19328: "null",
    19329: "null",
    19330: "null",
    19331: "null",
    19332: "null",
    19333: "null",
    19334: "null",
    19335: "null",
    19336: "null",
    19337: "null",
    19338: "null",
    19339: "null",
    19340: "null",
    19341: "null",
    19342: "null",
    19343: "null",
    19344: "null",
    19345: "null",
    19346: "null",
    19347: "null",
    19348: "null",
    19349: "null",
    19350: "null",
    19351: "null",
    19352: "null",
    19353: "null",
    19354: "null",
    19355: "null",
    19356: "null",
    19357: "null",
    19358: "null",
    19359: "null",
    19360: "null",
    19361: "null",
    19362: "null",
    19363: "null",
    19364: "null",
    19365: "null",
    19366: "null",
    19367: "null",
    19368: "null",
    19369: "null",
    19370: "null",
    19371: "null",
    19372: "null",
    19373: "null",
    19374: "null",
    19375: "null",
    19376: "null",
    19377: "null",
    19378: "null",
    19379: "null",
    19380: "null",
    19381: "null",
    19382: "null",
    19383: "null",
    19384: "null",
    19385: "null",
    19386: "null",
    19387: "null",
    19388: "null",
    19389: "null",
    19390: "null",
    19391: "null",
    19392: "null",
    19393: "null",
    19394: "null",
    19395: "null",
    19396: "null",
    19397: "null",
    19398: "null",
    19399: "null",
    19400: "null",
    19401: "null",
    19402: "null",
    19403: "null",
    19404: "null",
    19405: "null",
    19406: "null",
    19407: "null",
    19408: "null",
    19409: "null",
    19410: "null",
    19411: "null",
    19412: "null",
    19413: "null",
    19414: "null",
    19415: "null",
    19416: "null",
    19417: "null",
    19418: "null",
    19419: "null",
    19420: "null",
    19421: "null",
    19422: "null",
    19423: "null",
    19424: "null",
    19425: "null",
    19426: "null",
    19427: "null",
    19428: "null",
    19429: "null",
    19430: "null",
    19431: "null",
    19432: "null",
    19433: "null",
    19434: "null",
    19435: "null",
    19436: "null",
    19437: "null",
    19438: "null",
    19439: "null",
    19440: "null",
    19441: "null",
    19442: "null",
    19443: "null",
    19444: "null",
    19445: "null",
    19446: "null",
    19447: "null",
    19448: "null",
    19449: "null",
    19450: "null",
    19451: "null",
    19452: "null",
    19453: "null",
    19454: "null",
    19455: "null",
    19456: "null",
    19457: "null",
    19458: "null",
    19459: "null",
    19460: "null",
    19461: "null",
    19462: "null",
    19463: "null",
    19464: "null",
    19465: "null",
    19466: "null",
    19467: "null",
    19468: "null",
    19469: "null",
    19470: "null",
    19471: "null",
    19472: "null",
    19473: "Bag full of gems",
    19474: "null",
    19475: "null",
    19476: "Achievement diary cape",
    19477: "null",
    19478: "Light ballista",
    19479: "null",
    19480: "null",
    19481: "Heavy ballista",
    19482: "null",
    19483: "null",
    19484: "Dragon javelin",
    19485: "null",
    19486: "Dragon javelin(p)",
    19487: "null",
    19488: "Dragon javelin(p+)",
    19489: "null",
    19490: "Dragon javelin(p++)",
    19491: "null",
    19492: "Zenyte bracelet",
    19493: "Zenyte",
    19494: "null",
    19495: "null",
    19496: "Uncut zenyte",
    19497: "null",
    19498: "null",
    19499: "null",
    19500: "null",
    19501: "Zenyte amulet (u)",
    19502: "null",
    19503: "null",
    19504: "null",
    19505: "Mysterious note",
    19506: "null",
    19507: "Mysterious note",
    19508: "null",
    19509: "Mysterious note",
    19510: "null",
    19511: "Scrawled note",
    19512: "null",
    19513: "Translated note",
    19514: "null",
    19515: "Book of spyology",
    19516: "null",
    19517: "Brush",
    19518: "null",
    19519: "Juice-coated brush",
    19520: "null",
    19521: "Handkerchief",
    19522: "null",
    19523: "Kruk's paw",
    19524: "null",
    19525: "Kruk monkey greegree",
    19526: "null",
    19527: "Satchel",
    19528: "Satchel",
    19529: "Zenyte shard",
    19530: "null",
    19531: "null",
    19532: "Zenyte bracelet",
    19533: "null",
    19534: "null",
    19535: "Zenyte necklace",
    19536: "null",
    19537: "null",
    19538: "Zenyte ring",
    19539: "null",
    19540: "null",
    19541: "Zenyte amulet",
    19542: "null",
    19543: "null",
    19544: "Tormented bracelet",
    19545: "null",
    19546: "null",
    19547: "Necklace of anguish",
    19548: "null",
    19549: "null",
    19550: "Ring of suffering",
    19551: "null",
    19552: "null",
    19553: "Amulet of torture",
    19554: "null",
    19555: "null",
    19556: "Monkey",
    19557: "null",
    19558: "Nieve",
    19559: "Elysian spirit shield",
    19560: "Charged onyx",
    19561: "null",
    19562: "Deconstructed onyx",
    19563: "null",
    19564: "Royal seed pod",
    19565: "null",
    19566: "Bronze key",
    19567: "Combat scarred key",
    19568: "Combat scratched key",
    19569: "Combat damaged key",
    19570: "Bronze javelin heads",
    19571: "null",
    19572: "Iron javelin heads",
    19573: "null",
    19574: "Steel javelin heads",
    19575: "null",
    19576: "Mithril javelin heads",
    19577: "null",
    19578: "Adamant javelin heads",
    19579: "null",
    19580: "Rune javelin heads",
    19581: "null",
    19582: "Dragon javelin heads",
    19583: "null",
    19584: "Javelin shaft",
    19585: "null",
    19586: "Light frame",
    19587: "null",
    19588: "null",
    19589: "Heavy frame",
    19590: "null",
    19591: "null",
    19592: "Ballista limbs",
    19593: "null",
    19594: "null",
    19595: "Incomplete light ballista",
    19596: "null",
    19597: "null",
    19598: "Incomplete heavy ballista",
    19599: "null",
    19600: "null",
    19601: "Ballista spring",
    19602: "null",
    19603: "null",
    19604: "Unstrung light ballista",
    19605: "null",
    19606: "null",
    19607: "Unstrung heavy ballista",
    19608: "null",
    19609: "null",
    19610: "Monkey tail",
    19611: "null",
    19612: "null",
    19613: "Arceuus library teleport",
    19614: "null",
    19615: "Draynor manor teleport",
    19616: "null",
    19617: "Mind altar teleport",
    19618: "null",
    19619: "Salve graveyard teleport",
    19620: "null",
    19621: "Fenkenstrain's castle teleport",
    19622: "null",
    19623: "West ardougne teleport",
    19624: "null",
    19625: "Harmony island teleport",
    19626: "null",
    19627: "Cemetery teleport",
    19628: "null",
    19629: "Barrows teleport",
    19630: "null",
    19631: "Ape atoll teleport",
    19632: "null",
    19633: "null",
    19634: "Soul bearer",
    19635: "null",
    19636: "Damaged soul bearer",
    19637: "Soul journey",
    19638: "null",
    19639: "Black slayer helmet",
    19640: "null",
    19641: "Black slayer helmet (i)",
    19642: "null",
    19643: "Green slayer helmet",
    19644: "null",
    19645: "Green slayer helmet (i)",
    19646: "null",
    19647: "Red slayer helmet",
    19648: "null",
    19649: "Red slayer helmet (i)",
    19650: "null",
    19651: "Hosidius teleport",
    19652: "null",
    19653: "Golovanova fruit top",
    19654: "null",
    19655: "null",
    19656: "Uncooked botanical pie",
    19657: "null",
    19658: "null",
    19659: "Half a botanical pie",
    19660: "null",
    19661: "null",
    19662: "Botanical pie",
    19663: "null",
    19664: "null",
    19665: "Damaged monkey tail",
    19666: "null",
    19667: "null",
    19668: "Minecart control scroll",
    19669: "Redwood logs",
    19670: "null",
    19671: "null",
    19672: "Redwood pyre logs",
    19673: "null",
    19674: "null",
    19675: "Arclight",
    19676: "null",
    19677: "Ancient shard",
    19678: "null",
    19679: "Dark totem base",
    19680: "null",
    19681: "Dark totem middle",
    19682: "null",
    19683: "Dark totem top",
    19684: "null",
    19685: "Dark totem",
    19686: "null",
    19687: "Helm of raedwald",
    19688: "null",
    19689: "Clue hunter garb",
    19690: "null",
    19691: "Clue hunter gloves",
    19692: "null",
    19693: "Clue hunter trousers",
    19694: "null",
    19695: "Clue hunter boots",
    19696: "null",
    19697: "Clue hunter cloak",
    19698: "null",
    19699: "Hornwood helm",
    19700: "null",
    19701: "Jar of darkness",
    19702: "null",
    19703: "null",
    19704: "Compost pack",
    19705: "null",
    19706: "null",
    19707: "Amulet of eternal glory",
    19708: "null",
    19709: "null",
    19710: "Ring of suffering (i)",
    19711: "null",
    19712: "Clue nest (easy)",
    19713: "null",
    19714: "Clue nest (medium)",
    19715: "null",
    19716: "Clue nest (hard)",
    19717: "null",
    19718: "Clue nest (elite)",
    19719: "null",
    19720: "Occult necklace (or)",
    19721: "null",
    19722: "Dragon defender (t)",
    19723: "null",
    19724: "Left eye patch",
    19725: "null",
    19726: "null",
    19727: "Double eye patch",
    19728: "null",
    19729: "null",
    19730: "Bloodhound",
    19731: "null",
    19732: "Lucky impling jar",
    19733: "null",
    19734: "Clue scroll (medium)",
    19735: "Challenge scroll (medium)",
    19736: "Clue scroll (medium)",
    19737: "Challenge scroll (medium)",
    19738: "Clue scroll (medium)",
    19739: "Challenge scroll (medium)",
    19740: "Clue scroll (medium)",
    19741: "Challenge scroll (medium)",
    19742: "Clue scroll (medium)",
    19743: "Challenge scroll (medium)",
    19744: "Clue scroll (medium)",
    19745: "Challenge scroll (medium)",
    19746: "Clue scroll (medium)",
    19747: "Challenge scroll (medium)",
    19748: "Clue scroll (medium)",
    19749: "Challenge scroll (medium)",
    19750: "Clue scroll (medium)",
    19751: "Challenge scroll (medium)",
    19752: "Clue scroll (medium)",
    19753: "Challenge scroll (medium)",
    19754: "Clue scroll (medium)",
    19755: "Challenge scroll (medium)",
    19756: "Clue scroll (medium)",
    19757: "Challenge scroll (medium)",
    19758: "Clue scroll (medium)",
    19759: "Challenge scroll (medium)",
    19760: "Clue scroll (medium)",
    19761: "Key (medium)",
    19762: "Clue scroll (medium)",
    19763: "Challenge scroll (medium)",
    19764: "Clue scroll (medium)",
    19765: "Challenge scroll (medium)",
    19766: "Clue scroll (medium)",
    19767: "Challenge scroll (medium)",
    19768: "Clue scroll (medium)",
    19769: "Challenge scroll (medium)",
    19770: "Clue scroll (medium)",
    19771: "Challenge scroll (medium)",
    19772: "Clue scroll (medium)",
    19773: "Challenge scroll (medium)",
    19774: "Clue scroll (medium)",
    19775: "Casket (medium)",
    19776: "Clue scroll (medium)",
    19777: "Casket (medium)",
    19778: "Clue scroll (medium)",
    19779: "Casket (medium)",
    19780: "Clue scroll (medium)",
    19781: "Casket (medium)",
    19782: "Clue scroll (elite)",
    19783: "Clue scroll (elite)",
    19784: "Clue scroll (elite)",
    19785: "Clue scroll (elite)",
    19786: "Clue scroll (elite)",
    19787: "Clue scroll (elite)",
    19788: "Clue scroll (elite)",
    19789: "Clue scroll (elite)",
    19790: "Clue scroll (elite)",
    19791: "Clue scroll (elite)",
    19792: "Clue scroll (elite)",
    19793: "Clue scroll (elite)",
    19794: "Clue scroll (elite)",
    19795: "Clue scroll (elite)",
    19796: "Clue scroll (elite)",
    19797: "Clue scroll (elite)",
    19798: "Clue scroll (elite)",
    19799: "Clue scroll (elite)",
    19800: "Clue scroll (elite)",
    19801: "Clue scroll (elite)",
    19802: "Clue scroll (elite)",
    19803: "Clue scroll (elite)",
    19804: "Clue scroll (elite)",
    19805: "Clue scroll (elite)",
    19806: "Clue scroll (elite)",
    19807: "Clue scroll (elite)",
    19808: "Clue scroll (elite)",
    19809: "Clue scroll (elite)",
    19810: "Clue scroll (elite)",
    19811: "Clue scroll (elite)",
    19812: "Key (elite)",
    19813: "Clue scroll (elite)",
    19814: "Clue scroll (easy)",
    19815: "Casket (easy)",
    19816: "Clue scroll (easy)",
    19817: "Clue scroll (easy)",
    19818: "Clue scroll (easy)",
    19819: "Clue scroll (easy)",
    19820: "Clue scroll (easy)",
    19821: "Clue scroll (easy)",
    19822: "Clue scroll (easy)",
    19823: "Clue scroll (easy)",
    19824: "Clue scroll (easy)",
    19825: "Clue scroll (easy)",
    19826: "Clue scroll (easy)",
    19827: "Casket (easy)",
    19828: "Clue scroll (easy)",
    19829: "Clue scroll (easy)",
    19830: "Clue scroll (easy)",
    19831: "Clue scroll (easy)",
    19832: "Casket (easy)",
    19833: "Clue scroll (easy)",
    19834: "Casket (easy)",
    19835: "Clue scroll (master)",
    19836: "Reward casket (master)",
    19837: "Torn clue scroll (part 1)",
    19838: "Torn clue scroll (part 2)",
    19839: "Torn clue scroll (part 3)",
    19840: "Clue scroll (hard)",
    19841: "Casket (hard)",
    19842: "Clue scroll (hard)",
    19843: "Casket (hard)",
    19844: "Clue scroll (hard)",
    19845: "Casket (hard)",
    19846: "Clue scroll (hard)",
    19847: "Challenge scroll (hard)",
    19848: "Clue scroll (hard)",
    19849: "Casket (hard)",
    19850: "Clue scroll (hard)",
    19851: "Casket (hard)",
    19852: "Clue scroll (hard)",
    19853: "Clue scroll (hard)",
    19854: "Clue scroll (hard)",
    19855: "Challenge scroll (hard)",
    19856: "Clue scroll (hard)",
    19857: "Clue scroll (hard)",
    19858: "Clue scroll (hard)",
    19859: "Challenge scroll (hard)",
    19860: "Clue scroll (hard)",
    19861: "Casket (hard)",
    19862: "Clue scroll (hard)",
    19863: "Casket (hard)",
    19864: "Clue scroll (hard)",
    19865: "Casket (hard)",
    19866: "Clue scroll (hard)",
    19867: "Casket (hard)",
    19868: "Clue scroll (hard)",
    19869: "Casket (hard)",
    19870: "Clue scroll (hard)",
    19871: "Casket (hard)",
    19872: "Clue scroll (hard)",
    19873: "Casket (hard)",
    19874: "Clue scroll (hard)",
    19875: "Casket (hard)",
    19876: "Clue scroll (hard)",
    19877: "Casket (hard)",
    19878: "Clue scroll (hard)",
    19879: "Casket (hard)",
    19880: "Clue scroll (hard)",
    19881: "Casket (hard)",
    19882: "Clue scroll (hard)",
    19883: "Challenge scroll (hard)",
    19884: "Clue scroll (hard)",
    19885: "Challenge scroll (hard)",
    19886: "Clue scroll (hard)",
    19887: "Puzzle box (hard)",
    19888: "Clue scroll (hard)",
    19889: "Challenge scroll (hard)",
    19890: "Clue scroll (hard)",
    19891: "Puzzle box (hard)",
    19892: "Clue scroll (hard)",
    19893: "Challenge scroll (hard)",
    19894: "Clue scroll (hard)",
    19895: "Puzzle box (hard)",
    19896: "Clue scroll (hard)",
    19897: "Puzzle box (hard)",
    19898: "Clue scroll (hard)",
    19899: "Challenge scroll (hard)",
    19900: "Clue scroll (hard)",
    19901: "Challenge scroll (hard)",
    19902: "Clue scroll (hard)",
    19903: "Puzzle box (hard)",
    19904: "Clue scroll (hard)",
    19905: "Challenge scroll (hard)",
    19906: "Clue scroll (hard)",
    19907: "Challenge scroll (hard)",
    19908: "Clue scroll (hard)",
    19909: "Challenge scroll (hard)",
    19910: "Clue scroll (hard)",
    19911: "Puzzle box (hard)",
    19912: "Zombie head",
    19913: "null",
    19914: "null",
    19915: "Cyclops head",
    19916: "null",
    19917: "null",
    19918: "Nunchaku",
    19919: "null",
    19920: "null",
    19921: "Ancient d'hide boots",
    19922: "null",
    19923: "null",
    19924: "Bandos d'hide boots",
    19925: "null",
    19926: "null",
    19927: "Guthix d'hide boots",
    19928: "null",
    19929: "null",
    19930: "Armadyl d'hide boots",
    19931: "null",
    19932: "null",
    19933: "Saradomin d'hide boots",
    19934: "null",
    19935: "null",
    19936: "Zamorak d'hide boots",
    19937: "null",
    19938: "null",
    19939: "Strange device",
    19940: "null",
    19941: "Heavy casket",
    19942: "null",
    19943: "Arceuus scarf",
    19944: "null",
    19945: "null",
    19946: "Hosidius scarf",
    19947: "null",
    19948: "null",
    19949: "Lovakengj scarf",
    19950: "null",
    19951: "null",
    19952: "Piscarilius scarf",
    19953: "null",
    19954: "null",
    19955: "Shayzien scarf",
    19956: "null",
    19957: "null",
    19958: "Dark tuxedo jacket",
    19959: "null",
    19960: "null",
    19961: "Dark tuxedo cuffs",
    19962: "null",
    19963: "null",
    19964: "Dark trousers",
    19965: "null",
    19966: "null",
    19967: "Dark tuxedo shoes",
    19968: "null",
    19969: "null",
    19970: "Dark bow tie",
    19971: "null",
    19972: "null",
    19973: "Light tuxedo jacket",
    19974: "null",
    19975: "null",
    19976: "Light tuxedo cuffs",
    19977: "null",
    19978: "null",
    19979: "Light trousers",
    19980: "null",
    19981: "null",
    19982: "Light tuxedo shoes",
    19983: "null",
    19984: "null",
    19985: "Light bow tie",
    19986: "null",
    19987: "null",
    19988: "Blacksmith's helm",
    19989: "null",
    19990: "null",
    19991: "Bucket helm",
    19992: "null",
    19993: "null",
    19994: "Ranger gloves",
    19995: "null",
    19996: "null",
    19997: "Holy wraps",
    19998: "null",
    19999: "null",
    20000: "Dragon scimitar (or)",
    20001: "null",
    20002: "Dragon scimitar ornament kit",
    20003: "null",
    20004: "null",
    20005: "Ring of nature",
    20006: "null",
    20007: "null",
    20008: "Fancy tiara",
    20009: "null",
    20010: "null",
    20011: "3rd age axe",
    20012: "null",
    20013: "null",
    20014: "3rd age pickaxe",
    20015: "null",
    20016: "null",
    20017: "Ring of coins",
    20018: "null",
    20019: "null",
    20020: "Lesser demon mask",
    20021: "null",
    20022: "null",
    20023: "Greater demon mask",
    20024: "null",
    20025: "null",
    20026: "Black demon mask",
    20027: "null",
    20028: "null",
    20029: "Old demon mask",
    20030: "null",
    20031: "null",
    20032: "Jungle demon mask",
    20033: "null",
    20034: "null",
    20035: "Samurai kasa",
    20036: "null",
    20037: "null",
    20038: "Samurai shirt",
    20039: "null",
    20040: "null",
    20041: "Samurai gloves",
    20042: "null",
    20043: "null",
    20044: "Samurai greaves",
    20045: "null",
    20046: "null",
    20047: "Samurai boots",
    20048: "null",
    20049: "null",
    20050: "Obsidian cape (r)",
    20051: "null",
    20052: "null",
    20053: "Half moon spectacles",
    20054: "null",
    20055: "null",
    20056: "Ale of the gods",
    20057: "null",
    20058: "null",
    20059: "Bucket helm (g)",
    20060: "null",
    20061: "null",
    20062: "Torture ornament kit",
    20063: "null",
    20064: "null",
    20065: "Occult ornament kit",
    20066: "null",
    20067: "null",
    20068: "Armadyl godsword ornament kit",
    20069: "null",
    20070: "null",
    20071: "Bandos godsword ornament kit",
    20072: "null",
    20073: "null",
    20074: "Saradomin godsword ornament kit",
    20075: "null",
    20076: "null",
    20077: "Zamorak godsword ornament kit",
    20078: "null",
    20079: "null",
    20080: "Mummy's head",
    20081: "null",
    20082: "null",
    20083: "Mummy's body",
    20084: "null",
    20085: "null",
    20086: "Mummy's hands",
    20087: "null",
    20088: "null",
    20089: "Mummy's legs",
    20090: "null",
    20091: "null",
    20092: "Mummy's feet",
    20093: "null",
    20094: "null",
    20095: "Ankou mask",
    20096: "null",
    20097: "null",
    20098: "Ankou top",
    20099: "null",
    20100: "null",
    20101: "Ankou gloves",
    20102: "null",
    20103: "null",
    20104: "Ankou's leggings",
    20105: "null",
    20106: "null",
    20107: "Ankou socks",
    20108: "null",
    20109: "null",
    20110: "Bowl wig",
    20111: "null",
    20112: "null",
    20113: "Arceuus hood",
    20114: "null",
    20115: "null",
    20116: "Hosidius hood",
    20117: "null",
    20118: "null",
    20119: "Lovakengj hood",
    20120: "null",
    20121: "null",
    20122: "Piscarilius hood",
    20123: "null",
    20124: "null",
    20125: "Shayzien hood",
    20126: "null",
    20127: "null",
    20128: "Hood of darkness",
    20129: "null",
    20130: "null",
    20131: "Robe top of darkness",
    20132: "null",
    20133: "null",
    20134: "Gloves of darkness",
    20135: "null",
    20136: "null",
    20137: "Robe bottom of darkness",
    20138: "null",
    20139: "null",
    20140: "Boots of darkness",
    20141: "null",
    20142: "null",
    20143: "Dragon defender ornament kit",
    20144: "null",
    20145: "null",
    20146: "Gilded med helm",
    20147: "null",
    20148: "null",
    20149: "Gilded chainbody",
    20150: "null",
    20151: "null",
    20152: "Gilded sq shield",
    20153: "null",
    20154: "null",
    20155: "Gilded 2h sword",
    20156: "null",
    20157: "null",
    20158: "Gilded spear",
    20159: "null",
    20160: "null",
    20161: "Gilded hasta",
    20162: "null",
    20163: "null",
    20164: "Large spade",
    20165: "null",
    20166: "Wooden shield (g)",
    20167: "null",
    20168: "null",
    20169: "Steel platebody (g)",
    20170: "null",
    20171: "null",
    20172: "Steel platelegs (g)",
    20173: "null",
    20174: "null",
    20175: "Steel plateskirt (g)",
    20176: "null",
    20177: "null",
    20178: "Steel full helm (g)",
    20179: "null",
    20180: "null",
    20181: "Steel kiteshield (g)",
    20182: "null",
    20183: "null",
    20184: "Steel platebody (t)",
    20185: "null",
    20186: "null",
    20187: "Steel platelegs (t)",
    20188: "null",
    20189: "null",
    20190: "Steel plateskirt (t)",
    20191: "null",
    20192: "null",
    20193: "Steel full helm (t)",
    20194: "null",
    20195: "null",
    20196: "Steel kiteshield (t)",
    20197: "null",
    20198: "null",
    20199: "Monk's robe top (g)",
    20200: "null",
    20201: "null",
    20202: "Monk's robe (g)",
    20203: "null",
    20204: "null",
    20205: "Golden chef's hat",
    20206: "null",
    20207: "null",
    20208: "Golden apron",
    20209: "null",
    20210: "null",
    20211: "Team cape zero",
    20212: "null",
    20213: "null",
    20214: "Team cape x",
    20215: "null",
    20216: "null",
    20217: "Team cape i",
    20218: "null",
    20219: "null",
    20220: "Holy blessing",
    20221: "null",
    20222: "null",
    20223: "Unholy blessing",
    20224: "null",
    20225: "null",
    20226: "Peaceful blessing",
    20227: "null",
    20228: "null",
    20229: "Honourable blessing",
    20230: "null",
    20231: "null",
    20232: "War blessing",
    20233: "null",
    20234: "null",
    20235: "Ancient blessing",
    20236: "null",
    20237: "null",
    20238: "Charge dragonstone jewellery scroll",
    20239: "null",
    20240: "Crier coat",
    20241: "null",
    20242: "null",
    20243: "Crier bell",
    20244: "null",
    20245: "null",
    20246: "Black leprechaun hat",
    20247: "null",
    20248: "null",
    20249: "Clueless scroll",
    20250: "null",
    20251: "Arceuus banner",
    20252: "null",
    20253: "null",
    20254: "Hosidius banner",
    20255: "null",
    20256: "null",
    20257: "Lovakengj banner",
    20258: "null",
    20259: "null",
    20260: "Piscarilius banner",
    20261: "null",
    20262: "null",
    20263: "Shayzien banner",
    20264: "null",
    20265: "null",
    20266: "Black unicorn mask",
    20267: "null",
    20268: "null",
    20269: "White unicorn mask",
    20270: "null",
    20271: "null",
    20272: "Cabbage round shield",
    20273: "null",
    20274: "null",
    20275: "Gnomish firelighter",
    20276: "null",
    20277: "null",
    20278: "Gnomish firelighter",
    20279: "null",
    20280: "Puzzle box (master)",
    20281: "Puzzle box (master)",
    20282: "Puzzle box (master)",
    20283: "",
    20284: "",
    20285: "",
    20286: "",
    20287: "",
    20288: "",
    20289: "",
    20290: "",
    20291: "",
    20292: "",
    20293: "",
    20294: "",
    20295: "",
    20296: "",
    20297: "",
    20298: "",
    20299: "",
    20300: "",
    20301: "",
    20302: "",
    20303: "",
    20304: "",
    20305: "",
    20306: "",
    20307: "",
    20308: "",
    20309: "",
    20310: "",
    20311: "",
    20312: "",
    20313: "",
    20314: "",
    20315: "",
    20316: "",
    20317: "",
    20318: "",
    20319: "",
    20320: "",
    20321: "",
    20322: "",
    20323: "",
    20324: "",
    20325: "",
    20326: "",
    20327: "",
    20328: "",
    20329: "",
    20330: "",
    20331: "",
    20332: "",
    20333: "",
    20334: "",
    20335: "",
    20336: "",
    20337: "",
    20338: "",
    20339: "",
    20340: "",
    20341: "",
    20342: "",
    20343: "",
    20344: "",
    20345: "",
    20346: "",
    20347: "",
    20348: "",
    20349: "",
    20350: "",
    20351: "",
    20352: "",
    20353: "",
    20354: "",
    20355: "Light box",
    20356: "null",
    20357: "null",
    20358: "Clue geode (easy)",
    20359: "null",
    20360: "Clue geode (medium)",
    20361: "null",
    20362: "Clue geode (hard)",
    20363: "null",
    20364: "Clue geode (elite)",
    20365: "null",
    20366: "Amulet of torture (or)",
    20367: "null",
    20368: "Armadyl godsword (or)",
    20369: "null",
    20370: "Bandos godsword (or)",
    20371: "null",
    20372: "Saradomin godsword (or)",
    20373: "null",
    20374: "Zamorak godsword (or)",
    20375: "null",
    20376: "Steel trimmed set (lg)",
    20377: "null",
    20378: "null",
    20379: "Steel trimmed set (sk)",
    20380: "null",
    20381: "null",
    20382: "Steel gold-trimmed set (lg)",
    20383: "null",
    20384: "null",
    20385: "Steel gold-trimmed set (sk)",
    20386: "null",
    20387: "null",
    20388: "Adamant arrow",
    20389: "Dragon arrow",
    20390: "Shark",
    20391: "Null",
    20392: "Null",
    20393: "Prayer potion(4)",
    20394: "Prayer potion(3)",
    20395: "Prayer potion(2)",
    20396: "Prayer potion(1)",
    20397: "Spear",
    20398: "Null",
    20399: "Null",
    20400: "Null",
    20401: "Yew shortbow",
    20402: "Rune scimitar",
    20403: "Maple shortbow",
    20404: "Null",
    20405: "Abyssal whip",
    20406: "Dragon scimitar",
    20407: "Dragon dagger",
    20408: "Dark bow",
    20409: "Null",
    20410: "Null",
    20411: "Null",
    20412: "Null",
    20413: "Null",
    20414: "Null",
    20415: "Adamant platebody",
    20416: "Adamant platelegs",
    20417: "Blue d'hide body",
    20418: "Blue d'hide chaps",
    20419: "Null",
    20420: "Null",
    20421: "Rune platebody",
    20422: "Rune platelegs",
    20423: "Black d'hide body",
    20424: "Black d'hide chaps",
    20425: "Mystic robe top",
    20426: "Mystic robe bottom",
    20427: "Null",
    20428: "Dragon chainbody",
    20429: "Dragon platelegs",
    20430: "Ancient magicks tablet",
    20431: "Ancient staff",
    20432: "null",
    20433: "Evil chicken feet",
    20434: "null",
    20435: "null",
    20436: "Evil chicken wings",
    20437: "null",
    20438: "null",
    20439: "Evil chicken head",
    20440: "null",
    20441: "null",
    20442: "Evil chicken legs",
    20443: "null",
    20444: "null",
    20445: "Fire cape (broken)",
    20446: "null",
    20447: "Fire max cape (broken)",
    20448: "null",
    20449: "Bronze defender (broken)",
    20450: "null",
    20451: "Iron defender (broken)",
    20452: "null",
    20453: "Steel defender (broken)",
    20454: "null",
    20455: "Black defender (broken)",
    20456: "null",
    20457: "Mithril defender (broken)",
    20458: "null",
    20459: "Adamant defender (broken)",
    20460: "null",
    20461: "Rune defender (broken)",
    20462: "null",
    20463: "Dragon defender (broken)",
    20464: "null",
    20465: "Void knight top (broken)",
    20466: "null",
    20467: "Elite void top (broken)",
    20468: "null",
    20469: "Void knight robe (broken)",
    20470: "null",
    20471: "Elite void robe (broken)",
    20472: "null",
    20473: "Void knight mace (broken)",
    20474: "null",
    20475: "Void knight gloves (broken)",
    20476: "null",
    20477: "Void mage helm (broken)",
    20478: "null",
    20479: "Void ranger helm (broken)",
    20480: "null",
    20481: "Void melee helm (broken)",
    20482: "null",
    20483: "Decorative sword (broken)",
    20484: "null",
    20485: "Decorative armour (broken)",
    20486: "null",
    20487: "Decorative armour (broken)",
    20488: "null",
    20489: "Decorative helm (broken)",
    20490: "null",
    20491: "Decorative shield (broken)",
    20492: "null",
    20493: "Decorative armour (broken)",
    20494: "null",
    20495: "Decorative armour (broken)",
    20496: "null",
    20497: "Decorative armour (broken)",
    20498: "null",
    20499: "Decorative armour (broken)",
    20500: "null",
    20501: "Decorative armour (broken)",
    20502: "null",
    20503: "Decorative armour (broken)",
    20504: "null",
    20505: "Decorative armour (broken)",
    20506: "null",
    20507: "Fighter hat (broken)",
    20508: "null",
    20509: "Ranger hat (broken)",
    20510: "null",
    20511: "Healer hat (broken)",
    20512: "null",
    20513: "Fighter torso (broken)",
    20514: "null",
    20515: "Penance skirt (broken)",
    20516: "null",
    20517: "Elder chaos top",
    20518: "null",
    20519: "null",
    20520: "Elder chaos robe",
    20521: "null",
    20522: "null",
    20523: "Catalytic rune pack",
    20524: "Elemental rune pack",
    20525: "Adamant arrow pack",
    20526: "Bloody key",
    20527: "Survival token",
    20528: "null",
    20529: "null",
    20530: "null",
    20531: "null",
    20532: "Stash units (easy)",
    20533: "Stash units (medium)",
    20534: "Stash units (hard)",
    20535: "Stash units (elite)",
    20536: "Stash units (master)",
    20537: "Saradomin halo (broken)",
    20538: "null",
    20539: "Zamorak halo (broken)",
    20540: "null",
    20541: "Guthix halo (broken)",
    20542: "null",
    20543: "Reward casket (elite)",
    20544: "Reward casket (hard)",
    20545: "Reward casket (medium)",
    20546: "Reward casket (easy)",
    20547: "Monkfish",
    20548: "Super energy(4)",
    20549: "Super energy(3)",
    20550: "Super energy(2)",
    20551: "Super energy(1)",
    20552: "Rune battleaxe",
    20553: "Beginner wand",
    20554: "Toktz-xil-ak",
    20555: "Rune 2h sword",
    20556: "Apprentice wand",
    20557: "Granite maul",
    20558: "Magic shortbow",
    20559: "Dragon 2h sword",
    20560: "Master wand",
    20561: "Adamant full helm",
    20562: "Mystic hat",
    20563: "Proselyte sallet",
    20564: "Proselyte hauberk",
    20565: "Proselyte cuisse",
    20566: "Red d'hide body",
    20567: "Red d'hide chaps",
    20568: "Splitbark helm",
    20569: "Null",
    20570: "Null",
    20571: "Warrior helm",
    20572: "Archer helm",
    20573: "Farseer helm",
    20574: "Infinity top",
    20575: "Infinity bottoms",
    20576: "3rd age robe top",
    20577: "3rd age robe",
    20578: "Climbing boots",
    20579: "Mystic boots",
    20580: "Snakeskin boots",
    20581: "Mithril gloves",
    20582: "Adamant gloves",
    20583: "Rune gloves",
    20584: "Amulet of accuracy",
    20585: "Amulet of power",
    20586: "Amulet of glory",
    20587: "Rope",
    20588: "null",
    20589: "null",
    20590: "Stale baguette",
    20591: "null",
    20592: "null",
    20593: "Armadyl godsword",
    20594: "Bank filler",
    20595: "Elder chaos hood",
    20596: "null",
    20597: "null",
    20598: "Ahrim's robetop",
    20599: "Ahrim's robeskirt",
    20600: "Rune arrow",
    20601: "Wooden table",
    20602: "Waxwood bed",
    20603: "Carpet",
    20604: "Wooden stool",
    20605: "Wooden chair",
    20606: "null",
    20607: "Rune arrow pack",
    20608: "Bloodier key",
    20609: "Fairy enchantment",
    20610: "null",
    20611: "Ancient signet",
    20612: "null",
    20613: "Lunar signet",
    20614: "null",
    20615: "Arceuus signet",
    20616: "null",
    20617: "Ancient altar",
    20618: "Lunar altar",
    20619: "Dark altar",
    20620: "Occult altar",
    20621: "Occult altar",
    20622: "Occult altar",
    20623: "Mahogany adventure log",
    20624: "Gilded adventure log",
    20625: "Marble adventure log",
    20626: "Basic jewellery box",
    20627: "Fancy jewellery box",
    20628: "Ornate jewellery box",
    20629: "Boss lair display",
    20630: "Mounted emblem",
    20631: "Mounted coins",
    20632: "Cape hanger",
    20633: "Quest list",
    20634: "Tip jar",
    20635: "Spirit tree",
    20636: "Fairy ring",
    20637: "Spirit tree & fairy ring",
    20638: "Topiary bush",
    20639: "Restoration pool",
    20640: "Revitalisation pool",
    20641: "Rejuvenation pool",
    20642: "Fancy rejuvenation pool",
    20643: "Ornate rejuvenation pool",
    20644: "Zen theme",
    20645: "Otherworldly theme",
    20646: "Volcanic theme",
    20647: "Redwood fence",
    20648: "Obsidian fence",
    20649: "Teak garden bench",
    20650: "Gnome bench",
    20651: "Marble decorative bench",
    20652: "Obsidian decorative bench",
    20653: "Superior garden",
    20654: "Achievement gallery",
    20655: "Ring of suffering (r)",
    20656: "null",
    20657: "Ring of suffering (ri)",
    20658: "null",
    20659: "Giant squirrel",
    20660: "null",
    20661: "Tangleroot",
    20662: "null",
    20663: "Rocky",
    20664: "null",
    20665: "Rift guardian",
    20666: "null",
    20667: "Rift guardian",
    20668: "null",
    20669: "Rift guardian",
    20670: "null",
    20671: "Rift guardian",
    20672: "null",
    20673: "Rift guardian",
    20674: "null",
    20675: "Rift guardian",
    20676: "null",
    20677: "Rift guardian",
    20678: "null",
    20679: "Rift guardian",
    20680: "null",
    20681: "Rift guardian",
    20682: "null",
    20683: "Rift guardian",
    20684: "null",
    20685: "Rift guardian",
    20686: "null",
    20687: "Rift guardian",
    20688: "null",
    20689: "Rift guardian",
    20690: "null",
    20691: "Rift guardian",
    20692: "null",
    20693: "Phoenix",
    20694: "null",
    20695: "Bruma root",
    20696: "Bruma kindling",
    20697: "Rejuvenation potion (unf)",
    20698: "Bruma herb",
    20699: "Rejuvenation potion (4)",
    20700: "Rejuvenation potion (3)",
    20701: "Rejuvenation potion (2)",
    20702: "Rejuvenation potion (1)",
    20703: "Supply crate",
    20704: "Pyromancer garb",
    20705: "null",
    20706: "Pyromancer robe",
    20707: "null",
    20708: "Pyromancer hood",
    20709: "null",
    20710: "Pyromancer boots",
    20711: "null",
    20712: "Warm gloves",
    20713: "null",
    20714: "Tome of fire",
    20715: "null",
    20716: "Tome of fire (empty)",
    20717: "null",
    20718: "Burnt page",
    20719: "null",
    20720: "Bruma torch",
    20721: "null",
    20722: "Emerald lantern",
    20723: "null",
    20724: "Imbued heart",
    20725: "null",
    20726: "null",
    20727: "Leaf-bladed battleaxe",
    20728: "null",
    20729: "null",
    20730: "Mist battlestaff",
    20731: "null",
    20732: "null",
    20733: "Mystic mist staff",
    20734: "null",
    20735: "null",
    20736: "Dust battlestaff",
    20737: "null",
    20738: "null",
    20739: "Mystic dust staff",
    20740: "null",
    20741: "null",
    20742: "Empty jug pack",
    20743: "null",
    20744: "null",
    20745: "Combat dummy",
    20746: "Undead combat dummy",
    20747: "Bologa's blessing",
    20748: "null",
    20749: "Zamorak's grapes",
    20750: "null",
    20751: "null",
    20752: "Zamorak's unfermented wine",
    20753: "null",
    20754: "Giant key",
    20755: "null",
    20756: "Hill giant club",
    20757: "null",
    20758: "null",
    20759: "null",
    20760: "Ardougne max cape",
    20761: "null",
    20762: "Null",
    20763: "Null",
    20764: "Ardougne max hood",
    20765: "null",
    20766: "Manor key",
    20767: "Ruby key",
    20768: "Emerald key",
    20769: "Sapphire key",
    20770: "Notes",
    20771: "Notes",
    20772: "Notes",
    20773: "Banshee mask",
    20774: "null",
    20775: "Banshee top",
    20776: "null",
    20777: "Banshee robe",
    20778: "null",
    20779: "Hunting knife",
    20780: "null",
    20781: "Killer's knife",
    20782: "Bandos godsword",
    20783: "null",
    20784: "Dragon claws",
    20785: "Dragon warhammer",
    20786: "Ring of wealth (i5)",
    20787: "Ring of wealth (i4)",
    20788: "Ring of wealth (i3)",
    20789: "Ring of wealth (i2)",
    20790: "Ring of wealth (i1)",
    20791: "Extra supply crate",
    20792: "Hardcore ironman helm",
    20793: "null",
    20794: "Hardcore ironman platebody",
    20795: "null",
    20796: "Hardcore ironman platelegs",
    20797: "null",
    20798: "Smelly journal",
    20799: "Kindling",
    20800: "Empty gourd vial",
    20801: "Water-filled gourd vial",
    20802: "Healer icon",
    20803: "Null",
    20804: "Null",
    20805: "Null",
    20806: "Null",
    20807: "Null",
    20808: "Null",
    20809: "Null",
    20810: "Null",
    20811: "Null",
    20812: "Null",
    20813: "Null",
    20814: "Null",
    20815: "Null",
    20816: "Null",
    20817: "Null",
    20818: "Null",
    20819: "Null",
    20820: "Null",
    20821: "Null",
    20822: "Null",
    20823: "Null",
    20824: "Null",
    20825: "Null",
    20826: "Null",
    20827: "Null",
    20828: "Null",
    20829: "Null",
    20830: "Null",
    20831: "Null",
    20832: "Snow globe",
    20833: "null",
    20834: "Sack of presents",
    20835: "null",
    20836: "Giant present",
    20837: "null",
    20838: "Corrupted helm",
    20839: "null",
    20840: "Corrupted platebody",
    20841: "null",
    20842: "Corrupted platelegs",
    20843: "null",
    20844: "Corrupted plateskirt",
    20845: "null",
    20846: "Corrupted kiteshield",
    20847: "null",
    20848: "null",
    20849: "Dragon thrownaxe",
    20850: "null",
    20851: "Olmlet",
    20852: "null",
    20853: "Cave worms",
    20854: "Burnt fish",
    20855: "Raw pysk fish (0)",
    20856: "Pysk fish (0)",
    20857: "Raw suphi fish (1)",
    20858: "Suphi fish (1)",
    20859: "Raw leckish fish (2)",
    20860: "Leckish fish (2)",
    20861: "Raw brawk fish (3)",
    20862: "Brawk fish (3)",
    20863: "Raw mycil fish (4)",
    20864: "Mycil fish (4)",
    20865: "Raw roqed fish (5)",
    20866: "Roqed fish (5)",
    20867: "Raw kyren fish (6)",
    20868: "Kyren fish (6)",
    20869: "Burnt bat",
    20870: "Raw guanic bat (0)",
    20871: "Guanic bat (0)",
    20872: "Raw prael bat (1)",
    20873: "Prael bat (1)",
    20874: "Raw giral bat (2)",
    20875: "Giral bat (2)",
    20876: "Raw phluxia bat (3)",
    20877: "Phluxia bat (3)",
    20878: "Raw kryket bat (4)",
    20879: "Kryket bat (4)",
    20880: "Raw murng bat (5)",
    20881: "Murng bat (5)",
    20882: "Raw psykk bat (6)",
    20883: "Psykk bat (6)",
    20884: "Keystone crystal",
    20885: "Cavern grubs",
    20886: "Creature keeper's journal",
    20887: "null",
    20888: "Nistirio's manifesto",
    20889: "null",
    20890: "Tekton's journal",
    20891: "null",
    20892: "Medivaemia blossom",
    20893: "Transdimensional notes",
    20894: "null",
    20895: "Vanguard judgement",
    20896: "null",
    20897: "Houndmaster's diary",
    20898: "null",
    20899: "Dark journal",
    20900: "null",
    20901: "Grimy noxifer",
    20902: "Noxifer",
    20903: "Noxifer seed",
    20904: "Grimy golpar",
    20905: "Golpar",
    20906: "Golpar seed",
    20907: "Grimy buchu leaf",
    20908: "Buchu leaf",
    20909: "Buchu seed",
    20910: "Stinkhorn mushroom",
    20911: "Endarkened juice",
    20912: "Cicely",
    20913: "Elder (-)(1)",
    20914: "Elder (-)(2)",
    20915: "Elder (-)(3)",
    20916: "Elder (-)(4)",
    20917: "Elder potion (1)",
    20918: "Elder potion (2)",
    20919: "Elder potion (3)",
    20920: "Elder potion (4)",
    20921: "Elder (+)(1)",
    20922: "Elder (+)(2)",
    20923: "Elder (+)(3)",
    20924: "Elder (+)(4)",
    20925: "Twisted (-)(1)",
    20926: "Twisted (-)(2)",
    20927: "Twisted (-)(3)",
    20928: "Twisted (-)(4)",
    20929: "Twisted potion (1)",
    20930: "Twisted potion (2)",
    20931: "Twisted potion (3)",
    20932: "Twisted potion (4)",
    20933: "Twisted (+)(1)",
    20934: "Twisted (+)(2)",
    20935: "Twisted (+)(3)",
    20936: "Twisted (+)(4)",
    20937: "Kodai (-)(1)",
    20938: "Kodai (-)(2)",
    20939: "Kodai (-)(3)",
    20940: "Kodai (-)(4)",
    20941: "Kodai potion (1)",
    20942: "Kodai potion (2)",
    20943: "Kodai potion (3)",
    20944: "Kodai potion (4)",
    20945: "Kodai (+)(1)",
    20946: "Kodai (+)(2)",
    20947: "Kodai (+)(3)",
    20948: "Kodai (+)(4)",
    20949: "Revitalisation (-)(1)",
    20950: "Revitalisation (-)(2)",
    20951: "Revitalisation (-)(3)",
    20952: "Revitalisation (-)(4)",
    20953: "Revitalisation potion (1)",
    20954: "Revitalisation potion (2)",
    20955: "Revitalisation potion (3)",
    20956: "Revitalisation potion (4)",
    20957: "Revitalisation (+)(1)",
    20958: "Revitalisation (+)(2)",
    20959: "Revitalisation (+)(3)",
    20960: "Revitalisation (+)(4)",
    20961: "Prayer enhance (-)(1)",
    20962: "Prayer enhance (-)(2)",
    20963: "Prayer enhance (-)(3)",
    20964: "Prayer enhance (-)(4)",
    20965: "Prayer enhance (1)",
    20966: "Prayer enhance (2)",
    20967: "Prayer enhance (3)",
    20968: "Prayer enhance (4)",
    20969: "Prayer enhance (+)(1)",
    20970: "Prayer enhance (+)(2)",
    20971: "Prayer enhance (+)(3)",
    20972: "Prayer enhance (+)(4)",
    20973: "Xeric's aid (-)(1)",
    20974: "Xeric's aid (-)(2)",
    20975: "Xeric's aid (-)(3)",
    20976: "Xeric's aid (-)(4)",
    20977: "Xeric's aid (1)",
    20978: "Xeric's aid (2)",
    20979: "Xeric's aid (3)",
    20980: "Xeric's aid (4)",
    20981: "Xeric's aid (+)(1)",
    20982: "Xeric's aid (+)(2)",
    20983: "Xeric's aid (+)(3)",
    20984: "Xeric's aid (+)(4)",
    20985: "Overload (-)(1)",
    20986: "Overload (-)(2)",
    20987: "Overload (-)(3)",
    20988: "Overload (-)(4)",
    20989: "Overload (1)",
    20990: "Overload (2)",
    20991: "Overload (3)",
    20992: "Overload (4)",
    20993: "Overload (+)(1)",
    20994: "Overload (+)(2)",
    20995: "Overload (+)(3)",
    20996: "Overload (+)(4)",
    20997: "Twisted bow",
    20998: "null",
    20999: "null",
    21000: "Twisted buckler",
    21001: "null",
    21002: "null",
    21003: "Elder maul",
    21004: "null",
    21005: "null",
    21006: "Kodai wand",
    21007: "null",
    21008: "null",
    21009: "Dragon sword",
    21010: "null",
    21011: "null",
    21012: "Dragon hunter crossbow",
    21013: "null",
    21014: "null",
    21015: "Dinh's bulwark",
    21016: "null",
    21017: "null",
    21018: "Ancestral hat",
    21019: "null",
    21020: "null",
    21021: "Ancestral robe top",
    21022: "null",
    21023: "null",
    21024: "Ancestral robe bottom",
    21025: "null",
    21026: "null",
    21027: "Dark relic",
    21028: "Dragon harpoon",
    21029: "null",
    21030: "null",
    21031: "Infernal harpoon",
    21032: "null",
    21033: "Infernal harpoon (uncharged)",
    21034: "Dexterous prayer scroll",
    21035: "null",
    21036: "Mallignum root plank",
    21037: "Small storage unit",
    21038: "Medium storage unit",
    21039: "Large storage unit",
    21040: "Medium storage unit",
    21041: "Large storage unit",
    21042: "Large storage unit",
    21043: "Kodai insignia",
    21044: "null",
    21045: "null",
    21046: "Ancient tablet",
    21047: "Torn prayer scroll",
    21048: "null",
    21049: "Ancestral robes set",
    21050: "null",
    21051: "null",
    21052: "Manor key",
    21053: "Ruby key",
    21054: "Emerald key",
    21055: "Sapphire key",
    21056: "Notes",
    21057: "Notes",
    21058: "Notes",
    21059: "Killer's knife",
    21060: "Bandos godsword",
    21061: "Graceful hood",
    21062: "null",
    21063: "Graceful hood",
    21064: "Graceful cape",
    21065: "null",
    21066: "Graceful cape",
    21067: "Graceful top",
    21068: "null",
    21069: "Graceful top",
    21070: "Graceful legs",
    21071: "null",
    21072: "Graceful legs",
    21073: "Graceful gloves",
    21074: "null",
    21075: "Graceful gloves",
    21076: "Graceful boots",
    21077: "null",
    21078: "Graceful boots",
    21079: "Arcane prayer scroll",
    21080: "null",
    21081: "Opal ring",
    21082: "null",
    21083: "null",
    21084: "Jade ring",
    21085: "null",
    21086: "null",
    21087: "Topaz ring",
    21088: "null",
    21089: "null",
    21090: "Opal necklace",
    21091: "null",
    21092: "null",
    21093: "Jade necklace",
    21094: "null",
    21095: "null",
    21096: "Topaz necklace",
    21097: "null",
    21098: "null",
    21099: "Opal amulet (u)",
    21100: "null",
    21101: "null",
    21102: "Jade amulet (u)",
    21103: "null",
    21104: "null",
    21105: "Topaz amulet (u)",
    21106: "null",
    21107: "null",
    21108: "Opal amulet",
    21109: "null",
    21110: "null",
    21111: "Jade amulet",
    21112: "null",
    21113: "null",
    21114: "Topaz amulet",
    21115: "null",
    21116: "null",
    21117: "Opal bracelet",
    21118: "null",
    21119: "null",
    21120: "Jade bracelet",
    21121: "null",
    21122: "null",
    21123: "Topaz bracelet",
    21124: "null",
    21125: "null",
    21126: "Ring of pursuit",
    21127: "null",
    21128: "null",
    21129: "Ring of returning(5)",
    21130: "null",
    21131: "null",
    21132: "Ring of returning(4)",
    21133: "null",
    21134: "Ring of returning(3)",
    21135: "null",
    21136: "Ring of returning(2)",
    21137: "null",
    21138: "Ring of returning(1)",
    21139: "null",
    21140: "Efaritay's aid",
    21141: "null",
    21142: "null",
    21143: "Dodgy necklace",
    21144: "null",
    21145: "null",
    21146: "Necklace of passage(5)",
    21147: "null",
    21148: "null",
    21149: "Necklace of passage(4)",
    21150: "null",
    21151: "Necklace of passage(3)",
    21152: "null",
    21153: "Necklace of passage(2)",
    21154: "null",
    21155: "Necklace of passage(1)",
    21156: "null",
    21157: "Necklace of faith",
    21158: "null",
    21159: "null",
    21160: "Amulet of bounty",
    21161: "null",
    21162: "null",
    21163: "Amulet of chemistry",
    21164: "null",
    21165: "null",
    21166: "Burning amulet(5)",
    21167: "null",
    21168: "null",
    21169: "Burning amulet(4)",
    21170: "null",
    21171: "Burning amulet(3)",
    21172: "null",
    21173: "Burning amulet(2)",
    21174: "null",
    21175: "Burning amulet(1)",
    21176: "null",
    21177: "Expeditious bracelet",
    21178: "null",
    21179: "null",
    21180: "Flamtaer bracelet",
    21181: "null",
    21182: "null",
    21183: "Bracelet of slaughter",
    21184: "null",
    21185: "null",
    21186: "Fire max cape",
    21187: "Rock golem",
    21188: "Rock golem",
    21189: "Rock golem",
    21190: "Rock golem",
    21191: "Rock golem",
    21192: "Rock golem",
    21193: "Rock golem",
    21194: "Rock golem",
    21195: "Rock golem",
    21196: "Rock golem",
    21197: "Rock golem",
    21198: "Lava battlestaff",
    21199: "null",
    21200: "Mystic lava staff",
    21201: "null",
    21202: "Lava staff upgrade kit",
    21203: "null",
    21204: "null",
    21205: "Elder maul",
    21206: "Dragon sword",
    21207: "Dragon thrownaxe",
    21208: "Invitation list",
    21209: "Birthday balloons",
    21210: "null",
    21211: "4th birthday hat",
    21212: "null",
    21213: "Servant's money bag",
    21214: "Easter egg helm",
    21215: "null",
    21216: "Fruity easter egg",
    21217: "Fresh easter egg",
    21218: "Bitter easter egg",
    21219: "Earthy easter egg",
    21220: "Spicy easter egg",
    21221: "Meaty easter egg",
    21222: "Salted easter egg",
    21223: "Rich easter egg",
    21224: "Fluffy easter egg",
    21225: "Smoked easter egg",
    21226: "Fishy easter egg",
    21227: "Crunchy easter egg",
    21228: "Fruity chocolate mix",
    21229: "Fresh chocolate mix",
    21230: "Bitter chocolate mix",
    21231: "Earthy chocolate mix",
    21232: "Spicy chocolate mix",
    21233: "Meaty chocolate mix",
    21234: "Salted chocolate mix",
    21235: "Rich chocolate mix",
    21236: "Fluffy chocolate mix",
    21237: "Smoked chocolate mix",
    21238: "Fishy chocolate mix",
    21239: "Crunchy chocolate mix",
    21240: "Wester banana",
    21241: "Wester papaya",
    21242: "Wester lemon",
    21243: "Bucket of wester sand",
    21244: "Wester spices",
    21245: "Beef fillet",
    21246: "Sea salt",
    21247: "Gold fragment",
    21248: "Fluffy feathers",
    21249: "Wester fish",
    21250: "Rock",
    21251: "Wester chocolate",
    21252: "Egg mould",
    21253: "Farmer's strawhat",
    21254: "Farmer's strawhat",
    21255: "Slayer's staff (e)",
    21256: "null",
    21257: "Slayer's enchantment",
    21258: "null",
    21259: "Enchanted scroll",
    21260: "Enchanted quill",
    21261: "Mysterious orb",
    21262: "Antique lamp",
    21263: "Copper's crimson collar",
    21264: "Purple slayer helmet",
    21265: "null",
    21266: "Purple slayer helmet (i)",
    21267: "null",
    21268: "Slayer ring (eternal)",
    21269: "null",
    21270: "Eternal gem",
    21271: "null",
    21272: "null",
    21273: "Skotos",
    21274: "null",
    21275: "Dark claw",
    21276: "Skull sceptre (i)",
    21277: "null",
    21278: "Tzhaar-hur",
    21279: "Obsidian armour set",
    21280: "null",
    21281: "null",
    21282: "Infernal max hood",
    21283: "null",
    21284: "Infernal max cape",
    21285: "Infernal max cape",
    21286: "null",
    21287: "Infernal cape (broken)",
    21288: "null",
    21289: "Infernal max cape (broken)",
    21290: "null",
    21291: "Jal-nib-rek",
    21292: "null",
    21293: "Infernal eel",
    21294: "null",
    21295: "Infernal cape",
    21296: "null",
    21297: "Infernal cape",
    21298: "Obsidian helmet",
    21299: "null",
    21300: "null",
    21301: "Obsidian platebody",
    21302: "null",
    21303: "null",
    21304: "Obsidian platelegs",
    21305: "null",
    21306: "null",
    21307: "Rogue's equipment crate",
    21308: "Red rainbow strand",
    21309: "Orange rainbow strand",
    21310: "Yellow rainbow strand",
    21311: "Green rainbow strand",
    21312: "Blue rainbow strand",
    21313: "Purple rainbow strand",
    21314: "Rainbow scarf",
    21315: "null",
    21316: "Amethyst broad bolts",
    21317: "null",
    21318: "Amethyst javelin",
    21319: "null",
    21320: "Amethyst javelin(p)",
    21321: "null",
    21322: "Amethyst javelin(p+)",
    21323: "null",
    21324: "Amethyst javelin(p++)",
    21325: "null",
    21326: "Amethyst arrow",
    21327: "null",
    21328: "Amethyst fire arrow",
    21329: "null",
    21330: "Amethyst fire arrow (lit)",
    21331: "null",
    21332: "Amethyst arrow(p)",
    21333: "null",
    21334: "Amethyst arrow(p+)",
    21335: "null",
    21336: "Amethyst arrow(p++)",
    21337: "null",
    21338: "Amethyst bolt tips",
    21339: "null",
    21340: "Rock golem",
    21341: "Unidentified minerals",
    21342: "null",
    21343: "Mining gloves",
    21344: "null",
    21345: "Superior mining gloves",
    21346: "null",
    21347: "Amethyst",
    21348: "null",
    21349: "null",
    21350: "Amethyst arrowtips",
    21351: "null",
    21352: "Amethyst javelin heads",
    21353: "null",
    21354: "Hand fan",
    21355: "null",
    21356: "Minnow",
    21357: "null",
    21358: "Rock golem",
    21359: "Rock golem",
    21360: "Rock golem",
    21361: "null",
    21362: "null",
    21363: "null",
    21364: "null",
    21365: "null",
    21366: "null",
    21367: "null",
    21368: "null",
    21369: "null",
    21370: "null",
    21371: "null",
    21372: "null",
    21373: "null",
    21374: "null",
    21375: "null",
    21376: "null",
    21377: "null",
    21378: "null",
    21379: "null",
    21380: "null",
    21381: "null",
    21382: "null",
    21383: "null",
    21384: "null",
    21385: "null",
    21386: "null",
    21387: "Master scroll book (empty)",
    21388: "null",
    21389: "Master scroll book",
    21390: "null",
    21391: "Brutal black dragon",
    21392: "Expert mining gloves",
    21393: "null",
    21394: "Karambwanji",
    21395: "null",
    21396: "Clan wars cape",
    21397: "Clan wars cape",
    21398: "Clan wars cape",
    21399: "Clan wars cape",
    21400: "Clan wars cape",
    21401: "Clan wars cape",
    21402: "Clan wars cape",
    21403: "Clan wars cape",
    21404: "Clan wars cape",
    21405: "Clan wars cape",
    21406: "Clan wars cape",
    21407: "Clan wars cape",
    21408: "Clan wars cape",
    21409: "Clan wars cape",
    21410: "Clan wars cape",
    21411: "Clan wars cape",
    21412: "Clan wars cape",
    21413: "Clan wars cape",
    21414: "Clan wars cape",
    21415: "Clan wars cape",
    21416: "Clan wars cape",
    21417: "Clan wars cape",
    21418: "Clan wars cape",
    21419: "Clan wars cape",
    21420: "Clan wars cape",
    21421: "Clan wars cape",
    21422: "Clan wars cape",
    21423: "Clan wars cape",
    21424: "Clan wars cape",
    21425: "Clan wars cape",
    21426: "Clan wars cape",
    21427: "Clan wars cape",
    21428: "Wilderness cape",
    21429: "Wilderness cape",
    21430: "Wilderness cape",
    21431: "Wilderness cape",
    21432: "Wilderness cape",
    21433: "Wilderness champion amulet",
    21434: "Wilderness cape",
    21435: "Wilderness cape",
    21436: "Wilderness cape",
    21437: "Wilderness cape",
    21438: "Wilderness cape",
    21439: "Champion's cape",
    21440: "null",
    21441: "null",
    21442: "null",
    21443: "null",
    21444: "null",
    21445: "Pharaoh's sceptre",
    21446: "Pharaoh's sceptre",
    21447: "null",
    21448: "null",
    21449: "null",
    21450: "null",
    21451: "null",
    21452: "null",
    21453: "null",
    21454: "null",
    21455: "null",
    21456: "null",
    21457: "null",
    21458: "null",
    21459: "null",
    21460: "null",
    21461: "null",
    21462: "null",
    21463: "null",
    21464: "null",
    21465: "null",
    21466: "null",
    21467: "null",
    21468: "null",
    21469: "Teak seedling",
    21470: "null",
    21471: "Mahogany seedling",
    21472: "null",
    21473: "Teak seedling (w)",
    21474: "null",
    21475: "Mahogany seedling (w)",
    21476: "null",
    21477: "Teak sapling",
    21478: "null",
    21479: "null",
    21480: "Mahogany sapling",
    21481: "null",
    21482: "null",
    21483: "Ultracompost",
    21484: "null",
    21485: "null",
    21486: "Teak seed",
    21487: "null",
    21488: "Mahogany seed",
    21489: "null",
    21490: "Seaweed spore",
    21491: "null",
    21492: "null",
    21493: "null",
    21494: "null",
    21495: "null",
    21496: "null",
    21497: "null",
    21498: "null",
    21499: "null",
    21500: "null",
    21501: "null",
    21502: "null",
    21503: "null",
    21504: "Giant seaweed",
    21505: "null",
    21506: "null",
    21507: "Fossil island wyvern",
    21508: "Fossil island wyvern",
    21509: "Herbi",
    21510: "null",
    21511: "Herbiboar",
    21512: "Bird house",
    21513: "null",
    21514: "null",
    21515: "Oak bird house",
    21516: "null",
    21517: "null",
    21518: "Willow bird house",
    21519: "null",
    21520: "null",
    21521: "Teak bird house",
    21522: "null",
    21523: "null",
    21524: "Clue scroll (elite)",
    21525: "Clue scroll (elite)",
    21526: "Clue scroll (hard)",
    21527: "Clue scroll (hard)",
    21528: "Sawmill proposal",
    21529: "Sawmill agreement",
    21530: "Bone charm",
    21531: "Potion of sealegs",
    21532: "Iron ore fragment",
    21533: "Silver ore fragment",
    21534: "Coal fragment",
    21535: "Gold ore fragment",
    21536: "Mithril ore fragment",
    21537: "Adamantite ore fragment",
    21538: "Runite ore fragment",
    21539: "Heat-proof vessel",
    21540: "Large rock",
    21541: "Volcanic mine teleport",
    21542: "null",
    21543: "Calcite",
    21544: "null",
    21545: "Pyrophosphite",
    21546: "null",
    21547: "Small enriched bone",
    21548: "null",
    21549: "Medium enriched bone",
    21550: "null",
    21551: "Large enriched bone",
    21552: "null",
    21553: "Rare enriched bone",
    21554: "null",
    21555: "Numulite",
    21556: "null",
    21557: "null",
    21558: "null",
    21559: "null",
    21560: "null",
    21561: "null",
    21562: "Unidentified small fossil",
    21563: "null",
    21564: "Unidentified medium fossil",
    21565: "null",
    21566: "Unidentified large fossil",
    21567: "null",
    21568: "Unidentified rare fossil",
    21569: "null",
    21570: "Small fossilised limbs",
    21571: "null",
    21572: "Small fossilised spine",
    21573: "null",
    21574: "Small fossilised ribs",
    21575: "null",
    21576: "Small fossilised pelvis",
    21577: "null",
    21578: "Small fossilised skull",
    21579: "null",
    21580: "Medium fossilised limbs",
    21581: "null",
    21582: "Medium fossilised spine",
    21583: "null",
    21584: "Medium fossilised ribs",
    21585: "null",
    21586: "Medium fossilised pelvis",
    21587: "null",
    21588: "Medium fossilised skull",
    21589: "null",
    21590: "Fossilised roots",
    21591: "null",
    21592: "Fossilised stump",
    21593: "null",
    21594: "Fossilised branch",
    21595: "null",
    21596: "Fossilised leaf",
    21597: "null",
    21598: "Fossilised mushroom",
    21599: "null",
    21600: "Large fossilised limbs",
    21601: "null",
    21602: "Large fossilised spine",
    21603: "null",
    21604: "Large fossilised ribs",
    21605: "null",
    21606: "Large fossilised pelvis",
    21607: "null",
    21608: "Large fossilised skull",
    21609: "null",
    21610: "Rare fossilised limbs",
    21611: "null",
    21612: "Rare fossilised spine",
    21613: "null",
    21614: "Rare fossilised ribs",
    21615: "null",
    21616: "Rare fossilised pelvis",
    21617: "null",
    21618: "Rare fossilised skull",
    21619: "null",
    21620: "Rare fossilised tusk",
    21621: "null",
    21622: "Volcanic ash",
    21623: "null",
    21624: "Hoop snake",
    21625: "null",
    21626: "Sulliuscep cap",
    21627: "null",
    21628: "null",
    21629: "Archaeologist's diary",
    21630: "null",
    21631: "Ancient diary",
    21632: "null",
    21633: "Ancient wyvern shield",
    21634: "Ancient wyvern shield",
    21635: "null",
    21636: "null",
    21637: "Wyvern visage",
    21638: "null",
    21639: "null",
    21640: "Antique lamp",
    21641: "Antique lamp",
    21642: "Antique lamp",
    21643: "Granite boots",
    21644: "null",
    21645: "null",
    21646: "Granite longsword",
    21647: "null",
    21648: "null",
    21649: "Merfolk trident",
    21650: "null",
    21651: "null",
    21652: "Drift net",
    21653: "null",
    21654: "null",
    21655: "Pufferfish",
    21656: "Mermaid's tear",
    21657: "null",
    21658: "null",
    21659: "null",
    21660: "null",
    21661: "null",
    21662: "Fossil island note book",
    21663: "null",
    21664: "Scribbled note",
    21665: "null",
    21666: "Partial note",
    21667: "null",
    21668: "Ancient note",
    21669: "null",
    21670: "Ancient writings",
    21671: "null",
    21672: "Experimental note",
    21673: "null",
    21674: "Paragraph of text",
    21675: "null",
    21676: "Musty smelling note",
    21677: "null",
    21678: "Hastily scrawled note",
    21679: "null",
    21680: "Old writing",
    21681: "null",
    21682: "Short note",
    21683: "null",
    21684: "Uncooked mushroom pie",
    21685: "null",
    21686: "null",
    21687: "Half a mushroom pie",
    21688: "null",
    21689: "null",
    21690: "Mushroom pie",
    21691: "null",
    21692: "null",
    21693: "Bowl of fish",
    21694: "null",
    21695: "Runefest shield",
    21696: "null",
    21697: "Ash covered tome",
    21698: "Tzhaar air rune pack",
    21699: "null",
    21700: "null",
    21701: "Tzhaar water rune pack",
    21702: "null",
    21703: "null",
    21704: "Tzhaar earth rune pack",
    21705: "null",
    21706: "null",
    21707: "Tzhaar fire rune pack",
    21708: "null",
    21709: "null",
    21710: "Death note",
    21711: "Murky potion",
    21712: "Spectral potion",
    21713: "Tomberries",
    21714: "Tattered book",
    21715: "Note",
    21716: "Carved gem",
    21717: "Time bubble",
    21718: "Traiborn note",
    21719: "Jonas mask",
    21720: "Jonas mask",
    21721: "null",
    21722: "Diving helmet",
    21723: "Diving apparatus",
    21724: "Brittle key",
    21725: "null",
    21726: "Granite dust",
    21727: "null",
    21728: "Granite cannonball",
    21729: "null",
    21730: "Black tourmaline core",
    21731: "null",
    21732: "null",
    21733: "Guardian boots",
    21734: "null",
    21735: "null",
    21736: "Granite gloves",
    21737: "null",
    21738: "null",
    21739: "Granite ring",
    21740: "null",
    21741: "null",
    21742: "Granite hammer",
    21743: "null",
    21744: "null",
    21745: "Jar of stone",
    21746: "null",
    21747: "null",
    21748: "Noon",
    21749: "null",
    21750: "Midnight",
    21751: "null",
    21752: "Granite ring (i)",
    21753: "null",
    21754: "Rock thrownhammer",
    21755: "null",
    21756: "Varlamore envoy",
    21757: "null",
    21758: "Royal accord of twill",
    21759: "Certificate",
    21760: "Kharedst's memoirs",
    21761: "null",
    21762: "Lunch by the lancalliums",
    21763: "null",
    21764: "The fisher's flute",
    21765: "null",
    21766: "History and hearsay",
    21767: "null",
    21768: "Jewellery of jubilation",
    21769: "null",
    21770: "A dark disposition",
    21771: "null",
    21772: "Secret page",
    21773: "null",
    21774: "Letter",
    21775: "Certificate",
    21776: "Imbued saradomin max cape",
    21777: "null",
    21778: "Imbued saradomin max hood",
    21779: "null",
    21780: "Imbued zamorak max cape",
    21781: "null",
    21782: "Imbued zamorak max hood",
    21783: "null",
    21784: "Imbued guthix max cape",
    21785: "null",
    21786: "Imbued guthix max hood",
    21787: "null",
    21788: "Obelisk",
    21789: "null",
    21790: "null",
    21791: "Imbued saradomin cape",
    21792: "null",
    21793: "Imbued guthix cape",
    21794: "null",
    21795: "Imbued zamorak cape",
    21796: "null",
    21797: "Justiciar's hand",
    21798: "Ent's roots",
    21799: "Demon's heart",
    21800: "Enchanted symbol",
    21801: "null",
    21802: "Revenant cave teleport",
    21803: "null",
    21804: "Ancient crystal",
    21805: "null",
    21806: "null",
    21807: "Ancient emblem",
    21808: "null",
    21809: "null",
    21810: "Ancient totem",
    21811: "null",
    21812: "null",
    21813: "Ancient statuette",
    21814: "null",
    21815: "null",
    21816: "Bracelet of ethereum",
    21817: "Bracelet of ethereum (uncharged)",
    21818: "null",
    21819: "null",
    21820: "Revenant ether",
    21821: "null",
    21822: "null",
    21823: "null",
    21824: "null",
    21825: "null",
    21826: "Null",
    21827: "null",
    21828: "null",
    21829: "Null",
    21830: "null",
    21831: "null",
    21832: "Null",
    21833: "null",
    21834: "null",
    21835: "null",
    21836: "null",
    21837: "Ogre artefact",
    21838: "Shaman mask",
    21839: "null",
    21840: "null",
    21841: "Snow imp costume head",
    21842: "Snow imp costume body",
    21843: "Snow imp costume legs",
    21844: "Snow imp costume tail",
    21845: "Snow imp costume gloves",
    21846: "Snow imp costume feet",
    21847: "Snow imp costume head",
    21848: "null",
    21849: "Snow imp costume body",
    21850: "null",
    21851: "Snow imp costume legs",
    21852: "null",
    21853: "Snow imp costume tail",
    21854: "null",
    21855: "Snow imp costume gloves",
    21856: "null",
    21857: "Snow imp costume feet",
    21858: "null",
    21859: "Wise old man's santa hat",
    21860: "null",
    21861: "Enchanted curtains",
    21862: "Enchanted snowy curtains",
    21863: "Wise old man's teleport tablet",
    21864: "Snow sprite",
    21865: "Fine mesh net",
    21866: "Santa suit",
    21867: "Santa suit (wet)",
    21868: "Santa suit (dry)",
    21869: "Logs and kindling",
    21870: "Promissory note",
    21871: "Santa's seal",
    21872: "Vault key",
    21873: "Empty sack",
    21874: "Bulging sack",
    21875: "Kristmas kebab",
    21876: "null",
    21877: "null",
    21878: "null",
    21879: "null",
    21880: "Wrath rune",
    21881: "null",
    21882: "Dragon armour set (lg)",
    21883: "null",
    21884: "null",
    21885: "Dragon armour set (sk)",
    21886: "null",
    21887: "null",
    21888: "Turquoise slayer helmet",
    21889: "null",
    21890: "Turquoise slayer helmet (i)",
    21891: "null",
    21892: "Dragon platebody",
    21893: "null",
    21894: "null",
    21895: "Dragon kiteshield",
    21896: "null",
    21897: "null",
    21898: "Assembler max cape",
    21899: "null",
    21900: "Assembler max hood",
    21901: "null",
    21902: "Dragon crossbow",
    21903: "null",
    21904: "null",
    21905: "Dragon bolts",
    21906: "null",
    21907: "Vorkath's head",
    21908: "null",
    21909: "Vorkath's stuffed head",
    21910: "null",
    21911: "Rune dragon",
    21912: "Vorkath's head",
    21913: "Mythical cape",
    21914: "Ava's assembler (broken)",
    21915: "null",
    21916: "Assembler max cape (broken)",
    21917: "null",
    21918: "Dragon limbs",
    21919: "null",
    21920: "null",
    21921: "Dragon crossbow (u)",
    21922: "null",
    21923: "null",
    21924: "Dragon bolts (p)",
    21925: "null",
    21926: "Dragon bolts (p+)",
    21927: "null",
    21928: "Dragon bolts (p++)",
    21929: "null",
    21930: "Dragon bolts (unf)",
    21931: "null",
    21932: "Opal dragon bolts (e)",
    21933: "null",
    21934: "Jade dragon bolts (e)",
    21935: "null",
    21936: "Pearl dragon bolts (e)",
    21937: "null",
    21938: "Topaz dragon bolts (e)",
    21939: "null",
    21940: "Sapphire dragon bolts (e)",
    21941: "null",
    21942: "Emerald dragon bolts (e)",
    21943: "null",
    21944: "Ruby dragon bolts (e)",
    21945: "null",
    21946: "Diamond dragon bolts (e)",
    21947: "null",
    21948: "Dragonstone dragon bolts (e)",
    21949: "null",
    21950: "Onyx dragon bolts (e)",
    21951: "null",
    21952: "Magic stock",
    21953: "null",
    21954: "null",
    21955: "Opal dragon bolts",
    21956: "null",
    21957: "Jade dragon bolts",
    21958: "null",
    21959: "Pearl dragon bolts",
    21960: "null",
    21961: "Topaz dragon bolts",
    21962: "null",
    21963: "Sapphire dragon bolts",
    21964: "null",
    21965: "Emerald dragon bolts",
    21966: "null",
    21967: "Ruby dragon bolts",
    21968: "null",
    21969: "Diamond dragon bolts",
    21970: "null",
    21971: "Dragonstone dragon bolts",
    21972: "null",
    21973: "Onyx dragon bolts",
    21974: "null",
    21975: "Crushed superior dragon bones",
    21976: "null",
    21977: "null",
    21978: "Super antifire potion(4)",
    21979: "null",
    21980: "null",
    21981: "Super antifire potion(3)",
    21982: "null",
    21983: "null",
    21984: "Super antifire potion(2)",
    21985: "null",
    21986: "null",
    21987: "Super antifire potion(1)",
    21988: "null",
    21989: "null",
    21990: "Rift guardian",
    21991: "null",
    21992: "Vorki",
    21993: "null",
    21994: "Super antifire mix(2)",
    21995: "null",
    21996: "null",
    21997: "Super antifire mix(1)",
    21998: "null",
    21999: "null",
    22000: "Clue scroll (elite)",
    22001: "Clue scroll (easy)",
    22002: "Dragonfire ward",
    22003: "Dragonfire ward",
    22004: "null",
    22005: "null",
    22006: "Skeletal visage",
    22007: "null",
    22008: "null",
    22009: "Map piece",
    22010: "Map piece",
    22011: "Map piece",
    22012: "Map piece",
    22013: "Map piece",
    22014: "Map piece",
    22015: "Map piece",
    22016: "Map piece",
    22017: "Map piece",
    22018: "Map piece",
    22019: "Map piece",
    22020: "Map piece",
    22021: "Map piece",
    22022: "Map piece",
    22023: "Map piece",
    22024: "Map piece",
    22025: "Map piece",
    22026: "Map piece",
    22027: "Map piece",
    22028: "Map piece",
    22029: "Map piece",
    22030: "Map piece",
    22031: "Map piece",
    22032: "Map piece",
    22033: "Aivas' diary",
    22034: "null",
    22035: "Varrock census records",
    22036: "null",
    22037: "Malumac's journal",
    22038: "null",
    22039: "Ablenkian's escape",
    22040: "null",
    22041: "Imcandoria's fall",
    22042: "null",
    22043: "Imafore's betrayal",
    22044: "null",
    22045: "Lutwidge and the moonfly",
    22046: "null",
    22047: "Serafina",
    22048: "null",
    22049: "The weeping",
    22050: "null",
    22051: "Old notes",
    22052: "null",
    22053: "Old notes",
    22054: "null",
    22055: "Old notes",
    22056: "null",
    22057: "Old notes",
    22058: "null",
    22059: "Old notes",
    22060: "null",
    22061: "Old notes",
    22062: "null",
    22063: "Old notes",
    22064: "null",
    22065: "Old notes",
    22066: "null",
    22067: "Old notes",
    22068: "null",
    22069: "Old notes",
    22070: "null",
    22071: "Old notes",
    22072: "null",
    22073: "Old notes",
    22074: "null",
    22075: "Old notes",
    22076: "null",
    22077: "Old notes",
    22078: "null",
    22079: "Inert locator orb",
    22080: "null",
    22081: "Locator orb",
    22082: "null",
    22083: "Robert bust",
    22084: "Camorra bust",
    22085: "Tristan bust",
    22086: "Aivas bust",
    22087: "Dragon key",
    22088: "Dragon key piece",
    22089: "Dragon key piece",
    22090: "Dragon key piece",
    22091: "Dragon key piece",
    22092: "Dragon key",
    22093: "Ancient key",
    22094: "Water container",
    22095: "Swamp paste",
    22096: "Revitalisation potion",
    22097: "Dragon metal shard",
    22098: "null",
    22099: "null",
    22100: "Dragon metal slice",
    22101: "null",
    22102: "null",
    22103: "Dragon metal lump",
    22104: "null",
    22105: "null",
    22106: "Jar of decay",
    22107: "null",
    22108: "null",
    22109: "Ava's assembler",
    22110: "null",
    22111: "Dragonbone necklace",
    22112: "null",
    22113: "null",
    22114: "Mythical cape",
    22115: "null",
    22116: "Superior dragon bonemeal",
    22117: "null",
    22118: "Wrath talisman",
    22119: "null",
    22120: "null",
    22121: "Wrath tiara",
    22122: "null",
    22123: "null",
    22124: "Superior dragon bones",
    22125: "null",
    22126: "null",
    22127: "Adamant kiteshield",
    22128: "null",
    22129: "Adamant kiteshield",
    22130: "null",
    22131: "Adamant kiteshield",
    22132: "null",
    22133: "Adamant kiteshield",
    22134: "null",
    22135: "Adamant kiteshield",
    22136: "null",
    22137: "Adamant kiteshield",
    22138: "null",
    22139: "Adamant kiteshield",
    22140: "null",
    22141: "Adamant kiteshield",
    22142: "null",
    22143: "Adamant kiteshield",
    22144: "null",
    22145: "Adamant kiteshield",
    22146: "null",
    22147: "Adamant kiteshield",
    22148: "null",
    22149: "Adamant kiteshield",
    22150: "null",
    22151: "Adamant kiteshield",
    22152: "null",
    22153: "Adamant kiteshield",
    22154: "null",
    22155: "Adamant kiteshield",
    22156: "null",
    22157: "Adamant kiteshield",
    22158: "null",
    22159: "Adamant heraldic helm",
    22160: "null",
    22161: "Adamant heraldic helm",
    22162: "null",
    22163: "Adamant heraldic helm",
    22164: "null",
    22165: "Adamant heraldic helm",
    22166: "null",
    22167: "Adamant heraldic helm",
    22168: "null",
    22169: "Adamant heraldic helm",
    22170: "null",
    22171: "Adamant heraldic helm",
    22172: "null",
    22173: "Adamant heraldic helm",
    22174: "null",
    22175: "Adamant heraldic helm",
    22176: "null",
    22177: "Adamant heraldic helm",
    22178: "null",
    22179: "Adamant heraldic helm",
    22180: "null",
    22181: "Adamant heraldic helm",
    22182: "null",
    22183: "Adamant heraldic helm",
    22184: "null",
    22185: "Adamant heraldic helm",
    22186: "null",
    22187: "Adamant heraldic helm",
    22188: "null",
    22189: "Adamant heraldic helm",
    22190: "null",
    22191: "Useful rock",
    22192: "Maple bird house",
    22193: "null",
    22194: "null",
    22195: "Mahogany bird house",
    22196: "null",
    22197: "null",
    22198: "Yew bird house",
    22199: "null",
    22200: "null",
    22201: "Magic bird house",
    22202: "null",
    22203: "null",
    22204: "Redwood bird house",
    22205: "null",
    22206: "null",
    22207: "Glistening tear",
    22208: "Wrath rune",
    22209: "Extended super antifire(4)",
    22210: "null",
    22211: "null",
    22212: "Extended super antifire(3)",
    22213: "null",
    22214: "null",
    22215: "Extended super antifire(2)",
    22216: "null",
    22217: "null",
    22218: "Extended super antifire(1)",
    22219: "null",
    22220: "null",
    22221: "Extended super antifire mix(2)",
    22222: "null",
    22223: "null",
    22224: "Extended super antifire mix(1)",
    22225: "null",
    22226: "null",
    22227: "Bullet arrow",
    22228: "Field arrow",
    22229: "Blunt arrow",
    22230: "Barbed arrow",
    22231: "Dragon boots ornament kit",
    22232: "null",
    22233: "null",
    22234: "Dragon boots (g)",
    22235: "null",
    22236: "Dragon platebody ornament kit",
    22237: "null",
    22238: "null",
    22239: "Dragon kiteshield ornament kit",
    22240: "null",
    22241: "null",
    22242: "Dragon platebody (g)",
    22243: "null",
    22244: "Dragon kiteshield (g)",
    22245: "null",
    22246: "Anguish ornament kit",
    22247: "null",
    22248: "null",
    22249: "Necklace of anguish (or)",
    22250: "null",
    22251: "Oak shield",
    22252: "null",
    22253: "null",
    22254: "Willow shield",
    22255: "null",
    22256: "null",
    22257: "Maple shield",
    22258: "null",
    22259: "null",
    22260: "Yew shield",
    22261: "null",
    22262: "null",
    22263: "Magic shield",
    22264: "null",
    22265: "null",
    22266: "Redwood shield",
    22267: "null",
    22268: "null",
    22269: "Hard leather shield",
    22270: "null",
    22271: "null",
    22272: "Snakeskin shield",
    22273: "null",
    22274: "null",
    22275: "Green d'hide shield",
    22276: "null",
    22277: "null",
    22278: "Blue d'hide shield",
    22279: "null",
    22280: "null",
    22281: "Red d'hide shield",
    22282: "null",
    22283: "null",
    22284: "Black d'hide shield",
    22285: "null",
    22286: "null",
    22287: "Leather shields flyer",
    22288: "Trident of the seas (e)",
    22289: "null",
    22290: "Uncharged trident (e)",
    22291: "null",
    22292: "Trident of the swamp (e)",
    22293: "null",
    22294: "Uncharged toxic trident (e)",
    22295: "null",
    22296: "Staff of light",
    22297: "null",
    22298: "null",
    22299: "Ancient medallion",
    22300: "null",
    22301: "null",
    22302: "Ancient effigy",
    22303: "null",
    22304: "null",
    22305: "Ancient relic",
    22306: "null",
    22307: "null",
    22308: "Healer icon",
    22309: "Healer icon",
    22310: "Healer icon",
    22311: "Healer icon",
    22312: "Collector icon",
    22313: "Collector icon",
    22314: "Collector icon",
    22315: "Collector icon",
    22316: "Prop sword",
    22317: "null",
    22318: "Pet corporeal critter",
    22319: "Tzrek-zuk",
    22320: "Champion's lamp",
    22321: "Rotten cabbage",
    22322: "Avernic defender",
    22323: "Sanguinesti staff",
    22324: "Ghrazi rapier",
    22325: "Scythe of vitur",
    22326: "Justiciar faceguard",
    22327: "Justiciar chestguard",
    22328: "Justiciar legguards",
    22329: "null",
    22330: "Deadman starter pack",
    22331: "Starter sword",
    22332: "null",
    22333: "Starter bow",
    22334: "null",
    22335: "Starter staff",
    22336: "null",
    22337: "Collector icon",
    22338: "Collector icon",
    22339: "Collector icon",
    22340: "Defender icon",
    22341: "Defender icon",
    22342: "Defender icon",
    22343: "Defender icon",
    22344: "Defender icon",
    22345: "Defender icon",
    22346: "Attacker icon",
    22347: "Attacker icon",
    22348: "Attacker icon",
    22349: "Attacker icon",
    22350: "Null",
    22351: "Eggshell platebody",
    22352: "null",
    22353: "Eggshell platelegs",
    22354: "null",
    22355: "Holy handegg",
    22356: "null",
    22357: "null",
    22358: "Peaceful handegg",
    22359: "null",
    22360: "null",
    22361: "Chaotic handegg",
    22362: "null",
    22363: "null",
    22364: "Oculus orb",
    22365: "Certificate",
    22366: "null",
    22367: "Certificate",
    22368: "Bryophyta's staff (uncharged)",
    22369: "null",
    22370: "Bryophyta's staff",
    22371: "null",
    22372: "Bryophyta's essence",
    22373: "null",
    22374: "Mossy key",
    22375: "null",
    22376: "Puppadile",
    22377: "null",
    22378: "Tektiny",
    22379: "null",
    22380: "Vanguard",
    22381: "null",
    22382: "Vasa minirio",
    22383: "null",
    22384: "Vespina",
    22385: "null",
    22386: "Metamorphic dust",
    22387: "null",
    22388: "Xeric's guard",
    22389: "null",
    22390: "Xeric's warrior",
    22391: "null",
    22392: "Xeric's sentinel",
    22393: "null",
    22394: "Xeric's general",
    22395: "null",
    22396: "Xeric's champion",
    22397: "null",
    22398: "Ivandis flail",
    22399: "null",
    22400: "Drakan's medallion",
    22401: "null",
    22402: "Mysterious herb",
    22403: "Mysterious meat",
    22404: "Mysterious crushed meat",
    22405: "Vial of blood",
    22406: "Unfinished blood potion",
    22407: "Blood potion",
    22408: "Unfinished potion",
    22409: "Potion",
    22410: "Old notes",
    22411: "Old diary",
    22412: "null",
    22413: "Flaygian's notes",
    22414: "Chain",
    22415: "Tome of experience",
    22416: "The turncloak",
    22417: "null",
    22418: "Explosive discovery",
    22419: "null",
    22420: "Bloody grimoire",
    22421: "null",
    22422: "Elixir of everlasting",
    22423: "null",
    22424: "Buried alive",
    22425: "null",
    22426: "Deed",
    22427: "null",
    22428: "Old key",
    22429: "null",
    22430: "Bloody bracer",
    22431: "null",
    22432: "null",
    22433: "Emerald sickle (b)",
    22434: "null",
    22435: "Enchanted emerald sickle (b)",
    22436: "null",
    22437: "Rotten carrot",
    22438: "Justiciar armour set",
    22439: "null",
    22440: "null",
    22441: "Avernic defender (broken)",
    22442: "null",
    22443: "Cadantine blood potion (unf)",
    22444: "null",
    22445: "null",
    22446: "Vial of blood",
    22447: "null",
    22448: "null",
    22449: "Battlemage potion(4)",
    22450: "null",
    22451: "null",
    22452: "Battlemage potion(3)",
    22453: "null",
    22454: "null",
    22455: "Battlemage potion(2)",
    22456: "null",
    22457: "null",
    22458: "Battlemage potion(1)",
    22459: "null",
    22460: "null",
    22461: "Bastion potion(4)",
    22462: "null",
    22463: "null",
    22464: "Bastion potion(3)",
    22465: "null",
    22466: "null",
    22467: "Bastion potion(2)",
    22468: "null",
    22469: "null",
    22470: "Bastion potion(1)",
    22471: "null",
    22472: "null",
    22473: "Lil' zik",
    22474: "null",
    22475: "Message",
    22476: "null",
    22477: "Avernic defender hilt",
    22478: "null",
    22479: "null",
    22480: "null",
    22481: "Sanguinesti staff (uncharged)",
    22482: "null",
    22483: "null",
    22484: "null",
    22485: "null",
    22486: "Scythe of vitur (uncharged)",
    22487: "null",
    22488: "null",
    22489: "null",
    22490: "null",
    22491: "null",
    22492: "null",
    22493: "null",
    22494: "Sinhaza shroud tier 1",
    22495: "null",
    22496: "Sinhaza shroud tier 2",
    22497: "null",
    22498: "Sinhaza shroud tier 3",
    22499: "null",
    22500: "Sinhaza shroud tier 4",
    22501: "null",
    22502: "Sinhaza shroud tier 5",
    22503: "null",
    22504: "Serafina's diary",
    22505: "null",
    22506: "The butcher",
    22507: "null",
    22508: "Arachnids of vampyrium",
    22509: "null",
    22510: "The shadow realm",
    22511: "null",
    22512: "The wild hunt",
    22513: "null",
    22514: "Verzik vitur - patient record",
    22515: "null",
    22516: "Dawnbringer",
    22517: "Escape crystal",
    22518: "null",
    22519: "Cabbage",
    22520: "Cabbage",
    22521: "Coin pouch",
    22522: "Coin pouch",
    22523: "Coin pouch",
    22524: "Coin pouch",
    22525: "Coin pouch",
    22526: "Coin pouch",
    22527: "Coin pouch",
    22528: "Coin pouch",
    22529: "Coin pouch",
    22530: "Coin pouch",
    22531: "Coin pouch",
    22532: "Coin pouch",
    22533: "Coin pouch",
    22534: "Coin pouch",
    22535: "Coin pouch",
    22536: "Coin pouch",
    22537: "Coin pouch",
    22538: "Coin pouch",
    22539: "null",
    22540: "null",
    22541: "Rotten strawberry",
    22542: "Viggora's chainmace (u)",
    22543: "null",
    22544: "null",
    22545: "Viggora's chainmace",
    22546: "null",
    22547: "Craw's bow (u)",
    22548: "null",
    22549: "null",
    22550: "Craw's bow",
    22551: "null",
    22552: "Thammaron's sceptre (u)",
    22553: "null",
    22554: "null",
    22555: "Thammaron's sceptre",
    22556: "null",
    22557: "Amulet of avarice",
    22558: "null",
    22559: "null",
    22560: "null",
    22561: "null",
    22562: "null",
    22563: "null",
    22564: "null",
    22565: "null",
    22566: "null",
    22567: "null",
    22568: "null",
    22569: "null",
    22570: "null",
    22571: "null",
    22572: "null",
    22573: "null",
    22574: "null",
    22575: "null",
    22576: "null",
    22577: "null",
    22578: "null",
    22579: "null",
    22580: "null",
    22581: "null",
    22582: "null",
    22583: "null",
    22584: "null",
    22585: "null",
    22586: "Looting bag",
    22587: "null",
    22588: "Old man's coffin",
    22589: "Reduced cadava potion",
    22590: "Goat dung",
    22591: "Weiss fire notes",
    22592: "null",
    22593: "Te salt",
    22594: "null",
    22595: "Efh salt",
    22596: "null",
    22597: "Urt salt",
    22598: "null",
    22599: "Icy basalt",
    22600: "null",
    22601: "Stony basalt",
    22602: "null",
    22603: "Basalt",
    22604: "null",
    22605: "null",
    22606: "Fire of eternal light",
    22607: "Fire of dehumidification",
    22608: "Fire of nourishment",
    22609: "Fire of unseasonal warmth",
    22610: "Vesta's spear",
    22611: "null",
    22612: "null",
    22613: "Vesta's longsword",
    22614: "null",
    22615: "null",
    22616: "Vesta's chainbody",
    22617: "null",
    22618: "null",
    22619: "Vesta's plateskirt",
    22620: "null",
    22621: "null",
    22622: "Statius's warhammer",
    22623: "null",
    22624: "null",
    22625: "Statius's full helm",
    22626: "null",
    22627: "null",
    22628: "Statius's platebody",
    22629: "null",
    22630: "null",
    22631: "Statius's platelegs",
    22632: "null",
    22633: "null",
    22634: "Morrigan's throwing axe",
    22635: "null",
    22636: "Morrigan's javelin",
    22637: "null",
    22638: "Morrigan's coif",
    22639: "null",
    22640: "null",
    22641: "Morrigan's leather body",
    22642: "null",
    22643: "null",
    22644: "Morrigan's leather chaps",
    22645: "null",
    22646: "null",
    22647: "Zuriel's staff",
    22648: "null",
    22649: "null",
    22650: "Zuriel's hood",
    22651: "null",
    22652: "null",
    22653: "Zuriel's robe top",
    22654: "null",
    22655: "null",
    22656: "Zuriel's robe bottom",
    22657: "null",
    22658: "null",
    22659: "null",
    22660: "Empty bucket pack",
    22661: "null",
    22662: "null",
    22663: "Pet smoke devil",
    22664: "Scythe of vitur",
    22665: "Armadyl godsword",
    22666: "Rubber chicken",
    22667: "null",
    22668: "null",
    22669: "null",
    22670: "null",
    22671: "Kq head (tattered)",
    22672: "null",
    22673: "Stuffed kq head (tattered)",
    22674: "null",
    22675: "Scroll sack",
    22676: "null",
    22677: "Null",
    22678: "Null",
    22679: "Null",
    22680: "Null",
    22681: "Null",
    22682: "Null",
    22683: "Null",
    22684: "Eek",
    22685: "null",
    22686: "Null",
    22687: "Null",
    22688: "Null",
    22689: "Clown mask",
    22690: "null",
    22691: "Null",
    22692: "Clown bow tie",
    22693: "null",
    22694: "Null",
    22695: "Clown gown",
    22696: "null",
    22697: "Null",
    22698: "Clown trousers",
    22699: "null",
    22700: "Null",
    22701: "Clown shoes",
    22702: "null",
    22703: "null",
    22704: "Portal nexus",
    22705: "Marble portal nexus",
    22706: "Gilded portal nexus",
    22707: "Crystalline portal nexus",
    22708: "Mounted xeric's talisman",
    22709: "Mounted digsite pendant",
    22710: "Curator's medallion",
    22711: "Collection log",
    22712: "null",
    22713: "Star-face",
    22714: "null",
    22715: "Tree top",
    22716: "null",
    22717: "Tree skirt",
    22718: "null",
    22719: "Candy cane",
    22720: "null",
    22721: "Attacker icon",
    22722: "Attacker icon",
    22723: "Attacker icon",
    22724: "Collector icon",
    22725: "Defender icon",
    22726: "Defender icon",
    22727: "Defender icon",
    22728: "Defender icon",
    22729: "Attacker icon",
    22730: "Attacker icon",
    22731: "Dragon hasta",
    22732: "null",
    22733: "null",
    22734: "Dragon hasta(p)",
    22735: "null",
    22736: "null",
    22737: "Dragon hasta(p+)",
    22738: "null",
    22739: "null",
    22740: "Dragon hasta(p++)",
    22741: "null",
    22742: "null",
    22743: "Dragon hasta(kp)",
    22744: "Fake dragon hasta(kp)",
    22745: "null",
    22746: "Ikkle hydra",
    22747: "null",
    22748: "Ikkle hydra",
    22749: "null",
    22750: "Ikkle hydra",
    22751: "null",
    22752: "Ikkle hydra",
    22753: "null",
    22754: "Wyrm bonemeal",
    22755: "null",
    22756: "Drake bonemeal",
    22757: "null",
    22758: "Hydra bonemeal",
    22759: "null",
    22760: "Certificate",
    22761: "Dinh's hammer",
    22762: "Generator crank",
    22763: "8-gallon jug",
    22764: "5-gallon jug",
    22765: "Energy disk (level 4)",
    22766: "Energy disk (level 3)",
    22767: "Energy disk (level 2)",
    22768: "Energy disk (level 1)",
    22769: "Unknown fluid 1",
    22770: "Unknown fluid 2",
    22771: "Unknown fluid 3",
    22772: "Unknown fluid 4",
    22773: "Unknown fluid 5",
    22774: "Old notes",
    22775: "Ancient letter",
    22776: "null",
    22777: "Certificate",
    22778: "null",
    22779: "Null",
    22780: "Wyrm bones",
    22781: "null",
    22782: "null",
    22783: "Drake bones",
    22784: "null",
    22785: "null",
    22786: "Hydra bones",
    22787: "null",
    22788: "null",
    22789: "Uncooked dragonfruit pie",
    22790: "null",
    22791: "null",
    22792: "Half a dragonfruit pie",
    22793: "null",
    22794: "null",
    22795: "Dragonfruit pie",
    22796: "null",
    22797: "null",
    22798: "Bird nest",
    22799: "null",
    22800: "Bird nest",
    22801: "null",
    22802: "null",
    22803: "Rada's blessing",
    22804: "Dragon knife",
    22805: "null",
    22806: "Dragon knife(p)",
    22807: "null",
    22808: "Dragon knife(p+)",
    22809: "null",
    22810: "Dragon knife(p++)",
    22811: "null",
    22812: "Dragon knife",
    22813: "null",
    22814: "Dragon knife",
    22815: "null",
    22816: "Cormorant's glove",
    22817: "Cormorant's glove",
    22818: "Fish chunks",
    22819: "null",
    22820: "Molch pearl",
    22821: "null",
    22822: "null",
    22823: "null",
    22824: "null",
    22825: "null",
    22826: "Bluegill",
    22827: "null",
    22828: "null",
    22829: "Common tench",
    22830: "null",
    22831: "null",
    22832: "Mottled eel",
    22833: "null",
    22834: "null",
    22835: "Greater siren",
    22836: "null",
    22837: "null",
    22838: "Fish sack",
    22839: "null",
    22840: "Golden tench",
    22841: "null",
    22842: "Pearl barbarian rod",
    22843: "null",
    22844: "Pearl fly fishing rod",
    22845: "null",
    22846: "Pearl fishing rod",
    22847: "null",
    22848: "Celastrus seedling",
    22849: "null",
    22850: "Redwood seedling",
    22851: "null",
    22852: "Celastrus seedling (w)",
    22853: "null",
    22854: "Redwood seedling (w)",
    22855: "null",
    22856: "Celastrus sapling",
    22857: "null",
    22858: "null",
    22859: "Redwood sapling",
    22860: "null",
    22861: "null",
    22862: "Dragonfruit seedling",
    22863: "null",
    22864: "Dragonfruit seedling (w)",
    22865: "null",
    22866: "Dragonfruit sapling",
    22867: "null",
    22868: "null",
    22869: "Celastrus seed",
    22870: "null",
    22871: "Redwood tree seed",
    22872: "null",
    22873: "Potato cactus seed",
    22874: "null",
    22875: "Hespori seed",
    22876: "null",
    22877: "Dragonfruit tree seed",
    22878: "null",
    22879: "Snape grass seed",
    22880: "null",
    22881: "Attas seed",
    22882: "null",
    22883: "Iasor seed",
    22884: "null",
    22885: "Kronos seed",
    22886: "null",
    22887: "White lily seed",
    22888: "null",
    22889: "null",
    22890: "null",
    22891: "null",
    22892: "null",
    22893: "null",
    22894: "null",
    22895: "null",
    22896: "null",
    22897: "null",
    22898: "null",
    22899: "null",
    22900: "null",
    22901: "null",
    22902: "null",
    22903: "null",
    22904: "null",
    22905: "null",
    22906: "null",
    22907: "null",
    22908: "null",
    22909: "null",
    22910: "null",
    22911: "null",
    22912: "null",
    22913: "null",
    22914: "null",
    22915: "null",
    22916: "null",
    22917: "null",
    22918: "null",
    22919: "null",
    22920: "null",
    22921: "null",
    22922: "null",
    22923: "null",
    22924: "null",
    22925: "null",
    22926: "null",
    22927: "null",
    22928: "null",
    22929: "Dragonfruit",
    22930: "null",
    22931: "null",
    22932: "White lily",
    22933: "null",
    22934: "null",
    22935: "Celastrus bark",
    22936: "null",
    22937: "null",
    22938: "null",
    22939: "null",
    22940: "null",
    22941: "Rada's blessing 1",
    22942: "null",
    22943: "Rada's blessing 2",
    22944: "null",
    22945: "Rada's blessing 3",
    22946: "null",
    22947: "Rada's blessing 4",
    22948: "null",
    22949: "Battlefront teleport",
    22950: "null",
    22951: "Boots of brimstone",
    22952: "null",
    22953: "null",
    22954: "Devout boots",
    22955: "null",
    22956: "null",
    22957: "Drake's claw",
    22958: "null",
    22959: "null",
    22960: "Drake's tooth",
    22961: "null",
    22962: "null",
    22963: "Broken dragon hasta",
    22964: "null",
    22965: "null",
    22966: "Hydra's claw",
    22967: "null",
    22968: "null",
    22969: "Hydra's heart",
    22970: "null",
    22971: "Hydra's fang",
    22972: "null",
    22973: "Hydra's eye",
    22974: "null",
    22975: "Brimstone ring",
    22976: "null",
    22977: "null",
    22978: "Dragon hunter lance",
    22979: "null",
    22980: "null",
    22981: "Ferocious gloves",
    22982: "null",
    22983: "Hydra leather",
    22984: "null",
    22985: "null",
    22986: "Bonecrusher necklace",
    22987: "null",
    22988: "Hydra tail",
    22989: "null",
    22990: "null",
    22991: "Stone tablet",
    22992: "null",
    22993: "Seed pack",
    22994: "Bottomless compost bucket",
    22995: "null",
    22996: "null",
    22997: "Bottomless compost bucket",
    22998: "null",
    22999: "Bottled dragonbreath (unpowered)",
    23000: "null",
    23001: "null",
    23002: "Bottled dragonbreath",
    23003: "null",
    23004: "null",
    23005: "null",
    23006: "null",
    23007: "Tatty note",
    23008: "null",
    23009: "Gielinor's flora - flowers",
    23010: "null",
    23011: "Gielinor's flora - bushes",
    23012: "null",
    23013: "Gielinor's flora - hops",
    23014: "null",
    23015: "Gielinor's flora - allotments",
    23016: "null",
    23017: "Gielinor's flora - herbs",
    23018: "null",
    23019: "Gielinor's flora - trees",
    23020: "null",
    23021: "Gielinor's flora - fruit",
    23022: "null",
    23023: "Old notes",
    23024: "null",
    23025: "Old notes",
    23026: "null",
    23027: "Old notes",
    23028: "null",
    23029: "Old notes",
    23030: "null",
    23031: "Old notes",
    23032: "null",
    23033: "Old notes",
    23034: "null",
    23035: "Old notes",
    23036: "null",
    23037: "Boots of stone",
    23038: "null",
    23039: "null",
    23040: "Wyrm",
    23041: "Drake",
    23042: "Hydra",
    23043: "Sulphur lizard",
    23044: "null",
    23045: "Clue scroll (hard)",
    23046: "Clue scroll (medium)",
    23047: "Mystic hat (dusk)",
    23048: "null",
    23049: "null",
    23050: "Mystic robe top (dusk)",
    23051: "null",
    23052: "null",
    23053: "Mystic robe bottom (dusk)",
    23054: "null",
    23055: "null",
    23056: "Mystic gloves (dusk)",
    23057: "null",
    23058: "null",
    23059: "Mystic boots (dusk)",
    23060: "null",
    23061: "null",
    23062: "Nest box (seeds)",
    23063: "null",
    23064: "Jar of chemicals",
    23065: "null",
    23066: "null",
    23067: "Treasure scroll",
    23068: "Treasure scroll",
    23069: "Mysterious orb",
    23070: "Treasure scroll",
    23071: "Ancient casket",
    23072: "Antique lamp",
    23073: "Hydra slayer helmet",
    23074: "null",
    23075: "Hydra slayer helmet (i)",
    23076: "null",
    23077: "Alchemical hydra heads",
    23078: "null",
    23079: "Stuffed hydra heads",
    23080: "null",
    23081: "Alchemical hydra head",
    23082: "Antique lamp",
    23083: "Brimstone key",
    23084: "null",
    23085: "null",
    23086: "null",
    23087: "null",
    23088: "null",
    23089: "null",
    23090: "null",
    23091: "Ornate gloves",
    23092: "null",
    23093: "Ornate boots",
    23094: "null",
    23095: "Ornate legs",
    23096: "null",
    23097: "Ornate top",
    23098: "null",
    23099: "Ornate cape",
    23100: "null",
    23101: "Ornate helm",
    23102: "null",
    23103: "Null",
    23104: "Null",
    23105: "Null",
    23106: "Null",
    23107: "Null",
    23108: "Birthday cake",
    23109: "null",
    23110: "Mystic set (light)",
    23111: "null",
    23112: "null",
    23113: "Mystic set (blue)",
    23114: "null",
    23115: "null",
    23116: "Mystic set (dark)",
    23117: "null",
    23118: "null",
    23119: "Mystic set (dusk)",
    23120: "null",
    23121: "null",
    23122: "Oily pearl fishing rod",
    23123: "null",
    23124: "Gilded dragonhide set",
    23125: "null",
    23126: "null",
    23127: "Clue nest (beginner)",
    23128: "null",
    23129: "Clue bottle (beginner)",
    23130: "null",
    23131: "Clue scroll (medium)",
    23132: "Challenge scroll (medium)",
    23133: "Clue scroll (medium)",
    23134: "Challenge scroll (medium)",
    23135: "Clue scroll (medium)",
    23136: "Clue scroll (medium)",
    23137: "Clue scroll (medium)",
    23138: "Clue scroll (medium)",
    23139: "Clue scroll (medium)",
    23140: "Clue scroll (medium)",
    23141: "Clue scroll (medium)",
    23142: "Clue scroll (medium)",
    23143: "Clue scroll (medium)",
    23144: "Clue scroll (elite)",
    23145: "Clue scroll (elite)",
    23146: "Clue scroll (elite)",
    23147: "Clue scroll (elite)",
    23148: "Clue scroll (elite)",
    23149: "Clue scroll (easy)",
    23150: "Clue scroll (easy)",
    23151: "Clue scroll (easy)",
    23152: "Clue scroll (easy)",
    23153: "Clue scroll (easy)",
    23154: "Clue scroll (easy)",
    23155: "Clue scroll (easy)",
    23156: "Clue scroll (easy)",
    23157: "Clue scroll (easy)",
    23158: "Clue scroll (easy)",
    23159: "Clue scroll (easy)",
    23160: "Clue scroll (easy)",
    23161: "Clue scroll (easy)",
    23162: "Clue scroll (easy)",
    23163: "Clue scroll (easy)",
    23164: "Clue scroll (easy)",
    23165: "Clue scroll (easy)",
    23166: "Clue scroll (easy)",
    23167: "Clue scroll (hard)",
    23168: "Clue scroll (hard)",
    23169: "Clue scroll (hard)",
    23170: "Clue scroll (hard)",
    23171: "Puzzle box (hard)",
    23172: "Clue scroll (hard)",
    23173: "Puzzle box (hard)",
    23174: "Clue scroll (hard)",
    23175: "Clue scroll (hard)",
    23176: "Clue scroll (hard)",
    23177: "Clue scroll (hard)",
    23178: "Clue scroll (hard)",
    23179: "Clue scroll (hard)",
    23180: "Clue scroll (hard)",
    23181: "Clue scroll (hard)",
    23182: "Clue scroll (beginner)",
    23183: "Strange device",
    23184: "Mimic",
    23185: "Ring of 3rd age",
    23186: "null",
    23187: "null",
    23188: "Guthix d'hide shield",
    23189: "null",
    23190: "null",
    23191: "Saradomin d'hide shield",
    23192: "null",
    23193: "null",
    23194: "Zamorak d'hide shield",
    23195: "null",
    23196: "null",
    23197: "Ancient d'hide shield",
    23198: "null",
    23199: "null",
    23200: "Armadyl d'hide shield",
    23201: "null",
    23202: "null",
    23203: "Bandos d'hide shield",
    23204: "null",
    23205: "null",
    23206: "Dual sai",
    23207: "null",
    23208: "null",
    23209: "Rune platebody (h1)",
    23210: "null",
    23211: "null",
    23212: "Rune platebody (h2)",
    23213: "null",
    23214: "null",
    23215: "Rune platebody (h3)",
    23216: "null",
    23217: "null",
    23218: "Rune platebody (h4)",
    23219: "null",
    23220: "null",
    23221: "Rune platebody (h5)",
    23222: "null",
    23223: "null",
    23224: "Thieving bag",
    23225: "null",
    23226: "null",
    23227: "Rune defender ornament kit",
    23228: "null",
    23229: "null",
    23230: "Rune defender (t)",
    23231: "null",
    23232: "Tzhaar-ket-om ornament kit",
    23233: "null",
    23234: "null",
    23235: "Tzhaar-ket-om (t)",
    23236: "null",
    23237: "Berserker necklace ornament kit",
    23238: "null",
    23239: "null",
    23240: "Berserker necklace (or)",
    23241: "null",
    23242: "3rd age plateskirt",
    23243: "null",
    23244: "null",
    23245: "Reward casket (beginner)",
    23246: "Fremennik kilt",
    23247: "null",
    23248: "null",
    23249: "Rangers' tights",
    23250: "null",
    23251: "null",
    23252: "Giant boot",
    23253: "null",
    23254: "null",
    23255: "Uri's hat",
    23256: "null",
    23257: "null",
    23258: "Gilded coif",
    23259: "null",
    23260: "null",
    23261: "Gilded d'hide vambraces",
    23262: "null",
    23263: "null",
    23264: "Gilded d'hide body",
    23265: "null",
    23266: "null",
    23267: "Gilded d'hide chaps",
    23268: "null",
    23269: "null",
    23270: "Adamant dragon mask",
    23271: "null",
    23272: "null",
    23273: "Rune dragon mask",
    23274: "null",
    23275: "null",
    23276: "Gilded pickaxe",
    23277: "null",
    23278: "null",
    23279: "Gilded axe",
    23280: "null",
    23281: "null",
    23282: "Gilded spade",
    23283: "null",
    23284: "null",
    23285: "Mole slippers",
    23286: "null",
    23287: "null",
    23288: "Frog slippers",
    23289: "null",
    23290: "null",
    23291: "Bear feet",
    23292: "null",
    23293: "null",
    23294: "Demon feet",
    23295: "null",
    23296: "null",
    23297: "Jester cape",
    23298: "null",
    23299: "null",
    23300: "Shoulder parrot",
    23301: "null",
    23302: "null",
    23303: "Monk's robe top (t)",
    23304: "null",
    23305: "null",
    23306: "Monk's robe (t)",
    23307: "null",
    23308: "null",
    23309: "Amulet of defence (t)",
    23310: "null",
    23311: "null",
    23312: "Sandwich lady hat",
    23313: "null",
    23314: "null",
    23315: "Sandwich lady top",
    23316: "null",
    23317: "null",
    23318: "Sandwich lady bottom",
    23319: "null",
    23320: "null",
    23321: "Rune scimitar ornament kit (guthix)",
    23322: "null",
    23323: "null",
    23324: "Rune scimitar ornament kit (saradomin)",
    23325: "null",
    23326: "null",
    23327: "Rune scimitar ornament kit (zamorak)",
    23328: "null",
    23329: "null",
    23330: "Rune scimitar",
    23331: "null",
    23332: "Rune scimitar",
    23333: "null",
    23334: "Rune scimitar",
    23335: "null",
    23336: "3rd age druidic robe top",
    23337: "null",
    23338: "null",
    23339: "3rd age druidic robe bottoms",
    23340: "null",
    23341: "null",
    23342: "3rd age druidic staff",
    23343: "null",
    23344: "null",
    23345: "3rd age druidic cloak",
    23346: "null",
    23347: "null",
    23348: "Tormented ornament kit",
    23349: "null",
    23350: "null",
    23351: "Cape of skulls",
    23352: "null",
    23353: "null",
    23354: "Amulet of power (t)",
    23355: "null",
    23356: "null",
    23357: "Rain bow",
    23358: "null",
    23359: "null",
    23360: "Ham joint",
    23361: "null",
    23362: "null",
    23363: "Staff of bob the cat",
    23364: "null",
    23365: "null",
    23366: "Black platebody (h1)",
    23367: "null",
    23368: "null",
    23369: "Black platebody (h2)",
    23370: "null",
    23371: "null",
    23372: "Black platebody (h3)",
    23373: "null",
    23374: "null",
    23375: "Black platebody (h4)",
    23376: "null",
    23377: "null",
    23378: "Black platebody (h5)",
    23379: "null",
    23380: "null",
    23381: "Leather body (g)",
    23382: "null",
    23383: "null",
    23384: "Leather chaps (g)",
    23385: "null",
    23386: "null",
    23387: "Watson teleport",
    23388: "null",
    23389: "Spiked manacles",
    23390: "null",
    23391: "null",
    23392: "Adamant platebody (h1)",
    23393: "null",
    23394: "null",
    23395: "Adamant platebody (h2)",
    23396: "null",
    23397: "null",
    23398: "Adamant platebody (h3)",
    23399: "null",
    23400: "null",
    23401: "Adamant platebody (h4)",
    23402: "null",
    23403: "null",
    23404: "Adamant platebody (h5)",
    23405: "null",
    23406: "null",
    23407: "Wolf mask",
    23408: "null",
    23409: "null",
    23410: "Wolf cloak",
    23411: "null",
    23412: "null",
    23413: "Climbing boots (g)",
    23414: "null",
    23415: "null",
    23416: "Stash units (beginner)",
    23417: "Puzzle box (master)",
    23418: "",
    23419: "",
    23420: "",
    23421: "",
    23422: "",
    23423: "",
    23424: "",
    23425: "",
    23426: "",
    23427: "",
    23428: "",
    23429: "",
    23430: "",
    23431: "",
    23432: "",
    23433: "",
    23434: "",
    23435: "",
    23436: "",
    23437: "",
    23438: "",
    23439: "",
    23440: "",
    23441: "",
    23442: "Clue geode (beginner)",
    23443: "null",
    23444: "Tormented bracelet (or)",
    23445: "null",
    23446: "Giant easter egg",
    23447: "null",
    23448: "Bunnyman mask",
    23449: "null",
    23450: "Null",
    23451: "Null",
    23452: "Null",
    23453: "Null",
    23454: "Null",
    23455: "Null",
    23456: "Null",
    23457: "Null",
    23458: "Enchanted lyre(i)",
    23459: "null",
    23460: "Attacker icon",
    23461: "Attacker icon",
    23462: "Attacker icon",
    23463: "Attacker icon",
    23464: "Attacker icon",
    23465: "Attacker icon",
    23466: "Defender icon",
    23467: "Defender icon",
    23468: "Defender icon",
    23469: "Defender icon",
    23470: "Defender icon",
    23471: "Collector icon",
    23472: "Collector icon",
    23473: "Collector icon",
    23474: "Collector icon",
    23475: "Collector icon",
    23476: "Collector icon",
    23477: "Collector icon",
    23478: "Healer icon",
    23479: "Healer icon",
    23480: "Healer icon",
    23481: "Healer icon",
    23482: "Healer icon",
    23483: "Healer icon",
    23484: "Healer icon",
    23485: "Healer icon",
    23486: "Healer icon",
    23487: "Archaic emblem (tier 10)",
    23488: "null",
    23489: "Wine of zamorak",
    23490: "Larran's key",
    23491: "null",
    23492: "null",
    23493: "null",
    23494: "null",
    23495: "Sraracha",
    23496: "null",
    23497: "Temple coin",
    23498: "null",
    23499: "Grubby key",
    23500: "null",
    23501: "null",
    23502: "Temple key",
    23503: "null",
    23504: "Tome of the moon",
    23505: "null",
    23506: "Tome of the sun",
    23507: "null",
    23508: "Tome of the temple",
    23509: "null",
    23510: "Tattered moon page",
    23511: "null",
    23512: "Tattered sun page",
    23513: "null",
    23514: "Tattered temple page",
    23515: "null",
    23516: "Lamp of knowledge",
    23517: "Giant egg sac(full)",
    23518: "null",
    23519: "null",
    23520: "Giant egg sac",
    23521: "null",
    23522: "Mask of ranul",
    23523: "null",
    23524: "null",
    23525: "Jar of eyes",
    23526: "null",
    23527: "null",
    23528: "Sarachnis cudgel",
    23529: "null",
    23530: "null",
    23531: "null",
    23532: "null",
    23533: "Cooked karambwan",
    23534: "null",
    23535: "null",
    23536: "null",
    23537: "null",
    23538: "null",
    23539: "null",
    23540: "null",
    23541: "null",
    23542: "null",
    23543: "Super combat potion(4)",
    23544: "null",
    23545: "Super combat potion(3)",
    23546: "null",
    23547: "Super combat potion(2)",
    23548: "null",
    23549: "Super combat potion(1)",
    23550: "null",
    23551: "Ranging potion(4)",
    23552: "null",
    23553: "Ranging potion(3)",
    23554: "null",
    23555: "Ranging potion(2)",
    23556: "null",
    23557: "Ranging potion(1)",
    23558: "null",
    23559: "Sanfew serum(4)",
    23560: "null",
    23561: "Sanfew serum(3)",
    23562: "null",
    23563: "Sanfew serum(2)",
    23564: "null",
    23565: "Sanfew serum(1)",
    23566: "null",
    23567: "Super restore(4)",
    23568: "null",
    23569: "Super restore(3)",
    23570: "null",
    23571: "Super restore(2)",
    23572: "null",
    23573: "Super restore(1)",
    23574: "null",
    23575: "Saradomin brew(4)",
    23576: "null",
    23577: "Saradomin brew(3)",
    23578: "null",
    23579: "Saradomin brew(2)",
    23580: "null",
    23581: "Saradomin brew(1)",
    23582: "null",
    23583: "Stamina potion(4)",
    23584: "null",
    23585: "Stamina potion(3)",
    23586: "null",
    23587: "Stamina potion(2)",
    23588: "null",
    23589: "Stamina potion(1)",
    23590: "null",
    23591: "Helm of neitiznot",
    23592: "null",
    23593: "Barrows gloves",
    23594: "null",
    23595: "Berserker ring",
    23596: "null",
    23597: "Dragon defender",
    23598: "null",
    23599: "Spirit shield",
    23600: "null",
    23601: "Rune crossbow",
    23602: "null",
    23603: "Imbued guthix cape",
    23604: "null",
    23605: "Imbued zamorak cape",
    23606: "null",
    23607: "Imbued saradomin cape",
    23608: "null",
    23609: "Ava's accumulator",
    23610: "null",
    23611: "Armadyl crossbow",
    23612: "null",
    23613: "Staff of the dead",
    23614: "null",
    23615: "Vesta's longsword",
    23616: "null",
    23617: "Zuriel's staff",
    23618: "null",
    23619: "Morrigan's javelin",
    23620: "Statius's warhammer",
    23621: "null",
    23622: "Infernal cape",
    23623: "null",
    23624: "Seers ring (i)",
    23625: "null",
    23626: "Kodai wand",
    23627: "null",
    23628: "Ghrazi rapier",
    23629: "null",
    23630: "Heavy ballista",
    23631: "null",
    23632: "Karil's leathertop",
    23633: "Dharok's platelegs",
    23634: "Torag's platelegs",
    23635: "Verac's plateskirt",
    23636: "Verac's helm",
    23637: "Torag's helm",
    23638: "Guthan's helm",
    23639: "Dharok's helm",
    23640: "Amulet of fury",
    23641: "null",
    23642: "Blessed spirit shield",
    23643: "null",
    23644: "Eternal boots",
    23645: "null",
    23646: "Bandos tassets",
    23647: "null",
    23648: "Dragon javelin",
    23649: "Diamond bolts (e)",
    23650: "Rune pouch",
    23651: "null",
    23652: "Mage's book",
    23653: "Ahrim's staff",
    23654: "Occult necklace",
    23655: "Crystal seedling",
    23656: "null",
    23657: "Crystal seedling (w)",
    23658: "null",
    23659: "Crystal sapling",
    23660: "null",
    23661: "Crystal acorn",
    23662: "null",
    23663: "null",
    23664: "null",
    23665: "null",
    23666: "null",
    23667: "Dragonstone armour set",
    23668: "null",
    23669: "null",
    23670: "Flyer",
    23671: "null",
    23672: "null",
    23673: "Crystal axe",
    23674: "null",
    23675: "Crystal axe (inactive)",
    23676: "null",
    23677: "Dragon pickaxe (or)",
    23678: "null",
    23679: "null",
    23680: "Crystal pickaxe",
    23681: "null",
    23682: "Crystal pickaxe (inactive)",
    23683: "null",
    23684: "null",
    23685: "Divine super combat potion(4)",
    23686: "null",
    23687: "null",
    23688: "Divine super combat potion(3)",
    23689: "null",
    23690: "null",
    23691: "Divine super combat potion(2)",
    23692: "null",
    23693: "null",
    23694: "Divine super combat potion(1)",
    23695: "null",
    23696: "null",
    23697: "Divine super attack potion(4)",
    23698: "null",
    23699: "null",
    23700: "Divine super attack potion(3)",
    23701: "null",
    23702: "null",
    23703: "Divine super attack potion(2)",
    23704: "null",
    23705: "null",
    23706: "Divine super attack potion(1)",
    23707: "null",
    23708: "null",
    23709: "Divine super strength potion(4)",
    23710: "null",
    23711: "null",
    23712: "Divine super strength potion(3)",
    23713: "null",
    23714: "null",
    23715: "Divine super strength potion(2)",
    23716: "null",
    23717: "null",
    23718: "Divine super strength potion(1)",
    23719: "null",
    23720: "null",
    23721: "Divine super defence potion(4)",
    23722: "null",
    23723: "null",
    23724: "Divine super defence potion(3)",
    23725: "null",
    23726: "null",
    23727: "Divine super defence potion(2)",
    23728: "null",
    23729: "null",
    23730: "Divine super defence potion(1)",
    23731: "null",
    23732: "null",
    23733: "Divine ranging potion(4)",
    23734: "null",
    23735: "null",
    23736: "Divine ranging potion(3)",
    23737: "null",
    23738: "null",
    23739: "Divine ranging potion(2)",
    23740: "null",
    23741: "null",
    23742: "Divine ranging potion(1)",
    23743: "null",
    23744: "null",
    23745: "Divine magic potion(4)",
    23746: "null",
    23747: "null",
    23748: "Divine magic potion(3)",
    23749: "null",
    23750: "null",
    23751: "Divine magic potion(2)",
    23752: "null",
    23753: "null",
    23754: "Divine magic potion(1)",
    23755: "null",
    23756: "null",
    23757: "Youngllef",
    23758: "null",
    23759: "Corrupted youngllef",
    23760: "Smolcano",
    23761: "null",
    23762: "Crystal harpoon",
    23763: "null",
    23764: "Crystal harpoon (inactive)",
    23765: "null",
    23766: "null",
    23767: "null",
    23768: "Crystal impling jar",
    23769: "null",
    23770: "Clue scroll (elite)",
    23771: "Prifddinas teleport",
    23772: "null",
    23773: "Scrawled notes",
    23774: "null",
    23775: "Hand mirror",
    23776: "Red crystal",
    23777: "Yellow crystal",
    23778: "Green crystal",
    23779: "Cyan crystal",
    23780: "Blue crystal",
    23781: "Magenta crystal",
    23782: "Black crystal",
    23783: "Green crystal",
    23784: "Fractured crystal",
    23785: "Ardougne knight helm",
    23786: "null",
    23787: "Ardougne knight platebody",
    23788: "null",
    23789: "Ardougne knight platelegs",
    23790: "null",
    23791: "Ardougne knight tabard",
    23792: "Blue liquid",
    23793: "Green powder",
    23794: "Clear liquid",
    23795: "Red powder",
    23796: "Ode to eternity",
    23797: "null",
    23798: "Elder cadantine",
    23799: "null",
    23800: "Elder cadantine potion (unf)",
    23801: "null",
    23802: "Crystal",
    23803: "null",
    23804: "Crystal dust",
    23805: "null",
    23806: "Inversion potion",
    23807: "null",
    23808: "Crystal seed",
    23809: "null",
    23810: "Crystal seed",
    23811: "null",
    23812: "Orb of light",
    23813: "null",
    23814: "Clue scroll",
    23815: "Clue scroll",
    23816: "Clue scroll",
    23817: "Clue scroll",
    23818: "Explosive potion",
    23819: "null",
    23820: "Corrupted sceptre",
    23821: "Corrupted axe",
    23822: "Corrupted pickaxe",
    23823: "Corrupted harpoon",
    23824: "Corrupted shards",
    23825: "null",
    23826: "null",
    23827: "null",
    23828: "null",
    23829: "null",
    23830: "Corrupted dust",
    23831: "Corrupted spike",
    23832: "Corrupted bowstring",
    23833: "Corrupted orb",
    23834: "Weapon frame",
    23835: "Grym leaf",
    23836: "Linum tirinum",
    23837: "Corrupted ore",
    23838: "Phren bark",
    23839: "Vial",
    23840: "Corrupted helm (basic)",
    23841: "Corrupted helm (attuned)",
    23842: "Corrupted helm (perfected)",
    23843: "Corrupted body (basic)",
    23844: "Corrupted body (attuned)",
    23845: "Corrupted body (perfected)",
    23846: "Corrupted legs (basic)",
    23847: "Corrupted legs (attuned)",
    23848: "Corrupted legs (perfected)",
    23849: "Corrupted halberd (basic)",
    23850: "Corrupted halberd (attuned)",
    23851: "Corrupted halberd (perfected)",
    23852: "Corrupted staff (basic)",
    23853: "Corrupted staff (attuned)",
    23854: "Corrupted staff (perfected)",
    23855: "Corrupted bow (basic)",
    23856: "Corrupted bow (attuned)",
    23857: "Corrupted bow (perfected)",
    23858: "Corrupted teleport crystal",
    23859: "Gauntlet cape",
    23860: "null",
    23861: "Crystal sceptre",
    23862: "Crystal axe",
    23863: "Crystal pickaxe",
    23864: "Crystal harpoon",
    23865: "Pestle and mortar",
    23866: "Crystal shards",
    23867: "Crystal dust",
    23868: "Crystal spike",
    23869: "Crystalline bowstring",
    23870: "Crystal orb",
    23871: "Weapon frame",
    23872: "Raw paddlefish",
    23873: "Burnt fish",
    23874: "Paddlefish",
    23875: "Grym leaf",
    23876: "Linum tirinum",
    23877: "Crystal ore",
    23878: "Phren bark",
    23879: "Vial",
    23880: "Water-filled vial",
    23881: "Grym potion (unf)",
    23882: "Egniol potion (1)",
    23883: "Egniol potion (2)",
    23884: "Egniol potion (3)",
    23885: "Egniol potion (4)",
    23886: "Crystal helm (basic)",
    23887: "Crystal helm (attuned)",
    23888: "Crystal helm (perfected)",
    23889: "Crystal body (basic)",
    23890: "Crystal body (attuned)",
    23891: "Crystal body (perfected)",
    23892: "Crystal legs (basic)",
    23893: "Crystal legs (attuned)",
    23894: "Crystal legs (perfected)",
    23895: "Crystal halberd (basic)",
    23896: "Crystal halberd (attuned)",
    23897: "Crystal halberd (perfected)",
    23898: "Crystal staff (basic)",
    23899: "Crystal staff (attuned)",
    23900: "Crystal staff (perfected)",
    23901: "Crystal bow (basic)",
    23902: "Crystal bow (attuned)",
    23903: "Crystal bow (perfected)",
    23904: "Teleport crystal",
    23905: "Tephra",
    23906: "Refined tephra",
    23907: "Imbued tephra",
    23908: "Zalcano shard",
    23909: "null",
    23910: "null",
    23911: "Crystal crown",
    23912: "null",
    23913: "Crystal crown",
    23914: "null",
    23915: "Crystal crown",
    23916: "null",
    23917: "Crystal crown",
    23918: "null",
    23919: "Crystal crown",
    23920: "null",
    23921: "Crystal crown",
    23922: "null",
    23923: "Crystal crown",
    23924: "null",
    23925: "Crystal crown",
    23926: "null",
    23927: "Crystal of ithell",
    23928: "null",
    23929: "Crystal of iorwerth",
    23930: "null",
    23931: "Crystal of trahaearn",
    23932: "null",
    23933: "Crystal of cadarn",
    23934: "null",
    23935: "Crystal of crwys",
    23936: "null",
    23937: "Crystal of meilyr",
    23938: "null",
    23939: "Crystal of hefin",
    23940: "null",
    23941: "Crystal of amlodd",
    23942: "null",
    23943: "Elven signet",
    23944: "null",
    23945: "null",
    23946: "Eternal teleport crystal",
    23947: "null",
    23948: "Elven dawn",
    23949: "null",
    23950: "null",
    23951: "Enhanced crystal key",
    23952: "null",
    23953: "Crystal tool seed",
    23954: "null",
    23955: "null",
    23956: "Crystal armour seed",
    23957: "null",
    23958: "null",
    23959: "Enhanced crystal teleport seed",
    23960: "null",
    23961: "null",
    23962: "Crystal shard",
    23963: "null",
    23964: "Crystal dust",
    23965: "null",
    23966: "null",
    23967: "null",
    23968: "null",
    23969: "null",
    23970: "null",
    23971: "Crystal helm",
    23972: "null",
    23973: "Crystal helm (inactive)",
    23974: "null",
    23975: "Crystal body",
    23976: "null",
    23977: "Crystal body (inactive)",
    23978: "null",
    23979: "Crystal legs",
    23980: "null",
    23981: "Crystal legs (inactive)",
    23982: "null",
    23983: "Crystal bow",
    23984: "null",
    23985: "Crystal bow (inactive)",
    23986: "null",
    23987: "Crystal halberd",
    23988: "null",
    23989: "Crystal halberd (inactive)",
    23990: "null",
    23991: "Crystal shield",
    23992: "null",
    23993: "Crystal shield (inactive)",
    23994: "null",
    23995: "Blade of saeldor",
    23996: "null",
    23997: "Blade of saeldor (inactive)",
    23998: "null",
    23999: "null",
    24000: "Crystal grail",
    24001: "null",
    24002: "null",
    24003: "Elven boots",
    24004: "null",
    24005: "null",
    24006: "Elven gloves",
    24007: "null",
    24008: "null",
    24009: "Elven top",
    24010: "null",
    24011: "null",
    24012: "Elven skirt",
    24013: "null",
    24014: "null",
    24015: "Elven top",
    24016: "null",
    24017: "null",
    24018: "Elven skirt",
    24019: "null",
    24020: "null",
    24021: "Elven top",
    24022: "null",
    24023: "null",
    24024: "Elven legwear",
    24025: "null",
    24026: "null",
    24027: "Elven top",
    24028: "null",
    24029: "null",
    24030: "Memoriam crystal (1)",
    24031: "Memoriam crystal (2)",
    24032: "Memoriam crystal (3)",
    24033: "Memoriam crystal (4)",
    24034: "Dragonstone full helm",
    24035: "null",
    24036: "null",
    24037: "Dragonstone platebody",
    24038: "null",
    24039: "null",
    24040: "Dragonstone platelegs",
    24041: "null",
    24042: "null",
    24043: "Dragonstone boots",
    24044: "null",
    24045: "null",
    24046: "Dragonstone gauntlets",
    24047: "null",
    24048: "null",
    24049: "Crazed scribbles",
    24050: "null",
    24051: "A dear friend",
    24052: "null",
    24053: "On leprechauns",
    24054: "null",
    24055: "Bloody diary",
    24056: "null",
    24057: "The eight clans",
    24058: "null",
    24059: "Gollwyn's final statement",
    24060: "null",
    24061: "Niff & harry",
    24062: "null",
    24063: "Soggy journal",
    24064: "null",
    24065: "Ebrill's journal",
    24066: "null",
    24067: "Stained journal",
    24068: "null",
    24069: "The truth behind the myth (excerpt)",
    24070: "null",
    24071: "The living statues",
    24072: "null",
    24073: "The spurned demon",
    24074: "null",
    24075: "Legends of the mountain",
    24076: "null",
    24077: "null",
    24078: "null",
    24079: "null",
    24080: "null",
    24081: "null",
    24082: "null",
    24083: "null",
    24084: "null",
    24085: "null",
    24086: "null",
    24087: "null",
    24088: "null",
    24089: "null",
    24090: "null",
    24091: "null",
    24092: "null",
    24093: "null",
    24094: "null",
    24095: "null",
    24096: "null",
    24097: "null",
    24098: "null",
    24099: "null",
    24100: "null",
    24101: "null",
    24102: "null",
    24103: "null",
    24104: "null",
    24105: "null",
    24106: "null",
    24107: "null",
    24108: "null",
    24109: "null",
    24110: "null",
    24111: "null",
    24112: "null",
    24113: "null",
    24114: "null",
    24115: "null",
    24116: "null",
    24117: "null",
    24118: "null",
    24119: "null",
    24120: "null",
    24121: "null",
    24122: "null",
    24123: "Crystal bow",
    24124: "null",
    24125: "Crystal halberd",
    24126: "null",
    24127: "Crystal shield",
    24128: "null",
    24129: "null",
    24130: "Combat path starter kit",
    24131: "Combat path voucher",
    24132: "Marble lectern",
    24133: "Infernal max cape (l)",
    24134: "Fire max cape (l)",
    24135: "Assembler max cape (l)",
    24136: "Bronze defender (l)",
    24137: "Iron defender (l)",
    24138: "Steel defender (l)",
    24139: "Black defender (l)",
    24140: "Mithril defender (l)",
    24141: "Adamant defender (l)",
    24142: "Rune defender (l)",
    24143: "Dragon defender (l)",
    24144: "Staff of balance",
    24145: "null",
    24146: "null",
    24147: "Armadyl halo (broken)",
    24148: "null",
    24149: "Bandos halo (broken)",
    24150: "null",
    24151: "Seren halo (broken)",
    24152: "null",
    24153: "Ancient halo (broken)",
    24154: "null",
    24155: "Brassica halo (broken)",
    24156: "null",
    24157: "Decorative sword (l)",
    24158: "Decorative armour (l)",
    24159: "Decorative armour (l)",
    24160: "Decorative helm (l)",
    24161: "Decorative shield (l)",
    24162: "Decorative armour (l)",
    24163: "Decorative armour (l)",
    24164: "Decorative armour (l)",
    24165: "Decorative armour (l)",
    24166: "Decorative armour (l)",
    24167: "Decorative armour (l)",
    24168: "Decorative armour (l)",
    24169: "Saradomin halo (l)",
    24170: "Zamorak halo (l)",
    24171: "Guthix halo (l)",
    24172: "Healer hat (l)",
    24173: "Fighter hat (l)",
    24174: "Ranger hat (l)",
    24175: "Fighter torso (l)",
    24176: "Penance skirt (l)",
    24177: "Void knight top (l)",
    24178: "Elite void top (l)",
    24179: "Void knight robe (l)",
    24180: "Elite void robe (l)",
    24181: "Void knight mace (l)",
    24182: "Void knight gloves (l)",
    24183: "Void mage helm (l)",
    24184: "Void ranger helm (l)",
    24185: "Void melee helm (l)",
    24186: "Avernic defender (l)",
    24187: "Trouver parchment",
    24188: "null",
    24189: "Deadman's chest",
    24190: "Deadman's legs",
    24191: "Deadman's cape",
    24192: "Armadyl halo",
    24193: "null",
    24194: "Armadyl halo (l)",
    24195: "Bandos halo",
    24196: "null",
    24197: "Bandos halo (l)",
    24198: "Seren halo",
    24199: "null",
    24200: "Seren halo (l)",
    24201: "Ancient halo",
    24202: "null",
    24203: "Ancient halo (l)",
    24204: "Brassica halo",
    24205: "null",
    24206: "Brassica halo (l)",
    24207: "Victor's cape (1)",
    24208: "null",
    24209: "Victor's cape (10)",
    24210: "null",
    24211: "Victor's cape (50)",
    24212: "null",
    24213: "Victor's cape (100)",
    24214: "null",
    24215: "Victor's cape (500)",
    24216: "null",
    24217: "Guthixian icon",
    24218: "null",
    24219: "Swift blade",
    24220: "null",
    24221: "null",
    24222: "Ava's assembler (l)",
    24223: "Fire cape (l)",
    24224: "Infernal cape (l)",
    24225: "Granite maul",
    24226: "null",
    24227: "Granite maul",
    24228: "null",
    24229: "Ornate maul handle",
    24230: "null",
    24231: "null",
    24232: "Imbued saradomin max cape (l)",
    24233: "Imbued zamorak max cape (l)",
    24234: "Imbued guthix max cape (l)",
    24235: "House advertisement",
    24236: "Imbued saradomin cape (broken)",
    24237: "null",
    24238: "Imbued saradomin max cape (broken)",
    24239: "null",
    24240: "Imbued guthix cape (broken)",
    24241: "null",
    24242: "Imbued guthix max cape (broken)",
    24243: "null",
    24244: "Imbued zamorak cape (broken)",
    24245: "null",
    24246: "Imbued zamorak max cape (broken)",
    24247: "null",
    24248: "Imbued saradomin cape (l)",
    24249: "Imbued guthix cape (l)",
    24250: "Imbued zamorak cape (l)",
    24251: "Wilderness crabs teleport",
    24252: "null",
    24253: "Clue scroll (elite)",
    24254: "Fang",
    24255: "Venom gland",
    24256: "Unsealed letter",
    24257: "Unsealed letter",
    24258: "V sigil",
    24259: "V sigil (e)",
    24260: "Molten glass (i)",
    24261: "Lunar glass",
    24262: "Polishing rock",
    24263: "Ballad of the basilisk",
    24264: "null",
    24265: "V's shield",
    24266: "V's shield",
    24267: "null",
    24268: "Basilisk jaw",
    24269: "null",
    24270: "null",
    24271: "Neitiznot faceguard",
    24272: "null",
    24273: "null",
    24274: "null",
    24275: "null",
    24276: "Basilisk knight",
    24277: "Mysterious emblem (tier 1)",
    24278: "null",
    24279: "Mysterious emblem (tier 2)",
    24280: "null",
    24281: "Mysterious emblem (tier 3)",
    24282: "null",
    24283: "Mysterious emblem (tier 4)",
    24284: "null",
    24285: "Mysterious emblem (tier 5)",
    24286: "null",
    24287: "Decorative emblem",
    24288: "Dagon'hai hat",
    24289: "null",
    24290: "null",
    24291: "Dagon'hai robe top",
    24292: "null",
    24293: "null",
    24294: "Dagon'hai robe bottom",
    24295: "null",
    24296: "null",
    24297: "White bed sheets",
    24298: "Smoke powder",
    24299: "Shiny glass",
    24300: "Spooky hood",
    24301: "Spooky robe",
    24302: "Spooky skirt",
    24303: "Spooky gloves",
    24304: "Spooky boots",
    24305: "Spooky hood",
    24306: "null",
    24307: "Spooky robe",
    24308: "null",
    24309: "Spooky skirt",
    24310: "null",
    24311: "Spooky gloves",
    24312: "null",
    24313: "Spooky boots",
    24314: "null",
    24315: "Spookier hood",
    24316: "null",
    24317: "Spookier robe",
    24318: "null",
    24319: "Spookier skirt",
    24320: "null",
    24321: "Spookier gloves",
    24322: "null",
    24323: "Spookier boots",
    24324: "null",
    24325: "Pumpkin lantern",
    24326: "null",
    24327: "Skeleton lantern",
    24328: "null",
    24329: "Bounty crate",
    24330: "null",
    24331: "Birthday cake",
    24332: "Birthday cake",
    24333: "Dagon'hai robes set",
    24334: "null",
    24335: "null",
    24336: "Target teleport",
    24337: "null",
    24338: "Bounty hunter hat (tier 1)",
    24339: "null",
    24340: "Bounty hunter hat (tier 2)",
    24341: "null",
    24342: "Bounty hunter hat (tier 3)",
    24343: "null",
    24344: "Bounty hunter hat (tier 4)",
    24345: "null",
    24346: "Bounty hunter hat (tier 5)",
    24347: "null",
    24348: "Bounty hunter hat (tier 6)",
    24349: "null",
    24350: "null",
    24351: "null",
    24352: "null",
    24353: "null",
    24354: "null",
    24355: "null",
    24356: "null",
    24357: "null",
    24358: "null",
    24359: "null",
    24360: "null",
    24361: "Scroll box (beginner)",
    24362: "Scroll box (easy)",
    24363: "Scroll box (medium)",
    24364: "Scroll box (hard)",
    24365: "Scroll box (elite)",
    24366: "Scroll box (master)",
    24367: "Cabbage",
    24368: "null",
    24369: "null",
    24370: "Twisted slayer helmet",
    24371: "null",
    24372: "Twisted dragon trophy",
    24373: "null",
    24374: "Twisted rune trophy",
    24375: "null",
    24376: "Twisted adamant trophy",
    24377: "null",
    24378: "Twisted mithril trophy",
    24379: "null",
    24380: "Twisted steel trophy",
    24381: "null",
    24382: "Twisted iron trophy",
    24383: "null",
    24384: "Twisted bronze trophy",
    24385: "null",
    24386: "null",
    24387: "Twisted hat (t3)",
    24388: "null",
    24389: "Twisted coat (t3)",
    24390: "null",
    24391: "Twisted trousers (t3)",
    24392: "null",
    24393: "Twisted boots (t3)",
    24394: "null",
    24395: "Twisted cane",
    24396: "null",
    24397: "Twisted hat (t2)",
    24398: "null",
    24399: "Twisted coat (t2)",
    24400: "null",
    24401: "Twisted trousers (t2)",
    24402: "null",
    24403: "Twisted boots (t2)",
    24404: "null",
    24405: "Twisted hat (t1)",
    24406: "null",
    24407: "Twisted coat (t1)",
    24408: "null",
    24409: "Twisted trousers (t1)",
    24410: "null",
    24411: "Twisted boots (t1)",
    24412: "null",
    24413: "Twisted banner",
    24414: "null",
    24415: "null",
    24416: "Rune pouch (l)",
    24417: "Inquisitor's mace",
    24418: "Gravestone",
    24419: "Inquisitor's great helm",
    24420: "Inquisitor's hauberk",
    24421: "Inquisitor's plateskirt",
    24422: "Nightmare staff",
    24423: "Harmonised nightmare staff",
    24424: "Volatile nightmare staff",
    24425: "Eldritch nightmare staff",
    24426: "Cabbage",
    24427: "null",
    24428: "Green gingerbread shield",
    24429: "null",
    24430: "Red gingerbread shield",
    24431: "Blue gingerbread shield",
    24432: "Festive cinnamon stick",
    24433: "Festive ginger powder",
    24434: "Festive egg",
    24435: "Festive pot",
    24436: "Festive flour",
    24437: "Gingerbread shield",
    24438: "Iced gingerbread shield",
    24439: "Iced gingerbread shield",
    24440: "Iced gingerbread shield",
    24441: "Scaperune teleport",
    24442: "Bakery storage key",
    24443: "Gingerbread gnome",
    24444: "Twisted slayer helmet (i)",
    24445: "null",
    24446: "null",
    24447: "null",
    24448: "null",
    24449: "null",
    24450: "null",
    24451: "null",
    24452: "null",
    24453: "null",
    24454: "null",
    24455: "null",
    24456: "null",
    24457: "null",
    24458: "null",
    24459: "null",
    24460: "Twisted teleport scroll",
    24461: "null",
    24462: "null",
    24463: "Twisted blueprints",
    24464: "null",
    24465: "null",
    24466: "Twisted horns",
    24467: "null",
    24468: "null",
    24469: "Twisted relic hunter (t1) armour set",
    24470: "null",
    24471: "null",
    24472: "Twisted relic hunter (t2) armour set",
    24473: "null",
    24474: "null",
    24475: "Twisted relic hunter (t3) armour set",
    24476: "null",
    24477: "null",
    24478: "Open herb sack",
    24479: "Spice rack",
    24480: "Open coal bag",
    24481: "Open gem bag",
    24482: "Open seed box",
    24483: "Phoenix",
    24484: "Phoenix",
    24485: "Phoenix",
    24486: "Phoenix",
    24487: "null",
    24488: "Inquisitor's armour set",
    24489: "null",
    24490: "null",
    24491: "Little nightmare",
    24492: "null",
    24493: "Clue scroll (hard)",
    24494: "Puzzle box (hard)",
    24495: "Jar of dreams",
    24496: "null",
    24497: "null",
    24498: "null",
    24499: "null",
    24500: "null",
    24501: "null",
    24502: "null",
    24503: "null",
    24504: "null",
    24505: "null",
    24506: "null",
    24507: "null",
    24508: "null",
    24509: "null",
    24510: "null",
    24511: "Harmonised orb",
    24512: "null",
    24513: "null",
    24514: "Volatile orb",
    24515: "null",
    24516: "null",
    24517: "Eldritch orb",
    24518: "null",
    24519: "null",
    24520: "Victor's cape (1000)",
    24521: "null",
    24522: "Null",
    24523: "Death's coffer",
    24524: "Gravestone",
    24525: "Cat ears",
    24526: "null",
    24527: "Hell cat ears",
    24528: "Lamp of the gatherer",
    24529: "Harmony",
    24530: "null",
    24531: "Runner hat (broken)",
    24532: "null",
    24533: "Runner hat (l)",
    24534: "Mithril seeds",
    24535: "Magic egg ball",
    24536: "null",
    24537: "Carrot sword",
    24538: "null",
    24539: "'24-carat' sword",
    24540: "null",
    24541: "Painted fake magic egg",
    24542: "Unpainted fake magic egg",
    24543: "Conch shell",
    24544: "Broken egg",
    24545: "Dummy portal",
    24546: "Carrot",
    24547: "Broken goat horn",
    24548: "null",
    24549: "Cake",
    24550: "null",
    24551: "Blade of saeldor (c)",
    24552: "null",
    24553: "Blade of saeldor (c)",
    24554: "Pyromancer set",
    24555: "Tangleroot",
    24556: "null",
    24557: "Tangleroot",
    24558: "null",
    24559: "Tangleroot",
    24560: "null",
    24561: "Tangleroot",
    24562: "null",
    24563: "Tangleroot",
    24564: "null",
    24565: "Antique emblem (tier 1)",
    24566: "null",
    24567: "Antique emblem (tier 2)",
    24568: "null",
    24569: "Antique emblem (tier 3)",
    24570: "null",
    24571: "Antique emblem (tier 4)",
    24572: "null",
    24573: "Antique emblem (tier 5)",
    24574: "null",
    24575: "Antique emblem (tier 6)",
    24576: "null",
    24577: "Antique emblem (tier 7)",
    24578: "null",
    24579: "Antique emblem (tier 8)",
    24580: "null",
    24581: "Antique emblem (tier 9)",
    24582: "null",
    24583: "Antique emblem (tier 10)",
    24584: "null",
    24585: "Looting bag note",
    24586: "null",
    24587: "Rune pouch note",
    24588: "null",
    24589: "Blighted manta ray",
    24590: "null",
    24591: "null",
    24592: "Blighted anglerfish",
    24593: "null",
    24594: "null",
    24595: "Blighted karambwan",
    24596: "null",
    24597: "null",
    24598: "Blighted super restore(4)",
    24599: "null",
    24600: "null",
    24601: "Blighted super restore(3)",
    24602: "null",
    24603: "Blighted super restore(2)",
    24604: "null",
    24605: "Blighted super restore(1)",
    24606: "null",
    24607: "Blighted ancient ice sack",
    24608: "null",
    24609: "Null",
    24610: "null",
    24611: "Null",
    24612: "null",
    24613: "Blighted entangle sack",
    24614: "null",
    24615: "Blighted teleport spell sack",
    24616: "null",
    24617: "Vesta's blighted longsword",
    24618: "null",
    24619: "Vesta's longsword (inactive)",
    24620: "null",
    24621: "Blighted vengeance sack",
    24622: "null",
    24623: "Divine battlemage potion(4)",
    24624: "null",
    24625: "null",
    24626: "Divine battlemage potion(3)",
    24627: "null",
    24628: "null",
    24629: "Divine battlemage potion(2)",
    24630: "null",
    24631: "null",
    24632: "Divine battlemage potion(1)",
    24633: "null",
    24634: "null",
    24635: "Divine bastion potion(4)",
    24636: "null",
    24637: "null",
    24638: "Divine bastion potion(3)",
    24639: "null",
    24640: "null",
    24641: "Divine bastion potion(2)",
    24642: "null",
    24643: "null",
    24644: "Divine bastion potion(1)",
    24645: "null",
    24646: "null",
    24647: "null",
    24648: "null",
    24649: "null",
    24650: "Logs",
    24651: "null",
    24652: "Raw shrimps",
    24653: "null",
    24654: "null",
    24655: "Bones",
    24656: "Enraged tektiny",
    24657: "null",
    24658: "Flying vespina",
    24659: "null",
    24660: "Massive storage unit",
    24661: "Massive storage unit",
    24662: "Massive storage unit",
    24663: "Massive storage unit",
    24664: "Twisted ancestral hat",
    24665: "null",
    24666: "Twisted ancestral robe top",
    24667: "null",
    24668: "Twisted ancestral robe bottom",
    24669: "null",
    24670: "Twisted ancestral colour kit",
    24671: "null",
    24672: "Haemalchemy volume 2",
    24673: "Vyre noble top (unscented)",
    24674: "Vyre noble legs (unscented)",
    24675: "Vyre noble shoes (unscented)",
    24676: "Vyre noble top",
    24677: "null",
    24678: "Vyre noble legs",
    24679: "null",
    24680: "Vyre noble shoes",
    24681: "null",
    24682: "Old note",
    24683: "null",
    24684: "Tatty note",
    24685: "null",
    24686: "Journal page",
    24687: "null",
    24688: "Ancient armour",
    24689: "null",
    24690: "Tome of experience",
    24691: "Blisterwood logs",
    24692: "null",
    24693: "Ruby sickle (b)",
    24694: "null",
    24695: "Enchanted ruby sickle (b)",
    24696: "null",
    24697: "Blisterwood sickle",
    24698: "null",
    24699: "Blisterwood flail",
    24700: "null",
    24701: "Dark squirrel",
    24702: "Vyre",
    24703: "Coin pouch",
    24704: "Daeyalt essence",
    24705: "null",
    24706: "Daeyalt shard",
    24707: "null",
    24708: "Vampyre",
    24709: "Hallowed crystal shard",
    24710: "null",
    24711: "Hallowed mark",
    24712: "null",
    24713: "null",
    24714: "null",
    24715: "null",
    24716: "null",
    24717: "null",
    24718: "null",
    24719: "Hallowed token",
    24720: "null",
    24721: "Hallowed grapple",
    24722: "null",
    24723: "Hallowed focus",
    24724: "null",
    24725: "Hallowed symbol",
    24726: "null",
    24727: "Hallowed hammer",
    24728: "null",
    24729: "Dark dye",
    24730: "null",
    24731: "Hallowed ring",
    24732: "null",
    24733: "Dark acorn",
    24734: "null",
    24735: "Ring of endurance (uncharged)",
    24736: "Ring of endurance",
    24737: "null",
    24738: "Strange old lockpick",
    24739: "null",
    24740: "Strange old lockpick (full)",
    24741: "null",
    24742: "null",
    24743: "Graceful hood",
    24744: "null",
    24745: "Graceful hood",
    24746: "Graceful cape",
    24747: "null",
    24748: "Graceful cape",
    24749: "Graceful top",
    24750: "null",
    24751: "Graceful top",
    24752: "Graceful legs",
    24753: "null",
    24754: "Graceful legs",
    24755: "Graceful gloves",
    24756: "null",
    24757: "Graceful gloves",
    24758: "Graceful boots",
    24759: "null",
    24760: "Graceful boots",
    24761: "Strange hallowed tome",
    24762: "null",
    24763: "Mysterious page",
    24764: "null",
    24765: "Mysterious page",
    24766: "null",
    24767: "Mysterious page",
    24768: "null",
    24769: "Mysterious page",
    24770: "null",
    24771: "Mysterious page",
    24772: "null",
    24773: "Clue scroll (elite)",
    24774: "Blood pint",
    24775: "null",
    24776: "null",
    24777: "Blood shard",
    24778: "null",
    24779: "null",
    24780: "Amulet of blood fury",
    24781: "null",
    24782: "Raw mystery meat",
    24783: "null",
    24784: "null",
    24785: "Cooked mystery meat",
    24786: "null",
    24787: "null",
    24788: "Pat of not garlic butter",
    24789: "null",
    24790: "Long rope",
    24791: "null",
    24792: "Severed leg",
    24793: "null",
    24794: "Vyre noble blazer",
    24795: "null",
    24796: "Vyre noble coat tails",
    24797: "null",
    24798: "Vyre noble vest",
    24799: "null",
    24800: "Vyre noble pants",
    24801: "null",
    24802: "Vyre noble corset",
    24803: "null",
    24804: "Vyre noble skirt",
    24805: "null",
    24806: "Vyre noble dress top",
    24807: "null",
    24808: "Vyre noble dress bottom",
    24809: "null",
    24810: "Vyre noble blazer",
    24811: "null",
    24812: "Vyre noble coat tails",
    24813: "null",
    24814: "Vyre noble vest",
    24815: "null",
    24816: "Vyre noble pants",
    24817: "null",
    24818: "Vyre noble corset",
    24819: "null",
    24820: "Vyre noble skirt",
    24821: "null",
    24822: "Vyre noble dress top",
    24823: "null",
    24824: "Vyre noble dress bottom",
    24825: "null",
    24826: "Vyre noble blazer",
    24827: "null",
    24828: "Vyre noble coat tails",
    24829: "null",
    24830: "Vyre noble vest",
    24831: "null",
    24832: "Vyre noble pants",
    24833: "null",
    24834: "Vyre noble corset",
    24835: "null",
    24836: "Vyre noble skirt",
    24837: "null",
    24838: "Vyre noble dress top",
    24839: "null",
    24840: "Vyre noble dress bottom",
    24841: "null",
    24842: "A taste of hope",
    24843: "null",
    24844: "Ring of endurance (uncharged)",
    24845: "null",
    24846: "null",
    24847: "Red",
    24848: "null",
    24849: "Ziggy",
    24850: "null",
    24851: "Soft clay pack",
    24852: "null",
    24853: "Bag full of gems",
    24854: "null",
    24855: "Mythical max cape",
    24856: "null",
    24857: "Mythical max hood",
    24858: "null",
    24859: "Warrior path starter kit",
    24860: "Wizard path starter kit",
    24861: "Ranger path starter kit",
    24862: "Karamjan monkey",
    24863: "Zombie monkey",
    24864: "Maniacal monkey",
    24865: "Skeleton monkey",
    24866: "Kruk jr",
    24867: "Princely monkey",
    24868: "Golden armadyl special attack",
    24869: "Golden bandos special attack",
    24870: "Golden saradomin special attack",
    24871: "Golden zamorak special attack",
    24872: "Carpenter's helmet",
    24873: "null",
    24874: "Carpenter's shirt",
    24875: "null",
    24876: "Carpenter's trousers",
    24877: "null",
    24878: "Carpenter's boots",
    24879: "null",
    24880: "Amy's saw",
    24881: "null",
    24882: "Plank sack",
    24883: "null",
    24884: "Supply crate",
    24885: "Hosidius blueprints",
    24886: "Wooden table",
    24887: "Oak table",
    24888: "Teak table",
    24889: "Mahogany table",
    24890: "Wooden table",
    24891: "Oak table",
    24892: "Teak table",
    24893: "Mahogany table",
    24894: "Wooden table",
    24895: "Oak table",
    24896: "Teak table",
    24897: "Mahogany table",
    24898: "Wooden cabinet",
    24899: "Oak cabinet",
    24900: "Teak cabinet",
    24901: "Mahogany cabinet",
    24902: "Wooden bookcase",
    24903: "Oak bookcase",
    24904: "Teak bookcase",
    24905: "Mahogany bookcase",
    24906: "Wooden wardrobe",
    24907: "Oak wardrobe",
    24908: "Teak wardrobe",
    24909: "Mahogany wardrobe",
    24910: "Wooden dresser",
    24911: "Oak dresser",
    24912: "Teak dresser",
    24913: "Mahogany dresser",
    24914: "Wooden shelves",
    24915: "Oak shelves",
    24916: "Teak shelves",
    24917: "Mahogany shelves",
    24918: "Wooden bed",
    24919: "Oak bed",
    24920: "Teak bed",
    24921: "Mahogany bed",
    24922: "Wooden bed",
    24923: "Oak bed",
    24924: "Teak bed",
    24925: "Mahogany bed",
    24926: "Wooden drawer",
    24927: "Oak drawer",
    24928: "Teak drawer",
    24929: "Mahogany drawer",
    24930: "Wooden chair",
    24931: "Oak chair",
    24932: "Teak chair",
    24933: "Mahogany chair",
    24934: "Wooden cupboard",
    24935: "Oak cupboard",
    24936: "Teak cupboard",
    24937: "Mahogany cupboard",
    24938: "Waxwood log",
    24939: "Waxwood plank",
    24940: "Marlo's crate",
    24941: "Bp obj",
    24942: "Reinforced goggles",
    24943: "null",
    24944: "Sourhog foot",
    24945: "null",
    24946: "Hallowed sack",
    24947: "null",
    24948: "null",
    24949: "Moonclan teleport",
    24950: "null",
    24951: "Ourania teleport",
    24952: "null",
    24953: "Waterbirth teleport",
    24954: "null",
    24955: "Barbarian teleport",
    24956: "null",
    24957: "Khazard teleport",
    24958: "null",
    24959: "Fishing guild teleport",
    24960: "null",
    24961: "Catherby teleport",
    24962: "null",
    24963: "Ice plateau teleport",
    24964: "null",
    24965: "null",
    24966: "null",
    24967: "null",
    24968: "null",
    24969: "null",
    24970: "null",
    24971: "Cabbage",
    24972: "null",
    24973: "Cabbage",
    24974: "null",
    24975: "Headless head",
    24976: "null",
    24977: "Magical pumpkin",
    24978: "null",
    24979: "Pumpkin",
    24980: "Brown candy",
    24981: "Blue candy",
    24982: "White candy",
    24983: "Purple candy",
    24984: "Red candy",
    24985: "Green candy",
    24986: "Black candy",
    24987: "Orange candy",
    24988: "Pink candy",
    24989: "Rick's box",
    24990: "Rick's head",
    24991: "Witch's brew",
    24992: "Spider snack",
    24993: "Incantation",
    24994: "Cabbage",
    24995: "null",
    24996: "Cabbage",
    24997: "null",
    24998: "null",
    24999: "null",
    25000: "null",
    25001: "Trailblazer hood (t3)",
    25002: "null",
    25003: "null",
    25004: "Trailblazer top (t3)",
    25005: "null",
    25006: "null",
    25007: "Trailblazer trousers (t3)",
    25008: "null",
    25009: "null",
    25010: "Trailblazer boots (t3)",
    25011: "null",
    25012: "null",
    25013: "Trailblazer cane",
    25014: "null",
    25015: "null",
    25016: "Trailblazer hood (t2)",
    25017: "null",
    25018: "null",
    25019: "Trailblazer top (t2)",
    25020: "null",
    25021: "null",
    25022: "Trailblazer trousers (t2)",
    25023: "null",
    25024: "null",
    25025: "Trailblazer boots (t2)",
    25026: "null",
    25027: "null",
    25028: "Trailblazer hood (t1)",
    25029: "null",
    25030: "null",
    25031: "Trailblazer top (t1)",
    25032: "null",
    25033: "null",
    25034: "Trailblazer trousers (t1)",
    25035: "null",
    25036: "null",
    25037: "Trailblazer boots (t1)",
    25038: "null",
    25039: "null",
    25040: "Null",
    25041: "Null",
    25042: "Trailblazer dragon trophy",
    25043: "null",
    25044: "Trailblazer rune trophy",
    25045: "null",
    25046: "Trailblazer adamant trophy",
    25047: "null",
    25048: "Trailblazer mithril trophy",
    25049: "null",
    25050: "Trailblazer steel trophy",
    25051: "null",
    25052: "Trailblazer iron trophy",
    25053: "null",
    25054: "Trailblazer bronze trophy",
    25055: "null",
    25056: "Trailblazer banner",
    25057: "null",
    25058: "null",
    25059: "Infernal harpoon (or)",
    25060: "Null",
    25061: "null",
    25062: "null",
    25063: "Infernal pickaxe (or)",
    25064: "Null",
    25065: "null",
    25066: "Infernal axe (or)",
    25067: "Null",
    25068: "null",
    25069: "Graceful hood",
    25070: "null",
    25071: "Graceful hood",
    25072: "Graceful cape",
    25073: "null",
    25074: "Graceful cape",
    25075: "Graceful top",
    25076: "null",
    25077: "Graceful top",
    25078: "Graceful legs",
    25079: "null",
    25080: "Graceful legs",
    25081: "Graceful gloves",
    25082: "null",
    25083: "Graceful gloves",
    25084: "Graceful boots",
    25085: "null",
    25086: "Graceful boots",
    25087: "Trailblazer teleport scroll",
    25088: "null",
    25089: "null",
    25090: "Trailblazer tool ornament kit",
    25091: "null",
    25092: "null",
    25093: "Trailblazer globe",
    25094: "null",
    25095: "null",
    25096: "Trailblazer rug",
    25097: "null",
    25098: "null",
    25099: "Trailblazer graceful ornament kit",
    25100: "null",
    25101: "null",
    25102: "Fairy mushroom",
    25103: "null",
    25104: "Crystal of memories",
    25105: "null",
    25106: "Extradimensional bag",
    25107: "null",
    25108: "Extradimensional bag",
    25109: "null",
    25110: "Trailblazer axe",
    25111: "null",
    25112: "Trailblazer pickaxe",
    25113: "null",
    25114: "Trailblazer harpoon",
    25115: "null",
    25116: "null",
    25117: "League tomato",
    25118: "null",
    25119: "null",
    25120: "null",
    25121: "null",
    25122: "null",
    25123: "null",
    25124: "null",
    25125: "null",
    25126: "null",
    25127: "null",
    25128: "null",
    25129: "Beekeeper's hat",
    25130: "null",
    25131: "Beekeeper's top",
    25132: "null",
    25133: "Beekeeper's legs",
    25134: "null",
    25135: "Beekeeper's gloves",
    25136: "null",
    25137: "Beekeeper's boots",
    25138: "null",
    25139: "Bone fragments",
    25140: "null",
    25141: "null",
    25142: "null",
    25143: "null",
    25144: "null",
    25145: "Clay head",
    25146: "Fur head",
    25147: "Bloody head",
    25148: "null",
    25149: "null",
    25150: "null",
    25151: "null",
    25152: "Neilan's journal",
    25153: "null",
    25154: "Ornate undead combat dummy",
    25155: "Decorative boots (broken)",
    25156: "null",
    25157: "Decorative full helm (broken)",
    25158: "null",
    25159: "Castlewars brew(4)",
    25160: "Castlewars brew(3)",
    25161: "Castlewars brew(2)",
    25162: "Castlewars brew(1)",
    25163: "Decorative boots",
    25164: "null",
    25165: "Decorative full helm",
    25166: "null",
    25167: "Decorative boots",
    25168: "null",
    25169: "Decorative full helm",
    25170: "null",
    25171: "Decorative boots",
    25172: "null",
    25173: "Decorative boots (l)",
    25174: "Decorative full helm",
    25175: "null",
    25176: "Decorative full helm (l)",
    25177: "Slayer helmet (i)",
    25178: "null",
    25179: "Black slayer helmet (i)",
    25180: "null",
    25181: "Green slayer helmet (i)",
    25182: "null",
    25183: "Red slayer helmet (i)",
    25184: "null",
    25185: "Purple slayer helmet (i)",
    25186: "null",
    25187: "Turquoise slayer helmet (i)",
    25188: "null",
    25189: "Hydra slayer helmet (i)",
    25190: "null",
    25191: "Twisted slayer helmet (i)",
    25192: "null",
    25193: "Granite ring (i)",
    25194: "null",
    25195: "Blue cape",
    25196: "Soul fragment",
    25197: "Soul wars guide",
    25198: "null",
    25199: "Bones",
    25200: "null",
    25201: "Soul fragment",
    25202: "Bandages",
    25203: "Potion of power(4)",
    25204: "Potion of power(3)",
    25205: "Potion of power(2)",
    25206: "Potion of power(1)",
    25207: "Red cape",
    25208: "Blue cape",
    25209: "Barricade",
    25210: "Barricade",
    25211: "Explosive potion",
    25212: "Blue icon",
    25213: "Blue icon",
    25214: "Blue icon",
    25215: "Blue icon",
    25216: "Blue icon",
    25217: "Blue icon",
    25218: "Blue icon",
    25219: "Blue icon",
    25220: "Blue icon",
    25221: "Blue icon",
    25222: "Blue icon",
    25223: "Blue icon",
    25224: "Blue icon",
    25225: "Blue icon",
    25226: "Blue icon",
    25227: "Blue icon",
    25228: "Red icon",
    25229: "Red icon",
    25230: "Red icon",
    25231: "Red icon",
    25232: "Red icon",
    25233: "Red icon",
    25234: "Red icon",
    25235: "Red icon",
    25236: "Red icon",
    25237: "Red icon",
    25238: "Red icon",
    25239: "Red icon",
    25240: "Red icon",
    25241: "Red icon",
    25242: "Red icon",
    25243: "Red icon",
    25244: "Dark key",
    25245: "null",
    25246: "Ring of suffering (i)",
    25247: "null",
    25248: "Ring of suffering (ri)",
    25249: "null",
    25250: "Salve amulet(i)",
    25251: "null",
    25252: "Ring of the gods (i)",
    25253: "null",
    25254: "Tyrannical ring (i)",
    25255: "null",
    25256: "Treasonous ring (i)",
    25257: "null",
    25258: "Seers ring (i)",
    25259: "null",
    25260: "Archers ring (i)",
    25261: "null",
    25262: "Warrior ring (i)",
    25263: "null",
    25264: "Berserker ring (i)",
    25265: "null",
    25266: "Black mask (10) (i)",
    25267: "Black mask (9) (i)",
    25268: "Black mask (8) (i)",
    25269: "Black mask (7) (i)",
    25270: "Black mask (6) (i)",
    25271: "Black mask (5) (i)",
    25272: "Black mask (4) (i)",
    25273: "Black mask (3) (i)",
    25274: "Black mask (2) (i)",
    25275: "Black mask (1) (i)",
    25276: "Black mask (i)",
    25277: "null",
    25278: "Salve amulet(ei)",
    25279: "null",
    25280: "Essence pack",
    25281: "null",
    25282: "Sled",
    25283: "Red fireflies",
    25284: "Green fireflies",
    25285: "Stick",
    25286: "Mouldy sawdust",
    25287: "Rotten meat",
    25288: "Stale bread",
    25289: "Goblin stew",
    25290: "Goblin gifts",
    25291: "null",
    25292: "null",
    25293: "null",
    25294: "null",
    25295: "null",
    25296: "null",
    25297: "null",
    25298: "null",
    25299: "null",
    25300: "null",
    25301: "null",
    25302: "null",
    25303: "null",
    25304: "null",
    25305: "null",
    25306: "null",
    25307: "null",
    25308: "null",
    25309: "null",
    25310: "null",
    25311: "null",
    25312: "null",
    25313: "null",
    25314: "Giant boulder",
    25315: "null",
    25316: "Goblin decorations",
    25317: "null",
    25318: "null",
    25319: "Gnome child icon",
    25320: "Null",
    25321: "Null",
    25322: "20th anniversary hat",
    25323: "null",
    25324: "20th anniversary top",
    25325: "null",
    25326: "20th anniversary bottom",
    25327: "null",
    25328: "20th anniversary boots",
    25329: "null",
    25330: "20th anniversary gloves",
    25331: "null",
    25332: "20th anniversary necklace",
    25333: "null",
    25334: "20th anniversary cape",
    25335: "null",
    25336: "Gnome child mask",
    25337: "null",
    25338: "Gnome child icon",
    25339: "null",
    25340: "Ectoplasmator",
    25341: "null",
    25342: "Spoils of war",
    25343: "null",
    25344: "Soul cape",
    25345: "null",
    25346: "Soul cape",
    25347: "null",
    25348: "Lil' creator",
    25349: "null",
    25350: "Lil' destructor",
    25351: "Trophy pedestal",
    25352: "Ornate trophy pedestal",
    25353: "Oak trophy case",
    25354: "Mahogany trophy case",
    25355: "Banner stand",
    25356: "Ornate banner stand",
    25357: "Oak outfit stand",
    25358: "Mahogany outfit stand",
    25359: "League statue",
    25360: "Ornate league statue",
    25361: "Trailblazer globe",
    25362: "Rug",
    25363: "Opulent rug",
    25364: "Trailblazer rug",
    25365: "League accomplishments scroll",
    25366: "League hall",
    25367: "Infernal harpoon (uncharged)",
    25368: "null",
    25369: "Infernal pickaxe (uncharged)",
    25370: "null",
    25371: "Infernal axe (uncharged)",
    25372: "null",
    25373: "Dragon harpoon (or)",
    25374: "null",
    25375: "null",
    25376: "Dragon pickaxe (or)",
    25377: "null",
    25378: "Dragon axe (or)",
    25379: "null",
    25380: "Trailblazer relic hunter (t1) armour set",
    25381: "null",
    25382: "null",
    25383: "Trailblazer relic hunter (t2) armour set",
    25384: "null",
    25385: "null",
    25386: "Trailblazer relic hunter (t3) armour set",
    25387: "null",
    25388: "null",
    25389: "Swampbark body",
    25390: "null",
    25391: "null",
    25392: "Swampbark gauntlets",
    25393: "null",
    25394: "null",
    25395: "Swampbark boots",
    25396: "null",
    25397: "null",
    25398: "Swampbark helm",
    25399: "null",
    25400: "null",
    25401: "Swampbark legs",
    25402: "null",
    25403: "null",
    25404: "Bloodbark body",
    25405: "null",
    25406: "null",
    25407: "Bloodbark gauntlets",
    25408: "null",
    25409: "null",
    25410: "Bloodbark boots",
    25411: "null",
    25412: "null",
    25413: "Bloodbark helm",
    25414: "null",
    25415: "null",
    25416: "Bloodbark legs",
    25417: "null",
    25418: "null",
    25419: "Urium remains",
    25420: "null",
    25421: "null",
    25422: "Bleached bones",
    25423: "null",
    25424: "Gold key red",
    25425: "null",
    25426: "Gold key brown",
    25427: "null",
    25428: "Gold key crimson",
    25429: "null",
    25430: "Gold key black",
    25431: "null",
    25432: "Gold key purple",
    25433: "null",
    25434: "Zealot's robe top",
    25435: "null",
    25436: "Zealot's robe bottom",
    25437: "null",
    25438: "Zealot's helm",
    25439: "null",
    25440: "Zealot's boots",
    25441: "null",
    25442: "Bronze locks",
    25443: "null",
    25444: "null",
    25445: "Steel locks",
    25446: "null",
    25447: "null",
    25448: "Black locks",
    25449: "null",
    25450: "null",
    25451: "Silver locks",
    25452: "null",
    25453: "null",
    25454: "Gold locks",
    25455: "null",
    25456: "null",
    25457: "Broken coffin",
    25458: "null",
    25459: "Bronze coffin",
    25460: "null",
    25461: "Steel coffin",
    25462: "null",
    25463: "Black coffin",
    25464: "null",
    25465: "Silver coffin",
    25466: "null",
    25467: "Gold coffin",
    25468: "null",
    25469: "Open bronze coffin",
    25470: "Open steel coffin",
    25471: "Open black coffin",
    25472: "Open silver coffin",
    25473: "Open gold coffin",
    25474: "Tree wizards' journal",
    25475: "null",
    25476: "Bloody notes",
    25477: "null",
    25478: "Runescroll of swampbark",
    25479: "null",
    25480: "null",
    25481: "Runescroll of bloodbark",
    25482: "null",
    25483: "null",
    25484: "Soulreaper axe",
    25485: "Ultor ring",
    25486: "Magus ring",
    25487: "Venator ring",
    25488: "Bellator ring",
    25489: "Blood ancient sceptre",
    25490: "Ice ancient sceptre",
    25491: "Smoke ancient sceptre",
    25492: "Shadow ancient sceptre",
    25493: "Black d'hide chaps (beta)",
    25494: "Black d'hide vambraces (beta)",
    25495: "Crystal helm (beta)",
    25496: "Crystal body (beta)",
    25497: "Crystal legs (beta)",
    25498: "Clue scroll (elite)",
    25499: "Clue scroll (elite)",
    25500: "Cursed banana",
    25501: "null",
    25502: "Banana cape",
    25503: "null",
    25504: "Null",
    25505: "Null",
    25506: "Null",
    25507: "Null",
    25508: "Null",
    25509: "Null",
    25510: "Null",
    25511: "Null",
    25512: "Null",
    25513: "Null",
    25514: "Null",
    25515: "Dharok's platebody",
    25516: "Dharok's greataxe",
    25517: "Volatile nightmare staff",
    25518: "Ancestral hat",
    25519: "Jalrek-jad",
    25520: "null",
    25521: "Jar of spirits",
    25522: "null",
    25523: "null",
    25524: "Jar of smoke",
    25525: "null",
    25526: "null",
    25527: "Stardust",
    25528: "null",
    25529: "null",
    25530: "null",
    25531: "null",
    25532: "null",
    25533: "Soft clay pack",
    25534: "null",
    25535: "Essence pack",
    25536: "null",
    25537: "Bag full of gems",
    25538: "null",
    25539: "Celestial ring (uncharged)",
    25540: "null",
    25541: "Celestial ring",
    25542: "null",
    25543: "Celestial signet (uncharged)",
    25544: "null",
    25545: "Celestial signet",
    25546: "null",
    25547: "Star fragment",
    25548: "null",
    25549: "Golden prospector helmet",
    25550: "null",
    25551: "Golden prospector jacket",
    25552: "null",
    25553: "Golden prospector legs",
    25554: "null",
    25555: "Golden prospector boots",
    25556: "null",
    25557: "Dark flippers",
    25558: "null",
    25559: "Big harpoonfish",
    25560: "null",
    25561: "Stuffed big harpoonfish",
    25562: "null",
    25563: "Mounted harpoonfish",
    25564: "Raw harpoonfish",
    25565: "Harpoonfish",
    25566: "Crystallised harpoonfish",
    25567: "The desert trout - ship's log",
    25568: "null",
    25569: "Spirit anglers research notes",
    25570: "null",
    25571: "Damp egg",
    25572: "null",
    25573: "null",
    25574: "Tome of water",
    25575: "null",
    25576: "Tome of water (empty)",
    25577: "null",
    25578: "Soaked page",
    25579: "null",
    25580: "Tackle box",
    25581: "null",
    25582: "Fish barrel",
    25583: "null",
    25584: "Open fish barrel",
    25585: "Fish sack barrel",
    25586: "null",
    25587: "Open fish sack barrel",
    25588: "Spirit flakes",
    25589: "null",
    25590: "Casket",
    25591: "null",
    25592: "Spirit angler headband",
    25593: "null",
    25594: "Spirit angler top",
    25595: "null",
    25596: "Spirit angler waders",
    25597: "null",
    25598: "Spirit angler boots",
    25599: "null",
    25600: "Great blue heron",
    25601: "null",
    25602: "Tiny tempor",
    25603: "null",
    25604: "Gregg's eastdoor",
    25605: "null",
    25606: "Propeller hat",
    25607: "null",
    25608: "Gregg's iou",
    25609: "Pastel flowers",
    25610: "Thick dye",
    25611: "S.t.a.s.h chart",
    25612: "S.t.a.s.h blueprint",
    25613: "Baby mole-rat",
    25614: "null",
    25615: "Large water container",
    25616: "null",
    25617: "Tea flask",
    25618: "Plain satchel",
    25619: "Green satchel",
    25620: "Red satchel",
    25621: "Black satchel",
    25622: "Gold satchel",
    25623: "Rune satchel",
    25624: "Unsired",
    25625: "Barronite mace (broken)",
    25626: "null",
    25627: "Coal bag",
    25628: "Gem bag",
    25629: "Plank sack",
    25630: "Flamtaer bag",
    25631: "Steak sandwich",
    25632: "null",
    25633: "Imcando hammer (broken)",
    25634: "null",
    25635: "Barronite head",
    25636: "null",
    25637: "Barronite handle",
    25638: "null",
    25639: "Barronite guard",
    25640: "null",
    25641: "Barronite mace",
    25642: "null",
    25643: "Barronite mace (l)",
    25644: "Imcando hammer",
    25645: "null",
    25646: "Simple lockbox",
    25647: "Simple lockbox",
    25648: "Elaborate lockbox",
    25649: "Elaborate lockbox",
    25650: "Ornate lockbox",
    25651: "Ornate lockbox",
    25652: "Raw guppy",
    25653: "null",
    25654: "Guppy",
    25655: "null",
    25656: "Ruined guppy",
    25657: "null",
    25658: "Raw cavefish",
    25659: "null",
    25660: "Cavefish",
    25661: "null",
    25662: "Ruined cavefish",
    25663: "null",
    25664: "Raw tetra",
    25665: "null",
    25666: "Tetra",
    25667: "null",
    25668: "Ruined tetra",
    25669: "null",
    25670: "Raw catfish",
    25671: "null",
    25672: "Catfish",
    25673: "null",
    25674: "Ruined catfish",
    25675: "null",
    25676: "Barronite shards",
    25677: "null",
    25678: "null",
    25679: "null",
    25680: "null",
    25681: "null",
    25682: "null",
    25683: "null",
    25684: "Barronite deposit",
    25685: "null",
    25686: "Ancient globe",
    25687: "null",
    25688: "Ancient ledger",
    25689: "null",
    25690: "Ancient astroscope",
    25691: "null",
    25692: "Ancient treatise",
    25693: "null",
    25694: "Ancient carcanet",
    25695: "null",
    25696: "Mind core",
    25697: "null",
    25698: "Body core",
    25699: "null",
    25700: "Chaos core",
    25701: "null",
    25702: "Ungael lab notes",
    25703: "null",
    25704: "Lithkren vault notes",
    25705: "null",
    25706: "Dusty note",
    25707: "null",
    25708: "Board game piece",
    25709: "null",
    25710: "Stool",
    25711: "Stool",
    25712: "Clan cloak",
    25713: "null",
    25714: "Clan cloak",
    25715: "Clan cloak",
    25716: "Clan cloak",
    25717: "Clan cloak",
    25718: "Clan cloak",
    25719: "Clan cloak",
    25720: "Clan cloak",
    25721: "Clan vexillum",
    25722: "null",
    25723: "Clan vexillum",
    25724: "Clan vexillum",
    25725: "Clan vexillum",
    25726: "Clan vexillum",
    25727: "Clan vexillum",
    25728: "Clan vexillum",
    25729: "Clan vexillum",
    25730: "Bandages",
    25731: "Holy sanguinesti staff",
    25732: "null",
    25733: "Holy sanguinesti staff (uncharged)",
    25734: "Holy ghrazi rapier",
    25735: "null",
    25736: "Holy scythe of vitur",
    25737: "null",
    25738: "Holy scythe of vitur (uncharged)",
    25739: "Sanguine scythe of vitur",
    25740: "null",
    25741: "Sanguine scythe of vitur (uncharged)",
    25742: "Holy ornament kit",
    25743: "null",
    25744: "Sanguine ornament kit",
    25745: "null",
    25746: "Sanguine dust",
    25747: "null",
    25748: "Lil' maiden",
    25749: "Lil' bloat",
    25750: "Lil' nylo",
    25751: "Lil' sot",
    25752: "Lil' xarp",
    25753: "Antique lamp",
    25754: "Antipoison (-)(1)",
    25755: "Antipoison (-)(2)",
    25756: "Antipoison (-)(3)",
    25757: "Antipoison (-)(4)",
    25758: "Antipoison potion (1)",
    25759: "Antipoison potion (2)",
    25760: "Antipoison potion (3)",
    25761: "Antipoison potion (4)",
    25762: "Antipoison (+)(1)",
    25763: "Antipoison (+)(2)",
    25764: "Antipoison (+)(3)",
    25765: "Antipoison (+)(4)",
    25766: "Fiendish ashes",
    25767: "null",
    25768: "null",
    25769: "Vile ashes",
    25770: "null",
    25771: "null",
    25772: "Malicious ashes",
    25773: "null",
    25774: "null",
    25775: "Abyssal ashes",
    25776: "null",
    25777: "null",
    25778: "Infernal ashes",
    25779: "null",
    25780: "null",
    25781: "Ash sanctifier",
    25782: "null",
    25783: "Clue scroll (medium)",
    25784: "Clue scroll (medium)",
    25785: "Challenge scroll (medium)",
    25786: "Clue scroll (elite)",
    25787: "Clue scroll (elite)",
    25788: "Clue scroll (easy)",
    25789: "Clue scroll (easy)",
    25790: "Clue scroll (hard)",
    25791: "Clue scroll (hard)",
    25792: "Clue scroll (hard)",
    25793: "Receipt",
    25794: "Bone",
    25795: "Rose",
    25796: "Delivery confirmation",
    25797: "Order form",
    25798: "Demonic incantations",
    25799: "Bloody knife",
    25800: "Cultist robe",
    25801: "Kourend map",
    25802: "Rose's diary",
    25803: "Bluish key",
    25804: "Cold key",
    25805: "Rose's note",
    25806: "Rose's note",
    25807: "Rose's note",
    25808: "Rose's note",
    25809: "Lizardman egg",
    25810: "Damp key",
    25811: "Broken redirector",
    25812: "Sulphur potion",
    25813: "Shielding potion",
    25814: "Declaration",
    25815: "Dark nullifier",
    25816: "Shayzien journal",
    25817: "Royal accord of twill",
    25818: "Book of the dead",
    25819: "null",
    25820: "Antique lamp",
    25821: "null",
    25822: "Protest banner",
    25823: "null",
    25824: "Research notes",
    25825: "null",
    25826: "Lizardkicker",
    25827: "null",
    25828: "null",
    25829: "Old note",
    25830: "null",
    25831: "Shayzia military orders",
    25832: "null",
    25833: "Raw boar meat",
    25834: "null",
    25835: "null",
    25836: "Little parasite",
    25837: "Slepey tablet",
    25838: "Parasitic egg",
    25839: "null",
    25840: "Banana hat",
    25841: "null",
    25842: "Sraracha",
    25843: "Sraracha",
    25844: "Orange egg sac",
    25845: "null",
    25846: "Blue egg sac",
    25847: "null",
    25848: "null",
    25849: "Amethyst dart",
    25850: "null",
    25851: "Amethyst dart(p)",
    25852: "null",
    25853: "Amethyst dart tip",
    25854: "null",
    25855: "Amethyst dart(p+)",
    25856: "null",
    25857: "Amethyst dart(p++)",
    25858: "null",
    25859: "Enhanced crystal weapon seed",
    25860: "null",
    25861: "null",
    25862: "Bow of faerdhinen (inactive)",
    25863: "null",
    25864: "null",
    25865: "Bow of faerdhinen",
    25866: "null",
    25867: "Bow of faerdhinen (c)",
    25868: "null",
    25869: "Bow of faerdhinen (c)",
    25870: "Blade of saeldor (c)",
    25871: "null",
    25872: "Blade of saeldor (c)",
    25873: "null",
    25874: "Blade of saeldor (c)",
    25875: "null",
    25876: "Blade of saeldor (c)",
    25877: "null",
    25878: "Blade of saeldor (c)",
    25879: "null",
    25880: "Blade of saeldor (c)",
    25881: "null",
    25882: "Blade of saeldor (c)",
    25883: "null",
    25884: "Bow of faerdhinen (c)",
    25885: "null",
    25886: "Bow of faerdhinen (c)",
    25887: "null",
    25888: "Bow of faerdhinen (c)",
    25889: "null",
    25890: "Bow of faerdhinen (c)",
    25891: "null",
    25892: "Bow of faerdhinen (c)",
    25893: "null",
    25894: "Bow of faerdhinen (c)",
    25895: "null",
    25896: "Bow of faerdhinen (c)",
    25897: "null",
    25898: "Tztok slayer helmet",
    25899: "null",
    25900: "Tztok slayer helmet (i)",
    25901: "null",
    25902: "Tztok slayer helmet (i)",
    25903: "null",
    25904: "Vampyric slayer helmet",
    25905: "null",
    25906: "Vampyric slayer helmet (i)",
    25907: "null",
    25908: "Vampyric slayer helmet (i)",
    25909: "null",
    25910: "Tzkal slayer helmet",
    25911: "null",
    25912: "Tzkal slayer helmet (i)",
    25913: "null",
    25914: "Tzkal slayer helmet (i)",
    25915: "null",
    25916: "Dragon hunter crossbow (t)",
    25917: "null",
    25918: "Dragon hunter crossbow (b)",
    25919: "null",
    25920: "Antique lamp",
    25921: "Antique lamp",
    25922: "Antique lamp",
    25923: "Antique lamp",
    25924: "Antique lamp",
    25925: "Antique lamp",
    25926: "Ghommal's hilt 1",
    25927: "null",
    25928: "Ghommal's hilt 2",
    25929: "null",
    25930: "Ghommal's hilt 3",
    25931: "null",
    25932: "Ghommal's hilt 4",
    25933: "null",
    25934: "Ghommal's hilt 5",
    25935: "null",
    25936: "Ghommal's hilt 6",
    25937: "null",
    25938: "Anim offhand",
    25939: "Null",
    25940: "null",
    25941: "Anim offhand",
    25942: "Null",
    25943: "null",
    25944: "Anim offhand",
    25945: "Null",
    25946: "null",
    25947: "Anim offhand",
    25948: "Null",
    25949: "null",
    25950: "Anim offhand",
    25951: "Null",
    25952: "null",
    25953: "Anim offhand",
    25954: "Null",
    25955: "null",
    25956: "Combat achievements",
    25957: "null",
    25958: "Corrupted paddlefish",
    25959: "Corrupted escape crystal",
    25960: "Crystal paddlefish",
    25961: "Escape crystal",
    25962: "null",
    25963: "Crypt key",
    25964: "Ranis' head",
    25965: "Strange spider eggs",
    25966: "Sulphuric acid",
    25967: "Sticky note",
    25968: "Hespori bark",
    25969: "Null",
    25970: "Null",
    25971: "Null",
    25972: "Null",
    25973: "Null",
    25974: "Null",
    25975: "Lightbearer",
    25976: "null",
    25977: "Null",
    25978: "Null",
    25979: "Keris partisan",
    25980: "null",
    25981: "Keris partisan of breaching",
    25982: "null",
    25983: "Null",
    25984: "Null",
    25985: "Elidinis' ward",
    25986: "null",
    25987: "Null",
    25988: "Null",
    25989: "Null",
    25990: "Sigil of resilience",
    25991: "Sigil of resilience",
    25992: "null",
    25993: "Sigil of consistency",
    25994: "Sigil of consistency",
    25995: "null",
    25996: "Sigil of the formidable fighter",
    25997: "Sigil of the formidable fighter",
    25998: "null",
    25999: "Sigil of the rigorous ranger",
    26000: "Sigil of the rigorous ranger",
    26001: "null",
    26002: "Sigil of the meticulous mage",
    26003: "Sigil of the meticulous mage",
    26004: "null",
    26005: "Sigil of fortification",
    26006: "Sigil of fortification",
    26007: "null",
    26008: "Sigil of barrows",
    26009: "Sigil of barrows",
    26010: "null",
    26011: "Sigil of deft strikes",
    26012: "Sigil of deft strikes",
    26013: "null",
    26014: "Sigil of freedom",
    26015: "Sigil of freedom",
    26016: "null",
    26017: "Sigil of enhanced harvest",
    26018: "Sigil of enhanced harvest",
    26019: "null",
    26020: "Sigil of storage",
    26021: "Sigil of storage",
    26022: "null",
    26023: "Sigil of the smith",
    26024: "Sigil of the smith",
    26025: "null",
    26026: "Sigil of the alchemist",
    26027: "Sigil of the alchemist",
    26028: "null",
    26029: "Sigil of the fletcher",
    26030: "Sigil of the fletcher",
    26031: "null",
    26032: "Sigil of the chef",
    26033: "Sigil of the chef",
    26034: "null",
    26035: "Sigil of the craftsman",
    26036: "Sigil of the craftsman",
    26037: "null",
    26038: "Sigil of the abyss",
    26039: "Sigil of the abyss",
    26040: "null",
    26041: "Sigil of stamina",
    26042: "Sigil of stamina",
    26043: "null",
    26044: "Sigil of the potion master",
    26045: "Sigil of the potion master",
    26046: "null",
    26047: "Sigil of the eternal jeweller",
    26048: "Sigil of the eternal jeweller",
    26049: "null",
    26050: "Sigil of the treasure hunter",
    26051: "Sigil of the treasure hunter",
    26052: "null",
    26053: "Sigil of mobility",
    26054: "Sigil of mobility",
    26055: "null",
    26056: "Sigil of exaggeration",
    26057: "Sigil of exaggeration",
    26058: "null",
    26059: "Sigil of specialised strikes",
    26060: "Sigil of specialised strikes",
    26061: "null",
    26062: "Sigil of the porcupine",
    26063: "Sigil of the porcupine",
    26064: "null",
    26065: "Sigil of binding",
    26066: "Sigil of binding",
    26067: "null",
    26068: "Sigil of escaping",
    26069: "Sigil of escaping",
    26070: "null",
    26071: "Sigil of the ruthless ranger",
    26072: "Sigil of the ruthless ranger",
    26073: "null",
    26074: "Sigil of the feral fighter",
    26075: "Sigil of the feral fighter",
    26076: "null",
    26077: "Sigil of the menacing mage",
    26078: "Sigil of the menacing mage",
    26079: "null",
    26080: "Sigil of prosperity",
    26081: "Sigil of prosperity",
    26082: "null",
    26083: "Sigil of the dwarves",
    26084: "Sigil of the dwarves",
    26085: "null",
    26086: "Sigil of the elves",
    26087: "Sigil of the elves",
    26088: "null",
    26089: "Sigil of the barbarians",
    26090: "Sigil of the barbarians",
    26091: "null",
    26092: "Sigil of the gnomes",
    26093: "Sigil of the gnomes",
    26094: "null",
    26095: "Sigil of nature",
    26096: "Sigil of nature",
    26097: "null",
    26098: "Sigil of devotion",
    26099: "Sigil of devotion",
    26100: "null",
    26101: "Sigil of the forager",
    26102: "Sigil of the forager",
    26103: "null",
    26104: "Sigil of garments",
    26105: "Sigil of garments",
    26106: "null",
    26107: "Sigil of slaughter",
    26108: "Sigil of slaughter",
    26109: "null",
    26110: "Sigil of the fortune farmer",
    26111: "Sigil of the fortune farmer",
    26112: "null",
    26113: "Sigil of versatility",
    26114: "Sigil of versatility",
    26115: "null",
    26116: "Sigil of the serpent",
    26117: "Sigil of the serpent",
    26118: "null",
    26119: "Sigil of supreme stamina",
    26120: "Sigil of supreme stamina",
    26121: "null",
    26122: "Sigil of preservation",
    26123: "Sigil of preservation",
    26124: "null",
    26125: "Sigil of finality",
    26126: "Sigil of finality",
    26127: "null",
    26128: "Sigil of pious protection",
    26129: "Sigil of pious protection",
    26130: "null",
    26131: "Sigil of aggression",
    26132: "Sigil of aggression",
    26133: "null",
    26134: "Sigil of rampage",
    26135: "Sigil of rampage",
    26136: "null",
    26137: "Sigil of the skiller",
    26138: "Sigil of the skiller",
    26139: "null",
    26140: "Sigil of remote storage",
    26141: "Sigil of remote storage",
    26142: "null",
    26143: "Sigil of last recall",
    26144: "Sigil of last recall",
    26145: "null",
    26146: "Sigil of the guardian angel",
    26147: "Sigil of the guardian angel",
    26148: "null",
    26149: "Tuna",
    26150: "Combat potion(4)",
    26151: "Combat potion(3)",
    26152: "Combat potion(2)",
    26153: "Combat potion(1)",
    26154: "Mount karuulm diary",
    26155: "null",
    26156: "Group ironman helm",
    26157: "null",
    26158: "Group ironman platebody",
    26159: "null",
    26160: "Group ironman platebody",
    26161: "null",
    26162: "Group ironman platebody",
    26163: "null",
    26164: "Group ironman platebody",
    26165: "null",
    26166: "Group ironman platelegs",
    26167: "null",
    26168: "Group ironman bracers",
    26169: "null",
    26170: "Hardcore group ironman helm",
    26171: "null",
    26172: "Hardcore group ironman platebody",
    26173: "null",
    26174: "Hardcore group ironman platebody",
    26175: "null",
    26176: "Hardcore group ironman platebody",
    26177: "null",
    26178: "Hardcore group ironman platebody",
    26179: "null",
    26180: "Hardcore group ironman platelegs",
    26181: "null",
    26182: "Hardcore group ironman bracers",
    26183: "null",
    26184: "Blue icon",
    26185: "Blue icon",
    26186: "Blue icon",
    26187: "Blue icon",
    26188: "Blue icon",
    26189: "Blue icon",
    26190: "Blue icon",
    26191: "Blue icon",
    26192: "Blue icon",
    26193: "Blue icon",
    26194: "Blue icon",
    26195: "Blue icon",
    26196: "Blue icon",
    26197: "Blue icon",
    26198: "Blue icon",
    26199: "Blue icon",
    26200: "Red icon",
    26201: "Red icon",
    26202: "Red icon",
    26203: "Red icon",
    26204: "Red icon",
    26205: "Red icon",
    26206: "Red icon",
    26207: "Red icon",
    26208: "Red icon",
    26209: "Red icon",
    26210: "Red icon",
    26211: "Red icon",
    26212: "Red icon",
    26213: "Red icon",
    26214: "Red icon",
    26215: "Red icon",
    26216: "Null",
    26217: "Null",
    26218: "Null",
    26219: "Osmumten's fang",
    26220: "null",
    26221: "Ancient ceremonial top",
    26222: "null",
    26223: "Ancient ceremonial legs",
    26224: "null",
    26225: "Ancient ceremonial mask",
    26226: "null",
    26227: "Ancient ceremonial gloves",
    26228: "null",
    26229: "Ancient ceremonial boots",
    26230: "null",
    26231: "Nihil shard",
    26232: "null",
    26233: "Ancient godsword",
    26234: "null",
    26235: "Zaryte vambraces",
    26236: "null",
    26237: "Zaryte bow",
    26238: "null",
    26239: "Zaryte bow",
    26240: "null",
    26241: "Virtus mask",
    26242: "null",
    26243: "Virtus robe top",
    26244: "null",
    26245: "Virtus robe bottom",
    26246: "null",
    26247: "Pumpkin pie",
    26248: "Null",
    26249: "null",
    26250: "Jered's empty wine bottle",
    26251: "null",
    26252: "Ad coupon",
    26253: "null",
    26254: "Saucepan",
    26255: "null",
    26256: "Ugly halloween jumper (orange)",
    26257: "null",
    26258: "Ugly halloween jumper (black)",
    26259: "null",
    26260: "Haunted wine bottle",
    26261: "null",
    26262: "Rune scimitar",
    26263: "null",
    26264: "Studded body",
    26265: "null",
    26266: "Condensed gold",
    26267: "null",
    26268: "null",
    26269: "Gold sink",
    26270: "Festive cinnamon",
    26271: "null",
    26272: "Festive holly",
    26273: "null",
    26274: "Festive white wine",
    26275: "null",
    26276: "Notes",
    26277: "null",
    26278: "Magical cleaning potion",
    26279: "null",
    26280: "Pink stained platebody",
    26281: "null",
    26282: "Pink stained platelegs",
    26283: "null",
    26284: "Pink stained full helm",
    26285: "null",
    26286: "Clean platebody",
    26287: "null",
    26288: "Clean platelegs",
    26289: "null",
    26290: "Clean full helm",
    26291: "null",
    26292: "Festive gingerbread gnomes",
    26293: "null",
    26294: "Festive mulled wine",
    26295: "null",
    26296: "Snow",
    26297: "null",
    26298: "Secret santa present",
    26299: "null",
    26300: "Secret santa present",
    26301: "null",
    26302: "Secret santa present",
    26303: "null",
    26304: "Chocolate chips",
    26305: "null",
    26306: "Chocolate chips",
    26307: "null",
    26308: "A big present",
    26309: "null",
    26310: "Festive elf slippers",
    26311: "null",
    26312: "Festive elf hat",
    26313: "null",
    26314: "Snowman ring",
    26315: "null",
    26316: "Secret santa present (red)",
    26317: "null",
    26318: "Secret santa present (blue)",
    26319: "null",
    26320: "Secret santa present (green)",
    26321: "null",
    26322: "Secret santa present (black)",
    26323: "null",
    26324: "Secret santa present (gold)",
    26325: "null",
    26326: "Little snowball",
    26327: "null",
    26328: "Small snowball",
    26329: "null",
    26330: "Normal snowball",
    26331: "null",
    26332: "Big snowball",
    26333: "null",
    26334: "Large snowball",
    26335: "null",
    26336: "Huge snowball",
    26337: "null",
    26338: "Humongous snowball",
    26339: "null",
    26340: "Ancient brew(4)",
    26341: "null",
    26342: "Ancient brew(3)",
    26343: "null",
    26344: "Ancient brew(2)",
    26345: "null",
    26346: "Ancient brew(1)",
    26347: "null",
    26348: "Nexling",
    26349: "null",
    26350: "Ancient mix(2)",
    26351: "null",
    26352: "null",
    26353: "Ancient mix(1)",
    26354: "null",
    26355: "null",
    26356: "Frozen key",
    26357: "null",
    26358: "Frozen key piece (armadyl)",
    26359: "null",
    26360: "Frozen key piece (bandos)",
    26361: "null",
    26362: "Frozen key piece (zamorak)",
    26363: "null",
    26364: "Frozen key piece (saradomin)",
    26365: "null",
    26366: "Important letter",
    26367: "null",
    26368: "Nihil dust",
    26369: "null",
    26370: "Ancient hilt",
    26371: "null",
    26372: "Nihil horn",
    26373: "null",
    26374: "Zaryte crossbow",
    26375: "null",
    26376: "Torva full helm (damaged)",
    26377: "null",
    26378: "Torva platebody (damaged)",
    26379: "null",
    26380: "Torva platelegs (damaged)",
    26381: "null",
    26382: "Torva full helm",
    26383: "null",
    26384: "Torva platebody",
    26385: "null",
    26386: "Torva platelegs",
    26387: "null",
    26388: "Ecumenical key shard",
    26389: "null",
    26390: "Blood essence",
    26391: "null",
    26392: "Blood essence (active)",
    26393: "null",
    26394: "Bandosian components",
    26395: "null",
    26396: "null",
    26397: "null",
    26398: "null",
    26399: "null",
    26400: "null",
    26401: "null",
    26402: "null",
    26403: "null",
    26404: "null",
    26405: "null",
    26406: "null",
    26407: "null",
    26408: "null",
    26409: "null",
    26410: "null",
    26411: "null",
    26412: "null",
    26413: "null",
    26414: "null",
    26415: "null",
    26416: "null",
    26417: "Cabbage",
    26418: "null",
    26419: "Cabbage",
    26420: "null",
    26421: "Shattered relics variety ornament kit",
    26422: "null",
    26423: "null",
    26424: "Shattered banner",
    26425: "null",
    26426: "null",
    26427: "Shattered hood (t1)",
    26428: "null",
    26429: "null",
    26430: "Shattered top (t1)",
    26431: "null",
    26432: "null",
    26433: "Shattered trousers (t1)",
    26434: "null",
    26435: "null",
    26436: "Shattered boots (t1)",
    26437: "null",
    26438: "null",
    26439: "Shattered hood (t2)",
    26440: "null",
    26441: "null",
    26442: "Shattered top (t2)",
    26443: "null",
    26444: "null",
    26445: "Shattered trousers (t2)",
    26446: "null",
    26447: "null",
    26448: "Shattered boots (t2)",
    26449: "null",
    26450: "null",
    26451: "Shattered hood (t3)",
    26452: "null",
    26453: "null",
    26454: "Shattered top (t3)",
    26455: "null",
    26456: "null",
    26457: "Shattered trousers (t3)",
    26458: "null",
    26459: "null",
    26460: "Shattered boots (t3)",
    26461: "null",
    26462: "null",
    26463: "Void knight top (or)",
    26464: "null",
    26465: "Void knight robe (or)",
    26466: "null",
    26467: "Void knight gloves (or)",
    26468: "null",
    26469: "Elite void top (or)",
    26470: "null",
    26471: "Elite void robe (or)",
    26472: "null",
    26473: "Void mage helm (or)",
    26474: "null",
    26475: "Void ranger helm (or)",
    26476: "null",
    26477: "Void melee helm (or)",
    26478: "null",
    26479: "Shattered relics void ornament kit",
    26480: "null",
    26481: "null",
    26482: "Abyssal whip (or)",
    26483: "null",
    26484: "Abyssal tentacle (or)",
    26485: "null",
    26486: "Rune crossbow (or)",
    26487: "null",
    26488: "Book of balance (or)",
    26489: "null",
    26490: "Book of darkness (or)",
    26491: "null",
    26492: "Book of law (or)",
    26493: "null",
    26494: "Book of war (or)",
    26495: "null",
    26496: "Holy book (or)",
    26497: "null",
    26498: "Unholy book (or)",
    26499: "null",
    26500: "Shattered teleport scroll",
    26501: "null",
    26502: "null",
    26503: "Shattered relics bronze trophy",
    26504: "null",
    26505: "Shattered relics iron trophy",
    26506: "null",
    26507: "Shattered relics steel trophy",
    26508: "null",
    26509: "Shattered relics mithril trophy",
    26510: "null",
    26511: "Shattered relics adamant trophy",
    26512: "null",
    26513: "Shattered relics rune trophy",
    26514: "null",
    26515: "Shattered relics dragon trophy",
    26516: "null",
    26517: "Shattered cane",
    26518: "null",
    26519: "null",
    26520: "Cannon base (or)",
    26521: "null",
    26522: "Cannon stand (or)",
    26523: "null",
    26524: "Cannon barrels (or)",
    26525: "null",
    26526: "Cannon furnace (or)",
    26527: "null",
    26528: "Shattered cannon ornament kit",
    26529: "null",
    26530: "null",
    26531: "Mystic hat (or)",
    26532: "null",
    26533: "Mystic robe top (or)",
    26534: "null",
    26535: "Mystic robe bottom (or)",
    26536: "null",
    26537: "Mystic gloves (or)",
    26538: "null",
    26539: "Mystic boots (or)",
    26540: "null",
    26541: "Shattered relics mystic ornament kit",
    26542: "null",
    26543: "null",
    26544: "Unidentified fragment (harvesting)",
    26545: "Unidentified fragment (production)",
    26546: "Unidentified fragment (skilling)",
    26547: "Unidentified fragment (combat)",
    26548: "Unidentified fragment (misc)",
    26549: "Portable waystone",
    26550: "null",
    26551: "Arcane grimoire",
    26552: "null",
    26553: "null",
    26554: "Shattered relic hunter (t1) armour set",
    26555: "null",
    26556: "null",
    26557: "Shattered relic hunter (t2) armour set",
    26558: "null",
    26559: "null",
    26560: "Shattered relic hunter (t3) armour set",
    26561: "null",
    26562: "null",
    26563: "null",
    26564: "null",
    26565: "null",
    26566: "Clue scroll (hard)",
    26567: "White goblin mail",
    26568: "null",
    26569: "Pharmakos berries",
    26570: "null",
    26571: "Ekeleshuun key",
    26572: "Narogoshuun key",
    26573: "Huzamogaarb key",
    26574: "Saragorgak key",
    26575: "Horogothgar key",
    26576: "Yurkolgokh key",
    26577: "Plain of mud sphere",
    26578: "null",
    26579: "Whitefish",
    26580: "null",
    26581: "Goblin potion(4)",
    26582: "null",
    26583: "Goblin potion(3)",
    26584: "null",
    26585: "Goblin potion(2)",
    26586: "null",
    26587: "Goblin potion(1)",
    26588: "null",
    26589: "Snothead's bone",
    26590: "Snailfeet's bone",
    26591: "Mosschin's bone",
    26592: "Redeyes' bone",
    26593: "Strongbones' bone",
    26594: "Grubfoot",
    26595: "Zanik",
    26596: "Notes",
    26597: "null",
    26598: "Desert bait",
    26599: "null",
    26600: "Tiny fish",
    26601: "null",
    26602: "Osman's report",
    26603: "null",
    26604: "null",
    26605: "Treasure clue one",
    26606: "null",
    26607: "Treasure clue two",
    26608: "null",
    26609: "Treasure clue three",
    26610: "null",
    26611: "Zeke's challenge scroll",
    26612: "null",
    26613: "Capt' arnav's chest",
    26614: "null",
    26615: "Bucket (cookout)",
    26616: "null",
    26617: "Bucket of water (cookout)",
    26618: "null",
    26619: "Pot (cookout)",
    26620: "null",
    26621: "Pot of flour (cookout)",
    26622: "null",
    26623: "Tomato (cookout)",
    26624: "null",
    26625: "Cheese (cookout)",
    26626: "null",
    26627: "Banana (cookout)",
    26628: "null",
    26629: "Pizza base (cookout)",
    26630: "null",
    26631: "Incomplete pizza (cookout)",
    26632: "null",
    26633: "Uncooked pizza (cookout)",
    26634: "null",
    26635: "Plain pizza (cookout)",
    26636: "null",
    26637: "Burnt pizza (cookout)",
    26638: "null",
    26639: "Bread dough (cookout)",
    26640: "null",
    26641: "Bread (cookout)",
    26642: "null",
    26643: "Burnt bread (cookout)",
    26644: "null",
    26645: "Banana pizza (cookout)",
    26646: "null",
    26647: "Burnt banana pizza (cookout)",
    26648: "null",
    26649: "Skis",
    26650: "null",
    26651: "Loot key",
    26652: "Loot key",
    26653: "Loot key",
    26654: "Loot key",
    26655: "Loot key",
    26656: "Shoe",
    26657: "Shoe",
    26658: "Shoe",
    26659: "Shoe",
    26660: "Shoe",
    26661: "Shoe.",
    26662: "Shoe",
    26663: "Shoe",
    26664: "Shoe",
    26665: "Shoe",
    26666: "Shoe",
    26667: "Shoe.",
    26668: "Shoe",
    26669: "Shoe",
    26670: "Shoe",
    26671: "Shoe",
    26672: "Shoe",
    26673: "Shoe",
    26674: "Slayer helmet (i)",
    26675: "Black slayer helmet (i)",
    26676: "Green slayer helmet (i)",
    26677: "Red slayer helmet (i)",
    26678: "Purple slayer helmet (i)",
    26679: "Turquoise slayer helmet (i)",
    26680: "Hydra slayer helmet (i)",
    26681: "Twisted slayer helmet (i)",
    26682: "Tztok slayer helmet (i)",
    26683: "Vampyric slayer helmet (i)",
    26684: "Tzkal slayer helmet (i)",
    26685: "Granite ring (i)",
    26686: "Maoma's med helm (broken)",
    26687: "Maoma's full helm (broken)",
    26688: "Maoma's great helm (broken)",
    26689: "Calamity chest (broken)",
    26690: "Superior calamity chest (broken)",
    26691: "Elite calamity chest (broken)",
    26692: "Calamity breeches (broken)",
    26693: "Superior calamity breeches (broken)",
    26694: "Elite calamity breeches (broken)",
    26695: "Centurion cuirass (broken)",
    26696: "Wristbands of the arena (broken)",
    26697: "Hardened wristbands of the arena (broken)",
    26698: "Koriff's headband (broken)",
    26699: "Koriff's cowl (broken)",
    26700: "Koriff's coif (broken)",
    26701: "Saika's hood (broken)",
    26702: "Saika's veil (broken)",
    26703: "Saika's shroud (broken)",
    26704: "Null",
    26705: "Blighted surge sack",
    26706: "Scroll of imbuing",
    26707: "Dragon claws ornament kit",
    26708: "Dragon claws (or)",
    26709: "Dragon warhammer ornament kit",
    26710: "Dragon warhammer (or)",
    26711: "Heavy ballista ornament kit",
    26712: "Heavy ballista (or)",
    26713: "Armadyl armour ornament kit",
    26714: "Armadyl helmet (or)",
    26715: "Armadyl chestplate (or)",
    26716: "Armadyl chainskirt (or)",
    26717: "Bandos armour ornament kit",
    26718: "Bandos chestplate (or)",
    26719: "Bandos tassets (or)",
    26720: "Bandos boots (or)",
    26721: "Centurion cuirass",
    26722: "Centurion cuirass (l)",
    26723: "Wristbands of the arena",
    26724: "Wristbands of the arena (l)",
    26725: "Wristbands of the arena (c)",
    26726: "Wristbands of the arena (cl)",
    26727: "Wristbands of the arena (i)",
    26728: "Wristbands of the arena (il)",
    26729: "Wristbands of the arena (ic)",
    26730: "Wristbands of the arena (ilc)",
    26731: "Saika's hood",
    26732: "Saika's hood (l)",
    26733: "Saika's veil",
    26734: "Saika's veil (l)",
    26735: "Saika's shroud",
    26736: "Saika's shroud (l)",
    26737: "Koriff's headband",
    26738: "Koriff's headband (l)",
    26739: "Koriff's cowl",
    26740: "Koriff's cowl (l)",
    26741: "Koriff's coif",
    26742: "Koriff's coif (l)",
    26743: "Maoma's med helm",
    26744: "Maoma's med helm (l)",
    26745: "Maoma's full helm",
    26746: "Maoma's full helm (l)",
    26747: "Maoma's great helm",
    26748: "Maoma's great helm (l)",
    26749: "Calamity chest",
    26750: "Calamity chest (l)",
    26751: "Superior calamity chest",
    26752: "Superior calamity chest (l)",
    26753: "Elite calamity chest",
    26754: "Elite calamity chest (l)",
    26755: "Calamity breeches",
    26756: "Calamity breeches (l)",
    26757: "Superior calamity breeches",
    26758: "Superior calamity breeches (l)",
    26759: "Elite calamity breeches",
    26760: "Elite calamity breeches (l)",
    26761: "Ring of suffering (i)",
    26762: "Ring of suffering (ri)",
    26763: "Salve amulet(i)",
    26764: "Ring of the gods (i)",
    26765: "Tyrannical ring (i)",
    26766: "Treasonous ring (i)",
    26767: "Seers ring (i)",
    26768: "Archers ring (i)",
    26769: "Warrior ring (i)",
    26770: "Berserker ring (i)",
    26771: "Black mask (10) (i)",
    26772: "Black mask (9) (i)",
    26773: "Black mask (8) (i)",
    26774: "Black mask (7) (i)",
    26775: "Black mask (6) (i)",
    26776: "Black mask (5) (i)",
    26777: "Black mask (4) (i)",
    26778: "Black mask (3) (i)",
    26779: "Black mask (2) (i)",
    26780: "Black mask (1) (i)",
    26781: "Black mask (i)",
    26782: "Salve amulet(ei)",
    26783: "null",
    26784: "Colossal pouch",
    26785: "null",
    26786: "Colossal pouch",
    26787: "null",
    26788: "Gold tiara",
    26789: "null",
    26790: "null",
    26791: "null",
    26792: "Abyssal pearls",
    26793: "null",
    26794: "null",
    26795: "null",
    26796: "null",
    26797: "null",
    26798: "Catalytic talisman",
    26799: "null",
    26800: "null",
    26801: "Catalytic tiara",
    26802: "null",
    26803: "null",
    26804: "Elemental tiara",
    26805: "null",
    26806: "null",
    26807: "Abyssal green dye",
    26808: "null",
    26809: "Abyssal blue dye",
    26810: "null",
    26811: "Abyssal red dye",
    26812: "null",
    26813: "Abyssal needle",
    26814: "null",
    26815: "Ring of the elements",
    26816: "null",
    26817: "null",
    26818: "Ring of the elements",
    26819: "null",
    26820: "Guardian's eye",
    26821: "null",
    26822: "Abyssal lantern",
    26823: "null",
    26824: "Abyssal lantern (normal logs)",
    26825: "null",
    26826: "Abyssal lantern (blue logs)",
    26827: "null",
    26828: "Abyssal lantern (red logs)",
    26829: "null",
    26830: "Abyssal lantern (white logs)",
    26831: "null",
    26832: "Abyssal lantern (purple logs)",
    26833: "null",
    26834: "Abyssal lantern (green logs)",
    26835: "null",
    26836: "Abyssal lantern (oak logs)",
    26837: "null",
    26838: "Abyssal lantern (willow logs)",
    26839: "null",
    26840: "Abyssal lantern (maple logs)",
    26841: "null",
    26842: "Abyssal lantern (yew logs)",
    26843: "null",
    26844: "Abyssal lantern (blisterwood logs)",
    26845: "null",
    26846: "Abyssal lantern (magic logs)",
    26847: "null",
    26848: "Abyssal lantern (redwood logs)",
    26849: "null",
    26850: "Hat of the eye",
    26851: "null",
    26852: "Robe top of the eye",
    26853: "null",
    26854: "Robe bottoms of the eye",
    26855: "null",
    26856: "Boots of the eye",
    26857: "null",
    26858: "Hat of the eye (red)",
    26859: "null",
    26860: "Robe top of the eye (red)",
    26861: "null",
    26862: "Robe bottoms of the eye (red)",
    26863: "null",
    26864: "Hat of the eye (green)",
    26865: "null",
    26866: "Robe top of the eye (green)",
    26867: "null",
    26868: "Robe bottoms of the eye (green)",
    26869: "null",
    26870: "Hat of the eye (blue)",
    26871: "null",
    26872: "Robe top of the eye (blue)",
    26873: "null",
    26874: "Robe bottoms of the eye (blue)",
    26875: "null",
    26876: "Atlax's diary",
    26877: "null",
    26878: "Guardian fragments",
    26879: "Guardian essence",
    26880: "Catalytic guardian stone",
    26881: "Elemental guardian stone",
    26882: "Uncharged cell",
    26883: "Weak cell",
    26884: "Medium cell",
    26885: "Strong cell",
    26886: "Overcharged cell",
    26887: "Portal talisman (air)",
    26888: "Portal talisman (water)",
    26889: "Portal talisman (earth)",
    26890: "Portal talisman (fire)",
    26891: "Portal talisman (mind)",
    26892: "Portal talisman (chaos)",
    26893: "Portal talisman (death)",
    26894: "Portal talisman (blood)",
    26895: "Portal talisman (body)",
    26896: "Portal talisman (cosmic)",
    26897: "Portal talisman (nature)",
    26898: "Portal talisman (law)",
    26899: "Greatish guardian",
    26900: "null",
    26901: "Abyssal protector",
    26902: "null",
    26903: "Eye amulet",
    26904: "Strong cup of tea",
    26905: "Abyssal incantation",
    26906: "Colossal pouch",
    26907: "null",
    26908: "Intricate pouch",
    26909: "null",
    26910: "Tarnished locket",
    26911: "null",
    26912: "Lost bag",
    26913: "null",
    26914: "Amulet of the eye",
    26915: "null",
    26916: "Special hot sauce",
    26917: "Special super hot kebab",
    26918: "Secret report",
    26919: "Big bucket",
    26920: "Big bucket of camel milk",
    26921: "Big bucket of frozen camel milk",
    26922: "null",
    26923: "null",
    26924: "Magical cleaning potion",
    26925: "Cooler",
    26926: "Easter egg",
    26927: "Melted easter egg",
    26928: "Ice cream easter egg",
    26929: "Blunt scimitars",
    26930: "Washing line",
    26931: "Tanning wheel",
    26932: "Vat (dirty)",
    26933: "Vat (cleaned)",
    26934: "Wooden pole",
    26935: "Churning machine",
    26936: "Frozen churning machine",
    26937: "Easter hat",
    26938: "null",
    26939: "Crate ring",
    26940: "null",
    26941: "Polyelemental guardian stone",
    26942: "Message",
    26943: "Clue scroll (elite)",
    26944: "Clue scroll (elite)",
    26945: "Pharaoh's sceptre (uncharged)",
    26946: "null",
    26947: "null",
    26948: "Pharaoh's sceptre",
    26949: "null",
    26950: "Pharaoh's sceptre",
    26951: "null",
    26952: "Scarab mould",
    26953: "Scarab emblem",
    26954: "Stone tablet",
    26955: "Chest",
    26956: "Scarab emblem",
    26957: "Human emblem",
    26958: "Baboon emblem",
    26959: "Crocodile emblem",
    26960: "Rusty key",
    26961: "Lily of the elid",
    26962: "Cure crate",
    26963: "Odd spectacles",
    26964: "null",
    26965: "Bottle of 'tonic'",
    26966: "null",
    26967: "Circlet of water (uncharged)",
    26968: "null",
    26969: "Circlet of water",
    26970: "null",
    26971: "Cabbage",
    26972: "null",
    26973: "Cabbage",
    26974: "null",
    26975: "Cabbage",
    26976: "null",
    26977: "Cabbage",
    26978: "null",
    26979: "Cabbage",
    26980: "null",
    26981: "Null",
    26982: "Null",
    26983: "Null",
    26984: "Lost bag",
    26985: "null",
    26986: "Lost bag",
    26987: "null",
    26988: "Lost bag",
    26989: "null",
    26990: "Amulet of the eye",
    26991: "null",
    26992: "Amulet of the eye",
    26993: "null",
    26994: "Amulet of the eye",
    26995: "null",
    26996: "Ensouled hellhound head",
    26997: "Ensouled hellhound head",
    26998: "null",
    26999: "null",
    27000: "Void knight top (l)(or)",
    27001: "Void knight robe (l)(or)",
    27002: "Void knight gloves (l)(or)",
    27003: "Elite void top (l)(or)",
    27004: "Elite void robe (l)(or)",
    27005: "Void mage helm (l)(or)",
    27006: "Void ranger helm (l)(or)",
    27007: "Void melee helm (l)(or)",
    27008: "Dragon defender (l)(t)",
    27009: "Rune defender (l)(t)",
    27010: "Preform",
    27011: "null",
    27012: "Double ammo mould",
    27013: "null",
    27014: "Kovac's grog",
    27015: "null",
    27016: "null",
    27017: "Smithing catalyst",
    27018: "null",
    27019: "Ore pack",
    27020: "null",
    27021: "Colossal blade",
    27022: "null",
    27023: "Smiths tunic",
    27024: "null",
    27025: "Smiths trousers",
    27026: "null",
    27027: "Smiths boots",
    27028: "null",
    27029: "Smiths gloves",
    27030: "null",
    27031: "Smiths gloves (i)",
    27032: "null",
    27033: "Null",
    27034: "Null",
    27035: "Flower crown",
    27036: "null",
    27037: "Legendary red rose seed",
    27038: "Gorgeous orange lily seed",
    27039: "Beautiful yellow pansy seed",
    27040: "Tenacious indigo iris seed",
    27041: "Quality violet tulip seed",
    27042: "Group ironman helm (unranked)",
    27043: "null",
    27044: "Group ironman platelegs (unranked)",
    27045: "null",
    27046: "Group ironman bracers (unranked)",
    27047: "null",
    27048: "Group ironman platebody (unranked)",
    27049: "null",
    27050: "Group ironman platebody (unranked)",
    27051: "null",
    27052: "Group ironman platebody (unranked)",
    27053: "null",
    27054: "Group ironman platebody (unranked)",
    27055: "null",
    27056: "null",
    27057: "null",
    27058: "null",
    27059: "null",
    27060: "null",
    27061: "null",
    27062: "null",
    27063: "null",
    27064: "null",
    27065: "null",
    27066: "null",
    27067: "null",
    27068: "null",
    27069: "null",
    27070: "null",
    27071: "null",
    27072: "null",
    27073: "null",
    27074: "null",
    27075: "null",
    27076: "null",
    27077: "null",
    27078: "null",
    27079: "null",
    27080: "null",
    27081: "null",
    27082: "null",
    27083: "null",
    27084: "null",
    27085: "null",
    27086: "Rune pouch",
    27087: "Null",
    27088: "null",
    27089: "null",
    27090: "null",
    27091: "null",
    27092: "null",
    27093: "null",
    27094: "null",
    27095: "null",
    27096: "null",
    27097: "null",
    27098: "Elder maul ornament kit",
    27099: "null",
    27100: "Elder maul (or)",
    27101: "null",
    27102: "null",
    27103: "null",
    27104: "null",
    27105: "null",
    27106: "null",
    27107: "null",
    27108: "null",
    27109: "null",
    27110: "Mithril gloves (wrapped)",
    27111: "Rune gloves (wrapped)",
    27112: "Barrows gloves (wrapped)",
    27113: "Elder chaos robes ornament kit",
    27114: "null",
    27115: "Elder chaos top (or)",
    27116: "null",
    27117: "Elder chaos robe (or)",
    27118: "null",
    27119: "Elder chaos hood (or)",
    27120: "null",
    27121: "Dagon'hai robes ornament kit",
    27122: "null",
    27123: "Dagon'hai hat (or)",
    27124: "null",
    27125: "Dagon'hai robe top (or)",
    27126: "null",
    27127: "Dagon'hai robe bottom (or)",
    27128: "null",
    27129: "null",
    27130: "null",
    27131: "null",
    27132: "null",
    27133: "null",
    27134: "null",
    27135: "null",
    27136: "null",
    27137: "null",
    27138: "null",
    27139: "null",
    27140: "null",
    27141: "Flower crown",
    27142: "null",
    27143: "Flower crown",
    27144: "null",
    27145: "Flower crown",
    27146: "null",
    27147: "Flower crown",
    27148: "null",
    27149: "Flower crown",
    27150: "null",
    27151: "Flower crown",
    27152: "null",
    27153: "Flower crown",
    27154: "null",
    27155: "Flower crown",
    27156: "null",
    27157: "Dragon knife",
    27158: "Mystic robe top (dark)",
    27159: "Mystic robe bottom (dark)",
    27160: "Mystic robe top (light)",
    27161: "Mystic robe bottom (light)",
    27162: "Wizard boots",
    27163: "Guthix halo",
    27164: "Zamorak halo",
    27165: "Saradomin halo",
    27166: "Ghostly hood",
    27167: "Ghostly robe",
    27168: "Ghostly robe",
    27169: "Berserker helm",
    27170: "Infinity boots",
    27171: "Tormented bracelet",
    27172: "Necklace of anguish",
    27173: "Amulet of torture",
    27174: "Elder chaos top",
    27175: "Elder chaos robe",
    27176: "Elder chaos hood",
    27177: "Fremennik kilt",
    27178: "Spiked manacles",
    27179: "Rangers' tunic",
    27180: "Guthix chaps",
    27181: "Zamorak chaps",
    27182: "Saradomin chaps",
    27183: "3rd age mage hat",
    27184: "Ancient godsword",
    27185: "Rune defender",
    27186: "Zaryte crossbow",
    27187: "Bow of faerdhinen",
    27188: "Light ballista",
    27189: "Verac's flail",
    27190: "Verac's brassard",
    27191: "Unholy book",
    27192: "Opal dragon bolts (e)",
    27193: "Ancestral robe top",
    27194: "Ancestral robe bottom",
    27195: "Inquisitor's great helm",
    27196: "Inquisitor's hauberk",
    27197: "Inquisitor's plateskirt",
    27198: "Inquisitor's mace",
    27199: "3rd age range top",
    27200: "3rd age range legs",
    27201: "3rd age range coif",
    27202: "Menaphite remedy(4)",
    27203: "null",
    27204: "null",
    27205: "Menaphite remedy(3)",
    27206: "null",
    27207: "null",
    27208: "Menaphite remedy(2)",
    27209: "null",
    27210: "null",
    27211: "Menaphite remedy(1)",
    27212: "null",
    27213: "null",
    27214: "Scarab dung",
    27215: "null",
    27216: "Fossilised dung",
    27217: "null",
    27218: "null",
    27219: "Fang",
    27220: "null",
    27221: "Big banana",
    27222: "null",
    27223: "Eldritch ashes",
    27224: "null",
    27225: "Grain",
    27226: "Masori mask",
    27227: "null",
    27228: "null",
    27229: "Masori body",
    27230: "null",
    27231: "null",
    27232: "Masori chaps",
    27233: "null",
    27234: "null",
    27235: "Masori mask (f)",
    27236: "null",
    27237: "null",
    27238: "Masori body (f)",
    27239: "null",
    27240: "null",
    27241: "Masori chaps (f)",
    27242: "null",
    27243: "null",
    27244: "null",
    27245: "null",
    27246: "Osmumten's fang (or)",
    27247: "null",
    27248: "Cursed phalanx",
    27249: "null",
    27250: "null",
    27251: "Elidinis' ward (f)",
    27252: "null",
    27253: "Elidinis' ward (or)",
    27254: "null",
    27255: "Menaphite ornament kit",
    27256: "null",
    27257: "Icthlarin's shroud (tier 1)",
    27258: "null",
    27259: "Icthlarin's shroud (tier 2)",
    27260: "null",
    27261: "Icthlarin's shroud (tier 3)",
    27262: "null",
    27263: "Icthlarin's shroud (tier 4)",
    27264: "null",
    27265: "Icthlarin's shroud (tier 5)",
    27266: "null",
    27267: "Icthlarin's hood (tier 5)",
    27268: "null",
    27269: "Armadylean plate",
    27270: "null",
    27271: "null",
    27272: "Lily of the sands",
    27273: "null",
    27274: "null",
    27275: "Tumeken's shadow",
    27276: "null",
    27277: "Tumeken's shadow (uncharged)",
    27278: "null",
    27279: "Thread of elidinis",
    27280: "null",
    27281: "Divine rune pouch",
    27282: "null",
    27283: "Breach of the scarab",
    27284: "null",
    27285: "Eye of the corruptor",
    27286: "null",
    27287: "Keris partisan of corruption",
    27288: "null",
    27289: "Jewel of the sun",
    27290: "null",
    27291: "Keris partisan of the sun",
    27292: "null",
    27293: "Cache of runes",
    27294: "null",
    27295: "Water container",
    27296: "Mirror",
    27297: "Neutralising potion",
    27298: "Maisa's message",
    27299: "Antique lamp",
    27300: "Akila's journal",
    27301: "null",
    27302: "Het's capture",
    27303: "null",
    27304: "Apmeken's capture",
    27305: "null",
    27306: "Scabaras' capture",
    27307: "null",
    27308: "Crondis' capture",
    27309: "null",
    27310: "The wardens",
    27311: "null",
    27312: "The jackal's torch",
    27313: "null",
    27314: "Supplies",
    27315: "Nectar (4)",
    27316: "null",
    27317: "Nectar (3)",
    27318: "null",
    27319: "Nectar (2)",
    27320: "null",
    27321: "Nectar (1)",
    27322: "null",
    27323: "Silk dressing (2)",
    27324: "null",
    27325: "Silk dressing (1)",
    27326: "null",
    27327: "Tears of elidinis (4)",
    27328: "null",
    27329: "Tears of elidinis (3)",
    27330: "null",
    27331: "Tears of elidinis (2)",
    27332: "null",
    27333: "Tears of elidinis (1)",
    27334: "null",
    27335: "Blessed crystal scarab (2)",
    27336: "null",
    27337: "Blessed crystal scarab (1)",
    27338: "null",
    27339: "Liquid adrenaline (2)",
    27340: "null",
    27341: "Liquid adrenaline (1)",
    27342: "null",
    27343: "Smelling salts (2)",
    27344: "null",
    27345: "Smelling salts (1)",
    27346: "null",
    27347: "Ambrosia (2)",
    27348: "null",
    27349: "Ambrosia (1)",
    27350: "null",
    27351: "Honey locust",
    27352: "Tumeken's guardian",
    27353: "null",
    27354: "Elidinis' guardian",
    27355: "Masori armour set (f)",
    27356: "null",
    27357: "null",
    27358: "Tome of fire",
    27359: "Masori assembler (broken)",
    27360: "null",
    27361: "Masori assembler max cape (broken)",
    27362: "null",
    27363: "Masori assembler max cape",
    27364: "null",
    27365: "Masori assembler max cape (l)",
    27366: "Masori assembler max hood",
    27367: "null",
    27368: "Dawn scarab egg",
    27369: "Ancient key",
    27370: "Mask of rebirth",
    27371: "null",
    27372: "Masori crafting kit",
    27373: "null",
    27374: "Masori assembler",
    27375: "null",
    27376: "Masori assembler (l)",
    27377: "Remnant of akkha",
    27378: "Remnant of ba-ba",
    27379: "Remnant of kephri",
    27380: "Remnant of zebak",
    27381: "Ancient remnant",
    27382: "Akkhito",
    27383: "Babi",
    27384: "Kephriti",
    27385: "Zebo",
    27386: "Tumeken's damaged guardian",
    27387: "Elidinis' damaged guardian",
    27388: "Adventurer's top (t1)",
    27389: "null",
    27390: "Adventurer's trousers (t1)",
    27391: "null",
    27392: "Adventurer's hood (t1)",
    27393: "null",
    27394: "Adventurer's boots (t1)",
    27395: "null",
    27396: "Adventurer's top (t2)",
    27397: "null",
    27398: "Adventurer's trousers (t2)",
    27399: "null",
    27400: "Adventurer's hood (t2)",
    27401: "null",
    27402: "Adventurer's boots (t2)",
    27403: "null",
    27404: "Adventurer's top (t3)",
    27405: "null",
    27406: "Adventurer's trousers (t3)",
    27407: "null",
    27408: "Adventurer's hood (t3)",
    27409: "null",
    27410: "Adventurer's boots (t3)",
    27411: "null",
    27412: "Adventurer's vambraces",
    27413: "null",
    27414: "Giant stopwatch",
    27415: "null",
    27416: "Speedy teleport scroll",
    27417: "null",
    27418: "Bronze speedrun trophy",
    27419: "null",
    27420: "Silver speedrun trophy",
    27421: "null",
    27422: "Gold speedrun trophy",
    27423: "null",
    27424: "Platinum speedrun trophy",
    27425: "null",
    27426: "Dynamite(p)",
    27427: "Clue scroll (special)",
    27428: "Hood of ruin",
    27429: "null",
    27430: "Robe top of ruin",
    27431: "null",
    27432: "Robe bottom of ruin",
    27433: "null",
    27434: "Gloves of ruin",
    27435: "null",
    27436: "Socks of ruin",
    27437: "null",
    27438: "Cloak of ruin",
    27439: "null",
    27440: "Infinite money bag",
    27441: "null",
    27442: "Adventurer's cape",
    27443: "null",
    27444: "Graceful hood",
    27445: "null",
    27446: "Graceful hood",
    27447: "Graceful cape",
    27448: "null",
    27449: "Graceful cape",
    27450: "Graceful top",
    27451: "null",
    27452: "Graceful top",
    27453: "Graceful legs",
    27454: "null",
    27455: "Graceful legs",
    27456: "Graceful gloves",
    27457: "null",
    27458: "Graceful gloves",
    27459: "Graceful boots",
    27460: "null",
    27461: "Graceful boots",
    27462: "Fresh start helper",
    27463: "Treat cauldron",
    27464: "null",
    27465: "Treat cauldron",
    27466: "null",
    27467: "Treat cauldron",
    27468: "null",
    27469: "Treat cauldron",
    27470: "null",
    27471: "Treat cauldron",
    27472: "null",
    27473: "Witch hat",
    27474: "null",
    27475: "Witch top",
    27476: "null",
    27477: "Witch robes",
    27478: "null",
    27479: "Witch boots",
    27480: "null",
    27481: "Witch cape",
    27482: "null",
    27483: "Terrifying charm",
    27484: "null",
    27485: "Bruised banana",
    27486: "null",
    27487: "null",
    27488: "Smelly sock",
    27489: "null",
    27490: "null",
    27491: "Spooky egg",
    27492: "null",
    27493: "null",
    27494: "Old wool",
    27495: "null",
    27496: "null",
    27497: "Halloween wig",
    27498: "null",
    27499: "Halloween wig",
    27500: "null",
    27501: "Halloween wig",
    27502: "null",
    27503: "Halloween wig",
    27504: "null",
    27505: "Halloween wig",
    27506: "null",
    27507: "Halloween wig",
    27508: "null",
    27509: "Divine rune pouch (l)",
    27510: "null",
    27511: "Kasonde's journal",
    27512: "null",
    27513: "Word translations",
    27514: "null",
    27515: "Dirty note",
    27516: "Dirty note",
    27517: "Dirty note",
    27518: "Warning note",
    27519: "Stone tablet",
    27520: "Stone tablet",
    27521: "Stone tablet",
    27522: "Stone tablet",
    27523: "Wood carving",
    27524: "Wood carving",
    27525: "Wood carving",
    27526: "Wood carving",
    27527: "Wood carving",
    27528: "Wood carving",
    27529: "Wood carving",
    27530: "Wood carving",
    27531: "Wood carving",
    27532: "Compass",
    27533: "Wood carving",
    27534: "Wood carving",
    27535: "Wood carving",
    27536: "Wood carving",
    27537: "Wood carving",
    27538: "null",
    27539: "null",
    27540: "null",
    27541: "null",
    27542: "null",
    27543: "Antique lamp",
    27544: "Ghommal's lucky penny",
    27545: "null",
    27546: "Anim offhand",
    27547: "null",
    27548: "Anim offhand",
    27549: "null",
    27550: "Ghommal's avernic defender 5",
    27551: "Ghommal's avernic defender 5 (l)",
    27552: "Ghommal's avernic defender 6",
    27553: "Ghommal's avernic defender 6 (l)",
    27554: "Perfect gingerbread",
    27555: "Broken gingerbread",
    27556: "Very broken gingerbread",
    27557: "Shattered gingerbread",
    27558: "Sack of coal",
    27559: "Snowball",
    27560: "Golden snowball",
    27561: "Light beer",
    27562: "Mulled pine",
    27563: "Eggnog",
    27564: "Santa's list",
    27565: "null",
    27566: "Christmas jumper",
    27567: "null",
    27568: "Snow goggles & hat",
    27569: "null",
    27570: "Sack of coal",
    27571: "null",
    27572: "Festive nutcracker top",
    27573: "null",
    27574: "Festive nutcracker trousers",
    27575: "null",
    27576: "Festive nutcracker hat",
    27577: "null",
    27578: "Festive nutcracker boots",
    27579: "null",
    27580: "Festive nutcracker staff",
    27581: "null",
    27582: "Sweet nutcracker top",
    27583: "Sweet nutcracker trousers",
    27584: "Sweet nutcracker hat",
    27585: "Sweet nutcracker boots",
    27586: "Sweet nutcracker staff",
    27587: "null",
    27588: "Festive games crown",
    27589: "null",
    27590: "Muphin",
    27591: "null",
    27592: "Muphin",
    27593: "Muphin",
    27594: "null",
    27595: "Dusty scroll",
    27596: "Tullia's letter",
    27597: "Ancient map",
    27598: "Strange cipher",
    27599: "Strange list",
    27600: "Duke note",
    27601: "Numbers note",
    27602: "Settlements note",
    27603: "Lever handle",
    27604: "Icy chest",
    27605: "Jewel shard",
    27606: "Jewel shard",
    27607: "Ancient jewel",
    27608: "Icy key",
    27609: "null",
    27610: "Venator bow",
    27611: "null",
    27612: "Venator bow (uncharged)",
    27613: "null",
    27614: "Venator shard",
    27615: "null",
    27616: "Ancient essence",
    27617: "null",
    27618: "null",
    27619: "null",
    27620: "null",
    27621: "null",
    27622: "Frozen cache",
    27623: "null",
    27624: "Ancient sceptre",
    27625: "null",
    27626: "Ancient sceptre (l)",
    27627: "Ancient icon",
    27628: "null",
    27629: "Forgotten brew(4)",
    27630: "null",
    27631: "null",
    27632: "Forgotten brew(3)",
    27633: "null",
    27634: "null",
    27635: "Forgotten brew(2)",
    27636: "null",
    27637: "null",
    27638: "Forgotten brew(1)",
    27639: "null",
    27640: "null",
    27641: "Saturated heart",
    27642: "null",
    27643: "Charged ice",
    27644: "null",
    27645: "Mystic cards",
    27646: "null",
    27647: "null",
    27648: "Venenatis spiderling",
    27649: "Callisto cub",
    27650: "Vet'ion jr.",
    27651: "Vet'ion jr.",
    27652: "Webweaver bow (u)",
    27653: "null",
    27654: "null",
    27655: "Webweaver bow",
    27656: "null",
    27657: "Ursine chainmace (u)",
    27658: "null",
    27659: "null",
    27660: "Ursine chainmace",
    27661: "null",
    27662: "Accursed sceptre (u)",
    27663: "null",
    27664: "null",
    27665: "Accursed sceptre",
    27666: "null",
    27667: "Claws of callisto",
    27668: "null",
    27669: "null",
    27670: "Fangs of venenatis",
    27671: "null",
    27672: "null",
    27673: "Skull of vet'ion",
    27674: "null",
    27675: "null",
    27676: "Accursed sceptre (au)",
    27677: "null",
    27678: "null",
    27679: "Accursed sceptre (a)",
    27680: "null",
    27681: "Voidwaker hilt",
    27682: "null",
    27683: "null",
    27684: "Voidwaker blade",
    27685: "null",
    27686: "null",
    27687: "Voidwaker gem",
    27688: "null",
    27689: "null",
    27690: "Voidwaker",
    27691: "null",
    27692: "null",
    27693: "Ore pack",
    27694: "null",
    27695: "Dragon pickaxe (broken)",
    27696: "null",
    27697: "Crystal body",
    27698: "null",
    27699: "Crystal body (inactive)",
    27700: "null",
    27701: "Crystal legs",
    27702: "null",
    27703: "Crystal legs (inactive)",
    27704: "null",
    27705: "Crystal helm",
    27706: "null",
    27707: "Crystal helm (inactive)",
    27708: "null",
    27709: "Crystal body",
    27710: "null",
    27711: "Crystal body (inactive)",
    27712: "null",
    27713: "Crystal legs",
    27714: "null",
    27715: "Crystal legs (inactive)",
    27716: "null",
    27717: "Crystal helm",
    27718: "null",
    27719: "Crystal helm (inactive)",
    27720: "null",
    27721: "Crystal body",
    27722: "null",
    27723: "Crystal body (inactive)",
    27724: "null",
    27725: "Crystal legs",
    27726: "null",
    27727: "Crystal legs (inactive)",
    27728: "null",
    27729: "Crystal helm",
    27730: "null",
    27731: "Crystal helm (inactive)",
    27732: "null",
    27733: "Crystal body",
    27734: "null",
    27735: "Crystal body (inactive)",
    27736: "null",
    27737: "Crystal legs",
    27738: "null",
    27739: "Crystal legs (inactive)",
    27740: "null",
    27741: "Crystal helm",
    27742: "null",
    27743: "Crystal helm (inactive)",
    27744: "null",
    27745: "Crystal body",
    27746: "null",
    27747: "Crystal body (inactive)",
    27748: "null",
    27749: "Crystal legs",
    27750: "null",
    27751: "Crystal legs (inactive)",
    27752: "null",
    27753: "Crystal helm",
    27754: "null",
    27755: "Crystal helm (inactive)",
    27756: "null",
    27757: "Crystal body",
    27758: "null",
    27759: "Crystal body (inactive)",
    27760: "null",
    27761: "Crystal legs",
    27762: "null",
    27763: "Crystal legs (inactive)",
    27764: "null",
    27765: "Crystal helm",
    27766: "null",
    27767: "Crystal helm (inactive)",
    27768: "null",
    27769: "Crystal body",
    27770: "null",
    27771: "Crystal body (inactive)",
    27772: "null",
    27773: "Crystal legs",
    27774: "null",
    27775: "Crystal legs (inactive)",
    27776: "null",
    27777: "Crystal helm",
    27778: "null",
    27779: "Crystal helm (inactive)",
    27780: "null",
    27781: "Cabbage",
    27782: "null",
    27783: "Diamond speedrun trophy",
    27784: "null",
    27785: "Thammaron's sceptre (au)",
    27786: "null",
    27787: "null",
    27788: "Thammaron's sceptre (a)",
    27789: "null",
    27790: "Nightshade",
    27791: "null",
    27792: "null",
    27793: "null",
    27794: "Null",
    27795: "Null",
    27796: "Null",
    27797: "Null",
    27798: "Null",
    27799: "Null",
    27800: "Null",
    27801: "Null",
    27802: "Gnome child backpack",
    27803: "null",
    27804: "Cake hat",
    27805: "null",
    27806: "Bob the cat slippers",
    27807: "Null",
    27808: "Jad slippers",
    27809: "null",
    27810: "Dragon candle dagger",
    27811: "null",
    27812: "10th birthday cape",
    27813: "null",
    27814: "Jad plush",
    27815: "null",
    27816: "Stray dog plush",
    27817: "Null",
    27818: "Gnome child plush",
    27819: "Null",
    27820: "10th birthday balloons",
    27821: "null",
    27822: "Oldschool jumper",
    27823: "Oldschool jumper",
    27824: "Oldschool jumper",
    27825: "Oldschool jumper",
    27826: "Oldschool jumper",
    27827: "Oldschool jumper",
    27828: "Silver partyhat",
    27829: "null",
    27830: "null",
    27831: "Vesta's chainbody (bh)",
    27832: "Vesta's plateskirt (bh)",
    27833: "Statius's full helm (bh)",
    27834: "Statius's platebody (bh)",
    27835: "Statius's platelegs (bh)",
    27836: "Morrigan's coif (bh)",
    27837: "Morrigan's leather body (bh)",
    27838: "Morrigan's leather chaps (bh)",
    27839: "Zuriel's hood (bh)",
    27840: "Zuriel's robe top (bh)",
    27841: "Zuriel's robe bottom (bh)",
    27842: "Corrupted vesta's chainbody (bh)",
    27843: "Corrupted vesta's plateskirt (bh)",
    27844: "Corrupted statius's full helm (bh)",
    27845: "Corrupted statius's platebody (bh)",
    27846: "Corrupted statius's platelegs (bh)",
    27847: "Corrupted morrigan's coif (bh)",
    27848: "Corrupted morrigan's leather body (bh)",
    27849: "Corrupted morrigan's leather chaps (bh)",
    27850: "Corrupted zuriel's hood (bh)",
    27851: "Corrupted zuriel's robe top (bh)",
    27852: "Corrupted zuriel's robe bottom (bh)",
    27853: "Dark bow (bh)",
    27854: "null",
    27855: "Barrelchest anchor (bh)",
    27856: "null",
    27857: "Dragon mace (bh)",
    27858: "null",
    27859: "Dragon longsword (bh)",
    27860: "null",
    27861: "Abyssal dagger (bh)",
    27862: "null",
    27863: "Abyssal dagger (bh)(p)",
    27864: "null",
    27865: "Abyssal dagger (bh)(p+)",
    27866: "null",
    27867: "Abyssal dagger (bh)(p++)",
    27868: "null",
    27869: "Voidwaker",
    27870: "Lightbearer",
    27871: "Giant bronze dagger",
    27872: "null",
    27873: "Eastfloor spade",
    27874: "null",
    27875: "Nest hat",
    27876: "null",
    27877: "Nest hat",
    27878: "Null",
    27879: "null",
    27880: "Null",
    27881: "null",
    27882: "Null",
    27883: "null",
    27884: "Null",
    27885: "null",
    27886: "Null",
    27887: "null",
    27888: "Null",
    27889: "null",
    27890: "Null",
    27891: "null",
    27892: "Null",
    27893: "null",
    27894: "null",
    27895: "null",
    27896: "null",
    27897: "Scaly blue dragonhide",
    27898: "null",
    27899: "null",
    27900: "Vesta's spear (bh)",
    27901: "null",
    27902: "Vesta's spear (bh)(inactive)",
    27903: "null",
    27904: "Vesta's longsword (bh)",
    27905: "null",
    27906: "Vesta's longsword (bh)(inactive)",
    27907: "null",
    27908: "Statius's warhammer (bh)",
    27909: "null",
    27910: "Statius's warhammer (bh)(inactive)",
    27911: "null",
    27912: "Morrigan's throwing axe (bh)",
    27913: "null",
    27914: "Morrigan's throwing axe (bh)(inactive)",
    27915: "null",
    27916: "Morrigan's javelin (bh)",
    27917: "null",
    27918: "Morrigan's javelin (bh)(inactive)",
    27919: "null",
    27920: "Zuriel's staff (bh)",
    27921: "null",
    27922: "Zuriel's staff (bh)(inactive)",
    27923: "null",
    27924: "null",
    27925: "Vesta's chainbody (bh)(inactive)",
    27926: "null",
    27927: "null",
    27928: "Vesta's plateskirt (bh)(inactive)",
    27929: "null",
    27930: "null",
    27931: "Statius's full helm (bh)(inactive)",
    27932: "null",
    27933: "null",
    27934: "Statius's platebody (bh)(inactive)",
    27935: "null",
    27936: "null",
    27937: "Statius's platelegs (bh)(inactive)",
    27938: "null",
    27939: "null",
    27940: "Morrigan's coif (bh)(inactive)",
    27941: "null",
    27942: "null",
    27943: "Morrigan's leather body (bh)(inactive)",
    27944: "null",
    27945: "null",
    27946: "Morrigan's leather chaps (bh)(inactive)",
    27947: "null",
    27948: "null",
    27949: "Zuriel's hood (bh)(inactive)",
    27950: "null",
    27951: "null",
    27952: "Zuriel's robe top (bh)(inactive)",
    27953: "null",
    27954: "null",
    27955: "Zuriel's robe bottom (bh)(inactive)",
    27956: "null",
    27957: "Attack xp",
    27958: "Strength xp",
    27959: "Defence xp",
    27960: "Hitpoints xp",
    27961: "Magic xp",
    27962: "Ranged xp",
    27963: "Prayer xp",
    27964: "null",
    27965: "Corrupted vesta's chainbody (bh)(inactive)",
    27966: "null",
    27967: "null",
    27968: "Corrupted vesta's plateskirt (bh)(inactive)",
    27969: "null",
    27970: "null",
    27971: "Corrupted statius's full helm (bh)(inactive)",
    27972: "null",
    27973: "null",
    27974: "Corrupted statius's platebody (bh)(inactive)",
    27975: "null",
    27976: "null",
    27977: "Corrupted statius's platelegs (bh)(inactive)",
    27978: "null",
    27979: "null",
    27980: "Corrupted morrigan's coif (bh)(inactive)",
    27981: "null",
    27982: "null",
    27983: "Corrupted morrigan's leather body (bh)(inactive)",
    27984: "null",
    27985: "null",
    27986: "Corrupted morrigan's leather chaps (bh)(inactive)",
    27987: "null",
    27988: "null",
    27989: "Corrupted zuriel's hood (bh)(inactive)",
    27990: "null",
    27991: "null",
    27992: "Corrupted zuriel's robe top (bh)(inactive)",
    27993: "null",
    27994: "null",
    27995: "Corrupted zuriel's robe bottom (bh)(inactive)",
    27996: "null",
    27997: "Esoteric emblem (tier 1)",
    27998: "null",
    27999: "Esoteric emblem (tier 2)",
    28000: "null",
    28001: "Esoteric emblem (tier 3)",
    28002: "null",
    28003: "Esoteric emblem (tier 4)",
    28004: "null",
    28005: "Esoteric emblem (tier 5)",
    28006: "null",
    28007: "Esoteric emblem (tier 6)",
    28008: "null",
    28009: "Esoteric emblem (tier 7)",
    28010: "null",
    28011: "Esoteric emblem (tier 8)",
    28012: "null",
    28013: "Esoteric emblem (tier 9)",
    28014: "null",
    28015: "Esoteric emblem (tier 10)",
    28016: "null",
    28017: "Bounty hunter ornament kit",
    28018: "null",
    28019: "Dragon dagger (cr)",
    28020: "null",
    28021: "Dragon dagger (p)(cr)",
    28022: "null",
    28023: "Dragon dagger (p+)(cr)",
    28024: "null",
    28025: "Dragon dagger (p++)(cr)",
    28026: "null",
    28027: "Dragon mace (cr)",
    28028: "null",
    28029: "Dragon sword (cr)",
    28030: "null",
    28031: "Dragon scimitar (cr)",
    28032: "null",
    28033: "Dragon longsword (cr)",
    28034: "null",
    28035: "Dragon warhammer (cr)",
    28036: "null",
    28037: "Dragon battleaxe (cr)",
    28038: "null",
    28039: "Dragon claws (cr)",
    28040: "null",
    28041: "Dragon spear (cr)",
    28042: "null",
    28043: "Dragon spear (p)(cr)",
    28044: "null",
    28045: "Dragon spear (p+)(cr)",
    28046: "null",
    28047: "Dragon spear (p++)(cr)",
    28048: "null",
    28049: "Dragon halberd (cr)",
    28050: "null",
    28051: "Dragon 2h sword (cr)",
    28052: "null",
    28053: "Dragon crossbow (cr)",
    28054: "null",
    28055: "Dragon boots (cr)",
    28056: "null",
    28057: "Dragon med helm (cr)",
    28058: "null",
    28059: "Dragon sq shield (cr)",
    28060: "null",
    28061: "Dragon platelegs (cr)",
    28062: "null",
    28063: "Dragon plateskirt (cr)",
    28064: "null",
    28065: "Dragon chainbody (cr)",
    28066: "null",
    28067: "Fighter torso (or)",
    28068: "null",
    28069: "Fighter torso (l)(or)",
    28070: "Helm of neitiznot (or)",
    28071: "null",
    28072: "Dark bow imbue scroll",
    28073: "null",
    28074: "Barrelchest anchor imbue scroll",
    28075: "null",
    28076: "Dragon mace imbue scroll",
    28077: "null",
    28078: "Dragon longsword imbue scroll",
    28079: "null",
    28080: "Abyssal dagger imbue scroll",
    28081: "null",
    28082: "Bounty crate (tier 1)",
    28083: "null",
    28084: "Bounty crate (tier 2)",
    28085: "null",
    28086: "Bounty crate (tier 3)",
    28087: "null",
    28088: "Bounty crate (tier 4)",
    28089: "null",
    28090: "Bounty crate (tier 5)",
    28091: "null",
    28092: "Bounty crate (tier 6)",
    28093: "null",
    28094: "Bounty crate (tier 7)",
    28095: "null",
    28096: "Bounty crate (tier 8)",
    28097: "null",
    28098: "Bounty crate (tier 9)",
    28099: "null",
    28100: "Null",
    28101: "Null",
    28102: "Null",
    28103: "Null",
    28104: "Null",
    28105: "Null",
    28106: "Null",
    28107: "Null",
    28108: "Colourful scarf",
    28109: "Colourful scarf",
    28110: "Colourful scarf",
    28111: "Colourful scarf",
    28112: "Colourful scarf",
    28113: "Colourful scarf",
    28114: "Colourful scarf",
    28115: "Colourful scarf",
    28116: "Rainbow jumper",
    28117: "null",
    28118: "Colourful jumper",
    28119: "Colourful jumper",
    28120: "Colourful jumper",
    28121: "Colourful jumper",
    28122: "Colourful jumper",
    28123: "Colourful jumper",
    28124: "Colourful jumper",
    28125: "Colourful jumper",
    28126: "Poet's jacket",
    28127: "null",
    28128: "Love crossbow",
    28129: "null",
    28130: "Strange icon",
    28131: "null",
    28132: "Dusty lamp",
    28133: "Crypt map",
    28134: "Anima-infused bark",
    28135: "null",
    28136: "Forestry kit",
    28137: "null",
    28138: "Funky shaped log",
    28139: "null",
    28140: "Log basket",
    28141: "null",
    28142: "Open log basket",
    28143: "Forestry basket",
    28144: "null",
    28145: "Open forestry basket",
    28146: "Log brace",
    28147: "null",
    28148: "null",
    28149: "Sturdy harness",
    28150: "null",
    28151: "null",
    28152: "Nature offerings",
    28153: "null",
    28154: "Ritual mulch",
    28155: "null",
    28156: "null",
    28157: "Forester's ration",
    28158: "null",
    28159: "Secateurs blade",
    28160: "null",
    28161: "Secateurs attachment",
    28162: "null",
    28163: "Clothes pouch",
    28164: "null",
    28165: "null",
    28166: "Clothes pouch blueprint",
    28167: "null",
    28168: "null",
    28169: "Forestry top",
    28170: "null",
    28171: "Forestry legs",
    28172: "null",
    28173: "Forestry hat",
    28174: "null",
    28175: "Forestry boots",
    28176: "null",
    28177: "Felling axe handle",
    28178: "null",
    28179: "Leprechaun charm",
    28180: "Null",
    28181: "Clover insignia",
    28182: "Null",
    28183: "Mulch",
    28184: "Bee on a stick",
    28185: "Null",
    28186: "null",
    28187: "null",
    28188: "null",
    28189: "null",
    28190: "Powdered pollen",
    28191: "Null",
    28192: "Strange pollen",
    28193: "Unfired cup",
    28194: "null",
    28195: "null",
    28196: "Bronze felling axe",
    28197: "null",
    28198: "null",
    28199: "Iron felling axe",
    28200: "null",
    28201: "null",
    28202: "Steel felling axe",
    28203: "null",
    28204: "null",
    28205: "Black felling axe",
    28206: "null",
    28207: "null",
    28208: "Mithril felling axe",
    28209: "null",
    28210: "null",
    28211: "Adamant felling axe",
    28212: "null",
    28213: "null",
    28214: "Rune felling axe",
    28215: "null",
    28216: "null",
    28217: "Dragon felling axe",
    28218: "null",
    28219: "null",
    28220: "Crystal felling axe",
    28221: "Null",
    28222: "null",
    28223: "Crystal felling axe (inactive)",
    28224: "Null",
    28225: "null",
    28226: "3rd age felling axe",
    28227: "null",
    28228: "null",
    28229: "Beaver",
    28230: "Beaver",
    28231: "Beaver",
    28232: "Beaver",
    28233: "Beaver",
    28234: "Beaver",
    28235: "Beaver",
    28236: "Beaver",
    28237: "Beaver",
    28238: "Blood ancient sceptre (broken)",
    28239: "null",
    28240: "Smoke ancient sceptre (broken)",
    28241: "null",
    28242: "Ice ancient sceptre (broken)",
    28243: "null",
    28244: "Shadow ancient sceptre (broken)",
    28245: "null",
    28246: "Wisp",
    28247: "null",
    28248: "Butch",
    28249: "null",
    28250: "Baron",
    28251: "null",
    28252: "Lil'viathan",
    28253: "null",
    28254: "Sanguine torva full helm",
    28255: "null",
    28256: "Sanguine torva platebody",
    28257: "null",
    28258: "Sanguine torva platelegs",
    28259: "null",
    28260: "Blood ancient sceptre",
    28261: "null",
    28262: "Ice ancient sceptre",
    28263: "null",
    28264: "Smoke ancient sceptre",
    28265: "null",
    28266: "Shadow ancient sceptre",
    28267: "null",
    28268: "Blood quartz",
    28269: "null",
    28270: "Ice quartz",
    28271: "null",
    28272: "Shadow quartz",
    28273: "null",
    28274: "Smoke quartz",
    28275: "null",
    28276: "Chromium ingot",
    28277: "null",
    28278: "null",
    28279: "Bellator vestige",
    28280: "null",
    28281: "Magus vestige",
    28282: "null",
    28283: "Venator vestige",
    28284: "null",
    28285: "Ultor vestige",
    28286: "null",
    28287: "Ultor icon",
    28288: "null",
    28289: "Venator icon",
    28290: "null",
    28291: "Magus icon",
    28292: "null",
    28293: "Bellator icon",
    28294: "null",
    28295: "Berserker icon",
    28296: "null",
    28297: "null",
    28298: "Archer icon",
    28299: "null",
    28300: "null",
    28301: "Warrior icon",
    28302: "null",
    28303: "null",
    28304: "Seers icon",
    28305: "null",
    28306: "null",
    28307: "Ultor ring",
    28308: "null",
    28309: "null",
    28310: "Venator ring",
    28311: "null",
    28312: "null",
    28313: "Magus ring",
    28314: "null",
    28315: "null",
    28316: "Bellator ring",
    28317: "null",
    28318: "null",
    28319: "Executioner's axe head",
    28320: "null",
    28321: "Eye of the duke",
    28322: "null",
    28323: "Siren's staff",
    28324: "null",
    28325: "Leviathan's lure",
    28326: "null",
    28327: "Ring of shadows",
    28328: "null",
    28329: "Ring of shadows (uncharged)",
    28330: "Strangled tablet",
    28331: "Sirenic tablet",
    28332: "Scarred tablet",
    28333: "Frozen tablet",
    28334: "Awakener's orb",
    28335: "null",
    28336: "Ancient blood ornament kit",
    28337: "null",
    28338: "Soulreaper axe",
    28339: "null",
    28340: "null",
    28341: "Musca mushroom",
    28342: "Musca powder",
    28343: "Holos mushroom",
    28344: "Holos powder",
    28345: "Arder mushroom",
    28346: "Arder powder",
    28347: "Resper mushroom",
    28348: "Resper powder",
    28349: "Salax salt",
    28350: "Musca-holos poison",
    28351: "Arder-musca poison",
    28352: "Musca-resper poison",
    28353: "Holos-arder poison",
    28354: "Resper-holos poison",
    28355: "Arder-resper poison",
    28356: "Blackstone fragment",
    28357: "Blackstone fragment",
    28358: "null",
    28359: "null",
    28360: "Strange icon",
    28361: "Icon segment",
    28362: "Icon segment",
    28363: "Very long rope",
    28364: "Basic shadow torch",
    28365: "Superior shadow torch",
    28366: "Perfected shadow torch",
    28367: "Shadow blocker",
    28368: "Revitalising idol",
    28369: "Anima portal",
    28370: "Shadow key",
    28371: "Shadow key",
    28372: "Shadow key",
    28373: "Shadow key",
    28374: "Shadow key",
    28375: "Anima portal schematic",
    28376: "Revitalising idol schematic",
    28377: "Shadow blocker schematic",
    28378: "Basic shadow torch schematic",
    28379: "Superior shadow torch schematic",
    28380: "Perfected shadow torch schematic",
    28381: "Perfected shadow torch schematic",
    28382: "Potion note",
    28383: "Strange potion",
    28384: "Korbal herb",
    28385: "Argian berries",
    28386: "Unfinished serum",
    28387: "Unfinished serum",
    28388: "Strangler serum",
    28389: "Temple key",
    28390: "Barricade",
    28391: "null",
    28392: "Satchel",
    28393: "Detonator",
    28394: "Tatty page",
    28395: "Tatty page",
    28396: "Tatty page",
    28397: "Tatty page",
    28398: "Tatty page",
    28399: "Tatty page",
    28400: "Tatty page",
    28401: "Mucky note",
    28402: "Uncharged cell",
    28403: "Charged cell",
    28404: "Vardorvis' medallion",
    28405: "Perseriya's medallion",
    28406: "Sucellus' medallion",
    28407: "Whisperer's medallion",
    28408: "Hair clip",
    28409: "Ancient lamp",
    28410: "Dr banikan",
    28411: "null",
    28412: "Prisoner's letter",
    28413: "Knife",
    28414: "Chisel",
    28415: "Lockpick",
    28416: "Sapphire key",
    28417: "Emerald key",
    28418: "Ruby key",
    28419: "Diamond key",
    28420: "Dragonstone key",
    28421: "Onyx key",
    28422: "Rations",
    28423: "Requisition note",
    28424: "Grid note",
    28425: "Code converter",
    28426: "Magic lantern",
    28427: "Strange slider",
    28428: "Library note",
    28429: "Warning letter",
    28430: "Odd key",
    28431: "Orders note",
    28432: "Refugees note",
    28433: "Request note",
    28434: "Prayer note",
    28435: "Thank you note",
    28436: "Protest note",
    28437: "Evacuation note",
    28438: "Old tablet",
    28439: "Damp tablet",
    28440: "Damp tablet",
    28441: "Slimy key",
    28442: "Gunpowder",
    28443: "Scarred scraps",
    28444: "Withered note",
    28445: "Earth nerve",
    28446: "Water nerve",
    28447: "Fire nerve",
    28448: "Air nerve",
    28449: "Mind nerve",
    28450: "Soul nerve",
    28451: "Nature nerve",
    28452: "Smoke nerve",
    28453: "Blood nerve",
    28454: "Law nerve",
    28455: "Cosmic nerve",
    28456: "Astral nerve",
    28457: "Wrath nerve",
    28458: "Dust nerve",
    28459: "Steam nerve",
    28460: "Lava nerve",
    28461: "Abyssal observations",
    28462: "Crimson fibre",
    28463: "Radiant fibre",
    28464: "Illuminating lure",
    28465: "Slimy tablet",
    28466: "Slimy tablet",
    28467: "Slimy tablet",
    28468: "Gooey note",
    28469: "Gooey note",
    28470: "Gooey note",
    28471: "Stink bomb",
    28472: "null",
    28473: "Blood ancient sceptre (l)",
    28474: "Ice ancient sceptre (l)",
    28475: "Smoke ancient sceptre (l)",
    28476: "Shadow ancient sceptre (l)",
    28477: "Sigil of sustenance",
    28478: "Sigil of sustenance",
    28479: "null",
    28480: "Sigil of hoarding",
    28481: "Sigil of hoarding",
    28482: "null",
    28483: "Sigil of the alchemaniac",
    28484: "Sigil of the alchemaniac",
    28485: "null",
    28486: "Sigil of the hunter",
    28487: "Sigil of the hunter",
    28488: "null",
    28489: "Sigil of resistance",
    28490: "Sigil of resistance",
    28491: "null",
    28492: "Sigil of agile fortune",
    28493: "Sigil of agile fortune",
    28494: "null",
    28495: "Sigil of the food master",
    28496: "Sigil of the food master",
    28497: "null",
    28498: "Sigil of the well-fed",
    28499: "Sigil of the well-fed",
    28500: "null",
    28501: "Sigil of the infernal chef",
    28502: "Sigil of the infernal chef",
    28503: "null",
    28504: "Sigil of the infernal smith",
    28505: "Sigil of the infernal smith",
    28506: "null",
    28507: "Sigil of the lightbearer",
    28508: "Sigil of the lightbearer",
    28509: "null",
    28510: "Sigil of the bloodhound",
    28511: "Sigil of the bloodhound",
    28512: "null",
    28513: "Sigil of precision",
    28514: "Sigil of precision",
    28515: "null",
    28516: "Sigil of the augmented thrall",
    28517: "Sigil of the augmented thrall",
    28518: "null",
    28519: "Sigil of faith",
    28520: "Sigil of faith",
    28521: "null",
    28522: "Sigil of titanium",
    28523: "Sigil of titanium",
    28524: "null",
    28525: "Sigil of the ninja",
    28526: "Sigil of the ninja",
    28527: "null",
    28528: "Sigil of woodcraft",
    28529: "Sigil of woodcraft",
    28530: "null",
    28531: "Corrupted voidwaker",
    28532: "null",
    28533: "null",
    28534: "Corrupted dragon claws",
    28535: "null",
    28536: "null",
    28537: "Corrupted armadyl godsword",
    28538: "null",
    28539: "null",
    28540: "Corrupted twisted bow",
    28541: "null",
    28542: "null",
    28543: "Corrupted scythe of vitur",
    28544: "null",
    28545: "Corrupted scythe of vitur (uncharged)",
    28546: "null",
    28547: "Corrupted tumeken's shadow",
    28548: "null",
    28549: "Corrupted tumeken's shadow (uncharged)",
    28550: "null",
    28551: "Quest lamp",
    28552: "Quest lamp",
    28553: "Quest lamp",
    28554: "Quest lamp",
    28555: "Starter bow",
    28556: "null",
    28557: "Starter staff",
    28558: "null",
    28559: "Starter sword",
    28560: "null",
    28561: "Trinket of vengeance",
    28562: "null",
    28563: "null",
    28564: "Trinket of fairies",
    28565: "null",
    28566: "null",
    28567: "Trinket of advanced weaponry",
    28568: "null",
    28569: "null",
    28570: "Trinket of undead",
    28571: "null",
    28572: "null",
    28573: "Chest key",
    28574: "Strongroom key",
    28575: "Crystal chime seed",
    28576: "null",
    28577: "Crystal chime",
    28578: "null",
    28579: "Yewnock's notes",
    28580: "null",
    28581: "null",
    28582: "null",
    28583: "Warped sceptre (uncharged)",
    28584: "null",
    28585: "Warped sceptre",
    28586: "null",
    28587: "Magic lamp (strength)",
    28588: "Magic lamp (slayer)",
    28589: "Magic lamp (thieving)",
    28590: "Magic lamp (magic)",
    28591: "Tainted essence chunk",
    28592: "null",
    28593: "Warped extract",
    28594: "null",
    28595: "Twisted extract",
    28596: "null",
    28597: "Mangled extract",
    28598: "null",
    28599: "Scarred extract",
    28600: "null",
    28601: "Cobweb cape",
    28602: "null",
    28603: "Spider hat",
    28604: "null",
    28605: "Spider hat",
    28606: "null",
    28607: "Spider hat",
    28608: "null",
    28609: "Spider hat",
    28610: "null",
    28611: "Spider hat",
    28612: "null",
    28613: "Cape pouch",
    28614: "null",
    28615: "null",
    28616: "Pheasant cape",
    28617: "null",
    28618: "Pheasant boots",
    28619: "null",
    28620: "Pheasant hat",
    28621: "null",
    28622: "Pheasant legs",
    28623: "null",
    28624: "Pheasant tail feathers",
    28625: "null",
    28626: "Fox whistle",
    28627: "null",
    28628: "Sawmill voucher",
    28629: "null",
    28630: "Twitcher's gloves",
    28631: "null",
    28632: "null",
    28633: "null",
    28634: "null",
    28635: "null",
    28636: "null",
    28637: "null",
    28638: "null",
    28639: "null",
    28640: "null",
    28641: "null",
    28642: "null",
    28643: "null",
    28644: "Smoker fuel",
    28645: "Null",
    28646: "Smoker canister",
    28647: "Null",
    28648: "Mulch",
    28649: "Mulch",
    28650: "Packed mulch",
    28651: "Crystal charm",
    28652: "Null",
    28653: "Petal circlet",
    28654: "Null",
    28655: "Petal garland",
    28656: "null",
    28657: "Padded spoon",
    28658: "Null",
    28659: "Egg cushion",
    28660: "Null",
    28661: "Pheasant egg",
    28662: "null",
    28663: "Golden pheasant egg",
    28664: "null",
    28665: "Trap disarmer",
    28666: "Null",
    28667: "Trap disarmer blueprint",
    28668: "Null",
    28669: "Pheasant",
    28670: "Fox",
    28671: "Web cloak",
    28672: "Fancier boots",
    28673: "null",
    28674: "Sturdy beehive parts",
    28675: "null",
    28676: "Beehive (style 1)",
    28677: "Beehive (style 2)",
    28678: "Cabbage",
    28679: "null",
    28680: "Cabbage",
    28681: "null",
    28682: "Dinh's blazing bulwark",
    28683: "null",
    28684: "Trailblazer reloaded bulwark ornament kit",
    28685: "null",
    28686: "null",
    28687: "Blazing blowpipe (empty)",
    28688: "Blazing blowpipe",
    28689: "null",
    28690: "Trailblazer reloaded blowpipe ornament kit",
    28691: "null",
    28692: "null",
    28693: "Trailblazer reloaded alchemy scroll",
    28694: "null",
    28695: "null",
    28696: "Trailblazer reloaded vengeance scroll",
    28697: "null",
    28698: "null",
    28699: "Trailblazer reloaded death scroll",
    28700: "null",
    28701: "null",
    28702: "Trailblazer reloaded banner",
    28703: "null",
    28704: "null",
    28705: "Trailblazer reloaded home teleport scroll",
    28706: "null",
    28707: "null",
    28708: "Trailblazer reloaded rejuvenation pool scroll",
    28709: "null",
    28710: "null",
    28711: "Trailblazer reloaded rejuvenation pool",
    28712: "Trailblazer reloaded headband (t1)",
    28713: "null",
    28714: "null",
    28715: "Trailblazer reloaded top (t1)",
    28716: "null",
    28717: "null",
    28718: "Trailblazer reloaded trousers (t1)",
    28719: "null",
    28720: "null",
    28721: "Trailblazer reloaded boots (t1)",
    28722: "null",
    28723: "null",
    28724: "Trailblazer reloaded headband (t2)",
    28725: "null",
    28726: "null",
    28727: "Trailblazer reloaded top (t2)",
    28728: "null",
    28729: "null",
    28730: "Trailblazer reloaded trousers (t2)",
    28731: "null",
    28732: "null",
    28733: "Trailblazer reloaded boots (t2)",
    28734: "null",
    28735: "null",
    28736: "Trailblazer reloaded headband (t3)",
    28737: "null",
    28738: "null",
    28739: "Trailblazer reloaded top (t3)",
    28740: "null",
    28741: "null",
    28742: "Trailblazer reloaded trousers (t3)",
    28743: "null",
    28744: "null",
    28745: "Trailblazer reloaded boots (t3)",
    28746: "null",
    28747: "null",
    28748: "Trailblazer reloaded torch",
    28749: "null",
    28750: "null",
    28751: "Trailblazer reloaded dragon trophy",
    28752: "null",
    28753: "Trailblazer reloaded rune trophy",
    28754: "null",
    28755: "Trailblazer reloaded adamant trophy",
    28756: "null",
    28757: "Trailblazer reloaded mithril trophy",
    28758: "null",
    28759: "Trailblazer reloaded steel trophy",
    28760: "null",
    28761: "Trailblazer reloaded iron trophy",
    28762: "null",
    28763: "Trailblazer reloaded bronze trophy",
    28764: "null",
    28765: "Globetrotter pendant",
    28766: "null",
    28767: "Banker's note",
    28768: "null",
    28769: "Guardian horn",
    28770: "null",
    28771: "Sage's greaves",
    28772: "null",
    28773: "Sage's axe",
    28774: "null",
    28775: "Ruinous powers",
    28776: "null",
    28777: "Trailblazer reloaded relic hunter (t1) armour set",
    28778: "null",
    28779: "null",
    28780: "Trailblazer reloaded relic hunter (t2) armour set",
    28781: "null",
    28782: "null",
    28783: "Trailblazer reloaded relic hunter (t3) armour set",
    28784: "null",
    28785: "null",
    28786: "Icy jumper",
    28787: "null",
    28788: "Snowglobe helmet",
    28789: "null",
    28790: "Kourend castle teleport",
    28791: "null",
    28792: "Bone mace",
    28793: "null",
    28794: "Bone shortbow",
    28795: "null",
    28796: "Bone staff",
    28797: "null",
    28798: "Scurrius' spine",
    28799: "null",
    28800: "Antique lamp",
    28801: "Scurry",
    28802: "null",
    28803: "Grubby key",
    28804: "Bottle",
    28805: "Bottle of mist",
    28806: "Imbued barronite",
    28807: "Shield of arrav",
    28808: "List of elders",
    28809: "Elias white",
    28810: "Zombie axe",
    28811: "null",
    28812: "null",
    28813: "Broken zombie axe",
    28814: "null",
    28815: "null",
    28816: "Stone tablet",
    28817: "Granite tablet",
    28818: "Slate tablet",
    28819: "Shale tablet",
    28820: "Antique lamp",
    28821: "Wealthy citizen",
    28822: "Coin pouch",
    28823: "Lesser nagua",
    28824: "Civitas illa fortis teleport",
    28825: "null",
    28826: "Dizana's quiver (broken)",
    28827: "null",
    28828: "Blessed dizana's quiver (broken)",
    28829: "null",
    28830: "Dizana's max cape (broken)",
    28831: "Immature tecu salamander",
    28832: "null",
    28833: "null",
    28834: "Tecu salamander",
    28835: "null",
    28836: "null",
    28837: "Irit tar",
    28838: "null",
    28839: "Wood camo top",
    28840: "Null",
    28841: "null",
    28842: "Wood camo legs",
    28843: "Null",
    28844: "null",
    28845: "Jungle camo top",
    28846: "Null",
    28847: "null",
    28848: "Jungle camo legs",
    28849: "Null",
    28850: "null",
    28851: "Desert camo top",
    28852: "Null",
    28853: "null",
    28854: "Desert camo legs",
    28855: "Null",
    28856: "null",
    28857: "Polar camo top",
    28858: "Null",
    28859: "null",
    28860: "Polar camo legs",
    28861: "Null",
    28862: "null",
    28863: "Sunlight moth",
    28864: "Moonlight moth",
    28865: "Pyre fox",
    28866: "Embertailed jerboa",
    28867: "Sunlight antelope",
    28868: "Moonlight antelope",
    28869: "Hunters' sunlight crossbow",
    28870: "null",
    28871: "null",
    28872: "Sunlight antler bolts",
    28873: "null",
    28874: "null",
    28875: "null",
    28876: "null",
    28877: "null",
    28878: "Moonlight antler bolts",
    28879: "null",
    28880: "null",
    28881: "null",
    28882: "null",
    28883: "null",
    28884: "Sunlight antler",
    28885: "null",
    28886: "null",
    28887: "Moonlight antler",
    28888: "null",
    28889: "null",
    28890: "Sunlight moth",
    28891: "null",
    28892: "null",
    28893: "Moonlight moth",
    28894: "null",
    28895: "null",
    28896: "Rum",
    28897: "null",
    28898: "null",
    28899: "Wyrmling bones",
    28900: "null",
    28901: "null",
    28902: "Dizana's max cape",
    28903: "null",
    28904: "Dizana's max hood",
    28905: "null",
    28906: "Dizana's max cape (l)",
    28907: "Clue scroll (medium)",
    28908: "Clue scroll (medium)",
    28909: "Clue scroll (medium)",
    28910: "Clue scroll (elite)",
    28911: "Clue scroll (elite)",
    28912: "Clue scroll (elite)",
    28913: "Clue scroll (easy)",
    28914: "Clue scroll (easy)",
    28915: "Clue scroll (hard)",
    28916: "Clue scroll (hard)",
    28917: "Puzzle box (hard)",
    28918: "Clue scroll (hard)",
    28919: "Tonalztics of ralos (uncharged)",
    28920: "null",
    28921: "null",
    28922: "Tonalztics of ralos",
    28923: "null",
    28924: "Sunfire splinters",
    28925: "null",
    28926: "null",
    28927: "null",
    28928: "null",
    28929: "Sunfire rune",
    28930: "null",
    28931: "Searing page",
    28932: "null",
    28933: "Sunfire fanatic helm",
    28934: "null",
    28935: "null",
    28936: "Sunfire fanatic cuirass",
    28937: "null",
    28938: "null",
    28939: "Sunfire fanatic chausses",
    28940: "null",
    28941: "null",
    28942: "Echo crystal",
    28943: "null",
    28944: "null",
    28945: "Echo boots",
    28946: "null",
    28947: "Dizana's quiver (uncharged)",
    28948: "null",
    28949: "Dizana's quiver (uncharged) (l)",
    28950: "null",
    28951: "Dizana's quiver",
    28952: "null",
    28953: "Dizana's quiver (l)",
    28954: "null",
    28955: "Blessed dizana's quiver",
    28956: "null",
    28957: "Blessed dizana's quiver (l)",
    28958: "Scrawled poem",
    28959: "null",
    28960: "Smol heredit",
    28961: "null",
    28962: "Quetzin",
    28963: "null",
    28964: "Enchanted water talisman",
    28965: "Enchanted earth talisman",
    28966: "Infused water talisman",
    28967: "Infused earth talisman",
    28968: "Building supplies",
    28969: "Moss lizard tail",
    28970: "Bream scales",
    28971: "null",
    28972: "Varlamore invitation",
    28973: "Varlamore crest",
    28974: "Incriminating letter",
    28975: "Quetzal feed",
    28976: "Stolen amulet",
    28977: "Knight of varlamore",
    28978: "Smooth leaf",
    28979: "Sticky leaf",
    28980: "Makeshift poultice",
    28981: "Fur sample",
    28982: "Trimmed fur",
    28983: "Fox's report",
    28984: "Baby dragon bonemeal",
    28985: "null",
    28986: "Love letter",
    28987: "Plushy",
    28988: "Blue moon spear",
    28989: "null",
    28990: "null",
    28991: "Atlatl dart",
    28992: "null",
    28993: "null",
    28994: "null",
    28995: "null",
    28996: "null",
    28997: "Dual macuahuitl",
    28998: "null",
    28999: "null",
    29000: "Eclipse atlatl",
    29001: "null",
    29002: "null",
    29003: "null",
    29004: "Eclipse moon chestplate",
    29005: "null",
    29006: "null",
    29007: "Eclipse moon tassets",
    29008: "null",
    29009: "null",
    29010: "Eclipse moon helm",
    29011: "null",
    29012: "null",
    29013: "Blue moon chestplate",
    29014: "null",
    29015: "null",
    29016: "Blue moon tassets",
    29017: "null",
    29018: "null",
    29019: "Blue moon helm",
    29020: "null",
    29021: "null",
    29022: "Blood moon chestplate",
    29023: "null",
    29024: "null",
    29025: "Blood moon tassets",
    29026: "null",
    29027: "null",
    29028: "Blood moon helm",
    29029: "null",
    29030: "null",
    29031: "Eclipse moon chestplate",
    29032: "null",
    29033: "Eclipse moon tassets",
    29034: "null",
    29035: "Eclipse moon helm",
    29036: "null",
    29037: "Blue moon chestplate",
    29038: "null",
    29039: "Blue moon tassets",
    29040: "null",
    29041: "Blue moon helm",
    29042: "null",
    29043: "Blood moon chestplate",
    29044: "null",
    29045: "Blood moon tassets",
    29046: "null",
    29047: "Blood moon helm",
    29048: "null",
    29049: "Eclipse moon chestplate (broken)",
    29050: "null",
    29051: "null",
    29052: "Eclipse moon tassets (broken)",
    29053: "null",
    29054: "null",
    29055: "Eclipse moon helm (broken)",
    29056: "null",
    29057: "null",
    29058: "Blue moon chestplate (broken)",
    29059: "null",
    29060: "null",
    29061: "Blue moon tassets (broken)",
    29062: "null",
    29063: "null",
    29064: "Blue moon helm (broken)",
    29065: "null",
    29066: "null",
    29067: "Blood moon chestplate (broken)",
    29068: "null",
    29069: "null",
    29070: "Blood moon tassets (broken)",
    29071: "null",
    29072: "null",
    29073: "Blood moon helm (broken)",
    29074: "null",
    29075: "null",
    29076: "Raw moss lizard",
    29077: "Cooked moss lizard",
    29078: "Moonlight grub",
    29079: "Moonlight grub paste",
    29080: "Moonlight potion(4)",
    29081: "Moonlight potion(3)",
    29082: "Moonlight potion(2)",
    29083: "Moonlight potion(1)",
    29084: "Sulphur blades",
    29085: "null",
    29086: "null",
    29087: "Sulphurous essence",
    29088: "Calcified deposit",
    29089: "null",
    29090: "Calcified moth",
    29091: "null",
    29092: "null",
    29093: "null",
    29094: "null",
    29095: "null",
    29096: "null",
    29097: "null",
    29098: "Not meat",
    29099: "null",
    29100: "null",
    29101: "Raw barb-tailed kebbit",
    29102: "null",
    29103: "null",
    29104: "Raw wild kebbit",
    29105: "null",
    29106: "null",
    29107: "Raw dashing kebbit",
    29108: "null",
    29109: "null",
    29110: "Raw pyre fox",
    29111: "null",
    29112: "null",
    29113: "Raw moonlight antelope",
    29114: "null",
    29115: "null",
    29116: "Raw sunlight antelope",
    29117: "null",
    29118: "null",
    29119: "Raw graahk",
    29120: "null",
    29121: "null",
    29122: "Raw larupia",
    29123: "null",
    29124: "null",
    29125: "Raw kyatt",
    29126: "null",
    29127: "null",
    29128: "Cooked wild kebbit",
    29129: "null",
    29130: "null",
    29131: "Cooked barb-tailed kebbit",
    29132: "null",
    29133: "null",
    29134: "Cooked dashing kebbit",
    29135: "null",
    29136: "null",
    29137: "Cooked pyre fox",
    29138: "null",
    29139: "null",
    29140: "Cooked sunlight antelope",
    29141: "null",
    29142: "null",
    29143: "Cooked moonlight antelope",
    29144: "null",
    29145: "null",
    29146: "Cooked larupia",
    29147: "null",
    29148: "null",
    29149: "Cooked graahk",
    29150: "null",
    29151: "null",
    29152: "Cooked kyatt",
    29153: "null",
    29154: "null",
    29155: "Burnt kebbit",
    29156: "null",
    29157: "Burnt large beast",
    29158: "null",
    29159: "Burnt antelope",
    29160: "null",
    29161: "Burnt fox meat",
    29162: "null",
    29163: "Fox fur",
    29164: "null",
    29165: "null",
    29166: "Jerboa tail",
    29167: "null",
    29168: "Sunlight antelope antler",
    29169: "null",
    29170: "null",
    29171: "Moonlight antelope antler",
    29172: "null",
    29173: "null",
    29174: "Moonlight antelope fur",
    29175: "null",
    29176: "null",
    29177: "Sunlight antelope fur",
    29178: "null",
    29179: "null",
    29180: "Sapphire glacialis mix (2)",
    29181: "null",
    29182: "null",
    29183: "Snowy knight mix (2)",
    29184: "null",
    29185: "null",
    29186: "Ruby harvest mix (2)",
    29187: "null",
    29188: "null",
    29189: "Black warlock mix (2)",
    29190: "null",
    29191: "null",
    29192: "Sunlight moth mix (2)",
    29193: "null",
    29194: "null",
    29195: "Moonlight moth mix (2)",
    29196: "null",
    29197: "null",
    29198: "Sapphire glacialis mix (1)",
    29199: "null",
    29200: "null",
    29201: "Snowy knight mix (1)",
    29202: "null",
    29203: "null",
    29204: "Ruby harvest mix (1)",
    29205: "null",
    29206: "null",
    29207: "Black warlock mix (1)",
    29208: "null",
    29209: "null",
    29210: "Sunlight moth mix (1)",
    29211: "null",
    29212: "null",
    29213: "Moonlight moth mix (1)",
    29214: "null",
    29215: "null",
    29216: "Raw bream",
    29217: "Cooked bream",
    29218: "Jaguar fur",
    29219: "null",
    29220: "null",
    29221: "Chinchompa tuft",
    29222: "Tailfeathers",
    29223: "Kebbity tuft",
    29224: "Blue butterfly wing",
    29225: "Swamp lizard claw",
    29226: "Larupia ear",
    29227: "White butterfly wing",
    29228: "Large jerboa tail",
    29229: "Graahk horn spur",
    29230: "Black butterfly wing",
    29231: "Orange salamander claw",
    29232: "Kyatt tooth chip",
    29233: "Fox fluff",
    29234: "Red salamander claw",
    29235: "Red chinchompa tuft",
    29236: "Antelope hoof shard",
    29237: "Sunlight moth wing",
    29238: "Salamander claw",
    29239: "Herby tuft",
    29240: "Moonlight moth wing",
    29241: "Antelope hoof shard",
    29242: "Hunters' loot sack (basic)",
    29243: "null",
    29244: "Hunters' loot sack (adept)",
    29245: "null",
    29246: "Hunters' loot sack (expert)",
    29247: "null",
    29248: "Hunters' loot sack (master)",
    29249: "null",
    29250: "Hunters' loot sack",
    29251: "Basic quetzal whistle blueprint",
    29252: "null",
    29253: "Enhanced quetzal whistle blueprint",
    29254: "null",
    29255: "null",
    29256: "Perfected quetzal whistle blueprint",
    29257: "null",
    29258: "null",
    29259: "Torn enhanced quetzal whistle blueprint",
    29260: "null",
    29261: "Torn perfected quetzal whistle blueprint",
    29262: "null",
    29263: "Guild hunter headwear",
    29264: "null",
    29265: "Guild hunter top",
    29266: "null",
    29267: "Guild hunter legs",
    29268: "null",
    29269: "Guild hunter boots",
    29270: "null",
    29271: "Basic quetzal whistle",
    29272: "null",
    29273: "Enhanced quetzal whistle",
    29274: "null",
    29275: "Perfected quetzal whistle",
    29276: "null",
    29277: "Trapper's tipple",
    29278: "null",
    29279: "null",
    29280: "Mixed hide top",
    29281: "null",
    29282: "null",
    29283: "Mixed hide legs",
    29284: "null",
    29285: "null",
    29286: "Mixed hide boots",
    29287: "null",
    29288: "null",
    29289: "Mixed hide cape",
    29290: "null",
    29291: "null",
    29292: "Mixed hide base",
    29293: "null",
    29294: "null",
    29295: "Small meat pouch",
    29296: "null",
    29297: "Large meat pouch",
    29298: "null",
    29299: "Small fur pouch",
    29300: "null",
    29301: "Medium fur pouch",
    29302: "null",
    29303: "Large fur pouch",
    29304: "null",
    29305: "Hunter's spear",
    29306: "null",
    29307: "Quetzal feed",
    29308: "null",
    29309: "Huntsman's kit",
    29310: "null",
    29311: "Hunter spear tips",
    29312: "null",
    29313: "null",
    29314: "null",
    29315: "null",
    29316: "null",
    29317: "Tattered request note",
    29318: "null",
    29319: "Guild history excerpt",
    29320: "null",
    29321: "Apatura's note",
    29322: "null",
    29323: "Apatura's key",
    29324: "null",
    29325: "House keys",
    29326: "null",
    29327: "null",
    29328: "null",
    29329: "null",
    29330: "null",
    29331: "null",
    29332: "Valuables",
    29333: "null",
    29334: "null",
    29335: "null",
    29336: "null",
    29337: "null",
    29338: "Blessed bone statuette",
    29339: "null",
    29340: "Blessed bone statuette",
    29341: "null",
    29342: "Blessed bone statuette",
    29343: "null",
    29344: "Blessed bones",
    29345: "null",
    29346: "Blessed bat bones",
    29347: "null",
    29348: "Blessed big bones",
    29349: "null",
    29350: "Blessed zogre bones",
    29351: "null",
    29352: "Blessed babydragon bones",
    29353: "null",
    29354: "Blessed babywyrm bones",
    29355: "null",
    29356: "Blessed dragon bones",
    29357: "null",
    29358: "Blessed lava dragon bones",
    29359: "null",
    29360: "Blessed wyvern bones",
    29361: "null",
    29362: "Blessed superior dragon bones",
    29363: "null",
    29364: "Blessed wyrm bones",
    29365: "null",
    29366: "Blessed drake bones",
    29367: "null",
    29368: "Blessed hydra bones",
    29369: "null",
    29370: "Blessed fayrg bones",
    29371: "null",
    29372: "Blessed raurg bones",
    29373: "null",
    29374: "Blessed ourg bones",
    29375: "null",
    29376: "Dagannoth bones",
    29377: "null",
    29378: "Sun-kissed bones",
    29379: "null",
    29380: "null",
    29381: "Blessed bone shards",
    29382: "Jug of sunfire wine",
    29383: "null",
    29384: "Jug of blessed sunfire wine",
    29385: "null",
    29386: "Jug of blessed wine",
    29387: "null",
    29388: "Token",
    29389: "Token",
    29390: "Token",
    29391: "Token",
    29392: "Token",
    29393: "Token",
    29394: "Token",
    29395: "Token",
    29396: "Token",
    29397: "Token",
    29398: "Token",
    29399: "Token",
    29400: "Token",
    29401: "Token",
    29402: "Token",
    29403: "Token",
    29404: "Token",
    29405: "Token",
    29406: "Token",
    29407: "Token",
    29408: "A nice key",
    29409: "Sunbeam ale",
    29410: "null",
    29411: "null",
    29412: "Steamforge brew",
    29413: "null",
    29414: "null",
    29415: "Eclipse red",
    29416: "null",
    29417: "null",
    29418: "Moon-lite",
    29419: "null",
    29420: "null",
    29421: "Sun-shine",
    29422: "null",
    29423: "null",
    29424: "Sunfire fanatic armour set",
    29425: "null",
    29426: "null",
    29427: "Herbalist's notes",
    29428: "Chef's notes",
    29429: "null",
    29430: "null",
    29431: "Null",
    29432: "Null",
    29433: "Book of egg",
    29434: "null",
    29435: "Book of egg",
    29436: "null",
    29437: "Egg priest robe",
    29438: "null",
    29439: "Egg priest robe top",
    29440: "null",
    29441: "Egg priest necklace",
    29442: "null",
    29443: "Egg priest mitre",
    29444: "null",
    29445: "Null",
    29446: "Null",
    29447: "Null",
    29448: "Imbued mage arena cape",
    29449: "Zombie pirate key",
    29450: "null",
    29451: "null",
    29452: "null",
    29453: "null",
    29454: "null",
    29455: "Teleport anchoring scroll",
    29456: "null",
    29457: "null",
    29458: "Adamant seeds",
    29459: "null",
    29460: "Wilderness agility ticket",
    29461: "null",
    29462: "Small meat pouch (open)",
    29463: "null",
    29464: "Large meat pouch (open)",
    29465: "null",
    29466: "Small fur pouch (open)",
    29467: "null",
    29468: "Medium fur pouch (open)",
    29469: "null",
    29470: "Large fur pouch (open)",
    29471: "null",
    29472: "Prospector helmet",
    29473: "null",
    29474: "Prospector jacket",
    29475: "null",
    29476: "Prospector legs",
    29477: "null",
    29478: "Prospector boots",
    29479: "null",
    29480: "Agility arena ticket",
    29481: "null",
    29482: "Brimhaven voucher",
    29483: "null",
    29484: "Agility xp",
    29485: "Graceful recolour",
    29486: "Cursed amulet of magic",
    29487: "null",
    29488: "null",
    29489: "Rainbow cape",
    29490: "null",
    29491: "Colourful cape",
    29492: "null",
    29493: "Colourful cape",
    29494: "null",
    29495: "Colourful cape",
    29496: "null",
    29497: "Colourful cape",
    29498: "null",
    29499: "Colourful cape",
    29500: "null",
    29501: "Colourful cape",
    29502: "null",
    29503: "Colourful cape",
    29504: "null",
    29505: "Colourful cape",
    29506: "null",
    29507: "Rainbow crown shirt",
    29508: "null",
    29509: "Colourful crown shirt",
    29510: "Colourful crown shirt",
    29511: "Colourful crown shirt",
    29512: "Colourful crown shirt",
    29513: "Colourful crown shirt",
    29514: "Colourful crown shirt",
    29515: "Colourful crown shirt",
    29516: "Colourful crown shirt",
    29517: "Armadyl communiqu�",
    29518: "Unconscious broav",
    29519: "Broav",
    29520: "null",
    29521: "Dirty shirt",
    29522: "Waste-paper basket",
    29523: "Ruby key",
    29524: "Notes on pressure",
    29525: "Movario's notes (volume 1)",
    29526: "Movario's notes (volume 2)",
    29527: "Weight (1kg)",
    29528: "Weight (2kg)",
    29529: "Weight (5kg)",
    29530: "Enriched snapdragon",
    29531: "Super truth serum",
    29532: "Truth serum",
    29533: "Suspect sketch",
    29534: "Cell key",
    29535: "Strange teleorb",
    29536: "Teleorb",
    29537: "Teleorb",
    29538: "Enriched snapdragon seed",
    29539: "Agility dolmen",
    29540: "Energy dolmen",
    29541: "Restoration dolmen",
    29542: "Attack dolmen",
    29543: "Strength dolmen",
    29544: "Defence dolmen",
    29545: "Combat dolmen",
    29546: "Ranged dolmen",
    29547: "Prayer dolmen",
    29548: "Hunter dolmen",
    29549: "Fishing dolmen",
    29550: "Magic dolmen",
    29551: "Balance dolmen",
    29552: "Air block",
    29553: "Earth block",
    29554: "Fire block",
    29555: "Water block",
    29556: "Vine flower",
    29557: "null",
    29558: "Grimy note",
    29559: "null",
    29560: "Elite black full helm",
    29561: "null",
    29562: "Elite black platebody",
    29563: "null",
    29564: "Elite black platelegs",
    29565: "null",
    29566: "Dark squall hood",
    29567: "null",
    29568: "Dark squall robe top",
    29569: "null",
    29570: "Dark squall robe bottom",
    29571: "null",
    29572: "Silif",
    29573: "Lit explosive",
    29574: "Burning claw",
    29575: "null",
    29576: "null",
    29577: "Burning claws",
    29578: "null",
    29579: "null",
    29580: "Tormented synapse",
    29581: "null",
    29582: "null",
    29583: "Smouldering heart",
    29584: "null",
    29585: "Smouldering pile of flesh",
    29586: "null",
    29587: "Smouldering gland",
    29588: "null",
    29589: "Emberlight",
    29590: "null",
    29591: "Scorching bow",
    29592: "null",
    29593: "null",
    29594: "Purging staff",
    29595: "null",
    29596: "Duradel's notes",
    29597: "null",
    29598: "Deadman rug",
    29599: "Corrupted dark bow",
    29600: "null",
    29601: "null",
    29602: "Corrupted volatile nightmare staff",
    29603: "null",
    29604: "null",
    29605: "Armadyl godsword (deadman)",
    29606: "null",
    29607: "Voidwaker (deadman)",
    29608: "null",
    29609: "Volatile nightmare staff (deadman)",
    29610: "null",
    29611: "Dark bow (deadman)",
    29612: "null",
    29613: "Imbued zamorak cape (deadman)",
    29614: "null",
    29615: "Imbued guthix cape (deadman)",
    29616: "null",
    29617: "Imbued saradomin cape (deadman)",
    29618: "null",
    29619: "Armageddon rug",
    29620: "null",
    29621: "null",
    29622: "Armageddon teleport scroll",
    29623: "null",
    29624: "null",
    29625: "Armageddon weapon scroll",
    29626: "null",
    29627: "null",
    29628: "Armageddon cape fabric",
    29629: "null",
    29630: "null",
    29631: "Blighted overload (4)",
    29632: "null",
    29633: "null",
    29634: "Blighted overload (3)",
    29635: "null",
    29636: "null",
    29637: "Blighted overload (2)",
    29638: "null",
    29639: "null",
    29640: "Blighted overload (1)",
    29641: "null",
    29642: "null",
    29643: "Chitin",
    29644: "null",
    29645: "null",
    29646: "Cabbage",
    29647: "null",
    29648: "Sigil of meticulousness",
    29649: "Sigil of meticulousness",
    29650: "null",
    29651: "Sigil of revoked limitation",
    29652: "Sigil of revoked limitation",
    29653: "null",
    29654: "Sigil of rampart",
    29655: "Sigil of rampart",
    29656: "null",
    29657: "Sigil of deception",
    29658: "Sigil of deception",
    29659: "null",
    29660: "Sigil of lithe",
    29661: "Sigil of lithe",
    29662: "null",
    29663: "Sigil of adroit",
    29664: "Sigil of adroit",
    29665: "null",
    29666: "Sigil of onslaught",
    29667: "Sigil of onslaught",
    29668: "null",
    29669: "Sigil of restoration",
    29670: "Sigil of restoration",
    29671: "null",
    29672: "Sigil of swashbuckler",
    29673: "Sigil of swashbuckler",
    29674: "null",
    29675: "Sigil of gunslinger",
    29676: "Sigil of gunslinger",
    29677: "null",
    29678: "Sigil of arcane swiftness",
    29679: "Sigil of arcane swiftness",
    29680: "null",
    29681: "Null",
    29682: "null",
    29683: "null",
    29684: "Guthixian temple teleport",
    29685: "null",
    29686: "Dni23 torso lightbuttons",
    29687: "null",
    29688: "Dni23 torso darkbuttons",
    29689: "null",
    29690: "Dni23 torso darkbuttons",
    29691: "null",
    29692: "Dni23 torso shirt",
    29693: "null",
    29694: "Dni23 torso stitching",
    29695: "null",
    29696: "Dni23 torso twotoned",
    29697: "null",
    29698: "Dni23 torso princely",
    29699: "null",
    29700: "Dni23 torso rippedweskit",
    29701: "null",
    29702: "Dni23 torso rippedweskit",
    29703: "null",
    29704: "Dni23 torso croptops",
    29705: "null",
    29706: "Dni23 torso poloneck",
    29707: "null",
    29708: "Dni23 torso simple",
    29709: "null",
    29710: "Dni23 torso frilly",
    29711: "null",
    29712: "Dni23 torso corsetry",
    29713: "null",
    29714: "Dni23 torso bodice",
    29715: "null",
    29716: "Dni23 arms thin",
    29717: "null",
    29718: "Dni23 arms shoulderpads",
    29719: "null",
    29720: "Dni23 arms thickstripe",
    29721: "null",
    29722: "Dni23 arms loosesleeves",
    29723: "null",
    29724: "Dni23 arms princely",
    29725: "null",
    29726: "Dni23 arms tattylong",
    29727: "null",
    29728: "Dni23 arms ripped",
    29729: "null",
    29730: "Dni23 arms bare",
    29731: "null",
    29732: "Dni23 arms frilly",
    29733: "null",
    29734: "Dni23 arms tattyshort",
    29735: "null",
    29736: "Dni23 arms bareshoulders",
    29737: "null",
    29738: "Dni23 legs shorts",
    29739: "null",
    29740: "Dni23 legs beach",
    29741: "null",
    29742: "Dni23 legs princely",
    29743: "null",
    29744: "Dni23 legs leggings",
    29745: "null",
    29746: "Dni23 legs sidestripes",
    29747: "null",
    29748: "Dni23 legs ripped",
    29749: "null",
    29750: "Dni23 legs patched",
    29751: "null",
    29752: "Dni23 legs skirt",
    29753: "null",
    29754: "Dni23 legs longskirt",
    29755: "null",
    29756: "Dni23 legs longnarrowskirt",
    29757: "null",
    29758: "Dni23 legs shortskirt",
    29759: "null",
    29760: "Dni23 legs layered",
    29761: "null",
    29762: "Dni23 legs sashdots",
    29763: "null",
    29764: "Dni23 legs bighem",
    29765: "null",
    29766: "Dni23 legs sashtrousers",
    29767: "null",
    29768: "Dni23 legs patterned",
    29769: "null",
    29770: "Dni23 legs tornskirt",
    29771: "null",
    29772: "Dni23 legs patchedskirt",
    29773: "null",
    29774: "Amy's saw (off-hand)",
    29775: "Imcando hammer (off-hand)",
    29776: "null",
    29777: "Bruma torch (off-hand)",
    29778: "null",
    29779: "null",
    29780: "null",
    29781: "Coagulated venom",
    29782: "Spider cave teleport",
    29783: "null",
    29784: "Araxyte venom sack",
    29785: "null",
    29786: "Jar of venom",
    29787: "null",
    29788: "Araxyte head",
    29789: "null",
    29790: "Noxious point",
    29791: "null",
    29792: "Noxious blade",
    29793: "null",
    29794: "Noxious pommel",
    29795: "null",
    29796: "Noxious halberd",
    29797: "null",
    29798: "null",
    29799: "Araxyte fang",
    29800: "null",
    29801: "Amulet of rancour",
    29802: "null",
    29803: "null",
    29804: "Amulet of rancour (s)",
    29805: "null",
    29806: "Aranea boots",
    29807: "null",
    29808: "null",
    29809: "Venom-riddled note",
    29810: "null",
    29811: "null",
    29812: "null",
    29813: "null",
    29814: "null",
    29815: "null",
    29816: "Araxyte slayer helmet",
    29817: "null",
    29818: "Araxyte slayer helmet (i)",
    29819: "null",
    29820: "Araxyte slayer helmet (i)",
    29821: "null",
    29822: "Araxyte slayer helmet (i)",
    29823: "null",
    29824: "Extended anti-venom+(4)",
    29825: "null",
    29826: "null",
    29827: "Extended anti-venom+(3)",
    29828: "null",
    29829: "null",
    29830: "Extended anti-venom+(2)",
    29831: "null",
    29832: "null",
    29833: "Extended anti-venom+(1)",
    29834: "null",
    29835: "null",
    29836: "Nid",
    29837: "null",
    29838: "Rax",
    29839: "null",
    29840: "Eclipse moon chestplate",
    29841: "Eclipse moon tassets",
    29842: "Eclipse moon helm",
    29843: "Blue moon chestplate",
    29844: "Blue moon tassets",
    29845: "Blue moon helm",
    29846: "Blood moon chestplate",
    29847: "Blood moon tassets",
    29848: "Blood moon helm",
    29849: "Blue moon spear",
    29850: "Dual macuahuitl",
    29851: "Eclipse atlatl",
    29852: "Atlatl dart",
    29853: "Clue scroll (easy)",
    29854: "Clue scroll (easy)",
    29855: "Clue scroll (elite)",
    29856: "Clue scroll (elite)",
    29857: "Clue scroll (medium)",
    29858: "Clue scroll (medium)",
    29859: "Clue scroll (hard)",
    29860: "Casket (easy)",
    29861: "Casket (medium)",
    29862: "Casket (medium)",
    29863: "Casket (medium)",
    29864: "Casket (hard)",
    29865: "null",
    29866: "null",
    29867: "Prince itzla arkan",
    29868: "Emissary hood",
    29869: "null",
    29870: "Emissary robe top",
    29871: "null",
    29872: "Emissary robe bottom",
    29873: "null",
    29874: "Emissary sandals",
    29875: "null",
    29876: "Stone tablet",
    29877: "Tower key",
    29878: "Book",
    29879: "Poem",
    29880: "Scrap of paper",
    29881: "Scrap of paper",
    29882: "Scrap of paper",
    29883: "Completed note",
    29884: "Bandages",
    29885: "Icon",
    29886: "Icon",
    29887: "Icon",
    29888: "Icon",
    29889: "Glacial temotli",
    29890: "null",
    29891: "null",
    29892: "Pendant of ates (inert)",
    29893: "Pendant of ates",
    29894: "null",
    29895: "Frozen tear",
    29896: "null",
    29897: "Icon",
    29898: "Test kebab",
    29899: "Test kebab",
    29900: "Varlamorian kebab",
    29901: "null",
    29902: "null",
    29903: "Tattered sails",
    29904: "Sails",
    29905: "Betty's notes",
    29906: "Storeroom key",
    29907: "null",
    29908: "null",
    29909: "null",
    29910: "null",
    29911: "Prop sword",
    29912: "Butler's tray",
    29913: "Butler's tray",
    29914: "Butler's uniform",
    29915: "Butler's uniform",
    29916: "Butler's uniform",
    29917: "null",
    29918: "Butler's uniform",
    29919: "null",
    29920: "Costume needle",
    29921: "null",
    29922: "Case file",
    29923: "Chest key",
    29924: "null",
    29925: "Drinking flask",
    29926: "Threatening note",
    29927: "Shipping contract",
    29928: "Wine labels",
    29929: "Ram mask",
    29930: "Wolf mask",
    29931: "Bird mask",
    29932: "Jaguar mask",
    29933: "Snake mask",
    29934: "Ram mask",
    29935: "null",
    29936: "Wolf mask",
    29937: "null",
    29938: "Bird mask",
    29939: "null",
    29940: "Jaguar mask",
    29941: "null",
    29942: "Snake mask",
    29943: "null",
    29944: "Blackbird red",
    29945: "null",
    29946: "null",
    29947: "Chilhuac red",
    29948: "null",
    29949: "null",
    29950: "Principum red",
    29951: "null",
    29952: "Ixcoztic white",
    29953: "null",
    29954: "null",
    29955: "Metztonalli white",
    29956: "null",
    29957: "null",
    29958: "Tonameyo white",
    29959: "null",
    29960: "null",
    29961: "Fortis ash white",
    29962: "null",
    29963: "Chichilihui ros�",
    29964: "null",
    29965: "null",
    29966: "Imperial ros�",
    29967: "null",
    29968: "null",
    29969: "Xochipaltic ros�",
    29970: "null",
    29971: "Apprentice potion pack",
    29972: "Adept potion pack",
    29973: "Expert potion pack",
    29974: "Prescription goggles",
    29975: "null",
    29976: "Prescription goggles",
    29977: "null",
    29978: "Alchemist labcoat",
    29979: "null",
    29980: "Alchemist labcoat",
    29981: "null",
    29982: "Alchemist pants",
    29983: "null",
    29984: "Alchemist pants",
    29985: "null",
    29986: "Alchemist gloves",
    29987: "null",
    29988: "Alchemist's amulet",
    29989: "null",
    29990: "Alchemist's amulet",
    29991: "null",
    29992: "Alchemist's amulet",
    29993: "Aldarium",
    29994: "null",
    29995: "null",
    29996: "Reagent pouch",
    29997: "null",
    29998: "Open reagent pouch",
    29999: "Potion storage",
    30000: "Chugging barrel",
    30001: "null",
    30002: "Chugging barrel (disassembled)",
    30003: "null",
    30004: "null",
    30005: "Mox paste",
    30006: "null",
    30007: "Aga paste",
    30008: "null",
    30009: "Lye paste",
    30010: "null",
    30011: "Mammoth-might mix",
    30012: "Mystic mana amalgam",
    30013: "Marley's moonlight",
    30014: "Alco-augmentator",
    30015: "Aqualux amalgam",
    30016: "Azure aura mix",
    30017: "Liplack liquor",
    30018: "Anti-leech lotion",
    30019: "Megalite liquid",
    30020: "Mixalot",
    30021: "Mammoth-might mix",
    30022: "Mystic mana amalgam",
    30023: "Marley's moonlight",
    30024: "Alco-augmentator",
    30025: "Aqualux amalgam",
    30026: "Azure aura mix",
    30027: "Liplack liquor",
    30028: "Anti-leech lotion",
    30029: "Megalite liquid",
    30030: "Mixalot",
    30031: "Digweed",
    30032: "Mixology lab notes",
    30033: "null",
    30034: "null",
    30035: "Moonrise wines",
    30036: "null",
    30037: "Grape barrel",
    30038: "Termites",
    30039: "null",
    30040: "Colossal wyrm teleport scroll",
    30041: "null",
    30042: "Calcified acorn",
    30043: "null",
    30044: "Graceful crafting kit",
    30045: "Graceful hood",
    30046: "null",
    30047: "Graceful hood",
    30048: "Graceful cape",
    30049: "null",
    30050: "Graceful cape",
    30051: "Graceful top",
    30052: "null",
    30053: "Graceful top",
    30054: "Graceful legs",
    30055: "null",
    30056: "Graceful legs",
    30057: "Graceful gloves",
    30058: "null",
    30059: "Graceful gloves",
    30060: "Graceful boots",
    30061: "null",
    30062: "Graceful boots",
    30063: "null",
    30064: "Tome of earth",
    30065: "null",
    30066: "Tome of earth (empty)",
    30067: "null",
    30068: "Soiled page",
    30069: "null",
    30070: "Dragon hunter wand",
    30071: "null",
    30072: "null",
    30073: "Hueycoatl hide coif",
    30074: "null",
    30075: "null",
    30076: "Hueycoatl hide body",
    30077: "null",
    30078: "null",
    30079: "Hueycoatl hide chaps",
    30080: "null",
    30081: "null",
    30082: "Hueycoatl hide vambraces",
    30083: "null",
    30084: "null",
    30085: "Hueycoatl hide",
    30086: "null",
    30087: "null",
    30088: "Huasca seed",
    30089: "null",
    30090: "null",
    30091: "null",
    30092: "null",
    30093: "null",
    30094: "Grimy huasca",
    30095: "null",
    30096: "null",
    30097: "Huasca",
    30098: "null",
    30099: "null",
    30100: "Huasca potion (unf)",
    30101: "null",
    30102: "null",
    30103: "Redberry antelope",
    30104: "null",
    30105: "Tooth half of key",
    30106: "null",
    30107: "Loop half of key",
    30108: "null",
    30109: "Moon key",
    30110: "null",
    30111: "Helmet of the moon",
    30112: "null",
    30113: "Nasty token",
    30114: "Nasty token",
    30115: "Nasty token",
    30116: "Nasty token",
    30117: "Nasty token",
    30118: "Nasty token",
    30119: "Nasty token",
    30120: "Nasty token",
    30121: "Nasty token",
    30122: "Nasty surprise",
    30123: "The hueycoatl",
    30124: "null",
    30125: "Prayer regeneration potion(4)",
    30126: "null",
    30127: "null",
    30128: "Prayer regeneration potion(3)",
    30129: "null",
    30130: "null",
    30131: "Prayer regeneration potion(2)",
    30132: "null",
    30133: "null",
    30134: "Prayer regeneration potion(1)",
    30135: "null",
    30136: "null",
    30137: "Goading potion(4)",
    30138: "null",
    30139: "null",
    30140: "Goading potion(3)",
    30141: "null",
    30142: "null",
    30143: "Goading potion(2)",
    30144: "null",
    30145: "null",
    30146: "Goading potion(1)",
    30147: "null",
    30148: "null",
    30149: "Aldarin teleport",
    30150: "null",
    30151: "Bone squirrel",
    30152: "Huberte",
    30153: "null",
    30154: "Moxi",
    30155: "null",
    30156: "Butler's tray",
    30157: "Butler's tray",
    30158: "null",
    30159: "null",
    30160: "Picture map",
    30161: "Seed packets",
    30162: "Beige pumpkin",
    30163: "White pumpkin",
    30164: "Yellow pumpkin",
    30165: "Orange pumpkin",
    30166: "Red pumpkin",
    30167: "Dark green pumpkin",
    30168: "Powder grey pumpkin",
    30169: "Beige pumpkin (disgusted)",
    30170: "Beige pumpkin (silly)",
    30171: "Beige pumpkin (evil)",
    30172: "Beige pumpkin (angry)",
    30173: "Beige pumpkin (depressed)",
    30174: "Beige pumpkin (shocked)",
    30175: "Beige pumpkin (sad)",
    30176: "Beige pumpkin (happy)",
    30177: "Beige pumpkin (laughing)",
    30178: "White pumpkin (disgusted)",
    30179: "White pumpkin (silly)",
    30180: "White pumpkin (evil)",
    30181: "White pumpkin (angry)",
    30182: "White pumpkin (depressed)",
    30183: "White pumpkin (shocked)",
    30184: "White pumpkin (sad)",
    30185: "White pumpkin (happy)",
    30186: "White pumpkin (laughing)",
    30187: "Yellow pumpkin (disgusted)",
    30188: "Yellow pumpkin (silly)",
    30189: "Yellow pumpkin (evil)",
    30190: "Yellow pumpkin (angry)",
    30191: "Yellow pumpkin (depressed)",
    30192: "Yellow pumpkin (shocked)",
    30193: "Yellow pumpkin (sad)",
    30194: "Yellow pumpkin (happy)",
    30195: "Yellow pumpkin (laughing)",
    30196: "Orange pumpkin (disgusted)",
    30197: "Orange pumpkin (silly)",
    30198: "Orange pumpkin (evil)",
    30199: "Orange pumpkin (angry)",
    30200: "Orange pumpkin (depressed)",
    30201: "Orange pumpkin (shocked)",
    30202: "Orange pumpkin (sad)",
    30203: "Orange pumpkin (happy)",
    30204: "Orange pumpkin (laughing)",
    30205: "Red pumpkin (disgusted)",
    30206: "Red pumpkin (silly)",
    30207: "Red pumpkin (evil)",
    30208: "Red pumpkin (angry)",
    30209: "Red pumpkin (depressed)",
    30210: "Red pumpkin (shocked)",
    30211: "Red pumpkin (sad)",
    30212: "Red pumpkin (happy)",
    30213: "Red pumpkin (laughing)",
    30214: "Dark green pumpkin (disgusted)",
    30215: "Dark green pumpkin (silly)",
    30216: "Dark green pumpkin (evil)",
    30217: "Dark green pumpkin (angry)",
    30218: "Dark green pumpkin (depressed)",
    30219: "Dark green pumpkin (shocked)",
    30220: "Dark green pumpkin (sad)",
    30221: "Dark green pumpkin (happy)",
    30222: "Dark green pumpkin (laughing)",
    30223: "Powder grey pumpkin (disgusted)",
    30224: "Powder grey pumpkin (silly)",
    30225: "Powder grey pumpkin (evil)",
    30226: "Powder grey pumpkin (angry)",
    30227: "Powder grey pumpkin (depressed)",
    30228: "Powder grey pumpkin (shocked)",
    30229: "Powder grey pumpkin (sad)",
    30230: "Powder grey pumpkin (happy)",
    30231: "Powder grey pumpkin (laughing)",
    30232: "Scarecrow shirt",
    30233: "null",
    30234: "Halloween scarecrow",
    30235: "null",
    30236: "Scarecrow",
    30237: "Beige pumpkin (disgusted)",
    30238: "null",
    30239: "Beige pumpkin (silly)",
    30240: "null",
    30241: "Beige pumpkin (evil)",
    30242: "Beige pumpkin (angry)",
    30243: "Beige pumpkin (depressed)",
    30244: "Beige pumpkin (shocked)",
    30245: "Beige pumpkin (sad)",
    30246: "Beige pumpkin (happy)",
    30247: "Beige pumpkin (laughing)",
    30248: "White pumpkin (disgusted)",
    30249: "White pumpkin (silly)",
    30250: "White pumpkin (evil)",
    30251: "White pumpkin (angry)",
    30252: "White pumpkin (depressed)",
    30253: "White pumpkin (shocked)",
    30254: "White pumpkin (sad)",
    30255: "White pumpkin (happy)",
    30256: "White pumpkin (laughing)",
    30257: "Yellow pumpkin (disgusted)",
    30258: "Yellow pumpkin (silly)",
    30259: "Yellow pumpkin (evil)",
    30260: "Yellow pumpkin (angry)",
    30261: "Yellow pumpkin (depressed)",
    30262: "Yellow pumpkin (shocked)",
    30263: "Yellow pumpkin (sad)",
    30264: "Yellow pumpkin (happy)",
    30265: "Yellow pumpkin (laughing)",
    30266: "Orange pumpkin (disgusted)",
    30267: "Orange pumpkin (silly)",
    30268: "Orange pumpkin (evil)",
    30269: "Orange pumpkin (angry)",
    30270: "Orange pumpkin (depressed)",
    30271: "Orange pumpkin (shocked)",
    30272: "Orange pumpkin (sad)",
    30273: "Orange pumpkin (happy)",
    30274: "Orange pumpkin (laughing)",
    30275: "Red pumpkin (disgusted)",
    30276: "Red pumpkin (silly)",
    30277: "Red pumpkin (evil)",
    30278: "Red pumpkin (angry)",
    30279: "Red pumpkin (depressed)",
    30280: "Red pumpkin (shocked)",
    30281: "Red pumpkin (sad)",
    30282: "Red pumpkin (happy)",
    30283: "Red pumpkin (laughing)",
    30284: "Dark green pumpkin (disgusted)",
    30285: "Dark green pumpkin (silly)",
    30286: "Dark green pumpkin (evil)",
    30287: "Dark green pumpkin (angry)",
    30288: "Dark green pumpkin (depressed)",
    30289: "Dark green pumpkin (shocked)",
    30290: "Dark green pumpkin (sad)",
    30291: "Dark green pumpkin (happy)",
    30292: "Dark green pumpkin (laughing)",
    30293: "Powder grey pumpkin (disgusted)",
    30294: "Powder grey pumpkin (silly)",
    30295: "Powder grey pumpkin (evil)",
    30296: "Powder grey pumpkin (angry)",
    30297: "Powder grey pumpkin (depressed)",
    30298: "Powder grey pumpkin (shocked)",
    30299: "Powder grey pumpkin (sad)",
    30300: "Powder grey pumpkin (happy)",
    30301: "Powder grey pumpkin (laughing)",
    30302: "Torva full helm",
    30303: "Torva platebody",
    30304: "Torva platelegs",
    30305: "Arclight (inactive)",
    30306: "null",
    30307: "Elias' message",
    30308: "Mastaba key",
    30309: "Mastaba key",
    30310: "Base plans",
    30311: "Base key",
    30312: "Canopic jar (oil)",
    30313: "Canopic jar (oil and berries)",
    30314: "Canopic jar (full)",
    30315: "null",
    30316: "Code key",
    30317: "Decoder strips",
    30318: "Mahjarrat notes (a-j)",
    30319: "Mahjarrat notes (k-z)",
    30320: "Arrav's axe",
    30321: "Zombie helmet",
    30322: "null",
    30323: "null",
    30324: "Broken zombie helmet",
    30325: "null",
    30326: "null",
    30327: "null",
    30328: "Dust",
    30329: "null",
    30330: "null",
};
